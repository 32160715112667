import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import APIResourceStore from "../../Store/APIResourceStore";

export var VerifiedButton = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (state) => {
        onChange(state);
        setAnchorEl(null);
    };

    const onChange = (state) => {
        let entity = {
            id: props.entity.id
        };
        if (!props.nullable && state === null) {
            state = false;
        }
        entity[props.property] = state;

        APIResourceStore.resources[props.entityResource].apiPut(entity).then(() => {
            props.entity[props.property] = state;
        });
    };

    const renderIcon = () => {
        switch (props.entity[props.property]) {
            case true:
            case 1:
                return (
                    <Tooltip title="Verified" arrow placement="right">
                        <IconButton onClick={handleClick}>
                            <CheckIcon className="text-success" />
                        </IconButton>
                    </Tooltip>
                );
            // case false:
            // case 0:
            //   return (
            //     <Tooltip title="Rejected" arrow placement="right">
            //       <IconButton onClick={handleClick}>
            //         <ClearIcon className="text-danger" />
            //       </IconButton>
            //     </Tooltip>
            //   );
            default:
                return (
                    <Tooltip title="Pending" arrow placement="right">
                        <IconButton onClick={handleClick}>
                            <HourglassEmptyIcon />
                        </IconButton>
                    </Tooltip>
                );
        }
    };
    return (
        <div>
            {renderIcon()}
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={!props.readonly && Boolean(anchorEl)}
                onClose={() => handleClose(props.entity[props.property])}
            >
                <MenuItem onClick={() => handleClose(true)}>
                    <CheckIcon className="text-success" />
                </MenuItem>
                <MenuItem onClick={() => handleClose(null)}>
                    <HourglassEmptyIcon />
                </MenuItem>
            </Menu>
        </div>
    );
};
