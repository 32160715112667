import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { observer } from 'mobx-react';
import APIResourceStore from '../../../Store/APIResourceStore';
import Alert from '../../../Services/Alert';
import Navigation from '../../../Services/Navigation';
import FieldProviderStore from '../../../Services/APIResource/FieldProviders/__FieldProviderStore';
import ModelTesterQuestions from './ModelTesterQuestions';
import User from '../../../Services/User/User';
import ParameterStore from "../../../Store/ParameterStore";
import {PARAMETER_TYPE_MODEL_TYPE, PARAMETER_TYPE_RISK_CATEGORY} from "../../../Admin/ParameterAdmin";
import {DocumentManager} from "../../Display/DocumentManager/DocumentManager";
import {HelperButton} from "../../HelperButton/HelperButton";

class ModelTester extends Component {
    state = {
        entity: {
            name: null,
            description: null,
            modelOwner: User.profile.path || null,
            modelOwnerDelegation: null,
            declarer: User.profile.path || null,
            modelDeveloperTeam: null,
            modelType: null,
            useDescription: null,
            riskCategory: null,
            documentsEntities: [],
        },
    };

    fields = {
        name: {
            title: 'Model Name',
            type: 'text',
            required: true,
            helperTextEdit: 'Full model name in accordance with the model documentation or the model directory',
        },
        description: {
            title: 'Description',
            type: 'textarea',
            required: true,
            requiredComputed: true,
            helperTextEdit: 'Please describe the model and its purpose in a few words',
        },
        modelOwner: {
            title: 'Model owner',
            type: 'user',
            params: {
                resource: 'users',
                instanceId: 'users_mo',
                displayField: 'toString',
                editDisplayField: 'fullNameWithTeam',
                multi: false,
                links: false,
                endpoints: {
                    getAll: 'users/all-users/mo',
                },
            },
            required: true,
            requiredComputed: true,
            helperTextEdit: 'The Model Owner is responsible for ensuring that the development, implementation and continued use of models are carried out in accordance with the principles set out in the MRM policy',
        },
        modelOwnerDelegation: {
            title: 'Delegated model owner',
            type: 'user',
            params: {
                resource: 'users',
                instanceId: 'users_all',
                displayField: 'toString',
                editDisplayField: 'fullNameWithTeam',
                sortField: 'lastName',
                multi: false,
                links: false,
                endpoints: {
                    getAll: 'users/all-users/all',
                },
            },
            helperTextEdit: 'Backup of the Model Owner in charge of the update of the inventory on a regular basis',
        },
        modelDeveloperTeam: {
            title: 'Model developer team',
            type: 'entityTree',
            params: {
                resource: 'scopes',
                instanceId: 'scopes_dev',
                displayField: 'title',
                childrenPropertyName: 'childScopes',
                multi: false,
                links: false,
                endpoints: {
                    getAll: 'scopes/all-scopes/dev',
                },
            },
            helperTextEdit: 'The Model Developer team leads all model development activities, including methodology and design',
            required: true,
            requiredComputed: true,
            bulk: true,
        },
        modelType: {
            title: 'Model type',
            type: 'parameter',
            params: { type: PARAMETER_TYPE_MODEL_TYPE, multi: false },
            required: true,
            requiredComputed: true,
            helperTextEdit: 'Characterization of the model based on its function (ex: PD, Pricer, VaR…)',
        },
        useDescription: {
            title: 'Use description',
            type: 'textarea',
            required: true,
            requiredComputed: true,
        },
        riskCategory: {
            title: 'Risk Category',
            type: 'parameter',
            params: { type: PARAMETER_TYPE_RISK_CATEGORY, multi: false },
            required: true,
            requiredComputed: true,
            helperTextEdit: '1st risk category level' + '\nDefined following the internal risk taxonomy of the BPCE group',
        },
    };

    saveModel() {
        let resource = APIResourceStore.resources.models;
        let entity = this.state.entity;
        entity.nonModel = false;
        entity.modelStatus = ParameterStore('MODEL_STATUS_DRAFT');
        entity.documents = [];
        entity.documentsEntities.forEach((d) => {
            entity.documents.push(d['@id']);
        });
        if (entity.modelOwnerDelegation && entity.modelOwnerDelegation === entity.declarer) {
            entity.modelOwnerDelegationAccepted = true;
        }
        resource.apiPost(entity).then((entity) => {
            Alert.show({ message: 'Model has been saved' });
            Navigation.router.history.push('/resource/models/' + entity.id + '/edit');
        });
    }

    saveNonModel() {
        let resource = APIResourceStore.resources.models;
        let entity = this.state.entity;
        entity.nonModel = true;
        entity.nonModelStatus = ParameterStore('NON_MODEL_STATUS_NON_MODEL_AWAITING');
        entity.documents = [];
        entity.documentsEntities.forEach((d) => {
            entity.documents.push(d['@id']);
        });
        resource.apiPost(entity).then((entity) => {
            Alert.show({ message: 'This declaration has been submitted to MRM for confirmation.' });
        });
    }

    isValid() {
        return (
            Object.entries(this.fields)
                // get all the required and empty fields
                .filter(([fieldId, field]) => field.required && !this.state.entity[fieldId]).length === 0
        );
    }

    /**
     * @typedef {Object} Change
     * @property {*} change.field - Nom du champ à modifier
     * @property {*} change.value - Valeur du champ à modifier
     *
     * @param {[Change]} listOfChanges
     * @param {function} [callback]
     */
    handleChange(listOfChanges, callback) {
        let entity = { ...this.state.entity };
        listOfChanges.forEach((c) => {
            entity[c.field] = c.value;
        });
        this.setState({ entity: entity }, callback);
    }

    render() {
        const form = Object.entries(this.fields).map(([fieldId, field]) => (
            <div
                className={'edit-field ressource-api-field' + (field.helperTextEdit ? ' with-helper-button' : '')}
                key={fieldId}
            >
                {FieldProviderStore[field.type].getEdit(
                    field,
                    this.state.entity[fieldId],
                    (value) => {
                        this.handleChange([{ field: fieldId, value }]);
                    },
                    this.state.entity
                )}
                {field.helperTextEdit ? (
                    <HelperButton helperText={field.helperTextEdit} entity={this.state.entity} />
                ) : null}
            </div>
        ));
        let self = this;
        return (
            <Grid container spacing={2} className="container resource-edit">
                <Grid item xs={6} style={styles.gridPaper}>
                    <Paper style={styles.blockHeightStyle}>
                        <h1 className="background-linear-gradient">Identity</h1>
                        {form}
                        <DocumentManager
                            values={this.state.entity.documentsEntities}
                            entity={this.state.entity}
                            hideIfEmpty={true}
                            onUpdate={(entity) => {
                                self.handleChange([{ field: 'documentsEntities', value: entity.documentsEntities }]);
                            }}
                        />
                    </Paper>
                </Grid>
                {this.isValid() && (
                    <Grid item xs={6} style={styles.gridPaper}>
                        <Paper style={styles.blockHeightStyle}>
                            <h1 className="background-linear-gradient">Determination questions</h1>
                            <ModelTesterQuestions
                                onSave={(fields) => this.handleChange(fields, this.saveModel)}
                                onReject={(fields) => this.handleChange(fields, this.saveNonModel)}
                            />
                        </Paper>
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default observer(ModelTester);

const styles = {
    blockHeightStyle: {
        paddingBottom: 5,
        marginBottom: 35,
    },
    gridPaper: {
        marginTop: 15,
        marginBottom: 15,
        height: '100%',
    },
    listStyle: {
        marginBottom: 15,
    },
    requiredDocumentText: {
        marginLeft: 15,
        marginRight: 15,
        textAlign: 'center'
    }
};
