import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, CircularProgress, FormControl, Radio, TextField, Typography } from '@material-ui/core';

import ModalComplementary from '../../../Services/ModalComplementary';
import Banner from '../../Display/Banner/Banner';
import { ModalContent } from '../../Modal/ModalContent';
import { ButtonBar } from '../../Modal/ButtonBar';
import { ActionButton } from '../../Modal/ActionButton';
import { TableDisplay } from '../../Display/TableDisplay/TableDisplay';
import { asyncDebounce } from '../../../Services/utils';

export const QuickSelectForm = (props) => {
    // Labels props :
    const { label, selectLabel = 'Select', placeholder = 'Search', resourceName = 'entity' } = props;
    // DisplayTable props :
    const { columns } = props;
    const {
        multi,
        onChange,
        disableValue = () => false,
        apiSearch,
        autoSearch = false,
        clearable,
        withModal,
        additionalButtons,
    } = props;
    /** @type {import("../../../Services/APIResource/APIResource").APIResource} */
    const resource = props.resource;
    // Values est un tableau donc si on rerender le composant, même si le tableau est toujours
    // identique, il va considérer que c'en est un nouveau, donc on stocke la réf.
    const values = useRef(props.values);

    const RadioOrCheckbox = multi ? Checkbox : Radio;

    const [searchValue, setSearchValue] = useState('');
    const [progress, setProgress] = useState(false);
    const [submitProgress, setSubmitProgress] = useState(false);
    const [entities, setEntities] = useState([]);
    const [loadingEntities, setLoadingEntities] = useState(false);
    const [changed, setChanged] = useState(false); // Si les valeurs sélectionnées sont différentes des valeurs données
    /** Tableau d'IRIs */
    const [selected, setSelected] = useState([]);
    const [found, setFound] = useState([]);
    const [disabled, setDisabled] = useState([]);

    // Pour éviter des rerender
    const memoSearch = useCallback(asyncDebounce(apiSearch, 1000), []);

    useEffect(() => {
        if (values.current) {
            setSelected([...values.current]);
            setLoadingEntities(true);

            let _entities = values.current.map((iri) => resource.getItemFromResourcePath(iri));
            Promise.all(_entities).then((entities) => {
                setEntities(entities);
                setLoadingEntities(false);
            });
        }
        return () => {
            setEntities([]); // cleanup
            setSelected([]);
        };
    }, [values]);

    useEffect(() => {
        const search = async (searchValue) => {
            try {
                const items = await memoSearch(searchValue);
                setFound(items.map((m) => m['@id']));
                setEntities((entities) => {
                    const dedup = {}; // Pour dédupliquer les entities
                    [...entities, ...items].forEach((v) => (dedup[v['@id']] = v));
                    return Object.values(dedup).sort((a, b) => a.id - b.id);
                });
                setProgress(false);
            } catch (error) {
                //console.log('debounced !!');
            }
        };
        if ((searchValue && searchValue.length > 2 && memoSearch) || autoSearch) {
            setProgress(true);
            search(searchValue);
        }
        return () => {
            setFound([]); // cleanup
        };
    }, [searchValue, memoSearch]);

    useEffect(() => {
        setDisabled(entities.filter((e) => disableValue(e)).map((e) => e['@id']));
        return () => {
            setDisabled([]);
        };
    }, [entities]);

    useEffect(() => {
        /** Hors d'une Modal, on appelle onSubmit à chaque selection : */
        if (!withModal) handleOnSubmit();
        if (selected.length === values.current.length && selected.every((iri) => values.current.includes(iri))) {
            setChanged(false);
        } else {
            setChanged(true);
        }
    }, [selected]);

    /**
     *
     * @param {object} item {'@id': iri}
     */
    const toggleSelected = (item) => {
        let iri = item['@id'];
        if (multi) {
            setSelected((selected) => {
                return selected.includes(iri) ? [...selected.filter((i) => i !== iri)] : [...selected, iri];
            });
        } else {
            setSelected((selected) => (selected.includes(iri) ? [] : [iri]));
        }
    };
    const actions = (row) => {
        return (
            <RadioOrCheckbox
                checked={selected.includes(row['@id'])}
                size="small"
                onClick={(_e) => toggleSelected(row)}
                disabled={disabled.includes(row['@id'])}
            />
        );
    };

    const handleOnSubmit = async () => {
        setSubmitProgress(true);
        await onChange(multi ? selected : selected[0] !== undefined ? selected[0] : null);
        if (withModal) ModalComplementary.close();
    };

    return (
        <ModalContent className={"quick-select-form"}>
            {label && <Banner>{label}</Banner>}
            <Typography component={'h1'}>Selection</Typography>
            {!clearable && selected.length === 0 && (
                <Typography component="p">{`Select at least one ${resourceName}.`}</Typography>
            )}
            <TableDisplay
                cols={columns}
                rows={entities.filter((m) => selected.includes(m['@id']))}
                filter={false}
                actions={actions}
                actionsColIndex={0}
                dense
                loading={loadingEntities}
            />
            <Typography component={'h1'} style={styles.search}>
                Search
            </Typography>
            {
                /** Si autosearch alors on n'autorise pas le choix par l'utilisateur */
                !autoSearch && (
                    <FormControl className={'td_filter_control'}>
                        <TextField placeholder={placeholder} onChange={(event) => setSearchValue(event.target.value)} />
                    </FormControl>
                )
            }
            {progress && (
                <Box textAlign="center">
                    <CircularProgress />
                </Box>
            )}
            {!progress && found.length === 0 && (searchValue.length > 2 || autoSearch) && (
                <Typography component="p">No matching {resourceName} found.</Typography>
            )}
            {!progress && found.length === 0 && searchValue.length < 3 && !autoSearch && (
                <Typography component="p">Please fill at least 3 characters to launch the search.</Typography>
            )}
            {!progress && found.length > 0 && (
                <TableDisplay
                    cols={columns}
                    rows={entities.filter((m) => found.includes(m['@id']))}
                    filter={false}
                    actions={actions}
                    actionsColIndex={0}
                />
            )}
            {withModal && (
                <ButtonBar>
                    <ActionButton
                        disabled={progress || submitProgress || (!clearable && selected.length === 0) || !changed}
                        onClick={handleOnSubmit}
                    >
                        {selectLabel} {submitProgress && <CircularProgress size={20} />}
                    </ActionButton>
                    {additionalButtons}
                </ButtonBar>
            )}
        </ModalContent>
    );
};
QuickSelectForm.propTypes = {
    label: PropTypes.string,
    /** Label du bouton de sélection (défaut "Select") */
    selectLabel: PropTypes.string,
    /** Placeholder du champ de recherche (défaut "Search") */
    placeholder: PropTypes.string,
    /** Nom du type de resource manipulée (pour les messages) (défaut "entity") */
    resourceName: PropTypes.string,
    /** Colonnes à afficher dans les TableDisplay */
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    /** Entités déjà sélectionnées */
    values: PropTypes.arrayOf(PropTypes.string),
    /**
     * Resource des entités à chercher / sélectionner.
     * @type {import("../../../Services/APIResource/APIResource").APIResource}
     */
    resource: PropTypes.object.isRequired,
    apiSearch: PropTypes.func.isRequired,
    /** Cache la barre de recherche et lance l'apiSearch automatiquement */
    autoSearch: PropTypes.bool,
    /** Action déclenchée après la sélection des entities, à la validation. */
    onChange: PropTypes.func.isRequired,
    /** Renvoie true si on ne peut pas sélectionner la ligne en question (reçoit l'entité de la ligne en entrée) */
    disableValue: PropTypes.func,
    multi: PropTypes.bool,
    clearable: PropTypes.bool,
    /**
     * Si l'on n'est pas dans une Modal, alors on cache le bouton select et on appelle onChange à chaque
     * selection, càd qu'on laisse le parent gérer !
     */
    withModal: PropTypes.bool,
    additionalButtons: PropTypes.element,
};

const styles = {
    search: {
        marginTop: '20px',
    },
};
