import React from 'react';
import { DisplayTextField } from '../../../Components/Display/DisplayTextField/DisplayTextField';
import { DatePicker } from '../../../Components/Forms/DatePicker/DatePicker';
import { ModalDateRangePicker } from '../../../Components/Forms/DateRangePicker/ModalDateRangePicker';
import DateFormatter from '../../DateFormatter';
import {CONTEXT_LIST} from "../APIResource";

class DateProvider {
    getDisplay(field, value, entity, props, _resource, context) {
        return <DisplayTextField {...props} fieldName={field.title} fieldType={'date'} value={value ?? ''} context={context} />;
    }

    getDisplayList(field, value, entity, props, resource) {
        return this.getDisplay(field, value, entity, props, resource, CONTEXT_LIST);
    }

    getEdit(field, value, onChange, _entity, _routeParams, loading = false) {
        // On n'envoie pas les props error et helperText s'il n'y a pas d'erreur,
        // pour ne pas écraser le comportement par défaut des tests du Composant
        // (en particulier: >minDate, <maxDate etc.)
        let helperProps = field.errorHelperText
            ? {
                  helperText: field.errorHelperText,
                  error: !!field.errorHelperText,
                  className: 'field-error-control',
              }
            : {};

        return (
            <DatePicker
                label={field.title}
                //disabled={loading}
                value={value}
                {...field.params}
                onChange={onChange}
                required={field.requiredComputed}
                {...helperProps}
            />
        );
    }

    getFilter(options = {}) {
        const { field, value, onChange } = options;
        return (
            <ModalDateRangePicker
                placeholder="Filter"
                value={value}
                onChange={(startDate, endDate) => {
                    onChange({ startDate: startDate, endDate: endDate });
                }}
            />
        );
    }

    getValidationPattern() {
        return /.*/;
    }

    applyFilter(fieldId, value, activeFilters, filterTrigger) {
        if (value.startDate == null || value.startDate === '') {
            delete activeFilters[fieldId + '[after]'];
            delete activeFilters[fieldId + '[before]'];
        } else {
            let endDate = new Date(value.endDate.getTime());
            endDate.setHours(23);
            endDate.setMinutes(59);
            endDate.setSeconds(59);
            activeFilters[fieldId + '[after]'] = DateFormatter.datetimeToRfc3339(value.startDate);
            activeFilters[fieldId + '[before]'] = DateFormatter.datetimeToRfc3339(endDate);
        }
        filterTrigger();
    }
}

export default new DateProvider();
