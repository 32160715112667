import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import ParameterStore from '../../Store/ParameterStore';
import Modal from "../../Services/Modal";
import APIResourceStore from "../../Store/APIResourceStore";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import {submitReview} from "../../Services/Actions/ReviewActions";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import {Link} from "react-router-dom";
import Http from "../../Services/Http";
import { EditButton } from "../Buttons/EditButton";
import Alert from "../../Services/Alert";
import { ActionButton } from "../Modal/ActionButton";
import { isSeverityAllowed } from "../../Admin/FindingAdmin";

const findingRequiredFields = [
    "title",
    "severity",
    "issuer",
    "models",
    "issuanceDate",
    "status",
    "findingMraRelations",
    "weaknessesDescription",
    "impactsDescription"
];

const noticeRequiredFields = [
    "title",
    "noticeDescription",
    "noticeValidators",
    "actionPlan",
    "noticeOwners",
    "issuanceDate",
    "deadline",
    "findings",
];

const requiredFields = {
    finding: findingRequiredFields,
    notice: noticeRequiredFields,
}

const ValidateFindingNotice = (props) => {

    const {findings, notices, resource, resourceFinding, resourceNotice, confirmationMessage, entity, currentStatus, nextStatus, resourceEditComponent, tooltip, fromModel, fromNotice} = props;
    const [processing, setProcessing] = useState(false);
    const [errors, setErrors] = useState([]);
    const [globalError, setGlobalError] = useState(undefined);

    useEffect(() => {
        if (!processing) {
            findErrors();
        }
    }, []);

    const isMissingInItem = (property, item) =>
        (Array.isArray(item[property]) && item[property].length === 0) ||
        (!Array.isArray(item[property]) && !item[property]);

    const checkRequirements = async (findings, notices) => {
        const requirementsErrors = [];
        const promises = [
            ...findings.map((finding) => getItemFromResource(finding, 'finding')), 
            ...notices.map((notice) => getItemFromResource(notice, 'notice')),
        ];
    
        const items = await Promise.all(promises);
        items.forEach(({item, type}) => {
            if(!requirementsErrors[type]){
                requirementsErrors[type] = [];
            }
            requirementsErrors[type][item.id] = {hasError: false, missingProperties: []};
            if(
                (type === 'finding' && item.status !== ParameterStore('FINDING_STATUS_DELETED'))
                || (type === 'notice' && item.status === ParameterStore('NOTICE_STATUS_DRAFT'))
            ){
                requirementsErrors[type][item.id].hasError = requiredFields[type].some((property) => isMissingInItem(property, item));
                requirementsErrors[type][item.id].missingProperties = requiredFields[type].filter(p => isMissingInItem(p, item));
            }

            // Cas particulier pour les findings de sévérité NA :
            if (type === 'finding' && item.status !== ParameterStore('FINDING_STATUS_DELETED')) {
                if (!isSeverityAllowed(item)) {
                    requirementsErrors[type][item.id].hasError = true;
                    requirementsErrors[type][item.id].missingProperties.push('severity');
                }
            }
        })
    
        let error = false;
        for (var key in requirementsErrors) {
            for (var key2 in requirementsErrors[key]) {
                if(requirementsErrors[key][key2].hasError){
                    error = true;
                }
            }
        }
    
        return {requirementsErrors, error};   
    }
    
    const findErrors = async (callback = null) => {
        setProcessing(true);
        try {
            const {requirementsErrors, error } = await checkRequirements(findings, notices);
            setErrors(requirementsErrors);
            setGlobalError(error);
            if(callback){
                callback();
            }
        } catch (err) {
            Alert.show({
                message: "Error during loading findings and notices."
            })
        } finally {
            setProcessing(false);
        }
    }
    
    const getItemFromResource = (item, type) => {
        return new Promise(function (resolve, _reject) {
            APIResourceStore.resources['resource_' + type + 's_reviews'].apiGetOne(item['id'], true).then((item) => {
                resolve({item: item, type: type});
            });
        });
    }
    
    const getLinkForItem = (item, type) => {
      const toReview = errors?.notice?.[item.id]?.missingProperties.filter( p => !['findings', 'issuanceDate'].includes(p)).length === 0;
      const resourcePath = toReview  ? "review" : type;
      
      return  `/resource/${resourcePath}s/${toReview ? entity.id : item.id}/${
                                        !toReview
                                            ? 'edit'
                                            : 'detail'
                                    }` 
                                    + `?highlight=${errors?.[type]?.[item.id]?.missingProperties.join('-') || ''}` 
                                    + (type === 'finding' ? `&notice-id=${notices?.[0]?.id}` : '') 
                                    + (type === 'notice' 
                                    && errors?.notice?.[item.id]?.missingProperties.includes('findings')
                                        ? `&missing-finding=true&tab=issues`
                                        : '')
    }


    const displayList = (source, type) => {
        let rows = [];
        source.forEach(
            (item, index) => {
                item.type = type;
                if(
                    (fromNotice === true && type === 'finding' && item.status !== ParameterStore('FINDING_STATUS_DRAFT') && item.status !== ParameterStore('FINDING_STATUS_OPEN'))
                    || (!fromNotice && type === 'finding' && item.status !== ParameterStore('FINDING_STATUS_DRAFT'))
                    || (type === 'notice' && item.status !== ParameterStore('NOTICE_STATUS_DRAFT'))
                ){
                    return;
                }
                rows.push(
                    <TableRow
                        key={'list-' + type + '-' + index}
                    >
                        <TableCell>
                            {
                                errors[item.type] && errors[item.type][item['id']].hasError === true
                                ? <CloseIcon className="text-danger" />
                                : null
                            }
                            {
                                errors[item.type] && errors[item.type][item['id']].hasError === false
                                ? <CheckIcon className="text-success" />
                                : null
                            }
                            {
                                !errors[item.type]
                                || (errors[item.type] && typeof errors[item.type][item['id']] === 'undefined')
                                ? <HourglassEmptyIcon className="text-valid" />
                                : null
                            }
                        </TableCell>
                        <TableCell>
                            {item.title}
                        </TableCell>
                        <TableCell>
                            {item.severityString ?? (item.severity ? APIResourceStore.resources.parameters.getObservableItemByPath(item.severity)?.label : '')}
                        </TableCell>
                        <TableCell>
                            {item.statusString ?? (item.status ? APIResourceStore.resources.parameters.getObservableItemByPath(item.status)?.label : '')}
                        </TableCell>
                        <TableCell>
                            <Link
                                to={getLinkForItem(item, type)}
                                target={'_blank'}
                                style={{textDecoration: 'none'}}
                            >
                                <EditButton disabled={processing}/>
                            </Link>
                        </TableCell>
                    </TableRow>
                )
            }
        );
        return <Table
            className={'table-display small'}
            size={'small'}
        >
            <TableHead>
                <TableRow>
                    <TableCell key={'th_' + type + '_0'}>

                    </TableCell>
                    <TableCell key={'th_' + type + '_1'}>
                        Title
                    </TableCell>
                    <TableCell key={'th_' + type + '_2'}>
                        Severity
                    </TableCell>
                    <TableCell key={'th_' + type + '_3'}>
                        Status
                    </TableCell>
                    <TableCell key={'th_' + type + '_4'}>
                        Actions
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows}
            </TableBody>
        </Table>
    }

    const switchFindingAndNoticeStatus = () => {
        entity.fromNotice = fromNotice ? notices[0]['@id'] : null;
        Http.post(`models/${entity.id}/confirm-finding-notice`, entity).then((_response) => {
            if(confirmationMessage){
                Alert.show({
                    message: confirmationMessage,
                    type: "success"
                });
            }
            resource.getItemFromResourcePath(`api/models/${entity.id}`, true)
            if(resourceNotice && notices.length > 0){
                notices.forEach((item) => {
                    let apiPath = item['@id'] !== undefined ? item['@id'] : (typeof item.id !== undefined ? `api/notices/${item.id}` : item);
                    resourceNotice.getItemFromResourcePath(apiPath, true);
                });
            }
            if(resourceFinding && findings.length > 0){
                findings.forEach((item) => {
                    let apiPath = item['@id'] !== undefined ? item['@id'] : (typeof item.id !== undefined ? `api/finding/${item.id}`  : item);
                    resourceFinding.getItemFromResourcePath(apiPath, true);
                });
            }
        });
    }

    return (
        <Grid container spacing={2} className="container">
            <Grid item xs={12} style={{fontSize:'15px'}}>
                {fromModel ? 'Confirming these issues '
                    : 'Closing the current review '}will validate the following findings and notices, do you confirm ? All the mandatory fields must be filled to proceed.
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} className="container">
                    <Grid item xs={6}>
                        <strong>Required fields for Findings are :</strong>
                        <br />Title,
                        Issuance date,
                        Severity,
                        MRA dimension(s),
                        Weaknesses,
                        Impacts
                    </Grid>
                    <Grid item xs={6}>
                        <strong>Required fields for Notices are :</strong>
                        <br />Title,
                        Validator in charge of the follow-up,
                        Notice Owner,
                        Notice description,
                        Action Plan,
                        Deadline,
                        Association to at least one finding,
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} className="container">
                    <Grid item xs={6}>
                        {displayList(findings, 'finding')}
                    </Grid>
                    <Grid item xs={6}>
                        {displayList(notices, 'notice')}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between"
            }}>
                <Grid container spacing={2} className="container">
                    <Grid item xs={6}>
                        <ActionButton 
                            className={"" /* on écrase */} 
                            onClick={() => {
                                entity.reviewStatus = currentStatus;
                                Modal.close();
                            }}
                        >
                            Cancel
                        </ActionButton>
                    </Grid>
                    <Grid item xs={6} style={{textAlign:'right'}}>
                        <ActionButton
                            loading={processing !== undefined ? processing : false}
                            className={"" /* on écrase */} 
                            onClick={() => findErrors()}
                        >
                            Check{processing && 'ing'} requirements
                        </ActionButton>
                        <ActionButton
                            disabled={globalError || globalError === undefined}
                            onClick={() => {
                                findErrors(() => {
                                    if(fromModel){
                                        switchFindingAndNoticeStatus();
                                    }else{
                                        entity.validate = true;
                                        submitReview(
                                            resource,
                                            entity,
                                            currentStatus,
                                            nextStatus,
                                            resourceEditComponent,
                                            tooltip
                                        );
                                    }
                                    Modal.close();
                                })
                            }}
                        >
                            {fromModel ? 'Confirm these issues' : 'Validate and close'}
                        </ActionButton>
                        
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
ValidateFindingNotice.propTypes = {
    findings: PropTypes.array, 
    notices: PropTypes.array,
    /** Resource des Models */ 
    resource: PropTypes.any, 
    /** Objet Model */
    entity: PropTypes.object, 
    /** @todo Probablement une IRI de Parameter */
    currentStatus: PropTypes.any, 
    /** @todo Probablement une IRI de Parameter */
    nextStatus: PropTypes.any, 
    /** Composant (functionnel ou objet) */
    resourceEditComponent: PropTypes.any, 
    tooltip: PropTypes.string, 
    fromModel: PropTypes.bool,
};

export default ValidateFindingNotice;
