class Config{
    constructor(){
        this.env = 'dev';
        this.backend = 'https://dev.api.mrm.bpce.audience-pro.com';
        this.kms = 'https://kms.mrm.bpce.audience-pro.com/';
        this.api = this.backend + '/api';
        this.notificationRefeshInterval = 60000 // ms
        this.threadRefeshInterval = 20000 // ms
    }
}

export default new Config();