import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField/index";
import Modal from "../../../Services/Modal";
import { DateRangePicker } from "./DateRangePicker";
import DateFormatter from "../../../Services/DateFormatter";

export class ModalDateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.openModalPicker = this.openModalPicker.bind(this);
    this.testEmptyValue = this.testEmptyValue.bind(this);
    this.editProcess = false;
    this.state = {
      value: this.formatValue(this.props.value)
    };
  }

  componentDidMount() {
    this.setState({ value: this.formatValue(this.props.value) });
  }

  componentDidUpdate(prevProps) {

    if( this.props.value && this.props.value !== prevProps.value) {
      this.setState({ value: this.formatValue(this.props.value) });
    }
  }

  handleChange(startDate, endDate) {
    this.editProcess = false;
    this.setState({
      value: this.formatValue({ startDate, endDate })
    });
    if (this.props.onChange) {
      this.props.onChange(startDate, endDate);
    }
  }

  formatValue(options = {}) {
    const { startDate = "", endDate = "" } = options;
    if (!options || (!startDate && !endDate)) {
      return "";
    }
    
    const selectedEndDate = new Date(endDate);

    return (
      DateFormatter.dayIsoToString(startDate) +
      " - " +
      DateFormatter.dayIsoToString(selectedEndDate)
    );
  }

  openModalPicker() {
    this.editProcess = true;
    this.setState({ value: "" });
    Modal.open({
      title: this.props.title ? this.props.title : "Date range",
      modalStyle: {minHeight: "550px"},
      content: <DateRangePicker onChange={this.handleChange.bind(this)} />
    });
  }

  testEmptyValue(_e) {
    if (this.editProcess) {
      this.editProcess = false;
      this.props.onChange(null, null);
    }
  }

  render() {
    return (
      <TextField
        value={this.state.value}
        onClick={this.openModalPicker}
        onFocus={this.testEmptyValue}
        placeholder={this.props.placeholder ? this.props.placeholder : ""}
      />
    );
  }
}
ModalDateRangePicker.propTypes = {
    value: PropTypes.shape({
        startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    }),
    title: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
};
