import React, { useEffect, useState } from 'react';
import { ModalContent } from '../Modal/ModalContent';
import { EntityForm } from '../Forms/EntityForm/EntityForm';
import { NOTICE_FIELDS } from '../../Admin/NoticeAdmin';
import { ButtonBar } from '../Modal/ButtonBar';
import { ActionButton } from '../Modal/ActionButton';
import Modal from '../../Services/Modal';
import Alert from '../../Services/Alert';
import ParameterStore from '../../Store/ParameterStore';

const NoticeReopenForm = (props) => {
    const { resource } = props;

    const [progress, setProgress] = useState(false);
    const [notice, setNotice] = useState();

    useEffect(() => {
        setNotice({ ...props.notice, revisedDeadline: null, revisedDeadlineJustification: null, progress: 0 });
    }, []);

    const fields = {
        revisedDeadline: {
            ...NOTICE_FIELDS().revisedDeadline,
            displayCondition: () => true,
            required: true,
        },
        revisedDeadlineJustification: {
            ...NOTICE_FIELDS().revisedDeadlineJustification,
            displayCondition: () => true,
            required: true,
        },
        comments: {
            ...NOTICE_FIELDS().comments,
            displayCondition: () => true,
            required: false,
        },
        progress: {
            ...NOTICE_FIELDS().progress,
            displayCondition: () => true,
        },
    };

    const onSubmit = async () => {
        if (notice.progress === 100) {
            Alert.show({
                message: 'Progress cannot be 100%.',
                type: 'danger',
            });
            return;
        }
        const emptyFields = Object.keys(fields).filter((k) => (fields[k].required ? !notice[k] : false));
        if (emptyFields.length > 0) {
            Alert.show({
                message: 'Missing fields : ' + emptyFields.map((k) => fields[k].title).join(', '),
                type: 'danger',
            });
            return;
        }

        setProgress(true);

        notice.status = ParameterStore('NOTICE_STATUS_IN_PROGRESS');

        await resource.apiPut(notice);

        Alert.show({ message: 'Notice reopened.', type: 'success' });
        setProgress(false);
        Modal.close();
    };

    return (
        <ModalContent>
            {notice && <EntityForm entity={notice} fields={fields} onUpdate={setNotice} />}
            <ButtonBar>
                <ActionButton loading={progress} onClick={onSubmit}>
                    Reopen notice
                </ActionButton>
            </ButtonBar>
        </ModalContent>
    );
};
export default NoticeReopenForm;
