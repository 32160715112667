/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React from 'react';
import { APIResource } from "../Services/APIResource/APIResource";
import { ChangeLog } from "../Components/Display/ChangeLog/ChangeLog";
import {ROLE} from "../Services/User/User";
import { EntitySelectAsync } from '../Components/Forms/EntitySelectAsync/EntitySelectAsync';
import {BulkEdit} from "../Services/BulkActions/BulkEdit/BulkEdit";
import { 
    userHasRoleADMIN, 
    userHasRoleMRM,
} from '../Store/ParameterStore';
import MappedStringProvider from "../Services/APIResource/FieldProviders/MappedStringProvider";
import EntityAsyncProvider from "../Services/APIResource/FieldProviders/EntityAsyncProvider";

let rolesMap = {
    'ROLE_SUPER_ADMIN': 'Super Administrator',
    'ROLE_ADMIN': 'Administrator',
    'ROLE_MRM': 'Governance',
    'ROLE_IG': 'General Inspection',
    'ROLE_STD_USER': 'Standard User',
};
let adminRolesMap = {
    'ROLE_SUPER_ADMIN': 'Super Administrator',
    'ROLE_ADMIN': 'Administrator',
};
let restrictedRolesMap = {
    'ROLE_MRM': 'Governance',
    'ROLE_IG': 'General Inspection',
    'ROLE_STD_USER': 'Standard User',
};

export const ApiResourceDefaultParams = {
    id: 'users',
    instanceId: 'users',
    name: 'Users',
    icon: 'user',
    fieldForTitle: 'toString',
};

export default class UserAdmin {
    constructor() {
        this.configure();
    }

    configure() {
        // UserRoles
        const users = new APIResource(ApiResourceDefaultParams);
        users
            .setFields({
                id: { title: 'ID' },
                firstName: { title: 'First name' },
                lastName: { title: 'Last name' },
                fullName: {
                    title: 'Full name',
                    displayList: () => null,
                    filter: () => null,
                },
                email: { title: 'E-mail' },
                companyPosition: { title: 'Company position' },
                manager: {
                    title: 'Manager',
                    type: 'user',
                    params: {
                        resource: 'users',
                        instanceId: 'users_all',
                        displayField: 'toString',
                        links: true,
                        endpoints: {
                            getAll: 'users/all-users/all',
                        },
                    },
                    edit: () => null,
                },
                teams: {
                    title: 'Team',
                    type: 'entityTree',
                    params: {
                        resource: 'scopes',
                        instanceId: 'scopes_complete',
                        childrenPropertyName: 'childScopes',
                        displayField: 'title',
                        links: true,
                        linkPath: (entity) => `/resource/scopes/${entity.id}/detail`,
                        multi: true,
                        endpoints: {
                            getAll: 'scopes/all-scopes/complete',
                        },
                    },
                    filter: (field, onChange, value) => {
                        return (
                            <EntitySelectAsync
                                label={null}
                                resourceId={field.params.resource}
                                instanceId={field.params.instanceId}
                                resourceLabel={field.params.displayField}
                                value={value}
                                onChange={onChange}
                                clearable={true}
                                multi={field.params.multi}
                                endpoints={field.params.endpoints}
                            />
                        );
                    },
                    bulk: true
                },
                followedScopes: {
                    title: 'Followed teams',
                    type: 'entityTree',
                    params: {
                        resource: 'scopes',
                        instanceId: 'scopes_complete',
                        childrenPropertyName: 'childScopes',
                        displayField: 'title',
                        links: true,
                        linkPath: (entity) => `/resource/scopes/${entity.id}/detail`,
                        multi: true,
                        endpoints: {
                            getAll: 'scopes/all-scopes/complete',
                        },
                    },
                    filter: (field, onChange, value) => {
                        return (
                            <EntitySelectAsync
                                label={null}
                                resourceId={field.params.resource}
                                instanceId={field.params.instanceId}
                                resourceLabel={field.params.displayField}
                                value={value}
                                onChange={onChange}
                                clearable={true}
                                multi={field.params.multi}
                                endpoints={field.params.endpoints}
                            />
                        );
                    },
                },
                establishment: {
                    title: 'Establishment',
                    type: 'entityAsync',
                    params: {
                        resource: 'establishments',
                        displayField: 'title',
                        endpoints: {
                            getAll: 'establishments/all',
                        },
                    },
                },
                roles: {
                    title: 'Roles',
                    type: 'mappedObject',
                    params: {
                        mapping: rolesMap,
                        multi: true
                    },
                    token: false,
                    bulk: true,
                    edit: (field, value, onChange, entity, routeParams) => {
                        if(userHasRoleADMIN()){
                            field.params.mapping = rolesMap;
                            return MappedStringProvider.getEdit(field, value, onChange, entity, routeParams);
                        }else{
                            if(Object.keys(adminRolesMap).some(r=> value.includes(r))){
                                return MappedStringProvider.getDisplay(field, value, entity);
                            }
                        }
                        field.params.mapping = restrictedRolesMap;
                        return MappedStringProvider.getEdit(field, value, onChange, entity, routeParams);
                    },
                    filter: (field, onChange, value) => {
                        field.params.mapping = rolesMap;
                        return MappedStringProvider.getFilter({field, value, onChange});
                    },
                },
                overrideIAM: {
                    type: 'bool',
                    title: 'Sigma/Thor override',
                    required: true,
                    bulk: true,
                    helperText: "Give access to the tool regardless of the user's status in Sigma or Thor",
                },
                changeLogsEntities: {
                    title: 'Audit trail',
                    display: (field, value, entity, props) => (
                        <ChangeLog
                            field={field}
                            values={value}
                            entity={entity}
                            entityResource={'users'}
                            props={props}
                        />
                    ),
                    displayList: () => null,
                },

                //Additional fields without relation with User
                changeLogComment: {
                    title: 'Justification of the data update',
                    type: 'textarea',
                    display: (field, value, entity, props) => null,
                    displayList: (field, value, entity, props) => null,
                    token: false,
                },
            })
            .setLayout({
                tabs: {
                    'Personal Information': {
                        rows: [
                            {
                                panels: {
                                    Identity: {
                                        cols: 6,
                                        fields: ['firstName', 'lastName', 'companyPosition', 'teams', 'followedScopes', 'establishment', 'manager'],
                                    },
                                    Contacts: {
                                        cols: 6,
                                        fields: ['email'],
                                    },
                                },
                            },
                        ],
                    },
                    Access: {
                        rows: [
                            {
                                panels: {
                                    Roles: {
                                        cols: 12,
                                        fields: ['roles', 'overrideIAM'],
                                    },
                                },
                            },
                        ],
                    },
                    'Audit trail': {
                        rows: [
                            {
                                panels: {
                                    'Audit trail': {
                                        cols: 12,
                                        fields: ['changeLogsEntities', 'changeLogComment'],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .genListView({
                fields: ['id', 'firstName', 'lastName', 'email', 'roles', 'companyPosition'],
                menuItem: { title: 'Users' },
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .genInsertView({
                fields: [
                    'firstName',
                    'lastName',
                    'email',
                    'roles',
                    'overrideIAM',
                    'companyPosition',
                    'teams',
                    'establishment',
                ],
                menuItem: { title: 'Add' },
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .genEditView({
                fields: [
                    'firstName',
                    'lastName',
                    'email',
                    'roles',
                    'overrideIAM',
                    'companyPosition',
                    'teams',
                    'establishment',
                    'changeLogComment',
                ],
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .genDetailView({
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .allowDelete()
            .addBulkAction(BulkEdit, {
                resource: users,
                icon: 'edit',
                itemAccessCondition: (_entity) => userHasRoleMRM() || userHasRoleADMIN(),
                fields: Object.fromEntries(
                    Object.entries(users.fields)
                        .filter(([k, v]) => {
                            return v.bulk && users.operations.edit.fields.includes(k);
                        })
                        .map(([k, v]) => ((v.resourceId = users.id), [k, v]))
                ),
            })
        ;
    }
}
