import APIResourceStore from './APIResourceStore';
import { APIResource } from '../Services/APIResource/APIResource';
import User, { ROLE } from '../Services/User/User';
import { getIdFromIri } from '../Services/utils';

/**
 * Retourne un paramètre ID (son Iri) via son ID système
 * @param systemId string ID système
 * @returns {false|string} Retourne false au premier appel si la liste des paramètres n'est pas initialisée
 */
export default function getParamId(systemId) {
    return APIResourceStore.resources.parameters.getIdFromSystemId(systemId);
}

/**
 *
 * @param {String} parameterIri - Iri du parameter demandé (ex: /api/parameters/432)
 * @returns {Object}
 */
export function getParamByIri(parameterIri) {
    let id = getIdFromIri(parameterIri);
    return APIResourceStore.resources.parameters.getObservableItem(id);
}
/**
 *
 * @param {String} systemId - SystemId (par ex : REVIEW_TYPE_PERIODIC) du Parameter à récupérer
 * @returns {Object} - Objet de type Parameter
 */
export function getParamBySystemId(systemId) {
    let iri = getParamId(systemId);
    let res = iri ? getParamByIri(iri) : {};
    return res;
}

export const BusinessRole = {
    IS_VALIDATOR: 'IS_VALIDATOR',
    IS_OWNER: 'IS_OWNER',
    IS_DEVELOPER: 'IS_DEVELOPER',
    IS_DECLARER: 'IS_DECLARER',
    IS_STAKEHOLDER: 'IS_STAKEHOLDER',
    IS_MRMTEAM: 'IS_MRMTEAM',
}

// Crée la resource pour les scopes
const scopeResource = new APIResource({
    id: 'scopes',
    instanceId: 'parameter_store_scopes',
});

/**
 * Retourne un scope ID via son ID système
 * @param systemId string ID système
 * @returns {false|string} Retourne false au premier appel si la liste des scopes n'est pas initialisée
 */
function getScopeId(systemId) {
    return scopeResource.getIdFromSystemId(systemId);
}

/**
 * Retourne une fonction (model) => {} qui retourne true si
 * - groupEntities contient l'un des scopeSystemId
 *
 * @param groupEntities Liste d'ID système de Scopes
 * @returns {function(*=): number}
 */
function hasGroupEntity(...groupEntities) {
    return (model) => {
        let foundOneOrMore = groupEntities
            .map((groupEntitySystemId) => {
                let groupEntityId = getScopeId(groupEntitySystemId);

                // Si group entity override est définit, on vérifie qu'il contient notre groupe
                if (model.groupEntities && model.groupEntities.length) {
                    return model.groupEntities.includes(groupEntityId);
                }

                //On regarde son groupEntities du serveur
                return (model.groupEntities || []).filter((ge) => ge.systemId === groupEntitySystemId).length;
                // si au moins un group entity est vrai
            })
            .filter((v) => v).length;

        return foundOneOrMore;
    };
}

/**
 * Retourne une fonction (user) => {} qui retourne true si
 * - groupEntities contient l'un des scopeSystemId
 *
 * @param groupEntities Liste d'ID système de Scopes
 * @returns {function(*=): number}
 */
function userHasGroupEntity(...groupEntities) {
    return (user) => {
        let foundOneOrMore = groupEntities
            .map((groupEntitySystemId) => {
                let groupEntityId = getScopeId(groupEntitySystemId);

                // Si group entity override est définit, on vérifie qu'il contient notre groupe
                if (user.groupEntity) {
                    return user.groupEntity === groupEntityId;
                }
            })
            .filter((v) => v).length;

        return foundOneOrMore;
    };
}

const GROUP_BPCE = 'BPCE';
const GROUP_NTX = 'NTX';
const GROUP_NTXUS = 'NTXUS';

export const hasGroupEntityBPCE = hasGroupEntity(GROUP_BPCE);
export const hasOneGroupEntityNTX = hasGroupEntity(GROUP_NTX, GROUP_NTXUS);

export const userhasGroupEntityBPCE = userHasGroupEntity(GROUP_BPCE);
export const userhasOneGroupEntityNTX = userHasGroupEntity(GROUP_NTX, GROUP_NTXUS);

/**
 * @param {string|number} userId
 * @param {{modelOwner: string, modelOwnerDelegation: string, modelOwnerDelegationAccepted: boolean}} entity
 * @returns {boolean}
 */
export const userHasOwnershipRights = (userId, entity) => {
    return (
        entity &&
        (entity.modelOwner === `/api/users/${userId}` ||
            (entity.modelOwnerDelegation === `/api/users/${userId}` && entity.modelOwnerDelegationAccepted))
    );
};

/**
 *
 * @param {Array<string>} userTeamPaths
 * @param {{modelDeveloperTeam: string}} entity
 * @returns
 */
export const userHasDeveloperTeamRights = (userTeamPaths, entity) => {
    return entity && userTeamPaths.includes(entity.modelDeveloperTeam);
};

export const userHasImplementerTeamRights = (userTeamPaths, entity) => {
    if (entity.modelImplementerTeam) {
        return entity && userTeamPaths.includes(entity.modelImplementerTeam);
    } else if (entity.modelImplementerTeams) {
        if (entity.modelImplementerTeams.some((r) => userTeamPaths.indexOf(r) >= 0)) {
            return true;
        }
    } else if (entity.implementationsTeams) {
        if (entity.implementationsTeams.some((r) => userTeamPaths.indexOf(r['@id']) >= 0)) {
            return true;
        }
    }
    return false;
};

/**
 *
 * @param {Array<string>} userTeamPaths
 * @param {{modelValidatorTeams: Array<string>, validatorTeams: Array<string>}} entity
 * @returns
 */
export const userHasValidatorTeamRights = (userTeamPaths, entity) => {
    if (entity.modelValidatorTeams) {
        if (entity && userTeamPaths && (entity.modelValidatorTeams.some((r) => userTeamPaths.indexOf(r) >= 0) || entity.modelValidatorTeams.some((r) => userTeamPaths.indexOf(r['@id']) >= 0))) {
            return true;
        }
    } else if (entity.validatorTeams) {
        if (entity && userTeamPaths && (entity.validatorTeams.some((r) => userTeamPaths.indexOf(r) >= 0) || entity.validatorTeams.some((r) => userTeamPaths.indexOf(r['@id']) >= 0))) {
            return true;
        }
    }
    return false;
};

export const userHasContributingRights = (userId, entity) => {
    return entity && entity.contributors && entity.contributors.includes(`/api/users/${userId}`);
};

/**
 *
 * @param {Array<string>} userManagedScopesPaths
 * @param {{modelValidatorTeams: Array<string>, validatorTeams: Array<string>}} entity
 * @returns
 */
export const userValidatorTeamManagedRights = (userManagedScopesPaths, entity) => {
    if (Array.isArray(entity.modelValidatorTeams)) {
        if (
            entity &&
            userManagedScopesPaths &&
            (entity.modelValidatorTeams.some((r) => userManagedScopesPaths.indexOf(r) >= 0) || entity.modelValidatorTeams.some((r) => userManagedScopesPaths.indexOf(r['@id']) >= 0))
        ) {
            return true;
        }
    } else if (Array.isArray(entity.validatorTeams)) {
        if (
            entity &&
            userManagedScopesPaths &&
            (entity.validatorTeams.some((r) => userManagedScopesPaths.indexOf(r) >= 0) || entity.validatorTeams.some((r) => userManagedScopesPaths.indexOf(r['@id']) >= 0))
        ) {
            return true;
        }
    }
    return false;
};

/**
 * @param {string} userId
 * @param {{assignedValidator: string}} entity
 * @returns {boolean}
 */
export const userHasValidatorRights = (userId, entity) => {
    return entity && entity.assignedValidator === `/api/users/${userId}`;
};

/**
 * @param {string} userId
 * @param {{declarer: string}} entity
 * @returns {boolean}
 */
export const userHasDeclarerRights = (userId, entity) => {
    return entity && entity.declarer === `/api/users/${userId}`;
};

/**
 * @param {string} userId
 * @param {{businessSponsor: string}} entity
 * @returns {boolean}
 */
export const userHasBusinessSponsorRights = (userId, entity) => {
    return entity && entity.businessSponsor === `/api/users/${userId}`;
};

export const userHasRole = (role) => User.hasOneRole(role);
export const userHasRoleMRM = () => User.hasOneRole(ROLE.MRM);
export const userHasRoleSTD = () => User.hasOneRole(ROLE.STD_USER);
export const userHasRoleADMIN = () => User.hasOneRole(ROLE.ADMIN) || User.hasOneRole(ROLE.SUPER_ADMIN);
export const userHasRoleIG = () => User.hasOneRole(ROLE.IG);
export const userHasSpecificRole = (role) => User.hasSpecificRole(role);

export const userIsLod1 = (user, model) => {
    return (
        model.modelOwner === `/api/users/${user.profile.id}` ||
        model.modelOwnerDelegation === `/api/users/${user.profile.id}` ||
        userHasDeveloperRights(user, model)
    );
};
export const userIsLod2 = (user, model) => {
    // see https://app.asana.com/0/1134038211401766/1200301008806347/f
    if (userHasRoleMRM() || userHasRoleADMIN()) {
        return true;
    }

    return (
        model &&
        user.profile &&
        user.profile.teams &&
        (model.modelValidatorTeams?.some((r) => user.profile.teams.indexOf(r) >= 0) ||
            model.modelValidatorTeams?.some((r) => user.profile.followedScopes.indexOf(r) >= 0) ||
            model.modelValidatorTeams?.some((r) => user.profile.managedScopes.indexOf(r) >= 0))
    );
};

export const userHasStakeHolderRights = (user, entity) => {
    return (
        userHasOwnershipRights(user.getId(), entity) ||
        userHasDeveloperRights(user, entity) ||
        userHasImplementerRights(user, entity) ||
        userHasBusinessSponsorRights(user.getId(), entity)
    );
};

/**
 * @param {string|number} userId
 * @param {{noticeOwners: Array<string>}} entity
 * @returns {boolean}
 */
export const userHasNoticeOwnershipRights = (userId, entity) => {
    return entity && entity.noticeOwners && entity.noticeOwners.includes(`/api/users/${userId}`);
};

/**
 * @param {string|number} userId
 * @param {{noticeValidators: Array<string>}} entity
 * @returns {boolean}
 */
export const userHasInChargeRights = (userId, entity) => {
    return (
        entity &&
        entity.noticeValidators &&
        Array.isArray(entity.noticeValidators) &&
        entity.noticeValidators.some(function (val) {
            if (typeof val === 'string') {
                return val === `/api/users/${userId}`;
            }
            return val['@id'] === `/api/users/${userId}`;
        })
    );
};

/**
 * Attention : NoticeIssuer et pas Issuer !
 * @param {string|number} userId
 * @param {{noticeIssuer: string}} entity
 * @returns {boolean}
 */
export const userHasIssuerRights = (userId, entity) => {
    return entity && entity.noticeIssuer === `/api/users/${userId}`;
};

export const userHasIssuerTeamsRights = (user, entity) => {
    if (entity && entity.issuerTeams) {
        return entity.issuerTeams.reduce((prev, t) => prev || user.profile.teams.includes(t['@id']), false);
    }

    return false;
};

export const USER_SCOPE_MANAGER = 'managedScopes';
export const USER_SCOPE_MEMBER = 'teams';
export const USER_SCOPE_FOLLOWER = 'followedScopes';
export const USER_SCOPE_ALL = 'all';

/**
 * Détermine si un utilisateur est follower/manager/member de la team donnée.
 *
 * Pour tester tous les cas, utiliser USER_SCOPE_ALL
 *
 * @param {Object} User
 * @param {Object} entity
 * @param {string} teamsProperty Propriété "teams" pour laquelle on veut tester un des droits manager/member/follower ou tous
 * @param {USER_SCOPE_ALL|USER_SCOPE_FOLLOWER|USER_SCOPE_MANAGER|USER_SCOPE_MEMBER} scope Utiliser les const USER_SCOPE_* adéquates, default MEMBER
 * @returns
 */
export const userHasRights = (User, entity, teamsProperty, scope = USER_SCOPE_MEMBER) => {
    const scopes = scope === USER_SCOPE_ALL ? [USER_SCOPE_FOLLOWER, USER_SCOPE_MANAGER, USER_SCOPE_MEMBER] : [scope];

    return scopes.some(function (scope) {
        if (
            entity !== undefined &&
            entity[teamsProperty] !== undefined &&
            User.profile !== undefined &&
            User.profile[scope] !== undefined
        ) {
            if (Array.isArray(entity[teamsProperty])) {
                return entity[teamsProperty].some((r) => {
                    // ModelController ne renvoie pas toujours les données dans le format correct des autres appels
                    // donc on doit aussi tester r['@id'] si r est un objet au lieu d'une IRI.
                    if (typeof r !== 'string'){
                        return User.profile[scope].indexOf(r['@id']) >= 0;
                    }
                    return User.profile[scope].indexOf(r) >= 0;
                });
            } else {
                return User.profile[scope].includes(entity[teamsProperty]);
            }
        } else if (
            entity !== undefined &&
            entity[teamsProperty] !== undefined &&
            User !== undefined &&
            User[scope] !== undefined
        ) {
            if (Array.isArray(entity[teamsProperty])) {
                return entity[teamsProperty].some((r) => {
                    // ModelController ne renvoie pas toujours les données dans le format correct des autres appels
                    // donc on doit aussi tester r['@id'] si r est un objet au lieu d'une IRI.
                    if (typeof r !== 'string'){
                        return User.profile[scope].indexOf(r['@id']) >= 0;
                    }
                    return User[scope].indexOf(r) >= 0;
                });
            } else {
                return User[scope].includes(entity[teamsProperty]);
            }
        }
        return false;
    });
};

export const userHasNoticeIssuerTeamsRights = (user, entity) => {
    if (entity && entity.noticeIssuerTeams) {
        return entity.noticeIssuerTeams.reduce((prev, t) => prev || user.profile.teams.includes(t['@id']), false);
    }

    return false;
};

/** Si l'utilisateur est "VAL" pour l'entité données.
 *
 * Doit remplacer les appels à Validator + ValidatorTeams etc.
 * sauf quand seul le AssignedValidator est demandé.
 *
 * @param {object} user Objet User (@see Services/User/User)
 * @param {{assignedValidator: string, noticeValidators: Array<string>, modelValidatorTeams: Array<string>, validatorTeams: Array<string>}} entity Model ou Review en particulier
 * @returns {boolean}
 */
export const userIsVal = (user, entity) => {
    return (
        entity &&
        (userHasValidatorRights(user.getId(), entity) ||
            userHasInChargeRights(user.getId(), entity) ||
            userValidatorTeamManagedRights(user.profile.managedScopes, entity) ||
            userHasValidatorTeamRights(user.profile.teams, entity) ||
            userHasValidatorTeamRights(user.profile.followedScopes, entity) ||
            (entity['@type'] && entity['@type'] === "Model" && User.profile.isMemberOfValidatorTeam && entity.declarer === '/api/users/' + User.getId())
        )
    );
};

export const userHasNoRole = (user, entity) => {
    return (
        !userIsVal(user, entity) &&
        !userHasDeclarerRights(user.getId(), entity) &&
        !userHasContributingRights(user.getId(), entity) &&
        !userHasStakeHolderRights(user, entity) &&
        !userHasRoleIG() &&
        !userHasRoleMRM() &&
        !userHasRoleADMIN()
    );
};

/**
 * ATTENTION : il existe deux moyens de tester les droits MRM :
 * - {@see userHasRoleMRM()} : si l'utilisateur a le ROLE_MRM en back
 * - {@see userHasMRMRights(User, model)} : si l'utilisateur a un lien avec la MRM Team du model donné
 *
 * Il faut donc utiliser les 2 appels différemment en fonction du besoin.
 *
 * @param {*} user
 * @param {*} model
 * @returns {boolean} Si l'utilisateur a les droits MRM sur le model donné
 */
export const userHasMRMRights = (user, model) => {
    if (userHasRoleADMIN()) return true;
    if (!userHasRoleMRM()) return false;

    return userHasRights(user, model, 'mrmTeams', USER_SCOPE_ALL);
};

/**
 *
 * @param {{profile: any, managedScopes: any, teams: any, followedScopes: any}} user
 * @param {{modelDeveloperTeam: string}} entity
 * @returns boolean
 */
export const userHasDeveloperRights = (user, entity) => {
    return (
        entity &&
        ((user.profile &&
            ((Array.isArray(user.profile.managedScopes) &&
                userHasDeveloperTeamRights(user.profile.managedScopes, entity)) ||
                (Array.isArray(user.profile.teams) && userHasDeveloperTeamRights(user.profile.teams, entity)) ||
                (Array.isArray(user.profile.followedScopes) &&
                    userHasDeveloperTeamRights(user.profile.followedScopes, entity)))) ||
            (!user.profile &&
                ((Array.isArray(user.managedScopes) && userHasDeveloperTeamRights(user.managedScopes, entity)) ||
                    (Array.isArray(user.teams) && userHasDeveloperTeamRights(user.teams, entity)) ||
                    (Array.isArray(user.followedScopes) && userHasDeveloperTeamRights(user.followedScopes, entity)))))
    );
};

export const userHasImplementerRights = (user, entity) => {
    return (
        entity &&
        ((user.profile &&
            ((Array.isArray(user.profile.managedScopes) &&
                userHasImplementerTeamRights(user.profile.managedScopes, entity)) ||
                (Array.isArray(user.profile.teams) && userHasImplementerTeamRights(user.profile.teams, entity)) ||
                (Array.isArray(user.profile.followedScopes) &&
                    userHasImplementerTeamRights(user.profile.followedScopes, entity)))) ||
            (!user.profile &&
                ((Array.isArray(user.managedScopes) && userHasImplementerTeamRights(user.managedScopes, entity)) ||
                    (Array.isArray(user.teams) && userHasImplementerTeamRights(user.teams, entity)) ||
                    (Array.isArray(user.followedScopes) && userHasImplementerTeamRights(user.followedScopes, entity)))))
    );
};

export const amIAuthor = (entity) => {
    return entity.author === `/api/users/${User.getId()}`;
}
