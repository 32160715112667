import Http from '../../Http';
import Alert from "../../Alert";
import React, {Component} from "react";
import Modal from "../../Modal";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {DatePicker} from "../../../Components/Forms/DatePicker/DatePicker";
import Grid from "@material-ui/core/Grid";

export class ChangeLogExport{
    getId(){
        return 'changeLogCsvExport'
    }

    getLabel(){
        return 'Change logs export'
    }

    setParameters(params){
        this.parameters = params;
    }

    run(ids, params = null){
        this.ids = ids;

        Modal.open({
            title: 'Change logs export',
            content: <ChangeLogExportForm ids={this.ids} from={this.parameters !== undefined && this.parameters.from !== undefined ? this.parameters.from : null}/>
        });
    }
}


export class ChangeLogExportForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            progress: false
        };
    }

    save(){
        this.setState({progress: true});
        Http.post('change-logs/export', {ids: this.props.ids, from: this.props.from }).then((response) => {
            if(response.status === 'ERROR'){
                Alert.show({ message : response.message, type: 'danger'});
            }else if(response.status === 'OK'){
                if(response.route && response.fileName){
                    Http.openFile(response.route, response.fileName);
                }
            }else{
                Alert.show({ message : "File can't be downloaded"});
            }
            Modal.close();
            this.setState({progress: false});
        });
    }

    render() {
        return (
            <Grid className={'export container'}>
                { this.state.progress ?
                    <div className={"export_progress_container"}>
                        <CircularProgress />
                        <p>Please wait....</p>
                    </div>
                    :
                    <div>
                        <Button style={{marginTop: 10}} variant="contained" color="secondary" className="save button-general" onClick={this.save.bind(this)}>
                            Export
                        </Button>
                    </div>
                }
            </Grid>
        )
    }
}