import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Modal from "../../Services/Modal";
import Alert from "../../Services/Alert";
import {CircularProgress} from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import Http from "../../Services/Http";

export class ReviewHoldModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            holdComment: null
        };
    }

    hold(review) {

        if (this.state.holdComment === null) {
            Alert.show({
                message: "The justification is required.",
                type: "danger"
            });
            return;
        }

        this.processing = true;
        this.setState({processing: true});

        review.onHold = true;
        review.holdComment = this.state.holdComment;
        
        Http.post('reviews/hold', {
            '@id': review['@id'],
            'holdComment': review.holdComment,
            'author': review.author,
            'models': review.models
        })
            .then(() => {
                Modal.close();
                Alert.show({
                    message: 'The review was successfully put on hold.',
                    type: 'success',
                });
                this.setState({processing: false});
                this.props.resource.getItemFromResourcePath(`api/reviews/${review.id}`, true)
            })
            .catch((err) => {
                this.setState({processing: false});
                Modal.close();
            });
    }

    render() {
        return (
            <div>
                <p>Are you sure you want to put this review on hold ?</p>
                <FormControl className="container_textarea">
                    <label>Please explain your decision</label>
                    <TextareaAutosize
                        value={this.state.holdComment ? this.state.holdComment : ""}
                        onChange={(event) => this.setState({ holdComment: event.target.value })}
                        rows={5}
                        rowsMax={10}
                        required={true}
                    />
                </FormControl>
                <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Button style={{marginTop: 10}} variant="contained" color="secondary" className="save button-general" onClick={Modal.close}>
                        Cancel
                    </Button>
                    {
                        this.state.processing === true
                            ?
                            <Button
                                style={{ marginTop: 10 }}
                                variant="contained"
                                color="secondary"
                                className="save button-general"
                            >
                                <CircularProgress size={20} />
                            </Button>
                            :
                            <Button style={{marginTop: 10}} variant="contained" color="secondary" className="save button-general" onClick={() => this.hold(this.props.entity)}>
                                On hold
                            </Button>
                    }
                </div>
            </div>
        );
    }
}
