import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { QuickSelectForm } from '../Forms/QuickSelectForm/QuickSelectForm';
import { APIResource } from '../../Services/APIResource/APIResource';
import Modal from '../../Services/Modal';
import { userHasRoleMRM } from '../../Store/ParameterStore';
import { isValOfFinding } from '../../Admin/FindingAdmin';
import { ActionLink } from '../ActionLink/ActionLink';
import {PARAMETER_TYPE_FINDING_NOTICE_SEVERITY, PARAMETER_TYPE_NOTICE_STATUS} from "../../Admin/ParameterAdmin";

export const NoticeQuickSelect = (props) => {
    const { finding } = props;

    /** @type {import("../../Services/APIResource/APIResource").APIResource} */
    const resource = props.resource;
    const { instanceId = 'notices', endpoints } = props;

    const {
        label="Associate to an existing notice.",
        multi = false,
        withModal = true,
        inModal = false,
        clearable,
        filters,
        context,
    } = props;

    const showOpenModalButton = inModal ? false : withModal;

    const [loaded, setLoaded] = useState(false);

    /**
     * @type {React.MutableRefObject<APIResource>} resourceNotices contient une APIResource dans "current"
     */
    const resourceNotices = useRef();
    useEffect(() => {
        resourceNotices.current = new APIResource({
            id: 'notices',
            instanceId: instanceId,
            endpoints: endpoints || null,
        });
    }, [endpoints, instanceId]);

    /** Dans le cas d'une création, on n'a pas à attendre que l'entité soit chargée. */
    useEffect(() => {
        if (context === 'add') setLoaded(true);
    }, []);

    useEffect(() => {
        if (finding && finding.id) setLoaded(true);
    }, [finding]);

    // Renvoie les notices qui respectent les filtres demandés.
    const apiSearchNotice = async () => {
        const apiRequests = [
            // Toutes les notices qui ont un modèle du finding courant, sachant que les models de la notice
            // sont eux mêmes portés par les findings associés à la notice.
            resourceNotices.current.apiGetCollection({
                page: 1,
                rowsPerPage: 10000,
                filters: { 'findings.models.findings[]': finding['@id'] },
            }),
        ];

        const responses = await Promise.all(apiRequests);
        const items = responses.reduce((p, c) => [...p, ...c], []); // responses est un tableau de tableaux

        if (filters) {
            return resourceNotices.current.filter(items, filters, finding);
        }
        return items;
    };

    const disableValue = (value) => {
        if (userHasRoleMRM()) {
            // Dans ce cas on veut toutes les notices qui ont un modèle attaché au finding :
            // on ne fait rien de plus.
            return false;
        } else if (isValOfFinding(finding)) {
            return !(
                value.models.length === finding.models.length && value.models.every((e) => finding.models.includes(e))
            );
        }
        return false;
    };

    const columns = [
        {
            label: 'ID',
            field: 'id',
            forceDisplay: true,
            display: (field, value, entity) => <ActionLink to={`/resource/notices/${entity.id}/detail`} target="_blank">{value}</ActionLink>,
        },
        { 
            label: 'Title',
            field: 'title',
            forceDisplay: true,
            display: (field, value, entity) => <ActionLink to={`/resource/notices/${entity.id}/detail`} target="_blank">{value}</ActionLink>,
            tooltip: (field, value, entity) => entity.noticeDescription,
        },
        {
            label: 'Status',
            field: 'status',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_NOTICE_STATUS,
                multi: false,
            },
            forceDisplay: true,
        },
        {
            label: 'Severity',
            field: 'noticeSeverity',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_FINDING_NOTICE_SEVERITY,
                multi: false,
            },
            forceDisplay: true,
        },
        {
            label: 'Models',
            field: 'modelsEntities',
            display: (field, value) => value.map((v, i) => <>{i > 0 && ","}<ActionLink key={`modellink_${i}`} to={`/resource/models/${v['id']}/detail`} target="_blank">{v['functionalID']}</ActionLink></>),
        },
    ];

    const onChange = async (iris) => {
        const notices = await Promise.all(iris.map(iri => resourceNotices.current.getItemFromResourcePath(iri)));

        // Les listeners de severity se déclenchent sur l'update de Notice, donc on commence par là.
        await Promise.all(
            notices.map(notice => resourceNotices.current.apiPut({
                ...notice, 
                findings: [...(notice?.findings || []), finding['@id']]
            }))
        )
        // Puis on recharge le finding.
        await resource.apiGetOne(finding.id, true);
    };

    const openNoticeQuickSelectModal = () => {
        Modal.open({
            title: label,
            content: (
                <QuickSelectForm
                    label={label}
                    values={finding.notices}
                    onChange={onChange}
                    multi={finding.notices && finding.notices.length > 1 ? true : multi}
                    resource={resourceNotices.current}
                    resourceName="notice"
                    apiSearch={apiSearchNotice}
                    disableValue={disableValue}
                    clearable={clearable}
                    withModal={withModal}
                    autoSearch={true}
                    columns={columns}
                    selectLabel="Select an existing Notice"
                />
            ),
        });
    };

    return (
        <>
            {showOpenModalButton && (
                <Button
                    variant="contained"
                    color="primary"
                    style={styles.green}
                    disabled={!loaded}
                    onClick={openNoticeQuickSelectModal}
                >
                    {loaded ? 'Select' : <LoadingIndicator styles={styles.loadingIndicator} />}
                </Button>
            )}
            {!showOpenModalButton && !loaded && <LoadingIndicator styles={styles.loadingIndicator} />}
            {!showOpenModalButton && loaded && (
                <QuickSelectForm
                    label={label}
                    values={finding.notices}
                    onChange={onChange}
                    multi={finding.notices && finding.notices.length > 1 ? true : multi}
                    resource={resourceNotices.current}
                    resourceName="notice"
                    apiSearch={apiSearchNotice}
                    disableValue={disableValue}
                    clearable={clearable}
                    withModal={true}
                    autoSearch={true}
                    columns={columns}
                    selectLabel="Select an existing Notice"
                />
            )}
        </>
    );
};
NoticeQuickSelect.propTypes = {
    label: PropTypes.string,
    //values: PropTypes.arrayOf(PropTypes.string),
    finding: PropTypes.object,
    multi: PropTypes.bool,
    clearable: PropTypes.bool,
    /**
     * Resource des Findings à mettre à jour avec les nouvelles notices.
     * @type {import("../../Services/APIResource/APIResource").APIResource}
     */
    resource: PropTypes.object,
    /** Instance Id et endpoints de la resource des notices */
    instanceId: PropTypes.string,
    endpoints: PropTypes.object,
    /** Affiche un bouton pour ouvrir la modal (default), ou directement le form */
    withModal: PropTypes.bool,
    /**
     * Indique si le composant est déjà affiché dans une Modal (default false),
     * auquel cas le bouton d'ouverture de modal n'est pas affiché, indépendamment de la valeur de withModal.
     */
    inModal: PropTypes.bool,
    /** Filters est de la forme "predicate", cf APIResource */
    filters: PropTypes.any,
    /** Contexte pour déterminer si on doit attendre le chargement de l'entité avant activation */
    context: PropTypes.oneOf(['edit', 'add']),
};

const styles = {
    green: {
        backgroundColor: '#0dbbb7',
        alignSelf: 'flex-end',
        marginLeft: '10px',
        maxHeight: '32px',
    },
    search: {
        marginTop: '20px',
    },
    loadingIndicator: {
        display: 'flex',
        transition: 'color 150ms',
        alignSelf: 'center',
        fontSize: '0.5rem',
        margin: '0.5rem',
        textAlign: 'center',
    },
};
