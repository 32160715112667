import Navigation from "../../Services/Navigation";
import User from "../../Services/User/User";
import APIResourceFactory from "../../Services/APIResource/APIResourceFactory";//Ne pas supprimer
import { APIResource } from "../../Services/APIResource/APIResource";
import React, { Component } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "typeface-roboto/index.css";
import { Link } from "react-router-dom";
import MainMenu from "../MainMenu/MainMenu";
import RouteStore from "../../Store/RouteStore";
import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import AppBarStore from "../../Store/AppBarStore";
import { observer } from "mobx-react";
import Alert, { AlertProvider } from "../../Services/Alert";
import Modal from "../../Services/Modal";
import ModalProvider from "../../Services/ModalProvider";
import "../../Config/Routes";
import { Toolbar, AppBar, Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { NotificationMenu } from "../Display/NotificationMenu/NotificationMenu";
import MenuLogo from "../../Styles/Assets/img/menu.png";
import CloseIcon from "../../Styles/Assets/img/close icon.png";
import MainLogo from "../../Styles/Assets/img/logo-bpce.png";
import { userHasRoleMRM } from "../../Store/ParameterStore";
import Search from "../Search/Search";
import ModalComplementary from "../../Services/ModalComplementary";

const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#ac0577"
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: "#59237f"
        },
        success: {
            main: "#448e39"
        },
        warning: {
            main: "#faa61a"
        },
        danger: {
            main: "#f05665"
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2
    }
});

class Root extends Component {
    constructor(props) {

        super(props);
        this.state = {
            sideMenuClass: "side-menu-closed",
            open: false,
            loading: true
        };

        setTimeout(() => this.setState({loading: false}), 7000);

        this.anchorRef = React.createRef();

    }

    async componentDidMount() {
        // double check that we have the user id to avoid api issues
        if (!User.getId()) {
            await User.restore();
        }
        // On attend 7 secondes avant d'afficher la modale de reprise de session (si applicable)
        // car ce process nécessite qu'on ait preloadé l'ensemble l'endpoint models/getAll
        // sans quoi le retour de backend écrase la version locale.
        // Peut être amélioré mais fonctionnel en l'état.
        setTimeout(APIResource.checkExistingLocalCurrentEditingEntity, 7000);
    }

    toggleMenu() {
        if (this.state.sideMenuClass === "side-menu-closed") {
            this.setState({ sideMenuClass: "side-menu-open" });
        } else {
            this.setState({ sideMenuClass: "side-menu-closed" });
        }
    }

    closeMenu() {
        if (this.state.sideMenuClass != "side-menu-closed") {
            this.setState({ sideMenuClass: "side-menu-closed" });
        }
    }

    hasAccess = (route) => {
        return (
            !route.accessControl ||
            (typeof route.accessControl === "function"
                ? route.accessControl({})
                : User.hasOneRole(route.accessControl))
        );
    }

    genRoutes() {
        let routes = [];

        for (let i in RouteStore.routes) {
            const mainRoute = RouteStore.routes[i];
            const hasAccess = this.hasAccess(mainRoute);

            if (
                mainRoute.path &&
                mainRoute.component &&
                hasAccess
            ) {
                routes.push(
                    <Route
                        key={i}
                        exact
                        path={mainRoute.path}
                        render={props => mainRoute.component(props)}
                    />
                );
            }

            for (let j in mainRoute.children) {
                const childRoute = mainRoute.children[j];
                const hasChildAccess =
                    this.hasAccess(mainRoute) && this.hasAccess(childRoute);
                if (
                    childRoute.path &&
                    childRoute.component &&
                    hasChildAccess
                ) {
                    routes.push(
                        <Route
                            key={j}
                            exact
                            path={childRoute.path}
                            render={props =>
                                childRoute.component(props)
                            }
                        />
                    );
                }
            }
        }
        return routes;
    }


    handleToggle = () => {
        this.setOpen(!this.state.open);
    };

    handleClose = event => {
        if (
            this.anchorRef.current &&
            this.anchorRef.current.contains(event.target)
        ) {
            return;
        }

        this.setOpen(false);
    };

    setOpen = open => {
        this.setState({ open });
    };
    handleListKeyDow = event => {
        if (event.key === "Tab") {
            event.preventDefault();
            this.setOpen(false);
        }
    };

    // return focus to the button when we transitioned from !open -> open

    render() {
        const { open } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <Router ref={(ref) => (Navigation.router = ref)}>
                    <div id="main-container" className={this.state.sideMenuClass}>
                        <div id="side-menu">
                            <MainMenu />
                        </div>
                        <div id="main" onClick={this.closeMenu.bind(this)}>
                            {this.state.loading ? <div id="root-loader">Loading...</div> : null}
                            <header id="main-header" className={(User.profile.detailedEnv && (User.profile.detailedEnv === 'sandbox' || User.profile.detailedEnv === 'training' || User.profile.detailedEnv === 'devonline' || User.profile.detailedEnv === 'dev') ? " env-display" : "")}>
                                <AppBar position="static" id="main-app-bar">
                                    {User.profile.detailedEnv && User.profile.detailedEnv === 'sandbox' ? <div id="env-display">SANDBOX</div> : null}
                                    {User.profile.detailedEnv && User.profile.detailedEnv === 'training' ? <div id="env-display" style={{backgroundColor: '#01BC77'}}>TRAINING</div> : null}
                                    {User.profile.detailedEnv && User.profile.detailedEnv === 'devonline' ? <div id="env-display" style={{backgroundColor: 'orange'}}>DEV</div> : null}
                                    {User.profile.detailedEnv && User.profile.detailedEnv === 'dev' ? <div id="env-display" style={{backgroundColor: '#0dbbb7'}}>LOCAL</div> : null}
                                    <Toolbar>
                                        {Object.keys(User.profile).length ? (
                                            <IconButton
                                                edge="start"
                                                color="inherit"
                                                onClick={this.toggleMenu.bind(this)}
                                            >
                                                {this.state.sideMenuClass == 'side-menu-closed' ? (
                                                    <Icon className="menu-icon-toggle-menu">
                                                        <img src={MenuLogo} />
                                                    </Icon>
                                                ) : (
                                                    <Icon className="menu-icon-toggle-menu">
                                                        <img src={CloseIcon} />
                                                    </Icon>
                                                )}
                                            </IconButton>
                                        ) : null}
                                        <Typography variant="h6" className={`app-bar-store-title ${AppBarStore.appBarStoreTitleClass}`}>
                                            {AppBarStore.title}
                                        </Typography>
                                        {userHasRoleMRM() && <Search />}
                                        {User.profile && User.profile.fullName ? (
                                            <div id="app-bar-username">
                                                <Typography variant="h6" className="app-bar-username">
                                                    {User.profile.fullName}
                                                    <Link to="/profile/settings">
                                                        <i
                                                            className="fa fa-user user-icon"
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                        />
                                                    </Link>
                                                </Typography>
                                                <Box className="app-bar-username" ml={2}>
                                                    <Link to="/resource/models/list">
                                                        <i
                                                            className="fas fa-database"
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                        />
                                                    </Link>
                                                </Box>
                                                <NotificationMenu />
                                            </div>
                                        ) : null}
                                        <Link to="/" id="logo">
                                            <img src={MainLogo} />
                                        </Link>
                                    </Toolbar>
                                </AppBar>
                            </header>
                            <div id="main-content" className={'container-fluid ' + (Navigation.id || '')}>
                                <Switch>{this.genRoutes()}</Switch>
                            </div>
                        </div>
                    </div>
                    <AlertProvider
                        ref={(ref) => {
                            Alert.ref = ref;
                        }}
                        variant="success"
                    />
                    <ModalProvider
                        ref={(ref) => {
                            Modal.ref = ref;
                        }}
                    />
                    <ModalProvider
                        ref={(ref) => {
                            ModalComplementary.ref = ref;
                        }}
                    />
                </Router>
            </ThemeProvider>
        );
    }
}

export default observer(Root);
