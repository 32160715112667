import React from 'react';
import {DisplayTextField} from "../../../Components/Display/DisplayTextField/DisplayTextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import Chip from '@material-ui/core/Chip';
import {CONTEXT_LIST} from "../APIResource";

class TextareaProvider {
    getDisplay(field, value, entity, props, resource, context){
        return <DisplayTextField {...props} fieldName={field.title} value={value ? value : ''} entity={entity} context={context}/>;
    }

    getDisplayList(field, value, entity, props, resource){
        let string = String(value);
        return this.getDisplay(field, string.substr(0, 200) + (string.length > 200 ? ' [...]' : ''), entity, props, CONTEXT_LIST);
    }

    getEdit(field, value, onChange, entity, routeParams, loading = false){
        /** @see TextProvider.getEdit pour les explications sur les keys */
        const key = loading ? `text_preload_${field.id}` : `text_${field.id}`;
        return (
          <div className={field.errorHelperText ? "field-error-control" : ""}>
            <label>
              {field.title}
              {field.requiredComputed ? " *" : ""}
            </label>
            <TextareaAutosize
              key={key}
              //disabled={loading}
              defaultValue={value ? value : ""}
              onChange={event => onChange(event.target.value)}
              rows={field.rows || 5}
              rowsMax={field.rows || 10}
              ref={field.ref}
              onBlur={field.onBlur}
              required={field.requiredComputed}
              onFocus={field.onFocus}
            />
          </div>
        );
    }

    getFilter(options = {}){
      const { field, value, onChange } = options;
        return (
          <TextField
            id={"filter-" + field.id}
            placeholder="Filter"
            value={value ? value : ""}
            onChange={event => {
              onChange(event.target.value);
            }}
            className="input-text-field-table"
          />
        );
    }
}

export default new TextareaProvider();