import React, {Component} from "react";
import PropTypes from "prop-types";
import Modal from "../../../Services/Modal";
import { ButtonColumn } from "../../Modal/ButtonColumn";
import { ActionButton } from "../../Modal/ActionButton";
import Alert from "../../../Services/Alert";
import Navigation from "../../../Services/Navigation";
import FormControl from "@material-ui/core/FormControl";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import {CircularProgress} from "@material-ui/core";
import ParameterStore from '../../../Store/ParameterStore';
import { ButtonBar } from "../../Modal/ButtonBar";

const FindingCloseModal = (props) => {
    const { displayButtons } = props;
    const displayButtonsUnique = [...new Set(Object.keys(displayButtons))];

    let openCloseModal = () => {
        Modal.open({
            title: "Close",
            content: <FindingActionForm
                action={'close'}
                actionTitle={'Close'}
                successMessage={'The finding has been closed.'}
                requiredComment={true}
                {...props}
            />,
        });
    };

    let openDismissModal = () => {
        Modal.open({
            title: "Dismiss",
            content: <FindingActionForm
                action={'dismiss'}
                actionTitle={'Dismiss'}
                successMessage={'The finding has been dismissed.'}
                requiredComment={true}
                {...props}
            />,
        });
    };

    if (displayButtonsUnique.length === 1) {
        switch (displayButtonsUnique[0]) {
            case "close":
                openCloseModal();
                break;
            case "dismiss":
                openDismissModal();
                break;
            default:
                break;
        }
    }

    return (
        <div className={"declare-modal"}>
            <ButtonColumn>
                {displayButtonsUnique.includes("close") && (
                    <ActionButton onClick={openCloseModal}>
                        Close
                    </ActionButton>
                )}
                {displayButtonsUnique.includes("dismiss") && (
                    <ActionButton onClick={openDismissModal}>
                        Dismiss
                    </ActionButton>
                )}
            </ButtonColumn>
        </div>
    );
};
FindingCloseModal.propTypes = {
    /** */
    displayButtons: PropTypes.objectOf(
        PropTypes.shape({
            title: PropTypes.string,
            label: PropTypes.string,
        })
    ),
};

export default FindingCloseModal;

export class FindingActionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: null,
            progress: false,
        };
    }

    action(entity, resource) {
        if (
            this.state.comment === null
            && this.props.requiredComment
        ) {
            Alert.show({
                message: "The comment is required.",
                type: "danger"
            });
            return;
        }

        this.setState({progress: true});

        Navigation.router.history.push("/resource/findings/" + entity.id + '/detail');

        let currentStatus = entity.status;

        if(this.props.action === 'close'){
            entity.status = ParameterStore('FINDING_STATUS_CLOSED');
            entity.comments = this.state.comment;
        }else if(this.props.action === 'dismiss'){
            entity.status = ParameterStore('FINDING_STATUS_DISMISSED');
            entity.comments = this.state.comment;
        }

        this.props.resource.apiPut(entity).then(() => {
            Alert.show({ message: this.props.successMessage, type: "success" });
            resource.apiGetCollection({
                page: 1,
                rowsPerPage: 10000,
                forceReload: true
            });
            Modal.close();
            this.setState({progress: false});
        }).catch(err => {
            entity.status = currentStatus;
            console.error(err);
            Modal.close();
            this.setState({progress: false});
        });
    }

    getTitle = () => {
        if (this.props.entity) {
            if(this.props.action === 'close'){
                return `Are you sure you want to close the finding ${this.props.entity.title} ?`;
            }else if(this.props.action === 'dismiss'){
                return `Are you sure you want to cancel the finding ${this.props.entity.title} ?`;
            }
    }
    };

    handleClick() {
        const { entity, resource } = this.props;
        if (entity) {
            return this.action(entity, resource);
        }
    }

    render() {
        return (
            <ModalContent>
                <Typography component={"p"}>{this.getTitle()}</Typography>
                <FormControl className="container_textarea">
                    <label>Comment</label>
                    <TextareaAutosize
                        value={this.state.comment ? this.state.comment : ""}
                        onChange={event => this.setState({ comment: event.target.value })}
                        rows={5}
                        rowsMax={10}
                    />
                </FormControl>
                <ButtonBar>
                    <ActionButton
                        onClick={Modal.close}
                    >
                        Cancel
                    </ActionButton>
                    {this.state.progress ?
                        <ActionButton disabled={true}>
                            <CircularProgress size={20} />
                        </ActionButton>
                        :
                        <ActionButton
                            onClick={() => this.handleClick()}
                        >
                            {this.props.actionTitle}
                        </ActionButton>
                    }
                </ButtonBar>
            </ModalContent>
        );
    }
}