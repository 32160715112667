import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../Modal';
import { ButtonBar } from '../../../../Components/Modal/ButtonBar';
import { ActionButton } from '../../../../Components/Modal/ActionButton';
import { CircularProgress } from '@material-ui/core';

export const ResourceDelete = (props) => {
    const { onDelete, item, resourceName = 'item' } = props;

    const [progress, setProgress] = useState(false);

    /** @todo utiliser ValidationForm ? */

    return (
        <div>
            <p>
            {
                props.deleteConfirmation ??
                `Are you sure you want to delete this ${resourceName.toLowerCase()} ?`
            }
            </p>
            <ButtonBar>
                <ActionButton
                    onClick={Modal.close}
                    disabled={progress}
                >
                    Cancel
                </ActionButton>
                <ActionButton
                    onClick={() => {setProgress(true); onDelete(item);}}
                    disabled={progress}
                >
                    Delete {progress && <CircularProgress size={20} />}
                </ActionButton>
            </ButtonBar>
        </div>
    );
};
ResourceDelete.propTypes = {
    /** Nom de la resource à afficher */
    resourceName: PropTypes.string,
    /** item à supprimer */
    item: PropTypes.any,
    /** Fonction appelée pour supprimer l'item */
    onDelete: PropTypes.func,
};
