import React, {Component} from 'react';
import Dropzone from "react-dropzone";
import Alert from "../../../Services/Alert";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";

export class File extends Component{
    constructor(props){
        super(props);
        this.onDrop = this.onDrop.bind(this);
        this.state = {
            files: []
        };
    }

    onDrop(acceptedFiles){
        this.setState({dropClass: ''});
        let files = [];
        let error = false;
        if(Array.isArray(acceptedFiles)) {
            if (acceptedFiles.length > 0) {
                for(let i in acceptedFiles){
                    let fileType = acceptedFiles[i].name.split('.').pop();
                    if (this.props.fileTypes === null || this.props.fileTypes.includes(fileType)) {
                        files.push(acceptedFiles[i]);
                    }else{
                        Alert.show({message: 'Not allowed file type : ' + fileType, type: 'danger'});
                        error = true;
                    }
                }
            }
        }
        if(error === true){ files = [];}
        this.setState({files: files});
        this.props.callback(files, error);
    }

    isMultiple(){
        return this.props.maxFile > 1;
    }

    render(){

        return(
            <FormControl>
                <Dropzone
                    onDrop={(acceptedFiles) => this.onDrop(acceptedFiles)}
                    onDragEnter={() => this.setState({dropClass: 'dz-enter'})}
                    onDragLeave={() => this.setState({dropClass: ''})}
                >
                    {({getRootProps, getInputProps}) => (
                        <section className={"dropzone " + this.state.dropClass }>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} multiple={this.isMultiple()}/>
                                {this.state.files.length === 0 ?
                                    <p>Drag 'n' drop file here, or click to select file</p>
                                    :
                                    <IconButton>
                                        <i className={"fas fa-check"}></i>
                                    </IconButton>
                                }
                            </div>
                        </section>
                    )}
                </Dropzone>
                {this.props.fileTypes !== null ?
                    <p>Allowed file type{this.props.fileTypes.length>1?'s':''} : {this.props.fileTypes.join(', ')}</p>
                    : null}
            </FormControl>
        );
    }
}