import React, { Component } from "react";
import MUIModal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import {
  AppBar,
  Backdrop,
  Button,
  Fade,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Navigation from "./Navigation";
import Modal from "./Modal";
import {Fullscreen, FullscreenExit} from "@material-ui/icons";

export default class ModalProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      title: "",
      content: null,
      closable: true,
      hasFooter: false,
      disableSubmit: false,
      submitButtonText: "",
      className: '',
      closeOnRouteChange: false,
      allowFullScreen: false,
      fullScreen: false
    };

    setTimeout(() => {
      Navigation.router.history.listen((location, action) => {
        if(this.state.closeOnRouteChange){
          Modal.close();
        }
        //console.log(action, location.pathname);
      });
    }, 500);

  }

  /**
   * 
   * @param {object} params 
   * @param {string} params.title
   * @param {boolean} [params.closable]
   * @param {Function} [params.onClose]
   * @param {object} [contentStyle]
   * @param {object} [modalStyle]
   * @param {string} [className]
   * @param {boolean} [hasFooter]
   * @param {boolean} [disableSubmit]
   */
  open(params) {
    this.setState({
      open: true,
      ...params,
      closable: params.closable !== undefined ? params.closable : true,
      onClose: params.onClose,
      contentStyle: params.contentStyle || {},
      modalStyle: params.modalStyle || {},
      className: params.className
    });
  }

  close() {
    this.setState({
      open: false,
      title: "",
      content: null,
      closable: true,
      hasFooter: false,
      disableSubmit: false,
      submitButtonText: "",
      className: '',
      closeOnRouteChange: false,
      allowFullScreen: false,
      fullScreen: false
    });
    if (this.state.onClose) {
      this.state.onClose();
    }
  }

  fullScreen(fullScreen) {
    this.setState({
      fullScreen: fullScreen,
    });
  }

  render() {
    const { hasFooter, submitButtonText } = this.state;
    return (
      <MUIModal
        style={styles.modal}
        open={this.state.open}
        onClose={() => this.setState({ open: false }) && this.close()}
        onBackdropClick={() => {
          this.state.onBackdropClick && this.state.onBackdropClick();
          this.close();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className={this.state.className + ' ' + (this.state.fullScreen ? 'fullscreen' : '')}
      >
        <Fade in={this.state.open}>
          <div
            style={{
              ...styles.paper,
              ...{ paddingBottom: hasFooter ? 60 : 0 },
              ...this.state.modalStyle,
            }}
            className="modal-content"
          >
            <AppBar
              position="static"
              className="background-lowlight background-linear-gradient"
            >
              <Toolbar style={styles.toolBar}>
                <Typography style={styles.title} variant="h6">
                  {this.state.title}
                </Typography>
                {this.state.allowFullScreen && (
                    this.state.fullScreen ? <FullscreenExit onClick={() => this.fullScreen(false)} /> : <Fullscreen onClick={() => this.fullScreen(true)} />
                )}
                {this.state.closable && (
                  <CloseIcon onClick={() => this.close()} />
                )}
              </Toolbar>
            </AppBar>
            <div style={{ ...styles.content, ...this.state.contentStyle }}>
              {this.state.content}
            </div>

            {this.state.hasFooter ? (
              <Toolbar className="footer" style={styles.toolBar}>
                <Button
                  variant="contained"
                  color="primary"
                  style={styles.bottomButton}
                  type={"button"}
                  disabled={this.state.disableSubmit}
                  onClick={this.onSubmit}
                  className="button-general"
                >
                  {submitButtonText || "Save"}
                </Button>
              </Toolbar>
            ) : null}
          </div>
        </Fade>
      </MUIModal>
    );
  }
}

let styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#ffffff",
    border: "2px solid #000",
    padding: 0,
  },
  title: {
    marginTop: 0,
    flex: 1,
  },

  content: {
    padding: 20,
    overflow: "auto",
    boxSizing: "border-box",
  },
  bottomButton: {
    position: "absolute",
    right: 15,
    bottom: 15,
  },
};
