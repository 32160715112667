import React, {Component} from "react";
import Http from '../../Http';
import Alert from "../../Alert";
import Modal from "../../Modal";
import TextField from "@material-ui/core/TextField";
import {APIResource} from "../../APIResource/APIResource";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ActionButton } from "../../../Components/Modal/ActionButton";
import { ButtonBar } from "../../../Components/Modal/ButtonBar";

export class BulkAddCertifications{
    getId(){
        return 'bulkAddCertifications'
    }

    getLabel(){
        return 'Certify'
    }

    setParameters(params){
        this.parameters = params;
    }

    run(ids, params = null){
        this.ids = ids;

        Modal.open({
            title: 'Bulk Certification',
            content: <AddCertificationForm fields={this.parameters.fields} ids={this.ids}/>
        })
    }
}

export class AddCertificationForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            note: '',
            progress: false,
        };
    }

    save(){
        this.setState({progress: true});
        Http.post('models/addCertifications', {
            ids: this.props.ids,
            note: this.state.note,
            modelCertificationCampaign: this.props.modelCertificationCampaign,
        }).then((response) => {
            let modelResource = new APIResource({
                id: 'models',
                name: 'Models',
            });
            modelResource.apiGetCollection({ filters: {}, forceReload: true });
            Alert.show({
                message:
                    this.props.ids.length > 1
                        ? 'Bulk certification successfully processed.'
                        : 'Certification successfully processed.',
            });
            this.setState({progress: false});
            Modal.close();
        });
    }

    render() {
        const models = this.props.ids.length > 1
            ? "these models"
            : this.props.modelName
                ? <span>the <code>{this.props.modelName}</code> model</span>
                : "this model";
        return (
            <div className="container">
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox checked={this.state.certified} onChange={() => this.setState({certified: !this.state.certified})} />
                        }
                        label={<span>As a model owner, I hereby certify that the currently saved information on {models} is valid.</span>}
                    />

                    <TextField
                        id={'note'}
                        label={"Note"}
                        fullWidth={true}
                        multiline={true}
                        onChange={(event) => {this.setState({note: event.target.value})}}
                        value={this.state.note}
                    />
                </div>
                <ButtonBar>
                    <ActionButton
                        onClick={this.save.bind(this)} 
                        disabled={!this.state.certified || this.state.progress}
                        loading={this.state.progress}
                    >
                        Certify
                    </ActionButton>
                </ButtonBar>
            </div>
        )
    }
}