export const getIdFromIri = (iri) => {
    let elems = String(iri).split('/');
    // On exige que l'iri ne se termine pas par un /
    return elems.pop();
};

export const getResourceFromIri = (iri) => {
    let elems = String(iri).split('/');
    return elems[2];
};

export const scrollToTop = () => {
    const container = document.getElementById('main-content');
    if (container) {
        container.style = 'scroll-behavior: smooth;';
        container.scrollTo(0, 0);
    }
};

/**
 * Permet d'obtenir une fonction async en retour d'un debounce
 * et donc pouvoir faire : await asyncDebounce(maFonction, 1000).
 * Les appels intermédiaires sont "rejected".
 *
 * https://github.com/szchenghuang/debounce-async/blob/master/src/index.js
 *
 * asyncDebounce(func, [wait=0], [options={}])
 *
 * @param {Function} func The function to debounce.
 * @param {number} [wait=0] The number of milliseconds to delay.
 * @param {Object} [options={}] The options object.
 * @param {boolean} [options.leading=false] Specify invoking on the leading edge of the timeout.
 * @param {cancelObj} [options.cancelObj='canceled'] Specify the error object to be rejected.
 * @returns {Function} Returns the new debounced function.
 */
export const asyncDebounce = (func, wait = 0, { leading = false, cancelObj = 'canceled' } = {}) => {
    let timerId, latestResolve, shouldCancel;

    return function (...args) {
        if (!latestResolve) {
            // The first call since last invocation.
            return new Promise((resolve, reject) => {
                latestResolve = resolve;
                if (leading) {
                    invokeAtLeading.apply(this, [args, resolve, reject]);
                } else {
                    timerId = setTimeout(invokeAtTrailing.bind(this, args, resolve, reject), wait);
                }
            });
        }

        shouldCancel = true;
        return new Promise((resolve, reject) => {
            latestResolve = resolve;
            timerId = setTimeout(invokeAtTrailing.bind(this, args, resolve, reject), wait);
        });
    };

    function invokeAtLeading(args, resolve, reject) {
        func.apply(this, args).then(resolve).catch(reject);
        shouldCancel = false;
    }

    function invokeAtTrailing(args, resolve, reject) {
        if (shouldCancel && resolve !== latestResolve) {
            reject(cancelObj);
        } else {
            func.apply(this, args).then(resolve).catch(reject);
            shouldCancel = false;
            clearTimeout(timerId);
            timerId = latestResolve = null;
        }
    }
};

/**
 * Transforme une chaîne (par ex copiée-collée d'Excel) en tableau d'emails.
 *
 * Fonctionne pour `a@b.com;"Nom" <user@b.com>, Prenom mail@b.com` entre autres.
 *
 * @param {string} emails - Chaîne variable contenant des emails
 * @returns {Array<string>} Tableau d'emails valides
 */
export const parseEmails = (emails) => {
    if (!emails || typeof emails !== 'string' || !emails.includes('@')) return [];

    const validEmails = emails
        .replace(/[,; ><"']/g, ';')
        .split(';')
        .filter((email) => /^.*?@.*\.\w+$/.test(email));
    return validEmails;
};
