import React from 'react';
import { APIResource } from '../Services/APIResource/APIResource';
import {
    PARAMETER_TYPE_EXTERNAL_IMPACT,
    PARAMETER_TYPE_MATERIALITY,
    PARAMETER_TYPE_MODEL_TIER_RESULT,
    PARAMETER_TYPE_MODEL_TIERING_CATEGORY,
    PARAMETER_TYPE_MODEL_USAGE,
    PARAMETER_TYPE_SPECIAL_QUANTIFIED_MATERIALITY,
    PARAMETER_TYPE_METRICS,
    PARAMETER_TYPE_COMPLEXITY_LEVEL, PARAMETER_TYPE_TIERING_COMPLEXITY_RATIONALE,
} from './ParameterAdmin';
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import { ChangeLog } from '../Components/Display/ChangeLog/ChangeLog';
import { EntityDisplay } from '../Components/Display/EntityDisplay/EntityDisplay';
import { EntityExportForm } from '../Components/Export/EntityExport';
import { ParameterSelect } from '../Components/Forms/ParameterSelect/ParameterSelect';
import {Header} from "../Components/Header/Header";
import { EntityImportForm } from '../Components/Import/EntityImport';
import {TieringTable} from "../Components/Tiering/TieringTable";
import Http from "../Services/Http";
import ModelProvider from '../Services/APIResource/FieldProviders/ModelProvider';
import IntProvider from "../Services/APIResource/FieldProviders/IntProvider";
import { BulkEdit } from '../Services/BulkActions/BulkEdit/BulkEdit';
import Modal from '../Services/Modal';
import User from '../Services/User/User';
import APIResourceStore from "../Store/APIResourceStore";
import ParameterStore, {
    hasOneGroupEntityNTX,
    userHasMRMRights,
    userHasRoleADMIN,
    userHasRoleMRM,
    userHasRoleSTD,
} from '../Store/ParameterStore';
import { isGranted } from './common';

const header = () => {
    let header = [];

   if (
        !userHasRoleMRM()
    ) {
        header.push(
            <Header
                key={'tiering-header'}
                text={
                    'In order to create a tiering for the first time or to update it, please fill in the 3 levels and the quantified materiality if applicable. The tiering must be validated by MRM in order to become effective.'
                }
            />
        );
    }

    return header.length > 0 ? header : null;
};


export const TIERING_FIELDS = (tierings, resourceModels) => ({
    id: {
        title: 'ID',
        inParentList: false,
        display: (field, value, entity) => <TieringTable context={'detail'} entity={entity} resource={tierings} />,
        edit: (field, value, onChange, entity) => (
            <TieringTable context={'edit'} entity={entity} resource={tierings} onChange={onChange} />
        ),
        displayList: (field, value, entity, props) => IntProvider.getDisplayList(field, value, entity, props),
        params: {
            filterMulti: true,
        },
    },
    model: {
        title: 'Model',
        inParentList: false,
        type: 'model',
        params: {
            resource: 'models',
            instanceId: 'allModels',
            displayField: 'toString',
            multi: false,
            links: true,
            linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
            endpoints: {
                getAll: 'models/all-models',
            },
        },
        edit: (field, value, onChange, entity, routeParams) => {
            if ((routeParams.modelId || value) && !entity.model) {
                entity.model = routeParams.modelId ? '/api/models/' + routeParams.modelId : value;
            }
            return ModelProvider.getDisplay(field, entity.model, entity, {});
        },
    },
    tieringCategory: {
        title: 'Tiering category',
        inParentList: false,
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_MODEL_TIERING_CATEGORY,
            multi: false,
        },
        helperTextEdit: 'Category of models that share the same tiering rules',
        required: true,
        displayCondition: (entity) =>
            entity &&
            entity.model &&
            hasOneGroupEntityNTX(APIResourceStore.resources.allModels.getObservableItemByPath(entity.model)),
        edit: (field, value, onChange, entity) => {
            if (entity.verifiedByMrm === true && !userHasRoleMRM()) {
                return (
                    <EntityDisplay
                        resourceId="parameters"
                        resourceLabel="label"
                        label={field.title}
                        value={value}
                        links={false}
                    />
                );
            } else {
                return (
                    <ParameterSelect
                        field={field}
                        value={value}
                        onChange={onChange}
                        issueButton={'issueButton' in field ? field.issueButton : true}
                        entity={entity}
                        clearable={!('required' in field && field.required === true)}
                        multi={'multi' in field.params ? field.params.multi : false}
                        required={field.requiredComputed}
                        error={!!field.errorHelperText}
                        className={field.errorHelperText ? 'field-error-control' : ''}
                        helperText={field.errorHelperText}
                    />
                );
            }
        },
        bulk: true,
    },
    local: {
        title: 'Add a local tiering',
        inParentList: false,
        type: 'bool',
    },
    metrics: {
        title: 'Metrics',
        inParentList: false,
        type: 'parameter',
        helperTextEdit: 'Metric of the materiality',
        params: { type: PARAMETER_TYPE_METRICS, multi: false },
    },
    metricsAuto: {
        title: 'Metrics',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_METRICS, multi: false },
    },
    metricsOverride: {
        title: 'Metrics',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_METRICS, multi: false },
        helperTextEdit: 'Metric of the materiality',
        bulk: true,
    },
    metricsFinal: {
        title: 'Metrics',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_METRICS, multi: false },
    },
    quantifiedMateriality: {
        title: 'Quantified materiality',
        inParentList: false,
        type: 'float',
        helperTextEdit: 'Quantified total model materiality (RWA, outstanding amout,…)',
        suffix: (entity) => entity.quantifiedMaterialityUnit,
    },
    quantifiedMaterialityOverride: {
        title: 'Quantified materiality',
        inParentList: false,
        type: 'float',
        helperTextEdit: 'Quantified total model materiality (RWA, outstanding amout,…)',
        suffix: (entity) => {
            return entity.quantifiedMaterialityUnitOverride;
        },
        bulk: true,
    },
    quantifiedMaterialityFinal: {
        title: 'Quantified materiality',
        inParentList: false,
        type: 'float',
        helperTextEdit: 'Quantified total model materiality (RWA, outstanding amout,…)',
        suffix: (entity) => entity.quantifiedMaterialityUnitFinal,
    },
    specialQuantifiedMateriality: {
        title: 'Pricing and valuation thresholds',
        inParentList: false,
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_SPECIAL_QUANTIFIED_MATERIALITY,
            multi: false,
        },
        displayCondition: (entity) => {
            if (!entity.tieringCategory) {
                return false;
            }
            let model = APIResourceStore.resources.allModels.getObservableItemByPath(entity.model);
            if (hasOneGroupEntityNTX(model)) {
                if (
                    entity.tieringCategory ===
                    ParameterStore('TIERING_CATEGORY_MARKET_RISK_CATEGORY_AND_PRICING_OR_VALUATION')
                ) {
                    return true;
                }
            }
            return false;
        },
    },
    specialQuantifiedMaterialityOverride: {
        title: 'Pricing and valuation thresholds',
        inParentList: false,
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_SPECIAL_QUANTIFIED_MATERIALITY,
            multi: false,
        },
        displayCondition: (entity) => {
            if (!entity.tieringCategory) {
                return false;
            }
            let model = APIResourceStore.resources.allModels.getObservableItemByPath(entity.model);
            if (hasOneGroupEntityNTX(model)) {
                if (
                    entity.tieringCategory ===
                    ParameterStore(
                        'TIERING_CATEGORY_MARKET_RISK_CATEGORY_AND_PRICING_OR_VALUATION'
                    )
                ) {
                    return true;
                }
            }
            return false;
        },
        bulk: true,
    },
    materialityQuantifyingDate: {
        title: 'Materiality quantification date',
        inParentList: false,
        type: 'date',
        params: {
            maxDate: new Date(),
        },
        helperTextEdit: 'Issuance date of the data used to quantify the materiality',
    },
    materialityQuantifyingDateOverride: {
        title: 'Materiality quantification date',
        inParentList: false,
        type: 'date',
        params: {
            maxDate: new Date(),
        },
        helperTextEdit: 'Issuance date of the data used to quantify the materiality',
        bulk: true,
    },
    materialityQuantifyingDateFinal: {
        title: 'Materiality quantification date',
        inParentList: false,
        type: 'date',
        params: {
            maxDate: new Date(),
        },
        helperTextEdit: 'Issuance date of the data used to quantify the materiality',
    },
    materialityLevel: {
        title: 'Materiality level',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_MATERIALITY, multi: false },
        helperTextEdit:
            'The value of this field depends on the quantification of the materiality'
            + '\nThe thresholds between the different levels depends on the model type',
        required: true,
    },
    materialityLevelAuto: {
        title: 'Materiality level',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_MATERIALITY, multi: false },
        helperTextEdit:
            'The value of this field depends on the quantification of the materiality'
            + '\nThe thresholds between the different levels depends on the model type',
    },
    materialityLevelOverride: {
        title: 'Materiality level',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_MATERIALITY, multi: false },
        required: true,
        helperTextEdit:
            'The value of this field depends on the quantification of the materiality'
            + '\nThe thresholds between the different levels depends on the model type',
            bulk: true,
    },
    materialityLevelFinal: {
        title: 'Materiality level',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_MATERIALITY, multi: false },
        helperTextEdit:
            'The value of this field depends on the quantification of the materiality'
            + '\nThe thresholds between the different levels depends on the model type',
    },
    materialityLevelLocal: {
        title: 'Materiality level for local purposes',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_MATERIALITY, multi: false },
        helperTextEdit:
            'Materiality level at the local level; mesure of materiality in local entities',
        displayCondition: (entity) => entity.local,
    },
    materialityLevelLocalOverride: {
        title: 'Materiality level for local purposes',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_MATERIALITY, multi: false },
        helperTextEdit:
            'Materiality level at the local level; mesure of materiality in local entities',
        displayCondition: (entity) => entity.local,
        bulk: true,
    },
    materialityRationale: {
        title: 'Materiality rationale',
        inParentList: false,
        type: 'text',
        helperTextEdit:
            'Please detail the chosen method to quantify the materiality and clearly specify the metrics and the unit.',
    },
    materialityRationaleOverride: {
        title: 'Materiality rationale',
        inParentList: false,
        type: 'text',
        helperTextEdit:
            'Please detail the chosen method to quantify the materiality and clearly specify the metrics and the unit.',
        bulk: true,
    },
    materialityRationaleFinal: {
        title: 'Materiality rationale',
        inParentList: false,
        type: 'text',
        helperTextEdit:
            'Please detail the chosen method to quantify the materiality and clearly specify the metrics and the unit.',
    },
    complexityLevel: {
        title: 'Complexity level',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_COMPLEXITY_LEVEL, multi: false },
        helperTextEdit:
            'The value of the field depends on the model complexity:'
            + "\nThree levels are identified: « High », « Medium », « Low »",
        required: (entity) => {
            let model = resourceModels.getObservableItemByPath(entity.model);
            return hasOneGroupEntityNTX(model);
        }
    },
    complexityLevelOverride: {
        title: 'Complexity level override',
        inParentList: true,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_COMPLEXITY_LEVEL, multi: false },
        helperTextEdit:
            'The value of the field depends on the model complexity:'
            + "\nThree levels are identified: « High », « Medium », « Low »",
        required: (entity) => {
            let model = resourceModels.getObservableItemByPath(entity.model);
            return hasOneGroupEntityNTX(model);
        },
        bulk: true,
    },
    complexityLevelFinal: {
        title: 'Complexity level',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_COMPLEXITY_LEVEL, multi: false },
        helperTextEdit:
            'The value of the field depends on the model complexity:'
            + "\nThree levels are identified: « High », « Medium », « Low »",
    },
    complexityLevelLocal: {
        title: 'Complexity level for local purposes',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_COMPLEXITY_LEVEL, multi: false },
        helperTextEdit:
            'The value of the field depends on the model complexity:'
            + "\nThree levels are identified: « High », « Medium », « Low »",
        displayCondition: (entity) => entity.local,
    },
    complexityLevelLocalOverride: {
        title: 'Complexity level for local purposes',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_COMPLEXITY_LEVEL, multi: false },
        helperTextEdit:
            'The value of the field depends on the model complexity:'
            + "\nThree levels are identified: « High », « Medium », « Low »",
        displayCondition: (entity) => entity.local,
        bulk: true,
    },
    complexityRationale: {
        title: 'Complexity rationale',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_TIERING_COMPLEXITY_RATIONALE, multi: false },
        helperTextEdit:
            'Six levels of model complexity are defined for the models used for pricing and valuation'
            + "\n•	Level 1: Linear (e.g. IR swap, FX forward, etc.)"
            + "\n•	Level 2: Vanilla (vanilla equity options, etc.)"
            + "\n•	Level 3: Replication (models replicable with vanilla options, etc.)"
            + "\n•	Level 4: Exotic Mono"
            + "\n•	Level 5: Exotic Multi"
            + "\n•	Level 6: Hybrid"
            + "\nModel complexity are defined for the reserve category:"
            + "\n•	Liquidity Market Risk Reserves",
    },
    complexityRationaleOverride: {
        title: 'Complexity rationale',
        inParentList: true,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_TIERING_COMPLEXITY_RATIONALE, multi: false },
        helperTextEdit:
            'Six levels of model complexity are defined for the models used for pricing and valuation'
            + "\n•	Level 1: Linear (e.g. IR swap, FX forward, etc.)"
            + "\n•	Level 2: Vanilla (vanilla equity options, etc.)"
            + "\n•	Level 3: Replication (models replicable with vanilla options, etc.)"
            + "\n•	Level 4: Exotic Mono"
            + "\n•	Level 5: Exotic Multi"
            + "\n•	Level 6: Hybrid"
            + "\nModel complexity are defined for the reserve category:"
            + "\n•	Liquidity Market Risk Reserves",
        bulk: true,
    },
    complexityRationaleFinal: {
        title: 'Complexity rationale',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_TIERING_COMPLEXITY_RATIONALE, multi: false },
        helperTextEdit:
            'Please detail the chosen method to quantify the complexity and clearly specify the metrics and the unit.',
    },

    modelUsageLevel: {
        title: 'Model usage level',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_MODEL_USAGE, multi: false },
        helperTextEdit:
            'Assessment of the criticity of model outputs utilisation for strategic decisions within the group',
        required: true,
    },
    modelUsageLevelAuto: {
        title: 'Model usage level',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_MODEL_USAGE, multi: false },
        helperTextEdit:
            'Assessment of the criticity of model outputs utilisation for strategic decisions within the group',
    },
    modelUsageLevelOverride: {
        title: 'Model usage level',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_MODEL_USAGE, multi: false },
        required: true,
        helperTextEdit:
            'Assessment of the criticity of model outputs utilisation for strategic decisions within the group',
        bulk: true,
    },
    modelUsageLevelFinal: {
        title: 'Model usage level',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_MODEL_USAGE, multi: false },
        helperTextEdit:
            'Assessment of the criticity of model outputs utilisation for strategic decisions within the group',
    },
    modelUsageRationale: {
        title: 'Model usage rationale',
        inParentList: false,
        type: 'text',
        helperTextEdit: 'Please detail the chosen method to assess the model usage level',
    },
    modelUsageRationaleOverride: {
        title: 'Model usage rationale',
        inParentList: false,
        type: 'text',
        helperTextEdit: 'Please detail the chosen method to assess the model usage level',
        bulk: true,
    },
    modelUsageRationaleFinal: {
        title: 'Model usage rationale',
        inParentList: false,
        type: 'text',
        helperTextEdit: 'Please detail the chosen method to assess the model usage level',
    },

    externalImpactLevel: {
        title: 'External impact level',
        inParentList: false,
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_EXTERNAL_IMPACT,
            multi: false,
        },
        helperTextEdit:
            'Assessment of the impact of the model on external third parties such as the supervisor, clients, rating agencies....',
        required: true,
    },
    externalImpactLevelAuto: {
        title: 'External impact level',
        inParentList: false,
        type: 'parameter',
        params: { type: PARAMETER_TYPE_EXTERNAL_IMPACT, multi: false },
        helperTextEdit:
            'Assessment of the impact of the model on external third parties such as the supervisor, clients, rating agencies....',
    },
    externalImpactLevelOverride: {
        title: 'External impact level',
        inParentList: false,
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_EXTERNAL_IMPACT,
            multi: false,
        },
        required: true,
        helperTextEdit:
            'Assessment of the impact of the model on external third parties such as the supervisor, clients, rating agencies....',
        bulk: true,
    },
    externalImpactLevelFinal: {
        title: 'External impact level',
        inParentList: false,
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_EXTERNAL_IMPACT,
            multi: false,
        },
        helperTextEdit:
            'Assessment of the impact of the model on external third parties such as the supervisor, clients, rating agencies....',
    },
    externalImpactRationale: {
        title: 'External impact rationale',
        inParentList: false,
        type: 'text',
        helperTextEdit: 'Please detail the chosen method to assess the external impact level',
    },
    externalImpactRationaleOverride: {
        title: 'External impact rationale',
        inParentList: false,
        type: 'text',
        helperTextEdit: 'Please detail the chosen method to assess the external impact level',
        bulk: true,
    },
    externalImpactRationaleFinal: {
        title: 'External impact rationale',
        inParentList: false,
        type: 'text',
        helperTextEdit: 'Please detail the chosen method to assess the external impact level',
    },
    tierResult: {
        title: 'Tier result',
        inParentList: false,
        required: true,
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_MODEL_TIER_RESULT,
            multi: false,
        },
        helperTextEdit:
            'Tier result, defined automatically from External impact, Materiality, and Model usage values, and with 3 possible values: Tier 1, Tier 2, Tier 3\n' +
            'Possibility to override the value or to define it without filling the previous fields',
    },
    tierResultAuto: {
        title: 'Tier result',
        inParentList: false,
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_MODEL_TIER_RESULT,
            multi: false,
        },
        helperTextEdit:
            'Tier result, defined automatically from External impact, Materiality, and Model usage values, and with 3 possible values: Tier 1, Tier 2, Tier 3\n' +
            'Possibility to override the value or to define it without filling the previous fields',
    },
    tierResultOverride: {
        title: 'Tier result',
        inParentList: false,
        helperTextEdit:
            'Tier result, defined automatically from External impact, Materiality, and Model usage values, and with 3 possible values: Tier 1, Tier 2, Tier 3\n' +
            'Possibility to override the value or to define it without filling the previous fields\n' +
            'IMPORTANT: In some cases, you can leave this field empty for an automatic computation of its value',
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_MODEL_TIER_RESULT,
            multi: false,
        },
        bulk: true,
    },
    tierResultFinal: {
        title: 'Tier result',
        inParentList: true,
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_MODEL_TIER_RESULT,
            multi: false,
        },
        helperTextEdit:
            'Tier result, defined automatically from External impact, Materiality, and Model usage values, and with 3 possible values: Tier 1, Tier 2, Tier 3\n' +
            'Possibility to override the value or to define it without filling the previous fields',
    },

    tierResultLocal: {
        title: 'Tier result for local purposes',
        inParentList: false,
        helperTextEdit: 'Tier result at the local level; mesure of criticity in local entities',
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_MODEL_TIER_RESULT,
            multi: false,
        },
        required: (entity) => entity.local,
        displayCondition: (entity) => entity.local,
    },
    tierResultLocalOverride: {
        title: 'Tier result for local purposes',
        inParentList: false,
        helperTextEdit: 'Tier result at the local level; mesure of criticity in local entities',
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_MODEL_TIER_RESULT,
            multi: false,
        },
        required: (entity) => userHasRoleMRM() && entity.local,
        displayCondition: (entity) => entity.local,
        bulk: true,
    },
    tierRationale: {
        title: 'Tier rationale',
        inParentList: false,
        type: 'text',
        helperTextEdit:
            'Text field used to justify an override or a tier decision without computation from the 3 previous fields',
    },
    tierRationaleOverride: {
        title: 'Tier rationale',
        inParentList: false,
        type: 'text',
        helperTextEdit:
            'Text field used to justify an override or a tier decision without computation from the 3 previous fields',
            bulk: true,
    },
    tierRationaleFinal: {
        title: 'Tier rationale',
        inParentList: false,
        type: 'text',
        helperTextEdit:
            'Text field used to justify an override or a tier decision without computation from the 3 previous fields',
    },
    changeLogsEntities: {
        title: 'Audit trail',
        inParentList: false,
        display: (field, value, entity, props) => (
            <ChangeLog field={field} values={value} entity={entity} entityResource={'tierings'} props={props} />
        ),
        edit: () => null,
        displayList: () => null,
    },

    //Additional fields without relation with Tiering
    changeLogComment: {
        title: 'Justification of the data update',
        inParentList: false,
        type: 'textarea',
        display: () => null,

        token: false,
    },
});

const FIELDS_SAVE_WHITE_LIST = ['createdByMrm', 'submit'];

export const ApiResourceDefaultParams = {
    id: 'tierings',
    name: 'Tiering',
    fieldForTitle: 'toString',
    breadcrumbName: "Tier.",
    componentForTitle: (entity, resource, resourceEditComponent) => {
        return <Breadcrumb entity={entity} resource={resource} resourceEditComponent={resourceEditComponent} relationalProperty={'model'} resourcePath={'models'} />
    },
    icon: 'cog',
    fieldsAclLocation: 'annotations/tiering',
};

export default class TieringAdmin {
    constructor() {
        this.configure();
    }

    configure() {
        let additionalLinkButton = (entity, _resource, _modelResource) => {
            if (!entity.model) {
                return [];
            }

            let model = entity.model;
            model = model.split('/');
            let modelId = model[model.length - 1];


            return [{
                link:
                    '/resource/models/' + modelId + '/detail?tab=Tiering',
                tooltip: 'Associated model',
                icon: 'link',
            }];
        }

        let tierings = new APIResource(ApiResourceDefaultParams);
        tierings
            .setFields(TIERING_FIELDS(tierings, APIResourceStore.resources.allModels))
            .setValidation(() => {
                return true;
            })
            .setLayout({
                tabs: {
                    Tiering: {
                        rows: [
                            {
                                panels: {
                                    Tiering: {
                                        cols: 12,
                                        fields: ['model', 'tieringCategory', 'id'],
                                    },
                                },
                            },
                        ],
                    },
                    'Audit trail': {
                        rows: [
                            {
                                panels: {
                                    'Audit trail': {
                                        cols: 12,
                                        fields: ['changeLogsEntities', 'changeLogComment'],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            //Insert view is made by TieringForm
            .genEditView({
                itemAccessCondition: (entity) => entity && entity.id ? isGranted(entity, tierings.resourceId) : false,
                additionalLinkButton: additionalLinkButton,
                fieldsSaveWhitelist: FIELDS_SAVE_WHITE_LIST,
                onInit: async ({ entity }) => {
                    if (userHasRoleMRM() && !entity.id) {
                        entity.createdByMrm = true;
                    }

                    if (!entity.metrics && entity.metricsAuto) {
                        entity.metrics = entity.metricsAuto;
                    }
                    if (!entity.materialityLevel && entity.materialityLevelAuto) {
                        entity.materialityLevel = entity.materialityLevelAuto;
                    }
                    if (!entity.modelUsageLevel && entity.modelUsageLevelAuto) {
                        entity.modelUsageLevel = entity.modelUsageLevelAuto;
                    }
                    if (!entity.externalImpactLevel && entity.externalImpactLevelAuto) {
                        entity.externalImpactLevel = entity.externalImpactLevelAuto;
                    }
                    if (!entity.tierResult && entity.tierResultAuto && !entity.createdByMrm) {
                        entity.tierResult = entity.tierResultAuto;
                    }

                    if (userHasRoleMRM()) {
                        if (!entity.metricsOverride && entity.metrics) {
                            entity.metricsOverride = entity.metrics;
                        }
                        if (!entity.quantifiedMaterialityOverride && entity.quantifiedMateriality) {
                            entity.quantifiedMaterialityOverride = entity.quantifiedMateriality;
                        }
                        if (!entity.materialityQuantifyingDateOverride && entity.materialityQuantifyingDate) {
                            entity.materialityQuantifyingDateOverride = entity.materialityQuantifyingDate;
                        }
                        if (!entity.materialityLevelOverride && entity.materialityLevel) {
                            entity.materialityLevelOverride = entity.materialityLevel;
                        }
                        if (!entity.materialityRationaleOverride && entity.materialityRationale) {
                            entity.materialityRationaleOverride = entity.materialityRationale;
                        }
                        if(!entity.complexityLevelOverride && entity.complexityLevel){
                            entity.complexityLevelOverride = entity.complexityLevel;
                        }
                        if (!entity.complexityRationaleOverride && entity.complexityRationale) {
                            entity.complexityRationaleOverride = entity.complexityRationale;
                        }
                        if (!entity.modelUsageLevelOverride && entity.modelUsageLevel) {
                            entity.modelUsageLevelOverride = entity.modelUsageLevel;
                        }
                        if (!entity.modelUsageRationaleOverride && entity.modelUsageRationale) {
                            entity.modelUsageRationaleOverride = entity.modelUsageRationale;
                        }
                        if (!entity.externalImpactLevelOverride && entity.externalImpactLevel) {
                            entity.externalImpactLevelOverride = entity.externalImpactLevel;
                        }
                        if (!entity.externalImpactRationaleOverride && entity.externalImpactRationale) {
                            entity.externalImpactRationaleOverride = entity.externalImpactRationale;
                        }
                        if (!entity.tierRationaleOverride && entity.tierRationale) {
                            entity.tierRationaleOverride = entity.tierRationale;
                        }
                    }else if(userHasRoleSTD()){
                        entity.submit = true;
                    }
                },
                onUpdate: (fieldId, oldValue, newValue, entity, resource, resourceEditComponent) => {
                    if (
                        (
                            !entity.createdByMrm
                            && (
                                fieldId === 'tieringCategory'
                                || fieldId === 'quantifiedMateriality'
                                || fieldId === 'specialQuantifiedMateriality'
                                || fieldId === 'materialityLevel'
                                || fieldId === 'complexityLevel'
                                || fieldId === 'modelUsageLevel'
                                || fieldId === 'externalImpactLevel'
                                || fieldId === 'materialityLevelLocal'
                            )
                        )
                        ||
                        (
                            entity.createdByMrm
                            && (
                                fieldId === 'tieringCategory'
                                || fieldId === 'quantifiedMaterialityOverride'
                                || fieldId === 'specialQuantifiedMaterialityOverride'
                                || fieldId === 'materialityLevelOverride'
                                || fieldId === 'complexityLevelOverride'
                                || fieldId === 'modelUsageLevelOverride'
                                || fieldId === 'externalImpactLevelOverride'
                                || fieldId === 'materialityLevelLocalOverride'
                            )
                        )
                    ){
                        let modelEntity = APIResourceStore.resources.allModels.getObservableItemByPath(entity.model);
                        Http.post(`tiering/auto`, entity).then((response) => {
                            if (
                                hasOneGroupEntityNTX(modelEntity)
                                || response.autoAvailable === true
                            ) {
                                if (
                                    !entity.createdByMrm
                                    && fieldId !== 'materialityLevel'
                                    && fieldId !== 'modelUsageLevel'
                                    && fieldId !== 'externalImpactLevel'
                                ) {
                                    entity.materialityLevel = response.materialityLevelAuto;
                                    entity.modelUsageLevel = response.modelUsageLevelAuto;
                                    entity.externalImpactLevel = response.externalImpactLevelAuto;
                                }

                                if (fieldId === 'tieringCategory') {
                                    entity.metrics = response.metricsAuto;
                                }
                            }
                            entity.tierResult = response.tierResult;
                            entity.tierResultLocal = response.tierResultLocal;
                            entity.tierResultLocalOverride = response.tierResultLocalOverride;

                            entity.metricsAuto = response.metricsAuto;
                            entity.materialityLevelAuto = response.materialityLevelAuto;
                            entity.modelUsageLevelAuto = response.modelUsageLevelAuto;
                            entity.externalImpactLevelAuto = response.externalImpactLevelAuto;
                            entity.tierResultAuto = response.tierResultAuto;

                            // Pour MRM on propose le résultat auto, mais il pourra l'écraser
                            entity.tierResultOverride = response.tierResultOverride;

                            entity.quantifiedMaterialityUnit = response.quantifiedMaterialityUnit;
                            entity.quantifiedMaterialityUnitOverride = response.quantifiedMaterialityUnitOverride;
                            entity.quantifiedMaterialityUnitFinal = response.quantifiedMaterialityUnitFinal;
                            resourceEditComponent.setState({ entity: entity });
                        });
                    } else if (
                        userHasRoleMRM()
                        && (
                            fieldId === 'tieringCategory'
                            || fieldId === 'quantifiedMaterialityOverride'
                            || fieldId === 'specialQuantifiedMaterialityOverride'
                            || fieldId === 'materialityLevelOverride'
                            || fieldId === 'materialityLevelLocalOverride'
                            || fieldId === 'complexityLevelOverride'
                            || fieldId === 'modelUsageLevelOverride'
                            || fieldId === 'externalImpactLevelOverride'
                        )
                    ) {
                        Http.post(`tiering/auto`, entity).then((response) => {
                            // Cas particulier pour MRM pour mettre à jour automatiquement tierResultOverride
                            // même si l'entité n'est pas créée par MRM.
                            entity.tierResultOverride = response.tierResultOverride;
                            entity.tierResultLocalOverride = response.tierResultLocalOverride;
                            resourceEditComponent.setState({ entity: entity });
                        });
                    } else if (fieldId === 'metrics' || fieldId === 'metricsOverride') {
                        Http.post(`models/${entity.id}/tiering/unit`, entity).then((response) => {
                            entity.quantifiedMaterialityUnit = response.quantifiedMaterialityUnit;
                            entity.quantifiedMaterialityUnitOverride = response.quantifiedMaterialityUnitOverride;
                            entity.quantifiedMaterialityUnitFinal = response.quantifiedMaterialityUnitFinal;
                            resourceEditComponent.setState({ entity: entity });
                        });
                    }
                    resourceEditComponent.setState({ entity: entity });
                },
                header: header,
            })
            .genDetailView({
                additionalLinkButton: additionalLinkButton,
                header: header,
            })
            .genListView({
                fields: ['id','model','metricsFinal','quantifiedMaterialityFinal','materialityQuantifyingDateFinal','materialityLevelFinal','materialityRationaleFinal','complexityLevelFinal','complexityRationaleFinal','modelUsageLevelFinal','modelUsageRationaleFinal','externalImpactLevelFinal','externalImpactRationaleFinal','tierResultFinal','tierRationaleFinal'],
                additionalActionButtons: userHasRoleMRM() || userHasRoleADMIN() ? [
                    {
                        tooltip: 'Import',
                        icon: 'download',
                        className: 'download',
                        onClick: () => Modal.open({
                            title: "Import",
                            content: (
                                <EntityImportForm resource="tierings" />
                            ),
                            modalStyle: {width: "420px"}
                        }),
                    },
                    {
                        tooltip: 'Full export',
                        icon: 'upload',
                        className: 'upload',
                        onClick: () => Modal.open({
                            title: "Export",
                            content: (
                                <EntityExportForm resource="tierings" />
                            ),
                            modalStyle: {width: "420px"}
                        }),
                    },
                ] : null,
                permanentFilters: {last_verified: true},
            })
            .allowDelete({
                itemAccessCondition: (entity) => {
                    return userHasMRMRights(User, APIResourceStore.resources.allModels.getObservableItemByPath(entity.model))
                },
                returnPath: (entity) => {
                    let modelPath = entity.model;
                    if(modelPath){
                        let model = modelPath;
                        model = model.split('/');
                        let modelId = model[model.length - 1];
                        return (
                            '/resource/models/' +
                            modelId +
                            '/detail?tab=Tiering'
                        );
                    }
                    return '/resource/models/list';
                },
            })
            .addBulkAction(BulkEdit, {
                resource: tierings,
                    icon: 'edit',
                fields: Object.entries(TIERING_FIELDS(tierings, APIResourceStore.resources.allModels)).reduce((obj, [k, v]) => {
                    if (v.bulk) obj[k] = v;
                    return obj;
                }, {}),
            })
        ;
    }
}
