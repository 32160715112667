import React, {Component} from "react";
import Modal from "../../Services/Modal";
import Alert from "../../Services/Alert";
import Navigation from "../../Services/Navigation";
import {ParameterSelect} from "../Forms/ParameterSelect/ParameterSelect";
import ParameterStore, {hasGroupEntityBPCE, hasOneGroupEntityNTX, userHasRoleMRM} from "../../Store/ParameterStore";
import Http from "../../Services/Http";
import TextField from "@material-ui/core/TextField";
import {SuffixButton} from "../SuffixButton/SuffixButton";
import {HelperButton} from "../HelperButton/HelperButton";
import {Box} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {APIResource} from "../../Services/APIResource/APIResource";
import {TIERING_FIELDS} from "../../Admin/TieringAdmin";
import { ButtonBar } from "../Modal/ButtonBar";
import { ActionButton } from "../Modal/ActionButton";

export class TieringForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tieringCategory: null,
            local: false,
            //Pour MO
            materialityLevel: null,
            modelUsageLevel: null,
            externalImpactLevel: null,
            //Pour MRM
            materialityLevelOverride: null,
            modelUsageLevelOverride: null,
            externalImpactLevelOverride: null,

            quantifiedMateriality: null,

            errorDetected: false,
            errors: [],
            available: null,
            quantifiedMaterialityUnit: null
        };

        this.tieringResource = new APIResource({ id: 'tierings', instanceId: 'tiering_form_tierings',name: 'Tierings' });
        this.tieringResource.setFields(TIERING_FIELDS(this.tieringResource));
    }

    componentDidMount() {
        this.isAutomaticCalculationAvailable();
        this.initEntity();
    }

    isAutomaticCalculationAvailable() {
        Http.get(`models/${this.props.model.id}/tiering/auto`).then((response) => {
            this.setState({
                available: response.available,
                quantifiedMaterialityUnit: response.quantifiedMaterialityUnit,
                riskCategory: response.riskCategory,
                detailedUse: response.detailedUse,
            });
        });
    }

    initEntity(){
        let entity = {};
        entity.model = '/api/models/' + this.props.model.id;
        entity.tieringCategory = this.state.tieringCategory;
        entity.local = this.state.local;
        entity.quantifiedMateriality = this.state.quantifiedMateriality;
        entity.quantifiedMaterialityOverride = this.state.quantifiedMaterialityOverride;
        entity.specialQuantifiedMateriality = this.state.specialQuantifiedMateriality;
        entity.specialQuantifiedMaterialityOverride = this.state.specialQuantifiedMaterialityOverride;
        entity.materialityLevel = this.state.materialityLevel;
        entity.modelUsageLevel = this.state.modelUsageLevel;
        entity.externalImpactLevel = this.state.externalImpactLevel;
        entity.materialityLevelOverride = this.state.materialityLevelOverride;
        entity.modelUsageLevelOverride = this.state.modelUsageLevelOverride;
        entity.externalImpactLevelOverride = this.state.externalImpactLevelOverride;
        if(userHasRoleMRM()){
            entity.createdByMrm = true;
        }
        this.entity = entity;
    }

    save() {
        let entity = {};
        entity.model = '/api/models/' + this.props.model.id;
        entity.tieringCategory = this.state.tieringCategory;
        entity.local = this.state.local;
        entity.quantifiedMateriality = this.state.quantifiedMateriality;
        entity.quantifiedMaterialityOverride = this.state.quantifiedMaterialityOverride;
        entity.specialQuantifiedMateriality = this.state.specialQuantifiedMateriality;
        entity.specialQuantifiedMaterialityOverride = this.state.specialQuantifiedMaterialityOverride;
        entity.materialityLevel = this.state.materialityLevel;
        entity.modelUsageLevel = this.state.modelUsageLevel;
        entity.externalImpactLevel = this.state.externalImpactLevel;
        entity.materialityLevelOverride = this.state.materialityLevelOverride;
        entity.modelUsageLevelOverride = this.state.modelUsageLevelOverride;
        entity.externalImpactLevelOverride = this.state.externalImpactLevelOverride;

        let errors = {};
        if (this.state.available === false) {
            if (
                hasOneGroupEntityNTX(this.props.model)
                && this.state.tieringCategory === null
            ) {
                errors.tieringCategory = true;
            }
            if (this.state.local === null) {
                errors.local = true;
            }
            if (
                hasGroupEntityBPCE(this.props.model)
                && (this.state.materialityLevel === null && this.state.materialityLevelOverride === null)
            ) {
                errors.materialityLevel = true;
                errors.materialityLevelOverride = true;
            }
            if (
                hasGroupEntityBPCE(this.props.model)
                && (this.state.modelUsageLevel === null && this.state.modelUsageLevelOverride === null)
            ) {
                errors.modelUsageLevel = true;
                errors.modelUsageLevelOverride = true;
            }
            if (
                hasGroupEntityBPCE(this.props.model)
                && (this.state.externalImpactLevel === null && this.state.externalImpactLevelOverride === null)
            ) {
                errors.externalImpactLevel = true;
                errors.externalImpactLevelOverride = true;
            }
        } else {
            if (this.state.quantifiedMateriality === null && this.state.quantifiedMaterialityOverride === null) {
                errors.quantifiedMateriality = true;
                errors.quantifiedMaterialityOverride = true;
            }

        }
        if (Object.keys(errors).length > 0) {
            this.setState({errors: errors})
            Alert.show({message: 'Please fill required fields', type: 'danger'});
            return;
        }

        this.setState({apiUpdateInProgress: true});
        let entityId = null;

        if(userHasRoleMRM()){
            entity.createdByMrm = true;
        }else{
            entity.submit = true;
        }

        this.tieringResource.apiPost(entity).then((entity) => {
            Alert.show({message: "Tiering successfully created.", type: "success"});
            entityId = entity.id;
        })
            .finally(() => {
                Modal.close();
                if (entityId !== null) {
                    Navigation.router.history.push("/resource/tierings/" + entityId + '/edit');
                } else {
                    Alert.show({message: 'An error occurred.', type: 'danger'});
                }
                this.setState({
                    apiUpdateInProgress: false,
                });
                this.tieringResource.apiGetCollection({
                    page: 1,
                    rowsPerPage: 10000,
                    forceReload: true
                });
            });
    }

    onChange(property, value) {
        if (property === 'tieringCategory') {
            if (value === ParameterStore('TIERING_CATEGORY_MARKET_RISK_CATEGORY_AND_MODEL_MARKET_RISK_RESERVE_OR_IPV')) {
                this.setState({quantifiedMaterialityUnit: "M€"});
            }else{
                this.setState({quantifiedMaterialityUnit: null});
            }
        }
    }

    render() {
        const NTX = hasOneGroupEntityNTX(this.props.model);
        const BPCE = hasGroupEntityBPCE(this.props.model);
        return (
            <div className={"new-tiering-form"}>
                {this.state.available !== null && BPCE ?
                    <div className={"alert alert-info"} role="alert"
                         dangerouslySetInnerHTML={{ __html: this.state.available === false ? "No automatic proposition" : `Automatic proposition based on Risk Category <b>${this.state.riskCategory}</b> and main use <b>${this.state.detailedUse}</b>.` }}
                    />
                    : null}
                {this.state.available === false && NTX ?
                    <ParameterSelect
                        label={this.tieringResource.fields['tieringCategory'].title}
                        field={this.tieringResource.fields['tieringCategory']}
                        value={this.state.tieringCategory}
                        entity={this.entity}
                        issueButton={true}
                        clearable={false}
                        multi={false}
                        required={true}
                        onChange={(item) => {
                            this.setState({tieringCategory: item});
                            this.onChange('tieringCategory', item);
                        }}
                        error={this.state.tieringCategory === null && this.state.errors.tieringCategory}
                        className={this.state.tieringCategory === null && this.state.errors.tieringCategory ? "field-error-control" : ""}
                        helperText={this.state.tieringCategory === null && this.state.errors.tieringCategory ? this.tieringResource.fields['tieringCategory'].title + ': This field is required' : null}
                    />
                    : null}
                {this.state.available !== null
                    ?
                    userHasRoleMRM() ?
                        <div
                            className={
                                'edit-field ressource-api-field' +
                                (this.tieringResource.fields['quantifiedMaterialityOverride'].helperTextEdit
                                    ? ' with-helper-button'
                                    : '') +
                                (this.state.quantifiedMaterialityUnit
                                    ? ' with-suffix-button'
                                    : '')
                            }
                            key={'quantifiedMaterialityOverride'}
                        >
                            <TextField
                                label={this.tieringResource.fields['quantifiedMaterialityOverride'].title}
                                type="number"
                                onChange={event => {
                                    this.setState({quantifiedMaterialityOverride: parseFloat(event.target.value)});
                                }}
                                value={this.state.quantifiedMaterialityOverride}
                                required={false}
                                error={this.state.quantifiedMaterialityOverride === null && this.state.errors.quantifiedMaterialityOverride}
                                className={this.state.quantifiedMaterialityOverride === null && this.state.errors.quantifiedMaterialityOverride ? "field-error-control" : ""}
                                helperText={this.state.quantifiedMaterialityOverride === null && this.state.errors.quantifiedMaterialityOverride ? this.tieringResource.fields['quantifiedMaterialityOverride'].title + ': This field is required' : null}
                            />
                            {
                                this.state.quantifiedMaterialityUnit
                                    ? (
                                        <SuffixButton
                                            suffix={this.state.quantifiedMaterialityUnit}
                                            entity={this.state.entity}
                                        />
                                    ) : null}
                            {
                                this.tieringResource.fields['quantifiedMaterialityOverride'].helperTextEdit
                                    ? (
                                        <HelperButton
                                            helperText={
                                                this.tieringResource.fields['quantifiedMaterialityOverride'].helperTextEdit
                                            }
                                            entity={this.state.entity}
                                        />
                                    ) : null}
                        </div> : <div
                            className={
                                'edit-field ressource-api-field' +
                                (this.tieringResource.fields['quantifiedMateriality'].helperTextEdit
                                    ? ' with-helper-button'
                                    : '') +
                                (this.state.quantifiedMaterialityUnit
                                    ? ' with-suffix-button'
                                    : '')
                            }
                            key={'quantifiedMateriality'}
                        >
                            <TextField
                                label={this.tieringResource.fields['quantifiedMateriality'].title}
                                type="number"
                                onChange={event => {
                                    this.setState({quantifiedMateriality: parseFloat(event.target.value)});
                                }}
                                value={isNaN(this.state.quantifiedMateriality) || this.state.quantifiedMateriality === null || typeof this.state.quantifiedMateriality === undefined ? '' : this.state.quantifiedMateriality}
                                required={false}
                                error={this.state.quantifiedMateriality === null && this.state.errors.quantifiedMateriality}
                                className={this.state.quantifiedMateriality === null && this.state.errors.quantifiedMateriality ? "field-error-control" : ""}
                                helperText={this.state.quantifiedMateriality === null && this.state.errors.quantifiedMateriality ? this.tieringResource.fields['quantifiedMateriality'].title + ': This field is required' : null}
                            />
                            {
                                this.state.quantifiedMaterialityUnit
                                    ? (
                                        <SuffixButton
                                            suffix={this.state.quantifiedMaterialityUnit}
                                            entity={this.state.entity}
                                        />
                                    ) : null}
                            {
                                this.tieringResource.fields['quantifiedMateriality'].helperTextEdit
                                    ? (
                                        <HelperButton
                                            helperText={
                                                this.tieringResource.fields['quantifiedMateriality'].helperTextEdit
                                            }
                                            entity={this.state.entity}
                                        />
                                    ) : null}
                        </div>
                :null }
                {this.state.available !== null && this.state.tieringCategory === ParameterStore('TIERING_CATEGORY_MARKET_RISK_CATEGORY_AND_PRICING_OR_VALUATION') ?
                    userHasRoleMRM() ?
                        <ParameterSelect
                            label={this.tieringResource.fields['specialQuantifiedMaterialityOverride'].title}
                            field={this.tieringResource.fields['specialQuantifiedMaterialityOverride']}
                            value={this.state.specialQuantifiedMaterialityOverride}
                            issueButton={true}
                            clearable={false}
                            multi={false}
                            required={true}
                            onChange={(item) => this.setState({specialQuantifiedMaterialityOverride: item})}
                            error={this.state.specialQuantifiedMaterialityOverride === null && this.state.errors.specialQuantifiedMaterialityOverride}
                            className={this.state.specialQuantifiedMaterialityOverride === null && this.state.errors.specialQuantifiedMaterialityOverride ? "field-error-control" : ""}
                            helperText={this.state.specialQuantifiedMaterialityOverride === null && this.state.errors.specialQuantifiedMaterialityOverride ? this.tieringResource.fields['specialQuantifiedMaterialityOverride'].title + ': This field is required' : null}
                        />
                        :
                        <ParameterSelect
                            label={this.tieringResource.fields['specialQuantifiedMateriality'].title}
                            field={this.tieringResource.fields['specialQuantifiedMateriality']}
                            value={this.state.specialQuantifiedMateriality}
                            issueButton={true}
                            clearable={false}
                            multi={false}
                            required={true}
                            onChange={(item) => this.setState({specialQuantifiedMateriality: item})}
                            error={this.state.specialQuantifiedMateriality === null && this.state.errors.specialQuantifiedMateriality}
                            className={this.state.specialQuantifiedMateriality === null && this.state.errors.specialQuantifiedMateriality ? "field-error-control" : ""}
                            helperText={this.state.specialQuantifiedMateriality === null && this.state.errors.specialQuantifiedMateriality ? this.tieringResource.fields['specialQuantifiedMateriality'].title + ': This field is required' : null}
                        />
                    : null}
                {this.state.available === false && BPCE ?
                    userHasRoleMRM() ?
                        <ParameterSelect
                            label={this.tieringResource.fields['materialityLevelOverride'].title}
                            field={this.tieringResource.fields['materialityLevelOverride']}
                            value={this.state.materialityLevelOverride}
                            issueButton={true}
                            clearable={false}
                            multi={false}
                            required={true}
                            onChange={(item) => this.setState({materialityLevelOverride: item})}
                            error={this.state.materialityLevelOverride === null && this.state.errors.materialityLevelOverride}
                            className={this.state.materialityLevelOverride === null && this.state.errors.materialityLevelOverride ? "field-error-control" : ""}
                            helperText={this.state.materialityLevelOverride === null && this.state.errors.materialityLevelOverride ? this.tieringResource.fields['materialityLevelOverride'].title + ': This field is required' : null}
                        />
                        :
                        <ParameterSelect
                            label={this.tieringResource.fields['materialityLevel'].title}
                            field={this.tieringResource.fields['materialityLevel']}
                            value={this.state.materialityLevel}
                            issueButton={true}
                            clearable={false}
                            multi={false}
                            required={true}
                            onChange={(item) => this.setState({materialityLevel: item})}
                            error={this.state.materialityLevel === null && this.state.errors.materialityLevel}
                            className={this.state.materialityLevel === null && this.state.errors.materialityLevel ? "field-error-control" : ""}
                            helperText={this.state.materialityLevel === null && this.state.errors.materialityLevel ? this.tieringResource.fields['materialityLevel'].title + ': This field is required' : null}
                        />
                    : null}
                {this.state.available === false && BPCE ?
                    userHasRoleMRM() ?
                        <ParameterSelect
                            label={this.tieringResource.fields['modelUsageLevelOverride'].title}
                            field={this.tieringResource.fields['modelUsageLevelOverride']}
                            value={this.state.modelUsageLevelOverride}
                            issueButton={true}
                            clearable={false}
                            multi={false}
                            required={true}
                            onChange={(item) => this.setState({modelUsageLevelOverride: item})}
                            error={this.state.modelUsageLevelOverride === null && this.state.errors.modelUsageLevelOverride}
                            className={this.state.modelUsageLevelOverride === null && this.state.errors.modelUsageLevelOverride ? "field-error-control" : ""}
                            helperText={this.state.modelUsageLevelOverride === null && this.state.errors.modelUsageLevelOverride ? this.tieringResource.fields['modelUsageLevelOverride'].title + ': This field is required' : null}
                        />
                        :
                        <ParameterSelect
                            label={this.tieringResource.fields['modelUsageLevel'].title}
                            field={this.tieringResource.fields['modelUsageLevel']}
                            value={this.state.modelUsageLevel}
                            issueButton={true}
                            clearable={false}
                            multi={false}
                            required={true}
                            onChange={(item) => this.setState({modelUsageLevel: item})}
                            error={this.state.modelUsageLevel === null && this.state.errors.modelUsageLevel}
                            className={this.state.modelUsageLevel === null && this.state.errors.modelUsageLevel ? "field-error-control" : ""}
                            helperText={this.state.modelUsageLevel === null && this.state.errors.modelUsageLevel ? this.tieringResource.fields['modelUsageLevel'].title + ': This field is required' : null}
                        />
                    : null}
                {this.state.available === false && BPCE ?
                    userHasRoleMRM() ?
                        <ParameterSelect
                            label={this.tieringResource.fields['externalImpactLevelOverride'].title}
                            field={this.tieringResource.fields['externalImpactLevelOverride']}
                            value={this.state.externalImpactLevelOverride}
                            issueButton={true}
                            clearable={false}
                            multi={false}
                            required={true}
                            onChange={(item) => this.setState({externalImpactLevelOverride: item})}
                            error={this.state.externalImpactLevelOverride === null && this.state.errors.externalImpactLevelOverride}
                            className={this.state.externalImpactLevelOverride === null && this.state.errors.externalImpactLevelOverride ? "field-error-control" : ""}
                            helperText={this.state.externalImpactLevelOverride === null && this.state.errors.externalImpactLevelOverride ? this.tieringResource.fields['externalImpactLevelOverride'].title + ': This field is required' : null}
                        />
                        :
                        <ParameterSelect
                            label={this.tieringResource.fields['externalImpactLevel'].title}
                            field={this.tieringResource.fields['externalImpactLevel']}
                            value={this.state.externalImpactLevel}
                            issueButton={true}
                            clearable={false}
                            multi={false}
                            required={true}
                            onChange={(item) => this.setState({externalImpactLevel: item})}
                            error={this.state.externalImpactLevel === null && this.state.errors.externalImpactLevel}
                            className={this.state.externalImpactLevel === null && this.state.errors.externalImpactLevel ? "field-error-control" : ""}
                            helperText={this.state.externalImpactLevel === null && this.state.errors.externalImpactLevel ? this.tieringResource.fields['externalImpactLevel'].title + ': This field is required' : null}
                        />
                    : null}
                {this.state.available !== null ?
                    <ButtonBar>
                        <ActionButton
                            onClick={Modal.close}
                            disabled={this.state.apiUpdateInProgress}
                        >
                            Cancel
                        </ActionButton>
                        <ActionButton
                            onClick={() => this.save()}
                            loading={this.state.apiUpdateInProgress}
                        >
                            Save and edit details
                        </ActionButton>
                    </ButtonBar>
                    :
                    <Box textAlign="center">
                        <CircularProgress/>
                    </Box>
                }
            </div>
        );
    }
}