/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { observer } from "mobx-react";
import "./entity-select.css";
import Select from "react-select";
import { APIResource } from "../../../Services/APIResource/APIResource";
import { IssueButton } from "../../Issue/IssueButton";

export const EntitySelect = observer(
    class EntitySelect extends Component {
        constructor(props) {
            super(props);
            this.handleChange = this.handleChange.bind(this);

            this.state = {
                value: -1,
                isLoading: true,
                issueButton: false,
            };

            this.resource = new APIResource({
                instanceId:
                    'entity_select_' +
                    (this.props.instanceId ? this.props.instanceId : this.props.resourceId) +
                    (this.props.context ? '_' + this.props.context : ''),
                id: this.props.resourceId,
                name: this.props.label,
                endpoints: this.props.endpoints || null,
                context: this.props.context,
            });
        }

        componentDidMount() {
            this.mounted = true;
            let defaultFields = [this.props.resourceLabel || 'toString', 'verified', 'out_of_repository'];
            this.resource
                .apiGetCollection({
                    page: 1,
                    rowsPerPage: this.resource.endpoints && this.resource.endpoints.getAll ? -1 : 10000,
                    ...(this.props.apiFilters ? {filters: this.props.apiFilters} : {}),
                    fields: Array.isArray(this.props.neededFields) ? this.props.neededFields.concat(defaultFields) : defaultFields
                })
                .then(() => {
                    if (this.mounted) {
                        this.setState({ isLoading: false });
                        if (this.props.value) {
                            this.updateValue(this.props.value);
                        }
                    }
                });
            if (this.props.value) {
                this.updateValue(this.props.value);
            }
            if (this.props.issueButton === undefined) {
                this.setState({ issueButton: false });
            } else {
                this.setState({ issueButton: this.props.issueButton });
            }
        }

        componentWillUnmount() {
            this.mounted = false;
        }

        componentDidUpdate(prevProps, _prevState, _snapshot) {
            if (
                (this.mounted && this.props.value !== prevProps.value) ||
                (this.props.entity && this.props.entity != prevProps.entity)
            ) {
                this.updateValue(this.props.value);
            }
        }

        setAllowedValue(value, callback) {
            if (this.isLoading) {
                // On n'a pas encore les items, on accepte
                this.setState({ value }, callback);
            } else {
                // On vérifie que la valeur en question est autorisée
                let allowedItems = this.genItems().map((i) => i.value);
                if (Array.isArray(value)) {
                    const allowedValues = value.filter(
                        (v) => !allowedItems || !allowedItems.length || allowedItems.includes(v.value)
                    );

                    if (allowedValues.length !== value.length) {
                        this.handleChange(allowedValues, callback);
                    } else {
                        this.setState({ value }, callback);
                    }
                } else {
                    if (value && !allowedItems.includes(value.value)) {
                        this.handleChange(null, callback);
                    } else {
                        this.setState({ value }, callback);
                    }
                }
            }
        }

        isNullable() {
            if (!Array.isArray(this.state.value)) return true;

            return !this.props.orExistsMultiFilterNotAllowed || this.state.value.length === 0 || this.state.value.some( v => v.value === 'null')
        }

        isMulti() {
            if (!Array.isArray(this.state.value)) return this.props.multi;

            return this.props.multi && (!this.props.orExistsMultiFilterNotAllowed || this.state.value.every(v => v.value !== 'null'))
        }

        updateValue(value) {
            if (Array.isArray(value)) {
                if (value.length) {
                    if (typeof value[0] != 'object') {
                        let promises = [];
                        let values = [];
                        if(value.includes('null')){
                            values.push({
                                id: 'null',
                                value: 'null',
                                label: 'Null'
                            });
                        }
                        value.forEach((val) => {
                            let promise = this.resource.getItemFromResourcePath(val).then((entity) => {
                                if (entity) {
                                    values.push({
                                        value: entity['@id'],
                                        label: entity[this.props.resourceLabel],
                                        id: entity.id,
                                    });
                                }
                            });

                            promises.push(promise);
                        });
                        Promise.all(promises).then(() => {
                            this.setAllowedValue(values);
                        });
                    } else {
                        this.setAllowedValue(value);
                    }
                } else {
                    this.setAllowedValue(value);
                }
            } else {
                if (value && typeof value != 'object') {
                    this.resource.getItemFromResourcePath(value).then((entity) => {
                        if (entity) {
                            this.setAllowedValue({
                                value: entity['@id'],
                                label: entity[this.props.resourceLabel],
                                id: entity.id,
                            });
                        }
                    });
                } else {
                    this.setAllowedValue(value);
                }
            }
        }

        genItems() {
            let items = [];
            if (this.props.entity && !Object.keys(this.props.entity)) {
                return items;
            }

            if (this.resource) {
                items = this.resource.filterItems(this.props.filters, this.props.entity).map((item) => {
                    if(item){
                        return {
                            id: item.id,
                            value: item['@id'],
                            label: item[this.props.resourceLabel],
                        };
                    }
                });
            }
            if (this.props.value !== undefined && this.props.value != null) {
                if (!items.map((item) => item.value).filter((value) => value === this.props.value).length) {
                    let currentItem = this.resource.filterItems({ '@id': this.props.value });
                    if (currentItem.length) {
                        currentItem = currentItem[0];
                        items.push({
                            id: currentItem.id,
                            value: currentItem['@id'],
                            label: currentItem[this.props.resourceLabel],
                        });
                    }
                }
            }

            if(this.props.optionsSort) {
                items = items.sort(this.props.optionsSort)
            }

            if(this.props.context === 'filter' && this.isNullable()) {
                items.unshift({
                    id: 'null',
                    value: 'null',
                    label: 'Null'
                });
            }

            return items;
        }

        handleChange(selection, callback) {
            if (!selection && this.props.multi) {
                selection = [];
            }
            if (this.props.onChange) {
                if (!Array.isArray(selection)) {
                    if (!this.props.returnId) {
                        this.props.onChange(selection ? selection.value : null);
                    } else {
                        this.props.onChange(selection ? selection.id : null);
                    }
                } else {
                    if (!this.props.returnId) {
                        this.props.onChange(selection.map((item) => item.value));
                    } else {
                        this.props.onChange(selection.map((item) => item.id));
                    }
                }
            }
            this.setState({ value: selection }, callback);
        }

        onInputChange(newVal) {
            return this.props.onInputChange ? this.props.onInputChange(newVal) : newVal
        }

        render() {
            return (
                <div className={'select-component' + ' ' + (this.props.helperText ? 'field-error-control' : '')}>
                    {this.props.label ? (
                        <label>
                            {this.props.label}
                            {this.props.required ? ' *' : ''}
                        </label>
                    ) : (
                        ''
                    )}
                    <div className={this.state.issueButton ? 'with-issue-button' : ''}>
                        <Select
                            options={this.genItems()}
                            isLoading={this.state.isLoading}
                            isSearchable={this.props.searchable}
                            isClearable={this.props.clearable}
                            isDisabled={this.props.disabled}
                            placeholder={this.props.label}
                            isMulti={this.isMulti()}
                            label={`${this.props.label}`}
                            required={this.props.required}
                            onChange={(selection) => this.handleChange(selection)}
                            onInputChange={this.onInputChange.bind(this)}
                            value={this.state.value}
                            error={!!this.props.helperText}
                            className={'entity-select '}
                            //     helperText={this.props.helperText}
                        />
                        {this.state.issueButton ? (
                            this.props.issueButtonComponent ? (
                                this.props.issueButtonComponent
                            ) : (
                                <IssueButton
                                    field={this.props.label}
                                    issueButton={this.state.issueButton}
                                    entity={this.props.entity}
                                />
                            )
                        ) : null}
                    </div>
                </div>
            );
        }
    }
);
