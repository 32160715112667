import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../../../Services/Modal";
import Alert from "../../../Services/Alert";
// import FormControl from "@material-ui/core/FormControl";
// import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Navigation from "../../../Services/Navigation";
import Http from "../../../Services/Http";
import {CircularProgress, Typography} from "@material-ui/core";
import { ModalContent } from "../../Modal/ModalContent";
import { ActionButton } from "../../Modal/ActionButton";
import { ButtonBar } from "../../Modal/ButtonBar";

export const DeleteForm = (props) => {
    // const [comment, setComment] = useState(null);
    const [progress, setProgress] = useState(false);
    
    const { entity, ids, entityTypeLabel = 'model', entityTitle} = props;
    /** @type {import("../../../Services/APIResource/APIResource").APIResource} */
    const resource = props.resource;

    const endpoint = `${resource.resourceId}/bulk/delete`;

    const deleteItems = async (entities) => {
        /* Pas de commentaire pour les suppressions définitives, à l'inverse de LogicalDelete
        if (!comment) {
            Alert.show({
                message: "The comment is required before deletion.",
                type: "danger"
            });
            return;
        } */

        setProgress(true);
        Navigation.router.history.push(`/resource/${resource.instanceId}/list`);

        const ids_ = entities.map(e => e.id);

        try {
            await Http.post(endpoint, {ids: ids_});// , deletionComment: comment});
            const message = ids_.length === 1 ? "Item successfully deleted." : "Items successfully deleted.";
            Alert.show({ message: message, type: "success" });
            resource
            resource.apiGetCollection({
                page: 1,
                rowsPerPage: 10000,
                forceReload: true
            });
        }
        catch(err) {
            console.error(err);
        }
        Modal.close();
        setProgress(false);
    }

    const getTitle = () => {
        if (entity) {
            return `Are you sure you want to delete the ${entityTypeLabel.toLowerCase()} ${entityTitle || entity.functionalID} ?`;
        }
        if (ids) {
            return `Are you sure you want to delete ${ids.length} ${entityTypeLabel.toLowerCase()}${ids.length > 1 ? 's' : ''} ?`;
        }
    };

    const handleDeleteClick = () => {
        if (entity || (ids && ids.length)) {
            const entities = entity ? [entity] : ids.map(id => ({ id }));
            return deleteItems(entities);
        }
    }
    
    return (
        <ModalContent>
            <Typography component="p">{getTitle()}</Typography>
            {/* TODO : pas de commentaire pour les suppressions définitives.
            <FormControl className="container_textarea">
                <label>Comment</label>
                <TextareaAutosize
                    value={comment ? comment : ""}
                    onChange={event => setComment(event.target.value)}
                    rows={5}
                    rowsMax={10}
                />
            </FormControl> */}
            <ButtonBar>
                <ActionButton disabled={progress} onClick={Modal.close}>
                    Cancel
                </ActionButton>
                <ActionButton disabled={progress} onClick={handleDeleteClick}>
                    Delete {progress && <CircularProgress size={20} />}
                </ActionButton>
            </ButtonBar>
        </ModalContent>
    );

}
DeleteForm.propTypes = {
    ids: PropTypes.array,
    /** @type {import("../../Services/APIResource/APIResource").APIResource} */
    resource: PropTypes.object,
    entity: PropTypes.object,
    entityTypeLabel: PropTypes.string,
    entityTitle: PropTypes.string,
}
