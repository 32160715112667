import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Http from '../../Http';
import Alert from '../../Alert';
import Modal from '../../Modal';
import { LoadingSpinner } from '../../../Components/LoadingIndicator/LoadingSpinner';
import { ModalContent } from '../../../Components/Modal/ModalContent';
import { ActionButton } from '../../../Components/Modal/ActionButton';
import { ButtonBar } from '../../../Components/Modal/ButtonBar';

export class BulkVerify {
    getId() {
        return 'bulkVerify';
    }

    getLabel() {
        return 'Verify';
    }

    setParameters(params) {
        this.parameters = params;
    }

    run(ids) {
        this.ids = ids;

        Modal.open({
            title: 'Bulk Verify',
            content: <VerifyForm ids={this.ids} resource={this.parameters.resource} resourceId={this.parameters.resourceId} />,
        });
    }
}

export class VerifyForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            disabled: false,
        };
    }

    /**
     * Déclenche en back la vérification des entities données, avec status = 1, ou 0.
     * 
     * @param {number} status 1 ou 0
     */
    exec(status) {
        this.setState((prevState) => ({ disabled: !prevState.disabled }));

        Http.post(this.props.resourceId + '/verify', {
            ids: this.props.ids,
            status: status,
        }).then((response) => {
            if (response.status !== 'OK') {
                Alert.show({ message: response.message});
                Modal.close();
                return;
            }
            this.props.resource.apiGetCollection({ filters: {}, forceReload: true });
            Alert.show({
                message:
                    this.props.ids.length > 1
                        ? 'Bulk verification\'s changes successfully processed.'
                        : 'Verification\'s change successfully processed.',
            });
            Modal.close();
        });
    }

    unverify() {
        this.exec(0);
    }

    verify() {
        this.exec(1);
    }

    render() {
        return (
            <ModalContent>
                <ButtonBar>
                    <ActionButton 
                        disabled={this.state.disabled} 
                        onClick={this.unverify.bind(this)}
                    >
                        Un-verify
                        {this.state.disabled && <LoadingSpinner />}
                    </ActionButton>
                    <ActionButton
                        disabled={this.state.disabled} 
                        onClick={this.verify.bind(this)}
                        color="secondary"
                    >
                        Verify
                        {this.state.disabled && <LoadingSpinner />}
                    </ActionButton>
                </ButtonBar>
            </ModalContent>
        );
    }
}
VerifyForm.propTypes = {
    /** Liste des ids à traiter en bulk */
    ids: PropTypes.array,
    /** ApiResource à utiliser */
    resource: PropTypes.object,
    /** Type de l'entité tel qu'utilisé par api platform. ex : models, change_logs .. */
    resourceId: PropTypes.string,
};
