import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { APIResource } from '../../../Services/APIResource/APIResource';
import { SubdimensionContent } from './SubDimensionContent';
import Alert from '../../../Services/Alert';
import { scrollToTop } from '../../../Services/utils';

import { Paper } from '@material-ui/core';
import { Button, Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Http from '../../../Services/Http';
import { getParamBySystemId, getParamByIri } from '../../../Store/ParameterStore';
import { MRA_STATUS, MRA_PROCESS } from '../../../Services/MRA';
import UsaFlagImage from '../../../Styles/Assets/img/usa.png';
import FranceFlagImage from '../../../Styles/Assets/img/france.png';

export const DimensionContent = (props) => {
    const {
        dimension,
        onSave,
        onSubmit,
        onChangeLang,
        currentLang,
        addMraScore,
        getMraScores,
        mra,
        saving
    } = props;

    const parentDimension = getParamByIri(dimension.dimension);
    const currentStatus = getParamByIri(mra.status);
    const currentProcess = getParamByIri(mra.process);
    const isLoD1SelfAssessment = currentProcess.systemId === MRA_PROCESS.LOD1
    let canSave = false;
    if(currentStatus.systemId === MRA_STATUS.CONFLICT || currentStatus.systemId === MRA_STATUS.ON_GOING){
        canSave = true;
    }
    const isNTXDimension = parentDimension.systemId && parentDimension.systemId === "MRA_DIMENSION_SOURCE_NTX"
    const isGROUPDimension = parentDimension.systemId && parentDimension.systemId === "MRA_DIMENSION_SOURCE_GROUP"
    const [subDimensions, setSubDimensions] = useState([]); // on cumule les subdimensions meme quand props.dimension change
    const [loadingDimension, setLoadingDimension] = useState(true);
    const [onlyReload, setOnlyReload] = useState(false);
    const [forceReload, setForceReload] = useState(true);
    const [nextStatus, setNextStatus] = useState(null);

    const subDimensionsResource = useMemo(
        () =>
            new APIResource({
                id: 'mra_subdimensions',
                name: 'Subdimensions',
            }),
        []
    );

    const onScoreChange = useCallback(addMraScore, []);
    const getMraScoresMemo = getMraScores; // useCallback(getMraScores, [scoresMap]);

    useEffect(() => {
        const getNextStatus = async () => {
            let data = await Http.get('mras/' + mra.id + '/next_status');
            let systemId = data['hydra:member'][mra.id]['nextStatusSystemId'];
            setNextStatus(getParamBySystemId(systemId));
        };
        mra && getNextStatus();
    }, [mra]);

    /**
     * Rechargement des sous-dimensions quand la dimension change.
     */
    useEffect(() => {
        setSubDimensions([]);
        loadSubDimensions(dimension, subDimensionsResource);
    }, [dimension, subDimensionsResource, forceReload]);

    /**
     * Re render des composants quand les sous-dimensions changent.
     */
    useEffect(() => {
        // Réactivation du bouton
        if (subDimensions && subDimensions.length) {
            setLoadingDimension(false);
        }
    }, [subDimensions]);

    const loadSubDimensions = (dimension, subDimensionsResource) => {
        setLoadingDimension(true);
        Promise.delay = function(t, val) {
            return new Promise((resolve) => {
                setTimeout(resolve.bind(null, val), t);
            });
        };
        if (dimension && dimension.mraSubdimensions) {
            let promises = dimension.mraSubdimensions.map((value) =>
                subDimensionsResource.getItemFromResourcePath(value)
            );
            /**
             * Si une des Promise ne resoud jamais, alors on resterait indéfiniment en attente.
             * Pour contrer cela on met une race avec une Promise "delay", et si la course est perdue
             * on ne met pas à jour les sous dimensions, et on active le bouton "reload".
             * (NB: la Promesse qui a perdu est quand même résolue !)
             * A l'appel suivant, le cache de ApiResource va accélerer les choses et la requête a des chances de
             * passer.
             */
            Promise.all(
                promises.map((p) => {
                    return Promise.race([p, Promise.delay(5000, 'timeout')]);
                })
            ).then((subDimensions) => {
                let subdimensions_ = subDimensions.filter(
                    (o) => o !== 'timeout'
                );
                if (subdimensions_.length !== subDimensions.length) {
                    Alert.show({
                        message:
                            'Subdimensions fetching took too long : aborting. Please reload.',
                    });
                    setLoadingDimension(false);
                    setOnlyReload(true);
                } else {
                    // Si on veut garder les anciennes sous-dimensions (comportement précédent) :
                    // Attention, cela ne marche plus car on vide les sous-dimensions au changement
                    // d'onglet actuellement, cf plus haut.
                    /*setSubDimensions((prev) => [
                        ...prev,
                        ...subDimensions,
                    ]); */
                    setSubDimensions(subDimensions);
                    setOnlyReload(false);
                    scrollToTop();
                }
            });
        }
    };

    const genSubComponent = (subDimensions, onScoreChange, getMraScores) => {
        let subComponents = subDimensions.map((subdimension) => {
            return (
                <SubdimensionContent
                    key={subdimension.id}
                    subDimension={subdimension}
                    onScoreChange={onScoreChange}
                    isNTXDimension={isNTXDimension}
                    isGROUPDimension={isGROUPDimension}
                    mraStatus={currentStatus}
                    mraScoresToEdit={getMraScores(subdimension, true)}
                    mraScoresToShow={getMraScores(subdimension, false)}
                />
            );
        });
        return subComponents;
    };

    const onNextStep = () => {
        if (onSubmit) {
            setLoadingDimension(true);
            onSubmit(nextStatus) || setLoadingDimension(false);
        }
    };

    const onReload = () => {
        setForceReload((f) => !f);
    };

    return (
        <div style={styles.container}>
            <div className="sub-dimension">
                {genSubComponent(
                    subDimensions,
                    onScoreChange,
                    getMraScoresMemo
                )}
            </div>
        <Paper style={styles.buttonsContainer} elevation={5} className="button-bar-bottom">
                <Button
                    variant="contained"
                    className="tooltip tooltip-top button-table"
                    onClick={onChangeLang}
                >
                    <span style={{height: "13px", fontSize: '22px', lineHeight: '0.6'}}>
                        {currentLang === 'en' ? <img src={UsaFlagImage} style={{width: "24px", marginTop: "-5px"}} />: <img src={FranceFlagImage} style={{width: "24px", marginTop: "-5px"}}/>}
                    </span>
                    <span className="tooltiptext" style={{fontSize: '14px'}}>Change language</span>
                </Button>
                <Link to={'/resource/mras/' + (mra && mra.id) + '/detail'}>
                    <Button
                        variant="contained"
                        color="primary"
                        className="tooltip tooltip-top"
                        disabled={!mra}
                    >
                        <Icon style={styles.buttonIcon} className="fa fa-th-large" />
                        <span className="tooltiptext">View details</span>
                    </Button>
                </Link>
                {(!mra || mra.open) && canSave && (
                    <Button
                        variant="contained"
                        color="primary"
                        className="button-table button-show-version tooltip tooltip-top"
                        style={styles.saveButton}
                        onClick={onSave}
                        disabled={loadingDimension || saving || onlyReload}
                    >
                      <Icon style={styles.buttonIcon} className={
                             loadingDimension || saving
                                ? 'fa fa-circle-notch text-primary fa-rotate fa-spin'
                                : 'fa fa-save'
                        } />
                            <span className="tooltiptext">Save</span>
                    </Button>
                )}
                {(!mra || mra.open) && canSave && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onlyReload ? onReload : onNextStep}
                      style={styles.submitButton}
                        className="button-table tooltip tooltip-top"
                        disabled={
                            loadingDimension ||
                            (!nextStatus && subDimensions.length > 0)
                        }
                    >
                      {onlyReload && <>

                        <Icon style={styles.buttonIcon} className={'fa fa-redo'}/>
                        <span className="tooltiptext">Reload</span>
                        </>}
                        {!onlyReload &&
                        <>
                            <Icon style={styles.buttonIcon} className={
                                    loadingDimension
                                        ? 'fa fa-circle-notch text-primary fa-rotate fa-spin'
                                        : 'fa fa-arrow-up'
                                } />
                            <span className="tooltiptext">{isLoD1SelfAssessment ? 'Submit self-assessment' : 'Submit to LoD2'}</span>
                        </>
                        }
                        {/* {loadingDimension && ( */}
                        {/*     <Icon className="fa fa-circle-notch text-primary fa-rotate fa-spin" /> */}
                        {/* )} */}
                    </Button>
                )}
               </Paper>
        </div>
    );
};

DimensionContent.propTypes = {
    /** */
    dimension: PropTypes.object,
    /** */
    onSave: PropTypes.func,
    onSubmit: PropTypes.func,
    addMraScore: PropTypes.func,
    getMraScores: PropTypes.func,
    /** Objet ressource Mra */
    mra: PropTypes.object,
};

let styles = {
    container: {
        marginBottom: 60,
    },
    buttonsContainer: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(255,255,255,1)',
        padding: "10px !important",
    },
    nextButton: {
        textAlign: 'right',
        position: 'fixed',
        bottom: 10,
    },
    saveButton:{
        fontSize: 15,
        backgroundColor: '#0dbbb7',
        margin:3,
    },
    submitButton: {
        fontSize: 15,
        backgroundColor: '#48A10D',
        margin: 3,
    },
    prevButton: {
        textAlign: 'right',
        position: 'fixed',
        bottom: 0,
        right: 200,
        zIndex: 10,
    },
    buttonIcon: {
        fontSize: "0.875rem"
    }
};
