import React, {Component} from "react";
import Modal from '../../Modal';
import TextField from '@material-ui/core/TextField/index';
import Button from '@material-ui/core/Button';
import '../BulkEdit/bulk-edit.css';
import Http from '../../Http';
import Alert from '../../Alert';
import FieldProviderStore from "../../APIResource/FieldProviders/__FieldProviderStore";
import FormControl from "@material-ui/core/FormControl";
import ParameterStore, {getParamByIri, userHasRoleADMIN, userHasRoleMRM} from "../../../Store/ParameterStore";
import Navigation from "../../Navigation";
import { openLoadingModal, validateIds } from "../bulkUtils";

export class NewReview {
    getId(){
        return 'bulkNewReview'
    }

    getLabel(){
        return 'New review'
    }

    /**
     * 
     * @param {import('../../APIResource/APIResource').APIResourceBulkParams} params
     */
    setParameters(params){
        this.parameters = params;
    }

    async run(ids, parent = null){
        this.ids = ids;

        const errorMessage = this.parameters.forbiddenAccessMessage || "You don't have the rights to add new reviews.";

        openLoadingModal();

        if (
            !(await validateIds(
                ids,
                this.parameters.itemAccessCondition,
                this.parameters.resource,
                this.parameters.itemAccessConditionEntityFields,
                this.parameters.bulkValidateRoles
            ))
        ) {
            Modal.close();
            Alert.show({ message: errorMessage });
            return;
        }

        Modal.open({
            title: this.getLabel(),
            content: <NewReviewForm
                fields={this.parameters.fields}
                ids={this.ids}
                parent={parent}
                resource={this.parameters.resource}
            />,
            modalStyle: { width: 520 },
        })
    }
}

export class NewReviewForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            fields: {},
            changeLogComment: ''
        }

        this.changedFields = {};
    }

    handleChange(fieldId, value){
        if(fieldId == 'changeLogComment') {
            this.setState({changeLogComment: value});
        } else {
            let fields = this.state.fields;
            fields[fieldId] = value;
            //this.setState({fields: fields});
        }
        if (value === '' || value === null || (Array.isArray(value) && !value.length)) {
            if (userHasRoleMRM()) {
                this.changedFields[fieldId] = value;
            } else {
                delete this.changedFields[fieldId];
            }
        }
        else {
            this.changedFields[fieldId] = value;
        }
    }

    save(){

        for(let i in this.props.fields){
            if(
                this.state.fields[this.props.fields[i].id] === undefined
                && this.props.fields[i].required !== undefined
                && this.props.fields[i].required === true
            ){
                Alert.show({
                    message: 'Please fill all the required fields',
                    type: 'success',
                });
                return;
            }
        }

        if(this.state.reviewCommittee !== undefined){
            if(!Array.isArray(this.changedFields['reviewCommittees'])){
                this.changedFields['reviewCommittees'] = [];
            }
            this.changedFields['reviewCommittees'].push(this.state.reviewCommittee);
        }

        Http.post('models/bulk/new-review', {
            ids: this.props.ids,
            fields: this.changedFields,
        }).then((response) => {
            this.props.resource.apiGetCollection({forceReload: true}).then(() => this.props.parent.setState({selection: []}));
            Alert.show({message: response.message || 'Bulk Edit successfully processed.'});
            Modal.close();
            if(response.reviewId){
                Navigation.router.history.push(
                    '/resource/reviews/' + response.reviewId + '/detail'
                );
            }

        });

    }

    genForm(){
        let fields = [];
        for(let i in this.props.fields){
            let field = this.props.fields[i];
            const fieldId = i;
            let component = null;
            field.issueButton = false;
            field.required = true;
            field.requiredComputed = true;

            if(
                (
                    userHasRoleMRM()
                    || userHasRoleADMIN()
                )
                && (
                    fieldId === 'plannedStartingDate'
                    || fieldId === 'plannedClosingDate'
                )
            ){
                field.required = false;
                field.requiredComputed = false;
            }

            if(field.edit){
                component = field.edit(field, this.state.fields[fieldId], (value) => {this.handleChange(fieldId, value)}, this.state.fields, {});
            }
            else if(FieldProviderStore[field.type] && FieldProviderStore[field.type].getEdit){
                component = FieldProviderStore[field.type].getEdit(field, this.state.fields[fieldId], (value) => {this.handleChange(fieldId, value)}, this.state.fields, {});
            }
            else if(!field.component){
                if(this.state.fields[i] == undefined){
                    this.state.fields[i] = '';
                }
                component =
                    <div key={i}>
                        <FormControl>
                            <TextField
                                id={'field-' + i}
                                label={field.title}
                                className={'field-' + i}
                                onChange={(event) => {this.handleChange(i, event.target.value)}}
                                value={this.state.fields[i]}
                                required={field.required}
                            />
                        </FormControl>
                    </div>
                ;
            }
            else{
                component = field.component((value) => {this.handleChange(i, value)});
            }
            fields.push(<div className="field" key={i}>{component}</div>);
        }

        if(userHasRoleMRM()){
            let reviewCommittee = {
                title: 'Choose a committee',
                type: 'entity',
                params: {
                    multi: false,
                    resource: 'review_committees',
                    displayField: 'displayString',
                    filters: (committee) => {
                        return committee.type === ParameterStore('REVIEW_COMMITTEE_TYPE_COMMITTEE');
                    }
                },
                issueButton: false,
                required: false,
                disabled: true
            };

            fields.push(<div className="field" key={'field-reviewCommittee'}>{
                FieldProviderStore[reviewCommittee.type].getEdit(
                    reviewCommittee,
                    this.state.reviewCommittee,
                    (value) => {
                        this.setState({reviewCommittee: value})
                    },
                    this.state.fields,
                    {}
                )
            }</div>);
        }

        return(
            <div className="bulk-edit bulk-new-review">
                <div className={"alert alert-info"} role="alert">
                    You are about to create a new review with all the selected models.
                </div>

                <div className="fields main-fields"> {/* grand padding pour éviter que la dernière entity select ne soit invisible */}
                    {fields}
                </div>

                <div className="fields">
                    <Button variant="contained" color="secondary" className="save" onClick={this.save.bind(this)}>
                        Create the review
                    </Button>
                </div>

            </div>
        );
    }

    render(){
        return(this.genForm());
    }
}