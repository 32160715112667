import React from 'react';
import { APIResource } from '../Services/APIResource/APIResource';
import {
    PARAMETER_TYPE_ITSYSTEM_TYPE,
} from "./ParameterAdmin";
import {TableDisplay} from "../Components/Display/TableDisplay/TableDisplay";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import { BulkVerify } from '../Services/BulkActions/BulkVerify/BulkVerify';


export const IT_SYSTEM_FIELDS = {
    id: { 
        title: 'ID', 
        helperTextEdit: 'System IT ID defined by MRM tool',
    },
    name: {
        title: 'Name',
        type: 'text',
        helperTextEdit: 'Name of the IT System supporting models',
    },
    type: {
        title: 'Type',
        type: 'parameter',
        helperTextEdit: 'Type of IT system'
        + '\nUsed to differenciate Rating systems and others platforms',
        params: {
            type: PARAMETER_TYPE_ITSYSTEM_TYPE,
            multi: false
        },
    },
    module: {
        title: 'Module',
        type: 'textarea',
    },
    verified: { title: "Verified", type: "bool" },
    releaseNotesEntities:{
        title: 'Versions',
        display: (field, value, entity, props) => (
            <TableDisplay
                rows={value}
                cols={[
                    { label: "Version number", field: "versionNumber" },
                    { label: "Production launch date", field: "productionLaunchDateString" },
                    { label: "Description", field: "description" },
                ]}
                actions={(value) => {
                    return (
                        <div>
                            <Link
                                to={
                                    "/resource/system_versions/" +
                                    value.id +
                                    "/detail"
                                }
                                style={styles.actionLink}
                            >
                                <Button
                                    style={styles.mrDetailBtn}
                                    variant="contained"
                                    color="primary"
                                    className="button-table button-show-version"
                                >
                                    Detail
                                </Button>
                            </Link>
                            <Link
                                to={
                                    "/resource/system_versions/" +
                                    value.id +
                                    "/edit"
                                }
                                style={styles.actionLink}
                            >
                                <Button
                                    style={styles.mrDetailBtn}
                                    variant="contained"
                                    color="primary"
                                    className="button-revert button-table"
                                >
                                    Edit
                                </Button>
                            </Link>
                        </div>
                    );
                }}
                buttons={[
                    {
                        label: "New version",
                        to: "/resource/system_versions/add/" + entity.id,
                        icon: "fa-plus",
                    },
                ]}
            />
        ),
        displayList: () => null,
    },
    implementationsEntities: {
        title: "Implementations",
        display: (field, value, entity, props) => (
            <TableDisplay
                rows={value && value.filter(v => !v.modelDeleted)}
                cols={[
                    {
                        label: "Functional ID",
                        field: "functionalID",
                        display: (field, value, entity, props) => {
                            let models = value;
                            let component = [];
                            if(Array.isArray(models) && models.length > 0){
                                models.forEach((model) => {
                                    component.push(
                                        <Link
                                            to={
                                                '/resource/models/' +
                                                model.id +
                                                '/detail'
                                            }
                                            target="_blank"
                                        >
                                            <div className="link">
                                                <b>{model.functionalID}</b>
                                            </div>
                                        </Link>
                                    );
                                })
                                return component;
                            }
                            return '';
                        }
                    },
                    { label: "Date", field: "dateString" },
                    {
                        label: "Model implementer team",
                        field: "modelImplementerTeam",
                    },
                    { label: "IT System", field: "ITSystem" },
                    { label: "System Version", field: "systemVersion" },
                ]}
                actions={(value) => {
                    return (
                        <div>
                            <Link
                                to={
                                    "/resource/implementations/" +
                                    value.id +
                                    "/detail"
                                }
                                style={styles.actionLink}
                            >
                                <Button
                                    style={styles.mrDetailBtn}
                                    variant="contained"
                                    color="primary"
                                    className="button-table button-show-version"
                                >
                                    Detail
                                </Button>
                            </Link>
                            <Link
                                to={
                                    "/resource/implementations/" +
                                    value.id +
                                    "/edit"
                                }
                                style={styles.actionLink}
                            >
                                <Button
                                    style={styles.mrDetailBtn}
                                    variant="contained"
                                    color="primary"
                                    className="button-revert button-table"
                                >
                                    Edit
                                </Button>
                            </Link>
                        </div>
                    );
                }}
            />
        ),
        displayList: () => null,
    },
};

export const ApiResourceDefaultParams = {
    id: 'i_t_systems',
    instanceId: 'i_t_systems',
    name: 'IT System',
    icon: 'fa-code-branch',
    fieldForTitle: 'name',
};

export default class ITSystemAdmin {
    constructor() {
        this.configure();
    }

    configure() {
        let ITSystems = new APIResource(ApiResourceDefaultParams);
        ITSystems
            .setFields(IT_SYSTEM_FIELDS)
            .setLayout({
                tabs: {
                    Properties: {
                        rows: [
                            {
                                panels: {
                                    Identification: {
                                        cols: 6,
                                        fields: [
                                            'id',
                                            'name',
                                            'type',
                                            'module',
                                            'verified',
                                        ],
                                    },
                                },
                            },
                        ],
                    },
                    Versions: {
                        rows: [
                            {
                                panels: {
                                    Versions: {
                                        cols: 12,
                                        fields: [
                                            'releaseNotesEntities',
                                        ],
                                    },
                                },
                            },
                        ],
                    },
                    Implementations: {
                        rows: [
                            {
                                panels: {
                                    Implementations: {
                                        cols: 12,
                                        fields: [
                                            'implementationsEntities',
                                        ],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .genListView({
                menuItem: { title: 'System Versions' },
                fields: [
                    'id',
                    'name',
                    'type',
                    'module',
                    'verified',
                ]
            })
            .genInsertView({
                menuItem: { title: 'Add' },
                fields: [
                    'name',
                    'type',
                    'module',
                    'verified',
                ]
            })
            .genEditView({
                fields: [
                    'name',
                    'type',
                    'module',
                    'verified',
                ]
            })
            .genDetailView()
            .allowDelete()
            .addBulkAction(BulkVerify, {
                resource: ITSystems,
                icon: 'check',
                resourceId: 'i_t_systems',
            });;
    }
}

const styles = {
    mrDetailBtn: {
        margin: 1,
    },
    actionLink: {
        marginLeft: 5,
    },
};