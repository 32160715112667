import React, { Component } from 'react';
import Slider from '@material-ui/core/Slider';

export class ScorePicker extends Component {
    constructor(props) {
        super(props);

        this.disabled = props.disabled || false;
        this.updateValue = this.updateValue.bind(this);

        this.max = props.max ? props.max : 4
        const marks = [
            {
                value: 0,
                label: 'N/A'
            }
        ]
        for (let i = 1; i <= this.max; i++) {
            marks.push({
                value: i,
                label: i.toString()
            })
        }
        this.marks = marks
        this.state = {
            value: props.initValue || 0,
        };
    }

    updateValue(value) {
        this.setState({ value: value });
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        return (
            <div>
                <Slider
                    disabled={this.disabled}
                    defaultValue={0}
                    onChange={(e, value) => this.updateValue(value)}
                    className="slider-score-mra"
                    value={this.state.value}
                    step={1}
                    min={0}
                    max={this.max}
                    marks={this.marks}
                />
            </div>
        );
    }
}
