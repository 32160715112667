import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import React, {Component} from "react";
import {observer} from "mobx-react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import {Check} from "@material-ui/icons";
import * as PropTypes from "prop-types";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import ParameterStore from "../../Store/ParameterStore";
import {
    REVIEW_STATUS_ASSESSMENT, REVIEW_STATUS_ASSESSMENT_AWAITING,
    REVIEW_STATUS_CLOSED, REVIEW_STATUS_CLOSED_AWAITING,
    REVIEW_STATUS_FINALIZATION, REVIEW_STATUS_FINALIZATION_AWAITING,
    REVIEW_STATUS_PLANNING, REVIEW_STATUS_PLANNING_AWAITING,
    REVIEW_STATUS_SIGN_OFF, REVIEW_STATUS_SIGN_OFF_AWAITING,
} from "../../Admin/ReviewAdmin";

const STEP_OFF = 0;
const STEP_ON = 1;
const STEP_HOURGLASS = 2;
const STEP_COMPLETE = 3;

export const ReviewStep = observer(class ReviewStep extends Component {
    entity;

    constructor(props) {
        super(props);

        this.entity = this.props.entity;
        this.state = {
            validatedStep: null
        }
        this.steps = [
            {
                label: 'Request',
                key: 0,
            },
            {
                label: 'Planning',
                key: 1,
            },
            {
                label: 'Assessment',
                key: 2,
            },
            {
                label: 'Sign-Off',
                key: 3,
            },
            {
                label: 'Finalization',
                key: 4,
            },
        ];
    }

    componentDidMount() {
        this.entity = this.props.entity;
        this.getValidatedStep();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.entity !== prevProps.entity)) {
            this.entity = this.props.entity;
            this.getValidatedStep();
        }
    }

    getValidatedStep() {
        let entity = this.entity;

        if (entity === null || typeof entity === "undefined") {
            this.setState({validatedStep: null});
            return;
        }

        let validatedStep = null;
        if (entity.request && !entity.reviewStatus) {
            validatedStep = 0;
        } else if (
            entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING)
            || entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING_AWAITING)
        ) {
            validatedStep = 1;
        } else if (
            entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT)
            || entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING)
        ) {
            validatedStep = 2;
        } else if (
            entity.reviewStatus === ParameterStore(REVIEW_STATUS_SIGN_OFF)
            || entity.reviewStatus === ParameterStore(REVIEW_STATUS_SIGN_OFF_AWAITING)
        ) {
            validatedStep = 3;
        } else if (
            entity.reviewStatus === ParameterStore(REVIEW_STATUS_FINALIZATION)
            || entity.reviewStatus === ParameterStore(REVIEW_STATUS_FINALIZATION_AWAITING)
        ) {
            validatedStep = 4;
        }

        this.setState({validatedStep});
    }

    isCompletedStep(stepId, entity) {
        return stepId === 0 || stepId < this.state.validatedStep;
    }

    getStatus(stepId, entity){

        if(stepId < this.state.validatedStep){
            return STEP_COMPLETE;
        }

        if(stepId === 0){//Request
            return STEP_HOURGLASS;
        }else if(stepId === 1){//Planning
            if(entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING)){
                return STEP_ON;
            }else if(entity.reviewStatus === ParameterStore(REVIEW_STATUS_PLANNING_AWAITING)){
                return STEP_HOURGLASS;
            }
        }else if(stepId === 2){//Assessment
            if(entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT)){
                return STEP_ON;
            }else if(entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING)){
                return STEP_HOURGLASS;
            }
        }else if(stepId === 3){//Sign-off
            if(entity.reviewStatus === ParameterStore(REVIEW_STATUS_SIGN_OFF)){
                return STEP_ON;
            }else if(entity.reviewStatus === ParameterStore(REVIEW_STATUS_SIGN_OFF_AWAITING)){
                return STEP_HOURGLASS;
            }
        }else if(stepId === 4){//Finalization
            if(entity.reviewStatus === ParameterStore(REVIEW_STATUS_FINALIZATION)){
                return STEP_ON;
            }else if(entity.reviewStatus === ParameterStore(REVIEW_STATUS_FINALIZATION_AWAITING)){
                return STEP_HOURGLASS;
            }
        }

        return STEP_OFF;
    }

    renderStep(){
        let steps = [];
        this.steps.forEach((step) => {
            steps.push(<Step key={step.key}>
                <StepLabel
                    StepIconComponent={ReviewStepIcon}
                    StepIconProps={{status: this.getStatus(step.key, this.entity)}}
                    active={step.key === this.state.validatedStep}
                    completed={this.isCompletedStep(step.key, this.entity)}
                >
                    {step.label}
                </StepLabel>
            </Step>)
        });
        return steps;
    }

    render() {
        return (
            <div>
                { this.state.validatedStep !== null ?
                    <Stepper activeStep={this.state.validatedStep}>
                        {this.renderStep()}
                    </Stepper>
                : null }
            </div>
        );
    }

});

const useReviewStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#ccc',
    },
    circle: {
        width: 15,
        height: 15,
        color: 'white',
        borderRadius: '50%',
        backgroundColor: '#ccc',
        fontSize: 12,
        textAlign: 'center'
    },
    circleColor: {
        width: 15,
        height: 15,
        color: 'white',
        borderRadius: '50%',
        backgroundColor: 'purple',
        fontSize: 12,
        textAlign: 'center'
    },
    completed: {
        color: 'green',
        zIndex: 1,
        fontSize: 18,
    },
});

function ReviewStepIcon(props) {
    const classes = useReviewStepIconStyles();
    const { active, status, icon } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {status === STEP_OFF ? <div className={classes.circle}>{icon}</div> : null}
            {status === STEP_ON ? <div className={classes.circleColor}>{icon}</div> : null}
            {status === STEP_HOURGLASS ? <HourglassEmptyIcon /> : null}
            {status === STEP_COMPLETE ? <Check className={classes.completed} /> : null}
        </div>
    );
}

ReviewStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
};
