import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DocumentAddForm, DocumentManager, TYPE_FILE } from '../../Display/DocumentManager/DocumentManager';
import { APIResource } from '../../../Services/APIResource/APIResource';
import { Button, Paper } from '@material-ui/core';
import { genEditComponent, isFieldRequired } from '../../../Services/APIResource/Components/ResourceEdit/ResourceEdit';

/**
 * Wrapper pour faire des formulaires simples autour d'une entité
 *
 */
export const EntityForm = (props) => {
    const { entity, fields, onUpdate } = props;

    /**
     * @typedef {Object} Change
     * @property {*} change.field - Nom du champ à modifier
     * @property {*} change.value - Valeur du champ à modifier
     *
     * @param {[Change]} listOfChanges
     * @param {function} [callback]
     */
    const handleChange = (listOfChanges, callback) => {
        const updateFromPrevious = (p) => {
            let e = { ...p };
            listOfChanges.forEach((c) => {
                e[c.field] = c.value;
            });
            return e;
        };
        // Si onUpdate est un hook, alors il n'accepte pas de callback mais cela ne pose pas de problème
        onUpdate(updateFromPrevious, callback);
    };

    return (
        <>
            {Object.entries(fields).map(([fieldKey, field]) => (
                <EntityFormItem
                    key={fieldKey}
                    entity={entity}
                    field={field}
                    fieldKey={fieldKey}
                    handleChange={handleChange}
                />
            ))}
        </>
    );
};

EntityForm.propTypes = {
    entity: PropTypes.object,
    fields: PropTypes.object,
    /** Appelé lors de la mise à jour de l'entity. Utilise le "previousState" callback, donc passez un setState ou un hook useState set */
    onUpdate: PropTypes.func,
};

/**
 * @todo pour l'instant displayCondition n'accepte qu'une fonction pas un "predicate" comme dans apiresource
 */
export const EntityFormItem = (props) => {
    const { entity, fieldKey, handleChange } = props;
    const field = {...props.field, id: fieldKey, requiredComputed: isFieldRequired(props.field, entity, fieldKey)};

    const [showDocumentForm, setShowDocumentForm] = useState(false);

    const showItem = 'displayCondition' in field ? field.displayCondition(entity, entity[fieldKey]) : true;

    const documentResource = useMemo(() => new APIResource({ id: 'documents' }), []);

    /**
     * Attention, cette partie est récupérée de DocumentForm : il faudrait pouvoir utiliser la sienne
     * plutôt que de la réécrire ici.
     * @todo
     *
     * @param {*} document
     * @param {*} callback
     */
    const saveDocument = (document) => {
        let category = field.params.forceCategory && field.params.forceCategory();
        return documentResource
            .apiPostFile({
                title: document.title,
                type: document.type,
                category: category || document.category,
                file: document.documentFile,
                externalUrl: document.externalUrl,
                description: document.description,
                comment: document.comment,
                entity: {
                    id: entity.id,
                    '@type': entity['@type'],
                },
                fieldName: field.params.fieldName || 'Document',
            })
    };

    const onSave = async (formState, callback = null) => {
        /**
         * DocumentForm fonctionne avec 2 champs : propertyName et fieldName.
         * PropertyName concerne le champ "xxEntities", et fieldName le champ qui est réellement enregistré en base.
         * Ici on doit donc ajouter le résultat aux "Entities" pour l'affichage, mais surtout
         * ajouter le résultat au "fieldName", sinon le document sera désappairé de l'entity.
         */
        let valueProperty = entity[field.params.propertyName] || [];
        /**
         * params.fieldName est de la forme "Document" (pour générer : add"fieldName" en back), donc on doit
         * le transformer en champ de l'entité => ex : "documents"
         */
        const fieldName = field.params.fieldName.toLowerCase() + 's';
        let valueField = entity[fieldName] || [];

        if (formState.type === TYPE_FILE) {
            if (!formState.documents?.length) return;
            const responses = await Promise.all(
                formState.documents.map((d) =>
                    saveDocument({ ...formState, documentFile: d.documentFile, title: d.title })
                )
            );
            responses.map((response) => {
                valueProperty.push(response);
                valueField.push(response['@id']);
            });
        } else {
            const response = await saveDocument({ ...formState, documentFile: formState.documents });
            valueProperty.push(response);
            valueField.push(response['@id']);
        }
        // Mise à jour du parent
        handleChange([
            { field: fieldKey, value: valueProperty },
            { field: fieldName, value: valueField },
        ]);
        setShowDocumentForm(false);
        if (callback) callback();
    };

    return (
        <div className={'entity-form'}>
            {showItem &&
                field.type !== 'documents' &&
                genEditComponent(
                    field,
                    entity[fieldKey],
                    (value) => {
                        handleChange([{ field: fieldKey, value }]);
                    },
                    entity
                )}

            {showItem && field.type === 'documents' && (
                <Paper style={styles.documentForm}>
                    <h2 className="background-linear-gradient" style={{color: 'white'}}>{field.title}</h2>
                    {entity[field.params.propertyName] && (
                        <DocumentManager
                            values={entity[field.params.propertyName]}
                            entity={entity}
                            fieldName={field.params.fieldName}
                            propertyName={field.params.propertyName}
                            allowedAction={field.params.allowedAction}
                            onUpdate={(entity) => {
                                // cf au dessus pour fieldName
                                let fieldName = field.params.fieldName.toLowerCase() + 's';
                                return handleChange([
                                    {
                                        field: fieldKey,
                                        value: entity[fieldKey],
                                    },
                                    {
                                        field: fieldName,
                                        value: entity[fieldName],
                                    },
                                ]);
                            }}
                        />
                    )}
                    {!showDocumentForm && (
                        <Button
                            onClick={() => setShowDocumentForm(true)}
                            color={'primary'}
                            variant="contained"
                            style={styles.buttonAdd}
                        >
                            Add document
                        </Button>
                    )}
                    {showDocumentForm && (
                        <DocumentAddForm
                            fileTypes={field.params.fileTypes || null}
                            onSave={(document, callback) => onSave(document, callback)}
                            allowedTypes={field.params.allowedTypes || null}
                            allowedCategory={field.params.allowedCategory || false}
                            // AddForm should always have "add" action ?!
                            allowedAction={(e, d, action) =>
                                action === 'add' || field.params.allowedAction(e, d, action)
                            }
                            entity={entity}
                            saveButtonLabel={field.params.saveButtonLabel}
                            saveButtonColor="primary"
                        />
                    )}
                </Paper>
            )}
        </div>
    );
};
EntityFormItem.propTypes = {
    entity: PropTypes.object,
    field: PropTypes.object,
    fieldKey: PropTypes.string,
    handleChange: PropTypes.func,
};

const styles = {
    documentForm: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonAdd: {
        alignSelf: 'center',
        marginBottom: '20px',
        marginTop: '20px',
    },
};
