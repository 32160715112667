import {decorate, observable, intercept} from "mobx/lib/mobx";

class AppBarStore{
    constructor(){
        this.reset();
    }
    reset(){
        this.title = 'MRM SYSTEM';
    }
}
decorate(AppBarStore, {
    title: observable,
    appBarStoreTitleClass: observable
});


const appBarStore = new AppBarStore();
/*
Désactivé suite à la mise à jour breadcrumb, le title contient maintenant un objet react qui n'est pas compatible avec l'écriture ci-dessous

intercept(appBarStore, "title", change => {
    document.title = (change.newValue ? change.newValue + ' • ' : '') + 'BPCE Model Risk Management';;
    return change;
});
 */
export default appBarStore;