import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core';
/*
Assets
 */
// import dashboardImage from "../../Styles/Assets/img/dashboard.jpg";
import dashboardImage from '../../Styles/Assets/img/dashboard-2.jpg';
import inventoryImage from '../../Styles/Assets/img/models-inventory.jpg';
import reviewsImage from '../../Styles/Assets/img/reviews.jpg';
import certificationImage from '../../Styles/Assets/img/certification.jpg';
import issuesImage from '../../Styles/Assets/img/issues.jpg';
import planningImage from '../../Styles/Assets/img/planning.jpg';
import issuesTrackingToolImage from '../../Styles/Assets/img/issue-tracking.jpg';
import parametersImage from '../../Styles/Assets/img/tool-management.jpg';
import AppBarStore from '../../Store/AppBarStore';
import { userHasRoleMRM } from '../../Store/ParameterStore';
import User from "../../Services/User/User";

class HomeScreen extends Component {
    constructor(props) {
        super(props);
        AppBarStore.reset();
    }

    render() {
        return (
            <div className="container">
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Link to="/dashboard">
                            <Card className="homeCard">
                                <CardActionArea>
                                    <CardMedia image={dashboardImage} className="homeCardMedia" />
                                    <CardContent className="homeCardContent">
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="h2"
                                            bgcolor="primary.main"
                                            className="homeCardTitle"
                                        >
                                            Dashboard
                                        </Typography>
                                        <Typography variant="body2" component="p">
                                            Get a summary of your pending actions and create reports and analyses.
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={4}>
                        <Link to="/resource/models/list">
                            <Card className="homeCard">
                                <CardActionArea>
                                    <CardMedia image={inventoryImage} className="homeCardMedia" />
                                    <CardContent className="homeCardContent">
                                        <Typography gutterBottom variant="h5" component="h2" className="homeCardTitle">
                                            Inventory
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            This section gives you access to the BPCE Group models database. <br />
                                            Manage your perimeter or get information about any model.
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={4}>
                        <Link
                            to={
                                userHasRoleMRM()
                                    ? '/resource/model_certification_campaigns/list'
                                    : '/resource/my_model_certification_campaigns/list'
                            }
                        >
                            <Card className="homeCard">
                                <CardActionArea>
                                    <CardMedia
                                        image={certificationImage}
                                        title="Certification"
                                        className="homeCardMedia"
                                    />
                                    <CardContent className="homeCardContent">
                                        <Typography gutterBottom variant="h5" component="h2" className="homeCardTitle">
                                            Certification
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            Once a year, Model Owners are invited to ensure that all the models are
                                            registered in the inventory and the information is up to date.
                                            <br />
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={4}>
                        <Link to="/resource/ongoing_reviews/list">
                            <Card className="homeCard">
                                <CardActionArea>
                                    <CardMedia image={reviewsImage} className="homeCardMedia" />
                                    <CardContent className="homeCardContent">
                                        <Typography gutterBottom variant="h5" component="h2" className="homeCardTitle">
                                            Reviews
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            Consult and take part in the ongoing validation reviews on your perimeter.
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={4}>
                        <Link to="/resource/notices/list">
                            <Card className="homeCard">
                                <CardActionArea>
                                    <CardMedia image={issuesImage} className="homeCardMedia" />
                                    <CardContent className="homeCardContent">
                                        <Typography gutterBottom variant="h5" component="h2" className="homeCardTitle">
                                            Notices
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            You will find here an overview of all the notices related to the models of
                                            your perimeter issued by Validation teams.
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={4}>
                        <Link to={userHasRoleMRM() ? '/resource/open_troubles/list' : '/resource/my_troubles/list'}>
                            <Card className="homeCard">
                                <CardActionArea>
                                    <CardMedia image={issuesTrackingToolImage} className="homeCardMedia" />
                                    <CardContent className="homeCardContent">
                                        <Typography gutterBottom variant="h5" component="h2" className="homeCardTitle">
                                            Troubles
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            Get guidance regarding any trouble related to Model Risk Management (model
                                            ownership, inventory update, certification...)
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Link>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default HomeScreen;
