import React, { Component } from "react";
import Modal from "../../Services/Modal";
import {DetailButton} from "../Buttons/DetailButton";
import {EditButton} from "../Buttons/EditButton";
import {
    ResourceDetail
} from "../../Services/APIResource/Components/ResourceDetail/ResourceDetail";
import {ResourceEdit} from "../../Services/APIResource/Components/ResourceEdit/ResourceEdit";
import {APIResource, CONTEXT_ADD, CONTEXT_DETAIL, CONTEXT_EDIT, CONTEXT_CUSTOM} from "../../Services/APIResource/APIResource";
import APIResourceStore from "../../Store/APIResourceStore";
import Button from "@material-ui/core/Button";

const ModalResourceEdit = ({ modal, resource, parentResource }) => {
    return (
        <ResourceEdit
            inModal={true}
            openModalComponent={modal}
            resource={resource}
            context={modal.props.context}
            match={{ params: { id: modal.props.id, ...modal.props.routeParams } }}
            postSaveRedirect={() => {
                if (parentResource) {
                    parentResource.apiGetOne(modal.props.parentId, true).then((r) => Modal.close());
                } else {
                    Modal.close();
                }
                return modal.props.postSaveRedirectUrl;
            }}
        />
    );
};

const ModalResourceDetail = ({ modal, resource, objectId}) => {
    return (
        <ResourceDetail
            inModal={true}
            openModalComponent={modal}
            resource={resource}
            objectId={objectId}
            key={'resource_detail_modal'}
        />
    );
};

const ModalChildrenRender = ({ modal, resource, parentResource, objectId }) => {
    if (modal.props.context === CONTEXT_ADD || modal.props.context === CONTEXT_EDIT) {
        return <ModalResourceEdit modal={modal} resource={resource} parentResource={parentResource} />;
    } else if (modal.props.context === CONTEXT_CUSTOM) {
        return modal.props.customInnerView;
    } else {
        return <ModalResourceDetail modal={modal} resource={resource} objectId={objectId} />;
    }
};

const ModalInnerView = ({ modal, resource, parentResource, objectId }) => {
    return (
        <div id={'open-modal-container'}>
            <ModalChildrenRender
                modal={modal}
                resource={resource}
                parentResource={parentResource}
                objectId={objectId}
            />
        </div>
    );
};


export class OpenModal extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        //By default all object should be preload ; If ResourceStore knows objet it will be return immediately, if don't, forceReload is used to get objet from API
        if(this.props.instanceId){
            APIResourceStore.resources[this.props.instanceId].getObservableItem(this.props.id);
        }else if(!this.props.customInnerView){
            console.warn("Missing instanceId or customInnerView while using the OpenModal component", this.props)
        }
    }

    openModal() {
        let self = this;
        let parentResource = null;
        if (self.props.parentInstanceId && (self.props.context === CONTEXT_ADD || self.props.context === CONTEXT_EDIT)){
            parentResource = new APIResource({
                instanceId: self.props.parentInstanceId
            });
        }
        let resource = new APIResource({
            instanceId: self.props.instanceId,
            context: this.props.context
        });

        let title = self.props.modalTitle
        if(resource && resource.name){
            title = resource.name + ': ' + title
        }
        
        Modal.open({
            title,
            content: <ModalInnerView modal={self} resource={resource} parentResource={parentResource} objectId={this.props.id} />,
            contentStyle: { padding: 0 },
            closeOnRouteChange: true,
            allowFullScreen: true
        });
    }

    render() {
        let self = this;

        //console.log('render openModal', self.props)

        let style = {};
        if(this.props.inline){
            style = {display: "inline-flex"};
        }
        
        let button = null;
        if(self.props.flat){
            button = this.props.modalTitle;
        }else if(self.props.button){
            button = self.props.button;
        }else if(self.props.context === CONTEXT_DETAIL){
            button = self.props.withIcon ?
                <Button variant="contained" className="primary ">
                    <i className="fa fa-th-large"></i>
                </Button>
                : <DetailButton />;
        }else if(
            self.props.context === CONTEXT_ADD
            || self.props.context === CONTEXT_EDIT
        ){
            button = self.props.withIcon ?
                <Button variant="contained" className="button-edit warning tooltip">
                    <i className="fa fa-edit"></i>
                    <span className="tooltiptext">Edit</span>
                </Button>
                : <EditButton />
            ;
        }
        return (
            <div className={`open-modal context-${self.props.context} ${self.props.containerClassName}`} style={style}>
                <span onClick={() => this.openModal()} >
                    {button}
                </span>
            </div>
        );
    }
}
