import React, {Component} from "react";
import Modal from "../../../Services/Modal";
import Alert from "../../../Services/Alert";
import Navigation from "../../../Services/Navigation";
import FormControl from "@material-ui/core/FormControl";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {Button, CircularProgress, Paper, Typography} from "@material-ui/core";
import ParameterStore from '../../../Store/ParameterStore';
import { ButtonBar } from "../../Modal/ButtonBar";
import { ActionButton } from "../../Modal/ActionButton";
import { ModalContent } from "../../Modal/ModalContent";
import {DatePicker} from "../DatePicker/DatePicker";
import {DocumentAddForm, DocumentManager} from "../../Display/DocumentManager/DocumentManager";
import {EntityForm} from "../EntityForm/EntityForm";
import Http from "../../../Services/Http";
import APIResourceStore from "../../../Store/APIResourceStore";

export class NoticeActionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: null,
            progress: false,
        };

        this.bulk = !!this.props.ids;

        this.notice = null;
        this.noticeFields = {
            effectiveClosingDate: {
                title: 'Closing date',
                type: 'date',
                bulk: true,
                helperText: 'Closing date of the notice following LoD 2 validation',
                required: true
            },
            closingComment: {
                title: 'Closing comment',
                type: 'textarea',
                helperText: 'Comment following the validation of the notice closing by LoD 2',
            },
            documentsEntities: {
                title:
                    "Please attach documents to justify the delivery.",
                type: "documents",
                params: {
                    entityResource: "notices",
                    propertyName: "documentsEntities",
                    fieldName: "Document",
                    allowedCategory: false,
                    links: false,
                    saveButtonLabel: "Attach",
                    allowedAction: (entity, document, action) =>
                        ["show", "delete"].includes(action),
                },
                // eslint-disable-next-line react/display-name
                display: (field, value, entity) => (
                    <DocumentManager
                        values={value}
                        entity={entity}
                        entityResource={"notices"}
                        fieldName={"Document"}
                        propertyName={"documentsEntities"}
                        allowedAction={(entity, document, action) =>
                            ["show", "delete"].includes(action)
                        }
                    />
                ),
            },
            requestComment: {
                title: 'Delivery comment',
                type: 'textarea',
            },
        }
        if(this.props.action === 'close'){
            this.noticeFields = {
                effectiveClosingDate: {
                    title: 'Closing date',
                    type: 'date',
                    bulk: true,
                    helperText: 'Closing date of the notice following LoD 2 validation',
                    params: {
                        minDate: !this.bulk ? this.props.entity.deliveryDate : null
                    }
                },
                closingComment: {
                    title: 'Closing comment',
                    type: 'textarea',
                    helperText: 'Comment following the validation of the notice closing by LoD 2',
                    required: true
                },
                documentsEntities: {
                    title:
                        "Please attach documents to justify the delivery.",
                    type: "documents",
                    params: {
                        entityResource: "notices",
                        propertyName: "documentsEntities",
                        fieldName: "Document",
                        allowedCategory: false,
                        links: false,
                        saveButtonLabel: "Attach",
                        allowedAction: (entity, document, action) =>
                            ["show", "delete"].includes(action),
                    },
                    // eslint-disable-next-line react/display-name
                    display: (field, value, entity) => (
                        <DocumentManager
                            values={value}
                            entity={entity}
                            entityResource={"notices"}
                            fieldName={"Document"}
                            propertyName={"documentsEntities"}
                            allowedAction={(entity, document, action) =>
                                ["show", "delete"].includes(action)
                            }
                        />
                    ),
                },
            }
        }else if(this.props.action === 'dismiss'){
            this.noticeFields = {
                closingComment: {
                    title: 'Closing comment',
                    type: 'textarea',
                    helperText: 'Comment following the validation of the notice closing by LoD 2',
                    required: true
                },
            }
        }else if(this.props.action === 'moreInfo'){
            this.noticeFields = {
                requestComment: {
                    title: 'Delivery comment',
                    type: 'textarea',
                    required: true
                },
            }
        }
    }

    /**
     * @returns 
     */
    handleClick() {

        if (this.props.action === 'close') {
            if(!this.notice.effectiveClosingDate){
                Alert.show({
                    message: "The closing date is required.",
                    type: "danger"
                });
                return;
            }
            if(!this.notice.closingComment){
                Alert.show({
                    message: "The closing comment is required.",
                    type: "danger"
                });
                return;
            }
            if(
                !this.notice.documentsEntities
                || (
                    Array.isArray(this.notice.documentsEntities)
                    && this.notice.documentsEntities.length === 0
                )
            ){
                Alert.show({
                    message: "Please attach a supporting document in the documents tab in order to close the notice.",
                    type: "danger"
                });
                return;
            }

        }else if(
            (this.props.action === 'dismiss' && this.notice.closingComment === null)
            || (this.props.action === 'moreInfo' && this.notice.requestComment === null)
        ){
            Alert.show({
                message: "The comment is required.",
                type: "danger"
            });
            return;
        }

        this.setState({progress: true});

        let currentStatus = this.notice.status;

        if(this.props.action === 'close'){
            this.notice.status = ParameterStore('NOTICE_STATUS_CLOSED');
        }else if(this.props.action === 'dismiss'){
            this.notice.status = ParameterStore('NOTICE_STATUS_DISMISSED');
        }else if(this.props.action === 'moreInfo'){
            this.notice.status = ParameterStore('NOTICE_STATUS_IN_PROGRESS');
        }

        let promise = null;
        if(!this.bulk){
            promise = this.props.resource.apiPut(this.notice);
        }else{
            promise = Http.post('notices/close', {
                ids: this.props.ids,
                entity: this.notice
            });
        }

        promise.then(async (notice) => {
            Alert.show({ message: this.props.successMessage, type: "success" });
            if(this.props.callback){
                this.setState({progress: false});
                Modal.close();
                this.props.callback(notice);
            } else {
                Modal.close();
            }
            if(!this.bulk){
                Navigation.router?.history.push("/resource/" + this.props.resource.instanceId + "/" + this.notice.id + '/detail');
            }
        }).catch(err => {
            if(!this.bulk) {
                this.notice.status = currentStatus;
                this.setState({progress: false});
                Navigation.router?.history.push("/resource/" + this.props.resource.instanceId + "/" + this.notice.id + '/detail');
            }
            console.error(err);
            Modal.close();
        });
    }


    getTitle = () => {
        if (this.props.entity || this.props.ids) {
            if(this.props.action === 'close'){
                return `A supporting document is required in order to close the notice (LoD 1 documentation, folder path of the evidence or committee minutes).`;
            }else if(this.props.action === 'dismiss'){
                return `Do you want to cancel the notice ${this.props.entity.title} ? If so, please justify the cancellation.`;
            }else if(this.props.action === 'moreInfo'){
                return `You are about to request more information to the notice owner.`;
            }
        }
    };

    setNotice(getFields){
        let fields = getFields(this.notice)
        this.notice.effectiveClosingDate = fields.effectiveClosingDate;
        this.notice.closingComment = fields.closingComment;
        this.notice.requestComment = fields.requestComment;
        this.notice.documentsEntities = fields.documentsEntities;
    }

    render() {
        this.notice = this.props.entity ?? {};
        if (this.props.action === 'close') {
            this.notice.effectiveClosingDate = this.notice.effectiveClosingDate ?? new Date();
        }
        return (
            <ModalContent>
                <Typography component={"p"}>{this.getTitle()}</Typography>
                <EntityForm
                    entity={this.notice}
                    fields={this.noticeFields}
                    onUpdate={(n) => this.setNotice(n)}
                />
                <ButtonBar>
                    <ActionButton
                        disabled={this.state.progress}
                        onClick={Modal.close}
                    >
                        Cancel
                    </ActionButton>
                    {this.state.progress ?
                        <ActionButton disabled={true}>
                            <CircularProgress size={20} />
                        </ActionButton>
                        :
                        <ActionButton
                            onClick={() => this.handleClick()}
                        >
                            {this.props.actionTitle}
                        </ActionButton>
                    }
                </ButtonBar>
            </ModalContent>
        );
    }
}

const styles = {
    documentForm: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonAdd: {
        alignSelf: 'center',
        marginBottom: '20px',
        marginTop: '20px',
    },
};
