import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import './resource-edit.css';
import Alert from '../../../Alert';
import Paper from '@material-ui/core/Paper';
import { CircularProgress, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import FieldProviderStore from '../../FieldProviders/__FieldProviderStore';
import AppBarStore from '../../../../Store/AppBarStore';
import Environment from '../../../Environment';
import {APIResource, CONTEXT_EDIT, CONTEXT_ADD, CONTEXT_DETAIL, CONTEXT_LIST} from '../../APIResource';
import {
    hasRightsForOperation,
    hasRightsForField,
    renderAdditionalActionButtons,
    renderAdditionalLinkButton,
} from '../../Utils';
import { HelperButton } from '../../../../Components/HelperButton/HelperButton';
import { SuffixButton } from '../../../../Components/SuffixButton/SuffixButton';
import Navigation from '../../../Navigation';
import {ConfirmModal} from "../../../../Components/Modal/ConfirmModal";
import Modal from '../../../../Services/Modal'
import {OpenModal} from "../../../../Components/Modal/OpenModal";

/**
 * Fonction universelle pour générer un composant d'Edition pour un "field" donné.
 *
 * Utilisée par ResourceEdit et par les autres composants qui ont besoin de leur propre gestion.
 * @todo remplacer les gestions particulières (ex dans les Bulk, Workflow etc.) par cette fonction.
 *
 * @param {import('../../APIResource').APIResourceField} field
 * @param {any} value
 * @param {any} onChange
 * @param {Object} entity
 * @param {import('react-router').RouteComponentProps} routeProps
 * @param {string} context
 * @param {Function} refresh
 * @param {boolean} loadingEntity
 */
 export const genEditComponent = (
    field,
    value,
    onChange,
    entity,
    routeProps = { match: { params: {} } },
    context = CONTEXT_EDIT,
    refresh = () => undefined,
    loadingEntity = false
) => {
    let editComponent;
    if (field.edit) {
        editComponent = field.edit(
            field,
            value,
            onChange,
            entity,
            { ...routeProps.match.params, refresh } /** @todo où "refresh" est-il utilisé ?? */,
            context,
            [],
            loadingEntity
        );
    } else if (FieldProviderStore[field.type] && FieldProviderStore[field.type].getEdit) {
        editComponent = FieldProviderStore[field.type].getEdit(
            field,
            value,
            onChange,
            entity,
            routeProps.match.params,
            loadingEntity
        );
    } else {
        editComponent = FieldProviderStore.default.getEdit(
            field,
            value,
            onChange,
            entity,
            routeProps.match.params,
            loadingEntity
        );
    }
    return editComponent;
};

/**
 * 
 * @param {import('../../APIResource').APIResourceField} field 
 * @param {*} entity 
 * @param {string} fieldId 
 * @param {CONTEXT_ADD|CONTEXT_EDIT|null} context
 * @returns {boolean}
 */
export const isFieldRequired = (field, entity, fieldId, context = null) => {
    return typeof field.required === 'function' ? field.required(entity, fieldId, context) : field.required;
}

/**
 * 
 * @param {import('../../APIResource').APIResourceField} field 
 * @param {*} entity 
 * @param {string} fieldId 
 * @returns {boolean}
 */
export const isFieldEmpty = (field, entity, fieldId) => {
    const value = entity[fieldId];
    return (
        value === null ||
        value === undefined ||
        value === '' ||
        (field.params && field.params.multi === true && Array.isArray(value) && value.length === 0)
    );
};

export const ResourceEdit = observer(
    class ResourceEdit extends Component {
        constructor(props) {
            super(props);
            /** @type {{resource: import('../../APIResource').APIResource, context: CONTEXT_ADD|CONTEXT_EDIT}} */
            this.props;
            
            this.handleChange = this.handleChange.bind(this);
            this.genLayout = this.genLayout.bind(this);
            this.refresh = this.refresh.bind(this);
            this.debugFieldDisplay = false;
            this.props.resource.currentEditingActions = {
                save: this.save.bind(this),
                handleChange: this.handleChange.bind(this),
            };

            // todo ?? is this secure ? default operation is edit ?
            this.layout =
                this.props.context &&
                this.props.resource.operations[this.props.context] &&
                this.props.resource.operations[this.props.context].fieldsDefinition
                    ? this.props.resource.genOperationLayout(this.props.context)
                    : this.props.resource.genOperationLayout(CONTEXT_EDIT);

            this.autoSaveTimeout = null;
            this.nbFieldsPerTabCache = {};
            this.loadingChanges = {};
            this.state = {
                apiUpdateInProgress: false,
                entityHasChanged: false,
                /** Vrai tant que entity n'est pas récupérée du back ou du cache. */
                loadingEntity: true,
                entity: this.props.context === "edit" ? this.props.resource.getObservableItem(this.props.match.params.id) : {},
                currentTab: this.props.resource.getTabId(CONTEXT_EDIT, Environment.getUriParameter('tab')),
                currentTabName: Environment.getUriParameter('tab') ? Environment.getUriParameter('tab') : '', /** case sensitive @todo  */
                nbFieldsPerTab: {},
                fieldsDisplayState: {},
                spinner: false
            };

            if (this.props.display !== 'modal' && !this.props.inModal) {
                AppBarStore.title = this.props.match.params.id
                    ? this.props.resource.name + ': #' + this.props.match.params.id
                    : this.props.resource.name + ': New';
                if (this.props.resource.fieldForTitle && this.props.match.params.id) {
                    this.props.resource.getItem(this.props.match.params.id).then((entity) => {
                        AppBarStore.title = this.props.resource.name + ': ' + entity[this.props.resource.fieldForTitle];
                    });
                }
            }
        }

        componentWillMount() {
            if (this.props.resource.getAclForEditFields) {
                this.props.resource
                    .getAclForEditFields({
                        entity: Object.assign({
                            id: this.props.match.params.id
                        }, this.state.entity),
                        resource: this.props.resource,
                        context: this,
                        routeParams: this.props.match.params,
                    })
                    .then(() => {
                        this.forceUpdate();
                        this.verifyAllTabs();
                    });
            }
        }

        componentDidMount() {
            Navigation.id = 'main-content-resource-edit';
            this.verifyAllTabs();
            let init = this.props.resource.operations[this.props.context].onInit
                ? async (entity, callback, options = { localCurrentEditingEntity: false }) => {
                      await this.props.resource.operations[this.props.context].onInit({
                          entity,
                          resource: this.props.resource,
                          context: this,
                          routeParams: this.props.match.params,
                      });
                      this.setState({
                          entity: entity,
                          entityHasChanged: options && options.localCurrentEditingEntity,
                          loadingEntity: false,
                      }, callback);
                  }
                : (entity, callback, options = { localCurrentEditingEntity: false }) => {
                      this.setState({
                          entity: entity,
                          entityHasChanged: options && options.localCurrentEditingEntity,
                          loadingEntity: false,
                      }, callback);
                  };
            let localCurrentEditingEntity = APIResource.getLocalCurrentEditingEntity();

            if (localCurrentEditingEntity) {
                init({ ...localCurrentEditingEntity.entity }, this.verifyAllTabs, { localCurrentEditingEntity: true });
            } else {
                if (this.props.context === CONTEXT_EDIT) {
                    if(this.props.resource.operations[this.props.context].onLoad && this.state.entity.__init){
                        setTimeout( () => {
                            this.props.resource.operations[this.props.context].onLoad({entity: this.state.entity, resource: this.props.resource, context: this, routeParams: this.props.match.params})
                        }, 1);
                    }
                    this.props.resource.getItem(this.props.match.params.id, true).then((entity) => {
                        for(let field in this.loadingChanges){
                            entity[field] = this.loadingChanges[field];
                        }
                        this.loadingChanges = {};
                        init({ ...entity }, this.verifyAllTabs);
                    });
                } else {
                    init({ ...this.state.entity }, this.verifyAllTabs);
                }
            }
        }

        /**
         * Refresh the entity by getting the new state from the resource
         */
        refresh() {
            if (this.props.context === CONTEXT_EDIT) {
                return this.props.resource.getItem(this.props.match.params.id, true).then((entity) => {
                    this.setState({ entity: entity });
                    // this.validate();
                    this.verifyAllTabs();
                });
            }
            return;
        }

        componentWillUnmount() {
            Navigation.id = '';
            if (!window.location.pathname.match(/^\/logout$/)) {
                clearTimeout(this.autoSaveTimeout);
                APIResource.deleteLocalCurrentEditingCopy();
            }
        }

        handleChange(field, value, entity = null) {
            if (entity === null) {
                entity = { ...this.state.entity };
            }
            if(this.state.loadingEntity){
                this.loadingChanges[field] = value;
            }
            entity[field] = value;
            if (this.props.resource.operations[this.props.context].onUpdate) {
                this.props.resource.operations[this.props.context].onUpdate(
                    field,
                    this.state.entity[field],
                    value,
                    entity,
                    this.props.resource,
                    this
                );
            }
            if(
                this.props.resource.fields[field].type !== undefined 
                && this.props.resource.fields[field].type !== 'text' 
                && this.props.resource.fields[field].type !== 'textarea'
            ){
                this.setState({ entity: entity });
            }
            else {
                this.state.entity[field] = value;
            }
            clearTimeout(this.autoSaveTimeout);
            clearTimeout(this.setStateTimeout);
            this.setStateTimeout = setTimeout(() => {
                this.setState({entityHasChanged: true});
            }, 2000);

            this.autoSaveTimeout = setTimeout(
                () =>
                    APIResource.setLocalCurrentEditingCopy(
                        entity,
                        this.props.resource.instanceId,
                        this.props.resource.name,
                        this.props.resource.resourceId
                    ),
                5000
            );
        }

        /**
         * Recheck all display conditions and set null props to hidden fields
         */
        cleanEntity() {
            let self = this;
            return new Promise((resolve, reject) => {
                /**
                 * On supprime systématiquement les champs de l'entité qui ne sont pas déclarés dans les fields
                 * de la resource, pour ne pas risquer de les écraser lors du PUT.
                 * C'est la même idée que doNotResetValueWhenNotDisplayed.
                 */
                // 1. On doit supprimer des champs déclarés, ceux qui sont juste remplis avec les droits Acl remplis par ApiResource :
                const declaredFields = Object.entries(self.props.resource.fields).reduce(
                    (acc, [k, v]) => (Object.keys(v).length > 1 || !Object.keys(v).includes("apiRights")) ? [...acc, k] : acc,
                    []
                );
                // 2. On conserve les champs indispensables et la whitelist
                const whitelist = this.props.resource.operations[this.props.context].fieldsSaveWhitelist;
                const fields = [...declaredFields, 'id', '@id', '@context', '@type', ...whitelist];
                // 3. On n'envoie plus les champs non déclarés.
                const undeclaredFields = Object.keys(self.state.entity).filter((fieldId) => !fields.includes(fieldId));
                undeclaredFields.forEach((fieldId) => delete self.state.entity[fieldId]);
                for (let rowId in self.layout.tabs[Object.keys(self.layout.tabs)[self.state.currentTab]].rows) {
                    let currentRow = self.layout.tabs[Object.keys(self.layout.tabs)[self.state.currentTab]].rows[rowId];
                    for (let panelId in currentRow.panels) {
                        for (let fieldIndex in currentRow.panels[panelId].fields) {
                            let fieldId = currentRow.panels[panelId].fields[parseInt(fieldIndex)];
                            let field = self.props.resource.fields[fieldId];
                            if (
                                (field.displayConditions || field.displayCondition) &&
                                !self.props.resource.filter(
                                    self.state.entity,
                                    field.displayConditions || field.displayCondition,
                                    self.state,
                                    CONTEXT_EDIT,
                                    fieldId,
                                ).length
                            ) {
                                if (
                                    (field.displayCondition && field.displayCondition(self.state.entity, this.state, fieldIndex, this.props.context, fieldId) === false) ||
                                    (field.displayConditions && field.displayConditions(self.state.entity, this.state, fieldIndex, this.props.context, fieldId) === false)
                                ) {
                                    if (field.doNotResetValueWhenNotDisplayed) {
                                        delete self.state.entity[fieldId];
                                    } else {
                                        self.state.entity[fieldId] =
                                            field.params && field.params.multi && field.params.multi === true
                                                ? []
                                                : null;
                                    }
                                }
                            }
                        }
                    }
                }

                this.setState({ entity: self.state.entity }, resolve);
            });
        }

        cancel(){
            Modal.open({
                title: 'Confirm the cancellation',
                content: <ConfirmModal
                    message={'Are you sure you want to cancel ?'}
                    button={{
                        cancel: 'Continue creation',
                        confirm: 'Confirm cancellation'
                    }}
                    callback={() => {
                        Modal.close();
                        Navigation.router.history.goBack();
                    }}
                />,
                modalStyle: { width: 545 }
            })
        }

        save(
            saveConfirmationTxt = 'Content saved',
            updateConfirmationTxt = 'Content updated',
            validationErrorIntroTxt = 'The content has not been updated.',
            entityToValidate = null,
            onlyValidate = false,
            spinner = false
        ) {
            this.setState({ apiUpdateInProgress: true, spinner: spinner });
            clearTimeout(this.autoSaveTimeout);
            return new Promise((resolve, reject) => {
                let process = () => {
                    this.cleanEntity().then(() =>
                        this.validate(validationErrorIntroTxt).then((isValid) => {
                            if (isValid) {
                                this.verifyAllTabs();
                                if (onlyValidate){ 
                                    resolve(this.state.entity);
                                    this.setState({
                                        apiUpdateInProgress: false,
                                        spinner: false
                                    })
                                    return;
                                }
                                if (this.props.context === CONTEXT_ADD) {
                                    if (Object.keys(this.state.entity).length) {
                                        this.props.resource
                                            .apiPost(this.state.entity)
                                            .then((item) => {
                                                Alert.show({
                                                    message: saveConfirmationTxt,
                                                    type: 'success',
                                                });
                                                APIResource.deleteLocalCurrentEditingCopy();
                                                this.props.onSuccess && this.props.onSuccess();
                                                // where to redirect the user after he finishes the operation ?
                                                var { postSaveRedirect } = this.props.resource.operations.add;
                                                if(this.props.postSaveRedirect){
                                                    postSaveRedirect = this.props.postSaveRedirect;
                                                }
                                                const canEdit = hasRightsForOperation(CONTEXT_EDIT, {
                                                    ...this.props,
                                                    entity: this.state.entity,
                                                    hasRole: this.props.settingsApi && this.props.settingsApi.hasRole,
                                                });
                                                const canViewDetail = hasRightsForOperation(CONTEXT_DETAIL, {
                                                    ...this.props,
                                                    entity: this.state.entity,
                                                    hasRole: this.props.settingsApi && this.props.settingsApi.hasRole,
                                                });
                                                let targetUrl = '';
                                                if (
                                                    postSaveRedirect !== CONTEXT_DETAIL
                                                    && typeof postSaveRedirect === 'function'
                                                ) {
                                                    targetUrl =
                                                        postSaveRedirect(
                                                            this.props.resource,
                                                            item,
                                                            new URLSearchParams(window.location.search)
                                                        );
                                                }

                                                if(targetUrl === ''){
                                                    if (canEdit && (!postSaveRedirect || postSaveRedirect === CONTEXT_EDIT)) {
                                                        targetUrl =
                                                            '/resource/' +
                                                            this.props.resource.instanceId +
                                                            '/' +
                                                            item.id +
                                                            '/edit';
                                                    } else if (
                                                        canViewDetail &&
                                                        (!postSaveRedirect ||
                                                            postSaveRedirect === CONTEXT_DETAIL ||
                                                            postSaveRedirect === CONTEXT_EDIT) // if where are here with an edit operation it means that we can't edit...
                                                    ) {
                                                        targetUrl =
                                                            '/resource/' +
                                                            this.props.resource.instanceId +
                                                            '/' +
                                                            item.id +
                                                            '/detail';
                                                    } else {
                                                        targetUrl = '/resource/' + this.props.resource.instanceId + '/list';
                                                    }
                                                }

                                                this.props.history && this.props.history.push(targetUrl);

                                                resolve(item);

                                                if(targetUrl !== ''){
                                                    Navigation.router.history.push(targetUrl);
                                                } else if (this.props.resource.operations.detail) {
                                                    Navigation.router.history.push(
                                                        '/resource/' +
                                                            this.props.resource.instanceId +
                                                            '/' +
                                                            item.id +
                                                            '/detail?tab=' +
                                                            this.state.currentTabName
                                                    );
                                                }
                                            })
                                            .catch(reject)
                                            .finally(() =>
                                                this.setState({
                                                    apiUpdateInProgress: false,
                                                    spinner: false
                                                })
                                            );
                                    } else {
                                        this.setState({
                                            apiUpdateInProgress: false,
                                            spinner: false
                                        })
                                        Alert.show({
                                            message: 'No data to save',
                                            type: 'warning',
                                        });
                                        reject();
                                    }
                                } else {
                                    this.setState({ apiUpdateInProgress: true });
                                    this.props.resource
                                        .apiPut(this.state.entity)
                                        .then((item) => {
                                            APIResource.deleteLocalCurrentEditingCopy();
                                            this.setState({ entityHasChanged: false });
                                            Alert.show({
                                                message: updateConfirmationTxt,
                                                type: 'success',
                                            });
                                            this.props.onSuccess && this.props.onSuccess();
                                            this.refresh();
                                            resolve(item);

                                            // where to redirect the user after he finishes the operation ?
                                            var { postSaveRedirect } = this.props.resource.operations.edit;
                                            if(this.props.postSaveRedirect){
                                                postSaveRedirect = this.props.postSaveRedirect;
                                            }
                                            let targetUrl = '';
                                            if (
                                                postSaveRedirect !== CONTEXT_DETAIL
                                                && typeof postSaveRedirect === 'function'
                                            ) {
                                                targetUrl =
                                                    postSaveRedirect(
                                                        this.props.resource,
                                                        item,
                                                        new URLSearchParams(window.location.search)
                                                    );
                                            }

                                            if(targetUrl !== ''){
                                                Navigation.router.history.push(targetUrl);
                                            } else if (this.props.resource.operations.detail) {
                                                Navigation.router.history.push(
                                                    '/resource/' +
                                                        this.props.resource.instanceId +
                                                        '/' +
                                                        item.id +
                                                        '/detail?tab=' +
                                                        this.state.currentTabName
                                                );
                                            }
                                        })
                                        .catch(reject)
                                        .finally(() =>
                                            this.setState({
                                                apiUpdateInProgress: false,
                                                spinner: false
                                            })
                                        );
                                }
                            } else {
                                this.setState({
                                    apiUpdateInProgress: false,
                                    spinner: false
                                })
                                reject();
                            }
                        })
                    );
                };

                if (entityToValidate) {
                    this.setState({ entity: entityToValidate }, () => {
                        process();
                    });
                } else {
                    process();
                }
            });
        }

        validate(validationErrorIntroTxt = '') {
            return new Promise((resolve, reject) => {
                let errors = [];

                // Méthode gérant le traitement des erreurs et la résolution de la promesse
                let manageErrors = function (validationErrors) {
                    if (validationErrors !== true) {
                        errors = errors.concat(validationErrors);
                    }
                    if (errors.length > 0) {
                        errors.push();
                        let errorsTxt = errors.map((item) => item.field + ': ' + item.detail).join('\n\n');
                        Alert.show({
                            message: (validationErrorIntroTxt ? validationErrorIntroTxt + '\n\n' : '') + errorsTxt,
                            type: 'warning',
                        });
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                };

                // Test des règles de validation déclarées via this.setFields()
                this.props.resource.operations[this.props.context].fields.forEach((fieldId) => {
                    let field = this.props.resource.fields[fieldId];
                    field.errorHelperText = '';
                    if (!field) {
                        console.error('Champ inconnu', this.props.resource.instanceId, fieldId);
                        return;
                    }
                    if (
                        (field.displayConditions || field.displayCondition) &&
                        !this.props.resource.filter(
                            this.state.entity,
                            field.displayConditions || field.displayCondition,
                            this.state,
                            CONTEXT_EDIT,
                            fieldId,
                        ).length
                    ) {
                        return;
                    }

                    if (
                        !hasRightsForField(field, {
                            hasRole: this.props.settingsApi && this.props.settingsApi.hasRole,
                            operation: CONTEXT_EDIT,
                            entity: this.state.entity,
                        })
                    ) {
                        return;
                    }

                    if (
                        ((typeof field.required === 'function' && field.required(this.state.entity, fieldId, this.props.context)) ||
                            (typeof field.required !== 'function' && field.required)) &&
                        isFieldEmpty(field, this.state.entity, fieldId)
                    ) {
                        errors.push({
                            field: field.title,
                            detail: 'This field is required',
                        });
                        field.errorHelperText = 'This field is required';
                    }
                    if (
                        field.validationPattern &&
                        this.state.entity[fieldId] !== undefined &&
                        this.state.entity[fieldId] !== ''
                    ) {
                        if (this.state.entity[fieldId]) {
                            let stringValue = String(this.state.entity[fieldId]);
                            if (!stringValue.match(field.validationPattern)) {
                                errors.push({
                                    field: field.title,
                                    detail: 'The provided value is not valid',
                                });
                                field.errorHelperText = 'The provided value is not valid';
                            }
                        }
                    }
                });
                this.forceUpdate();
                // Exécution de la callBack de validation si celle-ci existe et qu'il n'y a pas d'erreur liée aux règles de validation déclarées via this.setFields()
                if (!errors.length && this.props.resource.validation) {
                    let callbackReturn = this.props.resource.validation(this.state.entity);
                    // Si la callback passée est une promesse
                    if (callbackReturn.then) {
                        callbackReturn.then((validationErrors) => {
                            manageErrors(validationErrors);
                        });
                    }
                    // Si la callback passée n'est pas une promesse
                    else {
                        let validationErrors = this.props.resource.validation(this.state.entity);
                        manageErrors(validationErrors);
                    }
                }
                // Si aucune callback de validation n'est fournie on traite les erreurs potentielles
                else {
                    manageErrors(true);
                }
            });
        }

        verifyAllTabs = (userClickTab) => {
            Object.keys(this.layout.tabs).forEach(this.verifyTabDisplayCondition);
            this.setState({ nbFieldsPerTab: this.nbFieldsPerTabCache });
            if (!userClickTab) {
                let newTab = this.props.resource.getTabId(this.props.context, Environment.getUriParameter('tab'), this.nbFieldsPerTabCache);
                this.setState({
                    currentTab: newTab,
                    currentTabName: Object.keys(this.layout.tabs)[newTab],
                });
                this.forceUpdate();
            }
        };

        verifyTabDisplayCondition = (tabLabel) => {
            let count = 0;
            if (
                this.layout.tabs[tabLabel].displayConditions &&
                this.layout.tabs[tabLabel].displayConditions(this.state.entity) === false
            ) {
                this.nbFieldsPerTabCache[tabLabel] = 0;
                return;
            }
            const { fieldsDisplayState } = this.state;
            const rows = this.layout.tabs[tabLabel].rows;
            for (let rowId in rows) {
                let currentRow = rows[rowId];
                for (let panelId in currentRow.panels) {
                    const panelFields = currentRow.panels[panelId].fields;
                    for (let fieldIndex in panelFields) {
                        let fieldId = panelFields[parseInt(fieldIndex)];
                        const fieldData = this.genField(fieldId, { hideForbiddenFields: true });
                        if (fieldData) {
                            count++;
                        }
                        if (this.debugFieldDisplay) {
                            fieldsDisplayState[fieldId] = !!fieldData;
                            this.setState({ fieldsDisplayState });
                        }
                    }
                }
            }
            this.nbFieldsPerTabCache[tabLabel] = count;
        };

        /**
         *
         *
         * @param {*} fieldId
         * @param {Object.<string,boolean>} [options={hideForbiddenFields: false}]
         * @returns
         */
        genField = (fieldId, options = { hideForbiddenFields: false }) => {
            let field = this.props.resource.fields[fieldId];

            field.requiredComputed = isFieldRequired(field, this.state.entity, fieldId, this.props.context);

            let editComponent = null;
            field.resourceId = this.props.resource.resourceId;
            const onChange = (value) => this.handleChange(fieldId, value);
            const value = this.state.entity[fieldId];
            if (
                (field.displayConditions || field.displayCondition) &&
                !this.props.resource.filter(
                    this.state.entity,
                    field.displayConditions || field.displayCondition,
                    this.state,
                    CONTEXT_EDIT,
                    fieldId,
                ).length
            ) {
                return;
            }

            const canEdit = hasRightsForField(field, {
                hasRole: this.props.settingsApi && this.props.settingsApi.hasRole,
                operation: CONTEXT_EDIT,
                entity: this.state.entity,
            });

            const canDisplay = hasRightsForField(field, {
                hasRole: this.props.settingsApi && this.props.settingsApi.hasRole,
                operation: CONTEXT_DETAIL,
                entity: this.state.entity,
            });

            let highlighted = false;

            // if the users rights do no allow him to edit the data
            // or if the field is not editable (or editConditions doesn't exist)
            // then use a display component
            if (!canEdit || (canEdit  && field.editConditions && !field.editConditions(field, value, this.state.entity))) {
                if (options.hideForbiddenFields) {
                    return;
                }
                const displayValue = canDisplay ? value : '- Restricted field -';

                const displayProvider = canDisplay
                    ? FieldProviderStore[field.type] || FieldProviderStore.default
                    : FieldProviderStore.default;

                editComponent = field.display
                    ? field.display(field, displayValue, this.state.entity, {}, null, CONTEXT_EDIT)
                    : displayProvider.getDisplay(field, displayValue, this.state.entity);
            } else {
                editComponent = genEditComponent(
                    field,
                    value,
                    onChange,
                    this.state.entity,
                    { match: this.props.match, location: this.props.location, history: this.props.history },
                    this.props.context,
                    this.refresh,
                    this.state.loadingEntity,
                );

                if(field.highlighted){
                    highlighted = field.highlighted(this.state.entity, field.id, new URLSearchParams(window.location.search))
                }
            }

            return (
                <div
                    className={
                        'edit-field ressource-api-field' +
                        (field.helperTextEdit ? ' with-helper-button' : '') +
                        (field.suffix ? ' with-suffix-button' : '') +
                        (highlighted ? ' highlight' : '')
                    }
                    key={fieldId}
                >
                    {editComponent}

                    {editComponent && field.suffix ? (
                        <SuffixButton suffix={field.suffix} entity={this.state.entity} />
                    ) : null}
                    {editComponent && field.helperTextEdit ? (
                        <HelperButton helperText={field.helperTextEdit} entity={this.state.entity} />
                    ) : null}
                </div>
            );
        };

        /**
         *
         *
         * @returns
         */
        genLayout() {
            let self = this;
            let { nbFieldsPerTab } = this.state;
            if (this.layout) {
                let additionalActionButtons = renderAdditionalActionButtons(
                    this.props.resource,
                    this.state.entity,
                    this.props.context,
                    this,
                    this.state.apiUpdateInProgress,
                    this.state.spinner
                );
                let genHeader = function (entity, context) {
                    return typeof self.props.resource.operations[context].header === 'function'
                        ? self.props.resource.operations[context].header(entity, context)
                        : null;
                };
                let genTabs = function () {
                    let tabs = [];
                    for (let tabLabel in self.layout.tabs) {
                        tabs.push(
                            <Tab
                                label={tabLabel}
                                key={tabLabel}
                                className={
                                    nbFieldsPerTab[tabLabel] < 1 ? 'display-hidden' : 'tab-' + nbFieldsPerTab[tabLabel]
                                }
                            />
                        );
                    }
                    return tabs;
                };
                let genContent = function () {
                    let panels = [];
                    const currentTabLabel = Object.keys(self.layout.tabs)[self.state.currentTab];
                    const rows = self.layout.tabs[currentTabLabel].rows;

                    for (let rowId in rows) {
                        let currentRow = rows[rowId];
                        for (let panelId in currentRow.panels) {
                            const panelFields = currentRow.panels[panelId].fields;
                            let fields = [];
                            for (let fieldIndex in panelFields) {
                                let fieldId = panelFields[parseInt(fieldIndex)];
                                const generatedField = self.genField(fieldId);
                                if (generatedField) {
                                    fields.push(generatedField);
                                }
                            }
                            if (fields.length) {
                                panels.push(
                                    self.props.display === 'modal' ? (
                                        <div key={panelId} className="MuiGrid-item MuiGrid-grid-xs-12">
                                            {fields}
                                        </div>
                                    ) : (
                                        <Grid
                                            key={panelId}
                                            item
                                            xs={currentRow.panels[panelId].cols}
                                            style={styles.blockHeightStyle}
                                            className="ressource-api-container"
                                        >
                                            <Paper style={styles.gridPaper} className="ressource-api-box">
                                                <h1 className="background-linear-gradient">{panelId}</h1>
                                                {fields}
                                            </Paper>
                                        </Grid>
                                    )
                                );
                            }
                        }
                    }
                    return (
                        <Grid
                            container
                            spacing={2}
                            className={`container resource-edit mrm-box ${
                                self.props.resource.instanceId ? self.props.resource.instanceId.toLowerCase() : ''
                            } ${self.props.resource.resourceId ? self.props.resource.resourceId.toLowerCase() : ''
                            } ${Array.isArray(additionalActionButtons) && additionalActionButtons.length > 0 ? 'with-additional-action-buttons' : ''}`}
                        >
                            {panels}
                        </Grid>
                    );
                };
                let allowDetail = true;
                allowDetail = hasRightsForOperation(CONTEXT_DETAIL, {
                    ...self.props,
                    entity: this.state.entity,
                    hasRole: this.props.settingsApi && this.props.settingsApi.hasOneRole,
                });
                let allowDetailInModal = false;
                if(self.props.inModal && typeof self.props.openModalComponent.props.allowStayInModal === 'function'){
                    allowDetailInModal = self.props.openModalComponent.props.allowStayInModal(self.state.entity, CONTEXT_DETAIL);
                }else if(self.props.inModal && typeof self.props.openModalComponent.props.allowStayInModal === 'boolean'){
                    allowDetailInModal = self.props.openModalComponent.props.allowStayInModal;
                }
                return self.props.display === 'modal' ? (
                    <div className={this.props.className} style={this.props.style}>
                        {genContent()}
                        <Button variant="contained" onClick={() => this.save()} className="item valid">
                            Save
                        </Button>
                    </div>
                ) : (
                    <div>
                        {genHeader(this.state.entity, this.props.context)}
                        {Object.keys(self.layout.tabs).length > 1 ? (
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={this.state.currentTab}
                                    onChange={(event, val) => {
                                        this.setState({
                                            currentTab: val,
                                            currentTabName: Object.keys(this.layout.tabs)[val],
                                        });
                                        this.verifyAllTabs(true);
                                    }}
                                    className="tabs-menu"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="standard"
                                    scrollButtons="auto"
                                    key={'resourceEditTab'}
                                >
                                    {genTabs()}
                                </Tabs>
                            </AppBar>
                        ) : null}
                        {genContent()}
                        {Array.isArray(additionalActionButtons) && additionalActionButtons.length > 0 ? (
                            <div
                                className={
                                    'button-custom-bar-bottom resource-edit' +
                                    (self.props.resource.instanceId
                                        ? ' ' + self.props.resource.instanceId.toLowerCase()
                                        : '') +
                                    (self.props.resource.resourceId
                                        ? ' ' + self.props.resource.resourceId.toLowerCase()
                                        : '')
                                }
                            >
                                {additionalActionButtons}
                            </div>
                        ) : null}
                        <div
                            className={
                                'button-bar-bottom resource-edit' +
                                (self.props.resource.instanceId
                                    ? ' ' + self.props.resource.instanceId.toLowerCase()
                                    : '') +
                                (self.props.resource.resourceId
                                    ? ' ' + self.props.resource.resourceId.toLowerCase()
                                    : '')
                            }
                        >
                            {renderAdditionalLinkButton(
                                this.props.resource,
                                this.state.entity,
                                this.props.context,
                                this
                            )}
                            { self.props.context === CONTEXT_ADD ?
                                <Tooltip
                                    title={'Cancel'}
                                    arrow={true}
                                    leaveTouchDelay={300}
                                >
                                <span className={'item'}>
                                    <Button
                                        variant="contained"
                                        onClick={() => this.cancel()}
                                        className="danger"
                                    >
                                        <i className={'fa fa-times-circle'}></i>
                                    </Button>
                                </span>
                                </Tooltip>
                            : null}
                            <Tooltip
                                title={self.state.entityHasChanged ? 'Save' : 'There is no change to save.'}
                                arrow={true}
                                leaveTouchDelay={300}
                            >
                                <span className={'item'}>
                                    <Button
                                        variant="contained"
                                        onClick={() => this.save()}
                                        className=" valid "
                                        disabled={
                                            [CONTEXT_EDIT, CONTEXT_ADD].includes(self.props.context) &&
                                            (self.state.apiUpdateInProgress || !self.state.entityHasChanged)
                                        }
                                    >
                                        <i
                                            className={
                                                self.state.apiUpdateInProgress && !this.state.spinner
                                                    ? 'fa fa-circle-notch text-primary fa-rotate fa-spin'
                                                    : 'fa fa-save'
                                            }
                                        ></i>
                                    </Button>
                                </span>
                            </Tooltip>
                            {allowDetail && self.props.context == CONTEXT_EDIT && !allowDetailInModal ? (
                                <Tooltip title="Detail view" arrow={true} leaveTouchDelay={300}>
                                    <Link
                                        key={'detail_resource'}
                                        to={
                                            '/resource/' +
                                            this.props.resource.instanceId +
                                            '/' +
                                            self.props.match.params.id +
                                            '/detail?tab=' +
                                            self.state.currentTabName
                                        }
                                        className="item"
                                    >
                                        <Button variant="contained" className="primary ">
                                            <i className="fa fa-th-large"></i>
                                        </Button>
                                    </Link>
                                </Tooltip>
                            ) : null}
                            {allowDetail && self.props.context == CONTEXT_EDIT && allowDetailInModal ? (
                                <Tooltip title="Detail view" arrow={true} leaveTouchDelay={300}>
                                    <OpenModal
                                        {...self.props.openModalComponent.props}
                                        withIcon={true}
                                        containerClassName="item"
                                        context={CONTEXT_DETAIL}
                                    />
                                </Tooltip>
                            ) : null}
                            {hasRightsForOperation(CONTEXT_LIST, {
                                ...self.props,
                                entity: this.state.entity,
                                hasRole: this.props.settingsApi && this.props.settingsApi.hasOneRole,
                            }) ? (
                                <Tooltip title={`${this.props.resource.name} list`} arrow={true} leaveTouchDelay={300}>
                                    <Link
                                        key={'list_resource'}
                                        to={'/resource/' + this.props.resource.instanceId + '/list'}
                                        className="item"
                                    >
                                        <Button variant="contained" className="secondary tooltip">
                                            <i className="fa fa-list"></i>
                                        </Button>
                                    </Link>
                                </Tooltip>
                            ) : null}
                        </div>
                    </div>
                );
            } else {
            }
        }

        render() {
            return this.genLayout();
        }
    }
);

const styles = {
    blockHeightStyle: {
        paddingBottom: 5,
        marginBottom: 35,
    },
    gridPaper: {
        marginTop: 15,
        marginBottom: 15,
        height: '100%',
    },
};
