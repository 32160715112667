import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Services/Modal';
import { Button, Icon } from '@material-ui/core';
import { IT_SYSTEM_FIELDS } from '../../Admin/ITSystemAdmin';
import EntityQuickAddForm from '../Forms/EntityQuickAddForm/EntityQuickAddForm';

export const ITSystemQuickAdd = (props) => {
    const { onChange } = props;

    // On extrait les champs qui nous intéressent de l'Admin ITSystem
    const allowedFields = ['name', 'type', 'module'];
    const fields = Object.keys(IT_SYSTEM_FIELDS)
        .filter((key) => allowedFields.includes(key))
        .reduce((p, key) => {
            return { ...p, [key]: IT_SYSTEM_FIELDS[key] };
        }, {});

    const addVerifiedToITSystem = (i_t_system) => {
        return {
            ...i_t_system,
            verified: true,
        };
    };

    const openITSystemQuickAddModal = () => {
        Modal.open({
            title: 'New IT System',
            content: (
                <EntityQuickAddForm
                    onSubmit={onChange}
                    fields={fields}
                    prePostHook={addVerifiedToITSystem}
                    resourceId={'i_t_systems'}
                />
            ),
        });
    };

    return (
        <Button variant="contained" color="primary" style={styles.green} onClick={openITSystemQuickAddModal}>
            <Icon className="fas fa-plus" />
        </Button>
    );
};
ITSystemQuickAdd.propTypes = {
    /** Exécutée après la création de l'entité */
    onChange: PropTypes.func.isRequired,
};

const styles = {
    green: {
        backgroundColor: '#0dbbb7',
    },
};
