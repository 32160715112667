import RouteStore, {Route} from "../../Store/RouteStore";
import React from "react";
import UserAssignation from "./UserAssignation";

RouteStore.routes.resource_users_assignation = new Route({
    path: '/resource/users/assignation',
    component: (props) => <UserAssignation {...props}/>,
    menuItem: {
        title: 'User assignation',
        icon: 'brain'
    },
});