import APIResourceStore from '../../../Store/APIResourceStore';

/**
 * Renvoie la liste des noms des champs en erreur.
 * 
 * @param {Object} fields - Pour chaque champ la valeur indique s'il est en erreur ou pas : ex: fields = { name: false, ..., dataSource: true, ...}
 */
export const extractErrorFieldnames = (fields, resourceName) => {
    return Object.entries(fields)
        .map(([fieldId, hasError]) => {
            if (
                hasError &&
                APIResourceStore.resources[resourceName].fields[fieldId] &&
                APIResourceStore.resources[resourceName].fields[fieldId].title
            ) {
                return APIResourceStore.resources[resourceName].fields[fieldId].title;
            } else if (
                !APIResourceStore.resources[resourceName].fields[fieldId] ||
                !APIResourceStore.resources[resourceName].fields[fieldId].title
            ) {
                console.log('missing data named : ' + fieldId);
                return false;
            }
        })
        .filter((fieldName) => fieldName);
};
