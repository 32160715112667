import React, { Component } from 'react';
import { TimerSharp } from '@material-ui/icons';
import { DisplayTextField } from '../DisplayTextField/DisplayTextField';

export class MappedValueDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
        };
    }

    componentDidMount() {
        this.valueInit();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value !== prevProps.value) {
            this.valueInit();
        }
    }

    valueInit() {
        if (Array.isArray(this.props.value)) {
            let values = [];
            this.props.value.forEach((val, index) => {
                values.push(<div key={index}>{this.props.map[val]}</div>);
            });
            this.setState({ value: <div>{values}</div> });
        } else {
            this.setState({
                value:
                    this.props.value !== '' && this.props.value !== null
                        ? this.props.map[this.props.value]
                        : '',
            });
        }
    }

    render() {
        return !this.props.showFieldset ? (
            <span>{this.state.value}</span>
        ) : (
            <DisplayTextField
                {...this.props}
                fieldName={this.props.fieldName}
                value={this.state.value}
            />
        );
    }
}
