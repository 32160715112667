import { APIResource } from "../Services/APIResource/APIResource";
import User, { ROLE } from "../Services/User/User";
import { PARAMETER_TYPE_TROUBLE_TYPE } from "../Admin/ParameterAdmin";
import { userHasRoleMRM } from "../Store/ParameterStore";

const statusMap = ["Submitted", "In progress", "Closed"];
const associatedEntitiesParams = {
    models: {
        resource: "models",
        label: "functionalID",
        multi: true,
        links: true,
        title: "Model",
    },
    modelCertificationCampaign: {
        resource: "model_certification_campaigns",
        label: "id",
        multi: false,
        links: true,
        title: "Certification Campaign",
    },
};

const getAssociatedEntitiesFields = () => {
    let fields = {};

    Object.entries(associatedEntitiesParams).forEach(([k, v]) => {
        fields[k] = {
            title: "Targeted " + v.title,
            type: "entity",
            params: {
                resource: v.resource,
                displayField: v.label,
                links: v.links,
                multi: v.multi,
            },
            displayCondition: (entity, entity2, key, context) =>
                context === "edit" || (entity[k] && entity[k].length > 0),
        };
    });
    return fields;
};

export const ApiResourceDefaultParams = {
    id: "issues",
    fieldForTitle: "title",
    fieldsAclLocation: 'annotations/issue',
};

export default class TroubleAdmin {
    constructor() {
        this.configure();
    }

    configure() {
        const TROUBLE_FIELDS = {
            id: { title: "ID" },
            date: { title: "Date", type: "date" },
            type: {
                title: "Type",
                type: "parameter",
                params: { type: PARAMETER_TYPE_TROUBLE_TYPE, multi: false },
            },
            status: {
                title: "Status",
                type: "mapped",
                params: {
                    mapping: statusMap,
                    multi: false,
                },
            },
            title: { title: "Title", type: "text" },
            fromUser: {
                title: "Issuer",
                type: "user",
                params: {
                    resource: "users",
                    displayField: "fullName",
                    links: true,
                    endpoints: {
                        getAll: "users/all-users/all",
                    },
                },
            },
            toTeams: {
                title: "MRM Teams",
                type: "entityAsync",
                params: {
                    resource: "scopes",
                    instanceId: "scopes_mrm",
                    displayField: "title",
                    multi: true,
                    endpoints: {
                        getAll: "scopes/all-scopes/mrm",
                    },
                },
            },
            toUsers: {
                title: "Assigned to",
                type: "user",
                params: {
                    resource: "users",
                    instanceId: "users_mrm",
                    displayField: "fullName",
                    links: true,
                    multi: true,
                    endpoints: {
                        getAll: "users/all-users/all",
                    },
                    filters: (item, entity) => {
                        return (
                            entity &&
                            entity.toTeamsEntities.reduce(
                                (prev, t) =>
                                    item && item.teams && 
                                        (
                                            item.teams.includes(t['@id']) ||
                                            (t.followers && t.followers.includes(item['@id'])) ||
                                            prev
                                        ),
                                false
                            )
                        )
                    }
                },
                displayConditions: (entity, entity2, key, context) =>
                    context === "edit"
                        ? entity.toTeams &&
                          entity.toTeams.reduce(
                              (prev, t) =>
                                  User.profile.teams.includes(t) ||
                                  (t.followers && t.followers.includes(
                                      `/api/users/${User.getId()}`
                                  )) ||
                                  prev,
                              false
                          ) || userHasRoleMRM()
                        : true,
            },
            description: { title: "Description", type: "textarea" },
            mrmAnswer: {
                title: "MRM Answer",
                type: "textarea",
                displayConditions: (entity, entity2, key, context) =>
                    context === "edit"
                        ? entity.toTeams &&
                          entity.toTeams.reduce(
                              (prev, t) =>
                                  User.profile.teams.includes(t) ||
                                  (t.followers && t.followers.includes(
                                      `/api/users/${User.getId()}`
                                  )) ||
                                  prev,
                              false
                          ) || userHasRoleMRM()
                        : true,
            },
            ...getAssociatedEntitiesFields(),
        };

        const LAYOUT = {
            tabs: {
                Detail: {
                    rows: [
                        {
                            panels: {
                                Properties: {
                                    cols: 6,
                                    fields: ["id", "date", "type", "status"],
                                },
                                Stakeholders: {
                                    cols: 6,
                                    fields: ["fromUser", "toTeams", "toUsers"],
                                },
                            },
                        },
                        {
                            panels: {
                                Purpose: {
                                    cols: 6,
                                    fields: [
                                        "title",
                                        "models",
                                        "modelCertificationCampaign",
                                        "description",
                                        "mrmAnswer",
                                    ],
                                },
                            },
                        },
                    ],
                },
            },
        };

        const LIST_FIELDS_DEFAULT = [
            "date",
            "type",
            "status",
            "title",
            "fromUser",
            "toTeams",
            "toUsers",
            "mrmAnswer",
        ];

        const views = [
            // Cette première vue est déclarée uniquement en vue d'alimenter la définition des champs de l'entité Issue dans le moteur de conception des Workflow
            // On utilise pour cela l'instanceID "issues" identique au nom de l'entité en backend
            {
                instanceId: "issues",
                name: "Issues",
                permanentFilters: {
                    /** @todo pas de parameterStore pour le statut pour l'instant on utilise la map
                    ParameterStore(
                        'MODEL_STATUS_CANDIDATE'
                    ), */
                    "status[0]": 0,
                    "status[1]": 1,
                    currentTeamOnly: true,
                    "exists[toUsers]": false,
                },
                listFields: LIST_FIELDS_DEFAULT,
                allRoutesAccessControl: [ROLE.MRM],
            },
            {
                instanceId: "my_troubles",
                name: "My troubles",
                permanentFilters: {
                    "status[0]": 0,
                    "status[1]": 1,
                    "status[2]": 2,
                    myTroubles: true,
                },
                listFields: LIST_FIELDS_DEFAULT,
            },
            {
                instanceId: "waiting_troubles",
                name: "Waiting for assignment Troubles",
                permanentFilters: {
                    /** @todo pas de parameterStore pour le statut pour l'instant on utilise la map
                    ParameterStore(
                        'MODEL_STATUS_CANDIDATE'
                    ), */
                    "status[0]": 0,
                    "status[1]": 1,
                    currentTeamOnly: true,
                    "exists[toUsers]": false,
                },
                listFields: LIST_FIELDS_DEFAULT,
                allRoutesAccessControl: [ROLE.MRM],
            },
            {
                instanceId: "open_troubles",
                name: "Open troubles",
                permanentFilters: {
                    /** @todo pas de parameterStore pour le statut pour l'instant on utilise la map
                    ParameterStore(
                        'MODEL_STATUS_CANDIDATE'
                    ), */
                    "status[0]": 0,
                    "status[1]": 1,
                    currentTeamOnly: true,
                },
                listFields: LIST_FIELDS_DEFAULT,
                allRoutesAccessControl: [ROLE.MRM],
            },
            {
                instanceId: "closed_troubles",
                name: "Closed troubles",
                permanentFilters: {
                    /** @todo pas de parameterStore pour le statut pour l'instant on utilise la map
                    ParameterStore(
                        'MODEL_STATUS_CANDIDATE'
                    ), */
                    status: 2,
                    currentTeamOnly: true,
                },
                listFields: LIST_FIELDS_DEFAULT,
                allRoutesAccessControl: [ROLE.MRM],
            },
        ];
        views.forEach((view) => {
            const resource = new APIResource({
                ...ApiResourceDefaultParams,
                ...{
                    instanceId: view.instanceId,
                    name: view.name,
                }
            });
            resource
                .setFields(TROUBLE_FIELDS)
                .setLayout(LAYOUT)
                .genListView({
                    fields: ["id", ...view.listFields],
                    permanentFilters: view.permanentFilters,
                    routeAccessControl: view.allRoutesAccessControl,
                })
                .genEditView({
                    fields: [
                        "description",
                        ...view.listFields,
                        ...[
                            "models",
                            "modelCertificationCampaign",
                        ],
                    ],
                    routeAccessControl: view.allRoutesAccessControl,
                }) /** @todo edition que si MRM */
                .genDetailView({
                    fields: [
                        "id",
                        "description",
                        ...view.listFields,
                        ...[
                            "models",
                            "modelCertificationCampaign",
                        ],
                    ],
                    routeAccessControl: view.allRoutesAccessControl,
                });
        });
    }
}
