import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import ModalComplementary from '../../Services/ModalComplementary';
import { APIResource } from '../../Services/APIResource/APIResource';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { QuickSelectForm } from '../Forms/QuickSelectForm/QuickSelectForm';
import { Link } from 'react-router-dom';

const QUERY_MIN_CHARS = 3;
const isID = (value) => value === parseInt(value).toString();

export const ModelQuickSelect = (props) => {
    const {
        label,
        values,
        onChange,
        multi = false,
        withModal = true,
        resourceId,
        instanceId,
        entity,
        endpoints,
        clearable,
        filters,
        context,
    } = props;

    const properties = ['id', 'modelID', 'initialID', 'name', 'modelOwner', 'functionalID', ...(props.additionalProperties || [])];

    const [loaded, setLoaded] = useState(false);

    const columns = [
        {
            label: 'ID',
            field: 'modelID',
            forceDisplay: true,
        },
        {
            label: 'Initial ID',
            field: 'initialID',
            forceDisplay: true,
        },
        {
            label: 'Functional ID',
            field: 'functionalID',
            forceDisplay: true,
        },
        {
            label: 'Name',
            field: 'name',
            forceDisplay: true,
            display: (field, value, model, _props) => (
                <Link to={`/resource/${resourceId}/${model?.id}/detail`} target={'_blank'} rel={'noreferrer'}>
                    {value}
                </Link>
            ),
        },
        {
            label: 'Model Owner',
            field: 'modelOwner',
            type: 'user',
            forceDisplay: true,
            params: {
                resource: 'users',
                instanceId: 'users_mo',
                displayField: 'toString',
                links: false,
            },
        },
    ];

    /** @type {{current: import('../../Services/APIResource/APIResource').APIResource}} */
    const resource = useRef();
    useEffect(instanceId => {
        resource.current = new APIResource({
            id: resourceId,
            instanceId: instanceId,
            endpoints: endpoints || null,
        }).clone(instanceId + '_quick_model');
    }, [resourceId, instanceId]);

    /** Dans le cas d'une création, on n'a pas à attendre que l'entité soit chargée. */
    useEffect(() => {
        if (context === 'add') setLoaded(true);
    }, []);

    useEffect(() => {
        if (entity && entity.id) setLoaded(true);
    }, [entity]);

    const apiSearchModel = async (searchValue) => {
        if (!searchValue || (!isID(searchValue) && searchValue.length < QUERY_MIN_CHARS)) return;

        const apiRequests = [];
        if (!isID(searchValue) && searchValue.length >= QUERY_MIN_CHARS){
            apiRequests.push(
                resource.current.apiGetCollection({ page: 1, rowsPerPage: 10000, filters: { 'initialID[]': searchValue }, fields: properties }),
                resource.current.apiGetCollection({ page: 1, rowsPerPage: 10000, filters: { 'name[]': searchValue }, fields: properties }),
                resource.current.apiGetCollection({
                    page: 1,
                    rowsPerPage: 10000,
                    filters: { 'functionalID[]': searchValue },
                    fields: properties,
                }),
            )
            // Traitement spécifique pour les noms et prénoms surtout s'ils sont composés :
            // PS: on considère toujours qu'ils sont écrits dans l'ordre prénom puis nom
            //     sinon il faudrait encore multiplier le nombre de requêtes. Dans ce cas passer à un filtre back.
            const nameParts = searchValue.split(' ')
            nameParts.forEach((namePart, i) => {
                if (i === 0){
                    apiRequests.push(
                        resource.current.apiGetCollection({
                            page: 1,
                            rowsPerPage: 10000,
                            filters: { 'modelOwner.lastName[]': searchValue },
                            fields: properties,
                       })
                    )
                }
                if (i === nameParts.length - 1){
                    apiRequests.push(
                        resource.current.apiGetCollection({
                            page: 1,
                            rowsPerPage: 10000,
                            filters: { 'modelOwner.firstName[]': searchValue },
                            fields: properties,
                       })
                    )
                }
                if (i !== 0){
                    apiRequests.push(
                        resource.current.apiGetCollection({
                            page: 1,
                            rowsPerPage: 10000,
                            filters: { 'modelOwner.firstName[]': nameParts.slice(0,i).join(' '), 'modelOwner.lastName[]': nameParts.slice(i).join(' ') },
                            fields: properties,
                        })
                    )
                }
            })
        }
        // Si les données sont au mauvais format cela peut faire disparaître le filtre recherché,
        // donc on les ajoute que si les types sont bons.
        // 1. ne contient que des chiffres :
        if (isID(searchValue))
            apiRequests.push(
                resource.current.apiGetCollection({ page: 1, rowsPerPage: 10000, filters: { modelID: searchValue }, fields: properties, })
            );
        // 2. est une IRI vers un user :
        if (searchValue.startsWith('/api/users/'))
            apiRequests.push(
                resource.current.apiGetCollection({ page: 1, rowsPerPage: 10000, filters: { modelOwner: searchValue }, fields: properties, })
            );
        const responses = await Promise.all(apiRequests);
        const items = responses.reduce((p, c) => [...p, ...c], []); // responses est un tableau de tableaux
        if (filters) { /** @todo attention si les filters ont besoin de + de champs que les "fields", utiliser la prop additionalProperties */
            return resource.current.filter(items, filters, entity);
        }
        return items;
    };

    const openModelQuickSelectModal = () => {
        ModalComplementary.open({
            title: 'Search models',
            content: (
                <QuickSelectForm
                    //label={label}
                    values={values}
                    columns={columns}
                    onChange={onChange}
                    multi={values && values.length > 1 ? true : multi}
                    resource={resource.current}
                    resourceName="model"
                    apiSearch={apiSearchModel}
                    clearable={clearable}
                    withModal={withModal}
                    placeholder="Search by ID, Initial ID, Functional ID, model owner or name."
                />
            ),
        });
    };

    return (
        <>
            {withModal && (
                <Button
                    variant="contained"
                    color="primary"
                    style={styles.green}
                    disabled={!loaded}
                    onClick={openModelQuickSelectModal}
                >
                    {loaded ? 'Select' : <LoadingIndicator styles={styles.loadingIndicator} />}
                </Button>
            )}
            {!withModal && !loaded && <LoadingIndicator styles={styles.loadingIndicator} />}
            {!withModal && loaded && (
                <QuickSelectForm
                    //label={label}
                    values={values}
                    columns={columns}
                    onChange={onChange}
                    multi={values && values.length > 1 ? true : multi}
                    resource={resource.current}
                    resourceName="model"
                    apiSearch={apiSearchModel}
                    clearable={clearable}
                    withModal={withModal}
                    placeholder="Search by ID, Initial ID, Functional ID, model owner or name."
                />
            )}
        </>
    );
};
ModelQuickSelect.propTypes = {
    label: PropTypes.string,
    /** Liste d'iri */
    values: PropTypes.arrayOf(PropTypes.string),
    entity: PropTypes.object,
    multi: PropTypes.bool,
    endpoints: PropTypes.object,
    clearable: PropTypes.bool,
    /** Affiche un bouton pour ouvrir la modal (default), ou directement le form */
    withModal: PropTypes.bool,
    /** Filters est de la forme "predicate", cf APIResource */
    filters: PropTypes.any,
    /** Exécutée lors du choix d'un ou plusieurs model.s */
    onChange: PropTypes.func,
    /** Pour créer la resource API correspondante */
    resourceId: PropTypes.string,
    instanceId: PropTypes.string,
    /** Contexte pour déterminer si on doit attendre le chargement de l'entité avant activation */
    context: PropTypes.oneOf(['edit', 'add']),
    /** Champs supplémentaires à récupérer pour les filtres, par défaut on ne récupère que id, modelID, modelOwner, initialID, name, functionalId */
    additionalProperties: PropTypes.arrayOf(PropTypes.string),
};

const styles = {
    green: {
        backgroundColor: '#0dbbb7',
        alignSelf: 'flex-end',
        marginLeft: '10px',
        maxHeight: '32px',
    },
    loadingIndicator: {
        display: 'flex',
        transition: 'color 150ms',
        alignSelf: 'center',
        fontSize: '0.5rem',
        margin: '0.5rem',
        textAlign: 'center',
    },
};
