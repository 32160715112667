import React from 'react';
import { Select } from '../../../Components/Forms/Select/Select';
import { MappedValueDisplay } from '../../../Components/Display/MappedValueDisplay/MappedValueDisplay';

class MappedValueProvider {
    getMapping = (field, entity) => {
        let mapping = field.params && field.params.mapping ? field.params.mapping : {};
        /**
             * Les lignes suivantes partaient d'une bonne intention : utiliser les propriétés publiques
             * des classes pour remplir les mapping, mais comme elles ne sont pas statiques elles ne sont
             * pas remplies par défaut, donc à la création d'une nouvelle entité on n'y a pas encore
             * accès ... Raté ...
        let mapping = field.params.mapping    ||
            (field.params.mappingFieldName &&
            entity &&
            entity[field.params.mappingFieldName]
                ? entity[field.params.mappingFieldName]
                : {});
        */
        return mapping;
    };

    getDisplay(field, value, entity, props, resource) {
        return (
            <MappedValueDisplay
                {...props}
                fieldName={field.title}
                value={value}
                map={this.getMapping(field, entity)}
                showFieldset={true}
            />
        );
    }

    getDisplayList(field, value, entity, props, resource) {
        return value !== '' && value !== null ? this.getMapping(field, entity)[value] : '';
    }

    getEdit(field, value, onChange, entity, routeParams, loading = false) {
        let preparedValue = [];
        if (value !== null && value !== undefined) {
            if (!Array.isArray(value)) {
                value = [value];
            }
            preparedValue = value.map((v) => ({
                value: parseInt(v),
                label: this.getMapping(field, entity)[v],
            }));
        }
        return (
            <div className={field.errorHelperText ? 'field-error-control' : ''}>
                <Select
                    label={field.title}
                    options={Object.keys(this.getMapping(field, entity)).map((key) => {
                        return {
                            value: parseInt(key),
                            label: this.getMapping(field, entity)[key],
                        };
                    })}
                    value={field.params.multi ? preparedValue : preparedValue[0]}
                    onChange={(item) => {
                        onChange(
                            item
                                ? field.params.multi
                                    ? item.map((i) => parseInt(i.value))
                                    : parseInt(item.value)
                                : null
                        );
                    }}
                    isMulti={field.params.multi}
                    isClearable={field.params.nullable}
                    required={field.requiredComputed}
                    error={!!field.errorHelperText}
                    className={'entity-select '}
                    helperText={field.errorHelperText}
                    //disabled={loading}
                />
            </div>
        );
    }

    getFilter(options = {}) {
        const { field, value, entity, onChange } = options;
        return (
            <Select
                options={Object.keys(this.getMapping(field, entity)).map((key) => {
                    return {
                        value: key,
                        label: this.getMapping(field, entity)[key],
                    };
                })}
                onChange={onChange}
                isClearable={value}
                isMulti={field.params.multi}
                value={
                    value
                        ? {
                              value: value,
                              label: this.getMapping(field, entity)[value],
                          }
                        : null
                }
            />
        );
    }
}

export default new MappedValueProvider();
