import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { IconButton, Button, Menu, MenuItem, FormControl, TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import String from '../../../Services/String';
import Chip from '@material-ui/core/Chip';
import CheckIcon from '@material-ui/icons/Check';
//import ClearIcon from "@material-ui/icons/Clear";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DoneIcon from '@material-ui/core/SvgIcon/SvgIcon';
import TableBody from '@material-ui/core/TableBody';
import Alert from '../../../Services/Alert';
import Modal from '../../../Services/Modal';
import Http from '../../../Services/Http';
import { ChangeLogForm } from '../../Display/ChangeLog/ChangeLog';
import { APIResource } from '../../../Services/APIResource/APIResource';
import {userHasRole, userHasSpecificRole} from '../../../Store/ParameterStore';
import { VerifiedMenu } from '../../../Components/Display/ChangeLog/ChangeLog';
import User, {ROLE as Role, ROLE} from '../../../Services/User/User';

const VerifiedDisplayComponent = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (state) => {
        props.onChange(state);
        setAnchorEl(null);
    };

    const renderIcon = () => {
        switch (props.value) {
            case true:
            case 1:
                return (
                    <Tooltip title="Verified" arrow placement="right">
                        <IconButton onClick={handleClick}>
                            <CheckIcon className="text-success" />
                        </IconButton>
                    </Tooltip>
                );
            // case false:
            // case 0:
            //   return (
            //     <Tooltip title="Rejected" arrow placement="right">
            //       <IconButton onClick={handleClick}>
            //         <ClearIcon className="text-danger" />
            //       </IconButton>
            //     </Tooltip>
            //   );
            default:
                return (
                    <Tooltip title="Pending" arrow placement="right">
                        <IconButton onClick={handleClick}>
                            <HourglassEmptyIcon />
                        </IconButton>
                    </Tooltip>
                );
        }
    };
    return (
        <div>
            {renderIcon()}
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={!props.readonly && Boolean(anchorEl)}
                onClose={() => handleClose(props.value)}
            >
                <MenuItem onClick={() => handleClose(true)}>
                    <CheckIcon className="text-success" />
                </MenuItem>
                <MenuItem onClick={() => handleClose(null)}>
                    <HourglassEmptyIcon />
                </MenuItem>
            </Menu>
        </div>
    );
};

class VersionsDetail extends Component {
    constructor(props) {
        super(props);
        this.showChanges = this.showChanges.bind(this);
        this.clone = this.clone.bind(this);
        this.resourceChangeLog = new APIResource({ id: 'change_logs' });
        if (this.props.entityResource) {
            this.entityResource = new APIResource({
                id: this.props.entityResource,
            });
        }
        this.state = {
            changes: null,
            tbody: null,
        };
        this.filter = null;
    }

    componentDidMount() {
        this.valueInit();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.model !== prevProps.model) {
            this.valueInit();
        }
    }

    valueInit() {
        if (Array.isArray(this.props.model.versionsSummary)) {
            this.setState({ changes: this.props.model.versionsSummary }, () => this.showChanges());
        } else {
            this.setState({ changes: [] }, () => this.showChanges());
        }
    }

    handleVerifiedIconClick(state, change) {
        if (state === change.verified) {
            return;
        }

        change.verified = state;
        if (state !== false && state !== 0) {
            return this.changeVerification(change.id, state);
        }
        Modal.open({
            title: 'Edit comment',
            content: (
                <ChangeLogForm
                    changeLog={change}
                    onSave={(changeLog) => {
                        this.onSave(changeLog)
                            .then(() => {
                                changeLog.verified = state;
                                return this.changeVerification(change.id, state);
                            })
                            .catch(console.warn);
                    }}
                />
            ),
        });
    }

    changeVerification(id, status) {
        return Http.post(`models-verify/${id}`, { status }).then(() => {
            for (let i in this.props.model.versionsSummary) {
                if (this.props.model.versionsSummary[i].id === id) {
                    this.props.model.versionsSummary[i].verified = status;
                }
            }
            this.setState({ changes: this.props.model.versionsSummary }, () => this.showChanges());
        });
    }

    editComment(changeLog) {
        Modal.open({
            title: 'Edit comment',
            content: <ChangeLogForm changeLog={changeLog} onSave={(changeLog) => this.onSave(changeLog)} />,
        });
    }

    updateEntity(id = null) {
        if (this.entityResource) {
            //Force updating parent from API when changing Documents collection are affecting parent's properties
            this.entityResource.getItemFromResourcePath(this.props.model['@id'], true).then((entity) => {
                this.setState({ changes: entity.versionsSummary });
            });
        }
    }

    onSave(version) {
        let promise;
        if (version.changeLogId) {
            promise = this.resourceChangeLog.apiPut({
                id: version.changeLogId,
                comment: version.comment,
            });
        } else {
            promise = this.resourceChangeLog.apiPost({
                modelId: '/api/models/' + version.id,
                comment: version.comment,
            });
        }

        return promise.then((entity) => {
            Alert.show({ message: 'Change log has been updated' });
            this.updateEntity();
            Modal.close();
        });
    }

    thead() {
        let tds_head = [];
        tds_head.push(
            <TableCell style={styles.theader} key={'date_header'}>
                Date
            </TableCell>
        );
        tds_head.push(
            <TableCell style={styles.theader} key={'user'}>
                User
            </TableCell>
        );
        tds_head.push(
            <TableCell style={styles.theader} width={500} key={'change_description_header'}>
                Changelogs
            </TableCell>
        );
        tds_head.push(
            <TableCell style={styles.theader} width={300} key={'comment_header'}>
                Comment
            </TableCell>
        );
        tds_head.push(
            <TableCell style={styles.theader} key={'verified'}>
                Verified
            </TableCell>
        );
        tds_head.push(
            <TableCell style={styles.theader} key={'action_header'} className="actions">
                Actions
            </TableCell>
        );
        return (
            <TableHead>
                <TableRow>{tds_head}</TableRow>
            </TableHead>
        );
    }

    showChanges(filter = null) {
        if (filter === null && this.filter) {
            filter = this.filter;
        } else {
            this.filter = filter;
        }

        let trs = [];
        let changes = this.state.changes;

        if (changes) {
            for (let i in changes) {
                if (
                    filter !== null &&
                    (!changes[i].changeLog || !changes[i].changeLog.match(new RegExp(filter, 'gi'))) &&
                    (!changes[i].author || !changes[i].author.match(new RegExp(filter, 'gi'))) &&
                    (!changes[i].date || !changes[i].date.match(new RegExp(filter, 'gi'))) &&
                    (!changes[i].comment || !changes[i].comment.match(new RegExp(filter, 'gi')))
                ) {
                    continue;
                }
                let changeDescription = String.nlToBr(changes[i].changeLog);
                let editComment = null;
                if (changes[i].initial) {
                    changeDescription = (
                        <Chip
                            label="Initial version"
                            deleteIcon={<DoneIcon />}
                            className="chip-button-table button-initial-version"
                        />
                    );
                } else if (changes[i].changeLogId && !(changes[i].verified && !User.hasOneRole('ROLE_MRM')) && !userHasSpecificRole(Role.IG)) {
                    editComment = (
                        <Button
                            variant="contained"
                            color="primary"
                            style={styles.button}
                            className="button-table button-show-version"
                            onClick={() => this.editComment(changes[i])}
                        >
                            Edit comment
                        </Button>
                    );
                }

                trs.push(
                    <TableRow key={i}>
                        <TableCell key={i + '-date'}>{changes[i].date}</TableCell>
                        <TableCell key={i + '-user'}>{changes[i].author}</TableCell>
                        <TableCell key={i + '-change'}>{changeDescription}</TableCell>
                        <TableCell key={i + '-comment'}>{String.nlToBr(changes[i].comment)}</TableCell>
                        <TableCell key={i + '--verified'}>
                            {<VerifiedMenu value={changes[i].verified} changelogId={changes[i].changelogId} />}
                        </TableCell>
                        <TableCell key={i + '-action'}>
                            {changes[i].relatedEntity ? (
                                <div>
                                    {editComment}
                                    <Link
                                        to={
                                            '/resource/' +
                                            changes[i].relatedEntityResourceType +
                                            '/' +
                                            changes[i].id +
                                            '/detail'
                                        }
                                        target="_blank"
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={styles.button}
                                            className="button-table button-show-version"
                                        >
                                            Show {changes[i].relatedEntityDisplayName}
                                        </Button>
                                    </Link>
                                </div>
                            ) : changes[i].activeVersion ? (
                                <div>
                                    {editComment}
                                    <Chip
                                        label="Current version"
                                        deleteIcon={<DoneIcon />}
                                        className="chip-button-table button-current-version"
                                    />
                                </div>
                            ) : (
                                <div>
                                    {editComment}
                                    {!changes[i].related && !changes[i].disableShowVersion ? (
                                        <Link to={'/resource/models/' + changes[i].id + '/detail'} target="_blank">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={styles.button}
                                                className="button-table button-show-version"
                                            >
                                                Show version
                                            </Button>
                                        </Link>
                                    ) : null}
                                    {!changes[i].related &&
                                    !changes[i].disableRevert &&
                                    (User.hasOneRole(ROLE.MRM) || User.hasOneRole(ROLE.ADMIN)) ? (
                                        <Button
                                            id={'btn_clone_' + i}
                                            variant="contained"
                                            color="primary"
                                            //onClick={this.clone.bind(this, changes[i].id)}
                                            onClick={() => {
                                                Modal.open({
                                                    title: 'Reversal confirmation',
                                                    content: (
                                                        <RevertModal clone={this.clone} versionId={changes[i].id} />
                                                    ),
                                                });
                                            }}
                                            className="button-table button-revert"
                                        >
                                            Revert
                                        </Button>
                                    ) : null}
                                </div>
                            )}
                        </TableCell>
                    </TableRow>
                );
            }
        }

        this.setState({ tbody: <TableBody>{trs}</TableBody> });
    }

    clone(id) {
        this.props.resource.revertItemVersion(id).then((entity) => {
            Alert.show({ message: 'Model version successfully reverted!' });
        });
    }

    render() {
        return (
            <div className="container">
                <Paper>
                    <FormControl className={'td_filter_control'}>
                        <TextField
                            className={'td_filter_input'}
                            placeholder="Filter"
                            onChange={(event) => this.showChanges(event.target.value || '')}
                        />
                    </FormControl>
                    <Table>
                        {this.thead()}
                        {this.state.tbody}
                    </Table>
                </Paper>
            </div>
        );
    }
}

class RevertModal extends Component {
    render() {
        return (
            <div>
                <p>Do you confirm the reversal ? All the updates made afterwards will be overwritten.</p>
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Button
                        style={{ marginTop: 10 }}
                        variant="contained"
                        color="secondary"
                        className="save button-general"
                        onClick={Modal.close}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{ marginTop: 10 }}
                        variant="contained"
                        color="secondary"
                        className="save button-general"
                        onClick={() => {
                            this.props.clone(this.props.versionId);
                            Modal.close();
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        );
    }
}

export default observer(VersionsDetail);

const styles = {
    blockHeightStyle: {
        paddingBottom: 5,
        marginBottom: 35,
    },
    gridPaper: {
        marginTop: 15,
        marginBottom: 15,
        height: '100%',
    },
    button: {
        marginRight: 10,
    },
};
