import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Modal from "../../Services/Modal";
import {APIResource} from "../../Services/APIResource/APIResource";
import Alert from "../../Services/Alert";
import {CircularProgress} from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import Navigation from "../../Services/Navigation";
import {DatePicker} from "../Forms/DatePicker/DatePicker";

export class ReviewRequestModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            comment: null,
            plannedDocumentationDeliveryDate: null

        };
        this.resource = new APIResource({ id: 'reviews', instanceId: 'reviews_request' });
    }

    getTitle = () => {
        if (this.props.entity) {
            return `You are about to request a review for model ${this.props.entity.functionalID}.`;
        }
    };

    handleClick() {
        if(!this.state.comment){
            Alert.show({ message: "Your review request required the context.", type: "error" });
            return;
        }
        if(!this.state.plannedDocumentationDeliveryDate){
            Alert.show({ message: "Please fill the planned documentation delivery date.", type: "error" });
            return;
        }

        this.setState({isLoading: true});
        this.resource.apiPost({
            models: ['api/models/' + this.props.entity.id],
            request: true,
            validatorTeams: this.props.entity.modelValidatorTeams,
            requestComment: this.state.comment,
            plannedDocumentationDeliveryDate: this.state.plannedDocumentationDeliveryDate,
            plannedDocumentationDeliveryDateApprovedByLod1: true,
            plannedDocumentationDeliveryDateApprovedByLod2: false,
        }).then((entity) => {
            Alert.show({ message: "Your review request was sent to this model's validator team. Optionally, you can add documents.", type: "success" });
            this.props.modelResource.apiGetCollection({
                page: 1,
                rowsPerPage: 10000,
                forceReload: true
            }).then(() => {
                this.setState({isLoading: false});
                Modal.close();
                Navigation.router.history.push(
                    `/resource/reviews/${entity.id}/detail?tab=Documents`
                );
            }).catch(() => {
                this.setState({isLoading: false});
                Modal.close();
            });
        }).catch(() => {
            this.setState({isLoading: false});
            Modal.close();
        });
    }

    render() {
        return (
            <div className={"request-review"}>
                <p>{this.getTitle()}</p>
                <FormControl className="container_textarea">
                    <label>Please communicate the context of your review request *</label>
                    <TextareaAutosize
                        value={this.state.comment ? this.state.comment : ""}
                        onChange={(event) => this.setState({ comment: event.target.value })}
                        rows={5}
                        rowsMax={10}
                        required={true}
                    />
                </FormControl>
                <DatePicker
                    label={'Planned documentation delivery date'}
                    value={this.state.plannedDocumentationDeliveryDate}
                    onChange={(event) => this.setState({ plannedDocumentationDeliveryDate: event })}
                    required={true}
                />
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}
                >
                    <Button
                        style={{ marginTop: 10 }}
                        variant="contained"
                        color="secondary"
                        className="save button-general"
                        onClick={Modal.close}
                    >
                        Cancel
                    </Button>
                    {
                        this.state.isLoading === true
                        ?
                            <Button
                                style={{ marginTop: 10 }}
                                variant="contained"
                                color="secondary"
                                className="save button-general"
                            >
                                <CircularProgress size={20} />
                            </Button>
                        :
                        <Button
                            style={{ marginTop: 10 }}
                            variant="contained"
                            color="secondary"
                            className="save button-general"
                            onClick={() => this.handleClick()}
                        >
                            Request a review
                        </Button>
                    }
                </div>
            </div>
        );
    }
}
