import React from 'react';
import { DisplayTextField } from "../../../Components/Display/DisplayTextField/DisplayTextField";
import TextField from '@material-ui/core/TextField';
import {CONTEXT_LIST} from "../APIResource";

class TextProvider {
  getDisplay(field, value, entity, props, resource, context) {
    return (
      <DisplayTextField
        {...props}
        fieldName={field.title}
        value={value}
        context={context}
      />
    );
  }

  getDisplayList(field, value, entity, props, resource) {
    return this.getDisplay(field, value, entity, props, resource, CONTEXT_LIST);
  }

  getEdit(field, value, onChange, entity, routeParams, loading = false) {
    /** 
     * Comme le composant Text n'est plus contrôlé, defaultValue ne déclenche pas le rerender quand
     * les données du champs changent (il les gère dans son propre state), or si la valeur initiale
     * n'est pas chargée, on doit forcer le re-render au moment où elle arrive.
     * Plus précisément il faut rerender l'Input inclus dans le TextField, donc la mise à jour de la
     * plupart des props du TextField n'auront pas d'effet sur le rerender de l'Input.
     * Donc le plus simple est de changer la key du TextField, qui sera détruit et remonté.
     */
    const key = loading ? `text_preload_${field.id}` : `text_${field.id}`;
    return (
      <TextField
        key={key}
        //disabled={loading}
        label={field.title}
        onChange={event => {
          onChange(event.target.value);
        }}
        defaultValue={value ? value : ''}
        inputRef={field.ref}
        onBlur={field.onBlur}
        onFocus={field.onFocus}
        required={field.requiredComputed !== undefined ? field.requiredComputed : field.required}
        error={!!field.errorHelperText}

      // helperText={field.errorHelperText}
      //  placeholder={field.errorHelperText}
      />
    );
  }

  getFilter(options = {}) {
    const { field, value, onChange } = options;
    return (
      <TextField
        id={"filter-" + field.id}
        placeholder="Filter"
        value={value ? value : ""}
        onChange={event => {
          onChange(event.target.value);
        }}
        className="input-text-field-table"
      />
    );
  }
}

export default new TextProvider();