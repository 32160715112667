import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Http from "../../Services/Http";
import Alert from "../../Services/Alert";
import EntityProvider from "../../Services/APIResource/FieldProviders/EntityProvider";
import ParameterStore, {userHasRoleMRM, userHasValidatorRights} from "../../Store/ParameterStore";
import User from "../../Services/User/User";
import APIResourceStore from "../../Store/APIResourceStore";
import Tooltip from '@material-ui/core/Tooltip';
import {OpenModal} from "../Modal/OpenModal";

const ReviewIssueRelation = (props) => {

    const { review, model, entity, value, fieldDefinition, type, resource, resourcePath, params, inModal } = props;
    const [processing, setProcessing] = useState(false);

    const removeAssociation = (findingPath, noticePath) => {
        setProcessing(true)
        Http
            .post(
                'reviews/disassociate-finding-notice',
                {findingPath: findingPath, noticePath: noticePath}
            )
            .then((_res) => {
                Alert.show({
                    message: 'Your findings and notices are successfully dissociated.',
                    type: 'success',
                })
                setProcessing(false)
                let parentEntityId = null;
                if(review !== undefined){
                    parentEntityId = review.id
                }
                if(model !== undefined){
                    parentEntityId = model.id
                }
                if(parentEntityId !== null){
                    resource.getItemFromResourcePath(`api/${resourcePath}/${parentEntityId}`, true)
                }
            })
            .catch((_err) => {
                setProcessing(false)
            })
        ;
    }

    const listEntities = (entities) => {
        let list = [];
        let paths = [...entities];
        paths.forEach(
            (path, index) => {
                let relationEntity = APIResourceStore.resources['resource_' + type + 's_reviews'].getObservableItemByPath(path);
                if (
                    (
                        type === 'finding'
                        && (
                            (relationEntity.status === ParameterStore('FINDING_STATUS_DRAFT') && (User.profile.isMemberOfValidatorTeam || userHasRoleMRM()))
                            || relationEntity.status !== ParameterStore('FINDING_STATUS_DRAFT')
                        )
                        && (
                            relationEntity.status !== ParameterStore('FINDING_STATUS_DELETED')
                        )
                    )
                    || (
                        type === 'notice'
                        && (
                            (relationEntity.status === ParameterStore('NOTICE_STATUS_DRAFT') && (User.profile.isMemberOfValidatorTeam || userHasRoleMRM()))
                            || relationEntity.status !== ParameterStore('NOTICE_STATUS_DRAFT')
                        )
                    )
                ) {
                    list.push(
                        <Tooltip
                            title={(type === 'notice' && relationEntity.noticeDescription) || (type === 'finding' && relationEntity.weaknessesDescription) || ''}
                            arrow
                            key={`tooltip-associate-${index}`}
                        >
                            <div
                                key={'associate-remove-' + type + '-' + index}
                            >
                                {EntityProvider.getDisplay(fieldDefinition, path, entity, {flat: true, showLoading: true, modalLinks: inModal})}
                                { (userHasValidatorRights(User.getId(), review ?? model) || userHasRoleMRM())
                                && (
                                    (
                                        review
                                        && review.reviewStatus === ParameterStore('REVIEW_STATUS_FINALIZATION')
                                    ) || model
                                )
                                    ?
                                    <Button
                                        color="secondary"
                                        className={"tooltip"}
                                        onClick={() =>
                                            type === 'finding' ?
                                                removeAssociation(path, 'api/notices/' + entity.id)
                                                : removeAssociation('api/findings/' + entity.id, path)
                                        }
                                        disabled={processing}
                                    >
                                        <i className="fa fa-unlink"></i>
                                        <span className="tooltiptext">Dissociate</span>
                                    </Button>
                                    : null }
                            </div>
                        </Tooltip>
                    )
                }
            }
        );
        return <div>{list.length ? list : '-'}</div>
    }

    return (
        <Grid container spacing={2} className="container">
            <Grid item xs={12}>
                {listEntities(value)}
            </Grid>
        </Grid>
    );
}

export default ReviewIssueRelation;