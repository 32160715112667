import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../Services/Modal";
import { ButtonColumn } from "../../Modal/ButtonColumn";
import { NonModelRejectForm } from "../NonModelRejectForm/NonModelRejectForm";
import ModelRetirementModal from "../../Model/Retire/ModelRetirementModal";
import { ActionButton } from "../../Modal/ActionButton";
import { ModelLogicalDeleteForm } from "../../Model/Delete/ModelLogicalDeleteForm";
import {ConvertSpecificFrameworkForm} from "../ConvertSpecificFrameworkForm/ConvertSpecificFrameworkForm";

export const REMOVAL_ACTION_RETIRE = 'retire';
export const REMOVAL_ACTION_DELETE = 'delete';
export const REMOVAL_ACTION_REJECT = 'reject';
export const REMOVAL_ACTION_CONVERT = 'convert';

const ModelRemovalModal = (props) => {
    const { displayButtons, entity, entityType, resource } = props;
    const displayButtonsUnique = [...new Set(Object.keys(displayButtons))];

    let openRetireModal = () => {
        Modal.open({
            title:
                (displayButtons[REMOVAL_ACTION_RETIRE] && displayButtons[REMOVAL_ACTION_RETIRE].title) ||
                "Retiring model",
            content: <ModelRetirementModal {...props} />,
            modalStyle: { width: '700px' },
        });
    };

    let openDeleteModal = () => {
        Modal.open({
            title:
                (displayButtons[REMOVAL_ACTION_DELETE] && displayButtons[REMOVAL_ACTION_DELETE].title) ||
                "Deletion",
            content: <ModelLogicalDeleteForm entity={entity} resource={resource} entityType={entityType} />,
        });
    };

    let openRejectModal = () => {
        Modal.open({
            title:
                (displayButtons[REMOVAL_ACTION_REJECT] && displayButtons[REMOVAL_ACTION_REJECT].title) ||
                "Conversion to non-model",
            content: <NonModelRejectForm {...props} />,
        });
    };

    let openConvertModal = () => {
        Modal.open({
            title:
                (displayButtons[REMOVAL_ACTION_CONVERT] && displayButtons[REMOVAL_ACTION_CONVERT].title) ||
                "Conversion to specific framework",
            content: <ConvertSpecificFrameworkForm {...props} />,
        });
    };

    if (displayButtonsUnique.length === 1) {
        switch (displayButtonsUnique[0]) {
            case REMOVAL_ACTION_RETIRE:
                openRetireModal();
                break;
            case REMOVAL_ACTION_DELETE:
                openDeleteModal();
                break;
            case REMOVAL_ACTION_REJECT:
                openRejectModal();
                break;
            case REMOVAL_ACTION_CONVERT:
                openConvertModal();
                break;
            default:
                break;
        }
    }

    return (
        <div className={"declare-modal"}>
            <ButtonColumn>
                {displayButtonsUnique.includes(REMOVAL_ACTION_RETIRE) && (
                    <ActionButton onClick={openRetireModal}>
                        {(displayButtons[REMOVAL_ACTION_RETIRE] &&
                            displayButtons[REMOVAL_ACTION_RETIRE].label) ||
                            "Propose the retirement to LoD 1"}
                    </ActionButton>
                )}
                {displayButtonsUnique.includes(REMOVAL_ACTION_DELETE) && (
                    <ActionButton onClick={openDeleteModal}>
                        {(displayButtons[REMOVAL_ACTION_DELETE] &&
                            displayButtons[REMOVAL_ACTION_DELETE].label) ||
                            "Deletion"}
                    </ActionButton>
                )}
                {displayButtonsUnique.includes(REMOVAL_ACTION_REJECT) && (
                    <ActionButton onClick={openRejectModal}>
                        {(displayButtons[REMOVAL_ACTION_REJECT] &&
                            displayButtons[REMOVAL_ACTION_REJECT].label) ||
                            "Conversion to non-model"}
                    </ActionButton>
                )}
                {displayButtonsUnique.includes(REMOVAL_ACTION_CONVERT) && (
                    <ActionButton onClick={openConvertModal}>
                        {(displayButtons[REMOVAL_ACTION_CONVERT] &&
                            displayButtons[REMOVAL_ACTION_CONVERT].label) ||
                            "Conversion to specific framework"}
                    </ActionButton>
                )}
            </ButtonColumn>
        </div>
    );
};
ModelRemovalModal.propTypes = {
    entityType: PropTypes.string,
    entity: PropTypes.any,
    resource: PropTypes.any,
    resourceDetailComponent: PropTypes.any,
    /** */
    displayButtons: PropTypes.objectOf(
        PropTypes.shape({
            title: PropTypes.string,
            label: PropTypes.string,
        })
    ),
};

export default ModelRemovalModal;
