import React, { Component, useState } from 'react';
import { observer } from "mobx-react";
import Button from "@material-ui/core/Button";
import Alert from "../../Services/Alert";
import Http from "../../Services/Http";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    Input,
    FormControlLabel,
    Grid, Icon,
} from "@material-ui/core";
import Modal from "../../Services/Modal";
import Select from "../Forms/Select/Select";
import { DatePicker } from "../Forms/DatePicker/DatePicker";
import { APIResource } from '../../Services/APIResource/APIResource';
import { CustomReport } from "../Dashboard/Modals/CustomDefinitions/CustomReport"
import {userHasRoleMRM} from "../../Store/ParameterStore";

const widgetId = 'user-export-widget';

const PresetDialog = ({ onPresetNameChange, presetName, onClick, selectedPreset, onCancel }) => {

    const [loading, setLoading] = useState(false)

    return (
            <div>
                {loading &&
                    <Grid className={'export container'}>
                        <div className={"export_progress_container"}>
                            <CircularProgress />
                            <p>Please wait....</p>
                        </div>
                    </Grid>
                }
                {!loading &&
                    <React.Fragment>
                    <div style={{ fontSize: '20px',
                                marginTop: '1em',
                                marginBottom: '1em' }}>
                        What's the name of your report?
                    </div>
                    <div>
                        <Input
                            name='preset-name'
                            type='text'
                            onChange={onPresetNameChange}
                            defaultValue={presetName}
                        />
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: '10px' }}
                            onClick={() => onClick(setLoading)}
                        >
                            {selectedPreset ? 'Update' : 'Save'}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={onCancel}

                        >
                            Cancel
                    </Button>
                    </div>
                    </React.Fragment>
                }
            </div>
        )

}


const PresetDeleteDialog = ({ presetName, onClick, onCancel }) => {

    const [loading, setLoading] = useState(false)

    return (
            <div>
                {loading &&
                    <Grid className={'export container'}>
                        <div className={"export_progress_container"}>
                            <CircularProgress />
                            <p>Please wait....</p>
                        </div>
                    </Grid>
                }
                {!loading &&
                    <React.Fragment>
                        <div style={{ fontSize: '20px',
                                        marginTop: '1em',
                                        marginBottom: '1em' }}>
                            {`Do you want to delete the report named "${presetName}"`}
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ marginRight: '10px' }}
                                onClick={() => onClick(setLoading)}
                            >
                                DELETE
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={onCancel}

                            >
                                Cancel
                            </Button>
                        </div>
                    </React.Fragment>
                }
            </div>
        )

}


class Export extends Component {

    constructor(props) {
        super(props);

        this.state = {
            progress: false,
            modelsLoading: true,
            date: null,
            models: [],
            parameters: null,
            hasFilters: false,
            hasPresets: false,
            showPresetsDialog: true,
            presets: [],
            presetName: null,
            selectedPreset: null,
            running: undefined,
            exportAsk: false
        };

        this.widgetParametersResource =
            new APIResource({ id: 'dashboard/widget/parameters' });
        this.userSettingResource =
            new APIResource({ id: 'user_settings' });
        this.setParameters = this.setParameters.bind(this)
        this.getCustomExport = this.getCustomExport.bind(this)
        this.getWidgetParameters = this.getWidgetParameters.bind(this)
        this.getUserExportPresets = this.getUserExportPresets.bind(this)
        this.saveUserExportPresets = this.saveUserExportPresets.bind(this)
        this.updateUserExportPreset = this.updateUserExportPreset.bind(this)
        this.deleteUserExportPreset = this.deleteUserExportPreset.bind(this)
        this.openDialogPresets = this.openDialogPresets.bind(this)
        this.openDeleteConfirm = this.openDeleteConfirm.bind(this)
        this.onPresetChange = this.onPresetChange.bind(this)
        this.onPresetNameChange = this.onPresetNameChange.bind(this)

        this.getWidgetParameters();
        this.getUserExportPresets();
    }

    async componentDidMount(){
        let response = await Http.get('export/model/running') || {};
        if(response.status === 'OK'){
            this.setState({running: response.running, date: new Date()});
        }
        else {
            console.error("unexpected response from export/model/running", response);
        }
    }

    componentDidUpdate() {
        if (this.state.models.length > 0 && this.state.modelsLoading) {
            this.setState({ modelsLoading: false })
        }
    }

    onPresetNameChange(e) {
        this.setState({
            presetName: e.target.value
        })
    }

    onPresetChange(value) {
        if(!value) {
            this.setState({
                selectedPreset: null,
                presetName: null
            })
            return
        }

        const preset = this.state.presets.find(p => value.id === p.id)
        if(preset){
            this.setState({
                selectedPreset: preset,
                presetName: preset.value.name,
            })
            this.setParameters(preset.value.parameters)
        }
    }

    openDialogPresets() {
        Modal.open({
            title: this.state.selectedPreset ? 'Update current report' : 'Save filters as a new report',
            content: <PresetDialog onPresetNameChange={this.onPresetNameChange}
                                   presetName={this.state.presetName}
                                   onClick={async (setLoading) => {
                                       setLoading(true)
                                       if(this.state.selectedPreset) {
                                           await this.updateUserExportPreset()
                                       } else {
                                           await this.saveUserExportPresets()
                                       }
                                       setLoading(false)
                                       Modal.close()
                                   }}
                                   selectedPreset={this.state.selectedPreset}
                                   onCancel={() => {
                                       Modal.close()
                                   }} />
        })
    }

    openDeleteConfirm() {
        Modal.open({
            title: 'Delete current report',
            content: <PresetDeleteDialog presetName={this.state.presetName}
                                         onClick={async (setLoading) => {
                                             setLoading(true)
                                             await this.deleteUserExportPreset()
                                             setLoading(false)
                                             Modal.close()
                                         }}
                                         onCancel={() => {
                                             Modal.close()
                                         }} />
        })
    }

    setParameters(parameters) {
        let hasFilters = false
        if (parameters && parameters.models && parameters.models[0] && parameters.models[0].conditions.length > 0) {
            hasFilters = true
        }
        this.setState({ parameters, hasFilters })
    }

    getCustomExport() {
        this.setState({ progress: true });

        Http.post('dashboard/widget/export', { 
            id: widgetId, 
            parameters: this.state.parameters, 
            date: this.state.hasFilters ? new Date() : this.state.date, 
        }).then((response) => {
            if (response.status === 'ERROR') {
                Alert.show({ message: response.message, type: 'danger' });
            } else if (response.status === 'OK') {
                if (response.route && response.fileName) {
                    Http.openFile(response.route, response.fileName);
                }else if(response.documentId && response.documentName){
                    Http.openEncryptedFile(
                        "/document/" +
                        response.documentId,
                        response.documentName
                    )
                }else if(response.message){
                    Alert.show({ message : response.message, type: 'success'});
                    this.setState({running: true, exportAsk: true});
                }
            } else {
                Alert.show({ message: "File can't be downloaded" });
            }
            Modal.close();
            this.setState({ progress: false });
        });
    }

    getWidgetParameters = async () => {
        const widgetParameters = await Http.get(`dashboard/widget/parameters?id=${widgetId}`, { cache: false })
        if (widgetParameters) {
            const models = widgetParameters["hydra:member"].fields[0].props.models
            if (models && models.length > 0) {
                this.setState({ models })
            }
        }

    };

    getUserExportPresets = async () => {
        const presets = await this.userSettingResource.apiGetCollection({
            page: 1,
            rowsPerPage: 100,
            forceReload: true,
            filters: {
                type: 'preset-export',
            },
        });

        if (presets && presets.length > 0) {
            this.setState({
                hasPresets: true,
                presets,
            })
        } else {
            this.setState({
                hasPresets: false,
                presets,
            })

        }
    };

    saveUserExportPresets = async () => {
        const preset = {
            name: this.state.presetName,
            parameters: this.state.parameters
        }
        const newPreset = await this.userSettingResource.apiPost({
            code: `preset-export-${Date.now()}`,
            type: 'preset-export',
            value: preset,
        });
        Alert.show({ message: "Report saved successfully" });
        this.setState({
            presets: [
                // weird but necessary to avoid dups
                ...this.state.presets.filter(p => p.id !== newPreset.id),
                newPreset
            ],
            hasPresets: true,
            presetName: null,
        })
    };

    updateUserExportPreset = async () => {
        const presetContent = {
            name: this.state.presetName,
            parameters: this.state.parameters
        }
        const updatedPreset = await this.userSettingResource.apiPut({
            ...this.state.selectedPreset,
            value: presetContent,
        });
        Alert.show({ message: "Report saved successfully" });
        this.setState({
            presets: [
                ...this.state.presets.filter(p => p.id !== this.state.selectedPreset.id),
                updatedPreset
            ],
            selectedPreset: updatedPreset,
        })
    };


    deleteUserExportPreset = async () => {
        await this.userSettingResource.apiDelete(this.state.selectedPreset);
        const newPresets = this.state.presets.filter(p => p.id !== this.state.selectedPreset.id)
        Alert.show({ message: "The report has been deleted" });
        this.setState({
            presets: newPresets,
            selectedPreset: null,
            presetName: null,
            hasPresets: newPresets.length > 0
        })
    };

    save() {
        this.setState({ progress: true });
        Http.post('models/export', { date: this.state.date }).then((response) => {
            if (response.status === 'ERROR') {
                Alert.show({ message: response.message, type: 'danger' });
            } else if (response.status === 'OK') {
                if (response.route && response.fileName) {
                    Http.openFile(response.route, response.fileName);
                }else if(response.documentId && response.documentName){
                    Http.openEncryptedFile(
                        "/document/" +
                        response.documentId,
                        response.documentName
                    )
                }else if(response.message){
                    Alert.show({ message : response.message, type: 'success'});
                }
            } else {
                Alert.show({ message: "File can't be downloaded" });
            }
            this.setState({ progress: false });
        });
    }

    render() {
        if (this.state.modelsLoading) {
            return (
                <Grid className={'export container'}>
                    <div className={"export_progress_container"}>
                        <CircularProgress />
                        <p>Please wait....</p>
                    </div>
                </Grid>
            )
        }

        let selectPresetValue = null
        if(this.state.selectedPreset) {

            selectPresetValue = {
                ...this.state.selectedPreset,
                label: this.state.selectedPreset.value.name
            }

        }
        return (
            <Grid className={'export container'}>
                <h1>Model export</h1>
                {this.state.progress &&
                    <div className={"export_progress_container"}>
                        <CircularProgress />
                        <p>Please wait....</p>
                    </div>
                }

                <div className='export_progress_container'>
                    {this.state.running === undefined && <p>Checking if an export is already running</p>}
                    {this.state.running === true && <p><Icon class="fas fa-exclamation-triangle" /> Export in progress. You will be notified when it is available.</p>}
                </div>

                {!this.state.process && !this.state.running &&
                    <div>
                        <div>
                            {this.state.hasPresets &&
                                <div style={{ width: "33%", marginBottom: "20px" }}>
                                    <Select
                                        name="preset"
                                        label="Reports"
                                        options={this.state.presets.map(p => ({...p, label: p.value.name}))}
                                        onChange={this.onPresetChange}
                                        isClearable
                                        value={selectPresetValue}
                                    // formatOptionLabel={formatOptionLabel}
                                    ></Select>
                                </div>
                            }
                            {userHasRoleMRM() && !this.state.hasFilters ?
                            <div style={{ width: "33%", marginBottom: "20px" }}>
                                <DatePicker label={"Export inventory at its state on"} value={this.state.date} onChange={(value) => this.setState({ date: value })} minDate={new Date("2021-11-29")} />
                            </div>
                            : null}
                            <CustomReport
                                models={this.state.models}
                                setParameters={this.setParameters}
                                userSettings={this.state.selectedPreset}
                                toggleExtendedView={() => { }}
                                exportMode={true}
                                exportDatasource="model"
                            />
                            <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button variant="contained" color="secondary" className="button-general" onClick={this.getCustomExport}>
                                    Export
                                </Button>
                                {this.state.hasFilters &&
                                    <Button variant="text" color="primary" style={{ marginLeft: "15px", padding: "10px 1em" }} onClick={this.openDialogPresets}>
                                        {this.state.selectedPreset ? 'Update report' : 'Save as report'}
                                    </Button>
                                }
                                {this.state.selectedPreset &&
                                    <Button variant="text" color="danger" style={{ marginLeft: "15px", padding: "10px 1em" }} onClick={this.openDeleteConfirm}>
                                        Delete report
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                }
            </Grid>
        )
    }
}

export default observer(Export);
