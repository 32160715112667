export default [
    'ECBAnnexID',
    'ECBID',
    'applicationDomainLevel1',
    'applicationDomainLevel2',
    'applicationDomainLevel3',
    'automaticScoreFlag',
    'businessSponsor >> id',
    'businessSponsor >> teams >> id',
    'certifications >> certifierPosition',
    'certifications >> certifierTeam',
    'certifications >> date',
    'championModels >> functionalID',
    'championModels >> initialID',
    'championModels >> name',
    'championOrChallenger',
    'coreModel',
    'coreModelParent >> functionalID',
    'coreModelParent >> initialID',
    'coreModelParent >> name',
    'dataContactPointTeam >> id',
    'declarationDate',
    'defaultPortfolioFlag',
    'delegationCommittees >> date',
    'downstreamModels >> functionalID',
    'downstreamModels >> initialID',
    'downstreamModels >> name',
    'expectedStatus',
    'firstImplementationDate',
    'functionalID',
    'implementations >> ITSystem >> id',
    'implementations >> date',
    'implementations >> modelImplementerTeam >> id',
    'inHouseOrVendor',
    'initialID',
    'microRiskCategory',
    'microRiskSubCategory',
    'mitigationActions',
    'modelDeveloperTeam >> id',
    'modelNatureDetailedString',
    'modelNatureStandard',
    'modelObservability',
    'modelOwner >> companyPosition',
    'modelOwner >> id',
    'modelOwner >> teams >> id',
    'modelOwnerDelegation >> id',
    'modelOwnerDelegation >> teams >> id',
    'modelStatus',
    'modelStatusDeletionDate',
    'modelStatusRetirementDate',
    'modelType',
    'modelUses >> BL >> id',
    'modelUses >> OUs >> id',
    'modelUses >> applicationDate',
    'modelUses >> approvalType',
    'modelUses >> dateOfApproval',
    'modelUses >> detailedUse',
    'modelUses >> developmentAndUse',
    'modelUses >> legalEntities >> bfcCode',
    'modelUses >> legalEntities >> copernicCode',
    'modelUses >> legalEntities >> id',
    'modelUses >> microRiskCategory',
    'modelUses >> microRiskSubCategory',
    'modelUses >> modelUsers >> id',
    'modelUses >> reportingDate',
    'modelUses >> riskCategory',
    'modelUses >> use',
    'modelUses >> useFrequency',
    'modelUses >> useMateriality',
    'modelUses >> validationStatus',
    'modelValidatorTeams >> id',
    'mras >> date',
    'mras >> modelRiskAssessmentResult',
    'mras >> score',
    'mras >> status',
    'mrmTeams >> id',
    'name',
    'nextFullValidationDateProcedure',
    'nextPeriodicValidationDateProcedure',
    'nextScheduledValidationDate',
    'nextScheduledValidationType',
    'nonModelStatus',
    'notices >> breach',
    'notices >> date',
    'notices >> deadline',
    'notices >> deliveryDate',
    'notices >> effectiveClosingDate',
    'notices >> initialId',
    'notices >> issuanceDate',
    'notices >> noticeIssuer >> id',
    'notices >> noticeOwners >> id',
    'notices >> noticeSeverity',
    'notices >> progress',
    'notices >> status',
    'notices >> deliveryDate',
    'notices >> title',
    'notices >> noticeValidators >> id',
    'operationalRiskFlag',
    'productionRun',
    'retirementCommittee >> date',
    'retirementStatus',
    'reviewedModel',
    'reviews >> assignedValidator >> id',
    'reviews >> closingDate',
    'reviews >> contributors >> id',
    'reviews >> effectiveDocumentationDeliveryDate',
    'reviews >> effectiveStartingDate',
    'reviews >> formalApplicationDate',
    'reviews >> formalPreApplicationDate',
    'reviews >> independentImplementationTesting',
    'reviews >> onHold',
    'reviews >> plannedClosingDate',
    'reviews >> plannedDocumentationDeliveryDate',
    'reviews >> plannedStartingDate',
    'reviews >> request',
    'reviews >> reviewStatus',
    'reviews >> reviewType',
    'reviews >> scope',
    'reviews >> segment',
    'reviews >> title',
    'reviews >> validationStatus',
    'reviews >> reviewCommittees >> date',
    'reviews >> reviewCommittees >> type',
    'riskCategory',
    'specificFramework',
    'tierings >> complexityLevelFinal',
    'tierings >> externalImpactLevelFinal',
    'tierings >> materialityLevelFinal',
    'tierings >> materialityQuantifyingDateFinal',
    'tierings >> tierResultFinal',
    'tierings >> tieringCategory',
    'upstreamModels >> functionalID',
    'upstreamModels >> initialID',
    'upstreamModels >> name',
    'validationFamily >> name',
    'validatorsLocation',
    'vendor',
    'lastValidationType',
    'lastFullValidationDate',
    'lastPeriodicValidationDate',
    'validationStatus',
    'mras >> modelRiskAssessmentResult',
    // 'mras >> score',
];
