import React, { Component } from 'react';
import { observer } from "mobx-react";
import { EntitySelect } from "../EntitySelect/EntitySelect";
import { ParameterQuickAdd } from '../../Parameter/ParameterQuickAdd';
import User, { ROLE } from '../../../Services/User/User';

export const getPredicate = (params, resourceId, allowNotVerified) => {
    const defaultPredicates = params.type ? { type: params.type } : {};
    const paramFilters = params.filters || params.filter;
    const verifiedFilter = {verified: 1};
    const filters = Array.isArray(paramFilters) ? paramFilters : (paramFilters !== undefined ? [paramFilters] : []);
    // On limite la selection aux paramètres "verified", si allowNotVerified n'est pas à true
    // mais si non alors il ne faut pas limiter à "verified" car on peut avoir des params non vérifiés.
    // C'est le cas surtout pour les filtres de list dans DataTable.
    if (allowNotVerified !== true) 
        filters.push(verifiedFilter);

    defaultPredicates.id = (id, entity, item) => {
        // Get all the dependentFields for this specific resource
        let dependentFields = [].concat(...(item.dependentFields || [])
            .filter(v => v[resourceId])
            .map(v => {
                return Object.values(v)
            }));

        // dependentFields = JSON.parse(JSON.stringify(dependentFields));

        return !dependentFields.length || !entity
            || dependentFields.filter(d => {
                // if at least one is true (OR condition)
                return Object.entries(d).map(([k, values]) => {
                    if (!Array.isArray(values)) {
                        values = [values];
                    }
                    let comparedValues = entity[k] || '';
                    if (!Array.isArray(comparedValues)) {
                        comparedValues = [comparedValues];
                    }
                    try {return values.filter(v => comparedValues.filter(cV => {
                        return (cV['@id'] || cV).match(new RegExp('^'+v.replace(/^\^/, '').replace(/\$$/, '')+'$'))}).length
                    ).length
                    } catch (err) {
                        console.error('[error] entity is missing metadata', resourceId, id, k, values);
                    }
                })
                    // if all are true (AND condition)
                    .filter(v => !v).length === 0
            }).length
    }

    return filters.concat([defaultPredicates])
}

export const ParameterSelect = observer(class ParameterSelect extends Component {

    render() {
        const props = Object.assign({}, this.props || {});
        const field = props.field || {};
        const params = field.params || {};

        if (!('label' in props)) {
            props.label = field.title;
        }
        if (!('multi' in props)) {
            props.multi = params.multi;
        }
        if (!('clearable' in props)) {
            props.clearable = params.clearable;
        }

        /**
         * 
         * @nb désactivé pour des raisons de perf : c'est trop coûteux de faire une requête api à chaque
         * affichage du parameter select : on ne bénéficie pas du cache d'APIResource dans ce cas là.
         * Donc on retourne {} systématiquement.
         */
        const getApiFilters = () => {
            const apiFilters = {};
            if (props.allowNotVerified) {
                // Permet de récupérer les parameters utilisés par le champ donné de la resource donnée (ex: model_uses.use)
                apiFilters.allowNotVerifiedButUsedBy = `${field.resourceId}.${field.id}`;
            }
            /**
             * NB : ne pas filtrer sur le type ou sur verified pour ne pas bloquer le getPredicate sur les
             * dependentFields. On pourrait filtrer éventuellement uniquement pour l'affichage des filtres de 
             * liste, à voir ...
             */
            // else { apiFilters.verified = 1; }
            // if (params.type) apiFilters.type = params.type;
            
            // return apiFilters;
            return {};
        }

        if (props.issueButton && User.hasOneRole(ROLE.MRM)){
            props.issueButtonComponent = (
                <ParameterQuickAdd label={props.label} parameterType={params.type} onChange={props.onChange} />
            );
        }
        return (
            <EntitySelect
                resourceId="parameters"
                resourceLabel="label"
                filters={getPredicate(params, field.resourceId, props.allowNotVerified)}
                apiFilters={getApiFilters()}
                neededFields={['type','verified','dependent_fields','systemId']}
                {...props}
            />
        );
    }
});
