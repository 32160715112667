import React from 'react';
import { APIResource } from '../Services/APIResource/APIResource';
import { ROLE } from '../Services/User/User';
import { ChangeLog } from '../Components/Display/ChangeLog/ChangeLog';
import { BulkVerify } from '../Services/BulkActions/BulkVerify/BulkVerify';

export const ApiResourceDefaultParams = {
    id: 'b_ls',
    name: 'Business Line (BL)',
    icon: 'fa-unused',
    fieldForTitle: 'title',
};

export default class BLAdmin {
    constructor() {
        this.configure();
    }

    configure() {
        let resource = new APIResource(ApiResourceDefaultParams);
        resource
            .setFields({
                id: { title: 'ID' },
                code: { title: 'Code', type: 'text' },
                title: {
                    title: 'Title',
                    type: 'text',
                    helperTextDisplay: (entity) => {
                        if (entity.verified === false) {
                            return {
                                type: 'warning',
                                icon: 'fa-exclamation-triangle',
                                text: 'Unverified',
                            };
                        }
                    },
                },
                parentBL: {
                    title: 'Business Line Parent',
                    type: 'entity',
                    params: {
                        resource: 'b_ls',
                        displayField: 'title',
                        multi: false,
                        links: true,
                    },
                },
                verified: { title: 'Verified', type: 'bool' },
                outOfRepository: { title: 'Out of repository', type: 'bool' },
                changeLogsEntities: {
                    title: 'Audit trail',
                    display: (field, value, entity, props) => (
                        <ChangeLog
                            field={field}
                            values={value}
                            entity={entity}
                            entityResource={'b_ls'}
                            props={props}
                        />
                    ),
                    displayList: () => null,
                },

                //Additional fields without relation with BL
                changeLogComment: {
                    title: 'Justification of the data update',
                    type: 'textarea',
                    display: (field, value, entity, props) => null,
                    displayList: (field, value, entity, props) => null,
                    token: false,
                },
            })
            .setLayout({
                tabs: {
                    'Business Line': {
                        rows: [
                            {
                                panels: {
                                    Identification: {
                                        cols: 6,
                                        fields: ['id', 'code', 'title'],
                                    },
                                    Properties: {
                                        cols: 6,
                                        fields: [
                                            'parentBL',
                                            'verified',
                                            'outOfRepository',
                                        ],
                                    },
                                },
                            },
                        ],
                    },
                    'Audit trail': {
                        rows: [
                            {
                                panels: {
                                    'Audit trail': {
                                        cols: 12,
                                        fields: [
                                            'changeLogsEntities',
                                            'changeLogComment',
                                        ],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .genListView({
                fields: [
                    'code',
                    'title',
                    'parentBL',
                    'verified',
                    'outOfRepository',
                ],
                menuItem: { title: 'BLs' },
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .genInsertView({
                fields: [
                    'code',
                    'title',
                    'parentBL',
                    'verified',
                    'outOfRepository',
                ],
                menuItem: { title: 'Add' },
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .genEditView({
                fields: [
                    'code',
                    'title',
                    'parentBL',
                    'verified',
                    'outOfRepository',
                ],
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .genDetailView({
                fields: [
                    'id',
                    'code',
                    'title',
                    'parentBL',
                    'verified',
                    'outOfRepository',
                    'changeLogsEntities',
                    'changeLogComment',
                ],
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .allowDelete()
            .addBulkAction(BulkVerify, {
                resource,
                icon: 'check',
                resourceId: "b_ls",
            });
    }
}
