import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { File } from '../Forms/File/File';
import Alert from '../../Services/Alert';
import Http from '../../Services/Http';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import String from '../../Services/String';
import User from '../../Services/User/User';
import { ActionButton } from '../Modal/ActionButton';
import { ButtonColumn } from '../Modal/ButtonColumn';
import APIResourceStore from "../../Store/APIResourceStore";

export const EntityImportForm = (props) => {
    const { resource, label } = props;

    const [file, setFile] = useState(null); //File to upload
    //const [fileName, setFileName] = useState(null); //FileName to use on applying duplication progress
    const [progress, setProgress] = useState(false);
    const [saving, setSaving] = useState(false);
    const [response, setResponse] = useState(null);
    const [message, setMessage] = useState(null);
    const [messageStatus, setMessageStatus] = useState(null);

    /**
     * Reset les propriétés lorsqu'on passe en mode saving.
     */
    useEffect(() => {
        if (saving) setProgress(true), setMessage(null), setMessageStatus(null);
        else setProgress(false), setFile(null);
    }, [saving]);

    /**
     * Traitement de la réponse.
     */
    useEffect(() => {
        if (!response) return;
        if (response.status === 'ERROR') {
            setMessage(response.message), setMessageStatus('danger');
        } else if (response.status === 'OK') {
            let message = '';
            let added = {};
            let updated = {};

            if (Number.isInteger(response.added)){
                added[resource] = response.added;
            } else { // response.added est un objet, voir backend App\Controller\ApiImport
                added = {...added, ...response.added};
            }
            if (Number.isInteger(response.updated)){
                updated[resource] = response.updated;
            }
            else { // response.updated est un objet, voir backend App\Controller\ApiImport
                updated = {...updated, ...response.updated};
            }

            for(const [k, v] of Object.entries(added)){
                message += v > 0 ? `${message === '' ? '' : '\n'}${partialLabel(k, v)} successfully added.` : '';
            }
            for(const [k, v] of Object.entries(updated)){
                message += v > 0 ? `${message === '' ? '' : '\n'}${partialLabel(k, v)} successfully updated.` : '';
            }
            setMessage(message), setMessageStatus('success');
        }
    }, [response]);

    const partialLabel = (resource, nb) => `${nb} ${nb > 1 ? resource : resource.slice(0, -1)}`;

    const onDrop = (files, error) => {
        if (error === false && files.length === 1) setFile(files[0]);
    };

    const updateList = (resource) => {
        APIResourceStore.resources[resource].apiGetCollection()
    }

    const save = async () => {
        if (file === null) {
            Alert.show({ message: 'Please drop or select a file', type: 'danger' });
            return;
        }
        setSaving(true);
        let logoutInterval = setInterval(() => {
            User.lastActivity = new Date();
        }, 1000);
        try {
            setResponse(await Http.postFile(`import/${resource}`, { file }));
        } catch (error) {
            setMessage(null), setMessageStatus(null);
            Alert.show({ message: 'Something went wrong, please try again', type: 'danger' });
        } finally {
            updateList(resource);
            setSaving(false);
            clearInterval(logoutInterval);
        }
    };

    return (
        <Grid className={'import container'}>
            <h1 style={{ textTransform: 'capitalize' }}>{label || resource}</h1>
            {message && (
                <div className={`alert alert-${messageStatus || 'primary'}`} role="alert">
                    {String.nlToBr(message)}
                </div>
            )}
            <div className={`alert alert-warning`} role="alert">
                {String.nlToBr("Please do not close the window before the end of the import")}
            </div>
            <ButtonColumn>
                {progress || <File fileTypes={['xlsx']} callback={onDrop} maxFile={1} />}
                <ActionButton onClick={save} disabled={progress} style={{ alignSelf: 'center' }}>
                    <>
                        {progress ? 'Please wait....' : 'Import'}
                        {progress && <CircularProgress size={20} />}
                    </>
                </ActionButton>
            </ButtonColumn>
        </Grid>
    );
};
EntityImportForm.propTypes = {
    /** Type de l'entité importée, ex: findings */
    resource: PropTypes.string.isRequired,
    label: PropTypes.string,
};
