import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import Alert from "../../Services/Alert";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { APIResource } from "../../Services/APIResource/APIResource";
import Modal from "../../Services/Modal";
import Icon from "@material-ui/core/Icon";
import {CircularProgress} from "@material-ui/core";
import Http from "../../Services/Http";
import { consoleBuffer } from '../../Services/Debug';
import { getIdFromIri } from '../../Services/utils';
import User, { ROLE } from '../../Services/User/User';

export class IssueButton extends Component {

    constructor(props) {
        super(props);

        this.modalTitle = 'New request';
        this.issueTooltip = 'Report a missing value';
        this.issueTitle = 'Missing value on field ' + this.props.field;
        this.issueDescription = 'Detail about the missing value: ';
        this.issueText = 'Send request';
        this.issueType = null;
    }

    addIssue() {

        if(typeof this.props.issueButton === "object"){
            if(this.props.issueButton.modalTitle){
                this.modalTitle = this.props.issueButton.modalTitle;
            }
            if(this.props.issueButton.title){
                this.issueTitle = this.props.issueButton.title;
            }
            if(this.props.issueButton.description){
                this.issueDescription = this.props.issueButton.description;
            }
            if(this.props.issueButton.button){
                this.issueText = this.props.issueButton.button;
            }
            if(this.props.issueButton.type){
                this.issueType = this.props.issueButton.type;
            }
        }

        Modal.open({
            title: this.modalTitle,
            content: <IssueAddForm
                title={this.issueTitle}
                description={this.issueDescription}
                textButton={this.issueText}
                type={this.issueType || this.props.type}
                models={this.props.entity.models ?? ([this.props.entity.model ? (this.props.entity.model['@id'] || this.props.entity.model) : this.props.entity['@id']])}
            />
        });
    }

    render() {
        if(typeof this.props.issueButton === "object"){
            if(this.props.issueButton.tooltip){
                this.issueTooltip = this.props.issueButton.tooltip;
            }
        }
        // On désactive tous les boutons pour MRM qui n'a aucune raison de s'envoyer des tickets.
        if (User.hasOneRole(ROLE.MRM)) {
            return <></>;
        } else {
            return (
                <Button variant="contained" color="primary" onClick={() => this.addIssue()} className="tooltip">
                    <Icon className={'fa fa-headset'}></Icon>
                    <span className="tooltiptext">{this.issueTooltip}</span>
                </Button>
            );
        }
    }
}
IssueButton.propTypes = {
    issueButton: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    field: PropTypes.string,
    type: PropTypes.any,
    entity: PropTypes.object,
}

export class IssueAddForm extends Component {
    constructor(props) {
        super(props);
        this.resource = new APIResource({ id: 'issues' });
        this.modelResource = new APIResource({ id: 'models', systemId: 'issueModels' })
        this.state = {
            title: props.title ?? 'New Trouble',
            description: props.description ?? '',
            modelCertificationCampaign: props.modelCertificationCampaign,
            type: props.type,
            models: props.models,
            modelsFull: [],
            textButton: props.textButton ?? 'Send Trouble',
            isSaving: false
        };
    }

    componentDidUpdate(prevProps) {
        let newState = {};
        const checkProps = (prop) => {
            if (this.props[prop] && this.props[prop] !== prevProps[prop]) {
                newState[prop] = this.props[prop];
            }
        };
        ['models', 'modelCertificationCampaign', 'type'].forEach((prop) => checkProps(prop));

        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }
    }

    /**
     * pour debug :
     */
    componentDidMount() {
        this.getModelsFull();
    }

    getModelsFull(){
        this.state.models.map((iri) => {
            let id = getIdFromIri(iri);
            this.modelResource
                .apiGetOne(id)
                .then((model) => this.setState((prev) => ({ modelsFull: [...prev.modelsFull, model] })));
        });
    }

    printModelsFull() {
        if (this.state.modelsFull.length){
            return this.state.modelsFull.map(m => m.functionalID).join(',');
        }
        return "aucun";
    }
    /**
     * Fin debug
     */

    save() {
        if (!this.state.title || !this.state.description) {
            Alert.show({ message: 'Please fill title and description', type: 'danger' });
            return;
        }
        this.setState({isSaving: true});
        this.resource
            .apiPost({
                title: this.state.title,
                type: this.state.type,
                description: this.state.description + "\nModels :\nFunctionalIds : " + this.printModelsFull() + "\nIris : " + (this.state.models ? this.state.models.join(','): "aucun"),
                modelCertificationCampaign: this.state
                    .modelCertificationCampaign,
                models: this.state.models,
                debugUrl: window.location.href,
                debugConsole: consoleBuffer.join('\n'),
            })
            .then((response) => {
                Alert.show({
                    message: 'Your request has been sent successfully',
                    type: 'success',
                });
                Modal.close();
                if (this.props.onClose) {
                    this.props.onClose(response);
                }
                this.setState({isSaving: false});
            }).catch((error) => {
                this.setState({ isSaving: false });
                Alert.show({
                    message: `Your trouble cannot be saved.\n${Http.getErrorMessage(
                        error
                    )}`,
                    type: "success",
                });
            });
    }

    render() {
        return (
            <div className="issue-modal-add">
                <div className="fields">
                    <FormControl>
                        <TextField label="Title" onChange={(event) => this.setState({ title: event.target.value })} value={this.state.title ? this.state.title : ''} />
                    </FormControl>
                    <FormControl className="container_textarea">
                        <label>Description</label><TextareaAutosize value={this.state.description ? this.state.description : ''} onChange={(event) => this.setState({ description: event.target.value })} rows={5} rowsMax={10} />
                    </FormControl>
                </div>
                {this.state.isSaving === true ? (
                    <Button
                        style={{ marginTop: 10 }}
                        variant="contained"
                        color="secondary"
                        className="save button-general"
                    >
                        <CircularProgress size={20} />
                    </Button>
                ) : (
                <Button variant="contained" color="secondary" className="save button-general" onClick={this.save.bind(this)}>
                    {this.state.textButton}
                </Button>
                )}
            </div>
        );
    }
}

IssueAddForm.propTypes = {
    models: PropTypes.array,
    title: PropTypes.string,
    description:PropTypes.string,
    modelCertificationCampaign: PropTypes.object,
    /** @todo contrôler le PropType de type */
    type: PropTypes.any,
    textButton: PropTypes.string,
    onClose: PropTypes.func,
}
