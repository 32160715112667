import React from "react";
import Http from "../Http";
import {APIResource, CONTEXT_PRELOAD} from "./APIResource";
import ParameterAdmin, {ApiResourceDefaultParams as ParameterAPIResourceParams} from "../../Admin/ParameterAdmin";
import UserAdmin, {ApiResourceDefaultParams as UserAPIResourceParams} from '../../Admin/UserAdmin';
import ScopeAdmin, {ApiResourceDefaultParams as ScopeAPIResourceParams} from '../../Admin/ScopeAdmin';
import ReviewAdmin, {ApiResourceDefaultParams as ReviewAPIResourceParams} from '../../Admin/ReviewAdmin';
import ModelAdmin, {ApiResourceDefaultParams as ModelAPIResourceParams} from '../../Admin/ModelAdmin';
import NoticeAdmin, {ApiResourceDefaultParams as NoticeAPIResourceParams} from '../../Admin/NoticeAdmin';
import ReviewModelUseAdmin, {ApiResourceDefaultParams as ReviewModelUseAPIResourceParams} from '../../Admin/ReviewModelUseAdmin';
import ReviewCommitteeAdmin, {ApiResourceDefaultParams as ReviewCommitteeAPIResourceParams} from '../../Admin/ReviewCommitteeAdmin';
import CertificationAdmin, {ApiResourceDefaultParams as CertificationAPIResourceParams} from '../../Admin/CertificationAdmin';
import ModelUseAdmin, {ApiResourceDefaultParams as ModelUseAPIResourceParams} from '../../Admin/ModelUseAdmin';
import VariableAdmin, {ApiResourceDefaultParams as VariableAPIResourceParams} from '../../Admin/VariableAdmin';
import ImplementationAdmin, {ApiResourceDefaultParams as ImplementationAPIResourceParams} from '../../Admin/ImplementationAdmin';
import WorkflowAdmin, {ApiResourceDefaultParams as WorkflowAPIResourceParams} from '../../Admin/WorkflowAdmin';
import ActionAdmin, {ApiResourceDefaultParams as ActionAPIResourceParams} from '../../Admin/ActionAdmin';
import ProcessAdmin, {ApiResourceDefaultParams as ProcessAPIResourceParams} from '../../Admin/ProcessAdmin';
import RecommendationIggBceAdmin, {ApiResourceDefaultParams as RecommendationIggBceAPIResourceParams} from '../../Admin/RecommendationIggBceAdmin';
import ChangeLogAdmin, {ApiResourceDefaultParams as ChangeLogAPIResourceParams} from '../../Admin/ChangeLogAdmin';
import ModelCertificationCampaignAdmin, {ApiResourceDefaultParams as ModelCertificationCampaignAPIResourceParams} from '../../Admin/ModelCertificationCampaignAdmin';
import TieringAdmin, {ApiResourceDefaultParams as TieringAPIResourceParams} from '../../Admin/TieringAdmin';
import MitigationActionAdmin, {ApiResourceDefaultParams as MitigationActionAPIResourceParams} from '../../Admin/MitigationActionAdmin';
import LegalEntityAdmin, {ApiResourceDefaultParams as LegalEntityAPIResourceParams} from '../../Admin/LegalEntityAdmin';
import BLAdmin, {ApiResourceDefaultParams as BLAPIResourceParams} from '../../Admin/BLAdmin';
import OUAdmin, {ApiResourceDefaultParams as OUAPIResourceParams} from '../../Admin/OUAdmin';
import TroubleAdmin, {ApiResourceDefaultParams as TroubleAPIResourceParams} from '../../Admin/TroubleAdmin';
import ValidationFamilyAdmin, {ApiResourceDefaultParams as ValidationFamilyAPIResourceParams} from '../../Admin/ValidationFamilyAdmin';
import SystemVersionAdmin, {ApiResourceDefaultParams as SystemVersionAPIResourceParams} from '../../Admin/SystemVersionAdmin';
import ITSystemAdmin, {ApiResourceDefaultParams as ITSystemAPIResourceParams} from '../../Admin/ITSystemAdmin';
import NoticeActionAdmin, {ApiResourceDefaultParams as NoticeActionAPIResourceParams} from '../../Admin/NoticeActionAdmin';
import FindingAdmin, {ApiResourceDefaultParams as FindingAPIResourceParams} from '../../Admin/FindingAdmin';
import MraAdmin, {ApiResourceDefaultParams as MraAPIResourceParams} from '../../Admin/MraAdmin';
import NotificationAdmin, {ApiResourceDefaultParams as NotificationAPIResourceParams} from '../../Admin/NotificationAdmin';
import {firstLetterUppercase} from "./Utils";

/** @module APIResource */

const ENTITY_SELECT = 'ENTITY_SELECT';
const ENTITY_SELECT_FILTER = 'ENTITY_SELECT_FILTER';

/**
 * @class
 * @constructor
 */
export class APIResourceFactory {
    constructor(params) {
        this.init(params);
    }

    /**
     * Méthode d'initialisation de la Resource
     * @param {APIResourceParams} params
     */
    init(params) {
        this.endpoints = params.endpoints || null;
        this.resources = params.resources || null;
        this.resourcesVariantInstances = params.resourcesVariantInstances || null;
        this.otherResources = params.otherResources || null;
    }

    preload(callback) {
        Http.get(this.endpoints).then((result) => {
            if (this.resources !== null) {
                callback(result);
            }
        });
    }
}

const classes = {
    UserAdmin,
    ScopeAdmin,
    ReviewAdmin,
    ModelAdmin,
    ModelUseAdmin,
    NoticeAdmin,
    FindingAdmin,
    ValidationFamilyAdmin,
    ITSystemAdmin,
    LegalEntityAdmin,
    OUAdmin,
    BLAdmin,
    ParameterAdmin,
    TieringAdmin,
    NotificationAdmin,
    ReviewModelUseAdmin,
    ReviewCommitteeAdmin,
    CertificationAdmin,
    ImplementationAdmin,
    ChangeLogAdmin,
    ModelCertificationCampaignAdmin,
    MitigationActionAdmin,
    RecommendationIggBceAdmin,
    TroubleAdmin,
    SystemVersionAdmin,
    NoticeActionAdmin,
    MraAdmin,
    ProcessAdmin,
    WorkflowAdmin,
    ActionAdmin,
    VariableAdmin
};

let resourceFactory = new APIResourceFactory({
    endpoints: 'preload',
    resources: [
        {
            id: 'users',
            instanceId: 'users_all',
            classAdmin: 'UserAdmin',
            defaultParams: UserAPIResourceParams
        },
        {
            id: 'scopes',
            instanceId: 'scopes_all',
            classAdmin: 'ScopeAdmin',
            defaultParams: ScopeAPIResourceParams
        },
        {
            id: 'reviews',
            classAdmin: 'ReviewAdmin',
            defaultParams: ReviewAPIResourceParams
        },
        {
            id: 'model_uses',
            classAdmin: 'ModelUseAdmin',
            defaultParams: ModelUseAPIResourceParams
        },
        {
            id: 'models',
            instanceId: 'models',
            classAdmin: 'ModelAdmin',
            defaultParams: ModelAPIResourceParams
        },
        {
            id: 'notices',
            classAdmin: 'NoticeAdmin',
            defaultParams: NoticeAPIResourceParams
        },
        {
            id: 'findings',
            classAdmin: 'FindingAdmin',
            defaultParams: FindingAPIResourceParams
        },
        {
            id: 'validation_families',
            classAdmin: 'ValidationFamilyAdmin',
            defaultParams: ValidationFamilyAPIResourceParams
        },
        {
            id: 'i_t_systems',
            classAdmin: 'ITSystemAdmin',
            defaultParams: ITSystemAPIResourceParams
        },
        {
            id: 'legal_entities',
            classAdmin: 'LegalEntityAdmin',
            defaultParams: LegalEntityAPIResourceParams
        },
        {
            id: 'o_us',
            classAdmin: 'OUAdmin',
            defaultParams: OUAPIResourceParams
        },
        {
            id: 'b_ls',
            classAdmin: 'BLAdmin',
            defaultParams: BLAPIResourceParams
        },
        {
            id: 'parameters',
            classAdmin: 'ParameterAdmin',
            defaultParams: ParameterAPIResourceParams
        },
        {
            id: 'tierings',
            classAdmin: 'TieringAdmin',
            defaultParams: TieringAPIResourceParams
        }
    ],
    resourcesVariantInstances: [
        {
            id: 'scopes',
            instanceId: 'scopes_complete',
        },
        {
            id: 'scopes',
            instanceId: 'scopes_mot',
            result: 'scopes_modelOwnerTeams'
        },
        {
            id: 'scopes',
            instanceId: 'scopes_dev',
            result: 'scopes_developerTeams'
        },
        {
            id: 'scopes',
            instanceId: 'scopes_val',
            result: 'scopes_validatorTeams'
        },
        {
            id: 'scopes',
            instanceId: 'scopes_mrm',
            result: 'scopes_mrmTeams'
        },
        {
            id: 'users',
            instanceId: 'users_mo',
            result: 'users_modelOwner'
        },
        {
            id: 'users',
            instanceId: 'users_bs',
            result: 'users_businessSponsor'
        },
        {
            id: 'users',
            instanceId: 'users_val',
            result: 'users_validator'
        },
        {
            id: 'findings',
            instanceId: 'resource_findings_reviews',
        },
        {
            id: 'notices',
            instanceId: 'resource_notices_reviews',
        },
        {
            id: 'notices',
            instanceId: 'notices_findings',
        },
        {
            id: 'validation_families',
            instanceId: 'validation_families_reviews'
        },
        {
            id: 'reviews',
            instanceId: 'notice_reviews'
        },
        {
            id: 'scopes',
            instanceId: 'parameter_store_scopes'
        },
        {
            id: 'models',
            instanceId: 'allModels'
        },
        {
            id: 'models',
            instanceId: 'allReviewableModels',
            result: 'models_allReviewableModels'
        },
    ],
    otherResources: [
        {
            id: 'notifications',
            classAdmin: 'NotificationAdmin',
        },
        {
            id: 'review_committees',
            classAdmin: 'ReviewCommitteeAdmin',
        },
        {
            id: 'certifications',
            classAdmin: 'CertificationAdmin',
        },
        {
            id: 'implementations',
            classAdmin: 'ImplementationAdmin',
        },
        {
            id: 'change_logs',
            classAdmin: 'ChangeLogAdmin',
        },
        {
            id: 'model_certification_campaigns',
            classAdmin: 'ModelCertificationCampaignAdmin',
        },
        {
            id: 'mitigation_actions',
            classAdmin: 'MitigationActionAdmin',
        },
        {
            id: 'recommendation_igg_bces',
            classAdmin: 'RecommendationIggBceAdmin',
        },
        {
            id: 'troubles',
            classAdmin: 'TroubleAdmin',
        },
        {
            id: 'system_versions',
            classAdmin: 'SystemVersionAdmin',
        },
        {
            id: 'notice_actions',
            classAdmin: 'NoticeActionAdmin',
        },
        {
            id: 'mras',
            classAdmin: 'MraAdmin',
        },
        {
            id: 'processes',
            classAdmin: 'ProcessAdmin',
        },
        {
            id: 'workflows',
            classAdmin: 'WorkflowAdmin',
        },
        {
            id: 'actions',
            classAdmin: 'ActionAdmin',
        },
        {
            id: 'variables',
            classAdmin: 'VariableAdmin',
        },
    ]
});
resourceFactory.preload((datas) => {

    //Création des APIResources avec Items
    resourceFactory.resources.forEach((resource) => {
        let items = datas.resources[`${resource.id}_preload`] ?? [];
        let params = {id: resource.id, instanceId: resource.instanceId, items, context: CONTEXT_PRELOAD};
        params = {...resource.defaultParams, ...params};
        new APIResource(params);//Classique
        new APIResource({id: resource.id, instanceId: `entity_select_${resource.instanceId}`, items, context: CONTEXT_PRELOAD});//Entity select
    });

    //Création des variants APIResources avec Items
    resourceFactory.resourcesVariantInstances.forEach((resource) => {
        let items = resource.result ? (datas.resources[resource.result] ?? []) : (datas.resources[`${resource.id}_preload`] ?? []);
        new APIResource({id: resource.id, instanceId: resource.instanceId, items, context: CONTEXT_PRELOAD});
    });

    //Création des APIResources sans Items
    resourceFactory.otherResources.forEach((resource) => {
        new APIResource({id: resource.id, instanceId: resource.instanceId, context: CONTEXT_PRELOAD});
    });

    //Création des Admins
    resourceFactory.resources.concat(resourceFactory.otherResources).forEach((resource) => {
        if(resource.classAdmin){
            new classes[resource.classAdmin]();
        }
    });
});