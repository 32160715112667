import React from 'react';
import {APIResource} from "../Services/APIResource/APIResource";
import {ParameterTypesMap, PARAMETER_TYPE_WORKFLOW_PROCESS} from "./ParameterAdmin";
import {TableDisplay} from "../Components/Display/TableDisplay/TableDisplay";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";

export const ApiResourceDefaultParams = {
    instanceId: 'process',
    id: 'parameters',
    name: 'Process',
    icon: 'cog',
    fieldForTitle: 'label'
};

export default class ProcessAdmin {
    constructor(){
        this.configure();
    }

    configure(){
        let process = new APIResource(ApiResourceDefaultParams);
        process
            .setFields({
                label: {title: 'Label'},
                type: {
                    title: "Type",
                    type: "mapped",
                    params: { mapping: ParameterTypesMap },
                    edit: (field, value, onChange, entity, routeParams) => {
                        entity[field.id] = PARAMETER_TYPE_WORKFLOW_PROCESS;
                        return null;
                    }
                },
                workflowsEntities: {
                    title: 'Workflows',
                    display: (field, value, entity, props) => <TableDisplay rows={value} cols={[
                        {label: 'Priority', field: 'priority'},
                        {label: 'Title', field: 'title'},
                        {label: 'Target Entity', field: 'targetEntityString'},
                        {label: 'Event', field: 'eventString'},
                        {label: 'Action', field: 'actionTitles', styles: {width: '30%'}, display: (field, titles) => { return <ul>{(titles || []).map(t => <li key={t}>{t}</li>)}</ul>}},
                    ]}
                                                                            actions={(value) => {
                                                                                return (
                                                                                    <div>
                                                                                        <Link
                                                                                            to={'/resource/workflows/' + value.id + '/detail'}
                                                                                            style={styles.actionLink}>
                                                                                            <Button style={styles.mrDetailBtn}
                                                                                                    variant="contained"
                                                                                                    color="primary"
                                                                                                    className="button-table button-show-version">
                                                                                                Detail
                                                                                            </Button>
                                                                                        </Link>
                                                                                        <Link
                                                                                            to={'/resource/workflows/' + value.id + '/edit'}
                                                                                            style={styles.actionLink}>
                                                                                            <Button style={styles.mrDetailBtn}
                                                                                                    variant="contained"
                                                                                                    color="primary"
                                                                                                    className="button-revert button-table">
                                                                                                Edit
                                                                                            </Button>
                                                                                        </Link>
                                                                                    </div>
                                                                                )
                                                                            }}
                                                                            buttons={[{
                                                                                label: 'New Workflow',
                                                                                to: '/resource/workflows/add/' + entity.id,
                                                                                icon: 'fa-plus'
                                                                            }]}
                    />,
                    displayList: () => null,
                },
            })
            .setLayout({
                tabs: {
                    'Process': {
                        rows: [
                            {
                                panels: {
                                    Properties: {
                                        cols: 2,
                                        fields: ["label", "type"],
                                    },
                                    'Workflows': {
                                        cols: 10,
                                        fields: ['workflowsEntities']
                                    }
                                },
                            }
                        ]
                    }
                }
            })
            .genListView({
                fields: ['label'],
                permanentFilters: {
                    type: PARAMETER_TYPE_WORKFLOW_PROCESS,
                }
            })
            .genInsertView({
                fields: ['label', 'type']
            })
            .genEditView({
                fields: ['label', 'type']
            })
            .genDetailView()
        ;
    }
}

const styles = {
    mrDetailBtn: {
        margin: 1,
    },
    actionLink: {
        marginLeft: 5
    },
};
