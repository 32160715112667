import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { APIResource } from '../../Services/APIResource/APIResource';
import { DimensionContent } from './Edit/DimensionContent';
import MraTabs from './Edit/MraTabs';
import MRA, { getUserRole, canEditMraScore, MRA_STATUS, MRA_PROCESS } from '../../Services/MRA';
import Alert from '../../Services/Alert';
import { toJS } from 'mobx/lib/mobx';
import Navigation from '../../Services/Navigation';
import { Header } from "../Header/Header";
import Http from '../../Services/Http';
import { getIdFromIri } from '../../Services/utils';
import { getParamByIri, getParamBySystemId } from '../../Store/ParameterStore';
import User from '../../Services/User/User';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { withTranslation } from 'react-i18next';

const ProcessHeader = (props) => {
    const { mraStatus, mraProcess } = props
    if(mraProcess !== MRA_PROCESS.LOD1_LOD2) return null
    
    let message = "In the 7 tabs below, please complete LoD1 score and score justification for every MRA subdimension. Please do not forget to submit your completed scores to LoD2 by clicking on the green button below. "
    if(mraStatus !== MRA_STATUS.ON_GOING) {
        message = "LoD2 has submitted scores that do not match with yours. You can update your scores if you want to. Please confirm your final scores before the Committee by clicking on the green button below. "
    }
    return <Header key="message" text={message} />
}

export const Edit = observer(
    withTranslation()(
        class Edit extends Component {
            constructor(props) {
                super(props);

                this.mraId = this.props.match.params.id;
                this.mraProcess = '';
                this.mraStatus = '';
                //this.clonedFrom = this.props.mraOptions.mraScores;
                /** @todo clonedFrom récupérer les scores du Mra en paramètre pour clonage
                 * et les passer à (après avoir enlevé les id) addMraScore */

                this.state = {
                    mra: null,
                    currentTabId: 0,
                    currentDimension: null,
                    showResults: false,
                    tabScores: {},
                    scoresMap: {},
                    role: '',
                    ready: false,
                    model: null,
                    assignedValidator: null,
                    validatorUsers: [],
                    saving: false,
                };

                this.modelResource = new APIResource({
                    id: 'models',
                    name: 'Model',
                });
                this.parameterResource = new APIResource({
                    id: 'parameters',
                    name: 'Parameter',
                });
                this.mraResource = new APIResource({
                    id: 'mras',
                    name: 'Mra',
                });
                this.reviewResource = new APIResource({
                    id: 'reviews',
                    name: 'Review',
                });
                this.userResource = new APIResource({
                    id: 'users',
                    name: 'Users',
                });
                this.scopeResource = new APIResource({
                    id: 'scopes',
                    name: 'Scopes',
                });
                this.dimensionsResource = new APIResource({
                    id: 'mra_dimensions',
                    name: 'Dimensions',
                    instanceId: 'mra_edit_dimensions',
                });

                this.mraResource.getItem(this.mraId).then((mra) => {
                    this.setState({ mra });

                    this.dimensionsResource
                        .apiGetCollection({
                            page: 1,
                            rowsPerPage: 10000,
                            filters: {
                                dimension: getIdFromIri(mra.dimension),
                            },
                        })
                        .then((value) => {
                            this.setState({
                                currentDimension: [...value].shift() || {},
                            });

                            Promise.all([
                                this.parameterResource.getItem(getIdFromIri(mra.process)),
                                this.parameterResource.getItem(getIdFromIri(mra.status)),
                                this.modelResource.getItem(getIdFromIri(mra.model)),
                            ]).then((res) => {
                                this.mraProcess = res[0].systemId;
                                this.mraStatus = res[1].systemId;
                                let model = res[2];

                                // add the id to save
                                mra.mraScores = mra.mraScores.map((score) => {
                                    const id = parseInt(score['@id'].split('/').pop(), 10);
                                    return { ...score, id };
                                });


                                let role = getUserRole(model);
                                // si le role est LoD2, il faut rediriger vers
                                // la page detail pour une saisie plus simpliste
                                // ou si MRA importé, l'édition n'est pas possible
                                // on redirige donc vers la page Détail
                                // ou si le LoD1 ne peut pas saisir car ce n'est pas
                                // son tour
                                if (role === 'LoD2' || mra.insertionFromImport) {
                                    Alert.show({
                                        message: `This view is used only by LoD1. You'll be redirected to the Detail View. `,
                                    });
                                    setTimeout(() => {
                                        Navigation.router.history.push(`/resource/mras/${this.mraId}/detail`);
                                    }, 3000);
                                }

                                if (role === 'LoD1' && this.mraStatus === MRA_STATUS.CONFLICT) {
                                    Alert.show({
                                        message: `MRA in conflict. You'll be redirected to the Detail View to edit and compare your scores.`,
                                    });
                                    setTimeout(() => {
                                        Navigation.router.history.push(`/resource/mras/${this.mraId}/detail`);
                                    }, 3000);
                                }
                                if (
                                    role === 'LoD1' &&
                                    this.mraProcess !== MRA_PROCESS.LOD1 &&
                                    this.mraStatus !== MRA_STATUS.ON_GOING &&
                                    this.mraStatus !== MRA_STATUS.CONFLICT
                                ) {
                                    Alert.show({
                                        message: `This MRA can't be edited as long as it hasn't been reviewed by LoD 2. You'll be redirected to the Detail View.`,
                                    });
                                    setTimeout(() => {
                                        Navigation.router.history.push(`/resource/mras/${this.mraId}/detail`);
                                    }, 3000);
                                }

                                this.setState({ role });

                                this.setState({ ready: true });

                                this.setState({ model });

                                this.addMraScore(mra.mraScores);

                                const review = model.reviewsEntities.find((review) => {
                                    return review.reviewStatusString === 'Open';
                                });

                                if (model.modelValidatorTeams) {
                                    model.modelValidatorTeams.forEach((team) => {
                                        this.scopeResource.getItem(getIdFromIri(team)).then((scope) => {
                                            const usersIds = scope.users.map((userIri) => getIdFromIri(userIri));
                                            this.setState({ validatorUsers: usersIds });
                                        });
                                    });
                                }

                                if (review) {
                                    // get assignated validator
                                    this.reviewResource.getItem(review.id).then((res) => {
                                        this.setState({ review: res });
                                        if (res.assignedValidator) {
                                            this.setState({ assignedValidator: getParamByIri(res.assignedValidator) });
                                        }
                                    });
                                }
                            });
                        });
                });
            }

            getDimensionTabId(dimension) {
                const dimensions = this.dimensionsResource.items;
                return dimension ? dimensions.findIndex((d) => dimension.id === d.id) : 0;
            }

            getCurrentTabId() {
                return this.getDimensionTabId(this.state.currentDimension);
            }

            getDimensionByTabId(tabId) {
                return this.dimensionsResource.items[tabId];
            }

            getCurrentMraScoresByDimension(dimension) {
                let allowedTypes = MRA.getAllowedTypes(this.state.role, this.mraStatus, this.mraProcess);

                const scores = MRA.scoresMapToMraScores(this.state.scoresMap, (s) => {
                    return (
                        allowedTypes.includes(getParamByIri(s.type).systemId) &&
                        dimension.mraSubdimensions.includes(s.mraSubdimension)
                    );
                });

                return scores;
            }

            updateDimensionTabScores(dimensionIri) {
                let dimension = this.dimensionsResource.items.filter((d) => d['@id'] === dimensionIri).pop();
                let mraScoresValues = this.getCurrentMraScoresByDimension(dimension);

                let tabScores = { ...this.state.tabScores };
                let tabId = this.getDimensionTabId(dimension);
                if (
                    dimension.mraSubdimensions.length === mraScoresValues.length &&
                    this.isMraScoreValid(dimension, mraScoresValues)
                ) {
                    let p = this.calcMraScoreByDimension(dimension, mraScoresValues);
                    p.then((data) => {
                        this.setState((prevState) => {
                            tabScores = { ...prevState.tabScores };
                            tabScores[tabId] = data;
                            return { ...prevState, tabScores };
                        });
                    });
                } else {
                    this.setState((prevState) => {
                        tabScores = { ...prevState.tabScores };
                        tabScores[tabId] = null;
                        return { ...prevState, tabScores };
                    });
                }
            }

            /**
             * Ajoute le mraScore donné à la map des scores du MRA actuel.
             *
             * @param {SubdimensionContent.MraScore} mraScore
             */
            addMraScore(mraScore) {
                let mraScores = !Array.isArray(mraScore) ? [mraScore] : mraScore;

                let dimensionsIris = [];
                let newScoresMap = this.state.scoresMap;

                mraScores.forEach((mraScore) => {
                    let { scoresMap, dimensionIri } = MRA.addMraScoreToScoresMap(
                        mraScore,
                        newScoresMap,
                        this.dimensionsResource.items
                    );
                    dimensionsIris.push(dimensionIri);
                    newScoresMap = scoresMap;
                });

                this.setState({ scoresMap: { ...this.state.scoresMap, ...newScoresMap } });
                /** @todo mettre cette partie dans le callback de setState et
                 * vérifier si on a touché les scores ou les justifications,
                 * pour éviter de recalculer le score s'il y a juste une justif de changée
                 */
                [...new Set(dimensionsIris)].forEach((dimensionIri) => this.updateDimensionTabScores(dimensionIri));
            }

            /**
             * Function pour récupérer le score d'une sous dimension.
             * On s'en sert pour initialiser les scores manquants automatiquement.
             *
             * PS: Nécessaire à partir du moment où l'on veut pouvoir revenir sur les onglets précédents.
             *
             * @param {Object} subdimension - Sous-dimension, il faudrait définir un typedef !
             * @param {boolean|null} canEdit - Teste si l'edition est possible. Ne teste pas si "canEdit" est null
             * @returns {MraScore}
             */
            getMraScores(subdimension, canEdit = null) {
                let scores = MRA.getMraScoresFromScoresMap(
                    this.state.scoresMap,
                    subdimension.mraDimension,
                    subdimension['@id'],
                    this.state.role,
                    this.mraStatus,
                    this.mraProcess
                );

                if (canEdit !== null) {
                    scores = canEdit
                        ? scores.filter((m) => canEditMraScore(m, this.state.role, this.mraStatus, this.mraProcess))
                        : scores.filter((m) => !canEditMraScore(m, this.state.role, this.mraStatus, this.mraProcess));
                }
                return scores;
            }

            /**
             * Renvoie un booléen indiquant si le score de la dimension donnée est valide.
             *
             * @param {Object} dimension
             */
            isMraScoreValid(dimension, mraScoresValues = null) {
                let valid = false;

                let _mraScoresValues = mraScoresValues || this.getCurrentMraScoresByDimension(dimension);

                // check if all the subdimensions are defined.
                // there's one line by score so we need to make
                // the mraSubdimensions unique (Residual + Inherent on the same dimension)
                let uniqueMraSubdimensions = _mraScoresValues.reduce((values, value) => {
                    if (!values[value.mraSubdimension]) {
                        values[value.mraSubdimension] = value.mraSubdimension;
                    }
                    return values;
                }, []);

                if (dimension.mraSubdimensions.length === Object.keys(uniqueMraSubdimensions).length) {
                    valid = _mraScoresValues.reduce(
                        (p, c) => (MRA.isMraScoreValid(c, this.mraProcess) ? p : false),
                        true
                    );
                }
                return valid;
            }

            /**
             * Vérifie si tous les Mra Scores (pour chaque dimension) sont valides.
             */
            isMraScoresValid() {
                const dimensions = this.dimensionsResource.items;
                let valid = true;
                let err = [];
                dimensions.forEach((dim) => {
                    if (!this.isMraScoreValid(dim)) {
                        valid = false;
                        err.push(dim);
                    }
                });

                return { valid, err };
            }

            /**
             * Appel au backend pour calculer le score d'une dimension
             * en envoyant la liste des scores des sous-dimensions
             *
             * @param {Object} dimension
             * @returns {number}
             */
            async calcMraScoreByDimension(dimension, mraScoresValues = null) {
                let res = 0;
                if (this.state.scoresMap !== null && this.state.scoresMap !== undefined) {
                    let _mraScoresValues = mraScoresValues || this.getCurrentMraScoresByDimension(dimension);

                    const parentDimension = getParamByIri(dimension.dimension);

                    if (
                        _mraScoresValues !== undefined &&
                        dimension.mraSubdimensions.length === _mraScoresValues.length
                    ) {
                        let data = await Http.post('mras/calc_mra_scores', {
                            scores: _mraScoresValues,
                            dimension: parentDimension.systemId,
                        });
                        res = data['hydra:member']['value'];
                    }
                }
                return res;
            }

            /**
             * Returns the next status if authorized
             * @return {[type]} [description]
             */
            getNextStatus(nextStatus, mra) {
                if (this.mraStatus === MRA_STATUS.CONFLICT) {
                    return getParamBySystemId(this.mraStatus);
                } else {
                    // if scores are matching and LOD2 already submitted
                    // then we can fast forward to the next status
                    if(MRA.areScoresMatching(mra.mraScores) && mra.lod2Submit) {
                        return getParamBySystemId(MRA_STATUS.FINALIZED) 
                    }
                }
                
                return nextStatus;
            }

            sendLod2NotificationOnConflict(toUser) {
                let content = `MRA in conflict: new LOD1 scores have been submitted for review`;
                if (this.state.review) {
                    content = `MRA in conflict: new LOD1 scores have been submitted for review: ${this.state.review.title} (id: ${this.state.review.id})`;
                }
                Http.post('notifications/new', {
                    toUser,
                    content,
                    link: `/resource/mras/${this.mraId}/detail`,
                    byEmail: true,
                });
            }

            /**
             * Changer le MRA de Statut.
             */
            onDimensionSubmit(nextStatus) {
                const { valid, err } = this.isMraScoresValid();
                // For tests purpose
                // const valid = true;
                // const err = {}
                if (!valid) {
                    /**
                     * @todo on pourrait afficher les tabs en erreur ?
                     */
                    let invalidDimensions = err.map((d) => d.title).join('\n');
                    Alert.show({
                        message: `Every subdimension of :\n ${invalidDimensions} \n should have at least one score or one justification.`,
                    });
                    // add 1 second of delay
                    setTimeout(() => {
                        MRA.save(this.mraId, this.state.scoresMap).then((m) => {
                            if (!m) {
                                Alert.show({
                                    message: 'An error occurred saving this MRA.',
                                });
                            } else {
                                Alert.show({
                                    message: 'This MRA has been saved.',
                                });
                            }
                        });
                    }, 7000);
                    return false;
                } else {
                    // On sauvegarde la demande actuelle avant de passer à la nouvelle étape
                    MRA.save(this.mraId, this.state.scoresMap).then((res) => {
                        const { success, payload} = res
                        let mra = null
                        if(success) {
                            mra = payload
                        } else {
                            Alert.show({
                                message: 'Unable to save this MRA',
                            });
                            return
                        }
                        
                        // On lance le passage à l'étape suivante :
                        const status = this.getNextStatus(nextStatus, mra);
                        MRA.submit(this.mraId, status, this.state.role).then((entity) => {
                            if (!entity) {
                                Alert.show({
                                    message: 'An error occurred submitting this MRA.',
                                });
                            } else {
                                // send a notification to LOD2 if the status is conflict
                                if (status === MRA_STATUS.CONFLICT) {
                                    if (this.state.assignedValidator) {
                                        this.sendLod2NotificationOnConflict(this.state.assignedValidator);
                                    } else {
                                        this.state.validatorUsers.forEach((userId) => {
                                            this.sendLod2NotificationOnConflict(userId);
                                        });
                                    }
                                }
                                Alert.show({
                                    message: 'This MRA has been successfully submitted.',
                                });

                                // on recharge le modèle avant d'afficher le détail
                                this.modelResource.getItem(entity.model.id, true).then((model) => {
                                    this.props.history.push('/resource/mras/' + entity.id + '/detail');
                                });
                            }
                        });
                    });
                }
            }

            /**
             * Sauvegarder le MRA
             */
            onDimensionSave() {
                // On lance la sauvegarde via l'API
                this.setState({ saving: true });
                MRA.save(this.mraId, this.state.scoresMap).then(result => {
                    this.setState({ saving: false });
                        if (!result.success) {
                            Alert.show({
                                message: `An error occurred saving this MRA: ${JSON.stringify(result.payload)}`,
                            });
                        } else {
                            Alert.show({
                                message: 'This MRA has been successfully saved.',
                            });
                        }
                }).catch(err => {
                    this.setState({ saving: false });
                        Alert.show({
                            message: `An error occurred saving this MRA: ${err}`,
                        });
                });

                return true;
            }

            changeTab(event, tabId) {
                let delay = new Date();
                delay = delay.setSeconds(delay.getSeconds() - 1);
                if (!this.lastTabChange || this.lastTabChange <= delay) {
                    this.lastTabChange = new Date();
                    this.setState({
                        currentDimension: this.getDimensionByTabId(tabId),
                    });
                }
            }

            changeLang() {
                const currentLang = this.props.i18n.language;
                if (currentLang === 'en') {
                    this.props.i18n.changeLanguage('fr');
                } else {
                    this.props.i18n.changeLanguage('en');
                }
            }


            render() {
                return (
                    <div className="mra-process-container">
                        {this.state.ready && (
                            <React.Fragment>
                                <ProcessHeader mraStatus={this.mraStatus} mraProcess={this.mraProcess} />
                                <div className="tabs">
                                    <MraTabs
                                        dimensions={this.dimensionsResource.items}
                                        tabId={this.getCurrentTabId()}
                                        tabScores={this.state.tabScores}
                                        onChangeTab={this.changeTab.bind(this)}
                                    />
                                </div>
                                <div className="content">
                                    <DimensionContent
                                        dimension={this.state.currentDimension}
                                        onSave={this.onDimensionSave.bind(this)}
                                        onSubmit={this.onDimensionSubmit.bind(this)}
                                        onChangeLang={this.changeLang.bind(this)}
                                        currentLang={this.props.i18n.language}
                                        addMraScore={this.addMraScore.bind(this)}
                                        getMraScores={this.getMraScores.bind(this)}
                                        mra={this.state.mra}
                                        saving={this.state.saving}
                                    />
                                </div>
                            </React.Fragment>
                        )}
                        {!this.state.ready && (
                            <div className="content" style={{ textAlign: 'center', marginTop: '14em' }}>
                                <LoadingIndicator />
                            </div>
                        )}
                    </div>
                );
            }
        }
    )
);
