import React from "react";
import ParameterStore, {userHasRoleMRM, userIsVal, userValidatorTeamManagedRights} from "../../Store/ParameterStore";
import Http from '../../Services/Http';
import Alert from "../Alert";
import Modal from "../Modal";
import Navigation from "../Navigation";
import ValidateFindingNotice from "../../Components/Review/ValidateFindingNotice";
import {
    REVIEW_STATUS_ASSESSMENT,
    REVIEW_STATUS_ASSESSMENT_AWAITING,
    REVIEW_STATUS_CLOSED,
    REVIEW_STATUS_DISMISSED,
    REVIEW_STATUS_FINALIZATION,
    REVIEW_STATUS_FINALIZATION_AWAITING, REVIEW_STATUS_PLANNING, REVIEW_STATUS_PLANNING_AWAITING,
    REVIEW_STATUS_SIGN_OFF,
    REVIEW_STATUS_SIGN_OFF_AWAITING
} from "../../Admin/ReviewAdmin";
import User from "../User/User";
import APIResourceStore from "../../Store/APIResourceStore";

export const submitDocument = (
    entity
) => {
    if(entity.documentsEntities.length === 0){
        Alert.show({
            message: 'Please upload at least one document before submitting to the review validator.',
            type: 'warning',
        });
        return;
    }

    Http.get(`reviews/${entity.id}/document`).then(() => {
        Alert.show({
            message: 'Your documents has been submitted to the review validator.',
            type: 'success',
        });
    }).catch(() => {

    });
}

export const submitManager = (
    entity
) => {
    Http.get(`reviews/${entity.id}/manager`).then(() => {
        Alert.show({
            message: 'The review has been submitted to VAL manager for validation.',
            type: 'success',
        });
    }).catch(() => {

    });
}

export const requestValidationOfPlannedDocumentationDeliveryDate = (
    entity, _resource
) => {
    let requestedBy = 'Lod1';
    if(userIsVal(User, entity) || userHasRoleMRM()){
        requestedBy = 'Lod2'; //entity.plannedDocumentationRequestConfirmationByLod2 = true;
    }
    Http.post('review/planned-documentation/request',{
        reviewId: entity.id,
        requestedBy: requestedBy
    }).then(() => {
        Alert.show({
            message: 'Your request has been sent.',
            type: 'success',
        });
    }).catch(() => {

    });
}

/**
 * 
 * @param {{findingsEntities: Array|undefined, noticesEntities: Array|undefined}} entity - Review
 * @returns {boolean}
 */
export const hasAtLeastOneNotice = (entity) => {
    /** Si une Finding a une severité NA, alors on autorise l'absence de Notice : */
    if (
        typeof entity.findingsEntities !== 'undefined'
        && entity.findingsEntities.some(finding => APIResourceStore.resources?.parameters?.getObservableItemByPath(finding.severity)?.systemId === 'FINDING_SEVERITY_NA')
    ) {
        return true;
    }
    if(
        typeof entity.noticesEntities === 'undefined'
        || entity.noticesEntities.length === 0
    ){
        return false;
    }
    return entity.noticesEntities.some(notice => notice.status !== ParameterStore('NOTICE_STATUS_DELETED'));
}

export const hasAtLeastOneFinding = (entity) => {
    if(
        typeof entity.findingsEntities === 'undefined'
        || entity.findingsEntities.length === 0
    ){
        return false;
    }
    return entity.findingsEntities.some(finding => finding.status !== ParameterStore('FINDING_STATUS_DELETED'));
}

const openValidateModal = (
    resource,
    entity,
    currentStatus,
    nextStatus,
    resourceEditComponent,
    tooltip
) => {
    Modal.open({
        title: 'Validate and close',
        content: (
            <ValidateFindingNotice
                findings={entity.findingsEntities}
                notices={entity.noticesEntities}
                resource={resource}
                entity={entity}
                currentStatus={currentStatus}
                nextStatus={nextStatus}
                resourceEditComponent={resourceEditComponent}
                tooltip={tooltip}
            />
        ),
    });
};

/** 
 * En cas d'échec de validation de l'entité, on sauvegarde les informations du formulaire. 
 * 
 * @param {*} entity
 * @param {*} resourceEditComponent
 * @param {import('../APIResource/APIResource').APIResource} resource
 */
const saveCurrentEntity = (entity, resourceEditComponent, resource) => {
    resourceEditComponent.setState({entity: entity});
    /** 
     * apiPut sert juste à sauvegarder les modifications actuelles, pas besoin de remettre
     * à jour le state de resourceEdit ensuite, sinon on perdrait les informations entrées depuis le retour
     * de validation en erreur
     */
    resource.apiPut(entity).then(r => {});
}

/**
 * 
 * @param {import('../APIResource/APIResource').APIResource} resource 
 * @param {Object} entity 
 * @param {*} currentStatus 
 * @param {*} nextStatus 
 * @param {*} resourceEditComponent 
 * @param {string} tooltip 
 */
export const submitReview = (
    resource,
    entity,
    currentStatus,
    nextStatus,
    resourceEditComponent,
    tooltip,
    spinner = false
) => {
    entity.reviewStatus = nextStatus;

    let success = 'The review has been saved';
    if (
        nextStatus === ParameterStore(REVIEW_STATUS_PLANNING)
    ) {
        if (userValidatorTeamManagedRights(User.profile.managedScopes, entity)) {
            success = 'The review has entered the planning phase.';
        } else {
            success = 'The review has entered the planning phase. VAL manager has been notified.';
        }
    } else if (
        nextStatus === ParameterStore(REVIEW_STATUS_PLANNING_AWAITING)
        || nextStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING)
        || nextStatus === ParameterStore(REVIEW_STATUS_SIGN_OFF_AWAITING)
        || nextStatus === ParameterStore(REVIEW_STATUS_FINALIZATION_AWAITING)
    ) {
        success = 'The review has been submitted to VAL manager for validation.';
    } else if (nextStatus === ParameterStore(REVIEW_STATUS_CLOSED)) {
        success = 'The review has been closed.';
    } else if (nextStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT)) {
        success = 'The review entered the assessment phase.';
    } else if (nextStatus === ParameterStore(REVIEW_STATUS_SIGN_OFF)) {
        success = 'The review has been switched to the sign-off phase.';
    } else if (nextStatus === ParameterStore(REVIEW_STATUS_FINALIZATION)) {
        success = 'The review entered the finalization phase.';
    } else if (nextStatus === ParameterStore(REVIEW_STATUS_DISMISSED)) {
        success = 'The review is now cancelled.';
    }

    if (
        nextStatus === ParameterStore(REVIEW_STATUS_FINALIZATION_AWAITING)
    ) {//La cloture est demandée
        //Vérification des findings/notices
        if (entity.issue === false || entity.issue === null) {
            if (
                hasAtLeastOneFinding(entity) === false
            ) {
                //Notice mais pas de finding -> Erreur
                Alert.show({
                    message: 'At least one finding is required at this stage.',
                    type: 'warning',
                });
                Navigation.router.history.push(
                    `/resource/${resource.instanceId}/${entity.id}/detail?tab=Issues`
                );
                return;
            }
            if(!entity.validate){
                // Validation des champs nécessaires à la Review avant de valider les Findings/Notices
                resource.currentEditingActions.save(
                    success,
                    success,
                    (tooltip !== undefined && tooltip !== null ? 'To ' + tooltip.toLowerCase() + ', the' : 'The') + ' following fields are required: ',
                    entity,
                    true,
                    spinner
                ).then((_entity) => {
                    entity.reviewStatus = currentStatus;
                    //Vérification de tous les champs requis des findings et notices
                    openValidateModal(
                        resource,
                        entity,
                        currentStatus,
                        nextStatus,
                        resourceEditComponent,
                        tooltip
                    );
                }).catch(() => {
                    entity.reviewStatus = currentStatus;
                    /** échec de validation de l'entité, on sauvegarde les informations */
                    saveCurrentEntity(entity, resourceEditComponent, resource);
                })
                return;
            }
        }
    }

    if (userValidatorTeamManagedRights(User.profile.managedScopes, entity) && nextStatus !== ParameterStore(REVIEW_STATUS_DISMISSED)) {
        // Vérification de la review avec le statut courant
        // NB: si j'ai bien compris c'est parce qu'on est déjà en statut "awaiting" puisqu'on est VAL Manager
        // donc on effectue les tests avec le statut "awaiting". Mais basiquement, il n'y a pas de contrôle de champs
        // il s'agit juste d'une validation.
        entity.reviewStatus = currentStatus;
        /**
         * @todo on pourrait utiliser directement resourceEditComponent ?!
         * @todo sortir cleanEntity + verify + setEntity de save et l'utiliser à la place ici
         */
        resource.currentEditingActions.save(
            success,
            success,
            (tooltip !== undefined && tooltip !== null ? 'To ' + tooltip.toLowerCase() + ', the' : 'The') + ' following fields are required: ',
            entity,
            true,
            spinner
        ).then((entity) => {
            //Si tous les champs sont ok, alors on sauvegarde le statut suivant sans vérification
            entity.reviewStatus = nextStatus;
            resourceEditComponent.setState({apiUpdateInProgress: true});
            resource.apiPut(entity).then((entity) => {
                Alert.show({message: success});
                resourceEditComponent.setState({entity: entity});
                Navigation.router.history.push(`/resource/${resource.instanceId}/${entity.id}/detail`);
            }).catch(() => {
                entity.reviewStatus = currentStatus;
                /** échec de validation de l'entité, on sauvegarde les informations */
                saveCurrentEntity(entity, resourceEditComponent, resource);
            }).finally(() => resourceEditComponent.setState({apiUpdateInProgress: false}));
        }).catch(() => {
            /** échec de validation de l'entité, on sauvegarde les informations */
            saveCurrentEntity(entity, resourceEditComponent, resource);
        }).finally(() => Modal.close());
    } else{
        //Vérifie la review avec le statut suivant
        /** @todo cf au dessus */
        resource.currentEditingActions.save(
            success,
            success,
            (tooltip !== undefined && tooltip !== null ? 'To ' + tooltip.toLowerCase() + ', the' : 'The') + ' following fields are required: ',
            entity,
            true
        ).then((entity) => {
            entity.reviewStatus = nextStatus;
            resource.apiPut(entity)
            .then((entity) => {
                Alert.show({message: success});
                resourceEditComponent.setState({entity: entity});
            })
            .catch(() => {
                entity.reviewStatus = currentStatus;
                /** échec de validation de l'entité, on sauvegarde les informations */
                saveCurrentEntity(entity, resourceEditComponent, resource);
            });
        }).catch(() => {
            entity.reviewStatus = currentStatus;
            /** échec de validation de l'entité, on sauvegarde les informations */
            saveCurrentEntity(entity, resourceEditComponent, resource);
        }
        ).finally(() => {
            resourceEditComponent.setState({apiUpdateInProgress: false});
            Modal.close()
        });
    }

};

export const noticeMissingFinding = () => {
    Alert.show({message: "All the notices must be associated to at least one finding"});
}
