import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FieldProviderStore from '../../../Services/APIResource/FieldProviders/__FieldProviderStore';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from "../../../Services/Alert";
import Http from "../../../Services/Http";
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { OptionalTooltip } from '../../OptionalTooltip/OptionalTooltip';
import Navigation from '../../../Services/Navigation';
import {OpenModal} from "../../Modal/OpenModal";

export class TableDisplay extends Component {
    constructor(props) {
        super(props);

        this.filter = this.filter.bind(this);

        this.state = {
            initialRows: null,
            rows: null,
            loading: false
        };
    }

    componentDidMount() {
        if (this.props.loading !== undefined ) this.setState({loading: this.props.loading})
        if(this.props.endpoints !== undefined){
            this.loadRows();
        }else if (this.props.rows !== undefined) {
            this.setState({
                initialRows: this.props.rows,
                rows: this.props.rows,
            });
        }
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if (this.props.rows && this.props.rows !== prevProps.rows) {
            if(this.props.endpoints !== undefined){
                this.loadRows();
            }else if (this.props.rows !== undefined) {
                this.setState({
                    initialRows: this.props.rows,
                    rows: this.props.rows,
                });
            }
        }
        
        if (this.props.loading !== undefined && this.props.loading !== prevProps.loading) this.setState({loading: this.props.loading});
    }

    loadRows(){
        this.setState({ loading: true });
        Http.get(this.props.endpoints).then((response) => {
            this.setState({
                loading: false, 
                initialRows: response.rows,
                rows: response.rows,
            });
        }).catch((error) => {
            this.setState({ loading: false });
            Alert.show({
                message: Http.getErrorMessage(error),
                type: "success",
            });
        })
    }

    filter(value) {
        if (value === '') {
            this.setState({ rows: this.state.initialRows });
            return;
        }
        let filtersRows = [];
        let regex = new RegExp(value, 'i');
        for (let i in this.state.initialRows) {
            for (let j in this.props.cols) {
                if (
                    regex.test(
                        this.state.initialRows[i][this.props.cols[j].field]
                    )
                ) {
                    filtersRows.push(this.state.initialRows[i]);
                    break;
                }
            }
        }
        this.setState({ rows: filtersRows });
    }

    getFilter() {
        if (this.props.filter === undefined || this.props.filter === true) {
            return (
                <FormControl className={'td_filter_control'}>
                    <TextField
                        className={'td_filter_input'}
                        placeholder="Filter"
                        onChange={(event) => this.filter(event.target.value)}
                    />
                </FormControl>
            );
        }
    }

    tHeader() {
        let ths = [];
        for (let i in this.props.cols) {

            if(!this.props.cols[i].headerStyles){
                this.props.cols[i].headerStyles = {};
            }

            ths.push(
                <TableCell key={'th_' + i} style={this.props.cols[i].headerStyles}>
                    {typeof this.props.cols[i].label === 'function'
                        ? this.props.cols[i].label()
                        : this.props.cols[i].label}
                </TableCell>
            );
        }
        if (this.props.actions) {
            let actionsColIndex =
                this.props.actionsColIndex !== undefined && !isNaN(this.props.actionsColIndex)
                    ? this.props.actionsColIndex
                    : 0; 
            ths.splice(
                actionsColIndex,
                0,
                <TableCell key={'th_actions' + actionsColIndex}>
                    {this.props.actionLabel ? this.props.actionLabel : 'Actions'}
                </TableCell>
            );
        }

        return (
            <TableHead>
                <TableRow>{ths}</TableRow>
            </TableHead>
        );
    }

    tBody(rows) {

        if(this.state.loading){
            return <TableBody>
                <TableRow
                    key={"loading"}
                >
                    <TableCell
                        key={'loading_' + this.props.cols.length}
                        colSpan={this.props.cols.length}
                    >
                        <Box textAlign="center">
                            <CircularProgress />
                        </Box>
                    </TableCell>
                </TableRow>
            </TableBody>;
        }

        let trs = [];
        for (let i in rows) {
            let tds = [];
            for (let j in this.props.cols) {
                if (!this.props.cols[j].styles) {
                    this.props.cols[j].styles = {};
                }
                let displayComponent = null;
                let fieldDefinition = this.props.cols[j];
                let field = fieldDefinition.field;
                let entity = rows[i];

                if(entity[field] === undefined && !fieldDefinition.forceDisplay){
                    displayComponent = FieldProviderStore.default.getDisplay(
                        fieldDefinition,
                        '- Restricted field -',
                        entity,
                        {
                            plainText: true,
                            flat: true,
                            textStyle: {
                                color: 'gray',
                                fontStyle: 'italic',
                            }
                        }
                    );
                }else if (fieldDefinition.display) {
                    displayComponent = fieldDefinition.display(
                        fieldDefinition,
                        entity[field],
                        entity,
                        { fieldDefinition }
                    );
                } else if (
                    fieldDefinition.type &&
                    FieldProviderStore[fieldDefinition.type]
                ) {
                    displayComponent = FieldProviderStore[
                        fieldDefinition.type
                    ].getDisplayList(fieldDefinition, entity[field], entity, {
                        plainText: true,
                        flat: true,
                        multi: true,
                    });
                } else {
                    displayComponent = FieldProviderStore.default.getDisplayList(
                        fieldDefinition,
                        entity[field],
                        entity,
                        { plainText: true }
                    );
                }
                tds.push(
                    <TableCell
                        key={i + '-' + j}
                        style={this.props.cols[j].styles}
                        className={this.props.cols[j].className || ''}
                    >
                        <Tooltip
                            title={
                                !fieldDefinition.noTooltip
                                    ? (
                                        fieldDefinition.tooltip !== undefined ?
                                        // on se donne la possibilité d'afficher un tooltip particulier, sinon on affiche le composant
                                        // il faudrait peut-être uniformiser les appels à display, tooltip etc.
                                        fieldDefinition.tooltip(fieldDefinition, entity[field], entity) 
                                        : displayComponent
                                    )
                                    : ''
                            }
                            placement={i === 0 ? 'bottom' : 'top'}
                            arrow
                        >
                            <div
                                className={
                                    'cell-content ' + this.props.cols[j].label
                                }
                                style={
                                    Object.assign(
                                        this.props.cols[j].style ? this.props.cols[j].style : {},
                                    this.props.cols[j].width
                                        ? {
                                              width:
                                                  this.props.cols[j].width +
                                                  'px',
                                          }
                                        : {})
                                }
                            >
                                {displayComponent}
                            </div>
                        </Tooltip>
                    </TableCell>
                );
            }
            if (this.props.actions) {
                let actionsColIndex =
                    this.props.actionsColIndex !== undefined && !isNaN(this.props.actionsColIndex)
                        ? this.props.actionsColIndex
                        : 0;
            tds.splice(
                    actionsColIndex,
                    0,
                    <TableCell
                        key={i + '_actions' + actionsColIndex}
                        style={{ width: '25%' }}
                        className="td_actions"
                    >
                        {this.props.actions(rows[i])}
                    </TableCell>
                );
            }
            trs.push(
                <TableRow
                    key={i}
                    className={this.props.selectedRow && this.props.selectedRow === rows[i].id ? 'active' : ''}
                    onDoubleClick={() => {
                        if (!this.props.resource || !this.props.resource.operations || !this.props.resource.operations.detail || !this.props.allowDoubleClick) {
                            return;
                        }
                        let targetResource =
                            this.props.resource.operations.list.targetDetailResource ||
                            this.props.resource.instanceId;
                        let id = rows[i].id;
                        if(!id && rows[i]['@id']){
                            id = parseInt(rows[i]['@id'].split('/').pop(), 10)
                        }
                        Navigation.router.history.push(
                            '/resource/' + targetResource + '/' + id + '/detail'
                        );
                    }}
                >
                    {tds}
                </TableRow>
            );
        }

        return <TableBody>{trs}</TableBody>;
    }

    buttons() {
        if (this.props.buttons === undefined) {
            return null;
        }

        let buttons = [];
        for (let i in this.props.buttons) {
            const buttonProps = this.props.buttons[i];
            if (buttonProps.inModal) {
                buttons.push(
                    <OptionalTooltip key={'tooltip_' + (i || '')} title={buttonProps.tooltip} arrow placement="top">
                        <OpenModal
                            key={'td_button_' + i}
                            {...buttonProps.modalProps}
                            modalTitle={buttonProps.label}
                            preload={true}
                            button={
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="button-general"
                                    disabled={buttonProps.disabled}
                                >
                                    {buttonProps.icon ? (
                                        <Icon
                                            className={
                                                'fa ' + buttonProps.icon
                                            }
                                        ></Icon>
                                    ) : null}
                                    {buttonProps.label}
                                </Button>
                            }
                        />
                    </OptionalTooltip>
                );
            }else if (buttonProps.to) {
                buttons.push(
                    <OptionalTooltip key={'tooltip_' + (i || '')} title={buttonProps.tooltip} arrow placement="top">
                        <Link
                            key={'td_button_' + i}
                            to={buttonProps.to}
                            style={styles.actionLink}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                className="button-general"
                                disabled={buttonProps.disabled}
                            >
                                {buttonProps.icon ? (
                                    <Icon
                                        className={
                                            'fa ' + buttonProps.icon
                                        }
                                    ></Icon>
                                ) : null}
                                {buttonProps.label}
                            </Button>
                        </Link>
                    </OptionalTooltip>
                );
            } else if (buttonProps.onClick) {
                
                buttons.push(
                    <OptionalTooltip key={'tooltip_' + (i || '')} title={buttonProps.tooltip} arrow placement="top">
                        <Button
                            key={'td_button_' + i}
                            style={{ zIndex: 0 }}
                            disabled={buttonProps.disabled}
                            variant="contained"
                            color="primary"
                            className="button-general"
                            onClick={() =>
                                buttonProps.onClick(buttonProps)
                            }
                        >
                            {buttonProps.icon ? (
                                <Icon
                                    className={'fa ' + buttonProps.icon}
                                ></Icon>
                            ) : null}
                            {buttonProps.label}
                        </Button>
                    </OptionalTooltip>
                );
            }else if(
                typeof buttonProps === 'function'
                && String(buttonProps).includes('return React.createElement')
            ){
                //buttonProps is a component
                buttons.push(buttonProps)
            }
        }

        return (
            <Grid item xs={12}>
                <Paper>{buttons}</Paper>
            </Grid>
        );
    }

    render() {
        return (
            <div>
                {this.props.label ? <label>{this.props.label}</label> : null}
                <Grid
                    container
                    spacing={2}
                    className={
                        this.props.className + ' ' +
                        (this.props.buttons === undefined
                            ? 'container'
                            : 'container detail-inner-new-button')
                    }
                >
                    {this.buttons()}
                    {
                        !this.props.hideIfEmpty
                        || (
                            this.props.hideIfEmpty
                            && this.state.rows
                            && this.state.rows.length > 0
                        ) ?
                            <Grid item xs={12}>
                                {this.getFilter()}
                                <Paper className="container-no-padding table-scroll">
                                    <Table
                                        className={
                                            'table-display' + this.props.dense
                                                ? 'small'
                                                : 'medium'
                                        }
                                        size={this.props.dense ? 'small' : 'medium'}
                                    >
                                        {this.tHeader()}
                                        {this.tBody(this.state.rows)}
                                    </Table>
                                </Paper>
                            </Grid>
                        : null
                    }
                </Grid>
            </div>
        );
    }
}
TableDisplay.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    /** Pour récupérer les valeurs si besoin */
    endpoints: PropTypes.string,
    filter: PropTypes.bool,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            to: PropTypes.any,
            icon: PropTypes.string,
            label: PropTypes.string,
            onClick: PropTypes.func,
            disabled: PropTypes.bool,
            /** si présent on affiche un tooltip sur le bouton */
            tooltip: PropTypes.string,
        })
    ),
    actions: PropTypes.any,
    actionLabel: PropTypes.string,
    /** index de la colonne Actions, dernière colonne si vide */
    actionsColIndex: PropTypes.number,
    /** @todo array of "fieldDefinition" à préciser */
    cols: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    rows: PropTypes.array,
    /** id de la ligne sélectionnée */
    selectedRow: PropTypes.any,
    hideIfEmpty: PropTypes.bool,
    dense: PropTypes.bool,
    loading: PropTypes.bool,
};

const styles = {
    actionLink: {
        marginLeft: 5,
        zIndex: 0,
    },
};
