import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import { CircularProgress} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Http from "../../Services/Http";
import Alert from "../../Services/Alert";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import ParameterStore from '../../Store/ParameterStore';
import Modal from "../../Services/Modal";
import { ActionLink } from "../ActionLink/ActionLink";
import { ActionButton } from "../Modal/ActionButton";
import { ButtonBar } from "../Modal/ButtonBar";
import { ModalContent } from "../Modal/ModalContent";

/** 
 * @typedef {Array<{id: String, title: String, status: String, statusString: String, severityString: String, notices: Array, allNoticeClosed: boolean}>} FindingsEntities 
 * 
 * @param {Object} props
 * @param {FindingsEntities} props.findings 
 * @param {import('../../Services/APIResource/APIResource').APIResource} props.resource - Resource de l'entité source
 * @param {{id: string}} props.entity - Entité de la resource donnée à recharger après traitement des Findings
 */
const CloseFindings = (props) => {

    const {entity, resource, findings} = props;
    const [selectionFinding, setSelectionFinding] = useState([]);
    /** @type {[FindingsEntities, Function]} */
    const [selectableFindings, setSelectableFindings] = useState([]);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        const selectable = findings.filter(f => display(f));
        if (!selectable.length){
            Modal.close();
            return;
        }
        setSelectableFindings(selectable);
    }, []);

    useEffect(() => {
        setSelectionFinding(selectableFindings.map(f => f.id));
    }, [selectableFindings]);

    function onSelectionChange(item) {
        let sel = [...selectionFinding];
        if (sel.indexOf(item.id) === -1) {
            sel.push(item.id);
        } else {
            for (let i in sel) {
                if (sel[i] === item.id) {
                    sel.splice(i, 1);
                }
            }
        }
        return sel;
    }

    function display(item){
        if(
            item.status === ParameterStore('FINDING_STATUS_DELETED')
            || item.status === ParameterStore('FINDING_STATUS_CLOSED')
            || item.notices.length === 0
            || item.allNoticeClosed === false
        ){
            return false;
        }
        return true;
    }

    const displayList = () => {
        const rows = selectableFindings.map((item, index) => (
            <TableRow key={'close-checkbox-finding-' + index}>
                <TableCell>
                    <Checkbox
                        indeterminate={false}
                        checked={selectionFinding.indexOf(item.id) !== -1}
                        onChange={() => {
                            setSelectionFinding(onSelectionChange(item));
                        }}
                    />
                </TableCell>
                <TableCell>
                    <ActionLink to={`/resource/findings/${item.id}/detail`} target={'_blank'}>
                        {item.title}
                    </ActionLink>
                </TableCell>
                <TableCell>{item.severityString}</TableCell>
                <TableCell>{item.statusString}</TableCell>
            </TableRow>
        ));
        return <Table
            className={'table-display small'}
            size={'small'}
        >
            <TableHead>
                <TableRow>
                    <TableCell key={'th_finding_0'}>

                    </TableCell>
                    <TableCell key={'th_finding_1'}>
                        Title
                    </TableCell>
                    <TableCell key={'th_finding_2'}>
                        Severity
                    </TableCell>
                    <TableCell key={'th_finding_3'}>
                        Status
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows}
            </TableBody>
        </Table>
    }

    const close = () => {
        if (
            selectionFinding.length === 0
        ) {
            Alert.show({
                message: 'At least one finding are required',
                type: 'danger',
            });
            return;
        }

        setProcessing(true);
        Http
            .post(
                'reviews/close-findings',
                {findings: selectionFinding}
            )
            .then(() => {
                Alert.show({
                    message: 'Your findings are successfully closed.',
                    type: 'success',
                });
                setProcessing(false);
                setSelectionFinding([]);
                entity && resource && resource.getItemFromResourcePath(`api/${resource.resourceId}/${entity.id}`, true);
                Modal.close();
            })
            .catch((_err) => {
                setProcessing(false)
            })
        ;
    }

    return (
        <ModalContent>
            {displayList()}
            <ButtonBar>
                <ActionButton
                    onClick={() => Modal.close()}
                >
                    Cancel
                </ActionButton>
                <ActionButton onClick={() => close()} disabled={selectionFinding.length === 0}>
                    {processing ? (
                        <CircularProgress size={24} />
                    ) : (
                        'Close Finding' + (selectionFinding.length > 1 ? 's' : '')
                    )}
                </ActionButton>
            </ButtonBar>
        </ModalContent>
    );
}
CloseFindings.propTypes = {
    findings: PropTypes.array,
    resource: PropTypes.object,
    entity: PropTypes.object,
}
export default CloseFindings;