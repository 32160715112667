import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Modal from "../../Services/Modal";
import {CircularProgress} from "@material-ui/core";

export class ConfirmModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: false,
        };
    }

    save() {
        this.setState({progress: true});
        if(this.props.callback){
            this.props.callback();
        }
        Modal.close();
    }

    render() {
        return (
            <div className={"logical-delete-form"}>
                {this.props.message}
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}
                >
                    <Button
                        style={{ marginTop: 10 }}
                        variant="contained"
                        color="secondary"
                        className="button-general"
                        onClick={Modal.close}
                    >
                        {this.props.button.cancel}
                    </Button>

                    { this.state.progress ?
                        <Button
                            style={{ marginTop: 10 }}
                            variant="contained"
                            color="secondary"
                            className="button-general"
                        >
                            <CircularProgress size={20} />
                        </Button>
                        :
                        <Button
                            style={{ marginTop: 10 }}
                            variant="contained"
                            color="secondary"
                            className="button-general"
                            onClick={() => {
                                this.save();
                            }}
                        >
                            {this.props.button.confirm}
                        </Button>
                    }
                </div>
            </div>
        );
    }
}

let styles = {
    actionLink: {
        marginLeft: 5
    }
};
