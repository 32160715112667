import React from 'react';

import { APIResource } from '../Services/APIResource/APIResource';
import User, {ROLE} from '../Services/User/User';
import Thread from '../Components/Display/Thread/Thread';
import { BulkEdit } from '../Services/BulkActions/BulkEdit/BulkEdit';
import { LogicalDeleteButton } from '../Components/DeleteButton/LogicalDeleteButton';
import { BulkDelete } from '../Services/BulkActions/BulkDelete/BulkDelete';
import {
    userHasOwnershipRights,
    userHasRoleADMIN,
    userHasRoleMRM,
} from "../Store/ParameterStore";
import Modal from "../Services/Modal";
import {EntityExportForm} from "../Components/Export/EntityExport";
import { ChangeLog } from '../Components/Display/ChangeLog/ChangeLog';
import ModelList from "../Components/ModelCertificationCampaign/ModelList/ModelList";
import APIResourceStore from '../Store/APIResourceStore';
import TextareaProvider from "../Services/APIResource/FieldProviders/TextareaProvider";

export const ApiResourceDefaultParams = {
    id: 'model_certification_campaigns',
};

export default class ModelCertificationCampaignAdmin {
    constructor() {
        this.configure();
    }

    async configure() {
        if (!User.getId()) {
            await User.restore();
        }
        const fieldsConfiguration = {
            id: { title: 'ID', style: { width: 50 } },
            author: {
                title: 'Author',
                type: 'user',
                params: {
                    resource: 'users',
                    displayField: 'toString',
                    editDisplayField: 'fullNameWithTeam',
                    sortField: 'lastName',
                    endpoints: {
                        getAll: 'users/all-users/all',
                    },
                },
            },
            modelOwner: {
                title: 'Model owner',
                type: 'user',
                required: true,
                params: {
                    resource: 'users',
                    instanceId: 'users_mo',
                    displayField: 'toString',
                    editDisplayField: 'fullNameWithTeam',
                    sortField: 'lastName',
                    endpoints: {
                        getAll: 'users/all-users/mo',
                    },
                },
            },
            modelOwners: {
                title: 'Model owners',
                type: 'user',
                required: (entity) =>
                    !(entity.scopes && entity.scopes.length) &&
                    !(entity.establishments && entity.establishments.length),
                params: {
                    resource: 'users',
                    instanceId: 'users_mo',
                    displayField: 'toString',
                    editDisplayField: 'fullNameWithTeam',
                    multi: true,
                    endpoints: {
                        getAll: 'users/all-users/mo',
                    },
                },
                display: () => null,
                displayList: () => null,
                editForceDisplay: true,
            },
            establishments: {
                title: 'Establishments',
                type: 'entityAsync',
                required: (entity) =>
                    !(entity.scopes && entity.scopes.length) && !(entity.modelOwners && entity.modelOwners.length),
                params: {
                    resource: 'establishments',
                    displayField: 'title',
                    multi: true,
                    endpoints: {
                        getAll: 'establishments/all',
                    },
                },
                display: () => null,
                displayList: () => null,
                editForceDisplay: true,
            },
            scopes: {
                title: 'Scopes',
                required: (entity) =>
                    !(entity.modelOwners && entity.modelOwners.length) &&
                    !(entity.establishments && entity.establishments.length),
                type: 'entityTree',
                params: {
                    multi: true,
                    resource: 'scopes',
                    instanceId: 'scopes_all',
                    displayField: 'title',
                    childrenPropertyName: 'childScopes',
                    endpoints: {
                        getAll: 'scopes/all-scopes/all',
                    },
                },
                display: () => null,
                displayList: () => null,
                editForceDisplay: true,
            },
            acknowledgmentOfReceipt: {
                title: 'Acknowledgment of receipt',
                type: 'bool',
            },
            numberOfMyModels: {
                title: 'Total number of models',
                filter: () => null,
            },
            numberOfCertifiedModels: {
                title: 'Number of certified models',
                type: 'text',
                filter: () => null,
            },
            numberOfTroubles: {
                title: 'Number of troubles',
                type: 'text',
                filter: () => null,
            },
            numberOfCertifiedModelsByModelOwnerDelegated: {
                title: 'Delegations follow-up',
                display: (field, value, entity, _props) => {
                    if(Array.isArray(value)){
                        let render = new Array();
                        for(let i in value){
                            render.push(value[i].modelOwnerDelegation + " (" + value[i].numberOfCertifiedModels + "/" + value[i].numberOfModels + ")");
                        }
                        return TextareaProvider.getDisplay(field, render.join("\n"), entity, _props);
                    }
                    return null;
                },
                displayList: (field, value, entity, _props) => {
                    if(Array.isArray(value)){
                        let render = new Array();
                        for(let i in value){
                            render.push(value[i].modelOwnerDelegation + " (" + value[i].numberOfCertifiedModels + "/" + value[i].numberOfModels + ")");
                        }
                        return TextareaProvider.getDisplay(field, render.join("\n"), entity, _props);
                    }
                    return <></>;//Keep this instead of null
                },
                filter: () => null,
            },
            status: {
                title: 'Status',
                type: 'mapped',
                params: {
                    mapping: {
                        0: 'Ongoing',
                        1: 'Closed',
                    },
                },
            },
            certificationCampaignState: {
                title: 'Progress',
                type: 'mapped',
                params: {
                    mapping: {
                        0: 'Partial',
                        1: 'Full',
                    },
                },
            },
            completenessOfCertification: {
                title: 'Exhaustiveness',
                type: 'bool',
            },
            missingModelComment: {
                title: 'Exhaustiveness comment',
                type: 'text',
            },
            comment: {
                title: 'Comment',
                type: 'text',
            },
            deletionComment: {
                title: 'Deletion comment',
                type: 'text',
                displayCondition: (entity, _entity2, _key, _context) => entity.deleted,
            },
            startDate: {
                title: 'Start date',
                type: 'date',
                required: true,
                params: {
                    disablePast: true,
                    minDate: new Date(),
                },
                bulk: true,
            },
            endDate: {
                title: 'End date',
                type: 'date',
                required: true,
                params: {
                    disablePast: true,
                    minDate: new Date(),
                },
                bulk: true,
            },
            modelsToCertifyEntities: {
                title: 'Models in this campaign',
                display: (field, value, entity, _props) => <ModelList id={entity.id} match={{params: {id:entity.id}}} fromMrmList={true} />,
                displayList: () => null,
            },
            messages: {
                title: 'Thread',
                display: (value, props, entity) => (
                    <Thread entityId={entity.id} entityType="model_certification_campaign" />
                ),
                displayList: () => null,
            },
            changeLogsEntities: {
                title: 'Audit trail',
                display: (field, value, entity, props) => (
                    <ChangeLog
                        field={field}
                        values={value}
                        entity={entity}
                        entityResource={'modelCertificationCampaign'}
                        props={props}
                    />
                ),
                displayList: () => null,
            },
        };
        const displayFieldsList = [
            'id',
            'modelOwner',
            'acknowledgmentOfReceipt',
            'numberOfMyModels',
            'numberOfCertifiedModels',
            'numberOfTroubles',
            'numberOfCertifiedModelsByModelOwnerDelegated',
            'status',
            'certificationCampaignState',
            'completenessOfCertification',
            'missingModelComment',
            'messages',
            'comment',
            'startDate',
            'endDate',
            'modelsToCertifyEntities',
            'deletionComment',
            'changeLogsEntities',
        ];

        const editFieldsList = [
            'startDate',
            'endDate',
            'comment',
        ];
        const views = [
            {
                instanceId: 'model_certification_campaigns',
                name: 'Model certification campaigns',
                listTitle: 'MRM certification dashboard',
                permanentFilters: {
                    'deleted': false
                },
                insertable: true,
                detailable: true,
                editable: true,
                deletable: User.hasOneRole('ROLE_MRM'),
                routeAccessControl: [ROLE.MRM],
                postSaveRedirect: 'list',
                addOperationTooltipText: 'Create a new campaign',
                bulkable: true,
                bulkEditable: true,
                bulkDeletable: true,
                displayFields: Object.keys(fieldsConfiguration).filter(f => ['messages', 'modelsToCertifyEntities', 'modelOwners',].indexOf(f) === -1),
                additionalActionButtons: (_resource) => {
                    let additionalActionButtons = [];

                    if(userHasRoleMRM() || userHasRoleADMIN()){
                        additionalActionButtons.push(
                            {
                                tooltip: 'Full export',
                                icon: 'upload',
                                className: 'upload',
                                onClick: () => Modal.open({
                                    title: "Export",
                                    content: (
                                        <EntityExportForm resource="model_certification_campaigns" label="MRM Certification dashboard"/>
                                    ),
                                    modalStyle: {width: "420px"}
                                }),
                            })
                    }

                    return additionalActionButtons;
                }
            },
            {
                instanceId: 'deleted_model_certification_campaigns',
                name: 'Deleted model certification campaigns',
                bulkEditable: true,
                permanentFilters: {
                    'deleted': true
                },
                insertable: true,
                detailable: true,
                editable: true,
                deletable: User.hasOneRole('ROLE_MRM'),
                routeAccessControl: [ROLE.MRM],
                postSaveRedirect: 'list',
                addOperationTooltipText: 'Create a new campaign',
                bulkable: true,
                displayFields: Object.keys(fieldsConfiguration).filter(f => ['messages', 'modelsToCertifyEntities', 'modelOwners',].indexOf(f) === -1)
            },
            {
                instanceId: 'my_model_certification_campaigns',
                name: 'My model certifications campaigns',
                permanentFilters: {
                    'deleted': false,
                    'my_model_certification_campaigns': true,
                },
                insertable: false,
                detailable: true,
                editable: false,
                displayFields: [
                    'id',
                    'startDate',
                    'endDate',
                    'modelOwner',
                    'status',
                    'certificationCampaignState',
                    'numberOfMyModels',
                    'numberOfCertifiedModels',
                    'numberOfTroubles',
                ],
            },
        ];

        views.map((view) => {
            const resource = new APIResource({
                ...ApiResourceDefaultParams,
                ...{
                    instanceId: view.instanceId,
                    name: view.name,
                }
            });

            resource
                // merging the right received from the api with the ones existing locally
                .setFields(fieldsConfiguration)
                .setLayout({
                    tabs: {
                        Parameters: {
                            rows: [
                                {
                                    panels: {
                                        Properties: {
                                            cols: 6,
                                            fields: [
                                                'id',
                                                'modelOwner',
                                                'modelOwners',
                                                'scopes',
                                                'establishments',
                                                'startDate',
                                                'endDate',
                                                'numberOfMyModels',
                                            ],
                                        },
                                        Process: {
                                            cols: 6,
                                            fields: [
                                                'status',
                                                'certificationCampaignState',
                                                'acknowledgmentOfReceipt',
                                                'completenessOfCertification',
                                                'missingModelComment',
                                                'numberOfCertifiedModels',
                                                'numberOfTroubles',
                                                'numberOfCertifiedModelsByModelOwnerDelegated',
                                            ],
                                        },
                                    },
                                },
                                {
                                    panels: {
                                        Models: {
                                            cols: 12,
                                            fields: ['modelsToCertifyEntities'],
                                        },
                                    },
                                },
                            ],
                        },
                        Chat: {
                            rows: [
                                {
                                    panels: {
                                        Messages: {
                                            cols: 12,
                                            fields: ['messages'],
                                        },
                                    },
                                },
                            ],
                        },
                        Versions: {
                            rows: [
                                {
                                    panels: {
                                        'Deletion': {
                                            cols: 12,
                                            fields: ['deletionComment'],
                                        },
                                    },
                                },
                            ],
                        },
                        'Audit trail': {
                            rows: [
                                {
                                    panels: {
                                        'Audit trail': {
                                            cols: 12,
                                            fields: ['changeLogsEntities'],
                                        },
                                    },
                                },
                            ],
                        },
                    },
                })
                .genListView({
                    fields: view.displayFields,
                    title: view.listTitle,
                    additionalActionButtons: view.additionalActionButtons,
                    routeAccessControl: view.routeAccessControl,
                    permanentFilters: Object.assign({}, view.permanentFilters),
                });

            if (view.insertable) {
                resource.genInsertView({
                    fields: ['modelOwners', 'scopes', 'establishments', 'startDate', 'endDate'],
                    routeAccessControl: view.routeAccessControl,
                    postSaveRedirect: view.postSaveRedirect,
                    operationTooltipText: view.addOperationTooltipText,
                    onUpdate: (
                        field,
                        value,
                        newValue,
                        entity,
                        resource,
                        context
                    ) => {
                        if (newValue) {
                            if (field === 'startDate') {
                                resource.fields.endDate.params.minDate = newValue;
                                delete resource.fields.endDate.params.maxDate;
                            } else if (field === 'endDate') {
                                resource.fields.startDate.params.maxDate = newValue;
                                delete resource.fields.startDate.params.minDate;
                            }
                        }
                        context.forceUpdate();
                    },
                });
            }

            if (view.editable) {
                resource.genEditView({
                    fields: editFieldsList,
                    routeAccessControl: view.routeAccessControl,
                });
            }

            if (view.detailable) {
                resource.genDetailView({
                    fields: displayFieldsList,
                    routeAccessControl: view.routeAccessControl,
                });
            }
            if (view.deletable) {
                resource.allowDelete({

                    component: (entity) => {
                        return (
                            <LogicalDeleteButton
                                entityType='modelCertificationCampaign'
                                entityTypeLabel={'Model certification campaign'}
                                entity={entity}
                                entityTitle={entity.id}
                                className="tooltip-top"
                                resource={resource}
                                hideReplaceButton={true}
                            />
                        );
                    },
                });
            }

            if (view.bulkable) {

                if (view.bulkEditable) {
                    resource.addBulkAction(BulkEdit, {
                        resource: resource,
                        icon: 'edit',
                        itemAccessCondition: (entity) =>
                            !entity.modelsToCertify
                            || entity.modelsToCertify
                                .map((iri) => APIResourceStore.resources.models.getObservableItemByPath(iri))
                                .some(
                                    (model) =>
                                        model &&
                                        (userHasRoleMRM() || userHasOwnershipRights(User.getId(), model))
                                ),
                        forbiddenAccessMessage: "You can't edit campaigns belonging to another owner.",
                        fields: Object.fromEntries(
                            Object.entries(
                                resource.fields
                            )
                                .filter(
                                    ([_k, v]) =>
                                        v.bulk
                                )
                                .map(
                                    ([k, v]) => (
                                        (v.resourceId =
                                            resource.id),
                                        [k, v]
                                    )
                                )
                        ),
                    });
                }

                if (view.bulkDeletable) {
                    resource.addBulkAction(BulkDelete, {
                        resource,
                        icon: 'trash-alt',
                        entityType: 'modelCertificationCampaign',
                        entityTypeLabel: 'Model certification campaign',
                    });
                }
            }
        });
    }
}
