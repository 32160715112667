import React from "react";
import { EntityDisplay } from "../../../Components/Display/EntityDisplay/EntityDisplay";
import { EntitySelect } from "../../../Components/Forms/EntitySelect/EntitySelect";
import {CONTEXT_LIST} from "../APIResource";

class EntityProvider {
  /**
   * 
   * @param {import('../APIResource').APIResourceField} field 
   * @param {*} value 
   * @param {*} entity 
   * @param {{plainText: boolean, flat: boolean, showLoading: boolean, modalLinks: boolean}} props
   * @param {*} _resource 
   * @returns 
   */
  getDisplay(field, value, entity, props, _resource, context) {
    return (
        <EntityDisplay
            {...props}
            resourceId={field.params.resource}
            instanceId={field.params.instanceId}
            resourceLabel={field.params.displayField}
            label={field.title}
            value={value}
            links={field.params.links}
            linkPath={field.params.linkPath}
            linkAction={field.params.linkAction}
            modalLinks={field.params.modalLinks || props?.modalLinks}
            parentEntity={entity}
            issueButton={'issueButton' in field ? field.issueButton : false}
            tooltip={field.params.tooltip}
            context={context}
        />
    );
  }

  getDisplayList(field, value, entity, props, resource) {
    return this.getDisplay(field, value, entity, props, resource, CONTEXT_LIST);
  }

  getEdit(field, value, onChange, entity, _routeParams, loading = false) {
    // On fait cela pour passer onChange au composant, plus bas
    const IssueButtonComponent = field.issueButtonComponent;

    return (
      <EntitySelect
        label={field.title}
        //disabled={loading}
        resourceId={field.params.resource}
        instanceId={field.params.instanceId}
        resourceLabel={field.params.displayField}
        value={value}
        onChange={onChange}
        clearable={!("required" in field && field.required === true)}
        multi={field.params.multi}
        issueButton={"issueButton" in field ? field.issueButton : true}
        issueButtonComponent={field.issueButtonComponent && <IssueButtonComponent onChange={onChange} />}
        entity={entity}
        filters={field.params.filters}
        required={"required" in field && field.requiredComputed === true}
        error={!!field.errorHelperText}
        className={field.errorHelperText ? "field-error-control" : ""}
        helperText={field.errorHelperText}
        endpoints={field.params.endpoints}
        neededFields={field.params.neededFields}
      />
    );
  }

  getFilter(options = {}) {
    const { field, value, onChange } = options;
    return (
      <EntitySelect
        resourceId={field.params.resource}
        instanceId={field.params.instanceId}
        resourceLabel={field.params.displayField}
        onChange={onChange}
        clearable={true}
        multi={true}
        filters={field.params.filters}
        value={value ? value : null}
        endpoints={field.params.endpoints}
        context='filter'
        orExistsMultiFilterNotAllowed={field.params.orExistsMultiFilterNotAllowed || false}
      />
    );
  }
}

export default new EntityProvider();
