import { APIResource } from '../../../Services/APIResource/APIResource';
import React, { Component } from 'react';
import Icon from "@material-ui/core/Icon";
import Http from "../../../Services/Http";
import Alert from "../../../Services/Alert";
import {DocumentButtons, DocumentManager} from "../DocumentManager/DocumentManager";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
    CircularProgress,
    FormControl,
    FormLabel,
    Input,
    InputLabel,
    TableBody,
    TextareaAutosize
} from "@material-ui/core";
import {Link} from "react-router-dom";
import {observer} from "mobx-react";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ParameterStore, {
    getParamByIri,
    userHasContributingRights, userHasRoleMRM, userHasSpecificRole,
    userHasValidatorRights, userHasValidatorTeamRights,
    userValidatorTeamManagedRights
} from "../../../Store/ParameterStore";
import User, {ROLE as Role} from "../../../Services/User/User";
import Modal from '../../../Services/Modal';
import { ModalContent } from '../../Modal/ModalContent';
import { LoadingSpinner } from '../../LoadingIndicator/LoadingSpinner';
import { ActionButton } from '../../Modal/ActionButton';
import { ButtonBar } from '../../Modal/ButtonBar';
import {
    documentAllowedActionAdd,
    documentAllowedActionDelete,
    documentAllowedActionShow,
    REVIEW_STATUS_ASSESSMENT,
    REVIEW_STATUS_ASSESSMENT_AWAITING,
    REVIEW_STATUS_CLOSED,
    REVIEW_STATUS_FINALIZATION,
    REVIEW_STATUS_FINALIZATION_AWAITING,
    REVIEW_STATUS_SIGN_OFF,
    REVIEW_STATUS_SIGN_OFF_AWAITING
} from "../../../Admin/ReviewAdmin";
import FieldProviderStore from "../../../Services/APIResource/FieldProviders/__FieldProviderStore";
import Navigation from "../../../Services/Navigation";
import {OpenModal} from "../../Modal/OpenModal";
import APIResourceStore from "../../../Store/APIResourceStore";
import {getIdFromIri} from "../../../Services/utils";

const TYPE_MEETING_CREATION   = 0;//Meeting Creation
const TYPE_COMMITTEE_CREATION = 5;//Committee Creation
const TYPE_MEETING_UPDATE     = 6;//Meeting Update
const TYPE_COMMITTEE_UPDATE   = 7;//Committee Update
const TYPE_DOCUMENT_UPLOAD    = 1;//Document upload
const TYPE_STATUS_CHANGE      = 2;//Status change
const TYPE_DOCUMENT_REMOVE    = 3;//Document removal
const TYPE_COMMENT            = 4;//Comment

let typesMap = {
    TYPE_MEETING_CREATION: 'Meeting Creation',
    TYPE_COMMITTEE_CREATION: 'Committee Creation',
    TYPE_DOCUMENT_UPLOAD: 'Document upload',
    TYPE_STATUS_CHANGE: 'Status change',
    TYPE_DOCUMENT_REMOVE: 'Document removal',
    TYPE_COMMENT: 'Comment',
};

typesMap = Object.fromEntries(Object.entries(typesMap).map(([k, v]) => {
    return [eval(k), v];
}));

const ReviewEventTypesMap = typesMap;

class ReviewEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filteredEvents: [],
            filter: null,
            progress: false,
            openMenu: false,
            anchorEl: React.createRef(),
            entity: this.props.entity
        };
        this.eventsResource = new APIResource({
            id: 'review_events',
        });
        this.export = this.export.bind(this);
        this.filter = this.filter.bind(this);
    }

    componentDidMount() {
        this.setState({filteredEvents: this.props.entity.reviewEventsEntities, entity: this.props.entity});
        this.filter();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.filter !== prevState.filter || this.props.entity !== prevProps.entity || this.state.entity !== prevState.entity) {
            this.filter();
        }
    }

    handleClick = event => {
        this.setState({ openMenu: true, anchorEl: event.currentTarget });
    };

    handleClose = event => {
        this.setState({ openMenu: false, anchorEl: null });
    };

    getIcon(event){
        if(event.type === TYPE_MEETING_CREATION || event.type === TYPE_MEETING_UPDATE) {//Meeting Creation
            return 'fa-calendar-alt';
        }else if(event.type === TYPE_COMMITTEE_CREATION || event.type === TYPE_COMMITTEE_UPDATE){//Meeting Creation
            return 'fa-gavel';
        }else if(
            event.type === TYPE_DOCUMENT_UPLOAD//Document upload
            || event.type === TYPE_DOCUMENT_REMOVE//Document removal
        ){
            return 'fa-file-import';
        }else if(event.type === TYPE_STATUS_CHANGE){//Status change
            return 'fa-sync-alt';
        }else if(event.type === TYPE_COMMENT){//Comment
            return 'fa-comment-alt';
        }
    }

    filter() {
        let self = this;
        let filteredEvents = [];
        if (this.state.filter === null || this.state.filter === -1) {
             filteredEvents = self.state.entity.reviewEventsEntities;
        } else {
            filteredEvents = self.state.entity.reviewEventsEntities.filter((event) => {
                let filters = [this.state.filter];
                // On gère les types MEETING/COMMITTEE "CREATION" et "UPDATE" de la même façon
                if (this.state.filter === TYPE_COMMITTEE_CREATION) {
                    filters = [TYPE_COMMITTEE_CREATION, TYPE_COMMITTEE_UPDATE];
                }
                if (this.state.filter === TYPE_MEETING_CREATION) {
                    filters = [TYPE_MEETING_CREATION, TYPE_MEETING_UPDATE];
                }
                if (filters.includes(event.type)) {
                    return event;
                }
            });
        }

        //Check access right
        let events = [];
        filteredEvents.forEach((event, index) => {
            if(
                userHasValidatorRights(User.getId(), self.state.entity)
                || userValidatorTeamManagedRights(User.profile.managedScopes, self.state.entity)
                || userHasRoleMRM()
            ){
                events.push(event);
                return;
            }

            if(
                (
                    event.type === TYPE_MEETING_CREATION
                    || event.type === TYPE_STATUS_CHANGE
                    || event.type === TYPE_COMMENT
                )
                && userHasValidatorTeamRights(User.profile.teams, self.state.entity)
            ){
                events.push(event);
                return;
            }

            if(
                (
                    event.type === TYPE_MEETING_CREATION
                    || event.type === TYPE_STATUS_CHANGE
                    || event.type === TYPE_COMMENT
                )
                && (
                    userHasContributingRights(User.getId(), self.state.entity)
                    || self.state.entity.amIMemberOfDeveloperTeam
                    || self.state.entity.amIModelOwner
                    || self.state.entity.amIModelOwnerDelegated
                )
            ){
                events.push(event);
            }

            if (event.type === TYPE_COMMENT && event.user === `/api/users/${User.getId()}`) {
                events.push(event);
            }

            if(event.type === TYPE_DOCUMENT_UPLOAD) {
                //Un auteur voit toujours ses propres documents
                if(
                    event.document !== null
                    && event.document.author
                    && event.document.author === '/api/users/' + User.getId()
                ){
                    events.push(event);
                    return;
                }
                if (
                    documentAllowedActionShow(self.state.entity, event.document)
                ) {
                    events.push(event);
                }
            }


        })

        this.setState({filteredEvents: events});
    }

    export(){
        this.setState({progress: true});
        let exportEventIds = this.state.filteredEvents.map(event => event.id);
        Http.post('review_events/export', {ids: exportEventIds}).then((response) => {
            if(response.status === 'ERROR'){
                Alert.show({ message : response.message, type: 'danger'});
            }else if(response.status === 'OK'){
                if(response.route && response.fileName){
                    Http.openFile(response.route, response.fileName);
                }
            }else{
                Alert.show({ message : "File can't be downloaded"});
            }
            this.setState({progress: false});
        });
    }

    openCommentModal(){
        let self = this;
        Modal.open({
            title: "New comment",
            content: (
                <ReviewEventComment 
                    entity={self.state.entity}
                    resource={this.props.resource} 
                    onChange={(entity) => this.setState({entity})} 
                />)
        });
    }

    openReviewCommitteeModal(){
        let self = this;
        Modal.open({
            title: "New Committee",
            content: (
                <ReviewCommitteeForm
                    entity={self.state.entity}
                    resource={this.props.resource}
                    onUpdate={(entity) => {
                        self.setState({entity});
                    }}
                />),
            modalStyle: { width: "800px", height: "600px" },
        });
    }

    openDissociateReviewCommitteeModal(event){
        let self = this;
        Modal.open({
            title: "Dissociate Committee",
            content: (
                <ReviewCommitteeDissociateForm
                    entity={self.state.entity}
                    event={event}
                    resource={this.props.resource}
                />),
            modalStyle: { width: "420px" },
        });
    }

    deleteComment(event){
        let self = this;
        this.eventsResource.apiDelete(event).then(() => {
            Alert.show({message: 'Comment successfully deleted.'});
            this.props.resource.getItem(self.state.entity.id, true);
        });
    }

    documentUpdate(entity){
        this.setState({entity});
    }

    renderItem(index){
        const event = this.state.filteredEvents[index];
        return <TableRow key={`event-${index}`} className={ (index%2 ? 'odd' : 'even') + ' type-'+event.type}>
            <TableCell>
                <Grid container>
                    <Icon className={"fa " + this.getIcon(event)}/>
                    <span className={'cell-content'}>
                        <div className="left">
                            <span className={'date'}>{event.dateString}</span><br/>
                            <span className={'title'}>{event.title}</span><br/>
                            <span className={'description'}>{event.description}</span>
                        </div>
                        <div className="right">
                            {
                                event.document && event.type === TYPE_DOCUMENT_UPLOAD ?
                                    <DocumentButtons
                                        key={'document_' + index}
                                        entity={this.state.entity}
                                        document={event.document}
                                        allowedAction={(review, document, action) => {
                                            if(action === 'show'){
                                                return documentAllowedActionShow(review, document);
                                            }else if(action === 'add'){
                                                return documentAllowedActionAdd(review, document);
                                            }else if(action === 'delete'){
                                                return documentAllowedActionDelete(review);
                                            }
                                            return false;
                                        }}
                                        onDeleteCallback={() => {
                                            this.props.resource.apiGetOne(this.state.entity.id, true).then((entity) => {
                                                this.setState({entity});
                                            });
                                        }}
                                    />
                                    : null
                            }
                            {
                                event.reviewCommittee && [TYPE_MEETING_CREATION, TYPE_COMMITTEE_CREATION, TYPE_MEETING_UPDATE, TYPE_COMMITTEE_UPDATE].includes(event.type) ?
                                    <div className={"thread-actions"}>
                                        <Link
                                            to={
                                                '/resource/review_committees/' +
                                                event.reviewCommittee['id'] +
                                                '/detail'
                                            }
                                        >
                                            <Button variant="contained" color="primary" className="button-table show">Detail</Button>
                                        </Link>
                                        { userHasRoleMRM() ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className="button-meeting"
                                                style={styles.actionLink}
                                                onClick={() => this.openDissociateReviewCommitteeModal(event)}
                                            >
                                                Dissociate
                                            </Button>
                                            : null }
                                    </div>
                                    : null
                            }
                            {
                                event
                                && event.type === TYPE_COMMENT
                                && (
                                    event.author === '/api/users/' + User.getId()
                                    || userHasValidatorRights(User.getId(), this.state.entity)
                                    || userHasRoleMRM()
                                ) ?
                                    <div className={"thread-actions"}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="button-meeting"
                                            style={styles.actionLink}
                                            onClick={() => this.deleteComment(event)}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                    : null
                            }
                        </div>
                    </span>
                </Grid>
            </TableCell>
        </TableRow>;
    }

    renderEvents(events){
        let items = [];
        events.forEach((event, index) => {
            items.push(this.renderItem(index))
        })

        return <TableBody>{items}</TableBody>;
    }

    renderHeader(){
        let ths = [];
        for (let i in this.props.cols) {
            ths.push(
                <TableCell key={'th_' + i}>
                    {typeof this.props.cols[i].label === 'function'
                        ? this.props.cols[i].label()
                        : this.props.cols[i].label}
                </TableCell>
            );
        }
        if (this.props.actions) {
            ths.push(
                <TableCell key={'th_' + this.props.cols.length}>
                    {this.props.actionLabel
                        ? this.props.actionLabel
                        : 'Actions'}
                </TableCell>
            );
        }

        return (
            <TableHead>
                <TableRow>{ths}</TableRow>
            </TableHead>
        );
    }

    render() {
        const id = this.state.openMenu ? 'menu-list-grow' : undefined;
        const { anchorEl, openMenu } = this.state;
        let predictedSubType = null;
        let predictedDocumentCategory = null;
        if(
            this.state.entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT)
            || this.state.entity.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING)
        ){
            predictedSubType = 'REVIEW_COMMITTEE_SUB_TYPE_KICK_OFF_MEETING';
            predictedDocumentCategory = 'DOCUMENT_CATEGORY_DRAFT_REPORT';
        }else if(
            this.state.entity.reviewStatus === ParameterStore(REVIEW_STATUS_SIGN_OFF)
            || this.state.entity.reviewStatus === ParameterStore(REVIEW_STATUS_SIGN_OFF_AWAITING)
        ){
            predictedSubType = 'REVIEW_COMMITTEE_SUB_TYPE_CLOSING_MEETING';
            predictedDocumentCategory = 'DOCUMENT_CATEGORY_FINAL_REPORT';
        }else if(
            this.state.entity.reviewStatus === ParameterStore(REVIEW_STATUS_FINALIZATION)
            || this.state.entity.reviewStatus === ParameterStore(REVIEW_STATUS_FINALIZATION_AWAITING)
        ){
            predictedSubType = null;
            predictedDocumentCategory = 'DOCUMENT_CATEGORY_POST_COMMITTEE_REPORT';
        }
        return (
            <div className={'review-event'}>
                <div className={"actions"}>
                    <Grid
                        container
                        spacing={2}
                        className={'container detail-inner-new-button'}
                    >
                        <div className={'menu'}>
                            <div className={'buttons'}>
                                <Button
                                    className={"button-list"}
                                    aria-describedby={id}
                                    open={openMenu}
                                    aria-haspopup="true"
                                    onClick={this.handleClick}
                                >
                                    <Icon className={`fa fa-filter`}/>
                                    <span className={'separator'}>|</span>
                                    <span className={'menu-text'}>{this.state.filter !== null ? (this.state.filter >= 0 ? ReviewEventTypesMap[this.state.filter] : 'All') : 'Choose a type'}</span>
                                    <Icon className={`fa fa-angle-down`}/>
                                </Button>
                                <Popper
                                    open={openMenu}
                                    id={id}
                                    anchorEl={anchorEl}
                                    role={undefined} transition disablePortal
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={this.handleClose}>
                                                    <MenuList id="menu-list-grow">
                                                        <MenuItem
                                                            onClick={() => this.setState({openMenu: false, anchorEl: null, filter: -1})}
                                                            value={-1}
                                                        >
                                                            All
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => this.setState({openMenu: false, anchorEl: null, filter: TYPE_MEETING_CREATION})}
                                                            value={TYPE_MEETING_CREATION}
                                                        >
                                                            { ReviewEventTypesMap[TYPE_MEETING_CREATION] }
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => this.setState({openMenu: false, anchorEl: null, filter: TYPE_COMMITTEE_CREATION})}
                                                            value={TYPE_COMMITTEE_CREATION}
                                                        >
                                                            { ReviewEventTypesMap[TYPE_COMMITTEE_CREATION] }
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => this.setState({openMenu: false, anchorEl: null, filter: TYPE_DOCUMENT_UPLOAD})}
                                                            value={TYPE_DOCUMENT_UPLOAD}
                                                        >
                                                            { ReviewEventTypesMap[TYPE_DOCUMENT_UPLOAD] }
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => this.setState({openMenu: false, anchorEl: null, filter: TYPE_DOCUMENT_REMOVE})}
                                                            value={TYPE_DOCUMENT_REMOVE}
                                                        >
                                                            { ReviewEventTypesMap[TYPE_DOCUMENT_REMOVE] }
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => this.setState({openMenu: false, anchorEl: null, filter: TYPE_STATUS_CHANGE})}
                                                            value={TYPE_STATUS_CHANGE}
                                                        >
                                                            { ReviewEventTypesMap[TYPE_STATUS_CHANGE] }
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => this.setState({openMenu: false, anchorEl: null, filter: TYPE_COMMENT})}
                                                            value={TYPE_COMMENT}
                                                        >
                                                            { ReviewEventTypesMap[TYPE_COMMENT] }
                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                                <Button
                                    onClick={this.export}
                                    aria-label="Export"
                                    disabled={this.state.progress}
                                    size={'small'}
                                    className={'export'}
                                >
                                    { this.state.progress ?
                                        <Icon className={`fa fa-spinner fa-spin`}/>
                                        :
                                        <Icon className={"fa fa-upload"}/>
                                    }
                                    Export
                                </Button>
                                { this.state.entity.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED) && !userHasSpecificRole(Role.IG) ?
                                    <>
                                        <DocumentManager
                                            values={this.state.entity.documentEntities}
                                            entity={this.state.entity}
                                            entityResource={this.props.resource.instanceId}
                                            allowedCategory={!(this.state.entity.amIModelOwner || this.state.entity.amIModelOwnerDelegated)}
                                            onlyButton={true}
                                            onUpdate={(entity) => this.documentUpdate(entity)}
                                            addButton={{
                                                icon: "fa-file-import",
                                                color: "#ffffff"
                                            }}
                                            defaultValues={{
                                                category: predictedDocumentCategory ? ParameterStore(predictedDocumentCategory) : null
                                            }}
                                        />
                                        <OpenModal
                                            parentInstanceId="reviews"
                                            parentId={this.state.entity.id}
                                            instanceId="review_committees"
                                            routeParams={{
                                                reviewId: this.state.entity.id,
                                                typeId: ParameterStore('REVIEW_COMMITTEE_TYPE_MEETING') ? getParamByIri(ParameterStore('REVIEW_COMMITTEE_TYPE_MEETING')).id : null,
                                                subTypeId: predictedSubType && ParameterStore(predictedSubType) ? getParamByIri(ParameterStore(predictedSubType)).id : null
                                            }}
                                            context="add"
                                            postSaveRedirectUrl={'/resource/reviews/'+this.state.entity.id+'/detail'}
                                            modalTitle="New meeting"
                                            button={<Button
                                                variant="contained"
                                                color="primary"
                                                className="button-meeting"
                                            >
                                                <Icon className={'fa fa-calendar-alt'} style={{color: '#ffffff'}}/>
                                                New meeting
                                            </Button>}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="button-meeting"
                                            style={styles.actionLink}
                                            onClick={this.openReviewCommitteeModal.bind(this)}
                                        >
                                            <Icon className={'fa fa-gavel'} style={{color: '#ffffff'}}/>
                                            New committee
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="button-general comment"
                                            onClick={this.openCommentModal.bind(this)}
                                        >
                                            <Icon className={'fa fa-comment-alt'} style={{color: '#ffffff'}}/>
                                            New comment
                                        </Button>
                                    </>
                                :
                                userHasRoleMRM() ?
                                    <div
                                        className={'new-button'}
                                    >
                                        <DocumentManager
                                            values={this.state.entity.documentEntities}
                                            entity={this.state.entity}
                                            entityResource={this.props.resource.instanceId}
                                            allowedCategory={!(this.state.entity.amIModelOwner || this.state.entity.amIModelOwnerDelegated)}
                                            onlyButton={true}
                                            onUpdate={(entity) => this.documentUpdate(entity)}
                                            addButton={{
                                                icon: "fa-file-import",
                                                color: "#ffffff"
                                            }}
                                            defaultValues={{
                                                category: predictedDocumentCategory ? ParameterStore(predictedDocumentCategory) : null
                                            }}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="button-meeting"
                                            style={styles.actionLink}
                                            onClick={this.openReviewCommitteeModal.bind(this)}
                                        >
                                            <Icon className={'fa fa-gavel'} style={{color: '#ffffff'}}/>
                                            New committee
                                        </Button>
                                    </div>
                                : null }
                            </div>
                        </div>
                    </Grid>
                </div>
                <Grid item xs={12}>
                    <Paper className="container-no-padding table-scroll">
                        <Table
                            className={'table-display small'}
                            size={'small'}
                        >
                            {this.renderEvents(this.state.filteredEvents)}
                        </Table>
                    </Paper>
                </Grid>
            </div>
        );
    }
};
class ReviewEventComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            disabled: false,
        };
    }

    comment() {
        let self = this;
        let commentEvent = {
            title: this.state.title,
            description: this.state.description,
            type: TYPE_COMMENT,
            reviewId: this.props.entity.id,
        };
        this.setState({ disabled: true });
        Http.post('review_events/comment', commentEvent).then((data) => {
            this.props.resource.apiGetOne(self.props.entity.id, true).then((entity) => {
                this.props.onChange(entity);
                this.setState({ disabled: false });
                Modal.close();
            });
        });
    }

    render() {
        return (
            <ModalContent className="event-modal-comment">
                <FormControl>
                    <InputLabel htmlFor="my-input">Title*</InputLabel>
                    <Input 
                        id="comment-title"
                        aria-describedby="comment-title" 
                        value={this.state.title ? this.state.title : ''}
                        onChange={(event) => this.setState({ title: event.target.value })} />
                </FormControl>
                <FormControl className="container_textarea">
                    <FormLabel>Description</FormLabel>
                    <TextareaAutosize
                        value={this.state.description ? this.state.description : ''}
                        onChange={(event) => this.setState({ description: event.target.value })}
                        rows={5}
                        rowsMax={10}
                    />
                </FormControl>
                <ButtonBar>
                    <ActionButton
                        disabled={this.state.disabled}
                        onClick={this.comment.bind(this)}
                    >
                        Send
                        {this.state.disabled && <LoadingSpinner />}
                    </ActionButton>
                </ButtonBar>
            </ModalContent>
        );
    }
}  

export default observer(ReviewEvent);

const styles = {
    actionLink: {
        marginLeft: 5,
        zIndex: 0,
    },
};

class ReviewCommitteeForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            reviewCommittee: null,
            processing: false,
        }
    }

    handleChange(fieldId, value){
        if(fieldId == 'changeLogComment') {
            this.setState({changeLogComment: value});
        } else {
            let fields = this.state.fields;
            fields[fieldId] = value;
            this.setState({fields: fields});
        }
    }

    save(){
        let self = this;
        if(self.state.reviewCommittee === null){
            Alert.show({message: 'Please select a committee.', type: 'danger'});
            return;
        }
        self.setState({processing: true});
        APIResourceStore.resources.review_committees.getItemFromResourcePath(self.state.reviewCommittee).then((reviewCommittee) => {
            if(Array.isArray(reviewCommittee.reviews)){
                reviewCommittee.reviews.push(`/api/reviews/${self.props.entity.id}`);
            }else{
                reviewCommittee.reviews = [`/api/reviews/${self.props.entity.id}`];
            }
            APIResourceStore.resources.review_committees.apiPut(reviewCommittee).then(() => {
                Alert.show({message: 'Committee successfully associated.'});
                self.props.resource.getItem(self.props.entity.id, true).then((entity) => {
                    self.props.onUpdate(entity);
                });
                self.setState({processing: false});
                Modal.close();
            });
        })
    }

    genForm(){
        let fields = [];
        let self = this;

        let reviewCommittee = {
            title: 'Choose a committee',
            type: 'entity',
            params: {
                multi: false,
                resource: 'review_committees',
                displayField: 'displayString',
                neededFields: ['type'],
                filters: (committee, entity) => {
                    return committee.type === ParameterStore('REVIEW_COMMITTEE_TYPE_COMMITTEE') && (!entity.reviewCommittees || !entity.reviewCommittees.includes(committee['@id']));
                }
            },
            issueButton: false,
            required: false,
            disabled: true
        };

        fields.push(<div className="field" key={'field-reviewCommittee'}>{
            FieldProviderStore[reviewCommittee.type].getEdit(
                reviewCommittee,
                this.state.reviewCommittee,
                (value) => {
                    this.setState({reviewCommittee: value})
                },
                self.props.entity,
                {}
            )
        }</div>);

        return(
            <div className="bulk-associate-validation-family">
                <div className="fields main-fields" style={{position:'relative',height: "440px"}}>
                    {fields}
                    <ActionButton
                        onClick={this.save.bind(this)}
                        loading={this.state.processing}
                        style={{position:'absolute', bottom:'0'}}
                    >
                        {!this.state.processing && 'Choose this existing committee'}
                    </ActionButton>
                </div>
                {
                    userHasRoleMRM() &&
                    <div className="fields">
                        {
                            this.state.processing ?
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className="choose"
                                >
                                    <CircularProgress size={24}/>
                                </Button>
                                :
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="choose"
                                    onClick={() => {
                                        Modal.close();
                                        Navigation.router.history.push(`/resource/review_committees/add/${this.state.entity.id}/${getParamByIri(ParameterStore('REVIEW_COMMITTEE_TYPE_COMMITTEE')).id}`);
                                    }}
                                >
                                    Create a new committee
                                </Button>
                        }
                    </div>
                }
            </div>
        );
    }

    render(){
        return(this.genForm());
    }
}

class ReviewCommitteeDissociateForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            processing: false,
        }
    }

    dissociate(){
        let self = this;
        let entity = self.props.entity;
        self.setState({processing: true});
        Http.post('review/dissociate/committee', {
            review: entity.id,
            committee: self.props.event.reviewCommittee.id
        }).then(() => {
            Alert.show({message: 'Committee successfully dissociated.'});
            self.props.resource.getItem(self.props.entity.id, true);
            self.setState({processing: false});
            Modal.close();
        });
    }

    render(){
        return <div className="dissociate-committee">
            <div className="fields main-fields">
                <p>Are you sure you want to dissociate this meeting ?</p>
                <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Button variant="contained" color="secondary" className="save button-general" onClick={Modal.close}>
                        Cancel
                    </Button>
                    {
                        this.state.processing === true
                            ?
                            <Button
                                style={{ marginTop: 10 }}
                                variant="contained"
                                color="secondary"
                                className="save button-general"
                            >
                                <CircularProgress size={20} />
                            </Button>
                            :
                            <Button variant="contained" color="secondary" className="save button-general" onClick={() => this.dissociate()}>
                                Dissociate
                            </Button>
                    }
                </div>
            </div>
        </div>;
    }
}