import { Detail } from './Detail';
import { Edit } from './Edit';
import { Init } from './Init';
import RouteStore, { Route } from '../../Store/RouteStore';
import React from 'react';

RouteStore.routes.resource_mras = new Route({
    path: '/resource/mras/add',
    component: (props) => <Init {...props} />,
});

RouteStore.routes.resource_mras_update = new Route({
    path: '/resource/mras/:id/update',
    component: (props) => <Edit {...props} />,
});

RouteStore.routes.resource_mras_detail = new Route({
    path: '/resource/mras/:id/detail',
    component: (props) => <Detail {...props} />,
});
