import moment from "moment";

class DateFormatter{
    constructor() {
        this.options = {year: 'numeric', month: 'long', day: 'numeric' };
    }

    /**
     * Attention, ne supprime pas la TZ, mais la fixe à celle du client.
     * 
     * @todo pas sûr que ce soit le comportement voulu ?
     * @see onlyDateInUTC qui correspond peut-être + à la "suppression de la TZ"
     *      mais sans gestion du Time.
     * 
     * @param {date} d 
     * @returns {Date}
     */
    removeZone(d){
        d = moment.parseZone(d);
        return new Date(d);
    }

    /**
     * 
     * @param {Date} date 
     * @param {boolean} withTime 
     * @param {boolean} withSeconds 
     * @returns {string}
     */
    toString(date, withTime=false, withSeconds=false) {
        const dateParts = date.toLocaleDateString('en-EN', this.options).split(' ');
        const dateString = dateParts[1].replace(',','') + ' ' + dateParts[0] + ' ' + dateParts[2];
        if (!withTime){
            return dateString;
        } else if (!withSeconds) {
            return dateString + ' ' + ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);
        } else {
            return dateString + ' ' + ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2) + ':' + ("0" +date.getSeconds()).slice(-2);
        }
    }

    dayIsoToString(dateString){
        try{
            let date = this.onlyDate(dateString);
            return this.toString(date);
        }catch (e) {
            console.info(e);
        }
    }

    dayHourIsoToString(dateString){
        try{
            let date = this.removeZone(dateString);
            return this.toString(date, true);
        }catch (e) {
            console.info(e);
        }
    }

    fullIsoToString(dateString){
        try{
            let date = this.removeZone(dateString);
            return this.toString(date, true, true);
        }catch (e) {
            console.info(e);
        }
    }

    onlyDate(date){
        if(!date){ return date; }
        date = this.removeZone(date);
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    }

    /**
     * Renvoie la date en équivalent UTC.
     * 
     * ie renvoie "Mon Apr 19 2021 02:00:00 GMT+0200", soit 19/04/2021 00:00 équivalent UTC
     * 
     * onlyDate renvoie la date du jour, mais avec l'offset de la timezone du client.
     * Donc si on veut le 31/12/2000 on obtient : 31/12/2000 00:00 -02:00 par ex si
     * on est dans la TZ -2h.
     * Hors lors de la conversion en ISO pour le back on envoie la date UTC qui se
     * transforme en 31/12/2000 22:00 +00:00 !!!! et donc on perd un jour !!
     * 
     * @param {*} date 
     */
    onlyDateInUTC(date){
        if (!date) return date;
        // On reparse en UTC (Z) sans considérer la TZ du client.
        let d = moment.utc(`${this.dateToRfc3339(date)}T${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}:${("0" + date.getSeconds()).slice(-2)} Z`).format('YYYY-MM-DD HH:mm:ss');
        date = new Date(d);
        if (!(date instanceof Date && !isNaN(date))) return date;
        return new Date(date.toISOString());
    }

    /**
     * Renvoie une date au type "RFC3339" : "2016-09-01T10:11:12+0000"
     * @param {Date} date
     * @returns {string} YYYY-mm-dd
     */
    dateToRfc3339(date) {
        let twoDigitsMonth =  ("0" + (date.getMonth() + 1)).slice(-2);
        let twoDigitsDay =  ("0" + (date.getDate())).slice(-2);
        return `${date.getFullYear()}-${twoDigitsMonth}-${twoDigitsDay}`;
    }
    
    /**
     * Renvoie une datetime au type "RFC3339" : "2016-09-01T10:11:12+0000"
     * @param {Date} date
     * @returns {string} YYYY-mm-ddTHH:MM:ss
     */
    datetimeToRfc3339(date) {
        let twoDigitsMonth =  ("0" + (date.getMonth() + 1)).slice(-2);
        let twoDigitsDay =  ("0" + (date.getDate())).slice(-2);
        let twoDigitsHours =  ("0" + (date.getHours())).slice(-2);
        let twoDigitsMinutes =  ("0" + (date.getMinutes())).slice(-2);
        let twoDigitsSeconds =  ("0" + (date.getSeconds())).slice(-2);
        return `${date.getFullYear()}-${twoDigitsMonth}-${twoDigitsDay}T${twoDigitsHours}:${twoDigitsMinutes}:${twoDigitsSeconds}`;
    }

    dateToIso8601(date){
        return moment.parseZone(date).format('DD MMMM YYYY');
    }

    dateToIso8601Full(date){
        return moment.parseZone(date).format('DD MMMM YYYY HH:mm:ss');
    }

    getYear(dateString){
        try{
            let date = this.removeZone(dateString);
            return date.getFullYear();
        }catch (e) {
            console.info(e);
        }
    }

    /**
     * Ajoute (ou retire si nbDays négatif) nbDays à la date du jour si aucune date n'est mise en paramètre
     *
     * @param nbDays
     * @param date
     * @returns {*}
     */
    addDays(nbDays, date = null){
        let startDate = new Date();
        if(date){
            startDate = date;
        }
        startDate.setDate(startDate.getDate() + nbDays);
        return this.dateToIso8601(startDate);
    }

    addDaysDateObject(nbDays, date = null){
        let startDate = new Date();
        if(date){
            startDate = date;
        }
        startDate.setDate(startDate.getDate() + nbDays);
        return startDate;
    }

    dayStringToDateObject(dateStr){
        return this.parseDate(dateStr + "T00:00:00+0000");
    }

    parseDate(str_date) {
        return new Date(Date.parse(str_date));
    }

    frToUsFormat(frDayString){
        let tab = frDayString.split('-');
        return tab[1] + '-' + tab[2] + '-' + tab[0];
    }
}
export default new DateFormatter();