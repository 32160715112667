import { APIResource } from '../../../Services/APIResource/APIResource';
import _ from 'lodash';
import LocalNotification from '../../../Services/LocalNotification';
import { differenceInMinutes, differenceInSeconds } from 'date-fns';
import User from '../../../Services/User/User';
import String from '../../../Services/String';
import Config from '../../../Config/App';
import React, { Component } from 'react';
import SendIcon from '@material-ui/icons/Send';

import {
    Badge,
    Button,
    Paper,
    Grow,
    Popper,
    CircularProgress,
    LinearProgress,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Typography,
    Divider,
    TextField,
    IconButton,
    InputBase,
} from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";

export class Thread extends Component {
    handle = null;
    /**
     * props : {
     *    entityType,
     *    entityId,
     * }
     **/

    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            isLoadingMessages: false,
            isSendingMessage: false,
        };
        this.renderList.bind(this);
        this.pauseRefresh = false;
        this.listRef = React.createRef();

        this.messagesResource = new APIResource({
            id: 'messages',
        });
        this.entityResource = new APIResource({
            id: props.entityType,
        });
    }

    componentDidMount() {
        this.getMessages({ forceReload: true });
        this.handle = setInterval(() => {
            if (this.pauseRefresh) {
                return;
            }
            if (
                User.lastActivity &&
                differenceInMinutes(new Date(), User.lastActivity) > 20
            ) {
                return;
            }


            this.getMessages({ forceReload: false });
        }, Config.threadRefeshInterval || 30000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.topic !== prevProps.topic)) {
            this.getMessages({ forceReload: true });
        }
    }

    componentWillUnmount() {
        clearInterval(this.handle);
    }

    getMessages = (options = { forceReload: true }) => {
        const { entityType, entityId } = this.props;
        this.pauseRefresh = true;
        options.rowsPerPage = 1000;

        if(this.props.topic){
            options.filters = {
                'topic': `/api/topics/${this.props.topic.id}`
            };
        }else{
            options.filters = {
                [_.camelCase(entityType)]: `/api/${entityType}s/${entityId}`,
            };
        }
        this.setState({ isLoadingMessages: true });
        return this.messagesResource
            .apiGetCollection(options)
            .then((messages) => {
                if (!messages) {
                    return;
                }
                const oldMessages = this.state.messages;
                const newMessages = _.differenceBy(
                    messages,
                    oldMessages,
                    (n) => n.id
                );
                this.setState({ messages });
                if (newMessages.length) {
                    this.notifyOfNewMessages(newMessages);
                    this.scrollToBottom();
                }
                return messages;
            })
            .finally(() => {
                this.setState({ isLoadingMessages: false });
                this.pauseRefresh = false;
            });
    };

    notifyOfNewMessages(messages) {
        return;
        // @todo work in progress manage case of many tabs. also case of single new message
        const newNotif = messages.filter((n) => n.status === 'new');
        if (!newNotif || !newNotif.length) {
            return;
        }
        if (newNotif && newNotif.length < 5) {
            newNotif.forEach((n, index) =>
                setTimeout(() => {
                    LocalNotification.notify(n);
                }, index * 2000)
            );
        } else {
            LocalNotification.notify({
                fromUser: { fullName: 'MRM System' },
                content: `You have ${newNotif.length} new messages`,
                link: '/dashboard',
            });
        }
    }

    handleMessageSubmit = (event) => {
        const { messages, content } = this.state;
        const { entityId, entityType, topic } = this.props;
        if (!content) {
            return;
        }
        this.setState({ isSendingMessage: true });
        let messageEntity = {
            from: `/api/users/${User.getId()}`,
            content
        }
        if(this.props.topic){
            messageEntity['topic'] = `/api/topics/${topic.id}`;
        }else{
            messageEntity[_.camelCase(entityType)] = `/api/${entityType}s/${entityId}`;
        }
        this.messagesResource
            .apiPost(messageEntity)
            .then(async (message) => {
                const updatedList = _.uniq(messages.concat([message]));
                this.setState({
                    messages: updatedList,
                    content: '',
                });
                this.scrollToBottom();
                this.getMessages({ forceReload: true });
            })
            .finally(() => this.setState({ isSendingMessage: false }));
    };

    renderOddItem(index) {
        const message = this.state.messages[index];
        return (
            <React.Fragment key={index}>
                <ListItem
                    alignItems="flex-start"
                    className={'message odd'}
                    style={{ backgroundColor: 'rgba(94, 33, 127, 0.08)' }}
                >
                    <ListItemText
                        component={'div'}
                        primary={
                            <Typography
                                component="div"
                                variant="caption"
                                color="textPrimary"
                                className={'avatar-container'}
                            >
                                <ListItemAvatar
                                    className={'avatar'}
                                >
                                    <Avatar>{message.fromInitials || ''}</Avatar>
                                </ListItemAvatar>
                                {message.fromString}
                            </Typography>
                        }
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="textPrimary"
                                >
                                    {String.nlToBr(message.content)}
                                </Typography>
                                <Typography variant="caption" component="span">
                                    {message.dateString}
                                </Typography>
                            </React.Fragment >
                        }
                    />
                </ListItem>
            </React.Fragment>
        );
    }

    renderEvenItem(index) {
        const message = this.state.messages[index];
        return (
            <React.Fragment key={index}>
                <ListItem
                    alignItems="flex-start"
                    style={{ textAlign: 'right', backgroundColor: 'rgba(94, 33, 127, 0.03)' }}
                    selected={false}
                    className={'message even'}
                >
                    <ListItemText
                        primary={
                            <Typography
                                component="div"
                                variant="caption"
                                color="textPrimary"
                                className={'avatar-container'}
                            >
                                <ListItemAvatar
                                    className={'avatar'}
                                >
                                    <Avatar>
                                        {message.fromInitials || ''}
                                    </Avatar>
                                </ListItemAvatar>
                                {message.fromString}
                            </Typography>
                        }
                        secondary={
                            <React.Fragment >
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="textPrimary"
                                >
                                    {String.nlToBr(message.content)}
                                </Typography>
                                <Typography variant="caption" component="span">
                                    {message.dateString}
                                </Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
            </React.Fragment>
        );
    }

    scrollToBottom = () => {
        const container = document.getElementById('main-content');
        if (container) {
            container.style = 'scroll-behavior: smooth;';
            container.scrollTo(0, container.scrollHeight);
        }
    };

    renderList(messages) {
        if (!messages) {
            return;
        }
        if (messages.length < 1) {
            return (
                <React.Fragment key={-1}>
                    <ListItem
                        alignItems="flex-start"
                        style={{ textAlign: 'center', 'fontStyle': 'italic' }}
                        selected={false}
                    >
                        <ListItemText
                            primary={
                                <Typography
                                    component="div"
                                    variant="caption"
                                    color="textPrimary"
                                >
                                    There are no messages in this thread yet.
                                </Typography>
                            }
                        />
                    </ListItem>
                </React.Fragment>
            );
        }
        return messages.map((message, index) =>
            message.from === `/api/users/${User.getId()}`
                ? this.renderEvenItem(index)
                : this.renderOddItem(index)
        );
    }

    render() {
        const {
            messages,
            isSendingMessage,
            isLoadingMessages,
        } = this.state;
        const { title, readOnly = false } = this.props;
        return (
            <div style={styles.messagesBlock}>
                {
                    this.props.topicName ?
                        <h1 className="background-linear-gradient">{this.props.topicName}</h1>
                    : null
                }
                <div id="messages-list" style={styles.list}>
                    <div style={{ height: 10 }}>
                        {isLoadingMessages && <LinearProgress />}
                    </div>
                    {title ? (<h2>{title}</h2>) : null}
                    <List ref={this.listRef}>
                        <>
                        {this.renderList(messages)}
                        {readOnly || <Divider component="hr" />}
                        {readOnly || <ListItem>
                            <TextField
                                value={this.state.content}
                                onChange={(event) =>
                                    this.setState({
                                        content: event.target.value,
                                    })
                                }
                                style={styles.input}
                                placeholder="Type a message"
                                multiline={true}
                            />
                            <IconButton
                                type="submit"
                                style={styles.iconButton}
                                aria-label="search"
                                onClick={this.handleMessageSubmit}
                            >
                                {isSendingMessage ? (
                                    <CircularProgress size={20} />
                                ) : (
                                        <SendIcon />
                                    )}
                            </IconButton>
                        </ListItem>}
                        </>
                    </List>
                </div>
            </div>
        );
    }
}

const styles = {
    deleteButton: { color: '#fff' },
    messagesBlock: {
        display: 'inline',
    },
    list: {
        minWidth: 500,
        marginTop: 14,
        backgroundColor: '#fff',
        overflow: 'auto',
        position: 'relative',
    },
    inputSection: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 'calc(100% - 50px)',
    },
    input: {
        margin: 1,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
};

export default Thread;
