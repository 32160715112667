import React, {Component} from "react";
import AppBarStore from "../../Store/AppBarStore";
import RelationLinks from "../AdditionalButtons/RelationLinks";
import {Link} from "react-router-dom";
import {APIResource} from "../../Services/APIResource/APIResource";
import Http from "../../Services/Http";

const allowedEntities = [
    'model_uses',
    'tierings',
    'mitigation_actions',
    'implementations',
    'reviews',
    'findings',
    'notices',
    'mras',
    'notice_actions'
];

export class Breadcrumb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            links: [],
            defaultTitle: '',
        };
        this.links = [];
        this.resourceModels = new APIResource({
            id: 'models',
            instanceId: 'allModels',
            endpoints: {
                getAll: 'models/all-models',
            }
        });
    }

    componentDidMount() {
        if(this.props.resourceEditComponent && this.props.resourceEditComponent.props.inModal){
            return;
        }
        AppBarStore.appBarStoreTitleClass = 'breadcrumb'
        this.generateRelationLinks();
        this.getDefaultTitle();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.resourceEditComponent && this.props.resourceEditComponent.props.inModal){
            return;
        }
        AppBarStore.appBarStoreTitleClass = 'breadcrumb'
        if(prevProps.entity !== this.props.entity){
            this.generateRelationLinks();
        }
        if(prevProps.entity !== this.props.entity || prevProps.resource !== this.props.resource){
            this.getDefaultTitle();
        }
    }

    getDefaultTitle() {
        const title =
            this.props.entity && this.props.resource.fieldForTitle
                ? this.props.entity[this.props.resource.fieldForTitle]
                : `#${this.props.entity.id} detail`;
        this.setState({defaultTitle: `${this.props.resource.breadcrumbName}: ${title}`});
    }

    getLinkFromItem(item){
        let entityId = null;
        if(typeof item === "object"){
            entityId = item.id;
        }else{
            item = item.split('/');
            entityId = item[item.length - 1];
        }
        return Http.post('breadcrumb/' + this.props.resourcePath + '/' + entityId ).then((response) => {
            this.links.push({
                link: '/resource/' + this.props.resourcePath + '/' + entityId + '/detail',
                title: response.toString
            });
        });
    }

    generateRelationLinks() {
        let promises = [];
        this.setState({links: []})
        this.links = []
        if(
            this.props.entity[this.props.relationalProperty]
            && Array.isArray(this.props.entity[this.props.relationalProperty])
            && this.props.entity[this.props.relationalProperty].length > 0
        ){
            for(const e of this.props.entity[this.props.relationalProperty]){
                promises.push(this.getLinkFromItem(e));
            }
        }else if(
            this.props.entity[this.props.relationalProperty]
            && !Array.isArray(this.props.entity[this.props.relationalProperty])
        ){
            AppBarStore.appBarStoreTitleClass = ''
            promises.push(this.getLinkFromItem(this.props.entity[this.props.relationalProperty]));
        }
        Promise.all(promises).then(() => {
            this.setState({links: this.links})
        });
    }

    render() {
        return <React.Fragment>
            {allowedEntities.includes(this.props.resource.resourceId)
            && this.state.links ? <React.Fragment>
                {this.state.links.length > 0 ? (this.props.relationalLabel ?? "Model")+ (this.state.links.length > 1 ? "s":"") + ": " : null}
                {
                    this.state.links.length > 1 ?
                        <RelationLinks
                            withoutTooltip={true}
                            withoutIcon={true}
                            label={(AppBarStore.previousTitle ?? this.state.links[0].title) + '...'}
                            links={this.state.links}
                        />
                        : null
                }
                {
                    this.state.links.length === 1
                        ? <Link
                            to={this.state.links[0].link}
                            className="item"
                            key={'additional-action-button-0'}
                        >
                            {this.state.links[0].title}
                        </Link>
                        : null
                }
                {this.state.links.length > 0 ? <br /> : null}
                {this.state.links.length > 0 ? <i className="fa fa-angle-right"></i> : null}
            </React.Fragment> : null}
            <span>
                {this.state.defaultTitle}
            </span>
        </React.Fragment>
    };
}

export default Breadcrumb;