import React, {Component} from 'react';
import _ from 'lodash';
import Fab from '@material-ui/core/Fab/index';
import SettingsIcon from '@material-ui/icons/Settings';
import Modal from '../../../Modal';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import Grid from '@material-ui/core/Grid/index';
import AppBar from "@material-ui/core/AppBar/index";
import Toolbar from "@material-ui/core/Toolbar/index";
import Typography from "@material-ui/core/Typography/index";
import Paper from "@material-ui/core/Paper/index";
import List from '@material-ui/core/List/index';
import ListItem from '@material-ui/core/ListItem/index';
import ListItemText from '@material-ui/core/ListItemText/index';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import './fields-config.css';


export class FieldsConfig extends Component{
    openConfigModal(){
        Modal.open({
          title: "Fields display",
          onClose: this.props.onClose,
          onBackdropClick: this.props.onBackdropClick,
          content: <ConfigPanel {...this.props} />
        });
    }

    render(){
        return(
            <Fab size="small" color="secondary" onClick={this.openConfigModal.bind(this)} className="settings-icon-header">
                <SettingsIcon />
            </Fab>
        );
    }
}

class ConfigPanel extends Component {
  constructor(props) {
    super(props);
    /** @type {{resource: import('../../APIResource').APIResource}} */
    this.props;

    this.state = {
      available: [],
      selected: []
    };
  }

  componentDidMount() {
      // Block dedicated to log all the available fields (information frequently asked by the client)
      /*
      for(let i in this.props.resource.fields){
          let field = this.props.resource.fields[i];
          if(this.props.resource.FieldIsInLayout(i) && (!field.displayList || field.displayList(field, null, null, null, this.props.resource) !== null)){
              console.log(this.getFieldPanelTitle(i) + field.title);
          }
      }
      */
      this.init();
  }

  init() {
      let selected = [];
      for (let fieldIndex in this.props.resource.currentList.displayFields) {
          let fieldName = this.props.resource.currentList.displayFields[fieldIndex];
          let field = this.props.resource.fields[fieldName];
          if (field) {
              if(
                  !field.displayList
                  || (
                      field.displayList
                      && field.displayList(field, null, null, null, this.props.resource) !== null
                  )
              ){
                  selected.push(fieldName);
              }
          }
      }
    this.displayAvailableFields();
    this.setState({
        selected: selected,
        selectedDisplay: selected,
    });
  }

  canDisplayList(fieldName) {
    // Disponible ?
    if (
      this.props.resource.currentList.displayFields.indexOf(fieldName) === -1 &&
      this.props.resource.fields[fieldName].title &&
      (this.props.resource.FieldIsInLayout(fieldName) ||
        this.props.resource.fields[fieldName].displayListIfNotInLayout)
    ) {
      // Affichable ?
      if (
        !this.props.resource.fields[fieldName].displayList ||
        this.props.resource.fields[fieldName].displayList(
          this.props.resource.fields[fieldName],
          null,
          null,
          {},
          this.props.resource
        )
      ) {
        return true;
      }
    }
    return false;
  }

  displayAvailableFields(fieldNames = []) {
    if (!fieldNames.length) fieldNames = Object.keys(this.props.resource.fields);

    const available = fieldNames.filter(fieldName => this.canDisplayList(fieldName));
    this.setState({ available: this.getSortedFieldList(available) });
  }

  getFieldPanelTitle(fieldId){
      if(!this.props.resource.layout){
          return '';
      }
      let layout = this.props.resource.layout;
      for(let tab in layout.tabs){
          for(let row in layout.tabs[tab].rows){
              for(let panel in layout.tabs[tab].rows[row].panels){
                for(let i in layout.tabs[tab].rows[row].panels[panel].fields){
                    if(layout.tabs[tab].rows[row].panels[panel].fields[i] === fieldId){
                        return panel + ': ';
                    }
                }
              }
          }
      }
      return '';
  }

  getSortedFieldList(items) {
      return items.sort(
          (a, b) => {
              let titleA = this.getFieldPanelTitle(a) + this.props.resource.fields[a].title;
              let titleB = this.getFieldPanelTitle(b) + this.props.resource.fields[b].title;
              return titleA > titleB ? 1 : -1
          }
      );
  }

  handleDragEnd(result) {
    if (result.destination) {
      let newState = {
        available: [...this.state.available],
        selected: [...this.state.selected]
      };
      newState[result.source.droppableId].splice(result.source.index, 1);
      newState[result.destination.droppableId].splice(
        result.destination.index,
        0,
        this.state[result.source.droppableId][result.source.index]
      );
      this.setState({
        available: newState.available,
        selected: newState.selected
      });
      this.props.resource.currentList.displayFields = [...newState.selected];

      if (this.props.updateCallback) {
        this.props.updateCallback();
      }
    }
  }

  onResetAllClicked = () => {
    let newStateSelected = this.getSortedFieldList([...this.state.available, ...this.state.selected]);
    this.setState({
        available: _.difference(newStateSelected, this.props.resource.currentList.initialDisplayFields),
        selected: this.props.resource.currentList.initialDisplayFields,
    });
    this.props.resource.currentList.displayFields = this.props.resource.currentList.initialDisplayFields.slice();
    if (this.props.updateCallback) {
        this.props.updateCallback();
    }
  };

  onAvailableFilterChange(filterTxt) {
    if (filterTxt) {
      const fieldNames = Object.keys(this.props.resource.fields).filter((fieldName) => {
        const fullTitle =
          this.getFieldPanelTitle(fieldName) + (this.props.resource.fields[fieldName].title || '');
        return fullTitle.match(new RegExp(filterTxt, 'gi'));
      });
      this.displayAvailableFields(fieldNames);
      return;
    }
    this.displayAvailableFields();
  }

  render() {
    return (
        <DragDropContext onDragEnd={this.handleDragEnd.bind(this)}>
            <Grid container spacing={2} className="container">
                <Grid item xs={6}>
                    <Paper className="fields-config-droppable-container">
                        <AppBar position="static">
                            <Toolbar>
                                <Typography variant="h6">
                                    Available fields
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <div>
                            <TextField
                                className="filter"
                                placeholder="Search"
                                onChange={(event) => {
                                    this.onAvailableFilterChange(
                                        event.target.value
                                    );
                                }}
                            />
                        </div>
                        <Droppable droppableId="available">
                            {(provided, snapshot) => (
                                <List
                                    component="nav"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="fields-config-droppable available"
                                >
                                    {this.state.available.map(
                                        (fieldName, index) => (
                                            <Draggable
                                                key={fieldName}
                                                draggableId={fieldName}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <ListItem
                                                        button
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <ListItemText
                                                            primary={this.getFieldPanelTitle(fieldName) + this.props.resource.fields[fieldName].title}
                                                        />
                                                    </ListItem>
                                                )}
                                            </Draggable>
                                        )
                                    )}
                                </List>
                            )}
                        </Droppable>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className="fields-config-droppable-container">
                        <AppBar position="static">
                            <Toolbar>
                                <Typography variant="h6">
                                    Displayed fields
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <Box style={styles.actionHeader}>
                            <Button
                                onClick={() => this.onResetAllClicked()}
                                color="secondary"
                            >
                                Reset
                            </Button>
                        </Box>
                        <Droppable droppableId="selected">
                            {(provided, snapshot) => (
                                <List
                                    component="nav"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="fields-config-droppable selected"
                                >
                                    {this.state.selected.map(
                                        (fieldName, index) => (
                                            <Draggable
                                                key={fieldName}
                                                draggableId={fieldName}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <ListItem
                                                        button
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <ListItemText
                                                            primary={this.getFieldPanelTitle(fieldName) + this.props.resource.fields[fieldName].title}
                                                        />
                                                    </ListItem>
                                                )}
                                            </Draggable>
                                        )
                                    )}
                                </List>
                            )}
                        </Droppable>
                    </Paper>
                </Grid>
            </Grid>
        </DragDropContext>
    );
  }
}



let styles = {
  actionHeader: {
    overflow: 'hidden'
  },
  actionButton: {
    float: 'right',
    marginRight: 10
  }
};
