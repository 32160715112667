import Axios from 'axios';
import App from '../Config/App';
import Alert from './Alert';
import DateFormatter from "./DateFormatter";
import String from "./String";

class Http {
    requestConfig = {
        headers: {}
    };

    cache = {};
    promiseCaches = {};
    defaultCache = 60000; // 60 sec

    setJWT(jwt) {
        this.requestConfig.headers['Authorization'] = 'Bearer ' + jwt;
    }

    /**
     * HTTP GET method
     * @param {string} path
     * @param {Object} options
     * @param {{token: any}} [options.request]
     * @param {boolean|number|undefined} [options.cache] - Cache peut contenir :
     * - {cache: false} pour forcer l'appel à API et ne pas mettre en cache (valeur par défaut)
     * - {cache: n} où n est la valeur en s durant laquelle le résultat de l'appel sera mis en cache
     * - {cache: true} pour mettre en cache l'appel API et utiliser la valeur de mise en cache par défaut
     */
    get(path, options) {
        let self = this;
        const date = new Date();
        const cacheTimeout =
            !options ||
                options.cache === undefined
                ? self.defaultCache
                : options.cache === true
                    ? self.defaultCache
                    : 1000 * options.cache;

        // bugfix, pour beneficier du cache la valeur de cache doit explicitement etre définie undefined est une valeur qui faisait eviter le cache
        // j'ai fix ici, mais sinon il faudrait fix dans tous les appels au cache.
        if (options && (options.cache === undefined || options.cache) && self.cache[path]
            && date.getTime() < self.cache[path].expires
        ) {
            return self.cache[path].promise
        } else {
            const promise = new Promise((resolve, reject) => {
                let config = JSON.parse(JSON.stringify(this.requestConfig));
                if(options && options.request){
                    config.cancelToken = options.request.token
                }
                Axios.get(App.api + '/' + path, config)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        if(!self.handle401(error) && error.message != 'NoAlert'){
                            Alert.show({ message: self.getErrorMessage(error), type: 'danger' });
                        }
                        delete self.cache[path];
                        reject(error);
                    })
                    .finally(function () {
                        if (!cacheTimeout) {
                            delete self.cache[path];
                        }
                    });
            });
            self.cache[path] = { expires: date.getTime() + cacheTimeout, promise };
            return promise;
        }
    }

    /**
     * HTTP POST method
     * @param path
     * @param object
     */
    post(path, object) {
        let self = this;
        return new Promise((resolve, reject) => {
            object = this.removeTimeZone(object);
            Axios.post(App.api + '/' + path, object, this.requestConfig)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    self.handle401(error);
                    Alert.show({ message: self.getErrorMessage(error), type: 'danger' });
                    reject(error);
                })
        });
    }

    /**
     * HTTP POST with multipart/form-data
     * @param path
     * @param datas
     *
     * @description datas : {
     *     file : File()
     *     params1: value1,
     *     params2: value2,
     *     ...
     * }
     *
     */
    postFile(path, datas = {}) {
        let self = this;
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            for (let i in datas) {
                if (datas[i] && typeof datas[i] === 'object' && datas[i].constructor !== File) {
                    formData.append(i, JSON.stringify(datas[i]));
                } else {
                    formData.append(i, datas[i]);
                }
            }
            let requestConfig = JSON.parse(JSON.stringify(this.requestConfig));
            requestConfig.headers['Content-Type'] = 'multipart/form-data';
            Axios.post(App.api + '/' + path, formData, requestConfig)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    self.handle401(error);
                    Alert.show({ message: self.getErrorMessage(error), type: 'danger' });
                    reject(error);
                })
        });

    }

    openFile(path, fileName) {
        let self = this;
        let requestConfig = JSON.parse(JSON.stringify(this.requestConfig));
        requestConfig.responseType = 'blob';
        return Axios.get(App.api + path, requestConfig)
            .then(function (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                //document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
                self.handle401(error);
                Alert.show({ message: self.getErrorMessage(error), type: 'danger' });
            });
    }

    openEncryptedFile(path, fileName) {
        let self = this;
        if(self.decryptingSession && self.decryptingSession === path + fileName){
            return;
        }
        self.decryptingSession = path + fileName;
        let requestConfig = JSON.parse(JSON.stringify(this.requestConfig));
        requestConfig.responseType = 'blob';

        let decryptionSessionToken = String.random(20);

        Axios.get(App.api + path + '/' + decryptionSessionToken, requestConfig)
            .then(function (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName.substring(14));
                //document.body.appendChild(link);
                link.click();
                delete self.decryptingSession;
            })
            .catch(function (error) {
                self.handle401(error);
                Alert.show({ message: self.getErrorMessage(error), type: 'danger' });
                delete self.decryptingSession;
            });

        setTimeout(() => {
            let formData = new FormData();
            formData.append('env', App.env);
            formData.append('sessionToken', decryptionSessionToken);
            Axios.post(App.kms, formData, requestConfig)
                .then(function (response) { })
                .catch(function (error) {
                    self.handle401(error);
                    Alert.show({ message: self.getErrorMessage(error), type: 'danger' });
                })
        }, 4000);
    }

    /**
     * HTTP PUT method
     * @param path
     * @param object
     */
    put(path, object) {
        let self = this;
        return new Promise((resolve, reject) => {
            object = this.removeTimeZone(object);
            Axios.put(App.api + '/' + path, object, this.requestConfig)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    self.handle401(error);
                    Alert.show({ message: self.getErrorMessage(error), type: 'danger' });
                    reject(error);
                })
        });
    }

    delete(path) {
        let self = this;
        return new Promise((resolve, reject) => {
            Axios.delete(App.api + '/' + path, this.requestConfig)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    self.handle401(error);
                    Alert.show({ message: self.getErrorMessage(error), type: 'danger' });
                    reject(error);
                })
        });

    }

    handle401(error) {
        if (error.response && error.response.data && error.response.data.code && (error.response.data.code === 401 || error.response.data.code === 403)) {
            window.location = App.backend + '/auth/login';
            return true;
        }
        return false;
    }

    getErrorMessage(error) {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
            return (error.response.data['hydra:title'] || 'Error') + ': ' + error.response.data['hydra:description'];
        }
        if (error.response && error.response.data && error.response.data['detail']) {
            return 'Error: ' + error.response.data['detail'];
        }
        return error.message || 'Woops! Something went awry…'
    }

    //Axios convert date to local, passing date in string fix this behavior
    removeTimeZone(object) {
        for (let key in object) {
            if (object.hasOwnProperty(key) && object[key] instanceof Date) {
                object[key] = DateFormatter.dateToIso8601Full(object[key]);
            }
        }
        return object;
    }

    getRequest(){
        return Axios.CancelToken.source();
    }
}

export default new Http();
