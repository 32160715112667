import React, {Component} from "react";
import Modal from '../../Modal';
import Button from '@material-ui/core/Button';
import '../BulkEdit/bulk-edit.css';
import Http from '../../Http';
import Alert from '../../Alert';
import {openLoadingModal, validateIds} from "../bulkUtils";
import CloseFindings from "../../../Components/Review/CloseFindings";
import {NoticeActionForm} from "../../../Components/Forms/CloseModal/NoticeCloseModal";

export class CloseNotice {
    getId(){
        return 'bulkCloseNotice'
    }

    getLabel(){
        return 'Close'
    }

    /**
     *
     * @param {import('../../APIResource/APIResource').APIResourceBulkParams} params
     */
    setParameters(params){
        this.parameters = params;
    }

    async run(ids, parent = null){
        this.ids = ids;
        let self = this;

        let openModal = () => {
            Modal.open({
                title: this.getLabel(),
                content: <NoticeActionForm
                    entityType='notice'
                    ids={self.ids}
                    resource={self.parameters.resource}
                    action={'close'}
                    actionTitle={'Close'}
                    successMessage={'The notice has been closed.'}
                    requiredComment={true}
                    callback={(response) => {
                        Modal.open({
                            title: 'Close findings',
                            content: <CloseFindings
                                findings={response.findings}
                            />
                        });
                    }}
                />,
            })
        }

        openLoadingModal();

        if (await validateIds(ids, this.parameters.itemAccessCondition, this.parameters.resource, this.parameters.itemAccessConditionEntityFields)) {
            openModal();
        } else {
            Modal.close();
            Alert.show({ message: this.parameters.forbiddenAccessMessage });
        }
    }
}