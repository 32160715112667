import React, {Component, useState} from 'react';
import PropTypes from 'prop-types';
import {TableDisplay} from "../TableDisplay/TableDisplay";
import {APIResource} from "../../../Services/APIResource/APIResource";
import String from "../../../Services/String";
import Button from "@material-ui/core/Button";
import Alert from "../../../Services/Alert";
import FormControl from "@material-ui/core/FormControl";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Modal from "../../../Services/Modal";
import { CircularProgress, FormLabel, IconButton, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Http from '../../../Services/Http';
import {userHasRole, userHasSpecificRole} from '../../../Store/ParameterStore';
import User, {ROLE as Role} from '../../../Services/User/User';
import { ActionButton } from '../../Modal/ActionButton';
import { ButtonBar } from '../../Modal/ButtonBar';

export class ChangeLog extends Component{

    constructor(props) {
        super(props);
        this.resource = new APIResource({id: 'change_logs'});
        if(this.props.entityResource) {
            this.entityResource = new APIResource({id: this.props.entityResource});
        }
        this.state = {
            tableValues: null
        }
    }

    componentDidMount(){
        this.valueInit();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if((this.props.values !== prevProps.values)){
            this.valueInit();
        }
    }

    valueInit() {
        if(Array.isArray(this.props.values)) {
            this.setState({tableValues: this.props.values});
        }else{
            this.setState({tableValues: []});
        }
    }

    editComment(changeLog){
        Modal.open({
            title: 'Edit comment',
            content: <ChangeLogForm changeLog={changeLog} onSave={(changeLog) => this.onSave(changeLog)} />
        });
    }

    updateEntity(){
        if(this.entityResource){
            //Force updating parent from API when changing Documents collection are affecting parent's properties
            this.entityResource.getItemFromResourcePath(this.props.entity['@id'], true).then((entity) => {
                this.setState({tableValues: entity.changeLogsEntities});
            });
        }
    }

    onSave(changeLog){
        this.resource.apiPut({
            id: changeLog.id,
            comment: changeLog.comment
        }).then((entity) => {
            Alert.show({ message : "Change log has been updated"});
            this.updateEntity();
            Modal.close();
        });
    }

    render() {
        return (
            <TableDisplay
                actionsColIndex={5}
                rows={this.state.tableValues}
                cols={[
                    { label: 'Date', field: 'dateString' },
                    { label: 'User', field: 'author' },
                    {
                        label: 'Changelogs',
                        field: 'description',
                        display: (field, value, entity, props) => (
                            <span>{String.nlToBr(value)}</span>
                        ),
                    },
                    {
                        label: 'Comment',
                        field: 'comment',
                        display: (field, value, entity, props) => (
                            <span>{String.nlToBr(value)}</span>
                        ),
                    },
                    {
                        label: 'Verified',
                        field: 'verified',
                        display: (field, value, entity, props) => (
                            <VerifiedMenu
                                value={value}
                                changelogId={entity.id}
                            />
                        ),
                        noTooltip: true,
                    },
                ]}
                actions={(value) => {
                    if(userHasSpecificRole(Role.IG)){
                        return;
                    }
                    return (
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                className="button-show-version"
                                onClick={() => this.editComment(value)}
                            >
                                Edit comment
                            </Button>
                        </div>
                    );
                }}
            />
        );
    }
};

export const ChangeLogForm = (props) => {
    const { changeLog, onSave } = props;

    const [comment, setComment] = useState(null);
    const [progress, setProgress] = useState(false);

    const save = () => {
        if (comment === null) {
            Alert.show({ message: 'Please fill the comment', type: 'danger' });
            return;
        }
        setProgress(true);
        let newComment = User.profile && User.profile.fullName ? `${User.profile.fullName}: ${comment}` : comment;
        newComment = changeLog.comment ? `${changeLog.comment}\n${newComment}` : newComment;
        onSave({ ...changeLog, comment: newComment }); // onSave closes the Modal
    };

    return (
        <div className="change-log-modal-edit">
            <div className="fields">
                <FormControl className="container_textarea">
                    <FormLabel>Comment</FormLabel>
                    <Typography style={{whiteSpace: "pre-line"}} variant="body2">{changeLog.comment}</Typography>
                    <TextareaAutosize
                        value={comment || ''}
                        onChange={(event) => setComment(event.target.value)}
                        rows={5}
                        rowsMax={10}
                    />
                </FormControl>
            </div>
            <ButtonBar>
                <ActionButton onClick={save} disabled={progress}>Save {progress && <CircularProgress size={20} />}</ActionButton>
            </ButtonBar>
        </div>
    );
};
ChangeLogForm.propTypes = {
    changeLog: PropTypes.object,
    onSave: PropTypes.func,
};

export const VerifiedMenu = (props) => {
    const { value, changelogId } = props;

    let userHasRoleMRM = userHasRole('ROLE_MRM');

    const [anchorEl, setAnchorEl] = useState(null);
    let [verified, setVerified] = useState(value);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChange = (value) => {
        setAnchorEl(null);
        setVerified(value);

        Http.post('changelogs/' + changelogId + '/verify', {
            status: value,
        });
    };

    const renderIcon = () => {
        switch (verified) {
            case true:
            case 1:
                return (
                    <Tooltip title="Verified" arrow placement="right">
                        <IconButton onClick={handleClick}>
                            <CheckIcon className="text-success" />
                        </IconButton>
                    </Tooltip>
                );
            default:
                return (
                    <Tooltip title="Pending" arrow placement="right">
                        <IconButton onClick={handleClick}>
                            <HourglassEmptyIcon />
                        </IconButton>
                    </Tooltip>
                );
        }
    };

    if (!changelogId) {
        return null;
    }
    return (
        <div>
            {renderIcon()}
            {userHasRoleMRM ? (
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                    <MenuItem
                        onClick={() => {
                            handleChange(true);
                        }}
                    >
                        <CheckIcon className="text-success" />
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleChange(false);
                        }}
                    >
                        <HourglassEmptyIcon />
                    </MenuItem>
                </Menu>
            ) : null}
        </div>
    );
};
VerifiedMenu.propTypes = {
    value: PropTypes.any,
    changelogId: PropTypes.any,
}