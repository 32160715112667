import React, {Component} from 'react';
//import './edit.css';
import {Model} from '../../Store/ModelStore';
import {observer} from "mobx-react";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import DateFormatter from "../../Services/DateFormatter";
import AppBarStore from '../../Store/AppBarStore';
import {Link} from "react-router-dom";
import ModelTester from "./Add/ModelTester";
import {Header} from "../Header/Header";
import {userHasRoleMRM} from "../../Store/ParameterStore";
import {ModelStep} from "../ModelStep/ModelStep";

class Add extends Component{

    constructor(props){
        super(props);
        this.state = {
            currentTab: 0,
            model: new Model(),
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        AppBarStore.title = 'New model';
    }

    handleChange(field, value){
        let model = {...this.state.model};

        // traitement spécifique pour les dates (date avec les heures min sec à 0)
        if(typeof value === 'object' && (field.indexOf('Date') > -1)){
            let dateValue = DateFormatter.onlyDate(value);
            model[field] = dateValue;
        }else{
            model[field] = value;
        }
        this.setState({model: model});
    }

    navigationButtons(){
        return(
            <div style={{textAlign: 'right'}} style={navStyles.nextButton}>
                <Link key={'listModels'} to={'/resource/models/list'}>
                    <Button variant="contained" color="primary" style={navStyles.listButton}>
                        Back to Inventory
                    </Button>
                </Link>
            </div>
        )
    }

    render() {
        let self = this;

        return (
            <div>
                {
                    !userHasRoleMRM() ?
                        <ModelStep
                            key={'model_step'}
                            forceStep={0}
                        />
                        : null
                }
                {
                    !userHasRoleMRM() ?
                        <Header
                            text={'As a first step, please fill the required information (all the fields are mandatory) and answer the identification tree questions. If your candidate is identified as a model, please proceed with the next step. If your candidate is identified as a tool, please submit to MRM for confirmation.'}
                        />
                        : null
                }
                <AppBar position="static" color="default">
                    <Tabs
                        value={this.state.currentTab}
                        onChange={(event, val) => {self.setState({currentTab: val})}}
                        className="tabs-menu"
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Information" />
                    </Tabs>
                </AppBar>
                {this.state.currentTab === 0 ? <ModelTester model={this.state.model} onChange={this.handleChange.bind(this)}/> : null }
                { this.navigationButtons() }
            </div>

        )
    }
}


export default observer(Add);

const navStyles = {
    actionLink: {
        marginLeft: 5
    },
    nextButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(255,255,255,0.75)',
        padding:3,
        borderTopLeftRadius: 5,
    },
    listButton:{
        fontSize: 10,
        backgroundColor: '#59237f',
        margin:3,
    },
    detailButton:{
        fontSize: 10,
        backgroundColor: '#faa61a',
        margin:3,
    },
    saveButton:{
        fontSize: 10,
        backgroundColor: '#0dbbb7',
        margin:3,
    }
};
