import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../Services/Modal';
import { CircularProgress } from '@material-ui/core';
import { ModalContent } from '../../Modal/ModalContent';
import { ButtonBar } from '../../Modal/ButtonBar';
import { ActionButton } from '../../Modal/ActionButton';
import APIResourceStore from '../../../Store/APIResourceStore';
import { EntityForm } from '../EntityForm/EntityForm';

const EntityQuickAddForm = (props) => {
    const { onSubmit, fields, prePostHook = (v) => v, resourceId } = props;

    const [value, setValue] = useState({});
    const [progress, setProgress] = useState(false);

    const apiAddEntity = async () => {
        if (!value) return;
        setProgress(true);

        // Pré traitement si nécessaire
        const entity = prePostHook(value);

        /** @type {import("../../Services/APIResource/APIResource").APIResource} */
        const resource = APIResourceStore.resources[resourceId];
        const newEntity = await resource.apiPost(entity);
        /** @todo si possible on pourrait remplir la resource avec cette nouvelle entité */
        // On renvoie l'iri de la nouvelle entité au parent.
        onSubmit(newEntity['@id']);

        setProgress(false);
        Modal.close();
    };

    return (
        <ModalContent>
            <EntityForm entity={value} fields={fields} onUpdate={setValue} />
            <ButtonBar>
                <ActionButton disabled={progress} onClick={apiAddEntity}>
                    Add {progress && <CircularProgress size={20} />}
                </ActionButton>
            </ButtonBar>
        </ModalContent>
    );
};
EntityQuickAddForm.propTypes = {
    /** Action déclenchée après le POST vers l'api. */
    onSubmit: PropTypes.func.isRequired,
    /** Tableau des champs, comme pour APIResource, cf EntityForm pour voir les champs supportés */
    fields: PropTypes.object.isRequired, 
    /** Fonction appelée avant le POST pour les données en cours, default (value) => value */
    prePostHook: PropTypes.func, 
    resourceId: PropTypes.string.isRequired,
};

export default EntityQuickAddForm;