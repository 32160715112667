import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Modal from '../../../Services/Modal';
import Alert from '../../../Services/Alert';
import Navigation from '../../../Services/Navigation';
import Http from '../../../Services/Http';
import { ButtonBar } from '../../Modal/ButtonBar';
import { ActionButton } from '../../Modal/ActionButton';
import { EntityForm } from '../../Forms/EntityForm/EntityForm';
import { ModalContent } from '../../Modal/ModalContent';

/** @type {Object.<string, import('../../../Services/APIResource/APIResource').APIResourceField} */
const modelDeleteFields = {
    documentsEntities: {
        title: 'Please attach documents to justify the deletion if necessary.',
        type: 'documents',
        params: {
            entityResource: 'models',
            propertyName: 'documentsEntities',
            fieldName: 'Document',
            allowedCategory: false,
            links: false,
            saveButtonLabel: 'Attach',
            allowedAction: (entity, document, action) => ['show', 'delete'].includes(action),
        },
    },
    deletionComment: {
        title: 'Deletion justification.',
        type: 'textarea',
    },
};

/**
 * Remplace le LogicalDeleteForm pour les Model, pour ajouter des champs supplémentaires (eg: documents...)
 */
export const ModelLogicalDeleteForm = (props) => {
    const { entity, entityType, hideReplaceButton } = props;
    /** @type {import('../../../Services/APIResource/APIResource').APIResource} */
    const resource = props.resource;

    const [progress, setProgress] = useState(false);
    const [andReplace, setAndReplace] = useState(false);
    const [model, setModel] = useState(entity);

    const deleteModel = async (andReplace = false) => {
        if (!model.deletionComment) {
            Alert.show({
                message: 'The comment is required before deletion.',
                type: 'danger',
            });
            return;
        }

        setProgress(true);
        setAndReplace(andReplace);

        if ((entityType === 'model' || entityType === 'models') && andReplace === true) {
            Navigation.router.history.push(`/resource/models/add/${model.id}`);
        } else {
            Navigation.router.history.push(`/resource/${resource.instanceId}/list`);
        }
        try {
            let comment = model.deletionComment;
            delete model.deletionComment; // Pour ne pas envoyer de deletionComment avant de réellement supprimer
            await resource.apiPut(model); // Pour mettre à jour les documents
            await Http.post('models/delete', {
                ids: [model.id],
                entity: entityType,
                deletionComment: comment,
            });
            Alert.show({ message: 'Item successfully deleted.', type: 'success' });
            await resource.apiGetCollection({
                forceReload: true,
            });
            setProgress(false);
            Modal.close();
        } catch (err) {
            console.error(err);
            setProgress(false);
            Modal.close();
        }
    };

    return (
        <ModalContent className={'logical-delete-form'}>
            <Grid container justify="center" spacing={2} className="container resource-edit">
                <Grid item xs={12}>
                    <EntityForm entity={model} onUpdate={setModel} fields={modelDeleteFields} />

                    <h2>Are you sure you want to delete the model {model.functionalID} ?</h2>

                    <ButtonBar>
                        <ActionButton onClick={Modal.close} disabled={progress}>
                            Cancel
                        </ActionButton>
                        <ActionButton onClick={() => deleteModel()} disabled={progress} loading={progress && !andReplace}>
                            Delete
                        </ActionButton>
                        {!hideReplaceButton && (
                            <ActionButton
                                onClick={() => deleteModel(true)}
                                disabled={progress}
                                loading={progress && andReplace}
                            >
                                Delete and replace
                            </ActionButton>
                        )}
                    </ButtonBar>
                </Grid>
            </Grid>
        </ModalContent>
    );
};
ModelLogicalDeleteForm.propTypes = {
    entity: PropTypes.object.isRequired,
    /** Instance d'APIResource */
    resource: PropTypes.any.isRequired,
    entityType: PropTypes.string,
    hideReplaceButton: PropTypes.bool,
};
