import React, {Component} from "react";
import Modal from '../../Modal';
import TextField from '@material-ui/core/TextField/index';
import Button from '@material-ui/core/Button';
import '../BulkEdit/bulk-edit.css';
import Http from '../../Http';
import Alert from '../../Alert';
import FieldProviderStore from "../../APIResource/FieldProviders/__FieldProviderStore";
import FormControl from "@material-ui/core/FormControl";

export class ValidationFamilyEdit{
    getId(){
        return 'bulkValidationFamilyEdit'
    }

    getLabel(){
        return 'Associate to a models family'
    }

    setParameters(params){
        this.parameters = params;
    }

    run(ids, parent = null){
        this.ids = ids;

        Modal.open({
            title: this.getLabel(),
            content: <ValidationFamilyForm
                ids={this.ids}
                parent={parent}
                resource={this.parameters.resource}
            />,
            modalStyle: { width: 520 },
        })
    }
}

export class ValidationFamilyForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            validationFamily: null,
            name: null,
            newValidationFamily: false
        }
    }

    handleChange(fieldId, value){
        if(fieldId == 'changeLogComment') {
            this.setState({changeLogComment: value});
        } else {
            let fields = this.state.fields;
            fields[fieldId] = value;
            this.setState({fields: fields});
        }
    }

    save(){

        if(this.state.newValidationFamily === false && this.state.validationFamily === null){
            Alert.show({message: 'Please select a models family.', type: 'danger'});
            return;
        }else if(this.state.newValidationFamily === true && this.state.name === null){
            Alert.show({message: 'Please fill the models family name.', type: 'danger'});
            return;
        }

        Http.post(this.props.resource.resourceId+'/bulk/validation-family', {
            ids: this.props.ids,
            validationFamily: this.state.newValidationFamily === false ? this.state.validationFamily : null,
            name: this.state.newValidationFamily === true ? this.state.name : null
        }).then((response) => {
            this.props.resource.apiGetCollection({forceReload: true}).then(() => this.props.parent.setState({selection: []}));
            Alert.show({message: response.message || 'Bulk Edit successfully processed.'});
            Modal.close();
        });
    }

    genForm(){
        let fields = [];

        let validationFamily = {
                title: 'Choose a models family',
                type: 'entity',
                params: {
                    multi: false,
                    resource: 'validation_families',
                    displayField: 'name',
                },
                issueButton: false,
                required: false,
                disabled: true
            };
        if(this.state.newValidationFamily === false) {
            fields.push(<div className="field" key={'field-validationFamily'}>{
                FieldProviderStore[validationFamily.type].getEdit(
                    validationFamily,
                    this.state.validationFamily,
                    (value) => {
                        this.setState({validationFamily: value})
                    },
                    this.state.fields,
                    {}
                )
            }</div>);
        }

        fields.push(
            <div className="field" key={'name'}>
                { this.state.newValidationFamily ?
                    <div key={'name'}>
                        <FormControl>
                            <TextField
                                id={'field-name'}
                                label={'Models family name'}
                                className={'field-name'}
                                onChange={(event) => {this.setState({name: event.target.value})}}
                                value={this.state.name}
                            />
                        </FormControl>
                    </div>
                : null }
            </div>
        );

        return(
            <div className="bulk-associate-validation-family">
                <div className={"alert alert-info"} role="alert">
                    You are about to associate a models family to all the selected models. This will replace the previously defined models family for these models.
                </div>
                <div className="fields main-fields">
                    {fields}
                    { this.state.newValidationFamily === false ?
                        <Button variant="contained" color="primary" className="choose" onClick={() => this.setState({newValidationFamily: true})}>
                            Create a new models family instead
                        </Button>
                        :
                        <Button variant="contained" color="primary" className="choose" onClick={() => this.setState({newValidationFamily: false})}>
                            Choose an existing models family
                        </Button>
                    }
                </div>
                <div className="fields">
                    <Button variant="contained" color="secondary" className="save" onClick={this.save.bind(this)}>
                        Associate all the selected models
                    </Button>
                </div>

            </div>
        );
    }

    render(){
        return(this.genForm());
    }
}
