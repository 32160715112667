import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import PersonIcon from '@material-ui/icons/Person';
import ExploreIcon from '@material-ui/icons/Explore';
import RateReviewIcon from '@material-ui/icons/RateReview';
import Divider from '@material-ui/core/Divider';
import Float from '../../../Services/Float';
import DisplayScoreSlider from './DisplayScoreSlider/DisplayScoreSlider';
import { WarningRounded } from '@material-ui/icons';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import Button from '@material-ui/core/Button';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import CheckIcon from '@material-ui/icons/Check';

import MRA, {
    isNTXRiskAssessmentType,
    isGROUPRiskAssessmentType,
    isBPCERiskAssessmentType,
    MRA_PROCESS,
    MRA_STATUS,
} from '../../../Services/MRA';
import { ParameterSelect } from '../../Forms/ParameterSelect/ParameterSelect';
import { PARAMETER_TYPE_MODEL_RISK_ASSESSMENT_RESULT, PARAMETER_TYPE_MRA_STATUS } from '../../../Admin/ParameterAdmin';
import User from '../../../Services/User/User';
import { getParamByIri, userIsLod2, userIsLod1, userHasRoleMRM } from '../../../Store/ParameterStore';
import { useTranslation } from 'react-i18next';
import { Radar } from 'react-chartjs-2';

export const OverviewPanel = (props) => {
    const radarRef = useRef();
    const {
        mra,
        mraStatus,
        mraModel,
        mraProcess,
        syncMras,
        onDetach,
        detachLoading,
        mraGroupLoading,
        dimensions,
        subdimensions,
        mraScores,
        onStatusChange,
    } = props;
    const {
        lod1KeyDimensionsMaxScore,
        lod1AverageScore,
        lod2KeyDimensionsMaxScore,
        lod2AverageScore,
        committeeKeyDimensionsMaxScore,
        committeeAverageScore,
        score,
        maxScore,
        versionAuthor,
        criticalDimensions,
        lod1Submit,
        lod2Submit,
    } = mra || {
        score: '',
        maxScore: '',
        versionAuthor: '',
        criticalDimensions: [],
    };
    let riskResult = getParamByIri(mra.modelRiskAssessmentResult);
    const author = versionAuthor ? versionAuthor.fullName : 'Generic user';
    const userRole = userIsLod2(User, mraModel) ? 'LoD2' : userIsLod1(User, mraModel) ? 'LoD1' : '';
    const mras = (syncMras.length > 0 && syncMras) || [
        { ...mra, model: mraModel, functionalID: mraModel.functionalID },
    ];

    const showCriticalDimensions = mraProcess.systemId !== MRA_PROCESS.LOD1_LOD2 || userRole !== 'LoD1' || lod1Submit;
    const parentDimension = getParamByIri(mra.dimension);
    const isNTXDimension = parentDimension.systemId && parentDimension.systemId === 'MRA_DIMENSION_SOURCE_NTX';
    const isGroupDimension = parentDimension.systemId && parentDimension.systemId === 'MRA_DIMENSION_SOURCE_GROUP';
    const isLoD1SelfAssessment = mraProcess.systemId === MRA_PROCESS.LOD1;
    const isLoD1LoD2Process = mraProcess.systemId === MRA_PROCESS.LOD1_LOD2;
    const hideLoD1Scores =
        isLoD1LoD2Process &&
        !mra.lod1Submit &&
        [MRA_STATUS.VALIDATED, MRA_STATUS.FINALIZED].includes(mraStatus.systemId);
    const hideLoD2Scores =
        isLoD1LoD2Process && userRole === 'LoD1' && [MRA_STATUS.ON_GOING].includes(mraStatus.systemId);
    const isClosedOrInCommittee = [MRA_STATUS.VALIDATED, MRA_STATUS.FINALIZED].includes(mraStatus.systemId);
    const canDetach = !isClosedOrInCommittee && mras.length > 1;
    const radarMaxScale = isNTXDimension || isGroupDimension ? 5 : 4;

    const { t } = useTranslation();

    const exportRadarChart = () => {
        const base64image = radarRef.current.chartInstance.toBase64Image();
        const link = document.createElement('a');
        link.download = `mra-radar-chart-id-${mra.id}.png`;
        link.href = base64image;
        link.click();
    };

    const borderColors = ['black', 'purple', 'rgb(228, 8, 120)'];
    let datasetCount = 0;
    let sortedDimensions = [];
    const dimensionsOrder = {
        'Model Input(s)': 0,
        'Methodology and design': 1,
        Implementation: 2,
        'On-going monitoring': 3,
        'Model performance': 4,
        Documentation: 5,
        Governance: 6,
    };
    dimensions.forEach((d) => {
        sortedDimensions[dimensionsOrder[d.title]] = d;
    });
    const applicableDimensions = {};
    let datasets = sortedDimensions.reduce((datasets, d) => {
        const overviewScores = MRA.getMraScoresByDimensionGroupedByType(d, subdimensions, mraScores);

        Object.values(overviewScores).forEach((s) => {
            let label = 'missing label';
            if (!s || !s.humanType || !s.humanType.label) {
                console.error('Missing humanType for score', s);
            } else {
                label = s.humanType.label.replace('residual', '');
            }

            if (hideLoD1Scores && label.indexOf('LoD1') >= 0) {
                // show lod1 scores only if submitted by LOD1 first
                return datasets;
            }

            if (hideLoD2Scores && label.indexOf('LoD2') >= 0) {
                return datasets;
            }

            if (mraStatus.systemId !== MRA_STATUS.VALIDATED && label.indexOf('Committee') >= 0) {
                // show committee scores only on status closed (= VALIDATED)
                return datasets;
            }
            const scoreToDisplay = s.score;
            applicableDimensions[d.title] = applicableDimensions[d.title] ? applicableDimensions[d.title] : s.score;

            if (datasets[s.type]) {
                datasets[s.type] = {
                    ...datasets[s.type],
                    data: [...datasets[s.type].data, scoreToDisplay],
                };
            } else {
                datasets[s.type] = {
                    label,
                    data: [scoreToDisplay],
                    backgroundColor: 'rgba(0,0,0,0)',
                    borderColor: borderColors[datasetCount],
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    borderWidth: 3,
                    order: -1 * datasetCount + 3, // smaller = priority
                };
                datasetCount++;
            }
        });

        return datasets;
    }, {});

    datasets = Object.values(datasets);
    const data = {
        labels: sortedDimensions.map((d) => {
            let label = d.title;
            if (d.isKeyDimension) {
                label = `${label}*`;
            }
            const hasScores = applicableDimensions[d.title] > 0;
            if (!hasScores) {
                label = `N/A (${label})`;
            }
            return label;
        }),
        datasets: [
            {
                label: 'gray',
                data: [0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95],
                backgroundColor: '#9FBB78',
                borderColor: '#D9D9D9',
                borderWidth: 0,
                pointRadius: 0,
                pointHoverRadius: 0,
                order: 100,
            },
            {
                label: 'softgreen',
                data: [2, 2, 2, 2, 2, 2, 2],
                backgroundColor: '#9EBA77',
                borderColor: '#9EBA77',
                borderWidth: 0,
                pointRadius: 0,
                pointHoverRadius: 0,
                order: 101,
            },
            {
                label: 'green',
                data: [3, 3, 3, 3, 3, 3, 3],
                backgroundColor: '#ABB96D',
                borderColor: '#ABB96D',
                borderWidth: 0,
                pointRadius: 0,
                pointHoverRadius: 0,
                order: 102,
            },
            {
                label: 'orange',
                data: [4, 4, 4, 4, 4, 4, 4],
                backgroundColor: '#D2B454',
                borderColor: '#D2B454',
                borderWidth: 0,
                pointRadius: 0,
                pointHoverRadius: 0,
                order: 103,
            },
            {
                label: 'red',
                data: [4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5],
                backgroundColor: '#CA4640',
                borderColor: '#CA4640',
                borderWidth: 0,
                pointRadius: 0,
                pointHoverRadius: 0,
                order: 104,
            },
            {
                label: 'black',
                data: [5, 5, 5, 5, 5, 5, 5],
                backgroundColor: '#784D4C',
                borderColor: '#784D4C',
                borderWidth: 0,
                pointRadius: 0,
                pointHoverRadius: 0,
                order: 105,
            },
            ...datasets,
        ],
    };

    const options = {
        tooltips: {
            enabled: false,
        },
        scale: {
            ticks: {
                beginAtZero: true,
                z: 100,
                // suggestedMax: radarMaxScale,
                max: radarMaxScale,
                stepSize: 1,
                showLabelBackdrop: true,
                display: false,
                fontColor: 'black',
                fontSize: 9,
                // padding: 1,
            },
            gridLines: {
                z: 90,
            },
        },
        legend: {
            labels: {
                filter: (legend) =>
                    !['green', 'softgreen', 'yellow', 'orange', 'red', 'gray', 'black'].includes(legend.text),
                boxWidth: 20,
                padding: 6,
            },
            position: 'top',
        },
    };

    const plugins = [
        {
            beforeInit: function (chart, options) {
                chart.legend.afterFit = function () {
                    this.height = this.height + 10;
                };
            },
        },
    ];

    return (
        <div className="container" style={styles.container}>
            <Paper style={styles.columnPanel}>
                <AppBar position="static" className="background-lowlight background-linear-gradient ">
                    <Toolbar>
                        <Typography variant="h5">{t('MRA Information')}</Typography>
                    </Toolbar>
                </AppBar>
                <List>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className="background-highlight">
                                <AssignmentIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText style={{ flexDirection: 'column' }} primary={t('Process')} />
                    </ListItem>
                    <div style={{ ...styles.versionAuthor, fontWeight: 'bold' }}>{mraProcess.label}</div>
                    <Divider variant="middle" component="li" />
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className="background-highlight">
                                <ExploreIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText style={{ flexDirection: 'column' }} primary={t('Status')} />
                    </ListItem>
                    <div style={{ ...styles.versionAuthor, fontWeight: 'bold' }}>
                        <ParameterSelect
                            label=""
                            field={{
                                params: {
                                    type: PARAMETER_TYPE_MRA_STATUS,
                                    filters: [
                                        (element) => {
                                            const allowed = [
                                                MRA_STATUS.ON_GOING,
                                                MRA_STATUS.VALIDATED,
                                                MRA_STATUS.FINALIZED,
                                            ].includes(element.systemId);
                                            return allowed;
                                        },
                                    ],
                                },
                            }}
                            value={mraStatus ? mraStatus['@id'] : undefined}
                            disabled={!userHasRoleMRM()}
                            onChange={function (status) {
                                onStatusChange(status);
                            }}
                            optionsSort={(a, b) => {
                                if (a.id > b.id) return 1;
                                return -1;
                            }}
                            required={false}
                            clearable={false}
                        />
                    </div>
                    {isLoD1LoD2Process && (
                        <div>
                            <div style={{ ...styles.versionAuthor, margin: 0, paddingBottom: 5 }}>
                                <span>LOD1 scores:</span>
                                <span className="tooltip tooltip-top">
                                    {lod1Submit && (
                                        <span style={{ ...styles.checkIcon, ...styles.iconContainer }}>
                                            <CheckIcon />
                                        </span>
                                    )}
                                    {!lod1Submit && (
                                        <span style={{ ...styles.hourglassIcon, ...styles.iconContainer }}>
                                            <HourglassFullIcon />
                                        </span>
                                    )}
                                    <span className="tooltiptext" style={{ fontSize: '14px' }}>
                                        {lod1Submit ? 'Scores submitted' : 'Waiting for scores completeness'}
                                    </span>
                                </span>
                            </div>
                            <div style={{ ...styles.versionAuthor, margin: 0, paddingBottom: 20 }}>
                                <span>LOD2 scores:</span>
                                <span className="tooltip tooltip-top">
                                    {lod2Submit && (
                                        <span style={{ ...styles.checkIcon, ...styles.iconContainer }}>
                                            <CheckIcon />
                                        </span>
                                    )}
                                    {!lod2Submit && (
                                        <span style={{ ...styles.hourglassIcon, ...styles.iconContainer }}>
                                            <HourglassFullIcon />
                                        </span>
                                    )}
                                    <span className="tooltiptext" style={{ fontSize: '14px' }}>
                                        {lod2Submit ? 'Scores submitted' : 'Waiting for scores completeness'}
                                    </span>
                                </span>
                            </div>
                        </div>
                    )}
                    <Divider variant="middle" component="li" />
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className="background-highlight">
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText style={{ flexDirection: 'column' }} primary={t('Author')} />
                    </ListItem>
                    <div style={{ ...styles.versionAuthor, fontWeight: 'bold' }}>{author}</div>
                    {mraGroupLoading && (
                        <div style={{ textAlign: 'center' }}>
                            <LoadingIndicator />
                        </div>
                    )}
                    {mras.length > 0 && (
                        <>
                            <Divider variant="middle" component="li" />
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className="background-highlight">
                                        <SyncAltIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText style={{ flexDirection: 'column' }} primary={t('MRA models')} />
                            </ListItem>
                            <div style={{ ...styles.versionAuthor, marginBottom: '15px' }}>
                                {mras.map((m) => (
                                    <div key={`overview_detach_${m.id}`} style={styles.mragroup}>
                                        <a
                                            href={`/resource/models/${m.model.id}/detail`}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <div
                                                style={{ ...styles.functionalid, ...(!canDetach && { width: '100%' }) }}
                                            >
                                                {m.functionalID}
                                            </div>
                                        </a>
                                        {!isClosedOrInCommittee && mras.length > 1 && (
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                size="small"
                                                disabled={detachLoading}
                                                onClick={() => onDetach(m)}
                                            >
                                                {detachLoading ? <LoadingIndicator /> : t('detach')}
                                            </Button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </List>
            </Paper>
            <Paper style={styles.columnPanel}>
                <AppBar position="static" className="background-lowlight background-linear-gradient ">
                    <Toolbar>
                        <Typography variant="h5">{t('MRA Scores')}</Typography>
                    </Toolbar>
                </AppBar>
                <List>
                    {!isNTXDimension && (
                        <React.Fragment>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className="background-highlight">
                                        <NetworkCheckIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t('Lod1 Score')} />
                            </ListItem>
                            <div style={styles.scoreMark}>
                                {hideLoD1Scores && <DisplayScoreSlider label="Average" highlight={false} score="N/A" />}
                                {!hideLoD1Scores && (
                                    <DisplayScoreSlider
                                        label="Average"
                                        highlight={lod1AverageScore > lod1KeyDimensionsMaxScore}
                                        score={
                                            lod1AverageScore && !isNaN(lod1AverageScore) && lod1AverageScore >= 0
                                                ? Float.format(lod1AverageScore, 2)
                                                : 'N/A'
                                        }
                                    />
                                )}
                            </div>
                            <div style={styles.scoreMark}>
                                {hideLoD1Scores && (
                                    <DisplayScoreSlider label="Key Dimensions Max" highlight={false} score="N/A" />
                                )}
                                {!hideLoD1Scores && (
                                    <DisplayScoreSlider
                                        label="Key Dimensions Max"
                                        highlight={lod1AverageScore < lod1KeyDimensionsMaxScore}
                                        score={
                                            lod1KeyDimensionsMaxScore &&
                                            !isNaN(lod1KeyDimensionsMaxScore) &&
                                            lod1KeyDimensionsMaxScore >= 0
                                                ? Float.format(lod1KeyDimensionsMaxScore, 2)
                                                : 'N/A'
                                        }
                                    />
                                )}
                            </div>
                            <Divider variant="middle" component="li" />
                        </React.Fragment>
                    )}
                    {!isLoD1SelfAssessment && (
                        <React.Fragment>
                            {!hideLoD2Scores && (
                                <React.Fragment>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className="background-highlight">
                                                <NetworkCheckIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={t('Lod2 Score')} />
                                    </ListItem>
                                    <div style={styles.scoreMark}>
                                        <DisplayScoreSlider
                                            label="Average"
                                            highlight={lod2AverageScore > lod2KeyDimensionsMaxScore}
                                            score={
                                                lod2AverageScore && !isNaN(lod2AverageScore) && lod2AverageScore >= 0
                                                    ? Float.format(lod2AverageScore, 2)
                                                    : 'N/A'
                                            }
                                        />
                                    </div>
                                    <div style={styles.scoreMark}>
                                        <DisplayScoreSlider
                                            label="Key Dimensions Max"
                                            highlight={lod2AverageScore < lod2KeyDimensionsMaxScore}
                                            score={
                                                lod2KeyDimensionsMaxScore &&
                                                !isNaN(lod2KeyDimensionsMaxScore) &&
                                                lod2KeyDimensionsMaxScore >= 0
                                                    ? Float.format(lod2KeyDimensionsMaxScore, 2)
                                                    : 'N/A'
                                            }
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                            {!isNTXDimension && mraStatus.systemId === MRA_STATUS.VALIDATED && (
                                <React.Fragment>
                                    <Divider variant="middle" component="li" />

                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className="background-highlight">
                                                <NetworkCheckIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={t('Committee Score')} />
                                    </ListItem>
                                    <div style={styles.scoreMark}>
                                        <DisplayScoreSlider
                                            label="Average"
                                            highlight={committeeAverageScore > committeeKeyDimensionsMaxScore}
                                            score={
                                                committeeAverageScore &&
                                                !isNaN(committeeAverageScore) &&
                                                committeeAverageScore >= 0
                                                    ? Float.format(committeeAverageScore, 2)
                                                    : 'N/A'
                                            }
                                        />
                                    </div>
                                    <div style={styles.scoreMark}>
                                        <DisplayScoreSlider
                                            label="Key Dimensions Max"
                                            highlight={committeeAverageScore < committeeKeyDimensionsMaxScore}
                                            score={
                                                committeeKeyDimensionsMaxScore &&
                                                !isNaN(committeeKeyDimensionsMaxScore) &&
                                                committeeKeyDimensionsMaxScore >= 0
                                                    ? Float.format(committeeKeyDimensionsMaxScore, 2)
                                                    : 'N/A'
                                            }
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </List>
            </Paper>
            <Paper style={styles.columnPanel}>
                <AppBar position="static" className="background-lowlight background-linear-gradient ">
                    <Toolbar>
                        <Typography variant="h5">{t('MRA Conclusions')}</Typography>
                    </Toolbar>
                </AppBar>
                <List>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className="background-highlight">
                                <WarningRounded />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText style={{ flexDirection: 'column' }} primary={t('Critical Dimensions')} />
                    </ListItem>
                    <div style={styles.criticalDimensions}>
                        {criticalDimensions && showCriticalDimensions
                            ? criticalDimensions
                                  .map((o) => o.title)
                                  .filter((o) => o)
                                  .join(', ')
                            : ''}

                        {criticalDimensions.map((o) => o.title).filter((o) => o).length < criticalDimensions.length && (
                            <LoadingIndicator styles={styles.loadingIndicator} />
                        )}
                    </div>
                    <Divider variant="middle" component="li" />
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className="background-highlight">
                                <RateReviewIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText style={{ flexDirection: 'column' }} primary={t('Model Risk Assessment Result')} />
                    </ListItem>
                    <div style={{ ...styles.versionAuthor, fontWeight: 'bold' }}>
                        <ParameterSelect
                            label=""
                            field={{
                                params: {
                                    type: PARAMETER_TYPE_MODEL_RISK_ASSESSMENT_RESULT,
                                    filters: [
                                        (element) => {
                                            if (isNTXDimension) {
                                                return isNTXRiskAssessmentType(element.systemId);
                                            } else if (isGroupDimension) {
                                                return isGROUPRiskAssessmentType(element.systemId);
                                            } else {
                                                return isBPCERiskAssessmentType(element.systemId);
                                            }
                                        },
                                    ],
                                },
                            }}
                            optionsSort={(a, b) => {
                                if (a.id > b.id) return 1;
                                return -1;
                            }}
                            value={riskResult ? riskResult['@id'] : undefined}
                            disabled
                            required={false}
                        />
                    </div>
                    <Divider variant="middle" component="li" />
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className="background-highlight">
                                <RateReviewIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText style={{ flexDirection: 'column' }} primary={t('Chart')} />
                    </ListItem>
                    <div>
                        <div>
                            <Radar ref={radarRef} data={data} options={options} plugins={plugins} />
                            <label
                                style={{
                                    fontSize: '10px',
                                    marginLeft: '20px',
                                    color: '#7A7A7A',
                                    paddingTop: '20px',
                                    display: 'block',
                                }}
                            >
                                *key dimensions
                            </label>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <Button variant="outlined" color="primary" onClick={exportRadarChart}>
                                <i className="fa fa-download" style={{ marginRight: '6px' }}></i>
                                <span>Export</span>
                            </Button>
                        </div>
                    </div>
                </List>
            </Paper>
        </div>
    );
};

OverviewPanel.propTypes = {
    mra: PropTypes.object,
};

let styles = {
    scoreMark: {
        width: '75%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 30,
        marginBottom: 0,
        height: 40,
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
    },
    versionAuthor: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 0,
        marginBottom: 20,
        fontSize: 18,
        textAlign: 'center',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    criticalDimensions: {
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: 0,
        marginBottom: 20,
        minHeight: 22,
        fontSize: 18,
        textAlign: 'center',
    },
    loadingIndicator: {
        display: 'flex',
        transition: 'color 150ms',
        justifyContent: 'center',
        fontSize: '0.5rem',
        margin: '0.5rem auto 0.5rem',
    },
    container: {
        display: 'flex',
        placeContent: 'center',
    },
    columnPanel: {
        flexGrow: 1,
        marginRight: '2em',
        width: '30%',
    },
    mragroup: {
        display: 'flex',
        placeContent: 'space-between',
        placeItems: 'center',
        marginBottom: '10px',
        background: 'rgb(89,35,127, 0.06)',
        fontSize: '13px',
        padding: '10px',
        borderRadius: '6px',
    },
    functionalid: {
        width: '70%',
        overflowWrap: 'break-word',
        textAlign: 'left',
    },
    iconContainer: {
        padding: '2px 4px',
        borderRadius: '3px',
        color: 'white',
        verticalAlign: 'middle',
        display: 'inline-block',
        marginLeft: '5px',
        lineHeight: 1,
    },
    checkIcon: {
        background: '#578C44',
    },
    hourglassIcon: {
        background: '#EEAA41',
    },
};
