import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Modal from "../../Services/Modal";
import { LogicalDeleteForm } from "../Forms/LogicalDeleteForm/LogicalDeleteForm";
import FormControl from "@material-ui/core/FormControl";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {CircularProgress} from "@material-ui/core";
import {DatePicker} from "../Forms/DatePicker/DatePicker";
import {DisplayTextField} from "../Display/DisplayTextField/DisplayTextField";
import Alert from "../../Services/Alert";
import {userHasValidatorRights, userHasValidatorTeamRights} from "../../Store/ParameterStore";
import User from "../../Services/User/User";
import Http from "../../Services/Http";
import {ParameterSelect} from "../Forms/ParameterSelect/ParameterSelect";
import APIResourceStore from "../../Store/APIResourceStore";
import {PARAMETER_TYPE_REVIEW_RESULT, PARAMETER_TYPE_REVIEW_TYPE} from "../../Admin/ParameterAdmin";
import {APIResource} from "../../Services/APIResource/APIResource";

export class UpdateModelUseValidationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validationStatus: null,
            validationStatusRationale: null,
        };
        this.resourceReviewModelUse = new APIResource({
            id: 'review_model_uses',
        });
    }

    componentDidMount() {
        this.setState({
            validationStatus: this.props.validationStatus,
            validationStatusRationale: this.props.validationStatusRationale
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.entity !== this.props.entity
            || prevProps.validationStatus !== this.props.validationStatus
            || prevProps.validationStatusRationale !== this.props.validationStatusRationale
        ) {
            this.setState({
                validationStatus: this.props.validationStatus,
                validationStatusRationale: this.props.validationStatusRationale
            });
        }
    }

    save() {

        this.setState({progress: true});

        this.props.entity.validationStatus = this.state.validationStatus;
        this.props.entity.validationStatusRationale = this.state.validationStatusRationale;

        this.resourceReviewModelUse.apiPut(this.props.entity).then(() => {
            this.props.resource.getItem(this.props.review.id, true).then(() => {
                Modal.close();
                this.setState({progress: false});
            });
        }).catch(err => {
            console.error(err);
            Modal.close();
            this.setState({progress: false});
        });
    }

    render() {
        return (
            <div className={"model-use-review-form"}>
                <div className={'select-component'}>
                    <label>
                        Validation status
                    </label>
                    <ParameterSelect
                        field={{
                            resourceId: 'parameters',
                            params: {
                                type: PARAMETER_TYPE_REVIEW_RESULT,
                                multi: false,
                            },
                        }}
                        value={this.state.validationStatus}
                        onChange={(val) => {
                            this.setState({validationStatus: val})
                        }}
                    />
                </div>
                <div
                    style={{marginTop: 10}}
                    className={'edit-field'}
                >
                    <label>
                        Validation status rationale
                    </label>
                    <TextareaAutosize
                        value={this.state.validationStatusRationale}
                        onChange={event => this.setState({validationStatusRationale: event.target.value})}
                        rows={5}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}
                >
                    { this.state.progress ?
                        <Button
                            style={{ marginTop: 10 }}
                            variant="contained"
                            color="secondary"
                            className="save button-general"
                        >
                            <CircularProgress size={20} />
                        </Button>
                        :
                        <Button
                            style={{ marginTop: 10 }}
                            variant="contained"
                            color="secondary"
                            className="save button-general"
                            onClick={() => {
                                this.save();
                            }}
                        >
                            Save
                        </Button>
                    }
                </div>
            </div>
        );
    }
}

let styles = {
    actionLink: {
        marginLeft: 5
    }
};
