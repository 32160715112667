import Navigation from "../Navigation";
import Modal from "../Modal";
import React, { useState } from "react";
import PropTypes from "prop-types";
import ParameterStore from "../../Store/ParameterStore";
import APIResourceStore from "../../Store/APIResourceStore";
import Alert from "../Alert";
import { ModalContent } from "../../Components/Modal/ModalContent";
import { ButtonBar } from "../../Components/Modal/ButtonBar";
import { ActionButton } from "../../Components/Modal/ActionButton";
import { Typography } from "@material-ui/core";

/**
 * 
 * @param {import('../APIResource/APIResource').APIResource} resource 
 * @param {*} entity - Model à soumettre
 * @param {*} currentStatus 
 * @param {*} nextStatus 
 * @param {*} resourceEditComponent 
 */
const submitToMrmFunction = (
    resource,
    entity,
    currentStatus,
    nextStatus,
    resourceEditComponent
) => {
    entity.insertionFromImport = false; //Pour hacker la fonction de requiredByStatus
    let previousExpectedStatus =
        typeof entity.expectedStatus === "undefined"
            ? null
            : entity.expectedStatus;
    let saveConfirmationTxt = "";
    let updateConfirmationTxt = "";
    let validationErrorIntroTxt = "";

    if (currentStatus === ParameterStore("MODEL_STATUS_DRAFT")) {
        entity.underDeclarationConversion = true;
        entity.expectedStatus = ParameterStore(
            "MODEL_STATUS_UNDER_DECLARATION"
        );
        saveConfirmationTxt =
            "Your candidate has been submitted to MRM for review.";
        validationErrorIntroTxt =
            "Your changes has been saved but your draft has not been submitted. The following fields are required in order to proceed:";
    } else if (
        currentStatus === ParameterStore("MODEL_STATUS_UNDER_DECLARATION")
    ) {
        entity.inventoriedConversion = true;
        entity.expectedStatus = ParameterStore("MODEL_STATUS_ACTIVE");
        saveConfirmationTxt =
            "Your model has been submitted to MRM for final review.";
        validationErrorIntroTxt =
            "Your declaration is incomplete. In order to submit to MRM, the following information is required:";
    }
    updateConfirmationTxt = saveConfirmationTxt;

    resource.currentEditingActions
        .save(
            saveConfirmationTxt,
            updateConfirmationTxt,
            validationErrorIntroTxt,
            entity,
        )
        .then((entity) => {
            if (currentStatus === ParameterStore("MODEL_STATUS_DRAFT")) {
                entity.expectedStatus = ParameterStore(
                    "MODEL_STATUS_UNDER_DECLARATION"
                );
            } else if (
                currentStatus ===
                ParameterStore("MODEL_STATUS_UNDER_DECLARATION")
            ) {
                entity.expectedStatus = ParameterStore("MODEL_STATUS_ACTIVE");
            }
            resource
                .apiPut(entity)
                .then(() => {
                    Modal.close();
                })
                .catch(() => {
                    Modal.close();
                });
        })
        .catch(() => {
            Modal.close();
            if (
                currentStatus === ParameterStore("MODEL_STATUS_DRAFT") ||
                currentStatus ===
                    ParameterStore("MODEL_STATUS_UNDER_DECLARATION")
            ) {
                entity.expectedStatus = previousExpectedStatus;
                entity.modelStatus = currentStatus;
                if (currentStatus === ParameterStore("MODEL_STATUS_DRAFT")) {
                    entity.underDeclarationConversion = false;
                } else if (
                    currentStatus ===
                    ParameterStore("MODEL_STATUS_UNDER_DECLARATION")
                ) {
                    entity.inventoriedConversion = false;
                }
            }
            resource.apiPut(entity).then((entity) => {
                resourceEditComponent.setState({ entity: entity });
            });
        });
};

export const submitToMRM = (
    resource,
    entity,
    currentStatus,
    nextStatus,
    resourceEditComponent
) => {
    if (
        currentStatus === ParameterStore("MODEL_STATUS_DRAFT") ||
        currentStatus === ParameterStore("MODEL_STATUS_UNDER_DECLARATION")
    ) {
        let currentStatusId = currentStatus.split("/")[
            currentStatus.split("/").length - 1
        ];
        let nextStatusId = nextStatus.split("/")[
            nextStatus.split("/").length - 1
        ];
        Modal.open({
            title:
                currentStatus === ParameterStore("MODEL_STATUS_DRAFT")
                    ? "Candidate submission"
                    : "Model submission",
            content: (
                <SubmitToMrmModal
                    entity={entity}
                    currentStatus={APIResourceStore.resources.parameters.getObservableItem(
                        currentStatusId
                    )}
                    nextStatus={APIResourceStore.resources.parameters.getObservableItem(
                        nextStatusId
                    )}
                    callback={() =>
                        submitToMrmFunction(
                            resource,
                            entity,
                            currentStatus,
                            nextStatus,
                            resourceEditComponent
                        )
                    }
                />
            ),
        });
    } else {
        submitToMrmFunction(
            resource,
            entity,
            currentStatus,
            nextStatus,
            resourceEditComponent
        );
    }
};

export const validateModel = (resource, entity, resourceDetailComponent) => {
    if (
        entity.expectedStatus === ParameterStore("MODEL_STATUS_ACTIVE") &&
        !entity.modelValidatorTeams
    ) {
        Alert.show({
            message: "Model validator teams: This field is required",
            type: "danger",
        });
        return;
    }

    let previousStatus = entity.modelStatus;
    let previousExpectedStatus = entity.expectedStatus;
    entity.modelStatus = entity.expectedStatus;
    entity.expectedStatus = null;

    let saveConfirmationTxt = "";

    if (
        entity.modelStatus === ParameterStore("MODEL_STATUS_UNDER_DECLARATION")
    ) {
        saveConfirmationTxt =
            "The draft has been converted to model under declaration. The declarer has been notified.";
    } else if (entity.modelStatus === ParameterStore("MODEL_STATUS_ACTIVE")) {
        saveConfirmationTxt =
            "The model has been added to the Inventory. The declarer has been notified.";
    }

    resource
        .apiPut(entity)
        .then((entity) => {
            Alert.show({
                message: saveConfirmationTxt,
                type: "success",
            });
            resourceDetailComponent.setState({ entity: entity });
        })
        .catch(() => {
            entity.modelStatus = previousStatus;
            entity.expectedStatus = previousExpectedStatus;
        });
};

/**
 * Changement de retirementStatus dans le processus de retrait.
 *
 * On ne fait pas de contrôle au niveau de cet appel.
 * Si on veut plus de sécurité il faut retranscrire les règles de RetireModelForm en back.
 *
 * @param {import('../APIResource/APIResource').APIResource} resource
 * @param {*} entity
 * @param {*} newStatusSystemId
 * @param {*} resourceDetailComponent
 * @param {*} callback
 */
export const retireModelStep = (
    entity,
    resource,
    newStatusSystemId,
    message,
    resourceDetailComponent,
    callback
) => {
    entity.retirementStatus = ParameterStore(newStatusSystemId);

    return resource.apiPut(entity).then((e) => {
        Alert.show({
            message,
            type: "success",
        });
        // On change l'onglet, et on l'indique dans la barre d'adresse
        resourceDetailComponent.setState({ currentTab: resource.getTabId('detail', 'Retirement') }, () => {
            callback(e);
            Navigation.router.history.push(`/resource/models/${e.id}/detail?tab=Retirement`);
        });
    });
};

/**
 * Remise à zéro du statut retirement, en conservant les justifications ou non.
 *
 * @param {*} entity
 * @param {*} resource
 * @param {*} resourceDetailComponent
 * @param {*} callback
 * @param {*} keepJustification
 */
export const resetRetirementStatus = (
    entity,
    resource,
    message,
    resourceDetailComponent,
    callback,
    keepJustification = true
) => {
    entity.retirementStatus = null;
    entity.retirementJustificationLod1 = keepJustification
        ? entity.retirementJustificationLod1
        : null;
    entity.retirementJustificationMrm = null;
    entity.retirementCommittee = null;
    entity.retirementRequestDate = null;
    entity.retirementExpectedDate = null;

    return resource.apiPut(entity).then((e) => {
        Alert.show({
            message,
            type: "success",
        });
        resourceDetailComponent.setState({ entity: e }, () => callback(e));
    });
};

const SubmitToMrmModal = (props) => {
    const [running, setRunning] = useState(false);

    const onClick = () => {
        setRunning(true);
        props.callback();
    }

    return (
        <ModalContent  className={"submit-to-mrm-modal"}>
            {props.currentStatus["@id"] ===
            ParameterStore("MODEL_STATUS_DRAFT") ? (
                <Typography component="p">
                    {"• You are about to submit " +
                        props.entity.functionalID +
                        " to MRM for review.\n" +
                        "• The MRM team will confirm that this candidate is indeed a model or will categorize it as a « Non-Model ».\n" +
                        "• All the fields of the « ID Card » and « Properties » tabs are mandatory in order to proceed."}
                </Typography>
            ) : (
                <Typography component="p">
                    {"• You are about to submit the model " +
                        props.entity.functionalID +
                        " to MRM for final review.\n" +
                        "• The MRM team will verify that all the information is correctly filled in and add the model to the inventory.\n" +
                        "• Please note that in order to proceed you must have declared at least one use, one implementation, one mitigation action (or indicate that this is not applicable) and the tiering of the model."}
                </Typography>
            )}
            <ButtonBar>
                <ActionButton
                    onClick={Modal.close}
                    disabled={running}
                >
                    Cancel
                </ActionButton>
                <ActionButton
                    onClick={onClick}
                    loading={running}
                >
                    Continue
                </ActionButton>
            </ButtonBar>
        </ModalContent>
    );
    
}
SubmitToMrmModal.propTypes = {
    /** entity est en fait un Model */
    entity: PropTypes.object,
    currentStatus: PropTypes.object,
    nextStatus: PropTypes.object,
    callback: PropTypes.func,
};
