import React from 'react';
import {APIResource} from "../Services/APIResource/APIResource";
import {userHasRoleMRM} from "../Store/ParameterStore";
import User from "../Services/User/User";
import EntityTreeProvider from "../Services/APIResource/FieldProviders/EntityTreeProvider";
import {TableDisplay} from "../Components/Display/TableDisplay/TableDisplay";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";

export const ApiResourceDefaultParams = {
    id: 'validation_families',
    name: 'Models family',
    icon: 'cog',
    fieldForTitle: 'name'
};

export default class ValidationFamily {
    constructor(){
        this.configure();
    }

    configure(){
        let validationFamilies = new APIResource(ApiResourceDefaultParams);
        validationFamilies
            .setFields({
                id: {title: 'ID'},
                name: {title: 'Name', type: 'text', required: true, width: 600},
                validatorTeamsIris: {
                    title: 'Validator teams',
                    type: 'entityTree',
                    params: {
                        multi: true,
                        resource: 'scopes',
                        instanceId: 'scopes_val',
                        displayField: 'title',
                        childrenPropertyName: 'childScopes',
                        endpoints: {
                            getAll: 'scopes/all-scopes/val',
                        }
                    },
                    edit: (field, value, onChange, entity, routeParams) => {
                        return EntityTreeProvider.getDisplay(field,value, entity, {});
                    },
                },
                modelsEntities: {
                    title: 'Model uses',
                    display: (field, value, entity, props) => (
                        <TableDisplay
                            endpoints={`entities/ValidationFamily/Models/${entity.id}`}
                            cols={[
                                {
                                    label: 'Functional ID',
                                    field: 'functionalID',
                                },
                                {
                                    label: 'Type',
                                    field: 'modelTypeString'
                                },
                                {
                                    label: 'Micro Risk Family',
                                    field: 'microRiskFamilyString',
                                },
                                {
                                    label: 'Tier',
                                    field: 'categoryResult'
                                },
                                {
                                    label: 'Model Owner',
                                    field: 'modelOwner',
                                },
                            ]}
                            actions={(value) => {
                                return (
                                    <div>
                                        <Link to={'/resource/models/' + value.id + '/detail'} style={styles.actionLink}>
                                            <Button
                                                style={styles.mrDetailBtn}
                                                variant="contained"
                                                color="primary"
                                                className="button-table button-show-version"
                                            >
                                                Detail
                                            </Button>
                                        </Link>
                                    </div>
                                );
                            }}
                        />
                    ),
                    displayList: () => null,
                }
            })
            .setValidation((entity) => {return true;})
            .setLayout({
                tabs: {
                    'Information' : {
                        rows: [
                            {
                                panels: {
                                    'Properties': {
                                        cols: 12,
                                        fields: ['name', 'validatorTeamsIris']
                                    }
                                }
                            }
                        ]
                    },
                    'Models' : {
                        rows: [
                            {
                                panels: {
                                    'Models': {
                                        cols: 12,
                                        fields: ['modelsEntities']
                                    }
                                }
                            }
                        ]
                    },
                }
            })
            .genListView({
                fields: ['name'],
                itemAccessCondition: (entity) => {
                    return userHasRoleMRM()
                        || User.profile.isMemberOfValidatorTeam === true;
                },
            })
            .genInsertView({
                itemAccessCondition: (entity) => {
                    return userHasRoleMRM()
                        || User.profile.isMemberOfValidatorTeam === true;
                },
            })
            .genEditView({
                fields: ['name', 'validatorTeamsIris'],
                itemAccessCondition: (entity) => {
                    return userHasRoleMRM()
                        || User.profile.isMemberOfValidatorTeam === true;
                },
            })
            .genDetailView({
                itemAccessCondition: (entity) => {
                    return userHasRoleMRM()
                        || User.profile.isMemberOfValidatorTeam === true;
                },
            })
            .allowDelete({
                itemAccessCondition: () => userHasRoleMRM(),
            })
        ;
    }
}

const styles = {
    mrDetailBtn: {
        margin: 1,
    },
    actionLink: {
        marginLeft: 5,
    },
};
