import RouteStore, {Route} from "../../Store/RouteStore";
import React from "react";
import Import from "./Import";
import RecommendationImport from "./RecommmendationImport";
import ReviewImport from "./ReviewImport";

RouteStore.routes.import = new Route({
    path: '/import/model',
    component: (props) => <Import {...props}/>,
    accessControl: ['ROLE_MRM', 'ROLE_ADMIN'],
    menuItem: {
        title: 'Import'
    }
});
RouteStore.routes.importRecommendation = new Route({
    path: '/import/recommendation',
    component: (props) => <RecommendationImport {...props}/>,
    menuItem: {
        title: 'Import'
    }
});
RouteStore.routes.importReview = new Route({
    path: '/import/review',
    component: (props) => <ReviewImport {...props}/>,
    accessControl: ['ROLE_MRM', 'ROLE_ADMIN'],
    menuItem: {
        title: 'Import'
    }
});
