import React from "react";
import { EntityDisplay } from "../../../Components/Display/EntityDisplay/EntityDisplay";
import { EntitySelectAsync } from "../../../Components/Forms/EntitySelectAsync/EntitySelectAsync";
import { EntitySelect } from "../../../Components/Forms/EntitySelect/EntitySelect";
import {CONTEXT_LIST} from "../APIResource";

class EntityAsyncProvider {
  getDisplay(field, value, entity, props, resource, context) {
    return (
        <EntityDisplay
            {...props}
            resourceId={field.params.resource}
            instanceId={field.params.instanceId}
            resourceLabel={field.params.displayField}
            label={field.title}
            value={value}
            links={field.params.links}
            linkPath={field.params.linkPath}
            linkAction={field.params.linkAction}
            parentEntity={entity}
            issueButton={'issueButton' in field ? field.issueButton : false}
            tooltip={field.params.tooltip}
            context={context}
        />
    );
  }

  getDisplayList(field, value, entity, props, resource) {
    return this.getDisplay(field, value, entity, props, resource, CONTEXT_LIST);
  }

  getEdit(field, value, onChange, entity, routeParams, loading = false) {
    return (
        <EntitySelectAsync
            label={field.title}
            //disabled={loading}
            resourceId={field.params.resource}
            instanceId={field.params.instanceId}
            resourceLabel={field.params.editDisplayField !== undefined ? field.params.editDisplayField : field.params.displayField}
            value={value}
            onChange={onChange}
            clearable={!('required' in field && field.required === true)}
            multi={field.params.multi}
            issueButton={'issueButton' in field ? field.issueButton : true}
            entity={entity}
            filters={field.params.filters}
            required={'required' in field && field.requiredComputed === true}
            error={!!field.errorHelperText}
            className={field.errorHelperText ? 'field-error-control' : ''}
            helperText={field.errorHelperText}
            endpoints={field.params.endpoints}
            noOption={field.params.hasOwnProperty('noOption') ? field.params.noOption : true}
        />
    );
  }

  getFilter(options = {}) {
    const { field, value, onChange } = options;
    return (
        <EntitySelect
            resourceId={field.params.resource}
            instanceId={field.params.instanceId}
            context="filter"
            resourceLabel={field.params.displayField}
            onChange={onChange}
            clearable={true}
            multi={true}
            filters={field.params.filters}
            value={value ? value : null}
            endpoints={field.params.endpoints}
            orExistsMultiFilterNotAllowed={field.params.orExistsMultiFilterNotAllowed || false}
        />
    );
  }
}

export default new EntityAsyncProvider();
