import React from 'react';
import { Button, styled } from '@material-ui/core';

const SimpleButton = styled(Button)({
    margin: '1',
});

export const EditButton = (props) => (
    <SimpleButton variant="contained" color="primary" className="button-table edit" {...props}>
        { props.children || "Edit"}
    </SimpleButton>
);
