/* eslint-disable no-console */
/* eslint-disable react/display-name */
import React from 'react';
import {LinkPreload as Link} from '../Components/Link/Link';

import {APIResource, CONTEXT_ADD, CONTEXT_DETAIL, CONTEXT_EDIT, CONTEXT_CUSTOM} from '../Services/APIResource/APIResource';
import { TableDisplay } from '../Components/Display/TableDisplay/TableDisplay';
import { DisplayTextField } from '../Components/Display/DisplayTextField/DisplayTextField';
import VersionsDetail from '../Components/Model/Detail/VersionsDetail';
import { ModelExport } from '../Services/BulkActions/BulkExport/ModelExport';
import { ChangeLogExport } from '../Services/BulkActions/BulkExport/ChangeLogExport';
import { AddCertificationForm } from '../Services/BulkActions/BulkAddCertifications/BulkAddCertifications';
import { BulkEdit } from '../Services/BulkActions/BulkEdit/BulkEdit';
import { BulkDelete } from '../Services/BulkActions/BulkDelete/BulkDelete';
import Modal from '../Services/Modal';
import APIResourceStore from '../Store/APIResourceStore';
import Navigation from '../Services/Navigation';
import User, { ROLE as Role, ROLE } from '../Services/User/User';
import { canEditByProcess, MRA_STATUS } from '../Services/MRA';
import ParameterStore, {
    hasOneGroupEntityNTX,
    hasGroupEntityBPCE,
    userHasRole,
    userHasRoleMRM,
    userHasRoleSTD,
    userHasOwnershipRights,
    userHasDeclarerRights,
    userHasBusinessSponsorRights,
    userHasStakeHolderRights,
    userHasRoleADMIN,
    userValidatorTeamManagedRights,
    userHasNoticeOwnershipRights,
    userHasNoRole,
    userIsVal,
    userHasContributingRights,
    userHasSpecificRole,
    userHasIssuerTeamsRights,
    userHasNoticeIssuerTeamsRights,
    userHasRoleIG,
    userHasMRMRights,
    userHasDeveloperRights,
    userHasImplementerRights,
    userHasValidatorRights,
    userHasRights,
    USER_SCOPE_MANAGER,
    BusinessRole,
} from '../Store/ParameterStore';
import { IssueAddForm } from '../Components/Issue/IssueButton';
import { LogicalDeleteButton } from '../Components/DeleteButton/LogicalDeleteButton';
import Http from '../Services/Http';
import {
  PARAMETER_TYPE_MODEL_TYPE,
  PARAMETER_TYPE_ADL1,
  PARAMETER_TYPE_ADL2,
  PARAMETER_TYPE_ADL3,
  PARAMETER_TYPE_MODEL_NATURE_STANDARD,
  PARAMETER_TYPE_CHAMPION_OR_CHALLENGER,
  PARAMETER_TYPE_IN_HOUSE_OR_VENDOR,
  PARAMETER_TYPE_RISK_CATEGORY,
  PARAMETER_TYPE_MICRO_RISK_CATEGORY,
  PARAMETER_TYPE_MICRO_RISK_SUB_CATEGORY,
  PARAMETER_TYPE_MODEL_STATUS,
  PARAMETER_TYPE_MODEL_VALIDATOR_LOCATION,
  PARAMETER_TYPE_REVIEW_TYPE,
  PARAMETER_TYPE_SPECIFIC_FRAMEWORK,
  PARAMETER_TYPE_NON_MODEL_STATUS,
  PARAMETER_TYPE_REVIEW_RESULT,
  PARAMETER_TYPE_MODEL_OBSERVABILITY,
  PARAMETER_TYPE_COMPLEXITY_LEVEL,
  PARAMETER_TYPE_MATERIALITY,
  PARAMETER_TYPE_MODEL_USAGE,
  PARAMETER_TYPE_MODEL_TIER_RESULT,
  PARAMETER_TYPE_EXTERNAL_IMPACT,
  PARAMETER_TYPE_METRICS,
  PARAMETER_TYPE_MODEL_TIERING_CATEGORY,
  PARAMETER_TYPE_TIERING_COMPLEXITY_RATIONALE, PARAMETER_TYPE_MODEL_POLICY_EXCEPTION_TYPE
} from '../Admin/ParameterAdmin';
import { ParameterSelect } from '../Components/Forms/ParameterSelect/ParameterSelect';
import {
    DocumentManager,
    DocumentList,
    DocumentListAccordion,
} from '../Components/Display/DocumentManager/DocumentManager';
import DateFormatter from '../Services/DateFormatter';
import { TieringForm } from '../Components/Tiering/TieringForm';
import { VerifiedButton } from '../Components/VerifiedButton/VerifiedButton';
import { CloneModelModal } from '../Components/Model/Clone/CloneModelModal';
import { submitToMRM, validateModel } from '../Services/Actions/ModelActions';
import { EntityDisplay } from '../Components/Display/EntityDisplay/EntityDisplay';
import BackgroundModel from '../Components/Model/Add/BackgroundModel';
import Environment from '../Services/Environment';
import TextProvider from '../Services/APIResource/FieldProviders/TextProvider';
import { Header } from '../Components/Header/Header';
import { ReviewRequestModal } from '../Components/Review/ReviewRequestModal';
import { ValidationFamilyEdit } from '../Services/BulkActions/BulkValidationFamily/ValidationFamilyEdit';
import { ModelStepProvider } from '../Components/ModelStep/ModelStep';
import ModalDeclareModel from '../Components/Model/Add/ModalDeclareModel';
import ModelRetirementModal, { retirementFields } from '../Components/Model/Retire/ModelRetirementModal';
import EntityAsyncProvider from '../Services/APIResource/FieldProviders/EntityAsyncProvider';
import ModelRemovalModal from '../Components/Forms/ModelRemoval/ModelRemoval';
import BoolProvider from '../Services/APIResource/FieldProviders/BoolProvider';
import { confirmAsNonModel, convertToModel } from '../Services/Actions/NonModelActions';
import ParameterProvider from '../Services/APIResource/FieldProviders/ParameterProvider';
import { NewReview } from '../Services/BulkActions/BulkReview/NewReview';
import { getUserRole } from '../Services/MRA';
import { getIdFromIri } from '../Services/utils';
import AssociateFindingNotice from '../Components/Review/AssociateFindingNotice';
import ReviewIssueRelation from '../Components/Review/ReviewIssueRelation';
import ValidateFindingNotice from '../Components/Review/ValidateFindingNotice';
import String from '../Services/String';
import DateProvider from '../Services/APIResource/FieldProviders/DateProvider';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { NewUse } from '../Services/BulkActions/BulkUse/NewUse';
import { needHighlightField as needHighlightFieldCommon } from './common';
import { EditButton } from '../Components/Buttons/EditButton';
import { DetailButton } from '../Components/Buttons/DetailButton';
import { postSaveRedirectToCertification } from './CertificationAdmin';
import ModelProvider from '../Services/APIResource/FieldProviders/ModelProvider';
import { REVIEW_STATUS_CLOSED } from './ReviewAdmin';
import {ConfirmModal} from "../Components/Modal/ConfirmModal";
import {specificFrameworkConversionFields} from "../Components/Forms/ConvertSpecificFrameworkForm/ConvertSpecificFrameworkForm";
import {OpenModal} from "../Components/Modal/OpenModal";
import { Init as MraInit } from "../Components/Mra/Init"

let mitigationActionsMap = {
    false: 'At least one mitigation action is applicable to this model',
    true: 'No mitigation action is applicable to this model',
};

const MODEL_EDIT_FIELDS_DEFAULT = [
    'name',
    'description',
    'initialID',
    'ECBID',
    'ECBAnnexID',
    'underDeclarationConversion',
    'inventoriedConversion',
    'clonedFrom',
    'modelType',
    'output',
    'referenceDocumentation',
    'validatorsLocation',
    'coreModel',
    'coreModelParent',
    'declarer',
    'declarationDate',
    'modelOwner',
    'modelOwnerDelegation',
    'modelOwnerDelegationDenyComment',
    'modelDeveloperTeam',
    'modelValidatorTeams',
    'validationFamily',
    'businessSponsor',
    'dataContactPointTeam',
    'riskCategory',
    'microRiskCategory',
    'microRiskSubCategory',
    'applicationDomainLevel1',
    'applicationDomainLevel2',
    'applicationDomainLevel3',
    'championOrChallenger',
    'championModels',
    'inHouseOrVendor',
    'vendor',
    'modelNatureStandard',
    'modelNatureDetailedString',
    'modelObservability',
    'nextScheduledValidationDate',
    'nextValidationDateVerificationComment',
    'nextScheduledValidationType',
    'upStreamNotAvailable',
    'downStreamNotAvailable',
    'upstreamModels',
    'downstreamModels',
    'backgroundModelRelations',
    'operationalRiskFlag',
    'automaticScoreFlag',
    'defaultPortfolioFlag',
    'productionRun',
    'changeLogComment',
    'mrmTeamsOverride',
    'dataSource',
    'modelStatus',
    'retirementCommittee',
    'retirementJustificationMrm',
    'retirementRefusedJustification',
    'delegationCommittees',
    'retirementRequestDate',
    'retirementExpectedDate',
    'policyExceptionStartDate',
    'policyExceptionEndDate',
    'policyExceptionType',
    'policyExceptionJustification',
    'specificFramework',
    //Champs non éditables
    'modelID',
    'functionalID',
    'reviewedModel',
    'nextFullValidationDateProcedure',
    'nextPeriodicValidationDateProcedure',
    'modelOwnerTeams',
    'modelOwnerEstablishment',
    'modelOwnerPosition',
    'lastValidationType',
    'lastCertificationDate',
    'lastFullValidationDate',
    'lastPeriodicValidationDate',
    'lastMinorEvolutionReviewDate',
    'certifierName',
    'certifierTeam',
    'certifierPosition',
    'lastCertificationResult',
    'inherentModelRiskScore',
    // "modelRiskScore",
    // "residualModelRiskScore",
    'modelRiskAssessmentResults',
    'validationStatus',
    'validationStatusRationale',
    'criticalScoreDimension',
    'averageModelRiskScore',
    'treeDeterminationAnswers',
    'useDescription',
    'insertionFromImport',
    'mitigationActions',
];
const MODEL_DETAIL_FIELDS_DEFAULT = [
    'name',
    'description',
    'treeDeterminationAnswers',
    'useDescription',
    'modelID',
    'initialID',
    'functionalID',
    'ECBID',
    'ECBAnnexID',
    'underDeclarationConversion',
    'inventoriedConversion',
    'modelStatusDeletionDate',
    'modelStatusRetirementDate',
    'clonedFrom',
    'modelType',
    'specificFramework',
    'output',
    'referenceDocumentation',
    'validatorsLocation',
    'coreModel',
    'coreModelParent',
    'declarer',
    'declarationDate',
    'modelOwner',
    'modelOwnerTeams',
    'modelOwnerEstablishment',
    'modelOwnerPosition',
    'modelOwnerDelegation',
    'modelDeveloperTeam',
    'modelValidatorTeams',
    'reviewedModel',
    'validationFamily',
    'businessSponsor',
    'dataContactPointTeam',
    'riskCategory',
    'microRiskCategory',
    'microRiskSubCategory',
    'applicationDomainLevel1',
    'applicationDomainLevel2',
    'applicationDomainLevel3',
    'championOrChallenger',
    'championModels',
    'inHouseOrVendor',
    'vendor',
    'modelNatureStandard',
    'modelNatureDetailedString',
    'modelObservability',
    'upStreamNotAvailable',
    'downStreamNotAvailable',
    'upstreamModels',
    'downstreamModels',
    'backgroundModelRelations',
    'foregroundModelRelations',
    'lastValidationType',
    'lastFullValidationDate',
    'lastPeriodicValidationDate',
    'lastMinorEvolutionReviewDate',
    'validationStatus',
    'validationStatusRationale',
    'lastCertificationDate',
    'nextScheduledValidationDate',
    'nextValidationDateVerificationComment',
    'nextScheduledValidationType',
    'nextFullValidationDateProcedure',
    'nextPeriodicValidationDateProcedure',
    'firstImplementationDate',
    'implementationsEntities',
    'certifierName',
    'certifierTeam',
    'certifierPosition',
    'lastCertificationResult',
    'inherentModelRiskScore',
    // "modelRiskScore",
    // "residualModelRiskScore",
    'modelRiskAssessmentResults',
    'criticalScoreDimension',
    'averageModelRiskScore',
    'tieringsEntities',
    'operationalRiskFlag',
    'automaticScoreFlag',
    'defaultPortfolioFlag',
    'productionRun',
    'certificationsEntities',
    'certificationsIssuesEntities',
    'modelUsesEntities',
    'mrasEntities',
    'reviewsEntities',
    'numberOfOpenNotices',
    'numberOfCriticalNotices',
    'noticesEntities',
    'findingsEntities',
    // 'iggBceRecommendationEntities',
    'versionsSummary',
    'deletionComment',
    'nonModelComment',
    'mrmTeams',
    'dataSource',
    'documentsEntities',
    'mitigationActions',
    'coveringMitigationActionsEntities',
    'reservingMitigationActionsEntities',
    'insertionFromImport',
    'modelStatus',
    'nonModelStatus',
    'retirementStatus',
    'retirementCommittee',
    'retirementJustificationMrm',
    'retirementDocumentsEntities',
    'retirementJustificationLod1',
    'retirementRefusedJustification',
    'delegationCommittees',
    'policyExceptionStartDate',
    'policyExceptionEndDate',
    'policyExceptionType',
    'policyExceptionJustification',
    'retirementRequestDate',
    'retirementExpectedDate',
    'specificFrameworkJustification',
    'convertSpecificFrameworkDocumentsEntities',
];
const MODEL_LIST_FIELDS_DEFAULT = [
    'modelID',
    'name',
    'modelType',
    'riskCategory',
    'modelOwner',
    'modelDeveloperTeam',
    'modelValidatorTeams',
];

const MODEL_FIELDS_BY_STATUS = {
    functionalID: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    name: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    description: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelType: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    output: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelStatus: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    expectedStatus: {
        draft: ['detail'],
        candidate: ['detail'],
        underDeclaration: ['detail'],
    },
    modelOwner: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelDeveloperTeam: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    businessSponsor: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    dataContactPointTeam: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    riskCategory: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    microRiskCategory: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    microRiskSubCategory: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    championOrChallenger: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    championModels: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    inHouseOrVendor: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    vendor: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    dataSource: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelNatureStandard: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelNatureDetailedString: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    useDescription: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    documentsEntities: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    versionsSummary: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    changeLogComment: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    underDeclarationConversion: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    inventoriedConversion: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelOwnerTeams: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelOwnerEntity: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelOwnerDelegation: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelValidatorTeams: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    validationFamily: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    mrmTeams: {
        draft: ['detail'],
        candidate: ['detail'],
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail'],
    },
    mrmTeamsOverride: {
        draft: ['edit'],
        candidate: ['edit'],
        underDeclaration: ['edit'],
        readyToBeInventoried: ['edit'],
    },
    applicationDomainLevel1: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    applicationDomainLevel2: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    applicationDomainLevel3: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelID: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    initialID: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    systemID: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    ECBID: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    ECBAnnexID: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    coreModel: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    coreModelParent: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    upStreamNotAvailable: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    downStreamNotAvailable: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    upstreamModels: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    downstreamModels: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    operationalRiskFlag: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    automaticScoreFlag: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    defaultPortfolioFlag: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    productionRun: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    clonedFrom: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    firstImplementationDate: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    implementationsEntities: {
        candidate: ['detail'],
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    mitigationActions: {
        candidate: ['detail'],
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail'],
    },
    coveringMitigationActionsEntities: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    reservingMitigationActionsEntities: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelUsesEntities: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    tieringsEntities: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    treeDeterminationAnswers: {
        draft: ['detail'],
        candidate: ['detail'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelOwnerEstablishment: {
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelRiskAssessmentResults: {
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    validationStatus: {
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    criticalScoreDimension: {
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    averageModelRiskScore: {
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    backgroundModelRelations: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    foregroundModelRelations: {
        candidate: ['detail'],
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail'],
    },
    nextScheduledValidationDate: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    nextValidationDateVerificationComment: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelObservability: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    reviewsEntities: {
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail'],
    },
    policyExceptionType:  {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    policyExceptionStartDate: {
      underDeclaration: ['detail', 'edit'],
      readyToBeInventoried: ['detail', 'edit']
    },
    policyExceptionEndDate: {
      underDeclaration: ['detail', 'edit'],
      readyToBeInventoried: ['detail', 'edit']
    },
    policyExceptionJustification: {
      underDeclaration: ['detail', 'edit'],
      readyToBeInventoried: ['detail', 'edit']
    }
};

const FORCE_HIDE_BEFORE_CANDIDATE = ['operationalRiskFlag'];

const FORCE_HIDE_BEFORE_INVENTORIED = [
    'inherentModelRiskScore',
    // "modelRiskScore",
    // "residualModelRiskScore",
    'modelRiskAssessmentResults',
    'validationStatus',
    'validationStatusRationale',
    'criticalScoreDimension',
    'averageModelRiskScore',
    'lastCertificationResult',
    'delegationCommittees',
];

const enableByStatus = (entity, propertyName, context) => {
    let enable = true;

    // Si on est dans le contexte de la vue de détail du modèle, on affiche tous les champs renseignés
    if (context === CONTEXT_DETAIL) {

        if (
            FORCE_HIDE_BEFORE_CANDIDATE.includes(propertyName) &&
            entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT')
        ) {
            return false;
        }

        if (
            FORCE_HIDE_BEFORE_INVENTORIED.includes(propertyName) &&
            !['MODEL_STATUS_ACTIVE', 'MODEL_STATUS_RETIRED', 'MODEL_STATUS_DELETED']
                .map(ParameterStore)
                .includes(entity.modelStatus)
        ) {
            return false;
        }

        //Cas particulier de ModelUsesEntities undefined, cette propriété est asynchrone
        if (propertyName === 'modelUsesEntities' && entity.hasModelUses) {
            return true;
        }

        return (
            (entity[propertyName] !== undefined && !Array.isArray(entity[propertyName])) ||
            (entity[propertyName] !== undefined &&
                Array.isArray(entity[propertyName]) &&
                entity[propertyName].length > 0) ||
            (entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT') &&
                !entity.expectedStatus &&
                MODEL_FIELDS_BY_STATUS[propertyName] !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].draft !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].draft.includes(context)) ||
            (entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT') &&
                entity.expectedStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') &&
                MODEL_FIELDS_BY_STATUS[propertyName] !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].candidate !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].candidate.includes(context)) ||
            (entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') &&
                !entity.expectedStatus &&
                MODEL_FIELDS_BY_STATUS[propertyName] !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].underDeclaration !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].underDeclaration.includes(context)) ||
            (entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') &&
                entity.expectedStatus === ParameterStore('MODEL_STATUS_ACTIVE') &&
                MODEL_FIELDS_BY_STATUS[propertyName] !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].readyToBeInventoried !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].readyToBeInventoried.includes(context)) ||
            entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE')
        );
    }

    // Si le modèle n'est pas en Draft, qu'il est clôné ou qu'il est en cours d'insertion, on affiche tous les champs
    if (entity.modelStatus !== ParameterStore('MODEL_STATUS_DRAFT') || entity.clonedFrom || !entity.id) {
        enable = true;
    }

    // Sinon, si on est en Draft, si la règle d'affichage du champ n'est pas définie ou l'est est n'autorise pas l'affichage dans le contexte, le champ ne doit pas apparaître
    if (entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT')) {
        if (MODEL_FIELDS_BY_STATUS[propertyName] === undefined) {
            enable = false;
        } else if (
            MODEL_FIELDS_BY_STATUS[propertyName].draft === undefined ||
            !MODEL_FIELDS_BY_STATUS[propertyName].draft.includes(context)
        ) {
            enable = false;
        }
        if (
            entity.expectedStatus &&
            MODEL_FIELDS_BY_STATUS[propertyName] !== undefined &&
            MODEL_FIELDS_BY_STATUS[propertyName].candidate !== undefined &&
            MODEL_FIELDS_BY_STATUS[propertyName].candidate.includes(context)
        ) {
            enable = true;
        }
    }

    // Si on est dans le contexte de la vue de détail du modèle, et que le champ ne doit pas être affiché (voir conditions précédentes), on force l'affichage de tous champs renseignés
    if (context === CONTEXT_DETAIL && enable === false) {
        enable = entity[propertyName] !== undefined;
    }

    return enable;
};

const requiredByStatus = (entity, propertyName) => {
    let required = [];

    if (userHasRoleMRM()) {
        return false;
    }

    if (entity.insertionFromImport === true) {
        if (
            entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE') &&
            userHasOwnershipRights(User.getId(), entity)
        ) {
            required = required.concat([
                'name',
                'description',
                'modelType',
                'modelOwner',
                'modelDeveloperTeam',
                'riskCategory',
            ]);
        } else if (entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT')) {
            required = required.concat([
                'name',
                'description',
                'modelOwner',
                'modelDeveloperTeam',
                'riskCategory',
                'modelType',
                'useDescription',
            ]);
        } else {
            //Champs requis du statut Under Declaration
            required = required.concat([
                'name',
                'description',
                'modelType',
                'output',
                'modelOwner',
                'modelDeveloperTeam',
                'businessSponsor',
                'riskCategory',
                'microRiskCategory',
                'microRiskSubCategory',
                'championOrChallenger',
                'championModels',
                'inHouseOrVendor',
                'vendor',
                'dataSource',
                'modelNatureStandard',
            ]);
        }
    } else {
        if (userHasRoleMRM() && entity.modelStatus !== ParameterStore('MODEL_STATUS_ACTIVE')) {
            required = required.concat(['name', 'description', 'modelType', 'riskCategory']);
        } else {
            if (
                //Conditions principales
                entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT') ||
                //Conditions secondaires : Cumul des required pour les étapes suivantes
                entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') ||
                entity.expectedStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') ||
                entity.underDeclarationConversion === true ||
                entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE')
            ) {
                required = required.concat([
                    'name',
                    'description',
                    'modelOwner',
                    'modelDeveloperTeam',
                    'riskCategory',
                    'modelType',
                    'productionRun',
                ]);
                // Cas particulier, useDescription non requis si model cloné
                if (entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') && !entity.clonedFrom) {
                    required = required.concat(['useDescription']);
                }
            }
            if (
                //Conditions principales
                entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') ||
                entity.expectedStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') ||
                entity.underDeclarationConversion === true ||
                //Conditions secondaires : Cumul des required pour les étapes suivantes
                entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE')
            ) {
                required = required.concat([
                    'output',
                    'businessSponsor',
                    'riskCategory',
                    'microRiskCategory',
                    'microRiskSubCategory',
                    'applicationDomainLevel1',
                    'championOrChallenger',
                    'championModels',
                    'inHouseOrVendor',
                    'vendor',
                    'dataSource',
                    'modelNatureStandard',
                    'modelNatureDetailedString',
                    'modelOwnerTeams',
                    'modelOwnerEstablishment',
                    'firstImplementationDate',
                    'modelRiskAssessmentResults',
                    'validationStatus',
                    'criticalScoreDimension',
                    'averageModelRiskScore',
                ]);
            }
            if (
                entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE') ||
                entity.expectedStatus === ParameterStore('MODEL_STATUS_ACTIVE')
            ) {
                if (
                    !entity.coveringMitigationActionsEntities ||
                    entity.coveringMitigationActionsEntities.length === 0
                ) {
                    required = required.concat(['mitigationActions']);
                }
            }
            if (
                userHasRoleMRM() &&
                (entity.expectedStatus === ParameterStore('MODEL_STATUS_ACTIVE') ||
                    entity.inventoriedConversion === true)
            ) {
                required = required.concat(['modelValidatorTeams', 'validationFamily']);
            }
        }
    }

    if (required.includes(propertyName)) {
        return true;
    }
    return false;
};
/**
 * Ne pas oublier d'ajouter les champs non déclarés mais utilisés dans la fieldsSaveWhitelist.
 */
const validationByStatus = (entity, resource) => {
    if (userHasRoleMRM()) {
        return true;
    }

    if (entity.insertionFromImport === true) {
        return true;
    }

    let errors = [];
    let modelStatus = entity.modelStatus;
    modelStatus = modelStatus.split('/');
    let modelStatusId = modelStatus[modelStatus.length - 1];
    let modelStatusLabel = APIResourceStore.resources.parameters.getObservableItem(modelStatusId).label;

    let detailViewTab = null;

    if (userHasRoleMRM() && entity.modelStatus !== ParameterStore('MODEL_STATUS_ACTIVE')) {
    } else {
        if (
            entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE') ||
            entity.expectedStatus === ParameterStore('MODEL_STATUS_ACTIVE')
        ) {
            //Le model doit avoir minimum 1 modelUse associé, qui lui-même doit avoir un Use et un Detailed Use.
            if (entity.hasModelUses === false) {
                errors.push({
                    field: userHasRoleMRM() ? 'Status ' + modelStatusLabel : 'Uses',
                    detail: 'At least one Use is required at this stage.',
                }); //Note: Les champs Use et detailedUse sont obligatoire dans le formulaire.
                detailViewTab = detailViewTab === null ? 'Uses' : detailViewTab;
            }
            //Au moins une implémentation associée si le model est en production
            if (
                (
                    typeof entity.implementations === 'undefined'
                    || entity.implementations.length === 0
                )
                && entity.productionRun
            ) {
                errors.push({
                    field: userHasRoleMRM() ? 'Status ' + modelStatusLabel : 'Implementations',
                    detail: 'At least one Implementation is required at this stage.',
                });
                detailViewTab = detailViewTab === null ? 'Implementations' : detailViewTab;
            }
            //Au moins une RiskAndMitigationAction sauf si le flag noRiskAndMitigationAction == true
            if (
                !entity.mitigationActions &&
                (typeof entity.coveringMitigationActions === 'undefined' ||
                    entity.coveringMitigationActions.length === 0)
            ) {
                errors.push({
                    field: userHasRoleMRM() ? 'Status ' + modelStatusLabel : 'Mitigation Action',
                    detail: 'At least one Mitigation Action is required at this stage.',
                });
                detailViewTab = detailViewTab === null ? 'Mitigation actions' : detailViewTab;
            }
            //Les données de tiering doivent être remplies
            if (typeof entity.tierings === 'undefined' || entity.tierings.length === 0) {
                errors.push({
                    field: userHasRoleMRM() ? 'Status ' + modelStatusLabel : 'Tierings',
                    detail: 'At least one Tiering is required at this stage.',
                });
                detailViewTab = detailViewTab === null ? 'Tiering' : detailViewTab;
            }
        }
    }

    if (errors.length > 0) {
        if (detailViewTab !== null) {
            Navigation.router.history.push(`/resource/${resource.instanceId}/${entity.id}/detail?tab=${detailViewTab}`);
        }
        return errors;
    }
    return true;
};

export const needHighlightField = (entity, propertyName, queryParams) => {
    return needHighlightFieldCommon(entity, propertyName, queryParams, 'certification');
};

const header = (entity) => {
    let header = [];

    if (entity.nonModelStatus === ParameterStore('NON_MODEL_STATUS_NON_MODEL_AWAITING')) {
        header.push(<ModelStepProvider key={'model_step'} forceStep={0} />);
    } else {
        header.push(<ModelStepProvider key={'model_step'} entity={entity} />);
    }

    if (entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT') && userHasDeclarerRights(User.getId(), entity)) {
        if (!entity.expectedStatus) {
            header.push(
                <Header
                    key={'header_draft'}
                    text={
                        'As a second step, please fill in the required information before submitting the candidate to MRM for review.\n' +
                        'As long as it is not submitted, your draft is not visible by MRM. It can be saved at any time. '
                    }
                />
            );
        } else {
            header.push(
                <Header
                    key={'header_draft'}
                    text={
                        'Your model candidate is currently being reviewed by MRM, you can continue your declaration. Please add at least one use, one implementation, one mitigation action (or indicate that this is not applicable) and the tiering of the model.'
                    }
                />
            );
        }
    } else if (
        entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') &&
        !entity.expectedStatus &&
        userHasDeclarerRights(User.getId(), entity)
    ) {
        header.push(
            <Header
                text={
                    'In order to finalize the declaration, please declare at least one use, one implementation (if the model runs in production), one mitigation action (or indicate that it is not applicable) and the tiering of the model then submit to MRM by clicking on the green arrow button.'
                }
            />
        );
    }

    if (
        entity.clonedFrom &&
        // Etapes 4 et 5 du workflow : under declaration, avec expected status "ACTIVE" ou non
        entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') &&
        userHasRoleMRM()
    ) {
        let clonedFromModel = APIResourceStore.resources.models.getObservableItem(getIdFromIri(entity.clonedFrom));
        let modelDisplay = clonedFromModel.initialID || '...loading...';
        header.push(<Header key="duplication" text={`This declaration is a duplication of model ${modelDisplay}.`} />);
    }

    // En contexte de certification et de surlignage :
    if (new URLSearchParams(window.location.search)?.get('certification')) {
        header.push(
            <Header
                key="certification"
                text={
                    'Highlighted fields in ID card, Properties and Implementations tabs must be completed in order to certify this model.' +
                    'Please also complete the other fields to the best of your knowledge.'
                }
            />
        );
    }

    return header.length > 0 ? header : null;
};

let tierings = new APIResource({
    id: 'tierings',
    instanceId: 'model_admin_tierings',
    name: 'Tierings',
    icon: 'brain',
});

/**
 *
 * @param {Array<Object>} models
 * @returns {Object.<string, import('../Services/APIResource/APIResource').APIResourceField>}
 */
export const MODEL_FIELDS = (models) =>
    ({
        modelID: {
            title: 'ID',
            width: 50,
            helperText: 'Short model ID defined by MRM tool',
            params: {
                filterMulti: true,
            },
        },
        name: {
            title: 'Name',
            type: 'text',
            helperText: 'Full model name in accordance with the model documentation or the model directory',
            required: (entity) => requiredByStatus(entity, 'name'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'name', context),
            width: 400,
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        description: {
            title: 'Description',
            type: 'textarea',
            helperText: 'Please describe the model and its purpose in a few words',
            required: (entity) => requiredByStatus(entity, 'description'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'description', context),
            width: 400,
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        treeDeterminationAnswers: {
            title: 'Tree determination answers',
            type: 'text',
            required: (entity) => requiredByStatus(entity, 'treeDeterminationAnswers'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'treeDeterminationAnswers', context),
            displayList: () => false,
        },
        treeDeterminationResult: {
            title: 'Tree determination Result',
            type: 'text',
            required: (entity) => requiredByStatus(entity, 'treeDeterminationResult'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'treeDeterminationResult', context),
        },
        useDescription: {
            title: 'Use description',
            type: 'textarea',
            required: (entity) => requiredByStatus(entity, 'useDescription'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'useDescription', context),
            displayList: () => false,
        },
        insertionFromImport: {
            title: 'Imported',
            type: 'bool',
            displayList: () => false,
            displayConditions: (entity, entity2, key, context) =>
                userHasRoleMRM() && enableByStatus(entity, 'insertionFromImport', context),
        },
        initialID: {
            title: 'Initial ID',
            type: 'text',
            helperText: 'Model ID used before the MRM tool implementation',
            params: {
                filterMulti: true,
            },
            required: (entity) => requiredByStatus(entity, 'initialID'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'initialID', context),
        },
        functionalID: {
            title: 'Functional ID',
            type: 'text',
            helperText: 'Model ID defined by MRM tool',
            params: {
                filterMulti: true,
            },
            width: 400,
            required: (entity) => requiredByStatus(entity, 'functionalID'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'functionalID', context),
        },
        systemID: {
            title: 'System ID',
            type: 'text',
            helperText: 'Model ID given on IT systems',
            required: (entity) => requiredByStatus(entity, 'systemID'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'systemID', context),
            displayList: () => false,
        },
        ECBID: {
            title: 'ECB ID',
            type: 'text',
            helperText: 'Model ID defined by the ECB',
            required: (entity) => requiredByStatus(entity, 'ECBID'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'ECBID', context),
        },
        ECBAnnexID: {
            title: 'ECB Annex ID',
            type: 'text',
            helperText: 'Additional model ID defined by the ECB',
            required: (entity) => requiredByStatus(entity, 'ECBAnnexID'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'ECBAnnexID', context),
        },
        modelType: {
            title: 'Model type',
            type: 'parameter',
            params: { type: PARAMETER_TYPE_MODEL_TYPE, multi: false },
            helperText: 'Characterization of the model based on its function (ex: PD, Pricer, VaR…)',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'modelType'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'modelType', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        coreModel: {
            title: 'Core model',
            type: 'bool',
            helperText:
                'A core model is either: \n' +
                '- an independent model which can be used on a standalone basis \n' +
                '- an aggregated model which can be split into sub-models for development and validation purposes \n',
            required: (entity) => requiredByStatus(entity, 'coreModel'),
            displayConditions: (entity, entity2, key, context) =>
                hasOneGroupEntityNTX(entity) && enableByStatus(entity, 'coreModel', context),
            bulk: true,
        },
        coreModelParent: {
            title: 'Core model parent',
            type: 'model',
            helperText: 'Functional ID of the Core model parent',
            params: {
                resource: 'models',
                instanceId: 'allModels',
                displayField: 'toString',
                multi: false,
                links: true,
                linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                endpoints: {
                    getAll: 'models/all-models',
                },
            },
            required: (entity) => requiredByStatus(entity, 'coreModelParent'),
            displayConditions: (entity, entity2, key, context) =>
                hasOneGroupEntityNTX(entity) &&
                entity.coreModel === false &&
                enableByStatus(entity, 'coreModel', context),
        },
        clonedFrom: {
            title: 'Duplicated from',
            type: 'model',
            params: {
                resource: 'models',
                instanceId: 'allModels',
                displayField: 'toString',
                multi: false,
                links: true,
                linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                endpoints: {
                    getAll: 'models/all-models',
                },
            },
            doNotResetValueWhenNotDisplayed: true,
            required: (entity) => requiredByStatus(entity, 'clonedFrom'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'clonedFrom', context),
            displayList: () => false,
        },
        declarer: {
            title: 'Declarer',
            type: 'user',
            params: {
                resource: 'users',
                displayField: 'toString',
                editDisplayField: 'fullNameWithTeam',
                sortField: 'lastName',
                multi: false,
                links: false,
                endpoints: {
                    getAll: 'users/all-users/all',
                },
            },
            required: (entity) => requiredByStatus(entity, 'declarer'),
            //displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'declarer', context)
        },
        declarationDate: {
            title: 'Declaration date',
            type: 'date',
            required: (entity) => requiredByStatus(entity, 'declarationDate'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'declarationDate', context),
            edit: (field, value, onChange, entity, routeParams) => {
                if (entity.insertionFromImport === false) {
                    return DateProvider.getDisplay(field, value, entity, {});
                }
                return DateProvider.getEdit(field, value, onChange, entity, routeParams);
            },
        },
        modelOwner: {
            title: 'Model owner',
            type: 'user',
            params: {
                resource: 'users',
                instanceId: 'users_mo',
                displayField: 'toString',
                editDisplayField: 'fullNameWithTeam',
                sortField: 'lastName',
                multi: false,
                links: false,
                endpoints: {
                    getAll: 'users/all-users/mo',
                },
            },
            helperText:
                'The Model Owner is responsible for ensuring that the development, implementation and continued use of models are carried out in accordance with the principles set out in the MRM policy',
            bulk: true,
            display: (field, value, entity, props, resourceDetailComponent, context) => {
                if (context === 'edit' && userHasOwnershipRights(User.getId(), entity)) {
                    field.issueButton = {
                        tooltip: 'Request a model owner change',
                        title: `Model owner change request for model ${entity.functionalID}`,
                        description: 'New model owner: ',
                        type: ParameterStore('TROUBLE_TYPE_OWNERSHIP'),
                    };
                } else {
                    field.issueButton = false;
                }
                return EntityAsyncProvider.getDisplay(field, value, entity, props);
            },
            displayList: (field, value, entity, props) => {
                return EntityAsyncProvider.getDisplay(field, value, entity, props);
            },
            required: (entity) => requiredByStatus(entity, 'modelOwner'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'modelOwner', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        modelOwnerTeams: {
            title: 'Model owner team',
            type: 'entityTree',
            params: {
                resource: 'scopes',
                instanceId: 'scopes_mot',
                displayField: 'title',
                multi: true,
                links: false,
                childrenPropertyName: 'childScopes',
                endpoints: {
                    getAll: 'scopes/all-scopes/mot',
                },
            },
            helperText: 'Team of the model owner (based on group repository)',
            bulk: false,
            required: (entity) => requiredByStatus(entity, 'modelOwnerTeams'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'modelOwnerTeams', context),
        },
        /*{title: 'Model owner team / position', type: 'entity', params: {resource: 'scopes', displayField: 'title', multi: false, links: false}, bulk: false},*/
        modelOwnerEstablishment: {
            title: 'Model owner establishment',
            type: 'text',
            helperText: 'Entity of the model owner (based on group repository)',
            edit: () => null,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'modelOwnerEstablishment', context),
        },
        modelOwnerPosition: {
            title: 'Model owner position',
            type: 'text',
            helperText: 'Function of the model owner (based on group repository)',
            edit: () => null,
            displayConditions: (entity) => enableByStatus(entity, 'modelOwnerPosition'),
            displayList: () => false,
        },
        modelOwnerDelegation: {
            title: 'Delegated model owner',
            type: 'user',
            params: {
                resource: 'users',
                instanceId: 'users_dmo',
                displayField: 'toString',
                editDisplayField: 'fullNameWithTeam',
                sortField: 'lastName',
                multi: false,
                links: false,
                endpoints: {
                    getAll: 'users/all-users/dmo',
                },
            },
            helperText: 'Backup of the Model Owner in charge of the update of the inventory on a regular basis',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'modelOwnerDelegation'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'modelOwnerDelegation', context),
            edit: (field, value, onChange, entity, routeParams) => {
                if (entity.modelOwnerDelegation === `/api/users/${User.getId()}`) {
                    return EntityAsyncProvider.getDisplay(field, value, entity, {});
                }
                return EntityAsyncProvider.getEdit(field, value, onChange, entity, routeParams);
            },
        },
        modelOwnerDelegationAccepted: {
            title: 'Delegation acceptance',
            type: 'bool',
            helperText: 'Delegation was accepted.',
            required: (entity) => requiredByStatus(entity, 'modelOwnerDelegationAccepted'),
            displayList: () => false,
            displayConditions: (entity, entity2, key, context) =>
                entity.modelOwnerDelegation && enableByStatus(entity, 'modelOwnerDelegationAccepted', context),
        },
        modelOwnerDelegationDenyComment: {
            title: 'Justification of the delegation refusal',
            type: 'text',
            helperText: 'Justification of the delegation refusal',
            required: (entity) => requiredByStatus(entity, 'modelOwnerDelegationDenyComment'),
            displayList: () => false,
            displayCondition: (entity, entity2, key, context) =>
                entity.modelOwnerDelegation &&
                !entity.modelOwnerDelegationAccepted &&
                enableByStatus(entity, 'modelOwnerDelegationDenyComment', context),
        },

        modelDeveloperTeam: {
            title: 'Model developer team',
            type: 'entityTree',
            params: {
                multi: false,
                resource: 'scopes',
                instanceId: 'scopes_dev',
                displayField: 'title',
                childrenPropertyName: 'childScopes',
                endpoints: {
                    getAll: 'scopes/all-scopes/dev',
                },
            },
            helperText:
                'The Model Developer team leads all model development activities, including methodology and design',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'modelDeveloperTeam'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'modelDeveloperTeam', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        modelValidatorTeams: {
            title: 'Model validator teams',
            type: 'entityTree',
            params: {
                multi: true,
                resource: 'scopes',
                instanceId: 'scopes_val',
                displayField: 'title',
                childrenPropertyName: 'childScopes',
                endpoints: {
                    getAll: 'scopes/all-scopes/val',
                },
            },
            helperText:
                'The Model Validator team is responsible for independently verifying that the models proposed by the Model Owner are indeed suitable for their intended use and that the associated model risk is correctly identified, assessed and, if necessary, reduced',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'modelValidatorTeams'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'modelValidatorTeams', context),
        },
        validationFamily: {
            title: 'Models family',
            type: 'entity',
            params: {
                multi: false,
                resource: 'validation_families',
                instanceId: 'model_validation_families',
                displayField: 'name',
            },
            helperText: 'Group of models reviewed together by the validation team',
            required: (entity) => requiredByStatus(entity, 'validationFamily'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'validationFamily', context),
        },
        mrmTeamsOverride: {
            title: 'MRM Teams',
            helperText:
                "The MRM function ensures that the inventory of the Group's models is exhaustive and up-to-date\n" +
                'Oversees the good application of the procedures and policy',
            type: 'entity',
            params: {
                resource: 'scopes',
                instanceId: 'scopes_mrm',
                displayField: 'title',
                multi: true,
                endpoints: {
                    getAll: 'scopes/mrm',
                },
            },
            bulk: true,
            displayList: () => null,
            display: (field, value, entity, props) => {
                return EntityAsyncProvider.getDisplay(
                    {
                        title: 'MRM Teams',
                        type: 'entityAsync',
                        params: {
                            resource: 'scopes',
                            instanceId: 'scopes_mrm',
                            displayField: 'title',
                            multi: true,
                            endpoints: {
                                getAll: 'scopes/all-scopes/mrm',
                            },
                        },
                    },
                    entity.mrmTeams,
                    entity,
                    props
                );
            },
            required: (entity) => requiredByStatus(entity, 'mrmTeamsOverride'),
            token: false,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'mrmTeamsOverride', context),
        },
        mrmTeams: {
            title: 'MRM Teams',
            type: 'entityAsync',
            params: {
                resource: 'scopes',
                instanceId: 'scopes_mrm',
                displayField: 'title',
                multi: true,
                endpoints: {
                    getAll: 'scopes/all-scopes/mrm',
                },
            },
            edit: () => null,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'mrmTeams', context),
            helperText:
                "The MRM function ensures that the inventory of the Group's models is exhaustive and up-to-date\n" +
                'Oversees the good application of the procedures and policy',
        },
        businessSponsor: {
            title: 'Business Sponsor',
            type: 'user',
            params: {
                resource: 'users',
                instanceId: 'users_bs',
                displayField: 'toString',
                editDisplayField: 'fullNameWithTeam',
                sortField: 'lastName',
                multi: false,
                links: false,
                endpoints: {
                    getAll: 'users/all-users/bs',
                },
            },
            helperText:
                'The Business Sponsor is a senior member of the business who has an overview of the use of the model',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'businessSponsor'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'businessSponsor', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        dataContactPointTeam: {
            title: 'Data contact point',
            helperText:
                'The Data Contact Point team is in charge of managing databases supplying model inputs\n' +
                'Responsible for the integrity and quality of data delivered to the model ',
            type: 'entityTree',
            params: {
                multi: false,
                resource: 'scopes',
                instanceId: 'scopes_dcp',
                displayField: 'title',
                childrenPropertyName: 'childScopes',
                endpoints: {
                    getAll: 'scopes/all-scopes/dcp',
                },
            },
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'dataContactPointTeam'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'dataContactPointTeam', context),
        },
        riskCategory: {
            title: 'Risk Category',
            type: 'parameter',
            params: { type: PARAMETER_TYPE_RISK_CATEGORY, multi: false },
            helperText: '1st risk category level' + '\nDefined following the internal risk taxonomy of the BPCE group',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'riskCategory'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'riskCategory', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        microRiskCategory: {
            title: 'Micro-Risk Category',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MICRO_RISK_CATEGORY,
                multi: false,
            },
            helperText: '2nd risk category level' + '\nDefined following the internal risk taxonomy of the BPCE group',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'microRiskCategory'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'microRiskCategory', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        microRiskSubCategory: {
            title: 'Micro-Risk Sub-Category',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MICRO_RISK_SUB_CATEGORY,
                multi: false,
            },
            helperText: '3rd risk category level' + '\nDefined following the internal risk taxonomy of the BPCE group',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'microRiskSubCategory'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'microRiskSubCategory', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        applicationDomainLevel1: {
            title: 'Scope of application - Level 1',
            type: 'parameter',
            params: { type: PARAMETER_TYPE_ADL1, multi: false },
            helperText: '1st population segmentation level of the inital perimeter',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'applicationDomainLevel1'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'applicationDomainLevel1', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        applicationDomainLevel2: {
            title: 'Scope of application - Level 2',
            type: 'parameter',
            params: { type: PARAMETER_TYPE_ADL2, multi: false },
            helperText: '2nd population segmentation level of the inital perimeter',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'applicationDomainLevel2'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'applicationDomainLevel2', context),
        },
        applicationDomainLevel3: {
            title: 'Scope of application - Level 3',
            type: 'parameter',
            params: { type: PARAMETER_TYPE_ADL3, multi: false },
            helperText: '3rd population segmentation level of the inital perimeter',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'applicationDomainLevel3'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'applicationDomainLevel3', context),
        },
        championOrChallenger: {
            title: 'Champion or challenger',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_CHAMPION_OR_CHALLENGER,
                multi: false,
            },
            helperText:
                'A champion model is a model developed and used for decision making purpose' +
                '\nA challenger model is a model built to provide another set of outcomes as a benchmark for a champion model',
            bulk: true,
            issueButton: false,
            required: (entity) => requiredByStatus(entity, 'championOrChallenger'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'championOrChallenger', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        championModels: {
            title: 'Associated Champion Model',
            type: 'model',
            params: {
                resource: 'models',
                instanceId: 'allModels',
                displayField: 'toString',
                multi: false,
                links: true,
                linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                endpoints: {
                    getAll: 'models/all-models',
                },
            },
            helperText: 'Champion model challenged by the current model',
            required: (entity) =>
                entity.championOrChallenger &&
                entity.championOrChallenger === ParameterStore('CHAMPION_OR_CHALLENGER_CHALLENGER') &&
                requiredByStatus(entity, 'championModels'),
            displayConditions: (entity, entity2, key, context) =>
                entity.championOrChallenger &&
                entity.championOrChallenger === ParameterStore('CHAMPION_OR_CHALLENGER_CHALLENGER') &&
                enableByStatus(entity, 'championModels', context),
        },
        inHouseOrVendor: {
            title: 'In House or Vendor',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_IN_HOUSE_OR_VENDOR,
                multi: false,
            },
            helperText: 'Was the model designed internally (In House) or by a third party (Vendor) ?',
            bulk: true,
            issueButton: false,
            required: (entity) => requiredByStatus(entity, 'inHouseOrVendor'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'inHouseOrVendor', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        vendor: {
            title: 'Vendor',
            type: 'text',
            helperText: 'Vendor name',
            displayConditions: (entity, entity2, key, context) =>
                entity.inHouseOrVendor === ParameterStore('VENDOR') && enableByStatus(entity, 'vendor', context),
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'vendor'),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        modelNatureStandard: {
            title: 'Model Nature Standard',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MODEL_NATURE_STANDARD,
                multi: false,
            },
            helperText:
                'Quantitative: based on historical observations and statistical or mathematical assumptions or other data use techniques (purely statistical/mathematical or AI)\n' +
                'Expert: based on the judgments and assumptions made by one or more experts\n' +
                'Hybrid: consisting of a combination of the first two points',
            bulk: true,
            issueButton: false,
            required: (entity) => requiredByStatus(entity, 'modelNatureStandard'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'modelNatureStandard', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        modelNatureDetailedString: {
            title: 'Model Nature Detailed',
            type: 'text',
            helperText:
                'Main method used' +
                '\nFor pricing functions used in valuation, the main pricing model must be filled in this field.',
            required: (entity) => requiredByStatus(entity, 'modelNatureDetailedString'),
            displayConditions: (entity, entity2, key, context) =>
                entity.modelNatureStandard &&
                (entity.modelNatureStandard === ParameterStore('MODEL_NATURE_STANDARD_QUANTITATIVE') ||
                    entity.modelNatureStandard === ParameterStore('MODEL_NATURE_STANDARD_HYBRID')) &&
                enableByStatus(entity, 'modelNatureDetailedString', context),
            bulk: true,
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        modelObservability: {
            title: 'Model observability',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MODEL_OBSERVABILITY,
                multi: false,
            },
            helperText: 'Describe the observability of the model',
            required: (entity) => requiredByStatus(entity, 'modelObservability'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'modelObservability', context) && hasOneGroupEntityNTX(entity),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
            bulk: true,
        },
        downStreamNotAvailable: {
            title: '',
            type: 'mapped',
            params: {
                mapping: {
                    false: '',
                    true: 'Downstream models are not available',
                },
            },
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'downStreamNotAvailable'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'downStreamNotAvailable', context),
            display: (field, value, entity) => {
                return value ? <label>No downstream model</label> : null;
            },
            displayList: () => null,
            edit: (field, value, onChange, entity, routeParams, operation, bulkEntities = [], loading) => {
                let authorizeBulkEdit = true;
                if(bulkEntities.length) {
                    bulkEntities.forEach((bulkEntity) => {
                        if (
                            Array.isArray(bulkEntity.downstreamModels) &&
                            bulkEntity.downstreamModels.length > 0
                        ) {
                            authorizeBulkEdit = false;
                        }
                    });
                }
                return (
                    <FormControlLabel
                        style={{ marginLeft: 10 }}
                        checked={value}
                        key={'form-control-label-' + Date.now()}
                        control={
                            <Checkbox
                                checked={value}
                                disabled={loading || entity?.downstreamModels?.length > 0 || authorizeBulkEdit === false}
                                onChange={(event, state) => {
                                    onChange(state);
                                }}
                            />
                        }
                        label={<span>No downstream model</span>}
                    />
                );
            },
        },
        upStreamNotAvailable: {
            title: '',
            type: 'mapped',
            params: {
                mapping: {
                    false: '',
                    true: 'Upstream models are not available',
                },
            },
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'upStreamNotAvailable'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'upStreamNotAvailable', context),
            display: (field, value, entity) => {
                return value ? <label>No upstream model</label> : null;
            },
            displayList: () => null,
            edit: (field, value, onChange, entity, routeParams, operation, bulkEntities = [], loading) => {
                let authorizeBulkEdit = true;
                if(bulkEntities.length) {
                    bulkEntities.forEach((bulkEntity) => {
                        if (
                            Array.isArray(bulkEntity.upstreamModels) &&
                            bulkEntity.upstreamModels.length > 0
                        ) {
                            authorizeBulkEdit = false;
                        }
                    });
                }
                return (
                    <FormControlLabel
                        style={{ marginLeft: 10 }}
                        checked={value}
                        key={'form-control-label-' + Date.now()}
                        control={
                            <Checkbox
                                checked={value}
                                disabled={loading || entity?.upstreamModels?.length > 0 || authorizeBulkEdit === false}
                                onChange={(event, state) => {
                                    onChange(state);
                                }}
                            />
                        }
                        label={<span>No upstream model</span>}
                    />
                );
            },
        },
        upstreamModels: {
            title: 'Upstream Models',
            type: 'model',
            params: {
                resource: 'models',
                instanceId: 'allModels',
                displayField: 'toString',
                multi: true,
                links: true,
                linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                endpoints: {
                    getAll: 'models/all-models',
                },
            },
            helperText: 'Indicate here the models which outputs are used as input data for the current model',
            required: (entity) => requiredByStatus(entity, 'upstreamModels'),
            displayConditions: (entity, entity2, key, context) =>
                !entity.upStreamNotAvailable && enableByStatus(entity, 'upstreamModels', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
            displayList: (field, value, entity, props) => {
                if (entity && !entity.upStreamNotAvailable) {
                    return ModelProvider.getDisplay(field, value, entity, props);
                }
                return TextProvider.getDisplayList(field, 'No upstream model', entity, props);
            },
        },
        downstreamModels: {
            title: 'Downstream Models',
            type: 'model',
            params: {
                resource: 'models',
                instanceId: 'allModels',
                displayField: 'toString',
                multi: true,
                links: true,
                linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                endpoints: {
                    getAll: 'models/all-models',
                },
            },
            helperText: 'Indicate here the models which use the current model outputs as input data',
            required: (entity) => requiredByStatus(entity, 'downstreamModels'),
            displayConditions: (entity, entity2, key, context) =>
                !entity.downStreamNotAvailable && enableByStatus(entity, 'downstreamModels', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
            displayList: (field, value, entity, props) => {
                if (entity && !entity.downStreamNotAvailable) {
                    return ModelProvider.getDisplay(field, value, entity, props);
                }
                return TextProvider.getDisplayList(field, 'No downstream model', entity, props);
            },
        },
        lastValidationType: {
            title: 'Last validation type',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_REVIEW_TYPE,
                multi: false,
            },
            helperText: 'Last type of review undergone by the model',
            required: (entity) => requiredByStatus(entity, 'lastValidationType'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'lastValidationType', context),
        },
        lastFullValidationDate: {
            title: 'Last full validation date',
            type: 'date',
            helperText: 'Last initial validation, full revalidation or change validation (committee date)',
            required: (entity) => requiredByStatus(entity, 'lastFullValidationDate'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'lastFullValidationDate', context),
        },
        lastPeriodicValidationDate: {
            title: 'Last periodic review date',
            type: 'date',
            helperText: 'Last periodic review date (committee date)',
            required: (entity) => requiredByStatus(entity, 'lastPeriodicValidationDate'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'lastPeriodicValidationDate', context),
        },
        lastMinorEvolutionReviewDate: {
            title: 'Last minor evolution review date',
            type: 'date',
            helperText: 'Last minor evolution review date (committee date)',
            required: (entity) => requiredByStatus(entity, 'lastMinorEvolutionReviewDate'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'lastMinorEvolutionReviewDate', context),
        },
        validationStatus: {
            title: 'Validation Status',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_REVIEW_RESULT,
            },
            helperText: 'Committee decision on the last  review undergone by the model',
            required: (entity) => requiredByStatus(entity, 'validationStatus'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'validationStatus', context),
        },
        validationStatusRationale: {
            title: 'Validation Status Rationale',
            type: 'text',
            helperText: 'Comments from the committee to justify the validation status',
            required: (entity) => requiredByStatus(entity, 'validationStatusRationale'),
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'validationStatusRationale', context),
        },
        nextScheduledValidationDate: {
            title: 'Next scheduled validation date',
            type: 'date',
            required: (entity) => requiredByStatus(entity, 'nextScheduledValidationDate'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'nextScheduledValidationDate', context),
            helperText: (entity) => {
                let helper = [];
                helper.push({
                    text: 'Next validation date, as scheduled by Validation or MRM team',
                });
                if (entity.nextScheduledValidationDateVerified === false) {
                    helper.push({
                        type: 'warning',
                        icon: 'fa-exclamation-triangle',
                        text: 'Unverified',
                    });
                }
                return helper;
            },
            edit: (field, value, onChange, entity, routeParams) => {
                return field.display(field, value, entity, {});
            },
            display: (field, value, entity, props) => {
                return (
                    <DisplayTextField
                        {...props}
                        fieldName={field.title}
                        value={
                            value
                                ? entity.nextScheduledValidationDateVerified === false
                                    ? entity.nextValidationDateVerificationComment ?? DateFormatter.getYear(value)
                                    : DateFormatter.dayIsoToString(value)
                                : null
                        }
                    />
                );
            },
        },
        nextScheduledValidationType: {
            title: 'Next scheduled validation type',
            type: 'parameter',
            helperText: 'Type of the next scheduled validation',
            params: {
                type: PARAMETER_TYPE_REVIEW_TYPE,
                multi: false,
            },
            required: (entity) => requiredByStatus(entity, 'nextScheduledValidationType'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'nextScheduledValidationType', context),
            edit: (field, value, onChange, entity, routeParams) => {
                return ParameterProvider.getDisplay(field, value, entity);
            },
        },
        nextValidationDateVerificationComment: {
            title: 'Next validation date (Scheduled - Year)',
            type: 'text',
            required: (entity) => requiredByStatus(entity, 'nextValidationDateVerificationComment'),
            displayCondition: (entity, entity2, key, context) => false,
            displayList: () => null,
            edit: (field, value) => <DisplayTextField fieldName={field.title} value={value} />,
            //enableByStatus(entity, 'nextValidationDateVerificationComment', context),
        },
        nextFullValidationDateProcedure: {
            title: 'Next full validation date (Procedure)',
            type: 'date',
            helperText:
                'Next theoretical full validation depending on the tiering of the model\n' +
                'The validation frequency is defined by MRM validation procedure',
            required: (entity) => requiredByStatus(entity, 'nextFullValidationDateProcedure'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'nextFullValidationDateProcedure', context),
            edit: (field, value) => DateProvider.getDisplay(field, value),
        },
        nextPeriodicValidationDateProcedure: {
            title: 'Next periodic review date (Procedure)',
            type: 'date',
            helperText:
                'Next theoretical periodic review depending on the tiering of the model\n' +
                'The validation frequency is defined by MRM validation procedure',
            required: (entity) => requiredByStatus(entity, 'nextPeriodicValidationDateProcedure'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'nextPeriodicValidationDateProcedure', context),
            edit: (field, value) => DateProvider.getDisplay(field, value),
        },
        firstImplementationDate: {
            title: 'First Implementation date',
            type: 'date',
            helperText: 'Model implementation date in BPCE IT Systems',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'firstImplementationDate'),
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'firstImplementationDate', context),
        },
        dataSource: {
            title: 'Data sources',
            type: 'text',
            helperText: 'Description of the data sources used by the model',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'dataSource'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'dataSource', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        backgroundModelRelations: {
            title: 'Background Models',
            type: 'entity',
            params: {
                resource: 'background_model_relations',
                displayField: 'toString',
                multi: true,
                links: true,
                linkPath: (entity) => {
                    let entityPath = entity.backgroundModel;
                    if (!entityPath) return null;
                    entityPath = entityPath.split('/');
                    let modelId = entityPath[entityPath.length - 1];
                    return '/resource/models/' + modelId + '/detail';
                },
                tooltip: (entity) => entity.backgroundModelName,
            },
            helperText:
                'Models which have been replaced on a perimeter by the current model, or which have been used as the basis of development for the current model',
            edit: (field, value, onChange, entity, routeParams) => (
                <BackgroundModel field={field} entity={entity} value={value} onChange={onChange} />
            ),
            required: (entity) => requiredByStatus(entity, 'backgroundModelRelations'),
            displayList: () => false,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'backgroundModelRelations', context),
        },
        foregroundModelRelations: {
            title: 'Offspring Models',
            type: 'entity',
            params: {
                resource: 'background_model_relations',
                displayField: 'toStringForeground',
                multi: true,
                links: true,
                linkPath: (entity) => {
                    let entityPath = entity.foregroundModel;
                    if (!entityPath) return null;
                    entityPath = entityPath.split('/');
                    let modelId = entityPath[entityPath.length - 1];
                    return '/resource/models/' + modelId + '/detail';
                },
                tooltip: (entity) => entity.foregroundModelName,
            },
            helperText: 'List of models created from the current model, or created to replace the current model',
            required: (entity) => requiredByStatus(entity, 'foregroundModelRelations'),
            displayList: () => false,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'foregroundModelRelations', context),
        },
        certifierName: {
            title: 'Last certifier name',
            type: 'text',
            helperText: 'Name of the last certifier',
            required: (entity) => requiredByStatus(entity, 'certifierName'),
            edit: (field, value) => <DisplayTextField fieldName={field.title} value={value} />,
            /** On force le droit d'un des champs en front (même si les droits sont interdits en back)
             * pour imposer l'affichage de l'onglet "Certifications", lors du mode edition.
             */
            rights: { edit: true },
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'certifierName', context),
        },
        certifierTeam: {
            title: 'Last certifier team',
            type: 'text',
            helperText: 'Team of the last certifier (based on group repository)',
            required: (entity) => requiredByStatus(entity, 'certifierTeam'),
            edit: (field, value) => <DisplayTextField fieldName={field.title} value={value} />,
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'certifierTeam', context),
        },
        certifierPosition: {
            title: 'Last certifier position',
            type: 'text',
            helperText: 'Position of the last certifier (based on group repository)',
            required: (entity) => requiredByStatus(entity, 'certifierPosition'),
            edit: (field, value) => <DisplayTextField fieldName={field.title} value={value} />,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'certifierPosition', context),
        },
        lastCertificationResult: {
            title: 'Certification result',
            helperText: 'Result of the last certification',
            type: 'mapped',
            params: {
                mapping: {
                    Certified: 'Certified',
                    'Not certified': 'Not certified',
                    'New model': 'New model',
                },
            },
            required: (entity) => requiredByStatus(entity, 'lastCertificationResult'),
            edit: (field, value) => <DisplayTextField fieldName={field.title} value={value} />,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'lastCertificationResult', context),
        },
        lastCertificationDate: {
            title: 'Last certification date',
            type: 'date',
            helperText: 'Date of the last certifier attestation of the model data',
            edit: (field, value) => DateProvider.getDisplay(field, value),
            required: (entity) => requiredByStatus(entity, 'lastCertificationDate'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'lastCertificationDate', context),
        },
        inherentModelRiskScore: {
            title: 'Inherent Model Risk Score',
            type: 'text',
            helperText: 'Model Risk Score without taking in account mitigations actions',
            required: (entity) => requiredByStatus(entity, 'inherentModelRiskScore'),
            displayConditions: (entity, entity2, key, context) => {
                return enableByStatus(entity, 'inherentModelRiskScore', context) && hasGroupEntityBPCE(entity);
            },
        },
        modelRiskAssessmentResults: {
            title: 'Model Risk Assessment Result',
            type: 'text',
            helperText: 'LoD 2 conclusion at the end of the Model Risk Assessment process',
            required: (entity) => requiredByStatus(entity, 'modelRiskAssessmentResults'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'modelRiskAssessmentResults', context),
        },
        criticalScoreDimension: {
            title: 'Critical Dimensions',
            type: 'text',
            helperText: 'Dimension(s) with the highest score obtained at the end of the Model Risk Assessment process',
            required: (entity) => requiredByStatus(entity, 'criticalScoreDimension'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'criticalScoreDimension', context),
        },
        averageModelRiskScore: {
            title: 'Model Risk Score',
            type: 'text',
            helperText: 'Last MRA score (maximum between the average score and the key dimensions maximum)',
            required: (entity) => requiredByStatus(entity, 'averageModelRiskScore'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'averageModelRiskScore', context),
        },
        operationalRiskFlag: {
            title: 'Operational risk flag',
            type: 'bool',
            helperText: 'Is the model covered by risk scenarios from the operational risk team ?',
            required: (entity) => requiredByStatus(entity, 'operationalRiskFlag'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'operationalRiskFlag', context),
            bulk: true,
        },
        automaticScoreFlag: {
            title: 'Automatic Score flag',
            type: 'bool',
            helperText:
                'Does the model deliver an automatic score for credit applications, without any human intervention ?',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'automaticScoreFlag'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'automaticScoreFlag', context) &&
                (entity.modelType === ParameterStore('MODEL_TYPE_PD') ||
                    entity.modelType === ParameterStore('MODEL_TYPE_CREDIT_GRANTING')),
        },
        defaultPortfolioFlag: {
            title: 'Low-Default Portfolio Flag',
            type: 'bool',
            helperText: 'Does the model carry a low-default portfolio risk (LDP) for IRB models ?',
            required: (entity) => requiredByStatus(entity, 'defaultPortfolioFlag'),
            displayCondition: (entity, entity2, key, context) =>
                entity.microRiskCategory === ParameterStore('MICRO_RISK_CATEGORY_DEFAULT_RISK') &&
                enableByStatus(entity, 'defaultPortfolioFlag', context),
            bulk: true,
        },
        productionRun: {
            title: 'Production run',
            type: 'bool',
            helperText: 'Has the model been launched in production ?',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'productionRun'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'productionRun', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        modelStatus: {
            title: 'Inventory status',
            type: 'parameter',
            params: { type: PARAMETER_TYPE_MODEL_STATUS, multi: false },
            helperText: 'Status of the model on the MRM lifecycle (under declaration, inventoried, retired…)',
            display: (field, value, entity, props) => {
                if(!entity.id){
                    return ParameterProvider.getDisplay(field, entity.modelStatus, entity, props);
                }
                return TextProvider.getDisplay(field, entity.modelStatusDisplay, entity, props);
            },
            displayList: (field, value, entity, props, resource) => {
                if (resource.instanceId === 'non_models') {
                    return null;
                }
                return TextProvider.getDisplayList(field, entity ? entity.modelStatusDisplay : null, entity, props);
            },
            edit: (field, value, onChange, entity, routeParams, operation, bulkEntities = [], loading = false) => {
                return userHasRoleMRM() && !entity.expectedStatus ? (
                    <ParameterSelect
                        field={field}
                        value={value}
                        onChange={onChange}
                        issueButton={'issueButton' in field ? field.issueButton : true}
                        entity={entity}
                        clearable={true}
                        multi={false}
                        disabled={ loading }
                    />
                ) : (
                    TextProvider.getDisplay(field, entity.modelStatusDisplay, entity, {})
                );
                //entity.modelStatus = (routeParams.modelId) ? modelStatusRetired : value;
            },
            required: (entity) => requiredByStatus(entity, 'modelStatus'),
            displayCondition: (entity, entity2, key, context) =>
                !(entity.nonModelStatus && !entity.deleted) && enableByStatus(entity, 'modelStatus', context),
        },
        nonModelStatus: {
            title: 'Non-model status',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_NON_MODEL_STATUS,
                multi: false,
            },
            displayCondition: (entity) => !entity.deleted && entity.nonModelStatus,
            displayList: (field, value, entity, props, resource) => {
                if (resource.instanceId !== 'non_models') {
                    return null;
                }
                return ParameterProvider.getDisplayList(field, value, entity, props);
            },
        },
        underDeclarationConversion: {
            title: 'Under declaration conversion',
            type: 'bool',
            edit: () => null,
            display: () => null,
        },
        inventoriedConversion: {
            title: 'Inventoried conversion',
            type: 'bool',
            edit: () => null,
            display: () => null,
        },
        modelStatusDeletionDate: {
            title: 'Deletion date',
            type: 'date',
            edit: () => null,
            displayList: (field, value, entity, props, resource) => {
                if(resource.instanceId !== 'deleted_models'){
                    return null;
                }
                return DateProvider.getDisplayList(field, value, entity, props, resource)
            },
            displayConditions: (entity, entity2, key, context) =>
                entity.modelStatus === ParameterStore('MODEL_STATUS_DELETED') &&
                enableByStatus(entity, 'modelStatusDeletionDate', context),
        },
        modelStatusRetirementDate: {
            title: 'Retirement date',
            type: 'date',
            edit: () => null,
            displayList: (field, value, entity, props, resource) => {
                if(resource.instanceId !== 'retired_models'){
                    return null;
                }
                return DateProvider.getDisplayList(field, value, entity, props, resource)
            },
            displayConditions: (entity, entity2, key, context) =>
                entity.modelStatus === ParameterStore('MODEL_STATUS_RETIRED') &&
                enableByStatus(entity, 'modelStatusRetirementDate', context),
        },
        expectedStatus: {
            title: 'Expected status',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MODEL_STATUS,
                multi: false,
                filters: (parameter) => {
                    if (
                        parameter['@id'] === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') ||
                        parameter['@id'] === ParameterStore('MODEL_STATUS_ACTIVE')
                    ) {
                        return parameter;
                    }
                    return null;
                },
            },
            edit: (field, value) => {
                return (
                    <EntityDisplay
                        resourceId="parameters"
                        resourceLabel="label"
                        label={field.title}
                        value={value}
                        links={false}
                    />
                );
            },
            displayList: () => null,
            required: (entity) => requiredByStatus(entity, 'expectedStatus'),
            displayConditions: (entity, entity2, key, context) =>
                entity.expectedStatus && enableByStatus(entity, 'expectedStatus', context),
        },
        output: {
            title: 'Output',
            type: 'text',
            helperText:
                'Describe output metrics from the items (e.g. fair value of an option, default probability of a loan…)',
            required: (entity) => hasOneGroupEntityNTX(entity) && requiredByStatus(entity, 'output'),
            displayConditions: (entity, entity2, key, context) => {
                return hasOneGroupEntityNTX(entity) && enableByStatus(entity, 'output', context);
            },
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
            bulk: true,
        },
        referenceDocumentation: {
            title: 'Reference documentation',
            type: 'text',
            required: (entity) => requiredByStatus(entity, 'referenceDocumentation'),
            displayConditions: (entity, entity2, key, context) =>
                hasOneGroupEntityNTX(entity) && enableByStatus(entity, 'referenceDocumentation', context),
        },
        validatorsLocation: {
            title: 'Validators location',
            type: 'parameter',
            helperText: 'Location of the model validation team',
            params: {
                type: PARAMETER_TYPE_MODEL_VALIDATOR_LOCATION,
                multi: false,
            },
            required: (entity) => requiredByStatus(entity, 'validatorsLocation'),
            displayConditions: (entity, entity2, key, context) =>
                hasOneGroupEntityNTX(entity) && enableByStatus(entity, 'validatorsLocation', context),
            bulk: true,
        },
        mitigationActions: {
            title: 'Mitigation actions',
            type: 'bool',
            params: {
                mapping: mitigationActionsMap,
            },
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'mitigationActions'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'mitigationActions', context),
            display: (field, value, entity) => {
                if (
                    !(
                        Array.isArray(entity.coveringMitigationActionsEntities) &&
                        entity.coveringMitigationActionsEntities.length > 0
                    )
                ) {
                    return (
                        <FormControlLabel
                            style={{ marginLeft: 10 }}
                            control={
                                <Checkbox
                                    checked={value === undefined ? false : value /** composant contrôlé => checked doit pas être undefined */}
                                    disabled={userHasNoRole(User, entity)}
                                    onChange={(event, state) => {
                                        entity.mitigationActions = state;
                                        models.apiPut(entity).then();
                                    }}
                                />
                            }
                            label={<span>No mitigation action is applicable to this model.</span>}
                        />
                    );
                }
                return BoolProvider.getDisplay(field, value, entity);
            },
            displayList: (field, value, _entity, _props) => {
                if (value === 'true' || value === true) {
                    return field.params.mapping['true'];
                }
                return field.params.mapping['false'];
            },
            edit: (field, value, onChange, entity, routeParams, operation, bulkEntities = [], loading) => {
                if(bulkEntities.length){
                    let authorizeBulkEdit = true;
                    bulkEntities.forEach((bulkEntity) => {
                        if (
                            Array.isArray(bulkEntity.coveringMitigationActions) &&
                            bulkEntity.coveringMitigationActions.length > 0
                        ) {
                            authorizeBulkEdit = false;
                        }
                    });
                    return authorizeBulkEdit ? <FormControlLabel
                            style={{ marginLeft: 10 }}
                            checked={value}
                            key={'form-control-label-' + Date.now()}
                            control={
                                <Checkbox
                                    checked={value === undefined ? false : value}
                                    disabled={loading}
                                    onChange={(event, state) => {
                                        onChange(state);
                                    }}
                                />
                            }
                            label={<span>No mitigation action is applicable to this model.</span>}
                        />
                    : undefined;
                }

                if (
                    Array.isArray(entity.coveringMitigationActionsEntities) &&
                    entity.coveringMitigationActionsEntities.length > 0
                ) {
                    return BoolProvider.getDisplay(field, value, entity);
                }
                return (
                    <FormControlLabel
                        style={{ marginLeft: 10 }}
                        checked={value}
                        key={'form-control-label-' + Date.now()}
                        control={
                            <Checkbox
                                checked={value === undefined ? false : value}
                                onChange={(event, state) => {
                                    onChange(state);
                                }}
                            />
                        }
                        label={<span>No mitigation action is applicable to this model.</span>}
                    />
                );
            },
        },
        modelComplexity: {
            title: 'Model complexity',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_COMPLEXITY_LEVEL,
                multi: false,
            },
            helperText:
                'The value of the field depends on the model complexity:' +
                '\nThree levels are identified: « High », « Medium », « Low »',
            required: (entity) => requiredByStatus(entity, 'modelComplexity'),
            edit: () => null,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'modelComplexity', context),
        },
        certificationsEntities: {
            title: 'Certifications',
            display: (field, value, entity, props) => {
                const activeCertificationCampaigns =
                    entity.certificationCampaignsEntities &&
                    entity.certificationCampaignsEntities.filter((cc) => cc.active);

                const hasActiveCertificationCampaign = !!(
                    activeCertificationCampaigns && activeCertificationCampaigns.length
                );

                const currentActiveCertificationCampaign =
                    hasActiveCertificationCampaign && activeCertificationCampaigns[0];

                const alreadyCertified =
                    value &&
                    value.filter((v) => v.modelCertificationCampaignId === currentActiveCertificationCampaign.id)
                        .length > 0;

                const alreadyHasTrouble =
                    entity &&
                    entity.issuesEntities &&
                    entity.issuesEntities.filter(
                        (v) => v.modelCertificationCampaignId === currentActiveCertificationCampaign.id
                    ).length > 0;
                const certificationIssuesEntities =
                    entity && entity.issuesEntities
                        ? entity.issuesEntities.filter(
                              (issue) => issue.type === ParameterStore('TROUBLE_TYPE_CERTIFICATION')
                          )
                        : [];
                return (
                    <div>
                        <TableDisplay
                            dense={true}
                            rows={value}
                            cols={[
                                { label: 'Date', field: 'dateString' },
                                { label: 'Certifier', field: 'user' },
                                { label: 'Certifier team', field: 'userTeam' },
                                { label: 'Certifier position', field: 'userPosition' },
                                { label: 'Note', field: 'note' },
                            ]}
                            buttons={
                                (userHasOwnershipRights(User.getId(), entity) || userHasRoleMRM()) &&
                                hasActiveCertificationCampaign &&
                                entity.allRequirementsCheckedToCertify
                                    ? // &&
                                      //  !alreadyCertified &&
                                      //  !alreadyHasTrouble
                                      [
                                          {
                                              label: 'New certification',
                                              icon: 'fa-plus',
                                              disabled: alreadyHasTrouble,
                                              onClick: () =>
                                                  Modal.open({
                                                      title: 'New certification',
                                                      content: (
                                                          <AddCertificationForm
                                                              ids={[entity.id]}
                                                              modelName={entity.functionalID}
                                                              modelCertificationCampaign={
                                                                  currentActiveCertificationCampaign.id
                                                              }
                                                          />
                                                      ),
                                                  }),
                                          },
                                      ]
                                    : []
                            }
                        />
                    </div>
                );
            },
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'certificationsEntities', context),
        },
        certificationsIssuesEntities: {
            title: 'Certifications Troubles',
            edit: () => null,
            display: (field, value, entity, props) => {
                const activeCertificationCampaigns =
                    entity.certificationCampaignsEntities &&
                    entity.certificationCampaignsEntities.filter((cc) => cc.active);

                const hasActiveCertificationCampaign = !!(
                    activeCertificationCampaigns && activeCertificationCampaigns.length
                );

                const currentActiveCertificationCampaign =
                    hasActiveCertificationCampaign && activeCertificationCampaigns[0];

                const alreadyCertified =
                    value &&
                    value.filter((v) => v.modelCertificationCampaignId === currentActiveCertificationCampaign.id)
                        .length > 0;

                const alreadyHasTrouble =
                    entity &&
                    entity.issuesEntities &&
                    entity.issuesEntities.filter(
                        (v) =>
                            v.modelCertificationCampaignId === currentActiveCertificationCampaign.id &&
                            v.statusString !== 'Closed'
                    ).length > 0;

                const certificationIssuesEntities =
                    entity && entity.issuesEntities
                        ? entity.issuesEntities.filter(
                              (issue) => issue.type === ParameterStore('TROUBLE_TYPE_CERTIFICATION')
                          )
                        : [];
                return (
                    <div>
                        <TableDisplay
                            dense={true}
                            rows={certificationIssuesEntities}
                            cols={[
                                { label: 'Date', field: 'dateString' },
                                { label: 'Certifier', field: 'user' },
                                { label: 'Title', field: 'title' },
                                {
                                    label: 'Description',
                                    field: 'description',
                                },
                            ]}
                            buttons={
                                userHasOwnershipRights(User.getId(), entity) && hasActiveCertificationCampaign
                                    ? // &&
                                      //  !alreadyCertified &&
                                      //  !alreadyHasTrouble
                                      [
                                          {
                                              label: 'Certification trouble',
                                              icon: 'fa-plus',
                                              disabled: alreadyCertified || alreadyHasTrouble,
                                              onClick: () =>
                                                  Modal.open({
                                                      title: 'New certification trouble',
                                                      content: (
                                                          <IssueAddForm
                                                              title={'New certification trouble'}
                                                              type={ParameterStore('TROUBLE_TYPE_CERTIFICATION')}
                                                              models={[`/api/models/${entity.id}`]}
                                                              modelCertificationCampaign={
                                                                  currentActiveCertificationCampaign['@id']
                                                              }
                                                              description=""
                                                          />
                                                      ),
                                                  }),
                                          },
                                      ]
                                    : []
                            }
                        />
                    </div>
                );
            },
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'certificationsIssuesEntities', context),
        },
        modelUsesEntities: {
            title: 'Model uses',
            display: (field, value, entity, props) => {
                // On utilise les mêmes conditions que dans ModelUseAdmin
                let conditionalFields = hasOneGroupEntityNTX(entity)
                    ? [
                          {
                              label: 'Taxonomy',
                              field: 'taxonomy',
                          },
                      ]
                    : [];

                return (
                    <TableDisplay
                        rows={value}
                        cols={[
                            { label: 'Use', field: 'useString' },
                            {
                                label: 'Detailed use',
                                field: 'detailedUseString',
                            },
                            {
                                label: 'Organizational Units',
                                field: 'OUsString',
                            },
                            {
                                label: 'Business Line',
                                field: 'BLString',
                            },
                            {
                                label: 'Legal Entity',
                                field: 'legalEntityString',
                                width: '110',
                            },
                            ...conditionalFields,
                            {
                                label: 'Validation status',
                                field: 'validationStatus',
                            },
                            {
                                label: 'Validation status rationale',
                                field: 'validationStatusRationale',
                            },
                            {
                                label: 'Supervisor authorization',
                                field: 'supervisorAuthorizationString',
                                width: '90',
                            },

                            {
                                label: 'Main use',
                                field: 'mainUse',
                                type: 'bool',
                                width: '90',
                            },
                        ]}
                        actions={(value) => {
                            return (
                                <div>
                                    <OpenModal
                                        instanceId="model_uses"
                                        id={value.id}
                                        context={CONTEXT_DETAIL}
                                        modalTitle={value.useString}
                                        allowStayInModal={true}
                                    />
                                    {userHasStakeHolderRights(User, entity) ||
                                    userIsVal(User, entity) ||
                                    userHasRoleMRM() ? (
                                        <OpenModal
                                            parentInstanceId="models"
                                            parentId={entity.id}
                                            instanceId="model_uses"
                                            id={value.id}
                                            context={CONTEXT_EDIT}
                                            modalTitle={value.useString}
                                            postSaveRedirectUrl={'/resource/models/'+entity.id+'/detail?tab=Uses'}
                                            allowStayInModal={true}
                                        />
                                    ) : null}
                                </div>
                            );
                        }}
                        buttons={
                            !userHasSpecificRole(Role.IG)
                                ? [
                                      {
                                          label: 'New model use',
                                          icon: 'fa-plus',
                                          inModal: true,
                                          modalProps: {
                                              context: CONTEXT_ADD,
                                              instanceId: 'model_uses',
                                              parentInstanceId: 'models',
                                              routeParams: { modelId: entity.id },
                                              parentId: entity.id,
                                              postSaveRedirectUrl: `/resource/models/${entity.id}}/detail?tab=Uses`
                                          }
                                      },
                                  ]
                                : null
                        }
                    />
                );
            },
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'modelUsesEntities', context),
        },
        mrasEntities: {
            edit: () => null,
            display: (field, value, entity, props) => {
                let addMraButton = {
                    label: 'MRA',
                    inModal: true,
                    modalProps: {
                        context: CONTEXT_CUSTOM,
                        customInnerView: <MraInit initialModelId={entity.id}  />,
                    },
                    icon: 'fa-plus',
                };
                let addQuickMraButton = {
                    label: 'Quick MRA',
                    icon: 'fa-plus',
                    inModal: true,
                    modalProps: {
                        context: CONTEXT_ADD,
                        instanceId: 'quick_mras',
                        parentInstanceId: 'models',
                        routeParams: { modelId: entity.id },
                        parentId: entity.id,
                        postSaveRedirectUrl: `/resource/models/${entity.id}}/detail?tab=MRA`
                    }
                };

                const role = getUserRole(entity);

                if (
                    !(
                        userHasMRMRights(User, entity) ||
                        userHasOwnershipRights(User.getId(), entity) ||
                        userHasDeveloperRights(User, entity) ||
                        userIsVal(User, entity)
                    )
                ) {
                    addMraButton = {};
                }
                if (!userHasMRMRights(User, entity)) {
                    addQuickMraButton = {};
                }

                const mras = (value ?? []).map((mra) => {
                    const { status, process } = mra;
                    let showEdition = false;
                    let showDetail = false;
                    let editLink = mra.quickMra
                        ? `/resource/quick_mras/${mra.id}/edit`
                        : `/resource/mras/${mra.id}/update`;
                    const detailLink = `/resource/${mra.quickMra ? 'quick_' : ''}mras/${mra.id}/detail`;
                    const instanceId = `${mra.quickMra ? 'quick_' : ''}mras`;
                    const canEdit = canEditByProcess(role, status, process);

                    if (canEdit) {
                        showEdition = true;
                        if (role === 'LoD2') {
                            editLink = `/resource/${mra.quickMra ? 'quick_' : ''}mras/${mra.id}/detail`;
                        }
                        if (role === 'LoD1' && status === MRA_STATUS.CONFLICT) {
                            editLink = `/resource/${mra.quickMra ? 'quick_' : ''}mras/${mra.id}/detail`;
                        }
                    } else {
                        showDetail = true;
                    }

                    return {
                        ...mra,
                        score: mra.status !== MRA_STATUS.VALIDATED ? '' : mra.score,
                        editLink,
                        detailLink,
                        showDetail,
                        showEdition,
                        instanceId
                    };
                });

                return (
                    <TableDisplay
                        rows={mras}
                        cols={[
                            {
                                label: 'Date',
                                field: 'dateString',
                                styles: { width: '15%' },
                            },
                            {
                                label: 'Author',
                                field: 'versionAuthor',
                                styles: { width: '20%' },
                            },
                            { label: 'Status', field: 'statusString' },
                            { label: 'Model Risk Score', field: 'score' },
                            { label: 'Model Risk assessment result', field: 'modelRiskAssessmentResult' },
                            { label: 'Critical dimensions', field: 'criticalDimensionsResult' },
                        ]}
                        actions={(value) => {
                            return (
                                <div>
                                    {value.showDetail && value.quickMra && (
                                        <OpenModal
                                            instanceId={value.instanceId}
                                            id={value.id}
                                            context="detail"
                                            modalTitle={value.id}
                                        />
                                    )}
                                    {value.showDetail && !value.quickMra && (
                                        <Link to={value.detailLink} style={styles.actionLink}>
                                            <DetailButton />
                                        </Link>
                                    )}
                                    {value.showEdition && !value.insertionFromImport && (
                                        <Link to={value.editLink} style={styles.actionLink}>
                                            <EditButton />
                                        </Link>
                                    )}
                                </div>
                            );
                        }}
                        buttons={[addMraButton, addQuickMraButton]}
                    />
                );
            },
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'mrasEntities', context) || true,
        },
        reviewsEntities: {
            title: 'Reviews',
            display: (field, value, entity, props) => (
                <TableDisplay
                    rows={value}
                    cols={[
                        { label: 'Status', field: 'reviewStatusString' },
                        { label: 'Review ID', field: 'reviewId' },
                        { label: 'Review type', field: 'reviewTypeString' },
                        { label: 'Title', field: 'title' },
                        {
                            label: 'Planned starting date',
                            field: 'plannedStartingDateString',
                        },
                        {
                            label: 'Planned closing date',
                            field: 'plannedClosingDateString',
                        },
                        {
                            label: 'Committee date',
                            field: 'committeesLastDateString',
                        },
                        { label: 'Validation status', field: 'validationStatusString' },
                        {
                            label: 'Documents',
                            field: 'documents',
                            type: 'entity',
                            params: {
                                resource: 'documents',
                            },
                            noTooltip: true,
                            style: { minWidth: '540px' },
                            display: (field, value, entity, props) => (
                                <DocumentListAccordion entity={entity} values={value} />
                            ),
                        },
                    ]}
                    actions={(value) => {
                        return (
                            <div>
                                {
                                    // Droits sur le Model :
                                    (userHasOwnershipRights(User.getId(), entity) ||
                                        userHasDeveloperRights(User, entity) ||
                                        userIsVal(User, entity) ||
                                        userHasMRMRights(User, entity) ||
                                        // Droits sur la Review :
                                        userHasContributingRights(User.getId(), value) ||
                                        userIsVal(User, value)) && (
                                        <Link
                                            to={'/resource/reviews/' + value.id + '/detail'}
                                            style={styles.actionLink}
                                        >
                                            <DetailButton />
                                        </Link>
                                    )
                                }
                                {
                                    // Droits Review :
                                    (((userHasValidatorRights(User.getId(), value) ||
                                        userHasRights(User, value, 'validatorTeams', USER_SCOPE_MANAGER)) &&
                                        value.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED)) ||
                                        // Droits Model :
                                        userHasMRMRights(User, entity)) && (
                                        <Link to={'/resource/reviews/' + value.id + '/edit'} style={styles.actionLink}>
                                            <EditButton />
                                        </Link>
                                    )
                                }
                            </div>
                        );
                    }}
                    buttons={
                        (entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') ||
                            entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE')) &&
                        (userHasOwnershipRights(User.getId(), entity) || userHasDeveloperRights(User, entity))
                            ? [
                                  {
                                      label: 'Request a review',
                                      icon: 'fa-plus',
                                      onClick: () =>
                                          Modal.open({
                                              title: 'Request a review',
                                              content: <ReviewRequestModal entity={entity} modelResource={models} />,
                                          }),
                                  },
                              ]
                            : ((entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') ||
                                  entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE')) &&
                                  entity.modelValidatorTeams &&
                                  userIsVal(User, entity)) ||
                              userHasMRMRights(User, entity)
                            ? [
                                  {
                                      label: 'New review',
                                      to: '/resource/reviews/add/' + entity.id,
                                      icon: 'fa-plus',
                                  },
                              ]
                            : []
                    }
                />
            ),
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'reviewsEntities', context),
        },
        numberOfOpenNotices: {
            title: 'Number of open notices',
            type: 'int',
        },
        numberOfCriticalNotices: {
            title: 'Number of critical notices',
            type: 'int',
        },
        noticesEntities: {
            title: 'Notices',
            display: (field, value, entity, props) => {
                let nonFilteredValues = value;
                const filteredValues = [];
                if (nonFilteredValues) {
                    nonFilteredValues.forEach((notice) => {
                        if (
                            notice.status !== ParameterStore('NOTICE_STATUS_DELETED') &&
                            ((notice.status === ParameterStore('NOTICE_STATUS_DRAFT') &&
                                (User.profile.isMemberOfValidatorTeam || userHasRoleMRM())) ||
                                notice.status !== ParameterStore('NOTICE_STATUS_DRAFT'))
                        ) {
                            filteredValues.push(notice);
                        }
                    });
                }

                value = [
                    ...filteredValues.filter((notice) => notice.status === ParameterStore('NOTICE_STATUS_IN_PROGRESS')),
                    ...filteredValues.filter(
                        (notice) => notice.status === ParameterStore('NOTICE_STATUS_WAITING_FOR_VALIDATION')
                    ),
                    ...filteredValues.filter((notice) => notice.status === ParameterStore('NOTICE_STATUS_CLOSED')),
                    ...filteredValues.filter((notice) => notice.status === ParameterStore('NOTICE_STATUS_DRAFT')),
                ];

                const hasAccessToItemDetail = (item) =>
                    userHasOwnershipRights(User.getId(), entity) ||
                    userHasNoticeOwnershipRights(User.getId(), item) ||
                    userHasDeveloperRights(User, entity) ||
                    userIsVal(User, entity) ||
                    userValidatorTeamManagedRights(User.profile.managedScopes, entity) ||
                    entity.amIBusinessSponsor ||
                    userHasRoleMRM();
                return (
                    <TableDisplay
                        rows={value}
                        cols={[
                            {
                                label: 'Status',
                                field: 'statusString',
                            },
                            {
                                label: 'ID',
                                field: 'id',
                                display: (field, value, notice, _props) => {
                                    return hasAccessToItemDetail(notice) ? (
                                        <OpenModal
                                            instanceId={'notices'}
                                            id={notice.id}
                                            context="detail"
                                            modalTitle={value}
                                            flat={true}
                                        />
                                    ) : (
                                        <>{value}</>
                                    );
                                },
                                tooltip: (field, value, entity) => entity.noticeDescription,
                            },
                            {
                                label: 'Title',
                                field: 'title',
                            },
                            {
                                label: 'Severity',
                                field: 'severityString',
                            },
                            {
                                label: 'Findings',
                                field: 'findings',
                                type: 'entity',
                                params: {
                                    resource: 'findings',
                                    displayField: 'title',
                                    multi: true,
                                    links: true,
                                    modalLinks: true,
                                    endpoints: {
                                        getAll: 'findings/all-findings',
                                    },
                                },
                                noTooltip: true,
                                display: (field, value, finding, props) => {
                                    return (
                                        <ReviewIssueRelation
                                            value={value}
                                            model={entity}
                                            entity={finding}
                                            {...props}
                                            resource={models}
                                            resourcePath={'models'}
                                            type={'finding'}
                                        />
                                    );
                                },
                            },
                            {
                                label: 'Deadline',
                                field: 'deadline',
                            },
                        ]}
                        actions={(value) => {
                            return (
                                <div>
                                    {(value.status !== ParameterStore('NOTICE_STATUS_DELETED') &&
                                        value.status !== ParameterStore('NOTICE_STATUS_CLOSED') &&
                                        value.status !== ParameterStore('NOTICE_STATUS_DISMISSED') &&
                                        (userIsVal(User, entity) || userHasNoticeIssuerTeamsRights(User, value))) ||
                                    userHasRoleMRM() ? (
                                        <Link to={'/resource/notices/' + value.id + '/edit'} style={styles.actionLink}>
                                            <EditButton />
                                        </Link>
                                    ) : null}
                                </div>
                            );
                        }}
                        buttons={
                            userHasMRMRights(User, entity)
                                ? [
                                      {
                                          label: 'New notice',
                                          to: '/resource/notices/add/model/' + entity.id,
                                          icon: 'fa-plus',
                                      },
                                      {
                                          label: 'Confirm these issues',
                                          icon: 'fa-paper-plane',
                                          onClick: () => {
                                              Modal.open({
                                                  title: 'Confirm these issues',
                                                  content: (
                                                      <ValidateFindingNotice
                                                          findings={entity.findingsEntities}
                                                          notices={entity.noticesEntities}
                                                          resource={models}
                                                          entity={entity}
                                                          fromModel={true}
                                                      />
                                                  ),
                                              });
                                          },
                                      },
                                  ]
                                : null
                        }
                    />
                );
            },
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'noticesEntities', context),
        },
        findingsEntities: {
            title: 'Findings',
            display: (field, value, entity, props) => {
                let nonFilteredValues = value;
                const filteredValues = [];
                if (nonFilteredValues) {
                    nonFilteredValues.forEach((finding) => {
                        if (
                            finding.status !== ParameterStore('FINDING_STATUS_DELETED') &&
                            ((finding.status === ParameterStore('FINDING_STATUS_DRAFT') &&
                                (User.profile.isMemberOfValidatorTeam || userHasRoleMRM())) ||
                                finding.status !== ParameterStore('FINDING_STATUS_DRAFT'))
                        ) {
                            filteredValues.push(finding);
                        }
                    });
                }
                // on regroupe grossièrement les valeurs par statut, dans cet ordre
                value = [
                    ...filteredValues.filter((f) => f.status === ParameterStore('FINDING_STATUS_OPEN')),
                    ...filteredValues.filter((f) => f.status === ParameterStore('FINDING_STATUS_CLOSED')),
                    ...filteredValues.filter((f) => f.status === ParameterStore('FINDING_STATUS_DRAFT')),
                ];

                const hasAccessToItemDetail = (item) =>
                    userHasOwnershipRights(User.getId(), entity) ||
                    userHasNoticeOwnershipRights(User.getId(), item) ||
                    userHasDeveloperRights(User, entity) ||
                    userIsVal(User, entity) ||
                    userValidatorTeamManagedRights(User.profile.managedScopes, entity) ||
                    entity.amIBusinessSponsor ||
                    userHasRoleMRM();
                return (
                    <TableDisplay
                        rows={value}
                        cols={[
                            {
                                label: 'Status',
                                field: 'statusString',
                            },
                            {
                                label: 'Title',
                                field: 'title',
                                display: (field, value, finding, _props) => {
                                    return hasAccessToItemDetail(finding) ? (
                                        <OpenModal
                                            instanceId={'findings'}
                                            id={finding.id}
                                            context="detail"
                                            modalTitle={value}
                                            flat={true}
                                        />
                                    ) : (
                                        <>{value}</>
                                    );
                                },
                                tooltip: (field, value, entity) => entity.weaknessesDescription,
                            },
                            {
                                label: 'Severity',
                                field: 'severityString',
                            },
                            {
                                label: 'Notices',
                                field: 'notices',
                                type: 'entity',
                                params: {
                                    resource: 'notices',
                                    displayField: 'id',
                                    multi: true,
                                    links: true,
                                    modalLinks: true,
                                    endpoints: {
                                        getAll: 'i_t_systems/all-i_t_systems',
                                    },
                                },
                                noTooltip: true,
                                display: (field, value, notice, props) => {
                                    return (
                                        <ReviewIssueRelation
                                            value={value}
                                            model={entity}
                                            entity={notice}
                                            {...props}
                                            resource={models}
                                            resourcePath={'models'}
                                            type={'notice'}
                                        />
                                    );
                                },
                            },
                        ]}
                        actions={(value) => {
                            return (
                                <div>
                                    {(value.status !== ParameterStore('FINDING_STATUS_DELETED') &&
                                        value.status !== ParameterStore('FINDING_STATUS_CLOSED') &&
                                        value.status !== ParameterStore('FINDING_STATUS_DISMISSED') &&
                                        (userIsVal(User, entity) || userHasIssuerTeamsRights(User, value))) ||
                                    userHasRoleMRM() ? (
                                        <Link to={'/resource/findings/' + value.id + '/edit'} style={styles.actionLink}>
                                            <EditButton />
                                        </Link>
                                    ) : null}
                                </div>
                            );
                        }}
                        buttons={
                            userHasMRMRights(User, entity)
                                ? [
                                      {
                                          label: 'New finding',
                                          to: '/resource/findings/add/model/' + entity.id,
                                          icon: 'fa-plus',
                                      },
                                      {
                                          label: 'Associate findings and notices',
                                          icon: 'fa-link',
                                          onClick: () => {
                                              Modal.open({
                                                  title: 'Associate findings and notices',
                                                  content: (
                                                      <AssociateFindingNotice
                                                          findings={entity.findingsEntities}
                                                          notices={entity.noticesEntities}
                                                          resource={models}
                                                          resourcePath={'models'}
                                                          entity={entity}
                                                      />
                                                  ),
                                              });
                                          },
                                      },
                                  ]
                                : null
                        }
                    />
                );
            },
            displayList: () => null,
        },
        iggBceRecommendationEntities: {
            title: 'IGG/BCE',
            display: (field, value, entity, props) => (
                <TableDisplay
                    rows={value}
                    cols={[
                        { label: 'Title', field: 'title' },
                        { label: 'Mission title', field: 'missionTitle' },
                        { label: 'Reference', field: 'reference' },
                        {
                            label: 'Status',
                            field: 'statusString',
                            width: '100',
                        },
                        {
                            label: 'Priority',
                            field: 'priorityString',
                            width: '100',
                        },
                        {
                            label: 'Origin',
                            field: 'originString',
                            width: '100',
                        },
                        {
                            label: 'Due date',
                            field: 'dueDateString',
                            width: '100',
                        },
                    ]}
                    actions={(value) => {
                        return (
                            <div>
                                <Link
                                    to={'/resource/recommendation_igg_bces/' + value.id + '/detail'}
                                    style={styles.actionLink}
                                >
                                    <DetailButton />
                                </Link>
                                <Link
                                    to={'/resource/recommendation_igg_bces/' + value.id + '/edit'}
                                    style={styles.actionLink}
                                >
                                    <EditButton />
                                </Link>
                            </div>
                        );
                    }}
                    buttons={
                        userIsVal(User, entity) || userHasRoleMRM()
                            ? [
                                  {
                                      label: 'New recommendation IGG BCE',
                                      to: '/resource/recommendation_igg_bces/add/model/' + entity.id,
                                      icon: 'fa-plus',
                                  },
                              ]
                            : null
                    }
                />
            ),
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'iggBceRecommendationEntities', context),
        },
        coveringMitigationActionsEntities: {
            title: 'Mitigation actions',
            display: (field, value, entity, _props) => {
                // On affiche les mitigations closed à la fin du tableau.
                const mitigationsNotClosed = value?.filter(v => v.status !== ParameterStore('MITIGATION_ACTION_STATUS_CLOSED')) || [];
                const mitigationsClosed = value?.filter(v => v.status === ParameterStore('MITIGATION_ACTION_STATUS_CLOSED')) || [];

                return <TableDisplay
                    rows={[...mitigationsNotClosed, ...mitigationsClosed]}
                    cols={[
                        {
                            label: 'Status',
                            field: 'statusString',
                        },
                        {
                            label: 'Mitigation measure type',
                            field: 'mitigationMeasureTypeString',
                        },
                        { label: 'Title', field: 'title' },
                        { label: 'Description', field: 'description' },
                        {
                            label: 'Start date',
                            field: 'startDate',
                        },
                        {
                            label: 'Reserve models',
                            field: 'reserveModels',
                            type: 'entity',
                            params: {
                                resource: 'models',
                                displayField: 'functionalID',
                                links: true,
                                multi: true,
                            },
                        },
                        {
                            label: 'Documents',
                            field: 'documents',
                            type: 'entity',
                            params: {
                                resource: 'documents',
                            },
                            noTooltip: true,
                            style: { minWidth: '540px' },
                            display: (field, value, entity, _props) => (
                                <DocumentListAccordion entity={entity} values={value} />
                            ),
                        },
                    ]}
                    actions={(value) => {
                        return (
                            <div>
                                {userHasOwnershipRights(User.getId(), entity) ||
                                userHasDeveloperRights(User, entity) ||
                                userIsVal(User, entity) ||
                                userHasRoleMRM() ? (
                                    <OpenModal
                                        instanceId="mitigation_actions"
                                        id={value.id}
                                        context="detail"
                                        modalTitle={value.mitigationMeasureTypeString}
                                    />
                                ) : null}
                                {userHasOwnershipRights(User.getId(), entity) ||
                                userHasDeveloperRights(User, entity) ||
                                userIsVal(User, entity) ||
                                userHasRoleMRM() ? (
                                    <Link
                                        to={'/resource/mitigation_actions/' + value.id + '/edit'}
                                        style={styles.actionLink}
                                    >
                                        <EditButton />
                                    </Link>
                                ) : null}
                            </div>
                        );
                    }}
                    buttons={
                        !entity.mitigationActions &&
                        (userHasRoleMRM() ||
                            userHasOwnershipRights(User.getId(), entity) ||
                            userHasDeveloperRights(User, entity) ||
                            userIsVal(User, entity))
                            ? [
                                  {
                                      label: 'New mitigation action',
                                      icon: 'fa-plus',
                                      inModal: true,
                                      modalProps: {
                                          context: CONTEXT_ADD,
                                          instanceId: 'mitigation_actions',
                                          parentInstanceId: 'models',
                                          routeParams: { modelId: entity.id },
                                          parentId: entity.id,
                                          postSaveRedirectUrl: `/resource/models/${entity.id}}/detail?tab=Mitigation Actions`
                                      }
                                  },
                              ]
                            : []
                    }
                />
            },
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'coveringMitigationActionsEntities', context),
        },
        reservingMitigationActionsEntities: {
            title: 'Reserve Models',
            display: (field, value, entity, props) => (
                <TableDisplay
                    rows={value}
                    cols={[
                        {
                            label: 'Mitigation measure type',
                            field: 'mitigationMeasureTypeString',
                        },
                        { label: 'Label', field: 'title' },
                        { label: 'Description', field: 'description' },
                        {
                            label: 'Covered models',
                            field: 'coveredModels',
                            type: 'entity',
                            params: {
                                resource: 'models',
                                displayField: 'functionalID',
                                links: true,
                                multi: true,
                            },
                        },
                        {
                            label: 'Documents',
                            field: 'documents',
                            type: 'entity',
                            params: {
                                resource: 'documents',
                            },
                            noTooltip: true,
                            style: { minWidth: '540px' },
                            display: (field, value, entity, props) => (
                                <DocumentListAccordion entity={entity} values={value} />
                            ),
                        },
                    ]}
                    actions={(value) => {
                        return (
                            <div>
                                {userHasOwnershipRights(User.getId(), entity) ||
                                userHasDeveloperRights(User, entity) ||
                                userIsVal(User, entity) ||
                                userHasRoleMRM() ? (
                                    <Link
                                        to={'/resource/mitigation_actions/' + value.id + '/detail'}
                                        style={styles.actionLink}
                                    >
                                        <DetailButton />
                                    </Link>
                                ) : null}
                                {userHasOwnershipRights(User.getId(), entity) ||
                                userHasDeveloperRights(User, entity) ||
                                userIsVal(User, entity) ||
                                userHasRoleMRM() ? (
                                    <Link
                                        to={'/resource/mitigation_actions/' + value.id + '/edit'}
                                        style={styles.actionLink}
                                    >
                                        <EditButton />
                                    </Link>
                                ) : null}
                            </div>
                        );
                    }}
                />
            ),
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                entity.reservingMitigationActionsEntities &&
                entity.reservingMitigationActionsEntities.length > 0 &&
                enableByStatus(entity, 'reservingMitigationActionsEntities', context),
        },
        lastTieringMaterialityLevelFinal: {
            title: 'Tiering: Materiality level',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MATERIALITY,
                multi: false,
            },
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
            //displayConditions: (entity, entity2, key, context) => false,
        },
        lastTieringModelUsageLevelFinal: {
            title: 'Tiering: Model usage level',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MODEL_USAGE,
                multi: false,
            },
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
            //displayConditions: (entity, entity2, key, context) => false,
        },
        lastTieringTierResultFinal: {
            title: 'Tiering: Tier result',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MODEL_TIER_RESULT,
                multi: false,
            },
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
            //displayConditions: (entity, entity2, key, context) => false,
        },
        lastTieringExternalImpactLevelFinal: {
            title: 'Tiering: External impact level',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_EXTERNAL_IMPACT,
                multi: false,
            },
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
            //displayConditions: (entity, entity2, key, context) => false,
        },
        lastTieringTierRationaleFinal: {
            title: 'Tiering: Tier rationale',
            type: 'text',
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
            //displayConditions: (entity, entity2, key, context) => false,
        },
        lastTieringComplexityLevelFinal: {
            title: 'Tiering: Complexity level',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_COMPLEXITY_LEVEL,
                multi: false,
            },
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
            //displayConditions: (entity, entity2, key, context) => false,
        },
        lastTieringComplexityRationaleFinal: {
            title: 'Tiering: Complexity rationale',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_TIERING_COMPLEXITY_RATIONALE,
                multi: false,
            },
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
            //displayConditions: (entity, entity2, key, context) => false,
        },
        lastTieringCategory: {
            title: 'Tiering: Category',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MODEL_TIERING_CATEGORY,
                multi: false,
            },
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
        },
        lastTieringMetricsFinal: {
            title: 'Tiering: Metrics',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_METRICS,
                multi: false,
            },
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
        },
        tieringsEntities: {
            title: 'Tierings',
            display: (field, value, entity, props) => {
                let complexityCol = hasOneGroupEntityNTX(entity)
                    ? [
                          {
                              label: 'Complexity level',
                              field: 'complexityLevelFinal',
                          },
                      ]
                    : [];
                return (
                    <TableDisplay
                        className={'model-tiering-entities'}
                        selectedRow={entity.tieringEntity ? entity.tieringEntity.id : null}
                        rows={value}
                        cols={[
                            { label: 'ID', field: 'id' },
                            { label: 'Date', field: 'dateString' },
                            {
                                label: 'Materiality level',
                                field: 'materialityLevelFinal',
                            },
                            {
                                label: 'Model usage level',
                                field: 'modelUsageLevelFinal',
                            },
                            {
                                label: 'External impact level',
                                field: 'externalImpactLevelFinal',
                            },
                            ...complexityCol,
                            { label: 'Tier result', field: 'tierResultFinal' },
                            {
                                label: 'Tier rationale',
                                field: 'tierRationaleFinal',
                            },
                            {
                                label: 'Verified by MRM',
                                field: 'verifiedByMrm',
                                display: (field, value, entity, props) => {
                                    return (
                                        <VerifiedButton
                                            readonly={
                                                /** MRM ne peut vérifier que le dernier Tiering */
                                                !userHasRoleMRM() || !entity.isLastTiering
                                            }
                                            entity={entity}
                                            entityResource={'tierings'}
                                            property={'verifiedByMrm'}
                                        />
                                    );
                                },
                            },
                        ]}
                        actions={(value) => {
                            return (
                                <div>
                                    {userHasRoleMRM() ||
                                    userHasOwnershipRights(User.getId(), entity) ||
                                    userHasDeveloperRights(User, entity) ||
                                    userHasImplementerRights(User, entity) ||
                                    userIsVal(User, entity) ||
                                    userHasBusinessSponsorRights(User.getId(), entity) ? (
                                        <OpenModal
                                            instanceId="tierings"
                                            id={value.id}
                                            context="detail"
                                            modalTitle={value.toString}
                                            allowStayInModal={true}
                                        />
                                    ) : null}
                                    {((!value.verifiedByMrm &&
                                        (userHasOwnershipRights(User.getId(), entity) ||
                                            userHasDeveloperRights(User, entity))) ||
                                        userHasRoleMRM()) &&
                                    value.isLastTiering ? (
                                        <OpenModal
                                            instanceId="tierings"
                                            id={value.id}
                                            context="edit"
                                            modalTitle={value.toString}
                                            allowStayInModal={true}
                                        />
                                    ) : null}
                                </div>
                            );
                        }}
                        buttons={
                            (userHasRoleMRM() ||
                                userHasDeveloperRights(User, entity) ||
                                userHasDeclarerRights(User.getId(), entity) ||
                                userHasOwnershipRights(User.getId(), entity)) &&
                            (!entity.tieringsEntities ||
                                entity.tieringsEntities.length === 0 ||
                                entity.tieringsEntities[0]['verifiedByMrm'] === true)
                                ? [
                                      {
                                          label: 'New tiering',
                                          onClick: (button) => {
                                              Modal.open({
                                                  title: button.label,
                                                  className: 'modal-create-tiering',
                                                  content: <TieringForm model={entity} />,
                                              });
                                          },
                                          icon: 'fa-plus',
                                      },
                                  ]
                                : []
                        }
                    />
                );
            },
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'tieringsEntities', context),
        },
        implementationsEntities: {
            title: 'Implementations',
            display: (field, value, entity, props) => (
                <TableDisplay
                    rows={value}
                    cols={[
                        { label: 'Date', field: 'dateString' },
                        {
                            label: 'Model implementer team',
                            field: 'modelImplementerTeam',
                        },
                        { label: 'IT System', field: 'ITSystem' },
                        { label: 'System Version', field: 'systemVersion' },
                    ]}
                    actions={(value) => {
                        return (
                            <div>
                                <OpenModal
                                    instanceId="implementations"
                                    id={value.id}
                                    context="detail"
                                    modalTitle={value.toString}
                                    allowStayInModal={true}
                                />
                                {userHasStakeHolderRights(User, entity) || userHasMRMRights(User, entity) ? (
                                    <OpenModal
                                        parentInstanceId="models"
                                        parentId={entity.id}
                                        instanceId="implementations"
                                        id={value.id}
                                        context={CONTEXT_EDIT}
                                        modalTitle={value.useString}
                                        postSaveRedirectUrl={'/resource/models/'+entity.id+'/detail?tab=Uses'}
                                        allowStayInModal={true}
                                    />
                                ) : null}
                            </div>
                        );
                    }}
                    buttons={
                        !userHasSpecificRole(Role.IG)
                            ? [
                                  {
                                      label: 'New implementation',
                                      icon: 'fa-plus',
                                      inModal: true,
                                      modalProps: {
                                          context: CONTEXT_ADD,
                                          instanceId: 'implementations',
                                          parentInstanceId: 'models',
                                          routeParams: { modelId: entity.id },
                                          parentId: entity.id,
                                          postSaveRedirectUrl: `/resource/models/${entity.id}}/detail?tab=Implementations`
                                      }
                                  },
                              ]
                            : null
                    }
                />
            ),
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'implementationsEntities', context),
        },
        itSystems: {
            title: 'IT Systems',
            type: 'entity',
            displayList: (field, value, entity, props) => (value ? String.nlToBr(value.join('\n')) : ' '),
            params: {
                resource: 'i_t_systems',
                displayField: 'name',
                multi: true,
                endpoints: {
                    getAll: 'i_t_systems/all-i_t_systems',
                },
            },
            bulk: false,
        },
        deletionComment: {
            title: 'Deletion comment',
            type: 'textarea',
            edit: () => null,
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                entity.modelStatus === ParameterStore('MODEL_STATUS_DELETED') &&
                enableByStatus(entity, 'deletionComment', context),
        },
        nonModelComment: {
            title: 'Justification of the conversion to non-model',
            type: 'textarea',
            edit: () => null,
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                entity.nonModel === true && enableByStatus(entity, 'nonModelComment', context),
        },
        versionsSummary: {
            title: 'Versions',
            display: (field, value, entity, props) => {
                return <VersionsDetail model={entity} resource={models} entityResource={'models'} />;
            },
            displayList: (field, value, entity, props) => null,
            token: false,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'versionsSummary', context),
        },
        //Additional fields without relation with Model
        changeLogComment: {
            title: 'Justification of the data update',
            type: 'textarea',
            display: (field, value, entity, props) => null,
            displayList: (field, value, entity, props) => null,
            bulk: false,
            displayConditions: (entity, entity2, key, context) => {
                return !userHasImplementerRights(User, entity) && enableByStatus(entity, 'changeLogComment', context);
            },
        },
        documentsEntities: {
            title: 'Documents',
            display: (field, value, entity, _props) => (
                <DocumentManager
                    values={value}
                    entity={entity}
                    entityResource={'models'}
                    allowedAction={(entity, document, action) => {
                        if (action === 'add') {
                            if (
                                userHasOwnershipRights(User.getId(), entity) ||
                                userHasDeveloperRights(User, entity) ||
                                userHasImplementerRights(User, entity) ||
                                userIsVal(User, entity) ||
                                userHasBusinessSponsorRights(User.getId(), entity) ||
                                userHasRoleMRM() ||
                                userHasRoleIG()
                            ) {
                                return true;
                            }
                        } else if (action === 'show') {
                            if (
                                userHasOwnershipRights(User.getId(), entity) ||
                                userHasDeveloperRights(User, entity) ||
                                userIsVal(User, entity) ||
                                userHasBusinessSponsorRights(User.getId(), entity) ||
                                userHasRoleMRM() ||
                                userHasRoleIG()
                            ) {
                                return true;
                            }
                        } else if (action === 'delete') {
                            if (userIsVal(User, entity) || userHasRoleMRM()) {
                                return true;
                            }
                        }
                        return false;
                    }}
                />
            ),
            displayList: (field, value, _entity, _props) => <DocumentList values={value} />,
            displayConditions: (entity, entity2, key, context) =>
                (userHasOwnershipRights(User.getId(), entity) ||
                    userHasDeveloperRights(User, entity) ||
                    userHasImplementerRights(User, entity) ||
                    userIsVal(User, entity) ||
                    userHasBusinessSponsorRights(User.getId(), entity) ||
                    userHasRoleMRM() ||
                    userHasRoleIG()) &&
                enableByStatus(entity, 'documentsEntities', context),
        },
        verifiedByMRM: {
            title: 'Verified By MRM',
            type: 'bool',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'verifiedByMRM'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'verifiedByMRM', context),
        },
        delegationCommittees: {
            title: 'Delegation Committees',
            type: 'entity',
            params: {
                resource: 'review_committees',
                displayField: 'displayString',
                links: true,
                multi: true,
                filters: (c) => c.type === ParameterStore('REVIEW_COMMITTEE_TYPE_COMMITTEE'),
            },
            helperText: 'Committees that granted or renewed the validation delegation',
            doNotResetValueWhenNotDisplayed: true,
            bulk: true,
            issueButton: false,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'delegationCommittees', context) && (context !== 'edit' || userHasRoleMRM()),
        },
        reviewedModel: {
            title: 'Reviewed model',
            type: 'bool',
            helperText: 'Has the model been submitted to a validation process ?',
            required: (entity) => requiredByStatus(entity, 'reviewedModel'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'reviewedModel', context),
            edit: (field, value) => BoolProvider.getDisplay(field, value),
        },
        policyExceptionStartDate: {
          title: 'Policy Exception start date',
          type: 'date',
          params: {}, //Ne pas supprimer, sert au minDate dans le onUpdate
          helperText: 'Date of the exception granted by MRM to a model that did not go through the proper validation and approval process, in accordance with the Group procedure',
          required: (entity, fieldId) => requiredByStatus(entity, fieldId),
          displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'policyExceptionStartDate', context),
          bulk: true,
        },
        policyExceptionEndDate: {
          title: 'Policy Exception end date',
          type: 'date',
          params: {}, //Ne pas supprimer, sert au minDate dans le onUpdate
          helperText: 'Expiry date of the exception',
          required: (entity, fieldId) => requiredByStatus(entity, fieldId),
          displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'policyExceptionEndDate', context),
          bulk: true,
        },
        policyExceptionType: {
          title: 'Policy Exception type',
          type: 'parameter',
          params: {
            type: PARAMETER_TYPE_MODEL_POLICY_EXCEPTION_TYPE,
            multi: false,
          },
          helperText:
            'Type of the exception granted by MRM: model use, retirement etc.',
          bulk: true,
          issueButton: false,
          required: (entity) => requiredByStatus(entity, 'policyExceptionType'),
          displayConditions: (entity, entity2, key, context) =>
            enableByStatus(entity, 'policyExceptionType', context),
          highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        policyExceptionJustification: {
            title: 'Policy Exception justification',
            type: 'textarea',
            helperText: 'MRM Justification for the exception granted',
            required: (entity, fieldId) => requiredByStatus(entity, fieldId),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'policyExceptionJustification', context),
            bulk: true,
        },
        process: {
            title: 'Process',
            type: 'mapped',
            params: {
                mapping: {
                    1: 'Declaration',
                    2: 'Non-Model',
                    3: 'Retirement',
                    4: 'Tiering update',
                },
            },
        },
        ...retirementFields,
        ...specificFrameworkConversionFields,
        specificFramework: {
            title: 'Specific framework',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_SPECIFIC_FRAMEWORK,
                multi: false,
            },
            bulk: false,
            required: false,
            displayList: (field, value, entity, props, resource) => {
                if(resource.instanceId !== 'specific_frameworks'){
                    return null;
                }
                return ParameterProvider.getDisplayList(field, value, entity, props, resource)
            },
            displayConditions: (entity, _e, _k, context) => entity.specificFramework || (context === 'edit' && userHasRoleMRM()),
            editConditions: () => userHasRoleMRM(),
        },
    });
const MODEL_LAYOUT = {
    tabs: {
        'ID Card': {
            rows: [
                {
                    panels: {
                        Identity: {
                            cols: 6,
                            fields: [
                                'name',
                                'description',
                                'modelType',
                                'specificFramework',
                                'output',
                                'modelStatus',
                                'nonModelStatus',
                                'expectedStatus',
                            ],
                        },
                        IDs: {
                            cols: 6,
                            fields: ['modelID', 'functionalID', 'initialID', 'systemID', 'ECBID', 'ECBAnnexID'],
                        },
                    },
                },
                {
                    panels: {
                        Taxonomy: {
                            cols: 6,
                            fields: ['riskCategory', 'microRiskCategory', 'microRiskSubCategory'],
                        },
                        'Scope of application': {
                            cols: 6,
                            fields: ['applicationDomainLevel1', 'applicationDomainLevel2', 'applicationDomainLevel3'],
                        },
                    },
                },
                {
                    panels: {
                        Ownership: {
                            cols: 6,
                            fields: [
                                'modelOwner',
                                'modelOwnerTeams',
                                'modelOwnerPosition',
                                'modelOwnerEstablishment',
                                'modelOwnerDelegation',
                            ],
                        },
                        Stakeholders: {
                            cols: 6,
                            fields: [
                                'modelDeveloperTeam',
                                'modelValidatorTeams',
                                'mrmTeams',
                                'mrmTeamsOverride',
                                'businessSponsor',
                                'dataContactPointTeam',
                            ],
                        },
                    },
                },
                {
                    panels: {
                        'Core model': {
                            cols: 6,
                            fields: ['coreModel', 'coreModelParent'],
                        },
                    },
                },
            ],
        },
        Properties: {
            rows: [
                {
                    panels: {
                        'Champion / Challenger': {
                            cols: 6,
                            fields: ['championOrChallenger', 'championModels'],
                        },
                        'Development and sources': {
                            cols: 6,
                            fields: ['inHouseOrVendor', 'vendor', 'dataSource'],
                        },
                    },
                },
                {
                    panels: {
                        'Upstream / Downstream': {
                            cols: 6,
                            fields: [
                                'upStreamNotAvailable',
                                'upstreamModels',
                                'downStreamNotAvailable',
                                'downstreamModels',
                            ],
                        },
                    },
                },
                {
                    panels: {
                        'Model Nature': {
                            cols: 6,
                            fields: ['modelNatureStandard', 'modelNatureDetailedString', 'modelObservability'],
                        },
                        'Family tree': {
                            cols: 6,
                            fields: ['backgroundModelRelations', 'foregroundModelRelations'],
                        },
                    },
                },
                {
                    panels: {
                        Flags: {
                            cols: 6,
                            fields: ['operationalRiskFlag', 'automaticScoreFlag', 'defaultPortfolioFlag'],
                        },
                    },
                },
                {
                    panels: {
                        Validation: {
                            cols: 6,
                            fields: [
                                'reviewedModel',
                                'validationFamily',
                                'validatorsLocation',
                                'delegationCommittees',
                                'policyExceptionStartDate',
                                'policyExceptionEndDate',
                                'policyExceptionType',
                                'policyExceptionJustification',
                            ],
                        },
                    },
                },
            ],
        },
        Uses: {
            rows: [
                {
                    panels: {
                        'Model uses': {
                            cols: 12,
                            fields: ['modelUsesEntities'],
                        },
                    },
                },
            ],
        },
        Tiering: {
            rows: [
                {
                    panels: {
                        Tiering: {
                            cols: 12,
                            fields: ['tieringsEntities'],
                        },
                    },
                },
            ],
        },
        Implementations: {
            rows: [
                {
                    panels: {
                        'Model Implementation': {
                            cols: 12,
                            fields: ['firstImplementationDate', 'productionRun', 'implementationsEntities'],
                        },
                    },
                },
            ],
        },
        'Mitigation actions': {
            rows: [
                {
                    panels: {
                        'Mitigation actions': {
                            cols: 12,
                            fields: ['mitigationActions', 'coveringMitigationActionsEntities'],
                        },
                        'Reserve models': {
                            cols: 12,
                            fields: ['reservingMitigationActionsEntities'],
                        },
                    },
                },
            ],
        },
        Certifications: {
            displayConditions: (entity) =>
                ['MODEL_STATUS_ACTIVE', 'MODEL_STATUS_DELETED', 'MODEL_STATUS_RETIRED']
                    .map(ParameterStore)
                    .includes(entity.modelStatus) &&
                (userHasStakeHolderRights(User, entity) || userHasRoleMRM()),
            rows: [
                {
                    panels: {
                        'Certification information': {
                            cols: 12,
                            fields: [
                                'lastCertificationDate',
                                'certifierName',
                                'certifierTeam',
                                'certifierPosition',
                                'lastCertificationResult',
                            ],
                        },
                        Certifications: {
                            cols: 12,
                            fields: ['certificationsEntities'],
                        },
                    },
                },
                {
                    panels: {
                        'Certifications Troubles': {
                            cols: 12,
                            fields: ['certificationsIssuesEntities'],
                        },
                    },
                },
            ],
        },
        'Model life': {
            displayConditions: (entity) => !userHasNoRole(User, entity),
            //env: 'dev',
            rows: [
                {
                    panels: {
                        'Past reviews': {
                            cols: 4,
                            fields: [
                                'lastValidationType',
                                'lastFullValidationDate',
                                'lastPeriodicValidationDate',
                                'lastMinorEvolutionReviewDate',
                                'validationStatus',
                                'validationStatusRationale',
                            ],
                        },
                    },
                },
                {
                    panels: {
                        'Forthcoming reviews': {
                            cols: 4,
                            fields: [
                                'nextScheduledValidationDate',
                                'nextValidationDateVerificationComment',
                                'nextScheduledValidationType',
                                'nextFullValidationDateProcedure',
                                'nextPeriodicValidationDateProcedure',
                            ],
                        },
                    },
                },
                {
                    panels: {
                        MRA: {
                            cols: 4,
                            fields: [
                                // 'inherentModelRiskScore',
                                // 'modelRiskScore',
                                // 'residualModelRiskScore',
                                'averageModelRiskScore',
                                'modelRiskAssessmentResults',
                                'criticalScoreDimension',
                            ],
                        },
                    },
                },
            ],
        },
        MRA: {
            displayConditions: (entity) => !userHasNoRole(User, entity),
            //env: 'dev',
            rows: [
                {
                    panels: {
                        MRAs: {
                            cols: 12,
                            fields: ['mrasEntities'],
                        },
                    },
                },
            ],
        },
        Reviews: {
            displayConditions: (entity) => !userHasNoRole(User, entity),
            //env: 'dev',
            rows: [
                {
                    panels: {
                        Reviews: {
                            cols: 12,
                            fields: ['reviewsEntities'],
                        },
                    },
                },
            ],
        },
        Issues: {
            displayConditions: (entity) =>
                entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE') && !userHasNoRole(User, entity),
            //env: 'dev',
            rows: [
                {
                    panels: {
                        Findings: {
                            cols: 6,
                            fields: ['findingsEntities'],
                        },
                        Notices: {
                            cols: 6,
                            fields: ['numberOfOpenNotices', 'numberOfCriticalNotices', 'noticesEntities'],
                        },
                        'IGG/BCE Recommendations': {
                            cols: 12,
                            fields: ['iggBceRecommendationEntities'],
                        },
                    },
                },
            ],
        },
        Documents: {
            rows: [
                {
                    panels: {
                        Documents: {
                            cols: 12,
                            fields: ['referenceDocumentation', 'documentsEntities'],
                        },
                    },
                },
            ],
        },
        Retirement: {
            rows: [
                {
                    panels: {
                        Retirement: {
                            cols: 12,
                            fields: [
                                'retirementRequestDate',
                                'retirementExpectedDate',
                                'retirementStatus',
                                'modelStatusRetirementDate',
                                'retirementCommittee',
                                'retirementJustificationMrm',
                                'retirementDocumentsEntities',
                                'retirementJustificationLod1',
                            ],
                        },
                    },
                },
            ],
        },
        'Audit trail': {
            displayConditions: (entity) => {
                return (
                    userHasStakeHolderRights(User, entity) ||
                    userIsVal(User, entity) ||
                    userHasRoleMRM() ||
                    userHasSpecificRole(Role.IG)
                );
            },
            rows: [
                {
                    panels: {
                        'Inventory removal': {
                            cols: 12,
                            fields: ['deletionComment', 'modelStatusDeletionDate', 'nonModelComment', 'specificFrameworkJustification', 'convertSpecificFrameworkDocumentsEntities'],
                        },
                    },
                },
                {
                    panels: {
                        Versions: {
                            cols: 12,
                            fields: ['versionsSummary', 'changeLogComment'],
                        },
                    },
                },
                {
                    panels: {
                        Declaration: {
                            cols: 6,
                            fields: ['treeDeterminationAnswers', 'useDescription'],
                        },
                        Creation: {
                            cols: 6,
                            fields: ['declarationDate', 'declarer', 'insertionFromImport', 'clonedFrom'],
                        },
                    },
                },
            ],
        },
    },
};

const FIELDS_SAVE_WHITE_LIST = [
    'mitigationActions',
    'expectedStatus',
    'groupEntities',
    'coveringMitigationActions',
    'implementations',
    'tierings',
    'modelUses',
];

const MODEL_EDIT = (resource) => ({
    fields: MODEL_EDIT_FIELDS_DEFAULT,
    /** Ne pas oublier les champs nécessaires à la validation {@see validationByStatus} */
    fieldsSaveWhitelist: FIELDS_SAVE_WHITE_LIST,
    onInit: ({ entity, _resource, _context }) => {
        if (
            Array.isArray(entity.coveringMitigationActionsEntities) &&
            entity.coveringMitigationActionsEntities.length > 0
        ) {
            entity.mitigationActions = false;
        }
    },
    onUpdate: (fieldId, oldValue, newValue, entity, resource, resourceEditComponent) => {
        if (fieldId === 'modelOwner' || fieldId === 'mrmTeamsOverride') {
            if (entity && entity.id) {
                let obj = {
                    modelOwner: entity.modelOwner,
                    mrmTeamsOverride: entity.mrmTeamsOverride,
                };
                Http.post(`models/${entity.id}/mrm-team-group-entity`, obj).then((response) => {
                    entity.mrmTeams = response.mrmTeams;
                    entity.modelOwnerTeams = response.modelOwnerTeams;
                    entity.groupEntities = response.groupEntities;
                    resourceEditComponent.setState({ entity });
                });
            }
        }
        if (fieldId === 'policyExceptionStartDate') {
            resource.fields.policyExceptionEndDate.params.minDate = newValue;
            delete resource.fields.policyExceptionEndDate.params.maxDate;
            if (!newValue) {
                delete resource.fields.policyExceptionEndDate.params.minDate;
            }
        } else if (fieldId === 'policyExceptionEndDate') {
            resource.fields.policyExceptionStartDate.params.maxDate = newValue;
            delete resource.fields.policyExceptionStartDate.params.minDate;
            if (!newValue) {
                delete resource.fields.policyExceptionStartDate.params.maxDate;
            }
        }
    },
    itemAccessCondition: (entity) => {
        let isGranted = async () => {
            let editGranted = await Http.get('models/' + entity.id + '/is_granted/MODEL_EDIT', { cache: true });
            return (
                (!!entity.activeVersion || entity.modelStatus === ParameterStore('MODEL_STATUS_RETIRED')) &&
                editGranted['hydra:member']['is_granted']
            );
        };
        let res = entity && entity.id ? isGranted() : false;
        return res;
    },
    routeAccessControl: (_entity) => true,
    postSaveRedirect: postSaveRedirectToCertification,
    additionalActionButtons: (entity, resource, resourceEditComponent, queryParams) => {
        let additionalActionButtons = [];

        if (
            !(
                typeof queryParams !== 'object' ||
                queryParams.get('certification-id') === undefined ||
                queryParams.get('certification-id') === '' ||
                queryParams.get('certification-id') === null
            )
        ) {
            additionalActionButtons.push({
                link: '/resource/my_model_certification_campaigns/' + queryParams.get('certification-id') + '/detail',
                tooltip: 'Go back to certification',
                icon: 'chevron-left',
            });
        }


        if (userHasRoleMRM() && entity.nonModelStatus === ParameterStore('NON_MODEL_STATUS_NON_MODEL_AWAITING')) {
            additionalActionButtons.push({
                onClick: (button) =>
                    Modal.open({
                        title: 'Convert to model',
                        content: (
                            <ConfirmModal
                                message={`Do you confirm that ${button.entity.functionalID} is indeed a model ? It will be converted into a draft model.`}
                                button={{
                                    cancel: 'Cancel',
                                    confirm: 'Convert to model',
                                }}
                                callback={() => {
                                    convertToModel(button.entity, button.resource)
                                }}
                            />
                        ),
                        modalStyle: { width: 450 },
                    }),
                tooltip: 'id',
                icon: 'database',
                className: 'agree',
            });
            additionalActionButtons.push({
                onClick: (button) =>
                    Modal.open({
                        title: 'Confirm as non-model',
                        content: (
                            <ConfirmModal
                                message={`Do you confirm that ${button.entity.functionalID} is a non-model ? It will be saved in the Non-models list.`}
                                button={{
                                    cancel: 'Cancel',
                                    confirm: 'Confirm as non-model',
                                }}
                                callback={() => {
                                    confirmAsNonModel(button.entity, button.resource)
                                }}
                            />
                        ),
                        modalStyle: { width: 450 },
                    }),
                tooltip: 'Confirm as non-model',
                icon: 'times',
                className: 'disagree',
            });
        }

        if (
            !userHasRoleMRM() &&
            (userHasOwnershipRights(User.getId(), entity) ||
                userHasDeclarerRights(User.getId(), entity) ||
                userHasDeveloperRights(User, entity)) &&
            !entity.expectedStatus &&
            entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT')
        ) {
            if (Environment.getUriParameter('cud') === 'true') {
                Navigation.router.history.push({
                    pathname: Navigation.router.pathname,
                    search: '', //Reset get param
                });
                submitToMRM(
                    resource,
                    entity,
                    entity.modelStatus,
                    ParameterStore('MODEL_STATUS_UNDER_DECLARATION'),
                    resourceEditComponent
                );
            }
            additionalActionButtons.push({
                onClick: (button) =>
                    submitToMRM(
                        resource,
                        button.entity,
                        entity.modelStatus,
                        ParameterStore('MODEL_STATUS_UNDER_DECLARATION'),
                        resourceEditComponent
                    ),
                tooltip: 'Submit to MRM',
                icon: 'arrow-up',
                className: 'agree',
            });
        }
        if (
            userHasRoleSTD() &&
            !userHasRoleMRM() &&
            (userHasOwnershipRights(User.getId(), entity) ||
                userHasDeclarerRights(User.getId(), entity) ||
                userHasDeveloperRights(User, entity)) &&
            entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') &&
            !entity.expectedStatus
        ) {
            let tooltip = 'Submit to MRM';
            let nextStatus = ParameterStore('MODEL_STATUS_ACTIVE');
            if (Environment.getUriParameter('cim') === 'true') {
                Navigation.router.history.push({
                    pathname: Navigation.router.pathname,
                    search: '', //Reset get param
                });
                submitToMRM(resource, entity, entity.modelStatus, nextStatus, resourceEditComponent);
            }
            additionalActionButtons.push({
                onClick: (button) =>
                    submitToMRM(resource, button.entity, entity.modelStatus, nextStatus, resourceEditComponent),
                tooltip: tooltip,
                icon: 'arrow-up',
                className: 'agree',
            });
        }

        return additionalActionButtons;
    },
    header: header,
});
const MODEL_DETAIL = (resource) => ({
    fields: MODEL_DETAIL_FIELDS_DEFAULT,
    onInit: ({ entity, _resource, _context }) => {
        if (entity.coveringMitigationActionsEntities && entity.coveringMitigationActionsEntities.length > 0) {
            entity.mitigationActions = false;
        }
    },
    additionalActionButtons: (entity, resource, resourceDetailComponent, queryParams) => {
        let additionalActionButtons = [];

        if (
            !(
                typeof queryParams !== 'object' ||
                queryParams.get('certification-id') === undefined ||
                queryParams.get('certification-id') === '' ||
                queryParams.get('certification-id') === null
            )
        ) {
            additionalActionButtons.push({
                link: '/resource/my_model_certification_campaigns/' + queryParams.get('certification-id') + '/detail',
                tooltip: 'Go back to certification',
                icon: 'chevron-left',
            });
        }

        if (userHasRoleMRM() && entity.nonModelStatus === ParameterStore('NON_MODEL_STATUS_NON_MODEL_AWAITING')) {
            additionalActionButtons.push({
                onClick: (button) =>
                    Modal.open({
                        title: 'Convert to model',
                        content: (
                            <ConfirmModal
                                message={`Do you confirm that ${button.entity.functionalID} is indeed a model ? It will be converted into a draft model.`}
                                button={{
                                    cancel: 'Cancel',
                                    confirm: 'Convert to model',
                                }}
                                callback={() => {
                                    convertToModel(button.entity, button.resource)
                                }}
                            />
                        ),
                        modalStyle: { width: 450 },
                    }),
                tooltip: 'Convert to model',
                icon: 'database',
                className: 'agree',
            });
            additionalActionButtons.push({
                onClick: (button) =>
                    Modal.open({
                        title: 'Confirm as non-model',
                        content: (
                            <ConfirmModal
                                message={`Do you confirm that ${button.entity.functionalID} is a non-model ? It will be saved in the Non-models list.`}
                                button={{
                                    cancel: 'Cancel',
                                    confirm: 'Confirm as non-model',
                                }}
                                callback={() => {
                                    confirmAsNonModel(button.entity, button.resource)
                                }}
                            />
                        ),
                        modalStyle: { width: 450 },
                    }),
                tooltip: 'Confirm as non-model',
                icon: 'times',
                className: 'disagree',
            });
        }

        /** Boutons pour le processus de retrait du Model */
        let displayButtons = {};
        let tooltips = {
            default: 'Removal',
            [ParameterStore('MODEL_RETIREMENT_STATUS_PROPOSED_LOD1')]: 'Confirm/discard this retirement request',
            [ParameterStore('MODEL_RETIREMENT_STATUS_PROPOSED_MRM')]: 'Confirm/discard this retirement request',
            [ParameterStore('MODEL_RETIREMENT_STATUS_AWAITING_COMMITTEE')]: 'Indicate if the committee confirmed or declined this model retirement',
        };
        if (
            entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE') &&
            ((!entity.retirementStatus && entity.modelStatus !== ParameterStore('MODEL_STATUS_RETIRED')) ||
                entity.retirementStatus === ParameterStore('MODEL_RETIREMENT_STATUS_PROPOSED_LOD1') ||
                entity.retirementStatus === ParameterStore('MODEL_RETIREMENT_STATUS_AWAITING_COMMITTEE') ||
                entity.specificFramework)
        ) {
            if (userHasRoleMRM() && (!entity.retirementStatus || entity.specificFramework)) {
                if (entity.nonModelStatus !== ParameterStore('NON_MODEL_STATUS_NON_MODEL_AWAITING')) {
                    displayButtons.retire = {
                        title: 'Propose the retirement to LoD 1',
                    };
                }
                displayButtons.delete = {};
                if (!entity.nonModelStatus) {
                    displayButtons.reject = {
                        title: 'Conversion to non-model',
                    };
                }
            } else if (userHasRoleMRM()) {
                displayButtons.retire = {
                    title: tooltips[entity.retirementStatus || 'default'],
                    label: tooltips[entity.retirementStatus || 'default'],
                };
                displayButtons.reject = {
                    title: 'Conversion to non-model',
                };
                displayButtons.delete = {};
            }
        }

        if (
            userHasRoleMRM() &&
            entity.nonModel === false &&
            !entity.retirementStatus &&
            entity.modelStatus !== ParameterStore('MODEL_STATUS_ACTIVE')
        ) {
            displayButtons.delete = {};
            displayButtons.reject = {
                title: 'Conversion to non-model',
            };
        }
        if (
            entity.declarer === '/api/users/' + User.getId() &&
            !entity.retirementStatus &&
            entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT')
        ) {
            displayButtons.delete = {};
        }

        if(
            (userHasRoleMRM() || userHasRoleADMIN())
            && !entity.retirementStatus
        ) {
            displayButtons.convert = {
                title: 'Conversion to specific framework',
            };
        }

        if (Object.keys(displayButtons).length > 0) {
            additionalActionButtons.push({
                onClick: (button) =>
                    Modal.open({
                        title: 'Removal',
                        content: (
                            <ModelRemovalModal
                                entityType="model"
                                entity={button.entity}
                                resource={resource}
                                resourceDetailComponent={resourceDetailComponent}
                                displayButtons={{
                                    ...displayButtons,
                                }}
                            />
                        ),
                        modalStyle: { width: '500px' },
                    }),
                tooltip: tooltips[entity.retirementStatus || 'default'],
                icon: 'trash-restore-alt',
                className: 'retire trash',
            });
        }

        if (
            entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE') &&
            entity.nonModelStatus !== ParameterStore('NON_MODEL_STATUS_NON_MODEL_AWAITING') &&
            //!userHasRoleMRM() &&
            userHasOwnershipRights(User.getId(), entity) &&
            (!entity.retirementStatus ||
                entity.retirementStatus === ParameterStore('MODEL_RETIREMENT_STATUS_PROPOSED_MRM'))
        ) {
            let tooltips = {
                default: 'Propose this model retirement',
                [ParameterStore(
                    'MODEL_RETIREMENT_STATUS_PROPOSED_MRM'
                )]: 'Confirm/discard this model retirement request',
            };
            additionalActionButtons.push({
                onClick: (button) =>
                    Modal.open({
                        title: 'Retiring model',
                        content: (
                            <ModelRetirementModal
                                entity={button.entity}
                                resource={resource}
                                resourceDetailComponent={resourceDetailComponent}
                            />
                        ),
                    }),
                tooltip: tooltips[entity.retirementStatus || 'default'],
                icon: 'trash-restore-alt',
                className: 'retire trash',
            });
        }

        /** Bouton pour le clonage */

        if (
            entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE') &&
            (userHasRoleMRM() || userHasOwnershipRights(User.getId(), entity) || userHasDeveloperRights(User, entity) || userIsVal(User, entity))
        ) {
            additionalActionButtons.push({
                onClick: (button) =>
                    Modal.open({
                        title: 'Duplication',
                        content: <CloneModelModal entity={button.entity} resource={resource} />,
                    }),
                tooltip: 'Duplicate',
                icon: 'clone',
                className: 'action',
            });
        }

        if (
            !userHasRoleMRM() &&
            (userHasOwnershipRights(User.getId(), entity) ||
                userHasDeclarerRights(User.getId(), entity) ||
                userHasDeveloperRights(User, entity)) &&
            !entity.expectedStatus &&
            entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT')
        ) {
            //
            additionalActionButtons.push({
                link: '/resource/' + resource.instanceId + '/' + entity.id + '/edit?cud=true', //Redirect to Edit view and trigger the same action button
                tooltip: 'Submit to MRM',
                icon: 'arrow-up',
                className: 'agree',
            });
        }
        if (
            userHasRoleSTD() &&
            !userHasRoleMRM() &&
            (userHasOwnershipRights(User.getId(), entity) ||
                userHasDeclarerRights(User.getId(), entity) ||
                userHasDeveloperRights(User, entity)) &&
            entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') &&
            !entity.expectedStatus
        ) {
            additionalActionButtons.push({
                link: '/resource/' + resource.instanceId + '/' + entity.id + '/edit?cim=true', //Redirect to Edit view and trigger the same action button
                tooltip: 'Submit to MRM',
                icon: 'arrow-up',
                className: 'agree',
            });
        }

        if (userHasRoleMRM()) {
            if (entity.expectedStatus && entity.nonModel === false) {
                let expectedStatus = entity.expectedStatus;
                expectedStatus = expectedStatus.split('/');
                let expectedStatusId = expectedStatus[expectedStatus.length - 1];
                let validateAsStatus = APIResourceStore.resources.parameters.getObservableItem(expectedStatusId);
                additionalActionButtons.push({
                    onClick: (_button) => validateModel(resource, entity, resourceDetailComponent),
                    tooltip: `Validate as ${validateAsStatus.label}`,
                    icon: 'check',
                    className: 'agree',
                });
            }
        }

        return additionalActionButtons;
    },
    header: header,
});

export const ApiResourceDefaultParams = {
    id: 'models',
    name: 'Models',
    icon: 'brain',
    fieldForTitle: 'toString',
    breadcrumbName: 'Model',
    fieldsAclLocation: 'annotations/model'
};

export default class ModelAdmin {
    constructor() {
        this.configure();
    }

    async configure() {
        // Pour s'assurer qu'on puisse tester userHasRoleMRM ensuite :
        await User.restore(true);

        // Models entities

        const userHasRoleMRM = userHasRole('ROLE_MRM');
        const views = [
            {
                instanceId: 'models',
                name: 'Models',
                listFields: MODEL_LIST_FIELDS_DEFAULT,
                insertable: true,
                bulkable: true,
                bulkEditable: userHasRoleMRM,
                bulkDeletable: userHasRoleMRM,
                bulkableNewUse: userHasRoleMRM,
                insertOnInit: ({ entity, _resource, _context }) => {
                    if (User.profile.isMemberOfValidatorTeam) {
                        entity.modelStatus = ParameterStore('MODEL_STATUS_UNDER_DECLARATION');
                        entity.modelValidatorTeams = User.profile.teams;
                    }else if (userHasRoleMRM) {
                        entity.modelStatus = ParameterStore('MODEL_STATUS_DRAFT');
                    }
                },
                aclFields: (entity, routeParams) => {
                    if(!entity.id){
                        if (User.profile.isMemberOfValidatorTeam) {
                            return {
                                modelStatus: ParameterStore('MODEL_STATUS_UNDER_DECLARATION'),
                                modelValidatorTeams: User.profile.teams,
                                declarer: `/api/users/${User.getId()}`,
                            };
                        }
                    }
                    return null;
                }
            },
            {
                instanceId: 'retired_models',
                name: 'Retired models',
                permanentFilters: {
                    'modelStatus[0]': ParameterStore('MODEL_STATUS_RETIRED'),
                },
                listFields: MODEL_LIST_FIELDS_DEFAULT,
                deletable: true,
                bulkEditable: userHasRoleMRM,
                bulkDeletable: userHasRoleMRM,
                bulkableNewUse: userHasRoleMRM,
            },
            {
                instanceId: 'deleted_models',
                name: 'Deleted models',
                permanentFilters: {
                    'modelStatus[0]': ParameterStore('MODEL_STATUS_DELETED'),
                    deleted: true,
                },
                listFields: MODEL_LIST_FIELDS_DEFAULT,
                bulkEditable: userHasRoleMRM,
                bulkableNewUse: userHasRoleMRM,
            },
            {
                instanceId: 'my_models',
                name: 'My models',
                bulkable: true,
                bulkEditable: userHasRoleSTD,
                bulkEditFields: [
                    'modelType',
                    'coreModel',
                    'modelOwnerDelegation',
                    'modelDeveloperTeam',
                    'businessSponsor',
                    'riskCategory',
                    'microRiskCategory',
                    'microRiskSubCategory',
                    'applicationDomainLevel1',
                    'applicationDomainLevel2',
                    'applicationDomainLevel3',
                    'championOrChallenger',
                    'championModels',
                    'inHouseOrVendor',
                    'vendor',
                    'modelNatureStandard',
                    'modelNatureDetailedString',
                    'dataSource',
                    'operationalRiskFlag',
                    'automaticScoreFlag',
                    'defaultPortfolioFlag',
                    'output',
                    'dataContactPointTeam',
                    'mitigationActions',
                    'upStreamNotAvailable',
                    'downStreamNotAvailable',
                ],
                permanentFilters: {
                    modelOwnerOrModelOwnerDelegationOrDeveloperTeam: true,
                },

                listFields: [
                    'functionalID',
                    'initialID',
                    'modelOwner',
                    'modelOwnerDelegation',
                    'lastCertificationDate',
                    'certifierName',
                    'modelOwnerDelegationDenyComment',
                ],

                // Si présent, ce champ ajoute des droits pour les deletable, bulkDeletable, bulkEditable ...
                itemAccessCondition: (entity) =>
                    userHasOwnershipRights(User.getId(), entity) ||
                    userHasDeclarerRights(User.getId(), entity) ||
                    userHasDeveloperRights(User, entity),
                bulkEditValidateRoles: [BusinessRole.IS_OWNER, BusinessRole.IS_DECLARER, BusinessRole.IS_DEVELOPER],
                        bulkableNewUse: true,

                /*buttons: [{
                      label: 'New review',
                      to: '/resource/reviews/add/' + entity.id,
                      icon: 'fa-plus'
                  }]*/
            },
            {
                instanceId: 'my_draft_models',
                name: 'My draft models',
                bulkable: true,
                deletable: true,
                bulkEditable: userHasRoleSTD,
                bulkDeletable: userHasRoleSTD,
                permanentFilters: {
                    my_draft_models: true,
                },
                listFields: [
                    'functionalID',
                    'initialID',
                    'modelOwner',
                    'modelOwnerDelegation',
                    'lastCertificationDate',
                    'certifierName',
                    'modelOwnerDelegationDenyComment',
                ],
                // Si présent, ce champ ajoute des droits pour les deletable, bulkDeletable, bulkEditable ...
                itemAccessCondition: (entity) =>
                    userHasOwnershipRights(User.getId(), entity) ||
                    userHasDeclarerRights(User.getId(), entity) ||
                    userHasDeveloperRights(User, entity),
            bulkEditValidateRoles: [BusinessRole.IS_OWNER, BusinessRole.IS_DECLARER, BusinessRole.IS_DEVELOPER],},
            {
                instanceId: 'declaration_models',
                name: 'My declarations in progress',
                bulkable: true,
                bulkEditable: userHasRoleMRM,
                bulkableNewUse: userHasRoleMRM,
                permanentFilters: {
                    my_declarations_in_progress: true,
                },
                listFields: [
                    'modelID',
                    'name',
                    'modelType',
                    'modelOwner',
                    'modelDeveloperTeam',
                    'riskCategory',
                    'modelStatus',
                ],
                itemAccessCondition: (entity) =>
                    userHasOwnershipRights(User.getId(), entity) ||
                    userHasDeclarerRights(User.getId(), entity) ||
                    userHasDeveloperRights(User, entity),bulkEditValidateRoles: [BusinessRole.IS_OWNER, BusinessRole.IS_DECLARER, BusinessRole.IS_DEVELOPER],
            },
            {
                instanceId: 'models_pending_request',
                name: 'Pending requests',
                bulkable: true,
                bulkEditable: userHasRoleMRM,
                bulkDeletable: userHasRoleMRM,
                bulkableNewUse: userHasRoleMRM,
                permanentFilters: {
                    models_pending_request: true,
                    //currentUserInMrmTeam: 1,
                },
                listFields: [
                    'modelID',
                    'process',
                    'functionalID',
                    'name',
                    'modelType',
                    'declarer',
                    'modelStatus',
                    'expectedStatus',
                    'treeDeterminationAnswers',
                    'treeDeterminationResult',
                ],
            },
            {
                instanceId: 'models_under_declaration',
                name: 'Models under declaration',
                bulkable: true,
                bulkEditable: userHasRoleMRM,
                bulkDeletable: userHasRoleMRM,
                bulkableNewUse: userHasRoleMRM,
                permanentFilters: {
                    'modelStatus[0]': ParameterStore('MODEL_STATUS_UNDER_DECLARATION'),
                },
                listFields: [
                    'modelID',
                    'process',
                    'functionalID',
                    'name',
                    'modelType',
                    'declarer',
                    'modelStatus',
                    'expectedStatus',
                    'treeDeterminationAnswers',
                    'treeDeterminationResult',
                ],
            },

            {
                instanceId: 'models_val',
                name: 'My validation perimeter',
                bulkable: true,
                bulkEditable: true,
                permanentFilters: {
                    models_val: true,
                },
                // Si présent, ce champ ajoute des droits pour les deletable, bulkDeletable, bulkEditable ...
                itemAccessCondition: (entity) => userIsVal(User, entity),
                bulkEditValidateRoles: [BusinessRole.IS_VALIDATOR],listFields: ['modelID', 'process', 'functionalID', 'name', 'modelType', 'declarer'],
            },
            {
                instanceId: 'specific_frameworks',
                name: 'Specific frameworks',
                permanentFilters: {
                    model_specific_framework: true
                },
                listFields: MODEL_LIST_FIELDS_DEFAULT,
                bulkEditable: userHasRoleMRM,
                bulkDeletable: userHasRoleMRM,
                bulkableNewUse: userHasRoleMRM,
                //listTargetDetailResource: "models",
            },
            {
                instanceId: 'non_models',
                name: 'Non-Models',
                icon: 'brain',
                bulkDeletable: userHasRoleMRM,
                permanentFilters: {
                    non_models_confirmed: true,
                },
                listFields: [
                    'modelID',
                    'name',
                    'modelType',
                    'riskCategory',
                    'modelOwner',
                    'modelDeveloperTeam',
                    'nonModelStatus',
                ],
            },
        ];
        views.forEach((view) => {
            const resource = new APIResource({
                ...{
                    instanceId: view.instanceId,
                    name: view.name,
                    aclFields: view.aclFields,
                },
                ...ApiResourceDefaultParams
            });
            resource
                // merging the right received from the api with the ones existing locally
                .setFields(MODEL_FIELDS(resource))
                .setLayout(MODEL_LAYOUT)
                .genListView({
                    fields: view.listFields,
                    skipButtonAccessCheck: true,
                    targetDetailResource: view.listTargetDetailResource || null,
                    additionalActionButtons:
                        userHasRoleMRM || userHasRoleADMIN()
                            ? [
                                  {
                                      label: 'Import',
                                      to: '/import/model',
                                      icon: 'fa-download',
                                      className: 'download',
                                  },
                                  {
                                      label: 'Full export',
                                      to: '/export/model',
                                      icon: 'fa-upload',
                                      className: 'upload',
                                  },
                              ]
                            : [
                                  // On autorise tout le monde à déclencher un export.
                                  {
                                      label: 'Full export',
                                      to: '/export/model',
                                      icon: 'fa-upload',
                                      className: 'upload',
                                  },
                              ],
                    additionalListActionButtons: view.additionalListActionButtons,
                    insertButton: {
                        path: userHasRoleMRM ? '/resource/models/add' : '/resource/models/test',
                        tooltip: 'Declare a new model',
                    },
                    permanentFilters: view.permanentFilters,
                neededFields: ['mrasEntities'],})
                .genEditView(MODEL_EDIT(resource))
                .genDetailView(MODEL_DETAIL(resource))
                .setValidation((entity) => validationByStatus(entity, resource));

            if (view.insertable && !userHasSpecificRole(Role.IG)) {
                resource.genInsertView({
                    menuItem: { title: 'Add' },
                    additionalRoutes: ['/resource/' + resource.instanceId + '/add/:modelId'],
                    routeAccessControl: () => User.profile.isMemberOfValidatorTeam === true
                            || userHasRoleMRM
                            || userHasRole(Role.ADMIN)
                    ,
                    itemAccessCondition: () => User.profile.isMemberOfValidatorTeam === true
                        || userHasRoleMRM
                        || userHasRole(Role.ADMIN),
                    onInit: view.insertOnInit,
                    operationTooltipText: 'Declare a new model',
                    insertButtonAction: ({ _resource, _event }) => {
                        if (!userHasRoleMRM) {
                            Navigation.router.history.push('/resource/models/test');
                        } else {
                            Modal.open({
                                title: 'Declare a new model',
                                content: <ModalDeclareModel />,
                                modalStyle: { width: '420px' },
                            });
                        }
                    },
                    fieldsSaveWhitelist: FIELDS_SAVE_WHITE_LIST,
                });
            }

            if (view.deletable && !userHasSpecificRole(Role.IG)) {
                resource.allowDelete({
                    itemAccessCondition: (entity) =>
                        !!entity.activeVersion &&
                        (view?.itemAccessCondition?.(entity) || userHasMRMRights(User, entity)),
                    component: (entity) => {
                        return (
                            <LogicalDeleteButton entity={entity} className="tooltip-top" resource={resource} />
                        );
                    },
                });
            }

            if (view.bulkable) {
                if (view.bulkEditable) {
                            resource.addBulkAction(BulkEdit, {
                                resource: resource,
                                icon: 'edit',
                                bulkValidateRoles: [...view?.bulkEditValidateRoles || [], BusinessRole.IS_OWNER, BusinessRole.IS_MRMTEAM],
                                itemAccessCondition: (entity) =>
                                    view?.itemAccessCondition?.(entity) ||
                                    userHasOwnershipRights(User.getId(), entity) ||
                                    userHasMRMRights(User, entity),
                                forbiddenAccessMessage: 'Bulk edit unauthorized.',
                                itemAccessConditionEntityFields: [
                                    'id',
                                    'modelID',
                                    'mrmTeams',
                                    'modelOwner',
                                    'modelOwnerDelegation',
                                    'modelOwnerDelegationAccepted',
                                    'modelOwnerTeams',
                                    'modelDevelopperTeam',
                                    'modelValidatorTeams',
                                    'validatorTeams',
                                    'declarer',
                                ],
                                fields: Object.fromEntries(
                                    Object.entries(APIResourceStore.resources.models.fields)
                                        .filter(([k, v]) => {
                                            if (view.bulkEditFields && !view.bulkEditFields.includes(k)) {
                                                return false;
                                            }
                                            return v.bulk && resource.operations.edit.fields.includes(k);
                                        })
                                        .map(([k, v]) => ((v.resourceId = resource.id), [k, v]))
                                ),
                            });
                        }

                        resource.addBulkAction(ModelExport, {
                            icon: 'file-export'
                        });
                if (userHasRoleMRM || userHasRole(Role.ADMIN)) {
                    resource.addBulkAction(ChangeLogExport, {
                        from: 'model',icon: ['fas', 'tasks'],
                            });
                    }

                if (
                    ((userHasRoleMRM || userHasRole(Role.ADMIN)) && view.instanceId === 'models') ||
                    (User.profile.isMemberOfValidatorTeam && view.instanceId === 'models_val')
                ) {
                    resource.addBulkAction(NewReview, {
                        resource: resource,icon: 'search',
                        itemAccessCondition: (entity) =>
                            userHasMRMRights(User, entity) ||
                            (User.profile.isMemberOfValidatorTeam && view.instanceId === 'models_val'),
                        itemAccessConditionEntityFields: [
                            'id',
                            'modelID',
                            'mrmTeams',
                        ],
                        forbiddenAccessMessage:
                            "You can't create a new review in bulk for models belonging to another MRM team.", // pas besoin de message d'erreur pour VAL, le cas n'est pas possible.
                        fields: Object.fromEntries(
                            Object.entries(APIResourceStore.resources.reviews.fields)
                                .filter(([_k, v]) => {
                                    return v.bulkEdit;
                                })
                                .map(([k, v]) => ((v.resourceId = resource.id), [k, v]))
                        ),
                    });
                }


                if (view.bulkableNewUse) {
                    resource.addBulkAction(NewUse, {
                        resource: resource,
                        icon: 'plus-circle',itemAccessCondition: (entity) =>
                            view?.itemAccessCondition?.(entity) ||
                            userHasOwnershipRights(User.getId(), entity) ||
                            userHasMRMRights(User, entity),
                        itemAccessConditionEntityFields: [
                            'id',
                            'modelID',
                            'mrmTeams',
                            'modelOwner',
                            'modelOwnerDelegation',
                            'modelOwnerDelegationAccepted',
                            'modelOwnerTeams',
                            'modelDevelopperTeam',
                            'modelValidatorTeams',
                            'validatorTeams',
                            'declarer',
                        ],
                        forbiddenAccessMessage:
                            "You can't add a new use in bulk to models belonging to another MRM team.",
                        fields: Object.fromEntries(
                            Object.entries(APIResourceStore.resources.model_uses.fields)
                                .filter(
                                    ([k, v]) =>
                                        v.bulk &&
                                        APIResourceStore.resources.model_uses.operations.edit.fields.includes(k)
                                )
                                .map(([k, v]) => ((v.resourceId = 'model_uses'), [k, v]))
                        ),
                    });
                }

                            if (
                            User.profile.isMemberOfValidatorTeam === true ||
                            userHasRoleMRM ||
                        userHasRole(Role.ADMIN)
                        ) {
                            resource.addBulkAction(ValidationFamilyEdit, {
                            resource: resource,
                            icon: 'layer-group'
                            });
                            }

                            /*
                            // DO not delete, commented because of possible conflicts with certifications campaigns.    .addBulkAction(BulkAddCertifications,{
                            fields: {
                                note: { title: 'Note' },
                        },
                    })
                */

                if (view.bulkDeletable) {
                    resource.addBulkAction(BulkDelete, {
                        resource,
                        icon: 'trash-alt',itemAccessCondition: (entity) =>
                            view?.itemAccessCondition?.(entity) || userHasMRMRights(User, entity),
                        forbiddenAccessMessage: "You can't delete models belonging to another MRM team.",
                        entityType: 'model',
                    });
                }
            }
        });
        // Root.rootComponent.forceUpdate();
    }
}

const styles = {
    actionLink: {
        marginLeft: 5,
    },
    customActionButton: {
        marginLeft: 5,
        color: 'white',
    },
};
