import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Modal from "../../Services/Modal";
import { LogicalDeleteForm } from "../Forms/LogicalDeleteForm/LogicalDeleteForm";
import FormControl from "@material-ui/core/FormControl";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {CircularProgress} from "@material-ui/core";
import {DatePicker} from "../Forms/DatePicker/DatePicker";
import {DisplayTextField} from "../Display/DisplayTextField/DisplayTextField";
import Alert from "../../Services/Alert";
import {userHasValidatorRights, userHasValidatorTeamRights} from "../../Store/ParameterStore";
import User from "../../Services/User/User";
import Http from "../../Services/Http";

export class PlannedDocumentationDeliveryDateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plannedDocumentationDeliveryDate: null,
        };
    }

    componentDidMount() {
        this.state.plannedDocumentationDeliveryDate = this.props.entity.plannedDocumentationDeliveryDate;
    }

    save() {
        if (
            !this.state.plannedDocumentationDeliveryDate
        ) {
            Alert.show({
                message: "Confirm the date: Planned documentation delivery date should be filled.",
                type: "danger"
            });
            return;
        }

        this.setState({progress: true});

        let approvedBy = 'Lod1';
        if (userHasValidatorTeamRights(User.profile.teams, this.props.entity)) {
            approvedBy = 'Lod2';
        }
        Http.post("review/planned-documentation", {
            reviewId: this.props.entity.id,
            plannedDocumentationDeliveryDate: this.state.plannedDocumentationDeliveryDate,
            approvedBy: approvedBy,
        }).then(() => {
            this.props.resource.getItem(this.props.entity.id, true).then(() => {
                Modal.close();
                this.setState({progress: false});
            });
        }).catch(err => {
            console.error(err);
            Modal.close();
            this.setState({progress: false});
        });
    }

    render() {
        return (
            <div className={"logical-delete-form"}>
                <DatePicker
                    label={'Planned documentation delivery date'}
                    value={this.props.entity.plannedDocumentationDeliveryDate ?? this.props.entity.plannedDocumentationDeliveryDate}
                    onChange={(event) => this.setState({ plannedDocumentationDeliveryDate: event })}
                    required={true}
                />
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}
                >
                    { this.state.progress ?
                        <Button
                            style={{ marginTop: 10 }}
                            variant="contained"
                            color="secondary"
                            className="save button-general"
                        >
                            <CircularProgress size={20} />
                        </Button>
                        :
                        <Button
                            style={{ marginTop: 10 }}
                            variant="contained"
                            color="secondary"
                            className="save button-general"
                            onClick={() => {
                                this.save();
                            }}
                        >
                            Confirm the date
                        </Button>
                    }
                </div>
            </div>
        );
    }
}

let styles = {
    actionLink: {
        marginLeft: 5
    }
};
