import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { APIResource } from '../../Services/APIResource/APIResource';
import { OverviewPanel } from './Display/OverviewPanel';
import { OverviewDimensions } from './Display/OverviewDimensions';
import Button from '@material-ui/core/Button';
import { getIdFromIri } from '../../Services/utils';
import { Paper } from '@material-ui/core';
import MRA, { getUserRole, canEditByProcess, MRA_STATUS, MRA_PROCESS, MRA_SCORES } from '../../Services/MRA';
import { getParamBySystemId, getParamByIri, userHasRoleMRM, userIsLod2 } from '../../Store/ParameterStore';
import Http from '../../Services/Http';
import Alert from '../../Services/Alert';
import Modal from '../../Services/Modal';
import { Header } from '../Header/Header';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import AppBarStore from '../../Store/AppBarStore';
import { withTranslation, useTranslation } from 'react-i18next';
import UsaFlagImage from '../../Styles/Assets/img/usa.png';
import FranceFlagImage from '../../Styles/Assets/img/france.png';
import { EntityExportForm } from '../Export/EntityExport';
import Navigation from '../../Services/Navigation';
import User from '../../Services/User/User';

const EditionButtons = ({
    noConflicts,
    userRole,
    mraId,
    mraProcess,
    show,
    mraStatus,
    mraModel,
    nextStatus,
    onSave,
    onDelete,
    onSubmit,
    loadingSave,
    loadingSubmit,
    lod1Submit,
    lod2Submit,
}) => {
    const { i18n } = useTranslation();

    let showNextStep = false;
    let showNextStepText = 'Next Step';
    let showNextStepIcon = 'fa fa-arrow-up';
    if (userRole === 'LoD1') {
        showNextStepText = 'Submit to LoD2';
    } else if (
        mraStatus.systemId === MRA_STATUS.ON_GOING &&
        mraProcess.systemId === MRA_PROCESS.LOD1_LOD2 &&
        !lod1Submit &&
        lod2Submit
    ) {
        // LOD2 can forward to AWAITING COMMITTEE status if LOD1 hasn't submitted
        // its scores
        showNextStepText = 'Next Step (only if LoD1 did not submit their MRA)';
        showNextStepIcon = 'fa fa-angle-double-right';
    }

    let showSave = true;
    let showLod1Edit = false;
    if (userRole === 'LoD1' && noConflicts) {
        showNextStep = true;
    }

    if (userRole === 'LoD1' && mraStatus && mraStatus.systemId === MRA_STATUS.ON_GOING && mraProcess) {
        showNextStep = true;
        showSave = false;
    }

    if (userRole === 'LoD2') {
        showNextStep = true;
    }

    if (
        userRole === 'LoD1' &&
        mraStatus &&
        mraProcess &&
        canEditByProcess(userRole, mraStatus.systemId, mraProcess.systemId)
    ) {
        showLod1Edit = true;
        if (mraStatus.systemId === MRA_STATUS.CONFLICT && mraProcess.systemId === MRA_PROCESS.LOD1_LOD2) {
            showLod1Edit = false;
        }
        if (mraStatus.systemId === MRA_STATUS.ON_GOING && mraProcess.systemId === MRA_PROCESS.LOD1_LOD2) {
            showSave = false;
            showNextStep = false;
        }
    }

    const changeLang = () => {
        const currentLang = i18n.language;
        if (currentLang === 'en') {
            i18n.changeLanguage('fr');
        } else {
            i18n.changeLanguage('en');
        }
    };

    if (show) {
        return (
            <React.Fragment>
                <Button variant="contained" className="tooltip tooltip-top button-table" onClick={changeLang}>
                    <span style={{ height: '13px', fontSize: '22px', lineHeight: '0.6' }}>
                        {i18n.language === 'en' ? (
                            <img src={UsaFlagImage} style={{ width: '24px', marginTop: '-5px' }} />
                        ) : (
                            <img src={FranceFlagImage} style={{ width: '24px', marginTop: '-5px' }} />
                        )}
                    </span>
                    <span className="tooltiptext" style={{ fontSize: '14px' }}>
                        Change language
                    </span>
                </Button>
                {showSave && (
                    <Button
                        variant="contained"
                        color="primary"
                        style={styles.saveButton}
                        onClick={onSave}
                        disabled={loadingSave}
                        className="tooltip tooltip-top"
                    >
                        <i
                            className={loadingSave ? 'fa fa-circle-notch text-primary fa-rotate fa-spin' : 'fa fa-save'}
                        ></i>
                        <span className="tooltiptext">Save</span>
                    </Button>
                )}
                {showLod1Edit && (
                    <Link to={`/resource/mras/${mraId}/update`}>
                        <Button
                            variant="contained"
                            color="primary"
                            className="button-edit warning tooltip"
                            style={{ marginLeft: 4 }}
                        >
                            <i
                                className={
                                    loadingSave ? 'fa fa-circle-notch text-primary fa-rotate fa-spin' : 'fa fa-edit'
                                }
                            ></i>
                        </Button>
                    </Link>
                )}
                {showNextStep && (
                    <Button
                        variant="contained"
                        color="secondary"
                        style={styles.submitButton}
                        onClick={onSubmit}
                        disabled={loadingSubmit}
                        className="tooltip tooltip-top"
                    >
                        <i
                            className={
                                loadingSubmit ? 'fa fa-circle-notch text-primary fa-rotate fa-spin' : showNextStepIcon
                            }
                        ></i>
                        <span className="tooltiptext">{showNextStepText}</span>
                    </Button>
                )}
            </React.Fragment>
        );
    }

    return null;
};

const ProcessHeader = (props) => {
    const { mraStatus, mraProcess, userRole, lod1Submit, lod2Submit } = props;

    if (mraProcess.systemId !== MRA_PROCESS.LOD1_LOD2 || mraStatus.systemId === MRA_STATUS.VALIDATED) return null;

    let message = ' ';

    if (userRole === 'LoD1') {
        if (!lod1Submit && !lod2Submit) {
            message =
                'You can edit and complete your scores by clicking on the yellow button below. Please submit your completed scores to LoD2 by clicking on the green button below.';
        } else if (!lod1Submit && lod2Submit) {
            message =
                'LoD2 is waiting for your completed scores. You can edit and complete your scores by clicking on the yellow button below. Please submit your completed scores to LoD2 by clicking on the green button below.';
        } else if (lod1Submit && !lod2Submit) {
            message = 'LoD2 is currently working on the MRA. You will be notified when LoD2 submits its scores.';
        } else if (lod1Submit && lod2Submit && mraStatus.systemId === MRA_STATUS.CONFLICT) {
            message = 'LoD2 has submitted scores that do not match yours. You can update your scores if relevant.';
        }
    } else if (userRole === 'LoD2') {
        if (!lod1Submit && !lod2Submit) {
            message =
                'Please complete LoD2 score and score justification for every MRA subdimension below. You can then submit your scores by clicking on the green button below before the Committee. LoD1 is currently working on the MRA, its scores may be indicated in the LoD1 score column if applicable.';
        } else if (!lod1Submit && lod2Submit) {
            message = 'LoD1 is currently working on the MRA. You will be notified when LoD1 submits its scores.';
        } else if (lod1Submit && !lod2Submit) {
            message =
                'Please complete LoD2 score and score justification for every MRA subdimension below. You can then submit your scores by clicking on the green button below before the Committee. LoD1 already submitted its scores indicated in the LoD1 score column.';
        }
        if (mraStatus.systemId === MRA_STATUS.CONFLICT) {
            message =
                'MRA scores do not match between LoD1 and LoD2. LoD1 has been invited to update its scores. Please confirm LoD2 scores to be presented to the Committee by clicking on the green button below.';
        }
        if (mraStatus.systemId === MRA_STATUS.FINALIZED) {
            message =
                'Please confirm score and indicate score justifications validated by the Committee. You can then click on the green button below to close this MRA process.';
        }
    }

    return <Header key="message" text={message} />;
};

export const Detail = observer(
    class Detail extends Component {
        constructor(props) {
            super(props);

            this.state = {
                mra: null,
                score: 0,
                maxScore: 0,
                isLoadinMraScores: true,
                mraProcess: null,
                mraStatus: null,
                nextStatus: null,
                followingNextStatus: null,
                dimensions: [],
                subdimensions: [],
                loadingSave: false,
                loadingSubmit: false,
                detachLoading: false,
                mraGroupLoading: false,
                mraScores: [],
                syncMras: [],
                assignedValidator: null,
                validatorUsers: [],
            };

            this.indexMraScores = {}; // for performance
            this.reindexScores = this.reindexScores.bind(this);

            this.mraScores = null;
            this.mraResource = new APIResource({
                id: 'mras',
                name: 'MRA',
                breadcrumbName: 'Mra',
            });
            this.modelResource = new APIResource({
                id: 'models',
                name: 'Model',
            });
            this.mraDimensionResource = new APIResource({
                id: 'mra_dimensions',
                name: 'MRA Dimensions',
            });
            this.mraSubdimensionResource = new APIResource({
                id: 'mra_subdimensions',
                name: 'Subdimensions',
            });
            this.parameterResource = new APIResource({
                id: 'parameters',
                name: 'Parameters',
            });
            this.userResource = new APIResource({
                instanceId: 'users_all',
            });
            this.mraGroupResource = new APIResource({ id: 'mra_groups', name: 'MraGroup' });

            this.scopeResource = new APIResource({
                id: 'scopes',
                name: 'Scopes',
            });
            this.reviewResource = new APIResource({
                id: 'reviews',
                name: 'Review',
            });
            this.onSaveMra = this.onSaveMra.bind(this);
            this.onDeleteMra = this.onDeleteMra.bind(this);
            this.onChangeMraScore = this.onChangeMraScore.bind(this);
            this.onSubmitMra = this.onSubmitMra.bind(this);
            this.isMraValid = this.isMraValid.bind(this);
            this.jumpToCompleteIfNoConflicts = this.jumpToCompleteIfNoConflicts.bind(this);
            this.onDetach = this.onDetach.bind(this);
            this.onDetachConfirm = this.onDetachConfirm.bind(this);
            this.onStatusChange = this.onStatusChange.bind(this);
        }

        componentDidMount() {
            this.loadMra();
        }

        componentDidUpdate(prevProps) {
            // change mra from the MRA Context block
            if (prevProps.match.params.id !== this.props.match.params.id) {
                this.loadMra();
            }
        }

        replacePathByItem(dimensions, path, item) {
            dimensions.splice(dimensions.indexOf(path), 1, item);
            return dimensions;
        }

        generateScoreKey(score) {
            let subdim = score.mraSubdimension;
            if (typeof subdim === 'object') {
                subdim = subdim['@id'];
            }
            return `${score.type}#${subdim}`;
        }

        reindexScores(mra) {
            const { mraScores } = mra;
            if (mraScores) {
                const indexScores = {};
                mraScores.forEach((sc) => {
                    const scoreKey = this.generateScoreKey(sc);
                    indexScores[scoreKey] = sc;
                });
                this.indexMraScores = indexScores;
                this.setState({
                    mraScores: [...mraScores],
                });
            }
        }

        loadMraSubResources(mra) {
            this.reindexScores(mra);

            this.mraDimensionResource
                .apiGetCollection({
                    page: 1,
                    rowsPerPage: 10000,
                    filters: {
                        dimension: getIdFromIri(mra.dimension),
                    },
                })
                .then((dimensions) => {
                    this.setState({ dimensions });
                });

            this.mraSubdimensionResource
                .apiGetCollection({
                    page: 1,
                    rowsPerPage: 10000,
                })
                .then((subdimensions) => {
                    this.setState({ subdimensions });
                });

            if (mra.criticalDimensions) {
                mra.criticalDimensions.forEach((path) => {
                    if (typeof path === 'string') {
                        this.mraDimensionResource.getItemFromResourcePath(path).then((dim) => {
                            this.setState((prevState) => ({
                                ...prevState,
                                mra: {
                                    ...prevState.mra,
                                    criticalDimensions: this.replacePathByItem(
                                        prevState.mra.criticalDimensions,
                                        path,
                                        dim
                                    ),
                                },
                            }));
                        });
                    }
                });
            }

            this.modelResource.getItemFromResourcePath(mra.model).then((m) => {
                this.setState({ mraModel: m });
                this.setState({ userRole: getUserRole(m) });

                const review = m.reviewsEntities.find((review) => {
                    return review.reviewStatusString === 'Open';
                });

                if (m.modelValidatorTeams) {
                    m.modelValidatorTeams.forEach((team) => {
                        this.scopeResource.getItem(getIdFromIri(team), true).then((scope) => {
                            const usersIds = scope.users?.map((userIri) => getIdFromIri(userIri));
                            this.setState({ validatorUsers: usersIds ? usersIds : [] });
                        });
                    });
                }

                if (review) {
                    // get assignated validator
                    this.reviewResource.getItem(review.id).then((res) => {
                        this.setState({ review: res });
                        if (res.assignedValidator) {
                            this.setState({ assignedValidator: getParamByIri(res.assignedValidator) });
                        }
                    });
                }
            });

            Http.get('mras/' + this.props.match.params.id + '/next_status').then((data) => {
                let systemId = data['hydra:member'][this.props.match.params.id]['nextStatusSystemId'];
                const followingNextStatusSystemId =
                    data['hydra:member'][this.props.match.params.id]['followingNextStatusSystemId'];
                this.setState({
                    nextStatus: getParamBySystemId(systemId),
                    followingNextStatus: getParamBySystemId(followingNextStatusSystemId),
                });
            });

            if (mra.versionAuthor) {
                this.userResource.getItemFromResourcePath(mra.versionAuthor).then((user) => {
                    this.setState((prevState) => ({
                        ...prevState,
                        mra: { ...prevState.mra, versionAuthor: user },
                    }));
                });
            }
            if (mra.process) {
                this.parameterResource
                    .getItemFromResourcePath(mra.process)
                    .then((p) => this.setState({ mraProcess: p }));
            }
            if (mra.status) {
                this.parameterResource.getItemFromResourcePath(mra.status).then((s) => {
                    this.setState({ mraStatus: s });
                });
            }

            if (mra.group) {
                this.setState({ mraGroupLoading: true });
                this.mraGroupResource.getItemFromResourcePath(mra.group, true).then((group) => {
                    const promises = group.mras.map(async (mraIRI) => {
                        const mraId = getIdFromIri(mraIRI);
                        if (mraId !== mra.id) {
                            // resource not fetch already
                            const otherMra = await this.mraResource.getItem(mraId);
                            const syncModel = await this.modelResource.getItemFromResourcePath(otherMra.model);
                            return {
                                ...otherMra,
                                functionalID: syncModel.functionalID,
                            };
                        }

                        const syncModel = await this.modelResource.getItemFromResourcePath(mra.model);
                        return {
                            ...mra,
                            functionalID: syncModel.functionalID,
                        };
                    });

                    Promise.all(promises).then((mras) => {
                        this.setState({ mraGroupLoading: false });
                        this.setState({ syncMras: mras });
                    });
                });
            }
        }

        loadMra() {
            this.mraResource.getItem(this.props.match.params.id).then((mra) => {
                AppBarStore.title = (
                    <Breadcrumb
                        entity={mra}
                        resource={this.mraResource}
                        relationalProperty={'model'}
                        resourcePath={'models'}
                    />
                );

                this.setState({ mra: mra });

                this.loadMraSubResources(mra);
            });
        }

        async onDeleteMra() {
            MRA.delete(this.state.mra).then(() => {
                Navigation.router.history.push(`/resource/models/${this.state.mraModel.id}/detail`);
            });
        }

        async onSaveMra(callback) {
            await this.delay(750);
            this.setState({ loadingSave: true });
            const mraToSave = {
                ...this.state.mra,
                mraScores: this.state.mraScores,
            };

            MRA.simpleSave(mraToSave).then((res) => {
                const { success, payload } = res;
                this.setState({ loadingSave: false });
                if (!success) {
                    Alert.show({
                        message: 'An error occurred saving this MRA.',
                    });
                } else {
                    Alert.show({
                        message: 'This MRA has been successfully saved.',
                    });

                    if (callback) {
                        callback();
                    }
                    // refresh state
                    this.setState({ mra: payload });
                    this.loadMraSubResources(payload);
                }
            });
        }

        isMraValid() {
            let scoreTypeToCheck = MRA_SCORES.LOD2_RESIDUAL;
            if (
                this.state.mraProcess.systemId === MRA_PROCESS.LOD1_LOD2 &&
                this.state.mraStatus.systemId === MRA_STATUS.FINALIZED
            ) {
                scoreTypeToCheck = MRA_SCORES.COMMITTEE_RESIDUAL;
            }
            if (
                (this.state.mraProcess.systemId === MRA_PROCESS.LOD1_LOD2 ||
                    this.state.mraProcess.systemId === MRA_PROCESS.LOD1) &&
                (this.state.mraStatus.systemId === MRA_STATUS.CONFLICT ||
                    this.state.mraStatus.systemId === MRA_STATUS.ON_GOING) &&
                this.state.userRole === 'LoD1'
            ) {
                scoreTypeToCheck = MRA_SCORES.LOD1_RESIDUAL;
            }

            const scoreWithValidations = this.state.mraScores
                .filter((mraScore) => [scoreTypeToCheck].includes(getParamByIri(mraScore.type).systemId))
                .map((mraScore) => {
                    const valid = MRA.isMraScoreValid(mraScore, this.state.mraProcess.systemId);
                    return {
                        valid,
                        mraScore,
                    };
                });

            const hasError = scoreWithValidations.find((validation) => !validation.valid);

            if (!hasError) {
                //check that we have all the scores for every subdimensions
                const numberOfScoresExpected = this.state.dimensions.reduce((numberOfScoresExpected, dimension) => {
                    numberOfScoresExpected += dimension.mraSubdimensions.length;
                    return numberOfScoresExpected;
                }, 0);

                const lod1ResidualScores = this.state.mraScores.filter((mraScore) =>
                    [MRA_SCORES.LOD1_RESIDUAL].includes(getParamByIri(mraScore.type).systemId)
                );
                const lod2ResidualScores = this.state.mraScores.filter((mraScore) =>
                    [MRA_SCORES.LOD2_RESIDUAL].includes(getParamByIri(mraScore.type).systemId)
                );
                const committeeScores = this.state.mraScores.filter((mraScore) =>
                    [MRA_SCORES.COMMITTEE_RESIDUAL].includes(getParamByIri(mraScore.type).systemId)
                );

                if (this.state.mraStatus.systemId === MRA_STATUS.FINALIZED) {
                    if (committeeScores.length < numberOfScoresExpected) {
                        return {
                            valid: false,
                            scoreWithValidations: committeeScores,
                        };
                    }
                } else if (
                    (this.state.mraStatus.systemId === MRA_STATUS.CONFLICT ||
                        this.state.mraStatus.systemId === MRA_STATUS.ON_GOING) &&
                    this.state.userRole === 'LoD1'
                ) {
                    if (lod1ResidualScores.length < numberOfScoresExpected) {
                        return {
                            valid: false,
                            scoreWithValidations: lod1ResidualScores,
                        };
                    }
                } else {
                    if (lod2ResidualScores.length < numberOfScoresExpected) {
                        return {
                            valid: false,
                            scoreWithValidations: lod2ResidualScores,
                        };
                    }
                }
            }

            return {
                valid: hasError === undefined,
                scoreWithValidations,
            };
        }

        sendLod2NotificationOnConflict(toUser) {
            let content = `MRA in conflict: new LOD1 scores have been submitted for review`;
            if (this.state.review) {
                content = `MRA in conflict: new LOD1 scores have been submitted for review: ${this.state.review.title} (id: ${this.state.review.id})`;
            }
            Http.post('notifications/new', {
                toUser,
                content,
                link: `/resource/mras/${this.state.mra.id}/detail`,
                byEmail: true,
            });
        }

        sendLod2NotificationOnResolution(toUser) {
            const content = `MRA : LoD1 submitted its scores that match with LoD2 scores. The MRA ${this.state.mra.id} is now in Awaiting Committee status.`;
            Http.post('notifications/new', {
                toUser,
                content,
                link: `/resource/mras/${this.state.mra.id}/detail`,
                byEmail: true,
            });
        }

        async jumpToCompleteIfNoConflicts() {
            // if we are in LOD1_LOD2 process and the next status is conflict
            // then check if we can directly jump to complete status
            return new Promise((resolve) => {
                if (
                    (this.state.nextStatus.systemId === MRA_STATUS.CONFLICT &&
                        this.state.mraProcess.systemId === MRA_PROCESS.LOD1_LOD2) ||
                    this.state.mraStatus.systemId === MRA_STATUS.CONFLICT
                ) {
                    // if all scores are the same
                    // jump directly to MRA_STATUS.COMPLETE
                    if (MRA.areScoresMatching(this.state.mraScores)) {
                        // ok to go to next status if coming from conflict
                        if (this.state.mraStatus.systemId === MRA_STATUS.CONFLICT) {
                            resolve(this.state.nextStatus);
                        }

                        resolve(this.state.followingNextStatus);
                    } else {
                        // can't submit to next step if scores are not the same
                        if (this.state.mraStatus.systemId === MRA_STATUS.CONFLICT) {
                            Modal.open({
                                title: 'The scores between LoD 1 and LoD 2 do not match.',
                                onClose: () => resolve(this.state.mraStatus),
                                onBackdropClick: () => resolve(this.state.mraStatus),
                                content: (
                                    <div>
                                        <div style={{ fontSize: '20px', marginTop: '2em', marginBottom: '1em' }}>
                                            Do you want to fill the committee scores nevertheless ?
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{ marginRight: '10px' }}
                                                onClick={() => {
                                                    resolve(this.state.nextStatus);
                                                    Modal.close();
                                                }}
                                            >
                                                Yes, I want to fill the committee scores
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => {
                                                    resolve(this.state.mraStatus);
                                                    Modal.close();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                ),
                            });
                        } else {
                            if (
                                this.state.mraStatus.systemId === MRA_STATUS.ON_GOING &&
                                !this.state.mra.lod1Submit &&
                                this.state.mra.lod2Submit
                            ) {
                                Modal.open({
                                    title: 'Confirm non-receipt or non-finalization of LoD1 scores',
                                    onClose: () => resolve(this.state.mraStatus),
                                    onBackdropClick: () => resolve(this.state.mraStatus),
                                    content: (
                                        <div>
                                            <div style={{ fontSize: '20px', marginTop: '2em', marginBottom: '1em' }}>
                                                Please confirm you are going to Committee without LoD1 scores.
                                            </div>
                                            <div style={{ textAlign: 'right' }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ marginRight: '10px' }}
                                                    onClick={() => {
                                                        resolve(this.state.followingNextStatus);
                                                        Modal.close();
                                                    }}
                                                >
                                                    Confirm
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => {
                                                        resolve(this.state.mraStatus);
                                                        Modal.close();
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </div>
                                    ),
                                });
                            } else {
                                resolve(this.state.nextStatus);
                            }
                        }
                    }
                } else {
                    resolve(this.state.nextStatus);
                }
            });
        }

        delay(ms) {
            return new Promise((resolve) => {
                setTimeout(resolve, ms);
            });
        }

        async onSubmitMra() {
            await this.delay(750);

            this.setState({ loadingSubmit: true });
            let mraToSave = {
                ...this.state.mra,
                mraScores: this.state.mraScores,
            };

            const { valid } = this.isMraValid();
            if (!valid) {
                this.setState({ loadingSubmit: false });

                Alert.show({
                    message: `Please enter a score and/or a justification for every subdimensions before submitting.`,
                });

                this.onSaveMra();
                return false;
            }

            const nextStatus = await this.jumpToCompleteIfNoConflicts();

            if (
                this.state.userRole === 'LoD1' &&
                this.state.mraStatus.systemId === MRA_STATUS.CONFLICT &&
                nextStatus.systemId === MRA_STATUS.CONFLICT
            ) {
                this.onSaveMra(() => {
                    if (this.state.assignedValidator) {
                        this.sendLod2NotificationOnConflict(this.state.assignedValidator);
                    }

                    this.state.validatorUsers.forEach((userId) => {
                        this.sendLod2NotificationOnConflict(userId);
                    });
                });
                this.setState({ loadingSubmit: false });
                return false;
            }

            if (nextStatus.systemId !== this.state.mraStatus.systemId) {
                // On lance le passage à l'étape suivante :
                MRA.simpleSubmit(mraToSave, nextStatus).then(async (entity) => {
                    this.setState({ loadingSubmit: false });
                    if (!entity) {
                        Alert.show({
                            message: 'An error occurred submitting this MRA.',
                        });
                    } else {
                        Alert.show({
                            message: 'This MRA has been successfully submitted.',
                        });

                        if (
                            this.state.userRole === 'LoD1' &&
                            this.state.mraStatus.systemId === MRA_STATUS.CONFLICT &&
                            nextStatus.systemId === MRA_STATUS.FINALIZED
                        ) {
                            if (this.state.assignedValidator) {
                                this.sendLod2NotificationOnResolution(this.state.assignedValidator);
                            } else {
                                this.state.validatorUsers.forEach((userId) => {
                                    this.sendLod2NotificationOnResolution(userId);
                                });
                            }
                        }
                        // refresh state
                        this.setState({ mra: entity });
                        this.loadMraSubResources(entity);
                    }
                });
            } else {
                this.setState({ loadingSubmit: false });
            }
        }

        onStatusChange(status) {
            this.setState({
                mra: {
                    ...this.state.mra,
                    status,
                },
            });
        }

        updateIndexMraScores(score) {
            const scoreKey = this.generateScoreKey(score);
            const existingEntry = this.indexMraScores[scoreKey];
            if (existingEntry) {
                this.indexMraScores[scoreKey] = {
                    ...existingEntry,
                    score: score.score,
                    justification: score.justification,
                };
            } else {
                this.indexMraScores[scoreKey] = score;
            }
        }

        onChangeMraScore(score) {
            this.updateIndexMraScores(score);
            this.setState({
                mraScores: Object.values(this.indexMraScores),
            });
        }

        onDetachConfirm(mra) {
            mra.group = null;
            mra.silent = false;
            this.setState({ detachLoading: true });
            Modal.close();
            MRA.simpleSave(mra).then((entity) => {
                if (!entity) {
                    Alert.show({
                        message: `An error occurred detaching MRA of model ${mra.functionalID}`,
                    });
                } else {
                    Alert.show({
                        message: `MRA of model ${mra.functionalID} successfully detached`,
                    });

                    // mra.group might become null for the current MRA
                    // if that's the case we won't reload
                    // so we need to filter out all MRAs
                    if (mra.id === this.state.mra.id) {
                        this.setState({ syncMras: [] });
                    }

                    // on recharge l'etat de la page détail
                    this.loadMra();
                }
                this.setState({ detachLoading: false });
            });
        }

        onDetach(mra) {
            // if group
            // update mra with a null group
            // if user confirm through a modal
            if (mra.group) {
                Modal.open({
                    title: 'Confirm the model detachment from this MRA',
                    content: (
                        <div>
                            <div style={{ fontSize: '20px', marginTop: '2em', marginBottom: '1em' }}>
                                You are going to detach a model from this MRA and create a separate MRA for this model.
                                There is no possible rollback.
                            </div>
                            <div style={{ fontSize: '20px', marginBottom: '2em' }}>
                                By confirming, we will detach the following model from the group MRA:
                                <div style={{ fontWeight: 'bold' }}>{mra.functionalID}</div>
                            </div>
                            <div style={{ textAlign: 'right' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginRight: '10px' }}
                                    onClick={() => {
                                        this.onDetachConfirm(mra);
                                    }}
                                >
                                    Yes, I want to detach
                                </Button>
                                <Button variant="contained" color="secondary" onClick={() => Modal.close()}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    ),
                });
            }
        }

        navigationButton() {
            const { userRole, mraStatus, mraProcess } = this.state;

            // allow risk assessment edit when
            // LOD2 and process LOD2
            // or LoD2 and process LOD1 + LOD2 + status finalized
            // or LoD1 only and status COMPLETED
            let showEdition = false;

            if (
                mraStatus &&
                mraProcess &&
                (userRole === 'LoD2' ||
                    (userRole === 'LoD1' &&
                        (mraStatus.systemId === MRA_STATUS.CONFLICT ||
                            mraStatus.systemId === MRA_STATUS.ON_GOING ||
                            (mraStatus.systemId === MRA_STATUS.VALIDATED &&
                                mraProcess.systemId === MRA_PROCESS.LOD1)))) &&
                !this.state.mra.insertionFromImport &&
                canEditByProcess(userRole, mraStatus.systemId, mraProcess.systemId)
            ) {
                showEdition = true;
            }

            const noConflicts =
                showEdition &&
                mraStatus.systemId === MRA_STATUS.CONFLICT &&
                MRA.areScoresMatching(this.state.mraScores);

            return (
                <Paper style={styles.nextButton} elevation={5}>
                    {noConflicts && (
                        <div style={styles.noConflicts}>
                            <div>Ready to submit.</div>
                            <div>No conflicts detected.</div>
                        </div>
                    )}
                    {this.state.mraModel && !this.state.mra.insertionFromImport && (
                        <EditionButtons
                            mraStatus={this.state.mraStatus}
                            show={showEdition}
                            userRole={userRole}
                            noConflicts={noConflicts}
                            mraId={this.state.mra.id}
                            mraProcess={this.state.mraProcess}
                            mraModel={this.state.mraModel}
                            nextStatus={this.state.nextStatus}
                            onSave={() => this.onSaveMra()}
                            onDelete={() => this.onDeleteMra()}
                            loadingSave={this.state.loadingSave}
                            loadingSubmit={this.state.loadingSubmit}
                            onSubmit={this.onSubmitMra}
                            lod1Submit={this.state.mra.lod1Submit}
                            lod2Submit={this.state.mra.lod2Submit}
                        />
                    )}

                    {userHasRoleMRM() && !showEdition && (
                        <Button
                            variant="contained"
                            color="primary"
                            style={styles.saveButton}
                            onClick={() => this.onSaveMra()}
                            disabled={this.state.loadingSave}
                            className="tooltip tooltip-top"
                        >
                            <i
                                className={
                                    this.state.loadingSave
                                        ? 'fa fa-circle-notch text-primary fa-rotate fa-spin'
                                        : 'fa fa-save'
                                }
                            ></i>
                            <span className="tooltiptext">Save</span>
                        </Button>
                    )}

                    {(userHasRoleMRM() || userIsLod2(User, this.state.mraModel)) && (
                        <Button
                            variant="contained"
                            color="primary"
                            style={styles.deleteButton}
                            onClick={() => this.onDeleteMra()}
                            disabled={this.state.loadingSave}
                            className="tooltip tooltip-top"
                        >
                            <i className={'fa fa-trash-alt'}></i>
                            <span className="tooltiptext">Delete</span>
                        </Button>
                    )}

                    <Button
                        variant="outlined"
                        color="primary"
                        style={styles.listButton}
                        disabled={!this.state.mra}
                        onClick={() => {
                            Modal.open({
                                title: 'Export MRA',
                                content: (
                                    <EntityExportForm resource="mras" label="Full export" ids={[this.state.mra.id]} />
                                ),
                                modalStyle: { width: '420px' },
                            });
                        }}
                    >
                        Full export
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        style={styles.listButton}
                        disabled={!this.state.mra}
                        onClick={() => {
                            Modal.open({
                                title: 'Export MRA',
                                content: (
                                    <EntityExportForm
                                        resource="mras"
                                        label="Export mra"
                                        ids={[this.state.mra.id]}
                                        context={'small'}
                                    />
                                ),
                                modalStyle: { width: '420px' },
                            });
                        }}
                    >
                        Export
                    </Button>
                    {
                        // On n'affiche pas le lien vers le Model pour un MRA parent.
                        this.state.mra && this.state.mra.childrenMras && this.state.mra.childrenMras.length < 1 && (
                            <Link
                                to={
                                    '/resource/models/' +
                                    (this.state.mra ? getIdFromIri(this.state.mra.model) : 0) +
                                    '/detail?tab=MRAs'
                                }
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={styles.listButton}
                                    disabled={!this.state.mra}
                                >
                                    Back to model
                                </Button>
                            </Link>
                        )
                    }
                    {this.state.mra && this.state.mra.review && (
                        <Link
                            to={
                                '/resource/reviews/' +
                                (this.state.mra ? getIdFromIri(this.state.mra.review) : 0) +
                                '/detail?tab=MRAS'
                            }
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                style={styles.listButton}
                                disabled={!this.state.mra}
                            >
                                Back to review
                            </Button>
                        </Link>
                    )}
                </Paper>
            );
        }

        render() {
            return (
                <div>
                    {this.state.mra &&
                        this.state.mraStatus &&
                        this.state.mraModel &&
                        this.state.mraProcess &&
                        this.state.mra.dimension &&
                        this.state.dimensions &&
                        this.state.dimensions.length &&
                        this.state.mraScores &&
                        this.state.subdimensions &&
                        this.state.subdimensions.length && (
                            <div>
                                <ProcessHeader
                                    mraStatus={this.state.mraStatus}
                                    mraProcess={this.state.mraProcess}
                                    userRole={this.state.userRole}
                                    lod1Submit={this.state.mra.lod1Submit}
                                    lod2Submit={this.state.mra.lod2Submit}
                                />
                                <div style={styles.centeredResult}>
                                    <OverviewPanel
                                        mra={this.state.mra}
                                        mraStatus={this.state.mraStatus}
                                        syncMras={this.state.syncMras}
                                        dimensions={this.state.dimensions}
                                        subdimensions={this.state.subdimensions}
                                        mraProcess={this.state.mraProcess}
                                        mraScores={this.state.mraScores}
                                        mraModel={this.state.mraModel}
                                        onStatusChange={this.onStatusChange}
                                        onDetach={this.onDetach}
                                        detachLoading={this.state.detachLoading}
                                        mraGroupLoading={this.state.mraGroupLoading}
                                    />
                                </div>
                                <OverviewDimensions
                                    mraProcess={this.state.mraProcess}
                                    dimension={this.state.mra.dimension}
                                    userRole={this.state.userRole}
                                    insertionFromImport={this.state.mra.insertionFromImport}
                                    mraScores={this.state.mraScores}
                                    mraStatus={this.state.mraStatus}
                                    onScoreChange={this.onChangeMraScore}
                                    lod1Submit={this.state.mra.lod1Submit}
                                />
                                {this.navigationButton()}
                            </div>
                        )}
                    {(!this.state.mra ||
                        !this.state.mraStatus ||
                        !this.state.mraModel ||
                        !this.state.mraProcess ||
                        !this.state.mraScores ||
                        !this.state.dimensions.length ||
                        !this.state.subdimensions.length) && (
                        <div className="content" style={{ textAlign: 'center', marginTop: '14em' }}>
                            <LoadingIndicator />
                        </div>
                    )}
                </div>
            );
        }
    }
);

const styles = {
    centeredResult: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 0,
        marginBottom: 0,
        width: '90%',
    },
    nextButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(255,255,255,1)',
        padding: 3,
    },
    saveButton: {
        fontSize: 15,
        backgroundColor: '#0dbbb7',
        margin: 3,
    },
    deleteButton: {
        fontSize: 15,
        backgroundColor: 'red',
        margin: 3,
    },
    submitButton: {
        fontSize: 15,
        backgroundColor: '#48A10D',
        margin: 3,
    },
    listButton: {
        fontSize: 10,
        //backgroundColor: '#59237f',
        margin: 3,
    },
    noConflicts: {
        fontSize: 16,
        textAlign: 'center',
        margin: '5px 10px',
        color: 'green',
        fontWeight: 'bold',
    },
};
