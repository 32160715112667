import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import {
    Button,
    Paper,
    FormControlLabel,
    FormGroup,
    RadioGroup,
    Radio,
    Typography,
    TextField,
    Tooltip,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import Http from '../../../Services/Http';
import Alert from '../../../Services/Alert';
import Modal from '../../../Services/Modal';
import User from '../../../Services/User/User';
import Navigation from '../../../Services/Navigation';
import FieldProviderStore from '../../../Services/APIResource/FieldProviders/__FieldProviderStore';
import { APIResource } from '../../../Services/APIResource/APIResource';
import AppBarStore from '../../../Store/AppBarStore';
import ParameterStore, {userHasOwnershipRights, userHasRoleMRM} from '../../../Store/ParameterStore';
import { TableDisplay } from '../../Display/TableDisplay/TableDisplay';
import { IssueAddForm } from '../../Issue/IssueButton';
import String from '../../../Services/String';
import Thread from '../../Display/Thread/Thread';
import Icon from "@material-ui/core/Icon";
import CheckRequirementsDetail from "./CheckRequirementsDetail";
import { CheckRequirementsSimple } from './CheckRequirementsSimple';
import Banner from '../../Display/Banner/Banner';
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import EntityAsyncProvider from "../../../Services/APIResource/FieldProviders/EntityAsyncProvider";
import {getIdFromIri} from "../../../Services/utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ActionButton } from '../../Modal/ActionButton';

const entityHumanReadableNames = {
    'modelUses': 'model use',
    'implementations': 'implementation',
    'coveringMitigationActions': 'mitigation action'
};

class ModelList extends Component {

    getTooltip(relationName) {
        if (!relationName) return 'Missing data';
        if(relationName === 'coveringMitigationActions'){
            return 'Add one mitigation action or indicate that this is not applicable';
        }else if(relationName === 'implementations'){
            return 'At least one implementation is required for a model in production';
        }
        return 'At least one ' + entityHumanReadableNames[relationName] + ' is required';
    }

    renderError(field, value, entity, relationName, resourceName){
        let model = null;
        this.state.modelCertificationCampaign.myModelsToCertifyEntities.forEach((modelToCertify) => {
            if(entity.functionalID === modelToCertify.functionalID){
                model = modelToCertify;
            }
        })
        /**
         * {@see backend ModelCertificationCampaign.getModelsToCertifyEntities().checkRequiredFields for content}
         */
        return <div>
            {
                model && model.checkRequiredFields[relationName].totalErrors > 0 ?
                <Tooltip title={this.getTooltip()} arrow placement="right">
                    <div
                        onClick={() => {
                            Modal.open({
                                title: this.getTooltip(),
                                content: <CheckRequirementsDetail
                                    certificationId={this.state.id}
                                    entities={model.checkRequiredFields[relationName].entities}
                                    resourceName={resourceName}
                                />
                            });
                        }}
                    >
                        <Icon className={"fa fa-times text-danger"} style={styles.iconWithHand}/>
                    </div>
                </Tooltip>
                : ( model
                    && (
                        (
                            relationName === 'modelUses'
                            && model.checkRequiredFields[relationName].totalErrors === 0
                            && model.checkRequiredFields.model.relations
                            && model.checkRequiredFields.model.relations.modelUsesEntities === true
                        )
                        || (
                            relationName === 'implementations'
                            && model.checkRequiredFields[relationName].totalErrors === 0
                            && model.checkRequiredFields.model.relations
                            && model.checkRequiredFields.model.relations.implementationsEntities === true
                        )
                        || (
                            relationName === 'coveringMitigationActions'
                            && model.checkRequiredFields[relationName].totalErrors === 0
                            && model.checkRequiredFields.model.relations
                            && model.checkRequiredFields.model.relations.coveringMitigationActionsEntities === true
                        )
                    )
                    ?
                    <Tooltip title={this.getTooltip(relationName)} arrow placement="right">
                        <div
                            onClick={() => {
                                Modal.open({
                                    title: 'Missing relation',
                                    content: <CheckRequirementsSimple
                                        entity={entity}
                                        certificationId={this.state.id}
                                        relationName={relationName}
                                        message={this.getTooltip(relationName)}
                                    />
                                });
                            }}
                        >
                            <Icon className={"fa fa-times text-danger"} style={styles.iconWithHand}/>
                        </div>
                    </Tooltip>
                    : <Icon className="fa fa-check text-success" /> )
            }
        </div>;
    }

    constructor(props) {
        super(props);
        AppBarStore.title = 'Model certification campaign';
        this.mccResource = new APIResource({
            id: 'model_certification_campaigns',
        });
        this.modelsResource = new APIResource({ id: 'models' });
        this.state = {
            id: null,
            message: null,
            messageStatus: null,
            models: [],
            alreadyCertifiedModels: {},
            modelsWithTroubles: {},
            checkedModels: {},
            canBeCertifiedModels: {},
            init: false,
            saving: false,
            currentTab: 0,
            tabsDefinition: []
        };
        this.allCheckboxChecked.bind(this);
        this.toggleAllCheckboxes.bind(this);

        let fields = [
            {
                label: () => (
                    <div style={{ marginLeft: -10 }}>
                        <Typography fontWeight="fontWeightBold" style={styles.checkboxHeader}>
                            Certify
                        </Typography>
                        <Checkbox
                            checked={this.allCheckboxChecked()}
                            disabled={!this.state.campaignCanBeEdited}
                            onChange={(event, checked) => this.toggleAllCheckboxes(checked)}
                        />
                    </div>
                ),
                field: 'certify',
                type: 'checkbox',
                forceDisplay: true,
                noTooltip: true,
                forMrm: false,
                styles: { paddingLeft: 10 },
                display: (field, value, entity, _props) => {
                    return (
                        <div>
                            <Checkbox
                                checked={this.state.checkedModels[entity['id']] || false}
                                disabled={!this.state.campaignCanBeEdited || !this.userHasOwnershipRights(entity) || !this.state.canBeCertifiedModels[entity['id']]}
                                onChange={(event) => {
                                    const val = event.target.checked;
                                    this.setState({
                                        checkedModels: { ...this.state.checkedModels, [entity.id]: val },
                                    });
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                label: 'Functional ID',
                field: 'toString',
                display: (field, value, entity) => (
                    <Link to={'/resource/models/' + entity.id + '/detail'} target="_blank">
                        <div className="link">
                            <b>{value}</b>
                        </div>
                    </Link>
                ),
            },
            {
                label: 'Name',
                field: 'name',
                forceDisplay: true,
                styles: {
                    paddingLeft: 10,
                    width: 300,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: 'black',
                    textDecoration: 'none',
                },
                display: (field, value, entity) => (
                    <Link to={'/resource/models/' + entity.id + '/detail'} target="_blank">
                        <div className="link">
                            <b>{entity.name}</b>
                        </div>
                    </Link>
                ),
            },
            {
                label: 'Status',
                field: 'readableStatus',
            },
            {
                label: 'Model Owner',
                field: 'modelOwner',
                type: 'user',
                forceDisplay: true,
                params: {
                    resource: 'users',
                    instanceId: 'users_mo',
                    displayField: 'toString',
                    editDisplayField: 'fullNameWithTeam',
                    multi: false,
                    links: false,
                    sortField: 'lastName',
                    endpoints: {
                        getAll: 'users/all-users/mo',
                    },
                },
            },
            {
                label: 'Delegated model owner',
                field: 'modelOwnerDelegation',
                type: 'user',
                forceDisplay: true,
                params: {
                    resource: 'users',
                    instanceId: 'users_mo',
                    displayField: 'toString',
                    editDisplayField: 'fullNameWithTeam',
                    multi: false,
                    links: false,
                    sortField: 'lastName',
                    endpoints: {
                        getAll: 'users/all-users/mo',
                    },
                },
                display: (field, value, entity, props) => {
                    if(entity.modelOwnerDelegationAccepted){
                        props.plainText = true;
                        return EntityAsyncProvider.getDisplayList(field, value, entity, props);
                    }
                    return null;
                },
            },
            {
                label: 'Certified',
                field: 'certified',
            },
            {
                label: 'Last certification date',
                field: 'lastCertificationDate',
                type: 'date',
                forceDisplay: true,
                props: { plainText: true },
            },
            {
                label: 'Last certifier',
                field: 'certifierName',
                forceDisplay: true
            },
            {
                label: 'Model',
                field: 'checkRequiredFields',
                forceDisplay: true,
                noTooltip: true,
                display: (field, value, entity, _props) => {
                    return this.renderError(field, value, entity, 'model', 'models');
                },
                styles: {width: '100px', textAlign: 'center', paddingLeft: 24},
                headerStyles: {textAlign: 'center'}
            },
            {
                label: 'Uses',
                field: 'checkRequiredFields',
                forceDisplay: true,
                noTooltip: true,
                display: (field, value, entity, _props) => {
                    return this.renderError(field, value, entity, 'modelUses', 'model_uses');
                },
                styles: {width: '100px', textAlign: 'center', paddingLeft: 24},
                headerStyles: {textAlign: 'center'}
            },
            {
                label: 'Implementations',
                field: 'checkRequiredFields',
                forceDisplay: true,
                noTooltip: true,
                display: (field, value, entity, _props) => {
                    return this.renderError(field, value, entity, 'implementations', 'implementations');
                },
                styles: {width: '100px', textAlign: 'center', paddingLeft: 24},
                headerStyles: {textAlign: 'center'}
            },
            {
                label: 'Mitigation Actions',
                field: 'checkRequiredFields',
                forceDisplay: true,
                noTooltip: true,
                display: (field, value, entity, _props) => {
                    return this.renderError(field, value, entity, 'coveringMitigationActions', 'mitigation_actions');
                },
                styles: {width: '100px', textAlign: 'center', paddingLeft: 24},
                headerStyles: {textAlign: 'center'}
            },
            {
                label: 'Trouble',
                field: 'issues',
                noTooltip: true,
                forceDisplay: true,
                display: (field, value, entity, { _fieldDefinition }) => {
                    const trouble = this.state.modelsWithTroubles && this.state.modelsWithTroubles[entity.id];

                    return (
                        <div>
                            <div>
                                {trouble && !this.state.checkedModels[entity.id]
                                    ? this.state.modelsWithTroubles[entity.id].description
                                    : null}
                            </div>
                            {trouble && trouble.statusString === 'Closed' ? <hr /> : null}
                            {!trouble || trouble.statusString === 'Closed' ? this.renderActions(entity) : null}
                        </div>
                    );
                },
                styles: {width: '140px', textAlign: 'center', paddingLeft: 24},
                headerStyles: {textAlign: 'center'}
            },
        ];

        if(props.fromMrmList){
            this.tableFields = Object.fromEntries(Object.entries(fields)
                .filter(([_k, v]) => {
                    return v.forMrm === true || v.forMrm === undefined;
                }).map(([k, v]) => ([k, v])));
        }else{
            this.tableFields = Object.fromEntries(Object.entries(fields)
                .filter(([_k, v]) => {
                    return v.forMrm === false || v.forMrm === undefined;
                }).map(([k, v]) => ([k, v])));
        }
    }

    componentDidMount() {
        const id = parseInt(this.props.id || this.props.match.params.id);
        this.setState({ id });
        this.getData(id);
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            const id = parseInt(this.props.id || this.props.match.params.id);
            this.setState({ id });
            this.getData(id);
        }
    }

    checkAccessRights(models) {
        const { isModelOwner } = this.state;
        const modelOnwerdelegates = [];
        models.forEach((model) => {
            if (model['modelOwnerDelegation'] && model['modelOwnerDelegationAccepted']) {
                modelOnwerdelegates.push(model['modelOwnerDelegation']);
            }
        });
        if (!userHasRoleMRM() && !isModelOwner && modelOnwerdelegates.indexOf(`/api/users/${User.getId()}`) === -1 && !this.redirect) {
            Navigation.router.history.push(`/resource/my_model_certification_campaigns/list`);
        }
    }

    allCheckboxChecked() {
        const { checkedModels, models } = this.state;

        let allChecked = true;

        models.forEach((model) => {
            if (!checkedModels[model.id]) {
                allChecked = false;
            }
        });

        return allChecked;
    }

    toggleAllCheckboxes(checked) {
        const { models, checkedModels, canBeCertifiedModels } = this.state;
        let nbOfCheckedModels = 0;
        models.forEach((model) => {
            if(canBeCertifiedModels[model.id]){
                checkedModels[model.id] = checked;
                nbOfCheckedModels++;
            }
        });

        if(nbOfCheckedModels === 0){
            Alert.show({ message: 'Please verify all the requirements', type: "danger" });
        }else{
            Alert.show({ message: 'You checked ' + nbOfCheckedModels + ' of ' + models.length + ' models.', type: "success" });
        }

        this.setState({ checkedModels });
    }

    checkCampaignState() {
        const { alreadyCertifiedModels, modelsWithTroubles, modelCertificationCampaign } = this.state;

        const modelsTreated = Object.keys(modelsWithTroubles).length + Object.keys(alreadyCertifiedModels).length;

        const allModelsTreated = modelsTreated === modelCertificationCampaign.numberOfMyModels;

        this.setState({
            campaignCompleted:
                modelCertificationCampaign &&
                modelCertificationCampaign.completenessOfCertification !== undefined &&
                modelCertificationCampaign.completenessOfCertification !== null,
            allModelsTreated: allModelsTreated,
            init: true,
        });
    }

    getData = (id = null) => {
        this.getCampaign(id || this.state.id).then((models) => this.getModels(models,true));
    };

    getCampaign = (id) => {
        return new Promise((resolve) => {
            this.mccResource.apiGetOne(id, true).then((modelCertificationCampaign) => {
                const isModelOwner = modelCertificationCampaign.modelOwner === `/api/users/${User.getId()}`;
                const isModelOwnerDelegated =
                    modelCertificationCampaign.myModelsToCertifyEntities &&
                    modelCertificationCampaign.myModelsToCertifyEntities.reduce((prev, current) => {
                        return (
                            prev ||
                            (current.modelOwnerDelegation === `/api/users/${User.getId()}` && current.modelOwnerDelegationAccepted)
                        );
                    }, false);
                // redirect to notice page if the campaign was not acknowledged.
                if (modelCertificationCampaign && !modelCertificationCampaign.acknowledgmentOfReceipt && isModelOwner) {
                    this.redirect = true;
                    Navigation.router.history.push(
                        `/resource/my_model_certification_campaigns/${modelCertificationCampaign.id}/notice`
                    );
                    return;
                }
                let canBeCertifiedModels = {};
                modelCertificationCampaign.myModelsToCertifyEntities.forEach((model) => {
                    canBeCertifiedModels[model.id] = !(model.checkRequiredFields['model'].totalErrors > 0
                        || model.checkRequiredFields['modelUses'].totalErrors > 0
                        || model.checkRequiredFields['modelUses'].missingEntities === true
                        || model.checkRequiredFields['implementations'].totalErrors > 0
                        || model.checkRequiredFields['implementations'].missingEntities === true
                        || model.checkRequiredFields['coveringMitigationActions'].totalErrors > 0
                        || model.checkRequiredFields['coveringMitigationActions'].missingEntities === true)
                    ;
                });

                let tabsDefinition = [];
                if(Array.isArray(modelCertificationCampaign.myModelsToCertifyEntities) && modelCertificationCampaign.myModelsToCertifyEntities.length > 0){
                    tabsDefinition.push({
                        apiPath: modelCertificationCampaign.myModelsToCertifyEntities[0]['modelOwner'],
                        name: modelCertificationCampaign.myModelsToCertifyEntities[0]['modelOwnerString']
                    });
                    modelCertificationCampaign.myModelsToCertifyEntities.forEach((model) => {
                        let user = {
                            apiPath: model['modelOwnerDelegation'],
                            name: model['modelOwnerDelegationString']
                        };
                        if(
                            user.apiPath !== ''
                            && tabsDefinition.filter(u => u.name === user.name).length === 0
                        ){
                            //For each missing user, test delegation
                            tabsDefinition.push(user);
                        }
                    })
                }

                this.setState({
                    isModelOwner,
                    isModelOwnerDelegated,
                    modelCertificationCampaign,
                    campaignCanBeEdited: modelCertificationCampaign && modelCertificationCampaign.statusString !== 'Closed' && !this.props.fromMrmList,
                    canBeCertifiedModels,
                    tabsDefinition
                });
                // @todo verify if campaign is still active.
                // @todo verify if user has rights to interact with this campaign.
                resolve(modelCertificationCampaign.myModelsToCertifyEntities);
            });
        });
    };

    getModels = (models, forceReload = false) => {
        let alreadyCertifiedModels = {};
        let checkedModels = {};
        let modelsWithTroubles = {};
        models.forEach((model) => {
            if (model.certificationsEntities && model.certificationsEntities.length) {
                model.certificationsEntities.forEach((certification) => {
                    if (certification.modelCertificationCampaignId === this.state.id) {
                        alreadyCertifiedModels[model.id] = true;
                    }
                });
            }
            if (model.issuesEntities && model.issuesEntities.length) {
                model.issuesEntities.forEach((issue) => {
                    if (issue.modelCertificationCampaignId === this.state.id) {
                        modelsWithTroubles[model.id] = issue;
                    }
                });
            }
            if (alreadyCertifiedModels[model.id] && !modelsWithTroubles[model.id]) {
                checkedModels[model.id] = true;
            }
        });

        this.checkAccessRights(models);
        this.setState({ alreadyCertifiedModels, modelsWithTroubles, checkedModels, models }, () =>
            this.checkCampaignState()
        );
    };

    handleSaveClick() {
        const { models, checkedModels, alreadyCertifiedModels, modelCertificationCampaign } = this.state;

        const idsToCerfify = [];
        const idsToRemove = [];
        const idsChecked = [];

        this.setState({saving: true});

        models.forEach((model) => {
            if (checkedModels[model.id]) {
                idsChecked.push(model.id);
            }
            // do not treat models that are already certified and checked
            if (alreadyCertifiedModels[model.id] && checkedModels[model.id]) {
                return;
            }

            if (checkedModels[model.id]) {
                idsToCerfify.push(model.id);
            }

            // Remove unchecked certifications
            if (alreadyCertifiedModels[model.id] && !checkedModels[model.id]) {
                idsToRemove.push(model.id);
            }
        });

        this.certifyModels(idsToCerfify)
            .then(() => {
                return this.removeModelTroubles(idsChecked);
            })
            .then(() => {
                if (idsToRemove.length) {
                    return this.removeCertificationModels(idsToRemove);
                }
            })
            .then(() => {
                Alert.show({
                    message: `Your certification has been saved. Thank you.`,
                    type: 'success',
                });

                // if many campaigns then we need a for loop that will certify all of them in one go.
                return this.mccResource.apiPut({
                    id: this.state.id,
                    ...modelCertificationCampaign,
                    certifications: undefined,
                    troubles: undefined,
                });
            })
            .then(() => {
                this.setState({saving: false});
                Navigation.router.history.push('/resource/my_model_certification_campaigns/list');
            });
    }

    userHasOwnershipRights = (entity) => {
        return (
            entity &&
            (entity.modelOwner === `/api/users/${User.getId()}` ||
                (entity.modelOwnerDelegation === `/api/users/${User.getId()}` && entity.modelOwnerDelegationAccepted))
        );
    };

    /**
     * Render the buttons at the end of each line
     */
    renderActions = (entity) => {
        if (
            !this.userHasOwnershipRights(entity) ||
            !this.state.campaignCanBeEdited ||
            (this.state.checkedModels && this.state.checkedModels[entity.id])
        ) {
            return '';
        }

        return (
            <Button
                style={{ marginRight: 1 }}
                variant="contained"
                size={"small"}
                color={"secondary"}
                className="button-table tooltip tooltip-left"
                disabled={!this.state.campaignCanBeEdited}
                onClick={() =>
                    Modal.open({
                        title: 'New certification trouble',
                        content: (
                            <IssueAddForm
                                title={'New certification trouble'}
                                type={ParameterStore('TROUBLE_TYPE_CERTIFICATION')}
                                models={[`/api/models/${entity.id}`]}
                                modelCertificationCampaign={this.state.modelCertificationCampaign['@id']}
                                description=""
                                onClose={() => {
                                    this.getData();
                                    let troubledModels = {
                                        ...this.state.modelsWithTroubles,
                                    };
                                    troubledModels[entity.id] = true;
                                    this.setState({
                                        modelsWithTroubles: troubledModels,
                                    });
                                }}
                            />
                        ),
                    })
                }
            >
                New Trouble
                <span className="tooltiptext">
                    {' '}
                    Declare an ownership objection or a problem regarding an information I can not modify.
                </span>
            </Button>
        );
    };

    renderCompletenessOfCertificationSection() {
        const { modelCertificationCampaign, isModelOwner } = this.state;
        if (!isModelOwner) {
            return;
        }
        return (
            <Paper style={styles.footerActions} className="container">
                <FormGroup style={styles.checkboxes} row>
                    All the models of my perimeter are listed:{' '}
                    <b>
                        {modelCertificationCampaign && modelCertificationCampaign.completenessOfCertification
                            ? 'Yes'
                            : 'No'}
                    </b>
                </FormGroup>
            </Paper>
        );
    }

    certifyModels(ids) {
        return Http.post('models/addCertifications', {
            ids: ids,
            note: '',
            modelCertificationCampaign: parseInt(this.state.id),
        });
    }

    removeCertificationModels(ids) {
        return Http.post('models/removeCertifications', {
            ids: ids,
            modelCertificationCampaign: parseInt(this.state.id),
        });
    }

    removeModelTroubles(ids) {
        return Http.post('models/removeTroubles', {
            ids: ids,
            modelCertificationCampaign: parseInt(this.state.id),
        });
    }

    /**
     * Render the buttons at the bottom of the page
     */
    renderActionFooter = () => {
        const { modelCertificationCampaign, campaignCanBeEdited, isModelOwner, isModelOwnerDelegated } = this.state;

        if (
            !campaignCanBeEdited
            // || campaignCompleted
        ) {
            if (isModelOwner) {
                return this.renderCompletenessOfCertificationSection();
            }
            return;
        }

        return (
            <Paper style={styles.footerActions} className="container">
                {isModelOwner && (
                    <FormGroup style={styles.checkboxes} row>
                        <RadioGroup
                            value={`${modelCertificationCampaign.completenessOfCertification}`}
                            onChange={(event) => {
                                modelCertificationCampaign.completenessOfCertification = event.target.value === 'true';
                                this.setState({
                                    modelCertificationCampaign,
                                });
                            }}
                        >
                            <FormControlLabel
                                value={'true'}
                                control={<Radio />}
                                label="All the models of my perimeter are listed"
                            />
                            <FormControlLabel
                                value={'false'}
                                control={<Radio />}
                                label="At least one of the models from my perimeter is not listed"
                            />
                        </RadioGroup>
                    </FormGroup>
                )}

                {isModelOwner && modelCertificationCampaign.completenessOfCertification === false && (
                    <FormGroup>
                        <TextField
                            id="outlined-helperText"
                            label="Exhaustiveness comment"
                            value={modelCertificationCampaign.missingModelComment}
                            variant="outlined"
                            multiline={true}
                            rows={3}
                            style={styles.comment}
                            disabled={!isModelOwner}
                            onChange={(event) => {
                                modelCertificationCampaign.missingModelComment = event.target.value;
                                this.setState({
                                    modelCertificationCampaign,
                                });
                            }}
                        />
                    </FormGroup>
                )}

                <FormGroup>
                    <ActionButton
                        disabled={
                            (!isModelOwner && !isModelOwnerDelegated) ||
                            (modelCertificationCampaign.completenessOfCertification === false &&
                                !modelCertificationCampaign.missingModelComment)
                            || this.state.saving
                        }
                        onClick={this.handleSaveClick.bind(this)}
                        loading={this.state.saving}
                    >
                        Confirm your certification
                    </ActionButton>
                </FormGroup>
            </Paper>
        );
    };

    renderCampaignSummary() {
        const { modelCertificationCampaign } = this.state;
        return [
            { field: 'modelID', title: 'ID' },
            {
                field: 'modelOwner',
                title: 'Model owner',
                type: 'user',
                required: true,
                params: {
                    resource: 'users',
                    instanceId: 'users_mo',
                    displayField: 'toString',
                    editDisplayField: 'fullNameWithTeam',
                    endpoints: {
                        getAll: 'users/all-users/mo',
                    },
                },
            },
            {
                field: 'status',
                title: 'Status',
                type: 'mapped',
                params: {
                    mapping: {
                        0: 'Ongoing',
                        1: 'Closed',
                    },
                },
            },
            {
                field: 'certificationCampaignState',
                title: 'Campaign State',
                type: 'mapped',
                params: {
                    mapping: {
                        0: 'Partial',
                        1: 'Full',
                    },
                },
            },
            { field: 'startDate', title: 'Start date', type: 'date' },
            { field: 'endDate', title: 'End date', type: 'date' },
            {
                field: 'completenessOfCertification',
                title: 'Exhaustiveness',
                type: 'bool',
            },
            {
                field: 'acknowledgmentOfReceipt',
                title: 'Acknowledgement of receipt',
                type: 'bool',
            },
            {
                field: 'numberOfModels',
                title: 'Total number of models',
                type: 'float',
            },
            {
                field: 'numberOfCertifiedModels',
                title: 'Number of certified models',
                type: 'float',
            },
            {
                field: 'numberOfTroubles',
                title: 'Number of troubles',
                type: 'float',
            },
        ].map((fieldDefinition) => {
            return (
                <div key={fieldDefinition.field} className={'detail-field ressource-api-field'}>
                    {FieldProviderStore[fieldDefinition.type || 'default'].getDisplay(
                        fieldDefinition,
                        modelCertificationCampaign[fieldDefinition.field],
                        modelCertificationCampaign,
                        fieldDefinition.props
                    )}
                </div>
            );
        });
    }

    renderTableDisplay(entity){
        let models = this.state.models.filter(m => {
            return entity.myModelsToCertifyEntities.some(mm => `/api/models/${mm['id']}` === m['@id'])
        });
        const myDelegatedModelsToCertify = entity.myModelsToCertifyEntities.filter(m => m.modelOwnerDelegation === `/api/users/${User.getId()}` && m.modelOwnerDelegationAccepted);
        if(
            entity.modelOwner === `/api/users/${User.getId()}`
            || myDelegatedModelsToCertify.length
            || userHasRoleMRM()
        ){
            if(this.state.currentTab === 0){
                return <TableDisplay filter={true} cols={this.tableFields} rows={myDelegatedModelsToCertify.length ? myDelegatedModelsToCertify : entity.myModelsToCertifyEntities} dense={true}></TableDisplay>
            }else{
                let delegatedModels = this.state.models.filter(m => m.modelOwnerDelegation === this.state.tabsDefinition[this.state.currentTab]['apiPath'] && m.modelOwnerDelegationAccepted);
                return <TableDisplay filter={true} cols={this.tableFields} rows={delegatedModels} dense={true}></TableDisplay>
            }
        }else{
            return <TableDisplay filter={true} cols={this.tableFields} rows={models} dense={true}></TableDisplay>
        }
    }

    render() {
        const self = this;
        if (!this.state.init) {
            return <Grid className={'export container'}>
                <div className={"export_progress_container"}>
                    <CircularProgress />
                </div>
            </Grid>
        }

        const { modelCertificationCampaign, campaignCanBeEdited } = this.state;

        let genTabs = function () {

            let tabs = [];
            for (let tabKey in self.state.tabsDefinition) {
                let tabLabel = self.state.tabsDefinition[tabKey]['name'];
                tabs.push(
                    <Tab
                        label={tabLabel}
                        key={tabKey}
                    />
                );
            }
            return tabs;
        };

        return (
            <Grid className={!this.props.fromMrmList && 'container'}>
                {!campaignCanBeEdited && modelCertificationCampaign && !this.props.fromMrmList && (
                    <Paper className="resource-detail ressource-api-box" style={styles.headerBlock}>
                        <h1 className="background-linear-gradient" style={{ color: 'white', paddingLeft: 10 }}>
                            {' '}
                            Campaign summary
                        </h1>
                        <div className="ressource-api-container">
                            <div className="ressource-api-box">{this.renderCampaignSummary()}</div>
                        </div>
                    </Paper>
                )}
                {campaignCanBeEdited && !this.props.fromMrmList && <Banner>
                    Please find below all models that need your review for this certification campaign.
                    <ul style={{listStyleType: "none"}}>
                        <li>
                            <Icon className="fa fa-check text-success" /> means that all required data for the model’s certification is completed. If all the information is accurate, you can now tick the « Certify » box to certify your model.
                        </li>
                        <li>
                            <Icon className="fa fa-times text-danger" /> means that some data is missing for the model’s certification. Please click on the cross button to see and edit the missing data.
                        </li>
                    </ul>
                    <strong>Please do not forget to click on the « Certify » box for each model then click on the "Confirm your certification" button below in order to validate.</strong>
                </Banner>}
                {
                    (
                        modelCertificationCampaign.modelOwner === `/api/users/${User.getId()}`
                        || userHasRoleMRM()
                    ) &&
                    <AppBar position="static" color="default">
                        <Tabs
                            value={this.state.currentTab}
                            onChange={(event, val) => {
                                this.setState({
                                    currentTab: val,
                                    currentTabUser: this.state.tabsDefinition[val]['apiPath'],
                                    currentTabName: this.state.tabsDefinition[val]['name'],
                                });
                            }}
                            className="tabs-menu"
                            indicatorColor="primary"
                            textColor="primary"
                            variant="standard"
                            scrollButtons="auto"
                            key={'resourceDetailTab'}
                        >
                            {genTabs()}
                        </Tabs>
                    </AppBar>
                }
                {this.renderTableDisplay(modelCertificationCampaign)}
                {!this.props.fromMrmList && this.renderActionFooter()}
                {modelCertificationCampaign && !this.props.fromMrmList && (<>
                        <Banner>
                            You can use the below chat to communicate with the MRM team following your certification.
                        </Banner>
                        <Paper style={styles.footerActions} className="container">
                            <Thread
                                entityType="model_certification_campaign"
                                entityId={modelCertificationCampaign.id}
                            ></Thread>
                        </Paper>
                    </>
                )}
                <div className="button-bar-bottom">
                    <Tooltip
                        title={String.nlToBr(`
                        In the Model Risk Management framework, a key quality control of the models inventory is the certification process. It aims at ensuring that all models are registered in the inventory and that the information is up to date.

As Model Owner, you are kindly requested to attest the completeness and the accuracy of the models inventory:
• Confirmation that all the models you own are recorded in the inventory. Otherwise, you must notify MRM of any model you own that is not recorded ;
• Confirmation that the data on your scope of models in the inventory is complete and accurate. Otherwise, you should update any information that may be missing, incomplete or incorrect ;
• Information on any new model under development and/or model to be retired.

If you want to delegate the certification of a model, you may appoint a delegate model owner in the inventory.
                        `)}
                        arrow={true}
                        leaveTouchDelay={300}
                    >
                        <span>
                            <Button variant="contained" className="item valid ">
                                <i className={'fa fa-question'}></i>
                            </Button>
                        </span>
                    </Tooltip>
                </div>
            </Grid>
        );
    }
}
ModelList.propTypes = {
    /** Match les paramètres du Router */
    match: PropTypes.object,
};

const styles = {
    blockHeightStyle: {
        paddingBottom: 5,
        marginBottom: 35,
    },
    headerBlock: {
        margin: 15,
    },
    title: {
        color: 'white',
        padding: 10,
    },
    gridPaper: {
        marginTop: 15,
        marginBottom: 15,
        height: '100%',
    },
    gridBody: {
        padding: 15,
        paddingTop: 0,
        color: 'black',
        fontSize: 18,
        textAlign: 'center',
    },
    mrDetailBtn: {
        margin: 1,
    },
    footerActions: {
        margin: 15,
        marginBottom: 30
    },
    checkboxes: {
        justifyContent: 'center',
    },
    checkboxHeader: {
        color: 'white',
        fontWeight: 'bold'
    },
    comment: {
        minHeight: 100,
        marginTop: 15,
        marginBottom: 15
    },
    iconWithHand: {
        cursor: "pointer",
    }
};

export default observer(ModelList);
