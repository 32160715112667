import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Chip } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next'

const ScoreChip = withStyles(() => ({
    root: {
        marginLeft: '5px',
    },
}))(Chip);
const ScoreTab = withStyles(() => ({
    wrapper: {
        flexDirection: 'row',
    },
}))(Tab);

const Label = ({ score, title }) => (
    <>
        {title}
        {score !== null && score !== undefined && (
            <ScoreChip
                variant="outlined"
                size="small"
                label={score}
                color="primary"
                component="span"
            />
        )}
    </>
);

Label.propTypes = {
    score: PropTypes.number,
    title: PropTypes.string,
};

const MraTabs = (props) => {
    const { dimensions, tabId, tabScores, onChangeTab } = props;

    const { t } = useTranslation();

    return (
        <AppBar position="static" color="default">
            <Tabs
                value={tabId}
                className="tabs-menu"
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                onChange={onChangeTab}
            >
                {dimensions.map((value, index) => (
                    <ScoreTab
                        key={index}
                        label={
                            <Label
                                score={tabScores[index]}
                                title={t(value.title)}
                            ></Label>
                        }
                    />
                ))}
            </Tabs>
        </AppBar>
    );
};
MraTabs.propTypes = {
    dimensions: PropTypes.array,
    tabId: PropTypes.number,
    tabScores: PropTypes.objectOf(PropTypes.number),
    onChangeTab: PropTypes.func,
};

export default MraTabs;
