import React, { Component } from 'react';
import { observer } from 'mobx-react';
import './data-table.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import TablePagination from '@material-ui/core/TablePagination';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { FieldsConfig } from './FieldsConfig';
import AppBar from '@material-ui/core/AppBar/AppBar';
import { Paper, Box } from '@material-ui/core';
import { ExpandMore, ExpandLess, DeleteOutline } from '@material-ui/icons';
import FieldProviderStore from '../../FieldProviders/__FieldProviderStore';
import { hasRightsForOperation, hasRightsForField, renderAdditionalListActionButtons } from '../../Utils';
import $ from 'jquery';
import Navigation from '../../../Navigation';
import Http from "../../../Http";

export const Pagination = observer(
    class Pagination extends Component {
        getPaginationOptions() {
            let paginationOptions = [10, 25, 50, 100, 200];

            if (this.props.resource.totalItems) {
                // On n'inclut plus le nombre total dans la liste des "Rows per page"
                // paginationOptions.push(this.props.resource.totalItems);
                paginationOptions = _.uniq(paginationOptions);
                paginationOptions = paginationOptions.sort((a, b) => a - b);
            }

            return paginationOptions;
        }

        render() {
            if (!this.props.updateCallback) {
                this.props.updateCallback = () => {};
            }
            if (!this.props.resource.totalItems || this.props.resource.totalItems.length < 1) {
                return <div />;
            }
            return (
                <TablePagination
                    rowsPerPageOptions={this.getPaginationOptions()}
                    component="div"
                    count={this.props.resource.totalItems}
                    rowsPerPage={
                        this.props.resource.currentList.rowsPerPage === 'All'
                            ? this.props.resource.totalItems
                            : this.props.resource.currentList.rowsPerPage
                    }
                    page={this.props.resource.currentList.page - 1}
                    onChangePage={this.props.onChangePage}
                    onChangeRowsPerPage={this.props.onChangeRowsPerPage}
                    className="table-pagination"
                />
            );
        }
    }
);

export const DataTable = observer(
    class DataTable extends Component {
        constructor(props) {
            super(props);

            this.onSelectionChange = this.onSelectionChange.bind(this);
            this.onFilterChange = this.onFilterChange.bind(this);
            this.selectAll = this.selectAll.bind(this);

            this.adjustTbodyHeightListner = false;

            this.state = {
                bulkActionValue: -1,
                selection: [],
                selectAll: false,
                remoteConfig: null,
                activeFilters: {},
                isLoadingData: false,
                numberOfAdditionalActions: 0,
            };
            /** @type {{resource: import("../../APIResource").APIResource}} */
            this.props;
        }

        async componentDidMount() {
            Navigation.id = 'datatable-view';
            const config = this.props.resource.getSavedConfig() || {filters: this.props.resource.currentList.filters} || {};
            this.initActiveFilters(config);
            this.refreshApiData({
                context: 'datatable-constructor',
                ...config,
                forceReload: true,
            });
            this.adjustTbodyHeight();
            this.props.resource.reloadSavedConfig();
            this.props.resource.restoreRemoteConfig();
            this.setLoadingData(true);
        }

        componentDidUpdate(_prevProps, _prevState, _snapshot) {
            this.adjustTbodyHeight();
        }

        componentWillUnmount() {
            Navigation.id = '';
        }

        /**
         * 
         * @param {{page: any, rowsPerPage: any, filters: any, order: any, context: any, forceReload: any, saveConfig: any}} options 
         */
        refreshApiData(options = {}) {
            const { page, rowsPerPage, filters, order, context, forceReload } = options;
            this.setLoadingData(true);
            // this is needed to force recalulation of the number of columns
            this.setState({ numberOfAdditionalActions: 0 });
            return this.props.resource
                .apiGetCollection({
                    page: page || this.props.resource.currentList.page,
                    rowsPerPage: rowsPerPage || this.props.resource.currentList.rowsPerPage,
                    filters: filters || this.cleanedActiveFilters(),
                    order: order || this.props.resource.currentList.order,
                    context,
                    forceReload,
                    saveConfig: true,
                    limitProperties: this.props.resource.operations.list._debugNoLimitProperties ? false : true,
                })
                .finally(() => Navigation.id !== '' && this.setLoadingData(false));
        }

        debouncedRefreshApiData = _.debounce(this.refreshApiData, 300);

        setLoadingData = (state) => {
            this.setState({ isLoadingData: !!state });
        };

        /**
         * Charge les filtres actifs à partir de la requête en cours et de la configuration utilisateur.
         * 
         * @param {Object} config, {@see APIResource.saveConfig()} 
         */
        initActiveFilters = async (config) => {
            return new Promise((resolve, _reject) => {
                this.setState(
                    {
                        activeFilters: {...config?.filters, ...this.props.resource.currentList.filters},
                    },
                    resolve
                )   
            });
        };

        cleanedActiveFilters = () => {
            const filters = {};
            for (const i in this.state.activeFilters) {
                if (
                    this.state.activeFilters[i] !== undefined &&
                    this.state.activeFilters[i] !== '' &&
                    this.state.activeFilters[i].length !== 0 &&
                    (!Array.isArray(this.state.activeFilters[i]) || this.state.activeFilters[i].some(v => v !== ''))
                ) {
                    filters[i] = this.state.activeFilters[i];
                }
            }
            return filters;
        };

        adjustTbodyHeight() {
            let adjust = () => {
                let containerHeight = $('#main-content').height();
                let headerHeight = $('#main-content .data-table-container > header').height();
                let theadHeight = $('#main-content .data-table table thead').height();
                if (window.navigator.platform.match(/win/gi) || window.navigator.platform.match(/linux/gi)) {
                    $('.data-table table tbody').height(containerHeight - headerHeight - theadHeight - 15);
                } else {
                    $('.data-table table tbody').height(containerHeight - headerHeight - theadHeight);
                }
            };
            adjust();
            if (!this.adjustTbodyHeightListner) {
                this.adjustTbodyHeightListner = true;
                $(window).resize(adjust);
            }
        }

        getActionColStyle() {
            let actionsCount = 0;
            const { resource } = this.props;

            if (this.props.resource.operations.list.defaultActionButtons) {
                if (resource.operations.detail) {
                    actionsCount++;
                }

                if (resource.operations.edit) {
                    actionsCount++;
                }

                if (resource.operations.delete) {
                    actionsCount++;
                }
            }
            const { numberOfAdditionalActions } = this.state;
            actionsCount += numberOfAdditionalActions || 0;
            const actionColStyles = actionsCount > 0 ? { width: 65 * actionsCount } : {};
            return actionColStyles;
        }

        thead() {
            let tds = [];

            if (Object.keys(this.props.resource.currentList.bulkActions).length > 0) {
                tds.push(
                    <TableCell padding="checkbox" key={-1} className="table-cell-checkbox">
                        <div className="cell-content">
                            <Checkbox
                                indeterminate={false}
                                checked={
                                    this.state.selection.length === this.props.resource.currentList.items.length &&
                                    this.props.resource.currentList.items.length > 0
                                }
                                onChange={this.selectAll}
                                className="input-checkbox-table"
                            />
                        </div>
                    </TableCell>
                );
            }
            const actionColStyles = this.getActionColStyle();
            if (
                this.props.resource.operations.list.defaultActionButtons ||
                this.props.resource.operations.list.additionalListActionButtons
            ) {
                tds.push(
                    <TableCell key={'action_header'} className="actions-header" style={actionColStyles}>
                        <div className="cell-content" style={actionColStyles}>
                            Actions
                        </div>
                    </TableCell>
                );
            }

            let resourceId = this.props.resource.resourceId;

            this.props.resource.currentList.displayFields.forEach((fieldId) => {
                let field = this.props.resource.fields[fieldId];
                let filterComponent = null;

                if (
                    field.displayList &&
                    !field.displayList(this.props.resource.fields[fieldId], null, null, null, this.props.resource)
                ) {
                    return;
                }

                if (!field) {
                    throw new Error('Field not found in DataTable: ' + fieldId);
                }

                if (field.filter) {
                    filterComponent = (
                        <div className="filter-custom">
                            {field.filter(
                                field,
                                (value) => {
                                    this.onFilterChange(fieldId, value, field.params);
                                },
                                this.getFilterValue(field)
                            )}
                        </div>
                    );
                } else {
                    if (field.type && FieldProviderStore[field.type]) {
                        filterComponent = (
                            <div className="filter">
                                {FieldProviderStore[field.type].getFilter({
                                    field,
                                    value: this.getFilterValue(field),
                                    onChange: (value) => {
                                        this.onFilterChange(field?.params?.filterPropertyName ?? fieldId, value, field.params);
                                    },
                                    resourceId
                                })}
                            </div>
                        );
                    } else {
                        filterComponent = (
                            <div className="filter">
                                {FieldProviderStore.default.getFilter({
                                    field,
                                    value: this.getFilterValue(field),
                                    onChange: (value) => {
                                        this.onFilterChange(field?.params?.filterPropertyName ?? fieldId, value, field.params);
                                    },
                                    resourceId
                                })}
                            </div>
                        );
                    }
                }

                tds.push(
                    <TableCell key={fieldId}>
                        <div
                            className={'cell-content ' + fieldId}
                            style={
                                this.props.resource.fields[fieldId].width
                                    ? {
                                          width: this.props.resource.fields[fieldId].width + 'px',
                                      }
                                    : null
                            }
                        >
                            <div style={styles.headerClickable} onClick={() => this.onSortChange(fieldId)}>
                                {this.props.resource.fields[fieldId].title}
                                {this.renderSortIcon(fieldId)}
                            </div>
                            <div className="col-filter">{filterComponent}</div>
                        </div>
                    </TableCell>
                );
            });

            return (
                <TableHead>
                    <TableRow>{tds}</TableRow>
                </TableHead>
            );
        }

        renderNoResults() {
            return (
                <TableBody>
                    <TableRow key={-1}>
                        <TableCell key={1} className="table-cell-checkbox"></TableCell>
                        <TableCell key={2}>
                            <Box textAlign="center" fontWeight="fontWeightLight" fontStyle="italic">
                                There is no result to display
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableBody>
            );
        }

        renderLoadingHeader(isLoadingData) {
            const { resource } = this.props;
            // On décide d'avoir un affichage différent en fonction de si on est en premier affichage
            // ou si la liste est déjà présente.
            const params = {};
            if (resource.currentList.items.length) {
                params.colSpan =
                    resource.currentList.displayFields.length +
                    (Object.keys(resource.currentList.bulkActions).length ? 1 : 0);
            } else {
                params.style = { width: '90vw', marginLeft: '10vw' };
            }
            return isLoadingData ? (
                <TableRow key={'loading'} className="loading">
                    <TableCell {...params}>
                        <LinearProgress />
                    </TableCell>
                </TableRow>
            ) : (
                <TableRow key={'loading'} style={{ height: '4px' }}></TableRow>
            );
        }

        tbody() {
            const { resource, settingsApi } = this.props;
            const { isLoadingData } = this.state;
            let trs = [];
            let fieldsCount = Object.keys(resource.currentList.bulkActions).length;
            let self = this;

            const actionColStyles = this.getActionColStyle();
            if (resource.currentList.items && !resource.currentList.items.length && !isLoadingData) {
                return this.renderNoResults();
            }

            trs.push(this.renderLoadingHeader(isLoadingData));

            for (let i in resource.currentList.items) {
                let tds = [];
                if (fieldsCount > 0) {
                    tds.push(
                        <TableCell padding="checkbox" key={-1} className="table-cell-checkbox">
                            <div className="cell-content">
                                <Checkbox
                                    checked={this.state.selection.indexOf(resource.currentList.items[i].id) !== -1}
                                    onChange={() => {
                                        this.setState({selectAll: false})
                                        self.onSelectionChange(self.props.resource.currentList.items[i].id);
                                    }}
                                />
                            </div>
                        </TableCell>
                    );
                }
                // if we have enough action
                if (
                    this.props.resource.operations.list.defaultActionButtons ||
                    this.props.resource.operations.list.additionalListActionButtons
                ) {
                    tds.push(
                        <TableCell key={'action_cell'} className="actions" style={actionColStyles}>
                            {this.rowActions(this.props.resource.currentList.items[i])}
                        </TableCell>
                    );
                }

                resource.currentList.displayFields.forEach((fieldName, index) => {
                    let value = null;

                    let field = this.props.resource.fields[fieldName];

                    if (field.displayList && !field.displayList(field, null, null, null, this.props.resource)) {
                        return;
                    }

                    let fieldValue = this.props.resource.currentList.items[i][fieldName];
                    if (fieldName.match(/.+\..+/)) {
                        let fieldNameParts = fieldName.split('.');
                        let childField = this.props.resource.currentList.items[i];
                        if (childField !== undefined) {
                            fieldNameParts.forEach((partName) => {
                                if (childField !== undefined) {
                                    childField = childField[partName];
                                }
                            });
                        }
                        fieldValue = childField;
                    }
                    // field is not present means it has been deleted because of acl
                    // If i have no right for this field, display an empty field.
                    if (
                        fieldValue === undefined ||
                        !hasRightsForField(field, {
                            hasRole: settingsApi && settingsApi.hasOneRole,
                            operation: 'display',
                            entity: this.props.resource.currentList.items[i],
                        })
                    ) {
                        value = undefined;
                    } else if (this.props.resource.fields[fieldName].displayList) {
                        value = this.props.resource.fields[fieldName].displayList(
                            field,
                            fieldValue,
                            this.props.resource.currentList.items[i],
                            { plainText: true },
                            resource
                        );
                    } else if (this.props.resource.fields[fieldName].display) {
                        value = this.props.resource.fields[fieldName].display(
                            field,
                            fieldValue,
                            this.props.resource.currentList.items[i],
                            { plainText: true },
                            resource
                        );
                    } else {
                        if (field.type && FieldProviderStore[field.type]) {
                            if (FieldProviderStore[field.type].getDisplayList) {
                                value = FieldProviderStore[field.type].getDisplayList(
                                    field,
                                    fieldValue,
                                    this.props.resource.currentList.items[i],
                                    { plainText: true },
                                    resource
                                );
                            } else {
                                value = FieldProviderStore[field.type].getDisplay(
                                    field,
                                    fieldValue,
                                    this.props.resource.currentList.items[i],
                                    { plainText: true },
                                    resource
                                );
                            }
                        } else {
                            value = FieldProviderStore.default.getDisplayList(
                                field,
                                fieldValue,
                                this.props.resource.currentList.items[i],
                                { plainText: true },
                                resource
                            );
                        }
                    }
                    tds.push(
                        <TableCell key={i + '-' + fieldName}>
                            { value && !field.noTooltip ?
                                <Tooltip
                                    title={value ? value : ''}
                                    placement={index === 0 ? 'bottom' : 'top'}
                                    arrow
                                >
                                    <div
                                        className={'cell-content ' + fieldName}
                                        style={
                                            this.props.resource.fields[fieldName].width
                                                ? {
                                                    width: this.props.resource.fields[fieldName].width + 'px',
                                                }
                                                : null
                                        }
                                    >
                                        {value}
                                    </div>
                                </Tooltip>
                                : <div
                                    className={'cell-content ' + fieldName}
                                    style={
                                        this.props.resource.fields[fieldName].width
                                            ? {
                                                width: this.props.resource.fields[fieldName].width + 'px',
                                            }
                                            : null
                                    }
                                >
                                    {value}
                                </div> }
                        </TableCell>
                    );
                });
                trs.push(
                    <TableRow
                        key={i}
                        onDoubleClick={() => {
                            if (!this.props.resource.operations.detail) {
                                return;
                            }
                            let targetResource =
                                this.props.resource.operations.list.targetDetailResource ||
                                this.props.resource.instanceId;
                            Navigation.router.history.push(
                                '/resource/' + targetResource + '/' + resource.currentList.items[i].id + '/detail'
                            );
                        }}
                    >
                        {tds}
                    </TableRow>
                );
            }
            return <TableBody>{trs}</TableBody>;
        }

        rowActions(item) {
            let actions = [];
            const { numberOfAdditionalActions } = this.state;
            // Detail
            if (
                hasRightsForOperation('detail', {
                    entity: item,
                    ...this.props,
                    hasRole: this.props.settingsApi && this.props.settingsApi.hasOneRole,
                }) &&
                this.props.resource.operations.list.defaultActionButtons
            ) {
                actions.push(
                    <Link
                        key={'action_detail_' + item.id}
                        to={'/resource/' + this.props.resource.instanceId + '/' + item.id + '/detail'}
                        style={styles.actionLink}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className="button-table button-show-version tooltip tooltip-right"
                        >
                            <i className="fa fa-th-large"></i>
                            <span className="tooltiptext">View details</span>
                        </Button>
                    </Link>
                );
            }

            // Edit
            if (
                hasRightsForOperation('edit', {
                    entity: item,
                    ...this.props,
                    hasRole: this.props.settingsApi && this.props.settingsApi.hasOneRole,
                }) &&
                this.props.resource.operations.list.defaultActionButtons
            ) {
                actions.push(
                    <Link
                        key={'action_edit_' + item.id}
                        to={'/resource/' + this.props.resource.instanceId + '/' + item.id + '/edit'}
                        style={styles.actionLink}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className="button-table  button-edit tooltip tooltip-right"
                        >
                            <i className="fa fa-edit"></i>
                            <span className="tooltiptext">Edit</span>
                        </Button>
                    </Link>
                );
            }

            // Delete
            if (
                hasRightsForOperation('delete', {
                    entity: item,
                    ...this.props,
                    hasRole: this.props.settingsApi && this.props.settingsApi.hasOneRole,
                }) &&
                this.props.resource.operations.list.defaultActionButtons
            ) {
                if (this.props.resource.operations.deleteComponent !== null) {
                    actions.push(
                        React.cloneElement(this.props.resource.operations.deleteComponent(item), {
                            key: 'action_delete_' + item.id,
                            context: 'datatable',
                            className: 'tooltip-right',
                        })
                    );
                } else {
                    actions.push(
                        <Button
                            key={'action_delete_' + item.id}
                            variant="contained"
                            color="primary"
                            style={styles.actionLink}
                            className="button-table  button-delete tooltip tooltip-right"
                            onClick={() => this.props.resource.deleteItemWithModalConfirmation(item)}
                        >
                            <i className="fa fa-trash-alt"></i>
                            <span className="tooltiptext">Delete</span>
                        </Button>
                    );
                }
            }
            const additionalListActionButtons = renderAdditionalListActionButtons(this.props.resource, item);

            if (additionalListActionButtons && additionalListActionButtons.length > numberOfAdditionalActions) {
                this.setState({
                    numberOfAdditionalActions: additionalListActionButtons.length,
                });
            }
            if (additionalListActionButtons && Array.isArray(additionalListActionButtons)) {
                actions = actions.concat(additionalListActionButtons);
            }
            return actions;
        }

        getSortKey(sortField) {
            let sortKey = '';
            if (!sortField || !this.props.resource.fields[sortField]) {
                console.warn(sortField, 'has no configuration');
                return;
            }
            // if we already added the nested field
            if (sortField.indexOf('.') > -1) {
                return sortField;
            }
            if (
                this.props.resource.fields[sortField].params &&
                this.props.resource.fields[sortField].params.sortField
            ) {
                sortKey = `${sortField}.${this.props.resource.fields[sortField].params.sortField}`;
            } else if (
                this.props.resource.fields[sortField].type &&
                this.props.resource.fields[sortField].type === 'parameter'
            ) {
                sortKey = `${sortField}.label`;
            } else if (
                this.props.resource.fields[sortField].type &&
                (this.props.resource.fields[sortField].type === 'entity' ||
                    this.props.resource.fields[sortField].type === 'entityTree')
            ) {
                const label =
                    this.props.resource.fields[sortField].params &&
                    this.props.resource.fields[sortField].params.displayField;
                if (label) {
                    sortKey = `${sortField}.${label}`;
                }
            }

            return sortKey || sortField;
        }

        getFilterValue(field) {
            const filters = this.state.activeFilters;
            let canonicalFieldId = field.canonicalFieldName ?? field.id;
            switch (field.type) {
                case 'date':
                    return {
                        startDate: filters[`${canonicalFieldId}[after]`],
                        endDate: filters[`${canonicalFieldId}[before]`],
                    };
                default:
                    return filters && filters[canonicalFieldId] !== undefined ? filters[canonicalFieldId] : null;
            }
        }

        getSortObjectFromResource() {
            if (this.props.resource.currentList.order) {
                const order = Object.entries(this.props.resource.currentList.order);
                if (order && order[0]) {
                    const [sortField, sortDirection] = order[0];
                    return { sortField, sortDirection };
                }
            }
            return {};
        }

        onFilterChange(id, value, params) {
            this.adjustTbodyHeight();
            if(this.props.resource.currentRequestList){
                this.props.resource.currentRequestList.cancel('NoAlert');
            }
            this.applyFilter(id, value, params).then(() => {
                this.props.resource.currentList.page = 1;
                return this.debouncedRefreshApiData();
            });

            return;
        }

        /**
         * 
         * @param {string} id - fieldId
         * @param {*} value 
         * @param {import('../../APIResource').APIResourceField['params']} params 
         * @returns 
         */
        applyFilter(id, value, params) {
            return new Promise((resolve, reject) => {
                try {
                    let field = this.props.resource.fields[id];
                    const { activeFilters } = this.state;
                    let canonicalFieldId = field.canonicalFieldName ?? id;

                    if(typeof value === "string" && params && params.filterMulti){
                        value = value.split(/[\s,]+/);
                    }

                    if (params?.listFilterTransform) {
                        value = Array.isArray(value)
                            ? value.map((v) => params.listFilterTransform(v))
                            : params.listFilterTransform(value);
                    }

                    if (field.type && FieldProviderStore[field.type] && FieldProviderStore[field.type].applyFilter) {
                        FieldProviderStore[field.type].applyFilter(
                            canonicalFieldId,
                            value,
                            activeFilters,
                            (...args) => {
                                this.setState({
                                    activeFilters,
                                });
                                resolve(...args);
                            }
                        );
                    } else {
                        FieldProviderStore.default.applyFilter(canonicalFieldId, value, activeFilters, (...args) => {
                            this.setState({
                                activeFilters,
                            });
                            resolve(...args);
                        });
                    }
                } catch (err) {
                    reject(err);
                }
            });
        }

        resetFilters = async () => {
            this.setState({ activeFilters: {} });
            this.props.resource.currentList.filters = {};
            this.refreshApiData({
                filters: {},
                context: 'onFilterChange-custom-filter',
                forceReload: true,
                saveConfig: true,
                cache: false,
            });
        };

        onChangeRowsPerPage = (event) => {
            this.refreshApiData({
                filters: this.activeFilters,
                rowsPerPage: event.target.value,
                forceReload: true,
                saveConfig: true,
                context: 'pagination-rowPerPagechange',
            });
            this.setState({ selection: [] });
        };

        onChangePage = (event, page) => {
            this.refreshApiData({
                filters: this.activeFilters,
                page: page + 1,
                forceReload: true,
                saveConfig: true,
                context: 'pagination-onPagechange',
            });
            this.setState({ selection: [] });
        };

        onFieldConfigClose = () => {
            // copy the filters from the resource in order to update the local state
            this.props.resource.updateFiltersList();
            this.initActiveFilters().then(() => this.refreshApiData({ forceReload: true }));
        };

        onFieldConfigUpdate = async () => {
            this.props.resource.saveRemoteConfig();
        };

        onSelectionChange(id) {
            let selection = this.state.selection;
            if (selection.indexOf(id) === -1) {
                selection.push(id);
            } else {
                for (let i in selection) {
                    if (selection[i] === id) {
                        selection.splice(i, 1);
                    }
                }
            }
            this.setState({ selection: selection });
        }

        onSortChange = async (fieldId) => {
            // do not sort if not sortable
            if (!this.props.resource.fields[fieldId] || !this.props.resource.fields[fieldId].sortable === false) {
                return;
            }
            if (this.props.resource.currentRequestList) {
                this.props.resource.currentRequestList.cancel('NoAlert');
            }
            const sortKey = this.getSortKey(fieldId);
            let sortDirection = 'asc';
            if (this.props.resource.currentList.order && this.props.resource.currentList.order[sortKey]) {
                sortDirection = this.props.resource.currentList.order[sortKey] === 'asc' ? 'desc' : 'asc';
            }
            const order = { [sortKey]: sortDirection };
            this.props.resource.currentList.order = order;
            this.debouncedRefreshApiData();
        };

        selectAll() {
            let selection = [];
            if (this.state.selection.length !== this.props.resource.currentList.items.length) {
                for (let i in this.props.resource.currentList.items) {
                    selection.push(this.props.resource.currentList.items[i].id);
                }
            }
            this.setState({ selection, selectAll: true });
        }

        bulkActions = () => {
            let self = this;

            if (this.state.selection.length === 0) {
                return null;
            }

            let genActions = function () {
                let actions = [];
                for (let i in self.props.resource.currentList.bulkActions) {
                    let actionLabel = null;
                    let withIcon = false;
                    if (self.props.resource.currentList.bulkActions[i].parameters?.icon) {
                        if (Array.isArray(self.props.resource.currentList.bulkActions[i].parameters.icon)) {
                            actionLabel =
                                <i className={`${self.props.resource.currentList.bulkActions[i].parameters.icon[0]} fa-${self.props.resource.currentList.bulkActions[i].parameters.icon[1]}`}></i>;
                        } else {
                            actionLabel =
                                <i className={`fa fa-${self.props.resource.currentList.bulkActions[i].parameters.icon}`}></i>;
                        }
                        withIcon = true;
                    } else {
                        actionLabel = self.props.resource.currentList.bulkActions[i].getLabel();
                    }
                    if (actionLabel) {
                        if (withIcon) {
                            actions.push(
                                <Tooltip
                                    title={self.props.resource.currentList.bulkActions[i].getLabel()}
                                    placement="top-start"
                                    arrow
                                    key={i}
                                    className={'withIcon'}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleChange(self, self.props.resource.currentList.bulkActions[i].getId())}
                                    >
                                        {actionLabel}
                                    </Button>
                                </Tooltip>
                            );
                        } else {
                            actions.push(
                                <Button
                                    variant="contained"
                                    color="primary"
                                    key={i}
                                    onClick={() => handleChange(self, self.props.resource.currentList.bulkActions[i].getId())}
                                >
                                    {actionLabel}
                                </Button>
                            );
                        }

                    }
                }
                return actions;
            };

            let handleChange = function (dataTable, value) {
                if (value !== -1) {
                    for (let i in dataTable.props.resource.currentList.bulkActions) {
                        if (dataTable.props.resource.currentList.bulkActions[i].getId() === value) {
                            if(dataTable.state.selectAll){
                                dataTable.setState({isLoadingData: true});
                                Http.get(dataTable.props.resource.currentList.lastUsedEndpoint.replace('perPage=' + dataTable.props.resource.currentList.rowsPerPage, 'perPage=100000') + '&properties[]=id')
                                    .then((result) => {
                                        let ids = result['hydra:member'].map((item) => item.id);
                                        dataTable.setState({isLoadingData: false});
                                        dataTable.props.resource.currentList.bulkActions[i].run(ids, dataTable, {selectAll: dataTable.state.selectAll});
                                        dataTable.setState({ selection: [], selectAll: false });
                                    })
                                    .catch(() => {
                                        dataTable.setState({isLoadingData: false});
                                    });
                            }
                            else{
                                dataTable.props.resource.currentList.bulkActions[i].run(dataTable.state.selection, dataTable, {selectAll: dataTable.state.selectAll});
                                dataTable.setState({ selection: [], selectAll: false });
                            }

                            return;
                        }
                    }
                }
            };

            if (Object.keys(this.props.resource.currentList.bulkActions).length > 0) {
                return genActions();
            }
        };

        renderSortIcon(fieldId) {
            let { sortDirection, sortField } = this.getSortObjectFromResource();

            const fieldSortKey = this.getSortKey(fieldId);
            if (!fieldId || fieldSortKey !== sortField || !this.props.resource.fields[fieldId].sortable === false) {
                return;
            }
            return (
                <IconButton style={styles.sortButton}>
                    {sortDirection === 'asc' ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            );
        }

        renderFilterStatusButton() {
            const filters = this.cleanedActiveFilters();
            const count = filters ? Object.keys(filters).length : 0;
            if (!count) {
                return;
            }
            const filtersTooltipContent = Object.keys(this.props.resource.currentList.filters).map((field) => (
                <div key={field}>{_.startCase(field)}</div>
            ));
            return (
                <Tooltip title={<div>{filtersTooltipContent}</div>} placement="bottom" arrow className="tooltip-white">
                    <div style={styles.resetFilterSection}>
                        <span style={styles.resetFilterText}>
                            {count} Filter{count > 1 ? 's' : ''} active{' '}
                        </span>
                        <IconButton
                            tooltip="Reset filters"
                            style={styles.resetFilterButton}
                            onClick={this.resetFilters}
                        >
                            <DeleteOutline style={{ color: '#f9d008' }} />
                        </IconButton>
                    </div>
                </Tooltip>
            );
        }

        renderAddButton() {
            if (
                this.props.resource.operations.add &&
                this.props.resource.operations.list.showDefaultAddButton
            ) {
                const button = (
                    <Button
                        variant="contained"
                        className="primary tooltip"
                        onClick={($event) => {
                            this.props.resource.operations.add.insertButtonAction({
                                resource: this.props.resource,
                                event: $event,
                            });
                        }}
                    >
                        <i className="fa fa-plus"></i>
                        <span className="tooltiptext">{this.props.resource.operations.add.tooltipText || 'Add'}</span>
                    </Button>
                );

                return this.props.resource.operations.add.insertButtonAction ? (
                    button
                ) : (
                    <Link
                        key={'list_resource_button_add'}
                        to={
                            this.props.resource.operations.list.insertButton &&
                            this.props.resource.operations.list.insertButton.path
                                ? this.props.resource.operations.list.insertButton.path
                                : '/resource/' + this.props.resource.instanceId + '/add'
                        }
                        className="item "
                    >
                        <Button variant="contained" className="primary tooltip">
                            <i className="fa fa-plus"></i>
                            <span className="tooltiptext">
                                {this.props.resource.operations.list.insertButton &&
                                this.props.resource.operations.list.insertButton.tooltip
                                    ? this.props.resource.operations.list.insertButton.tooltip
                                    : this.props.resource.operations.add.tooltipText || 'Add'}
                            </span>
                        </Button>
                    </Link>
                );
            }
            return null;
        }
        renderAdditionalButtons() {
            let self = this;
            if (this.props.resource.operations.list.additionalActionButtons !== null) {
                const buttons = [];
                let buttonsDefinition = [];
                if (typeof this.props.resource.operations.list.additionalActionButtons === 'function') {
                    buttonsDefinition = this.props.resource.operations.list.additionalActionButtons(
                        this.props.resource
                    );
                } else {
                    buttonsDefinition = this.props.resource.operations.list.additionalActionButtons;
                }
                buttonsDefinition.forEach((button, index) => {
                    if (!button.onClick) {
                        buttons.push(
                            <Link key={'list_resource_button_add_' + index} to={button.to} className="item ">
                                <Button
                                    variant="contained"
                                    className={'default tooltip ' + (button.class || ' ') + (button.className || ' ')}
                                >
                                    <i className={'fa ' + button.icon}></i>
                                    <span className="tooltiptext">{button.label || 'Add'}</span>
                                </Button>
                            </Link>
                        );
                    } else {
                        let resource = self.props.resource;
                        let operation = 'list';
                        buttons.push(
                            <Button
                                key={'list_resource_button_add_' + index}
                                variant="contained"
                                style={button.style}
                                className={'tooltip ' + (button.class || ' ') + (button.className || ' ')}
                                onClick={() => button.onClick({ resource, index, operation })}
                            >
                                <i className={'fa fa-' + button.icon}></i>
                                <span className="tooltiptext">{button.tooltip}</span>
                            </Button>
                        );
                    }
                });

                return buttons;
            }
            return null;
        }

        render() {
            return (
                <Paper className="data-table-container">
                    <AppBar position="static" className="background-lowlight model-list-header">
                        <Toolbar style={styles.toolBar}>
                            <Typography variant="h6">
                                {this.props.resource.operations.list.title || this.props.resource.name + ' list'}
                            </Typography>
                            {this.renderFilterStatusButton()}
                            <Pagination
                                resource={this.props.resource}
                                updateCallback={() => {
                                    this.setState({ selection: [] });
                                }}
                                onChangePage={this.onChangePage.bind(this)}
                                onChangeRowsPerPage={this.onChangeRowsPerPage.bind(this)}
                            />
                            <FieldsConfig
                                {...this.props}
                                onClose={this.onFieldConfigClose}
                                onBackdropClick={this.onFieldConfigClose}
                                updateCallback={this.onFieldConfigUpdate}
                            />
                        </Toolbar>
                    </AppBar>
                    {this.state.selection.length > 0 &&
                        <AppBar position="static" className="background-lowlight bulk-actions">
                            <Typography variant="h6">Apply to {this.state.selectAll ? this.props.resource.totalItems : this.state.selection.length} item{this.state.selection.length > 1? 's' : ''}</Typography>
                            {this.bulkActions()}
                        </AppBar>
                    }
                    <div className="data-table">
                        <Table className="main-table" size="small">
                            {this.thead()}
                            {this.tbody()}
                        </Table>
                    </div>
                    <div className="button-bar-bottom">
                        {this.props.resource.operations.list.additionalActionButtons !== undefined
                            ? this.renderAdditionalButtons()
                            : null}
                        {hasRightsForOperation('add', {
                            ...this.props,
                            entity: this.state.entity,
                            hasRole: this.props.settingsApi && this.props.settingsApi.hasOneRole,
                        }) || this.props.resource.operations.list.skipButtonAccessCheck
                            ? this.renderAddButton()
                            : null}
                    </div>
                </Paper>
            );
        }
    }
);

let styles = {
    actionLink: {
        marginLeft: 5,
    },
    toolBar: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    sortButton: {
        float: 'right',
        padding: 0,
        color: 'white',
        marginRight: -7,
    },
    headerClickable: {
        cursor: 'pointer',
    },
    resetFilterSection: {
        marginLeft: 20,
    },
    resetFilterText: {
        fontSize: 14,
    },
    resetFilterButton: {
        marginLeft: 0,
        paddingLeft: 0,
        color: '#f9d008',
    },
};
