import React from 'react';
import { APIResource } from '../Services/APIResource/APIResource';
import { TableDisplay } from '../Components/Display/TableDisplay/TableDisplay';
import {LinkPreload as Link} from '../Components/Link/Link';
import { ChangeLog } from '../Components/Display/ChangeLog/ChangeLog';
import {
    PARAMETER_TYPE_FINDING_STATUS, PARAMETER_TYPE_FINDING_NOTICE_SEVERITY,
} from '../Admin/ParameterAdmin';
import ParameterStore, {
    userHasNoticeOwnershipRights,
    userHasRights,
    userHasRoleADMIN,
    userHasRoleMRM,
    userHasSpecificRole,
    userHasValidatorRights,
    USER_SCOPE_ALL,
} from '../Store/ParameterStore';
import {WorkflowStepProvider} from "../Components/WorkflowStep/WorkflowStep";
import {LogicalDeleteButton} from "../Components/DeleteButton/LogicalDeleteButton";
import Modal from "../Services/Modal";
import { CloneModal } from "../Components/CloneModal/CloneModal";
import ChoiceModal from "../Components/Modal/ChoiceModal";
import User, {ROLE as Role} from "../Services/User/User";
import {FindingActionForm} from "../Components/Forms/CloseModal/FindingCloseModal";
import ModelProvider from '../Services/APIResource/FieldProviders/ModelProvider';
import EntityAsyncProvider from "../Services/APIResource/FieldProviders/EntityAsyncProvider";
import EntityProvider from "../Services/APIResource/FieldProviders/EntityProvider";
import ParameterProvider from "../Services/APIResource/FieldProviders/ParameterProvider";
import { EntityImportForm } from '../Components/Import/EntityImport';
import { EntityExport, EntityExportForm } from '../Components/Export/EntityExport';
import APIResourceStore from "../Store/APIResourceStore";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import { BulkDelete } from '../Services/BulkActions/BulkDelete/BulkDelete';
import { EditButton } from '../Components/Buttons/EditButton';
import { DetailButton } from '../Components/Buttons/DetailButton';
import { 
    DISPLAY,
    DISPLAY_ADD,
    EDIT,
    fieldHighlightedParam,
    HIDDEN,
    isDisplayed,
    isEditable,
    isRequired,
    queryHasAttribute,
    REQUIRED
} from './common';
import { Header } from '../Components/Header/Header';
import { NoticeQuickSelect } from '../Components/Notice/NoticeQuickSelect';
import { ModalContent } from '../Components/Modal/ModalContent';
import { ButtonColumn } from '../Components/Modal/ButtonColumn';
import { ActionButton } from '../Components/Modal/ActionButton';
import { ActionLink } from '../Components/ActionLink/ActionLink';
import {BulkEdit} from "../Services/BulkActions/BulkEdit/BulkEdit";
import FindingMra from "../Components/Finding/Add/FindingMra";
import {OpenModal} from "../Components/Modal/OpenModal";
import {isMemberOfNoticeOwnerTeams} from "./NoticeAdmin";

const getReview = (entity, routeParams) => {
    entity.review = routeParams.reviewId ? '/api/reviews/' + routeParams.reviewId : entity.review;
    if(entity.review && routeParams.reviewInstanceId){
        return APIResourceStore.resources[routeParams.reviewInstanceId].getObservableItemByPath(entity.review);
    }
}

const getModel = (modelPath) => {
    if(modelPath){
        return APIResourceStore.resources.allModels.getObservableItemByPath(modelPath);
    }
}

/** @see App\Traits\OriginTrait dans le back */
const ORIGIN_REVIEW = 'review';

/**
 * Local function to know if the user is VAL of Finding.
 *
 * @param {{validators: Array<string>, validatorTeams: Array<string>}} finding Finding entity
 * @returns {bool}
 * @see: https://app.asana.com/0/1134038211401766/1201201533578189/f
 */
export const isValOfFinding = (finding) => {
    // Pour gagner du temps on vérifie d'abord les champs standards avant de chercher dans les teams.
    if (finding && Array.isArray(finding.validators) && finding.validators.includes(`/api/users/${User.getId()}`)) return true;

    return userHasRights(User, finding, 'validatorTeams', USER_SCOPE_ALL);
}

export const isSeverityAllowed = (finding, parameter = undefined) => {
    if (!parameter) parameter = APIResourceStore.resources?.parameters?.getObservableItemByPath(finding.severity);
    return parameter.systemId !== 'FINDING_SEVERITY_NA' || !finding?.notices?.length
};

const header = (entity) => {
    let header = [];

    header.push(<WorkflowStepProvider
        key={"finding_step"}
        entity={entity}
        activeStep={(entity) => {
            if(entity.status === ParameterStore('FINDING_STATUS_DRAFT')){
                return 0
            }else if(entity.status === ParameterStore('FINDING_STATUS_OPEN')){
                return 1
            }else if(
                entity.status === ParameterStore('FINDING_STATUS_CLOSED')
                || entity.status === ParameterStore('FINDING_STATUS_DISMISSED')
            ){
                return 2
            }
            return null;
        }}
        steps={[
            {
                label: "Draft",
                key: 0
            },
            {
                label: "Open",
                key: 1
            },
            {
                label: "Closed / Cancelled",
                key: 2
            }
        ]}
    />);

    let message = undefined;
    if (entity.status === ParameterStore('FINDING_STATUS_DRAFT')){
        message = isValOfFinding(entity)
            ? 'The status of your finding is Draft and you can start to edit it. '
              + 'Please note that you can create an associated notice in the Notices tab.'
            : message;
        message =
            isValOfFinding(entity) && entity.hasOriginReview
                ? 'The status of your finding is Draft and you can start to edit it.'
                  + 'The finding will be confirmed when the associated notice is confirmed. Otherwise, the finding will be confirmed when the review closes.'
                : message;
    }

    message && header.push(<Header key={"message"} text={message} />);

    return header;
}

/** 
 * Mapping de l'index du tableau des droits en fonction du rôle cherché.
 * @see FIELDS_BY_STATUS
 */
 const ROLE_INDEX_MAPPING = {
    val: 0,
    noticeOwner: 1,
    other: 2,
}

/**
 * on positionne dans cet ordre les droits pour : [VAL,Owner,others] (voir ROLE_INDEX_MAPPING)
 * Voir dans common.js pour les différents droits
 * 
 * NB: attention, les champs "HIDDEN" sont envoyés 'NULL' au back, sauf s'ils ont la propriété
 *     doNotResetValueWhenNotDisplayed.
 * 
 * @see NoticeAdmin même mécanisme pour Notices
 */
const FIELDS_BY_STATUS = {
    id: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    title: {
        draft: [EDIT + REQUIRED, DISPLAY, DISPLAY],
        open: [EDIT + REQUIRED, DISPLAY, DISPLAY],
        closed: [DISPLAY + REQUIRED, DISPLAY, DISPLAY],
    },
    severity: {
        draft: [EDIT + REQUIRED, DISPLAY, DISPLAY],
        open: [EDIT + REQUIRED, DISPLAY, DISPLAY],
        closed: [DISPLAY + REQUIRED, DISPLAY, DISPLAY],
    },
    issuer: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    issuerTeams: {
        draft: [DISPLAY - DISPLAY_ADD, DISPLAY - DISPLAY_ADD, DISPLAY - DISPLAY_ADD],
        open: [DISPLAY - DISPLAY_ADD, DISPLAY - DISPLAY_ADD, DISPLAY - DISPLAY_ADD],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    models: {
        draft: [EDIT + REQUIRED, DISPLAY, DISPLAY],
        open: [EDIT + REQUIRED, DISPLAY, DISPLAY],
        closed: [DISPLAY + REQUIRED, DISPLAY, DISPLAY],
    },
    review: {
        draft: [EDIT, DISPLAY, DISPLAY],
        open: [EDIT, DISPLAY, DISPLAY],
        closed: [EDIT, DISPLAY, DISPLAY],
    },
    status: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    issuanceDate: {
        draft: [EDIT + REQUIRED, DISPLAY, DISPLAY],
        open: [DISPLAY + REQUIRED, DISPLAY, DISPLAY],
        closed: [DISPLAY + REQUIRED, DISPLAY, DISPLAY],
    },
    findingMraRelations: {
        draft: [EDIT, DISPLAY, DISPLAY],
        open: [EDIT + REQUIRED, DISPLAY, DISPLAY],
        closed: [DISPLAY + REQUIRED, DISPLAY, DISPLAY],
    },
    contextDescription: {
        draft: [EDIT, DISPLAY, DISPLAY],
        open: [EDIT, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    weaknessesDescription: {
        draft: [EDIT, DISPLAY, DISPLAY],
        open: [EDIT + REQUIRED, DISPLAY, DISPLAY],
        closed: [DISPLAY + REQUIRED, DISPLAY, DISPLAY],
    },
    impactsDescription: {
        draft: [EDIT, DISPLAY, DISPLAY],
        open: [EDIT + REQUIRED, DISPLAY, DISPLAY],
        closed: [DISPLAY + REQUIRED, DISPLAY, DISPLAY],
    },
    noticesEntities: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    changeLogsEntities: {
        draft: [DISPLAY, DISPLAY, DISPLAY],
        open: [DISPLAY, DISPLAY, DISPLAY],
        closed: [DISPLAY, DISPLAY, DISPLAY],
    },
    /** @todo Valider ça : */
    changeLogComment: {
        draft: [HIDDEN, HIDDEN, HIDDEN],
        open: [HIDDEN, HIDDEN, HIDDEN],
        closed: [HIDDEN, HIDDEN, HIDDEN],
    },

}

/** Mapping entre le statut de l'entité et le nom dans FIELDS_BY_STATUS */
const getStatusKey = (status) => {
    if (status === ParameterStore('FINDING_STATUS_DRAFT')) return "draft";
    if (status === ParameterStore('FINDING_STATUS_OPEN')) return "open";
    if (status === ParameterStore('FINDING_STATUS_CLOSED')) return "closed";
    if (status === ParameterStore('FINDING_STATUS_DISMISSED')) return "closed";
    return "";
}

/**
 * Détermine si le champ est requis à partir du tableau FIELDS_BY_STATUS.
 * 
 * @param {*} entity 
 * @param {string} fieldId 
 * @returns {boolean}
 */
export const requiredByStatus = (entity, fieldId) => {
    // Aucun champ requis pour MRM
    if (userHasRoleMRM()) return false;

    // Par défaut le champ n'est pas requis, et on reproduit la structure des droits avec le .map , avec une permission non "required", genre DISPLAY.
    const rights = FIELDS_BY_STATUS[fieldId]?.[getStatusKey(entity.status)] || Object.keys(ROLE_INDEX_MAPPING).map(_role => HIDDEN);
    let required = false;

    if (isValOfFinding(entity)){
        required = isRequired(rights[ROLE_INDEX_MAPPING.val]);
    } else if (
        entity.notices // finding ne reçoit plus les entities depuis le back en mode LIST
        && entity.notices.map(
            n => APIResourceStore.resources.notices.getObservableItemByPath(n)
        ).reduce((acc, n) => userHasNoticeOwnershipRights(User.getId(), n) || acc, false)
    ) {
        required = isRequired(rights[ROLE_INDEX_MAPPING.noticeOwner]);
    } else {
        required = isRequired(rights[ROLE_INDEX_MAPPING.other]);
    }

    return required;
};

/**
 * Affiche l'élément en fonction du contexte, du statut et du rôle,
 * définis dans la const FIELDS_BY_STATUS.
 * 
 * @returns {boolean}
 */
const displayConditions = (entity, parentState, key, context_, fieldId) => {
    /** 
     * @todo pour l'instant displayConditions ne reçoit pas le contexte "add"
     * donc on bidouille ici pour le récupérer et s'en servir.
     * @fixme il faudra corriger ça !!!
     */
    const context = entity.id ? context_ : "add";

    if (userHasRoleMRM()) 
        return true;

    // Par défaut on cache, si les champs n'existent pas, et on reproduit la structure des droits avec le .map
    const rights = FIELDS_BY_STATUS[fieldId]?.[getStatusKey(entity.status)] || Object.keys(ROLE_INDEX_MAPPING).map(_role => HIDDEN);
    let display = false;

    if (isValOfFinding(entity)){
        display = isDisplayed(rights[ROLE_INDEX_MAPPING.val], context);
    } else if (
        entity.notices
        && entity.notices.map(
            n => APIResourceStore.resources.notices.getObservableItemByPath(n)
        ).reduce((acc, n) => userHasNoticeOwnershipRights(User.getId(), n) || acc, false)
    ) {
        display = isDisplayed(rights[ROLE_INDEX_MAPPING.noticeOwner], context);
    } else {
        display = isDisplayed(rights[ROLE_INDEX_MAPPING.other], context);
    }

    return display;
}

/**
 * Indique s'il faut afficher le composant d'édition ou de display en fonction du contexte, du statut et du rôle,
 * définis dans la const FIELDS_BY_STATUS.
 * 
 * @returns {boolean}
 */
const editConditions = (field, value, entity) => {
    // Par défaut on affiche le composant DISPLAY, si les champs n'existent pas, et on reproduit la structure des droits avec le .map.
    const rights = FIELDS_BY_STATUS[field.id]?.[getStatusKey(entity.status)] || Object.keys(ROLE_INDEX_MAPPING).map(_role => DISPLAY);
    let editable = false;

    if (userHasRoleMRM()) {
        editable = true;
    } else if (isValOfFinding(entity)){
        editable = isEditable(rights[ROLE_INDEX_MAPPING.val]);
    } else if (
        entity.notices
        && entity.notices.map(
            n => APIResourceStore.resources.notices.getObservableItemByPath(n)
        ).reduce((acc, n) => userHasNoticeOwnershipRights(User.getId(), n) || acc, false)
    ) {
        editable = isEditable(rights[ROLE_INDEX_MAPPING.noticeOwner]);
    } else {
        editable = isEditable(rights[ROLE_INDEX_MAPPING.other]);
    }

    return editable;
}

const additionalLinkButton = (entity) => {
    let review = entity.review;
    if(review) {
        review = review.split('/');
        let reviewId = review[review.length - 1];

        return [{
            link:
                '/resource/reviews/' + reviewId + '/detail?tab=Issues',
            tooltip: 'Associated review',
            icon: 'link',
        }];
    }
}
const additionalActionButtons = (entity, resource, resourceDetailComponent) => {
    let additionalActionButtons = [];

    if (
        entity.status === ParameterStore('FINDING_STATUS_OPEN')
        && userHasValidatorRights(User.getId(), entity)
    ) {
        additionalActionButtons.push({
            onClick: (button) =>
                Modal.open({
                    title: "Close",
                    content: <FindingActionForm
                        entityType='finding'
                        entity={button.entity}
                        resource={resource}
                        resourceDetailComponent={
                            resourceDetailComponent
                        }
                        action={'close'}
                        actionTitle={'Close'}
                        successMessage={'The finding has been closed.'}
                        requiredComment={true}
                    />,
                }),
            tooltip: "Close",
            icon: "check",
            className: "agree",
        });
        additionalActionButtons.push({
            onClick: (button) =>
                Modal.open({
                    title: "Cancel",
                    content: <FindingActionForm
                        entityType='finding'
                        entity={button.entity}
                        resource={resource}
                        resourceDetailComponent={
                            resourceDetailComponent
                        }
                        action={'dismiss'}
                        actionTitle={'Cancel'}
                        successMessage={'The finding has been cancelled.'}
                        requiredComment={true}
                    />,
                }),
            tooltip: "Cancel",
            icon: "ban",
            className: "retire trash",
        });
    }

    if (isValOfFinding(entity)){
        additionalActionButtons.push({
            onClick: (_button) =>
                Modal.open({
                    title: 'Duplication',
                    content: <CloneModal
                        entity={entity} 
                        resource={resource} 
                        message={`You are about to create a new finding by duplicating the data from ${entity.title}. 
                            Do you want to continue? 
                            In order to simply update the data of this finding, please use the below "Edit" yellow button.`}
                        errorMessage={"Your finding cannot be cloned."}
                        successMessage={(clonedEntity) => `Your new finding ${clonedEntity.id} (cloned from ${entity.title}) has been declared.`}
                    />,
                }),
            tooltip: 'Duplicate',
            icon: 'clone',
            className: 'action',
        });
    }

    return additionalActionButtons;
};

/**
 * Paramètres par défaut pour chaque champ de APIResource.
 * 
 * Permet d'automatiser les tests sur required, displayed ou editable.
 */
const fieldDefaultParams = {
    ...fieldHighlightedParam,
    displayConditions,
    editConditions,
    required: requiredByStatus,
}

/**
 * 
 * @param {*} _resource 
 * @returns {Object.<string, import('../Services/APIResource/APIResource').APIResourceField>}
 */
const FINDING_FIELDS = (_resource) => ({
    id: {
        title: 'ID',
        edit: () => null,
        helperText: 'Finding ID assigned by the tool',
        ...fieldDefaultParams,
        params: {
            filterMulti: true,
        },
    },
    title: {
        title: 'Title',
        type: 'text',
        helperText: 'Please give a short and explicit title to the finding',
        ...fieldDefaultParams,
    },
    review: {
        title: 'Review',
        type: 'entity',
        params: {
            resource: 'reviews',
            instanceId: 'finding_reviews',
            displayField: 'toString',
            links: true,
            endpoints: {
                getAll: 'string/entities/review',
            },
        },
        bulk: true,
        helperText: 'ID of the review that led to the finding',
        ...fieldDefaultParams,
        edit: (field, value, onChange, entity) => {
            // On bloque l'edition pour VAL si le Finding est créé depuis la Review.
            if (entity.hasOriginReview && isValOfFinding(entity)){
                return EntityProvider.getDisplay(field, value, entity);
            }
            return EntityProvider.getEdit(field, value, onChange, entity);
        },
    },
    severity: {
        title: 'Severity',
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_FINDING_NOTICE_SEVERITY,
            multi: false,
            filters: (item, entity) => isSeverityAllowed(entity, item)
        },
        helperText: 'Finding criticality defined by the validation team\n' +
            'BPCE : C1, C2, C3\n' +
            'Natixis : ***, ** , *',
        issueButton: false,
        ...fieldDefaultParams,
    },
    issuer: {
        title: 'Issuer',
        type: 'user',
        params: {
            resource: 'users',
            instanceId: 'users_all',
            displayField: 'toString',
            links: true,
            endpoints: {
                getAll: 'users/all-users/all',
            },
        },
        bulk: true,
        helperText: 'Name of the validator that initially emitted the finding',
        edit: (field, value, onChange, entity, routeParams) => {
            if (userHasRoleMRM()){
                return EntityAsyncProvider.getEdit(field, value, onChange, entity, routeParams);
            }
            return EntityAsyncProvider.getDisplay(field, value, entity, {});
        },
        ...fieldDefaultParams,
    },
    issuerTeams: {
        title: 'Issuer teams',
        type: 'entityAsync',
        params: {
            resource: 'scopes',
            instanceId: 'scopes_all',
            displayField: 'title',
            links: true,
            linkPath: (entity) => `/resource/scopes/${entity.id}/detail`,
            multi: true,
            endpoints: {
                getAll: 'scopes/all-scopes/all',
            }
        },
        helperText: 'Team of the finding issuer (based on group repository)',
        /**
         * Si on gère seulement avec displayConditions alors MRM pourra modifier le champ.
         * Ici on bloque également pour MRM
         */
        edit: () => null,
        ...fieldDefaultParams,
    },
    status: {
        title: 'Status',
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_FINDING_STATUS,
            multi: false,
        },
        bulk: true,
        helperText: 'Progress status of the finding (Draft, Open, Closed / Cancelled)',
        edit: (field, value, onChange, entity, routeParams) => {
            if (userHasRoleMRM()){
                return ParameterProvider.getEdit(field, value, onChange, entity, routeParams);
            }
            return ParameterProvider.getDisplay(field, value, entity, {});
        },
        ...fieldDefaultParams,
    },
    contextDescription: {
        title: 'Context description',
        type: 'textarea',
        helperText: 'Please give some information on the context that could help understand the weaknesses',
        ...fieldDefaultParams,
    },
    weaknessesDescription: {
        title: 'Weaknesses description',
        type: 'textarea',
        helperText: 'Please describe the model deficiencies, insufficiencies and / or uncertainties identified during the review on one or more risk subdimensions',
        ...fieldDefaultParams,
    },
    impactsDescription: {
        title: 'Impacts description',
        type: 'textarea',
        helperText: 'Description of the potential or actual impact (scope, etc.), justification by tangible elements, assessment of the impact if it is possible to do so, taking into account the existing mitigation actions',
        ...fieldDefaultParams,
    },
    models: {
        title: 'Models',
        type: 'model',
        params: {
            resource: 'models',
            instanceId: 'allModels',
            displayField: 'toString',
            links: true,
            multi: true,
            linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
        },
        helperText: 'ID of the models affected by the finding',
        /** @todo vérifier si on garde : déjà géré par editConditions, mais le cas de noticeId c'est
         * probablement pour notifié "add" spécifique, mais autorisé qu'à VAL donc ...
         */
        edit: (field, value, onChange, entity, routeParams) => {
            if(
                routeParams.noticeId ||
                userHasRoleMRM() ||
                User.profile.isMemberOfValidatorTeam ||
                isValOfFinding(entity)
            ) {
                return ModelProvider.getEdit(field, value, onChange, entity, routeParams);
            } else {
                return ModelProvider.getDisplay(field, value, entity, {});
            }
        },
        ...fieldDefaultParams,
    },
    issuanceDate: {
        title: 'Issuance date',
        type: 'date',
        bulk: true,
        helperText: 'Date of the committee that initially confirmed the finding',
        ...fieldDefaultParams,
    },
    findingMraRelations: {
        title: 'Mra dimension → Mra Sub Dimension',
        type: 'entity',
        params: {
            resource: 'finding_mra_relations',
            displayField: 'toString',
            multi: true,
        },
        issueButton: false,
        helperText: 'Please indicate the dimension(s) / subdimension(s) of the Model Risk Assessment affected by the weakness identified, in accordance with the new evaluation matrix',
        edit: (field, value, onChange, entity, _routeParams) => (
            <FindingMra field={field} entity={entity} value={value} onChange={onChange} />
        ),
        ...fieldDefaultParams,
    },
    noticesEntities: {
        title: 'Notices',
        display: (field, value, entity, _props) => {
            let nonFilteredValues = value;
            value = [];
            if(nonFilteredValues){
                nonFilteredValues.forEach((notice) => {
                    if (
                        (notice.status === ParameterStore('NOTICE_STATUS_DRAFT') && (User.profile.isMemberOfValidatorTeam || userHasRoleMRM()))
                        || notice.status !== ParameterStore('NOTICE_STATUS_DRAFT')
                    ) {
                        value.push(notice);
                    }
                })
            }
            return <TableDisplay
                rows={value}
                cols={[
                    {
                        label: 'ID',
                        field: 'id',
                    },
                    {
                        label: 'Status',
                        field: 'statusString',
                    },
                    {
                        label: 'Title',
                        field: 'title'
                    },
                    {
                        label: 'Severity',
                        field: 'severityString'
                    },
                ]}
                actions={(value) => {
                    return (
                        <div>
                            {
                                value.amIModelOwner
                                || value.amIMemberOfModelOwnerTeam
                                || value.amIModelOwnerDelegated
                                || userHasNoticeOwnershipRights(User.getId(), value)
                                || value.amIMemberOfDeveloperTeam
                                || isValOfFinding(entity)
                                || isMemberOfNoticeOwnerTeams(value)
                                || value.amIBusinessSponsor
                                || userHasRoleMRM()
                                || userHasSpecificRole(Role.IG)
                                || User.profile.isMemberOfValidatorTeam
                                    ?
                                    <OpenModal
                                        instanceId={'notices'}
                                        id={value.id}
                                        context="detail"
                                        modalTitle={value.title}
                                    />
                                    : null
                            }
                            {(value.status !== ParameterStore('NOTICE_STATUS_DELETED')
                                && value.status !== ParameterStore('NOTICE_STATUS_CLOSED')
                                && value.status !== ParameterStore('NOTICE_STATUS_DISMISSED')
                                && userHasValidatorRights(User.getId(), entity)
                            )
                            || userHasRoleMRM()
                                ?
                                <OpenModal
                                    parentInstanceId="findings"
                                    parentId={entity.id}
                                    instanceId="notices"
                                    id={value.id}
                                    context="edit"
                                    modalTitle={value.title}
                                    postSaveRedirectUrl={'/resource/findings/'+entity.id+'/detail?tab=Notices'}
                                />
                                : null
                            }
                        </div>
                    );
                }}
                buttons={
                    isValOfFinding(entity) || userHasRoleMRM() ?
                        [
                            {
                                label: 'Associate to a new or an existing Notice',
                                icon: 'fa-plus',
                                disabled: !!entity.hasOriginReview,
                                tooltip: entity.hasOriginReview ? 'Please go on the review page to associate to a new or an existing notice' : '',
                                onClick: () =>
                                    Modal.open({
                                        title: 'Associate to a Notice',
                                        content: (
                                            <ModalContent>
                                                <ButtonColumn>
                                                    <ActionLink
                                                        to={entity.review
                                                            ? `/resource/notices/add/finding/${entity.id}/`
                                                            + entity.review.split('/')[entity.review.split('/').length - 1]
                                                            : `/resource/notices/add/finding/${entity.id}`}
                                                        style={{display: 'contents'}}
                                                    >
                                                        <ActionButton onClick={() => Modal.close()}>
                                                            Create a new Notice
                                                        </ActionButton>
                                                    </ActionLink>
                                                    <ActionButton
                                                        onClick={() => Modal.open({
                                                            title: "Associate to an existing Notice",
                                                            content: <NoticeQuickSelect
                                                                resource={_resource}
                                                                finding={entity}
                                                                values={entity.notices}
                                                                multi={true}
                                                                inModal={true}
                                                                clearable={true}
                                                            />
                                                        })}
                                                    >
                                                        Associate to an existing Notice
                                                    </ActionButton>
                                                </ButtonColumn>
                                            </ModalContent>
                                        ),
                                        modalStyle: { width: '420px' },
                                    }),
                            },
                        ] : null}
            />
        },
        edit: () => null,
        displayList: () => null,
        ...fieldDefaultParams,
    },

    changeLogsEntities: {
        title: 'Audit trail',
        display: (field, value, entity, props) => (
            <ChangeLog
                field={field}
                values={value}
                entity={entity}
                entityResource={'findings'}
                props={props}
            />
        ),
        displayList: () => null,
        ...fieldDefaultParams,
    },

    //Additional fields without relation with Notice
    changeLogComment: {
        title: 'Justification of the data update',
        type: 'textarea',
        display: () => null,
        displayList: () => null,
        token: false,
        ...fieldDefaultParams,
    },
});

const FINDING_LAYOUT = {
    tabs: {
        General: {
            rows: [
                {
                    panels: {
                        Identification: {
                            cols: 4,
                            fields: [
                                'id',
                                'title',
                                'severity',
                            ],
                        },
                        Attributions: {
                            cols: 4,
                            fields: [
                                'issuer',
                                'issuerTeams',
                                'models',
                                'review',
                            ],
                        },
                        Progress: {
                            cols: 4,
                            fields: [
                                'status',
                                'issuanceDate',
                            ],
                        },

                    },
                },
                {
                    panels: {
                        'Risk dimensions': {
                            cols: 12,
                            fields: [
                                'findingMraRelations',
                            ],
                        },
                    },
                },
                {
                    panels: {
                        "Context and weaknesses": {
                            cols: 12,
                            fields: [
                                'contextDescription',
                                'weaknessesDescription',
                            ],
                        },
                    },
                },
                {
                    panels: {
                        Impacts: {
                            cols: 12,
                            fields: [
                                'impactsDescription',
                            ],
                        },
                    },
                },
            ],
        },
        Notices: {
            rows: [
                {
                    panels: {
                        'Notices': {
                            cols: 12,
                            fields: [
                                'noticesEntities',
                            ],
                        },
                    },
                },
            ],
        },
        'Audit trail': {
            rows: [
                {
                    panels: {
                        'Audit trail': {
                            cols: 12,
                            fields: [
                                'changeLogsEntities',
                                'changeLogComment',
                            ],
                        },
                    },
                },
            ],
        },
    },
};

const additionalActionButtonsList = (_resource) => {
    let additionalActionButtons = [];

    if (userHasRoleMRM() || userHasRoleADMIN()){
        additionalActionButtons.push({
            tooltip: 'Import',
            icon: 'download',
            className: 'download',
            /**
             * L'import se fait via les fichiers FindingNotice,
             * donc on utilise la resource "notices" ici.
             */
            onClick: () => Modal.open({
                title: "Import",
                content: (
                    <EntityImportForm resource="notices" label="Import Findings/Notices" />
                ),
                modalStyle: {width: "420px"}
            }),
        })
    }

    additionalActionButtons.push(
        {
            tooltip: 'Full export',
            icon: 'upload',
            className: 'upload',
            onClick: () => Modal.open({
                title: "Export",
                content: (
                    <EntityExportForm resource="findings" withDate={userHasRoleMRM() || userHasRoleADMIN() || User.profile.isMemberOfValidatorTeam} />
                ),
                modalStyle: {width: "420px"}
            }),
        }
    )


    if(
        User.profile.isMemberOfValidatorTeam
        || userHasRoleMRM()
    ) {
        const choices = [
            {
                link: '/resource/findings/add',
                title: 'New finding',
            },
        ];

        userHasRoleMRM() &&
        choices.push({
            link: '/resource/notices/add',
            title: 'New notice',
        });
        additionalActionButtons.push({
            onClick: () =>
                Modal.open({
                    title: userHasRoleMRM() ? 'New' : 'Declare a new Issue',
                    content: (
                        <>
                            <ChoiceModal choices={choices} />
                            {userHasRoleMRM() || (
                                <p>
                                    Please note that in order to create a new Notice, you must start
                                    with creating at least one Finding. Then, you will be able to create
                                    a new Notice directly on the Finding Sheet.
                                </p>
                            )}
                        </>
                    ),
                    modalStyle: { width: '420px' },
                }),
            tooltip: 'New',
            icon: 'plus',
            className: 'primary',
        });
    }

    return additionalActionButtons;
};

const FIELDS_SAVE_WHITE_LIST = ['origin'];

export const ApiResourceDefaultParams = {
    id: 'findings',
    fieldForTitle: 'title',
    name: "Finding",
    componentForTitle: (entity, resource, resourceEditComponent) => {
        return <Breadcrumb entity={entity} resource={resource} resourceEditComponent={resourceEditComponent} relationalProperty={'models'} resourcePath={'models'} />
    }
};

export default class FindingAdmin {
    constructor() {
        this.configure();
    }

    async configure() {
        await User.restore(true);

        let resourceNotices = new APIResource({
            id: 'notices',
            name: 'Notices',
            fieldForTitle: 'title',
            endpoints: {
                getAll: 'notices/all-notices',
            },
        });

        const views = [
            {
                instanceId: 'findings',
                name: 'Findings',
                additionalActionButtonsList: additionalActionButtonsList,
                permanentFilters: null,
                insertable: true,
                deletable: true,
                bulkEditable: true,
                bulkDeletable: true,
            },
            {
                instanceId: 'deleted_findings',
                name: 'Deleted findings',
                permanentFilters: {
                    deleted: true,
                },
            },
        ];

        views.forEach((view) => {
            const resource = new APIResource({
                ...ApiResourceDefaultParams,
                ...{
                    instanceId: view.instanceId,
                    name: view.name,
                }
            });
            resource
                .setFields(FINDING_FIELDS(resource))
                .setLayout(FINDING_LAYOUT)
                .genListView({
                    fields: [
                        'id',
                        'models',
                        'title',
                        'weaknessesDescription',
                        'severity',
                        'issuer',
                    ],
                    showDefaultAddButton: false,
                    additionalActionButtons: view.additionalActionButtonsList,
                    permanentFilters: view.permanentFilters,
                })
                .genDetailView({
                    header: header,
                    additionalActionButtons: additionalActionButtons,
                    additionalLinkButton: additionalLinkButton,
                    itemAccessCondition: (entity) => {
                        return entity.amIModelOwner
                            || entity.amIModelOwnerDelegated
                            || userHasNoticeOwnershipRights(User.getId(), entity)
                            || entity.amIMemberOfDeveloperTeam
                            || isValOfFinding(entity)
                            || entity.amIBusinessSponsor
                            || userHasRoleMRM();
                    }
                })
                .addBulkAction(EntityExport, {
                    resource: "findings",
                    icon: 'file-export',
                    label: "Export selected findings ?"
                })
            ;
            if(view.insertable){
                resource
                    .genInsertView({
                        menuItem: { title: 'Add' },
                        additionalRoutes: [
                            '/resource/findings/add/notice/:noticeId',
                            '/resource/findings/add/notice/:noticeId/:reviewId',
                            '/resource/findings/add/model/:modelId',
                            '/resource/findings/add/review/:reviewId/:reviewInstanceId',
                        ],
                        fields: [
                            'id',
                            'title',
                            'review',
                            'severity',
                            'issuer',
                            'issuerTeams',
                            'models',
                            'status',
                            'issuanceDate',
                            'findingMraRelations',
                            'contextDescription',
                            'weaknessesDescription',
                            'impactsDescription',
                            'changeLogComment'
                        ],
                        postSaveRedirect: 'detail',
                        fieldsSaveWhitelist: FIELDS_SAVE_WHITE_LIST,
                        onInit: ({ entity, routeParams, _context }) => {
                            entity.notices = routeParams.noticeId ? ['/api/notices/' + routeParams.noticeId] : entity.notices;
                            entity.models = routeParams.modelId ? ['/api/models/' + routeParams.modelId] : entity.models;
                            if(!entity.models){
                                entity.models = [];
                            }else{
                                entity.modelEntity = getModel(entity.models[0]);
                            }
                            entity.review = routeParams.reviewId ? '/api/reviews/' + routeParams.reviewId : entity.review;
                            if(entity.review && routeParams.reviewId && !routeParams.noticeId){
                                entity.origin = ORIGIN_REVIEW;
                                entity.hasOriginReview = true;
                                entity.reviewEntity = getReview(entity, routeParams);
                                if(entity.reviewEntity?.models){
                                    entity.reviewEntity.models.forEach((modelPath) => {
                                        entity.models.push(modelPath);
                                    });
                                }
                            }
                            if(entity.notices && Array.isArray(entity.notices) && entity.notices.length > 0 && routeParams.noticeId){
                                entity.notices.forEach((noticePath) => {
                                    resourceNotices.getItemFromResourcePath(noticePath).then((notice) => {
                                        if(notice.models){
                                            /**
                                             * Si les notices associées sont aussi liées à d'autres findings, alors elles peuvent avoir
                                             * plus de Models que le Finding courant, on remplit donc par défaut.
                                             */
                                            notice.models.forEach((modelPath) => {
                                                entity.models.push(modelPath);
                                            });
                                        }
                                    });
                                })
                            }

                            entity.status = ParameterStore('FINDING_STATUS_OPEN');
                            if(entity.review || User.profile.isMemberOfValidatorTeam){
                                entity.status = ParameterStore('FINDING_STATUS_DRAFT');
                            }

                            entity.issuer = `/api/users/${User.getId()}`;
                            entity.validators = [entity.issuer];
                        },
                    })
                    .genEditView({
                        fields: [
                            'id',
                            'title',
                            'review',
                            'severity',
                            'issuer',
                            'issuerTeams',
                            'models',
                            'status',
                            'issuanceDate',
                            'findingMraRelations',
                            'contextDescription',
                            'weaknessesDescription',
                            'impactsDescription',
                            'changeLogComment'
                        ],
                        fieldsSaveWhitelist: FIELDS_SAVE_WHITE_LIST,
                        itemAccessCondition: (entity) =>
                            (entity.status !== ParameterStore('FINDING_STATUS_DELETED')
                                && entity.status !== ParameterStore('FINDING_STATUS_CLOSED')
                                && entity.status !== ParameterStore('FINDING_STATUS_DISMISSED') || userHasRoleMRM())
                            && !userHasSpecificRole(Role.IG)
                            && ( isValOfFinding(entity)
                                || userHasRoleMRM()
                            ),
                        onUpdate: (_fieldId, _oldValue, _newValue, _entity, _resource, _resourceEditComponent) => {
                            /* // on ne veut plus systématiquement mettre à jour les models depuis la review
                            // on le fait uniquement dans le onInit en cas de review déjà remplie.
                            if (fieldId === 'review' && newValue){
                                resourceReviews.getItemFromResourcePath(newValue).then((review) => {
                                    if(!entity.models){ entity.models = []; }
                                    entity.models = entity.models.concat(review.models);
                                    resourceEditComponent.setState({ entity });
                                });
                            } */
                        },
                        header: header,
                        additionalActionButtons: (entity, resource, resourceEditComponent, queryParams) => {
                            let additionalActionButtons = [];

                            if (queryHasAttribute(queryParams, 'notice-id')) {
                                additionalActionButtons.push({
                                    link: '/resource/notices/' + queryParams.get('notice-id') + '/detail',
                                    tooltip: 'Go back to notice',
                                    icon: 'chevron-left',
                                });
                            }
                            return additionalActionButtons;
                        },
                        additionalLinkButton: additionalLinkButton,
                    })
                ;
            }
            if(view.deletable){
                resource.allowDelete({
                    itemAccessCondition: (entity) => (
                            entity.status === ParameterStore('FINDING_STATUS_DRAFT')
                            && isValOfFinding(entity)
                        )
                        || userHasRoleMRM()
                    ,
                    component: (entity) => {
                        return (
                            <LogicalDeleteButton
                                entity={entity}
                                className="tooltip-top"
                                resource={resource}
                                hideReplaceButton={true}
                                entityType={'finding'}
                                entityTypeLabel={'finding'}
                                entityTitle={entity.title}
                                successMessage={"Your finding has been deleted."}
                            />
                        );
                    },
                });
            }

            if (
                userHasRoleMRM()
                && view.bulkEditable
            ) {
                resource
                    .addBulkAction(BulkEdit, {
                        resource: resource,
                        icon: 'edit',
                        fields: Object.fromEntries(
                            Object.entries(resource.fields)
                                .filter(([k, v]) => {
                                    return v.bulk && resource.operations.edit.fields.includes(k);
                                })
                                .map(([k, v]) => ((v.resourceId = resource.id), [k, v]))
                        ),
                    })
                ;
            }

            if (
                userHasRoleMRM()
                && view.bulkDeletable
            ) {
                resource
                    .addBulkAction(BulkDelete, {
                        itemAccessCondition: (entity) => (
                                entity.status === ParameterStore('FINDING_STATUS_DRAFT')
                                && isValOfFinding(entity)
                            )
                            || userHasRoleMRM(),
                        forbiddenAccessMessage: "You can't delete findings that are not in Draft status.",
                        resource,
                        icon: 'trash-alt',
                        entityType: 'finding',
                        entityTypeLabel: 'Finding',
                        softDelete: true
                    })
                ;
            }

        });
    }
}

const styles = {
    mrDetailBtn: {
        margin: 1,
    },
    actionLink: {
        marginLeft: 5,
    },
};
