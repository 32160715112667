import Http from '../../Services/Http';
import Alert from "../Alert";
import ParameterStore from "../../Store/ParameterStore";
import Navigation from "../Navigation";

export const submitValidation = (
    entity
) => {
    Http.get(`notices/${entity.id}/manager`).then(() => {
        Alert.show({
            message: 'The notice has been submitted to VAL for validation.',
            type: 'success',
        });
    }).catch(() => {

    });
}

export const submitToNoticeOwnerToReviewAction = (
    entity
) => {
    Http.get(`reviews/${entity.id}/notice-owner`).then(() => {
        Alert.show({
            message: 'Your reminder has been sent to the Notice Owner(s).',
            type: 'success',
        });
    }).catch(() => {

    });
}


export const submitNotice = (
    resource,
    entity,
    currentStatus,
    nextStatus,
    resourceEditComponent,
    tooltip
) => {
    entity.status = nextStatus;

    let success = 'The notice has been saved';
    if (
        nextStatus === ParameterStore('NOTICE_STATUS_WAITING_FOR_VALIDATION')
    ) {
        success = 'The notice has been submitted to VAL for validation.';
    }
    
    resource.currentEditingActions.save(
        success,
        success,
        'Your changes have been saved. ' + (tooltip !== undefined && tooltip !== null ? 'To ' + tooltip.toLowerCase() + ', the' : 'The') + ' following fields are required: ',
        entity
    ).then((entity) => {
        entity.status = nextStatus;
        resource.apiPut(entity).then(() => {});
    }).catch(() => {
        entity.status = currentStatus;
        resource.apiPut(entity).then((entity) => {
            resourceEditComponent.setState({entity: entity});
        });
    });
};

export const noticeConfirmReceipt = (
    resource,
    entity,
    resourceEditComponent
) => {
    entity.readOwnershipNotification = true;

    resource.apiPut(entity).then((entity) => {
        Alert.show({ message: 'The validator has been notified' });
        resourceEditComponent.setState({entity: entity});
        Navigation.router.history.push(`/resource/my_notices/${entity.id}/detail`);
    }).catch(() => {
        entity.readOwnershipNotification = false;
        resource.apiPut(entity).then((entity) => {
            resourceEditComponent.setState({entity: entity});
            Navigation.router.history.push(`/resource/my_notices/${entity.id}/detail`);
        }).catch(() => {
            resourceEditComponent.setState({entity: entity});
            Navigation.router.history.push(`/resource/my_notices/${entity.id}/detail`);
        });
    });
}

export const noticeMissingFieldsAndFinding = () => {
    Alert.show({
        message: "All the required fields must be completed and all the notices must be associated to at least one finding",
        type: 'warning',
    });
}