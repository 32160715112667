import React, { Component } from "react";
import Modal from "../../../Services/Modal";
import Alert from "../../../Services/Alert";
import FormControl from "@material-ui/core/FormControl";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Navigation from "../../../Services/Navigation";
import Http from "../../../Services/Http";
import { ButtonBar } from "../../Modal/ButtonBar";
import { ActionButton } from "../../Modal/ActionButton";

export class LogicalDeleteForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: null,
            progress: false,
        };
    }

    delete(entity, resource, andReplace = false) {
        if (this.state.comment === null) {
            Alert.show({
                message: "The comment is required before deletion.",
                type: "danger"
            });
            return;
        }

        this.setState({progress: true});

        if ((this.props.entityType === 'model' || this.props.entityType === 'models') && andReplace === true) {
            Navigation.router.history.push("/resource/models/add/" + entity.id);
        } else {
            Navigation.router.history.push(
                "/resource/" + resource.instanceId + "/list"
            );
        }

        let endpoint = (this.props.entityType == 'model' || this.props.entityType == 'models') ? 'models/delete' : 'operations/logical-delete';

        Http.post(endpoint, {ids: [entity.id], entity: this.props.entityType, deletionComment: this.state.comment}).then(() => {
            Alert.show({ message: this.props.successMessage || "Item successfully deleted.", type: "success" });
            resource.apiGetCollection({
                forceReload: true
            });
            Modal.close();
            this.setState({progress: false});
        }).catch(err => {
            console.error(err);
            Modal.close();
            this.setState({progress: false});
        });
    }

    groupDelete(entities, resource) {
        if (!this.state.comment) {
            Alert.show({
                message: "The comment is required before deletion.",
                type: "danger"
            });
            return;
        }

        this.setState({progress: true});

        Navigation.router.history.push(
            "/resource/" + resource.instanceId + "/list"
        );

        const ids = entities.map(entity => {
            return entity.id;
        });

        let endpoint = (this.props.entityType == 'model' || this.props.entityType == 'models') ? 'models/delete' : 'operations/logical-delete';

        Http.post(endpoint, {ids: ids, entity: this.props.entityType, deletionComment: this.state.comment}).then(() => {
            Alert.show({ message: "Items successfully deleted.", type: "success" });
            resource.apiGetCollection({
                forceReload: true
            });
            Modal.close();
            this.setState({progress: false});
        }).catch(err => {
            console.error(err);
            Modal.close();
            this.setState({progress: false});
        });
    }

    getTitle = () => {
        if (this.props.entity) {
            return `Are you sure you want to delete the ${this.props.entityTypeLabel || 'model'} ${this.props.entityTitle || this.props.entity.functionalID} ?`;
        }
        if (this.props.ids) {
            return `Are you sure you want to delete ${this.props.ids.length} ${
                this.props.entityTypeLabel && this.props.entityTypeLabel.toLowerCase() || 'model'
            }${this.props.ids.length > 1 ? 's' : ''} ?`;
        }
    };

    handleDeleteClick() {
        const { entity, ids, resource } = this.props;
        if (entity) {
            return this.delete(entity, resource);
        }
        if (ids && ids.length) {
            const entities = ids.map(id => ({ id }));
            return this.groupDelete(entities, resource, false);
        }
    }

    render() {
        return (
            <div className={"logical-delete-form"}>
                <p>{this.getTitle()}</p>
                <FormControl className="container_textarea">
                    <label>Comment</label>
                    <TextareaAutosize
                        value={this.state.comment ? this.state.comment : ""}
                        onChange={event => this.setState({ comment: event.target.value })}
                        rows={5}
                        rowsMax={10}
                    />
                </FormControl>
                <ButtonBar>
                    <ActionButton
                        disabled={this.state.progress}
                        onClick={Modal.close}
                    >
                        Cancel
                    </ActionButton>
                    <ActionButton
                        onClick={() => this.handleDeleteClick()}
                        loading={this.state.progress}
                    >
                        Delete
                    </ActionButton>
                    {this.props.entity && !this.props.hideReplaceButton && (
                        <ActionButton
                            onClick={() =>
                                this.delete(this.props.entity, this.props.resource, true)
                            }
                            loading={this.state.progress}
                        >
                            Delete and replace
                        </ActionButton>
                    )}
                </ButtonBar>
            </div>
        );
    }
}