import React from "react";

class Modal {
  constructor() {
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }
  open(params) {
    if (this.ref) {
      this.ref.open(params);
    }
    return this;
  }
  close() {
    if (this.ref) {
      this.ref.close();
    }
  }
}

export default new Modal();
