import { APIResource } from "../../../Services/APIResource/APIResource";
import _ from "lodash";
import LocalNotification from "../../../Services/LocalNotification";
import Config from "../../../Config/App";
import React, { Component } from "react";
import { NotificationWidget } from "../../Dashboard/Widget";
import {
    Badge,
    Button,
    Paper,
    Grow,
    Popper,
    ClickAwayListener
} from "@material-ui/core";

import NotificationsNone from "@material-ui/icons/NotificationsNone";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import Http from "../../../Services/Http";

export class NotificationMenu extends Component {
    handle = null;
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            notifications: [],
            count: 0
        };

        this.pauseRefresh = false;
        this.anchorRef = React.createRef();

        this.notificationsResource = new APIResource({
            id: "notifications"
        });
    }

    componentDidMount() {
        this.getNotifications({ forceReload: true });

        this.handle = setInterval(() => {
            if (this.pauseRefresh) {
                return;
            }
            this.getNotifications({ forceReload: true });
        }, Config.notificationRefeshInterval || 1000);
    }

    componentWillUnmount() {
        clearInterval(this.handle);
    }

    getNotifications = (options = { forceReload: true }) => {
        this.pauseRefresh = true;
        options.rowsPerPage = 1000;
        return this.notificationsResource
            .apiGetCollection(options)
            .then(notifications => {
                if (!notifications) {
                    return;
                }
                const count = notifications.filter(n => n.status === "new").length;
                const oldNotifications = this.state.notifications;
                const newNotifications = _.differenceBy(
                    notifications,
                    oldNotifications,
                    n => n.id
                );
                this.setState({ notifications, count });
                this.notifyOfNewMessages(newNotifications);

                return notifications;
            })
            .finally(() => {
                this.pauseRefresh = false;
            });
    };

    notifyOfNewMessages(notifications) {
        // @todo work in progress manage case of many tabs. also case of single new message
        const newNotif = notifications.filter(n => n.status === "new");
        if (!newNotif || !newNotif.length) {
            return;
        }
        if (newNotif && newNotif.length < 5) {
            newNotif.forEach((n, index) =>
                setTimeout(() => {
                    LocalNotification.notify(n);
                }, index * 2000)
            );
        } else {
            LocalNotification.notify({
                fromUser: { fullName: "MRM System" },
                content: `You have ${newNotif.length} new notifications`,
                link: "/dashboard"
            });
        }
    }

    clearNotifications = (options = { forceReload: true }) => {
        const { notifications } = this.state;
        this.setState({ count: 0 });
        if (notifications.length) {
            Http.get('notifications/read').then(() => {});
        }
    };

    handleToggle = () => {
        this.setOpen(!this.state.open);
        this.clearNotifications();
    };

    handleClose = event => {
        if (
            this.anchorRef.current &&
            this.anchorRef.current.contains(event.target)
        ) {
            return;
        }

        this.setOpen(false);
    };

    setOpen = open => {
        this.setState({ open });
    };
    handleListKeyDow = event => {
        if (event.key === "Tab") {
            event.preventDefault();
            this.setOpen(false);
        }
    };

    // return focus to the button when we transitioned from !open -> open

    render() {
        const { open, notifications, count } = this.state;
        return (
            <div style={styles.notificationsBlock}>
                <Button
                    ref={this.anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleToggle}
                    style={{ color: '#fff' }}
                >
                    <Badge color="primary" badgeContent={count}>
                        {count ? (
                            <NotificationsActiveIcon />
                        ) : (
                                <NotificationsNone />
                            )}
                    </Badge>
                </Button>
                <Popper
                    open={open}
                    anchorEl={this.anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: 'center bottom',
                            }}
                        >
                            <Paper id="menu-list-grow" style={styles.list}>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <NotificationWidget
                                        notifications={notifications}
                                        onNotificationClick={() => this.setOpen(false)}
                                    />
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    }
}

const styles = {
    deleteButton: { color: "#fff" },
    notificationsBlock: {
        display: "inline"
    },
    list: {
        minWidth: 500,
        marginTop: 14,
        maxHeight: "50vh",
        backgroundColor: "#fff",
        overflow: "auto"
    }
};

export default NotificationMenu;
