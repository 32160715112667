import React, {Component} from "react";
import PropTypes from 'prop-types';
import Modal from '../../Modal';
import FormControl from '@material-ui/core/FormControl/index';
import TextField from '@material-ui/core/TextField/index';
import './bulk-edit.css';
import Http from '../../Http';
import Alert from '../../Alert';
import FieldProviderStore from "../../APIResource/FieldProviders/__FieldProviderStore";
import { userHasRoleADMIN } from '../../../Store/ParameterStore';
import { ActionButton } from "../../../Components/Modal/ActionButton";
import { openLoadingModal, validateIds } from "../bulkUtils";
import { Checkbox, FormControlLabel } from "@material-ui/core";

export class BulkEdit{
    getId(){
        return 'bulkEditing'
    }

    getLabel(){
        return 'Bulk Edit'
    }

    /**
     * @param {import('../../APIResource/APIResource').APIResourceBulkParams} params 
     */
    setParameters(params){
        this.parameters = params;
    }

    /**
     * @param {Array} ids 
     * @param {*} parent - DataTable en cours
     */
    async run(ids, parent, params = {}){
        this.ids = ids;
        const errorMessage = this.parameters.forbiddenAccessMessage || 'Bulk edit unavailable : Your selection can only include items you have the right to edit.';

        let openModal = () => {
            Modal.open({
                title: 'Bulk Edit',
                content: (
                    <BulkEditingForm
                        fields={this.parameters.fields}
                        ids={this.ids}
                        parent={parent}
                        resource={this.parameters.resource}
                        selectAll={params?.selectAll}
                    />
                ),
            });
        };

        openLoadingModal();

        if (
            await validateIds(
                ids,
                this.parameters.itemAccessCondition,
                this.parameters.resource,
                this.parameters.itemAccessConditionEntityFields,
                this.parameters.bulkValidateRoles
            )
        ) {
            openModal();
        } else {
            Modal.close();
            Alert.show({ message: errorMessage });
        }
    }
}

export class BulkEditingForm extends Component{
    constructor(props){
        super(props);
        /** @type {{resource: import('../../APIResource/APIResource').APIResource}} */
        this.props;

        this.state = {
            fields: {},
            changeLogComment: '',
            progress: false,
            nullFields: [],
        }

        this.changedFields = {};
    }

    handleChange(fieldId, value){
        if(fieldId === 'changeLogComment') {
            this.setState({changeLogComment: value});
        } else {
            let fields = this.state.fields;
            fields[fieldId] = value;
            this.setState({fields: fields});
        }
        if (value === '' || value === null || (Array.isArray(value) && !value.length)) {
            if (userHasRoleADMIN()) {
                this.changedFields[fieldId] = value;
            } else {
                delete this.changedFields[fieldId];
            }
        }
        else {
            this.changedFields[fieldId] = value;
        }
    }

    save(){
        this.setState({progress: true});
        Http.post(this.props.resource.resourceId + '/bulk/edit', {
            ids: this.props.ids,
            fields: this.changedFields,
            changeLogComment: this.state.changeLogComment
        }).then((response) => {
            this.props.parent.refreshApiData({forceReload: true})
                .then(() => this.props.parent.setState({selection: [], progress: false}));
            Alert.show({message: response.message || 'Bulk Edit successfully processed.'});
            Modal.close();
        });
    }

    genForm(){
        let fields = [];
        for(let i in this.props.fields){
            let field = this.props.fields[i];
            const fieldId = i;
            let component = null;
            field.issueButton = false;
            field.required = false;

            /** @todo utiliser editComponent et tester les displayCondition pour les entités modifiées, plutôt que de passer les "bulkEntities" */
            if(field.edit){
                component = field.edit(field, this.state.fields[fieldId], (value) => {this.handleChange(fieldId, value)}, this.state.fields, {}, "bulkEdit", this.props.resource.items.filter((item) => this.props.ids.includes(item.id)));
            }
            else if(FieldProviderStore[field.type] && FieldProviderStore[field.type].getEdit){
                component = FieldProviderStore[field.type].getEdit(field, this.state.fields[fieldId], (value) => {this.handleChange(fieldId, value)}, this.state.fields, {});
            }
            else if(!field.component){
                if(this.state.fields[i] === undefined){
                    this.state.fields[i] = '';
                }
                component =
                    <div key={i}>
                        <FormControl>
                            <TextField
                                id={'field-' + i}
                                label={field.title}
                                className={'field-' + i}
                                onChange={(event) => {this.handleChange(i, event.target.value)}}
                                value={this.state.fields[i]}
                            />
                        </FormControl>
                    </div>
                ;
            }
            else{
                component = field.component((value) => {this.handleChange(i, value)});
            }
            if (component !== undefined) fields.push(
                <div className="field" key={i}>
                    {this.state.nullFields.includes(fieldId) ? <TextField label={field.title} value={this.state.fields[fieldId]} disabled={true} /> : component}
                    {userHasRoleADMIN() && <FormControlLabel
                        style={{ marginLeft: 10 }}
                        control={
                            <Checkbox
                                checked={this.state.nullFields.includes(fieldId)}
                                onChange={(event, checked) => {
                                    this.setState({nullFields: checked ? [...this.state.nullFields, fieldId] : this.state.nullFields.filter(f => f !== fieldId)});
                                    if (checked) {
                                        this.handleChange(fieldId, field.params.multi ? [] : null)
                                    } else {
                                        const fields = { ...this.state.fields};
                                        delete fields[fieldId];
                                        this.setState({fields});
                                    }
                                }}
                            />
                        }
                        label={<span>null</span>}
                    />}
                </div>
            );
        }

        let bottomFields = []
        Object.entries({
            changeLogComment: {title: 'Justification of the data update', type: 'text', rows: 3,  display: () => null, displayList: () => null}
        }).map(([ fieldId, field ]) => {
            this.state.changeLogComment = this.state.changeLogComment || '';
            bottomFields.push(<div className="field changelog" key={fieldId}>
                {FieldProviderStore[field.type].getEdit(field, this.state.changeLogComment, (value) => {
                    this.handleChange(fieldId, value)
                }, this.state.fields)}
            </div>)
        });

        return(
            <div className="bulk-edit">
                <div className="fields main-fields"> {/* grand padding pour éviter que la dernière entity select ne soit invisible */}
                    {fields}
                </div>
                <div className="fields">
                    {bottomFields}
                    <ActionButton 
                        onClick={this.save.bind(this)}
                        loading={this.state.progress}
                    >
                        Apply to {this.props.ids.length} {this.props.resource.resourceId}
                    </ActionButton>
                </div>

            </div>
        );
    }

    render(){
        return(this.genForm());
    }
}
BulkEditingForm.propTypes = {
    ids: PropTypes.array,
    parent: PropTypes.any,
    resource: PropTypes.any,
    fields: PropTypes.object,
}
