import React, {Component} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import String from "./String";

class Alert{
    /**
     * @param {{message: string, type: 'success'|'warning'|'danger'}} params
     */
    show(params){
        if(this.ref){
            this.ref.show(params);
        }
    }
}

export default new Alert();

export class AlertProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            message: null,
            type: null
        }
    }

    show(params) {
        this.setState({
            message: String.nlToBr(params.message),
            visible: true,
            type: params.type ? params.type : 'success'
        });
    }



    render() {
        let self = this;
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.state.visible}
                onClose={() => self.setState({visible: false})}
                autoHideDuration={8000}
            >
                <SnackbarContent
                    message={
                        <span>
                            {this.state.message}
                        </span>
                    }
                    action={[
                        <IconButton key="close" aria-label="close" color="inherit" onClick={() => {
                            self.setState({visible: false})
                        }}>
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        );
    }
}
