import React, {Component} from 'react';
import {observer} from "mobx-react";
import {DataTable} from "../DataTable/DataTable";
import AppBarStore from '../../../../Store/AppBarStore';
import {CONTEXT_LIST} from "../../APIResource";

export const ResourceList = observer(class ResourceList extends Component{
    constructor(props){
        super(props);
        AppBarStore.title = this.props.resource.name;
        AppBarStore.previousTitle = null;
    }

    render(){
        let self = this;
        let genHeader = function () {
            return typeof self.props.resource.operations[CONTEXT_LIST].header === "function"
                ? self.props.resource.operations[CONTEXT_LIST].header()
                : null;
        };
        return (
          <div className={'resource-list'}>
              {genHeader()}
            <DataTable {...this.props} />
          </div>
        );
    }
});
