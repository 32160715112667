import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Alert from '../../../Services/Alert';
import Button from "@material-ui/core/Button";
import Modal from "../../../Services/Modal";
import Icon from "@material-ui/core/Icon";
import {ParameterSelect} from "../../Forms/ParameterSelect/ParameterSelect";
import {APIResource} from "../../../Services/APIResource/APIResource";
import {DisplayTextField} from "../../Display/DisplayTextField/DisplayTextField";
import { ButtonBar } from '../../Modal/ButtonBar';
import { ActionButton } from '../../Modal/ActionButton';
import { CircularProgress } from '@material-ui/core';
import {PARAMETER_TYPE_MODEL_BACKGROUND_TAG} from "../../../Admin/ParameterAdmin";
import {EntitySelect} from "../../Forms/EntitySelect/EntitySelect";

const fields = {
    mraDimension: {
        title: 'Mra dimension',
        type: 'entity',
        params: {
            resource: 'mra_dimensions',
            instanceId: 'finding_mra_dimensions',
            displayField: 'title',
            filters: {
                lang: 'en'
            },
        },
        required: true,
        requiredComputed: true,
    },
    mraSubDimension: {
        title: 'Mra Sub Dimension',
        type: 'entity',
        params: {
            resource: 'mra_subdimensions',
            instanceId: 'finding_mra_subdimensions',
            displayField: 'title',
            filters: {
                lang: 'en'
            },
        },
    },
};

class FindingMra extends Component {

    constructor(props) {
        super(props);
        this.openFindingMraModal = this.openFindingMraModal.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.resource = new APIResource({ id: this.props.field.params.resource, instanceId: 'finding_mra_' + this.props.field.params.resource });
        this.state = {
          value: '',
        };
    }

    componentDidMount() {
        this.updateValue(this.props.value);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.value !== prevProps.value) || (this.props.entity !== prevProps.entity)) {
            this.updateValue(this.props.value);
        }
    }

    genFindingMraForm(){
        return <FindingMraForm
            resource={this.resource}
            entity={this.props.entity}
            field={this.props.field}
            onSubmit={(entity) => {
                this.updateValue(entity[this.props.field.id]);
                this.props.onChange(this.props.entity[this.props.field.id]);
            }}
            inverseRelation={this.props.inverseRelation}
            modalRef={this.props.modalRef}
        />
    }

    openFindingMraModal(){
        const modal = this.props.modalRef || Modal;
        return modal.open({
            title: `Add a dimension`,
            content: this.genFindingMraForm(),
        });
    };

    removeItem(i){
        this.props.entity[this.props.field.id].splice(i, 1);
        this.updateValue(this.props.entity[this.props.field.id]);
        this.props.onChange(this.props.entity[this.props.field.id]);
    }

    updateValue(value){
        if (Array.isArray(value) && value.length > 0 && typeof value[0] != 'object') {
            let promises = [];
            let values = [];
            value.forEach((val, index) => {
                const id = val && val.indexOf('/api') > -1 ? val.split('/').pop() : val;
                let promise = this.resource.getItemFromResourcePath(id).then((entity) => {
                    if (!entity) {
                        return;
                    }
                    let valLabel = entity[this.props.field.params.displayField] ? entity[this.props.field.params.displayField] : '-';
                    values.push(
                        <div key={index}>
                            {valLabel}
                            <Button
                                size="small"
                                style={{color: 'hsl(0,0%,80%)', marginLeft: 10, minWidth: 30}}
                                onClick={() => this.removeItem(index)}
                            >
                                <Icon className={"fa fa-times"}></Icon>
                            </Button>
                        </div>
                    );
                });
                promises.push(promise);
            });
            Promise.all(promises).then(() => {
                this.setState({value: <div>{values}</div>});
            });
        }else{
            this.setState({value: []});
        }
    }

    render() {
        return (
            <div
                className={
                    'select-component finding-mra-relation'
                }
            >
                {this.props.field.label ? (
                    <label>
                        {this.props.field.label}
                    </label>
                ) : null}
                <div className={'with-edit-button'}>
                    <DisplayTextField
                        fieldName={this.props.field.title}
                        value={this.state.value}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.openFindingMraModal}
                    >
                        <Icon className={"fa fa-plus"}></Icon>
                    </Button>
                </div>
            </div>
        );
    }
}

export default observer(FindingMra);

class FindingMraForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mraDimension: null,
            mraSubDimension: null,
        };
        this.submit = this.submit.bind(this);
    }

    submit() {
        let entity = {};
        let self = this;

        if(self.state.mraDimension === null){
            Alert.show({ message: 'Please select a Mra dimension', type: 'danger' });
            return;
        }

        entity.mraDimension = self.state.mraDimension;
        entity.mraSubDimension = self.state.mraSubDimension;
        entity.finding = self.props.entity['@id'];

        self.setState({ apiUpdateInProgress: true });
        self.props.resource.apiPost(entity).then((item) => {
            if(!self.props.entity.findingMraRelations){
                self.props.entity.findingMraRelations = [];
            }
            self.props.entity[self.props.field.id].push(item['@id']);
            self.props.onSubmit(self.props.entity);
            Alert.show({ message: `Dimension successfully added.`, type: "success" });
        })
        .finally(() => {
            self.props.modalRef ? self.props.modalRef.close() : Modal.close();
        });
    }

    render() {
        var subDimensionFilters = fields.mraSubDimension.params.filters;
        subDimensionFilters['mraDimension'] = this.state.mraDimension;
        return (
            <div className={"new-finding-mra-form"}>

                <EntitySelect
                    label={fields.mraDimension.title}
                    resourceId={fields.mraDimension.params.resource}
                    instanceId={fields.mraDimension.params.instanceId}
                    resourceLabel={fields.mraDimension.params.displayField}
                    value={this.state.mraDimension}
                    onChange={(val) => {
                        this.setState({mraDimension: val, mraSubDimension: null})
                    }}
                    clearable={!("required" in fields.mraDimension && fields.mraDimension.required === true)}
                    multi={fields.mraDimension.params.multi}
                    filters={fields.mraDimension.params.filters}
                    required={"required" in fields.mraDimension && fields.mraDimension.requiredComputed === true}
                    error={!!fields.mraDimension.errorHelperText}
                    className={fields.mraDimension.errorHelperText ? "field-error-control" : ""}
                    helperText={fields.mraDimension.errorHelperText}
                    endpoints={fields.mraDimension.params.endpoints}
                />

                {
                    this.state.mraDimension &&
                    <EntitySelect
                        label={fields.mraSubDimension.title}
                        resourceId={fields.mraSubDimension.params.resource}
                        instanceId={fields.mraSubDimension.params.instanceId}
                        resourceLabel={fields.mraSubDimension.params.displayField}
                        value={this.state.mraSubDimension}
                        onChange={(val) => {
                            this.setState({mraSubDimension: val})
                        }}
                        clearable={!("required" in fields.mraSubDimension && fields.mraSubDimension.required === true)}
                        multi={fields.mraSubDimension.params.multi}
                        filters={subDimensionFilters}
                        required={"required" in fields.mraSubDimension && fields.mraSubDimension.requiredComputed === true}
                        error={!!fields.mraSubDimension.errorHelperText}
                        className={fields.mraSubDimension.errorHelperText ? "field-error-control" : ""}
                        helperText={fields.mraSubDimension.errorHelperText}
                        endpoints={fields.mraSubDimension.params.endpoints}
                    />
                }


                <ButtonBar>
                    <ActionButton onClick={() => this.props.modalRef ? this.props.modalRef.close() : Modal.close()}>
                        Cancel
                    </ActionButton>
                    <ActionButton onClick={() => this.submit()} disabled={this.state.apiUpdateInProgress}>
                        {this.state.apiUpdateInProgress ?
                            <CircularProgress size={20} />
                            : 'Add' }
                    </ActionButton>
                </ButtonBar>
            </div>
        );
    }
}
